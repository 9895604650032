import React, { useContext, useEffect, useState } from 'react'
import styles from './styles.module.css';
import { MobXProviderContext, observer } from 'mobx-react';
import { UserStore } from 'stores/UserStore';
import Charts from './Charts'
import Table from './Table'

export interface Restriction {
  modelCount: number | string,
  deployCount: number | string,
  storageCount: number | string,
  projectCount: number | string,
}

const Account = () => {
  const [restriction, setRestriction] = useState({
    used: {
      modelCount: 0,
      deployCount: 0,
      storageCount: 0,
      projectCount: 0,
    },
    restriction: {},
    plan: {},
    user: {}
  } as {
    used: Restriction,
    restriction: Object
    user
  });
  const [loading, setLoading] = useState(true);

  const { userStore: { getAccountInfo } } = useContext(MobXProviderContext);

  useEffect(() => {
    getAccountInfo().then((data: any) => {
      if (data.status === 200) {
        const limit = data.limit;
        for (let key in limit) {
          if (typeof limit[key] !== 'number') delete limit[key]
        }
        setRestriction({
          used: data.used,
          restriction: data.restriction,
          user: data.user
        })
      }
      setLoading(false)
    })
  }, []);

  return loading ? null : <div className={styles.main}>
    <Charts restriction={restriction} />
    <Table restriction={restriction} />
  </div>
};

export default observer(Account)
