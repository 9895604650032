import React from 'react';
import classnames from 'classnames';
import { Layout, Menu, Breadcrumb } from 'antd';
import { TranslationOutlined } from '@ant-design/icons';
import logo from '../Sider/rsquared_logo_color.svg';
import styles from './styles.module.css';
import EN from '../../../constant/en';
const { Header, Content, Sider } = Layout;


const LoginHeader = () => (
  <Header style={{ background: 'white' }}>
    <div className={classnames(styles.loginHeader, 'container')}>
      <img className={styles.logoImg} src={logo} alt="logo" style={{ width: 56 }}/>
    </div>
  </Header>
);

export default LoginHeader
