import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { request } from '@app/utils';
import { useRequest } from 'ahooks';
import { ExplainParam, Explain, DPDExplainOption, DependenceExplain, ExplainViewLabel, ExplainViewType } from './type';
import { DependentPlotsChart } from './DependentPlotsChart';
import MIBar from './MIBar';
import { DependentOptionForm } from './DependentOptionForm';
import r2LoadGif from '@app/assets/R2Loading.gif'
import { Select, Button, Result, Row, Col } from 'antd';
import Project from '@app/stores/Project';

const { Option } = Select;

interface DependentPlotProps {
  explain: DependenceExplain,
  predictOptions: Array<string>,
  project: Project,
  onExplain: (option: DPDExplainOption) => Promise<DependenceExplain>,
  refreshExplain: (id: number) => Promise<DependenceExplain>,
  onUpdateExplain?: (exp: DependenceExplain) => any,
}

export const DependentPlots: React.FC<DependentPlotProps> = ({
  explain,
  predictOptions,
  project,
  onExplain,
  refreshExplain,
  onUpdateExplain = () => {},
}) => {
  const { run: sync, cancel: cancelSync } = useRequest((id: number) => refreshExplain(id).then((res) => {
    onUpdateExplain(res);
  }), {
    pollingInterval: 5000,
    manual: true,
  });

  useEffect(() => {
    const func = (explain.errorMsg || !explain.explaining) ? cancelSync : () => sync(explain.id);
    func();
    
    return cancelSync;
  }, [explain.id, explain.errorMsg, explain.explaining])

  const newExplain = (option: DPDExplainOption) => {
    cancelSync();
    onExplain(option).then((res) => {
      onUpdateExplain(res)
    });
  }


  const renderOption = (text: string) => {
    return (
      <DependentOptionForm
        predictOptions={predictOptions.filter(option => option !== explain.plotData.option.target)}
        target={explain.plotData.option.target}
        onFinish={values => newExplain({ featureLabel: values.featureLabel, target: values.target })}
        initialValues={explain.plotData.option}
      >
        <Button type='primary' htmlType='submit'>{text}</Button>
      </DependentOptionForm>
    )
  }

  if (explain.explaining) {
    return (
      <Row>
        {/* <Col span={8}>
          {renderOption('等待时间过长？重新解释')}
        </Col> */}
        <Col span={16} style={{ minHeight: 300 }}>
          <Result
            icon={(<img src={r2LoadGif} alt="loading" />)}
            title={`正在解释${ExplainViewLabel[explain.viewType]}`}
            // subTitle={project.modelExplainError}
            // extra={renderOption('等待时间过长？重新解释')}
          />
        </Col>
      </Row>
    )
  }
  if (explain.errorMsg || !explain.plotData.data) {
    return (
      <Row>
        <Col span={8}>
          {renderOption('重新解释')}
        </Col>
        <Col span={16} style={{ minHeight: 300 }}>
          <Result
            status="error"
            title={`解释${ExplainViewLabel[explain.viewType]}失败`}
            subTitle={explain.errorMsg}
            // extra={renderOption('重新解释')}
          />
      </Col>
    </Row>
    )
  }
  return (
    <Row>
      <Col span={12}>
        {renderOption('PDP')}
      </Col>
      <Col span={16} style={{ minHeight: 300 }}>
        {explain.modelId !== 0 && <MIBar message={explain.plotData.data as any} project={project} />}
        {explain.modelId === 0 && <DependentPlotsChart
          data={explain.plotData.data.filter(d => d.y === 1).map(d => ({ key: d.x.toString(), freq: d.freq }))}
        />}
      </Col>
    </Row>
  );
}
