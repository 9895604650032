import EN_LAN from './en_lan'
import ZH_LAN from './zh_lan'
import moment from 'moment'

class LAN {
  constructor() {
    this.update()
  }

  update() {
    const lang = !!(window as any).r2_env.isEN ? 'en' : 'zh-cn'
    moment.locale(lang)
    localStorage.isEN = (window as any).r2_env.isEN
    Object.assign(this, !!(window as any).r2_env.isEN ? EN_LAN : ZH_LAN)
  }

}

const lan = new LAN()

const changeEN = () => {
  lan.update()
}

export default lan as any

export {
  changeEN
}
