import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Form, Input, Button, Checkbox, Slider, Select, FormProps
} from 'antd';

const { Option } = Select;

interface Props extends FormProps {
}


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 4,
    },
  },
};


export const TreeOptionForm: React.FunctionComponent<Props> = observer(({
  children,
  ...formProps
}) => {

  return (
    <Form
      {...formItemLayout}
      {...formProps}
    >
      <Form.Item
        label="crit"
        name="crit"
      >
        <Select>
          <Option key='gini' value='gini'>gini</Option>
          <Option key='entropy' value='entropy'>entropy</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="split"
        name="split"
      >
        <Select>
          <Option key='best' value='best'>best</Option>
          <Option key='random' value='random'>random</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="depth"
        name="depth"
      >
        <Select>
          <Option key={1} value={1}>1</Option>
          <Option key={2} value={2}>2</Option>
          <Option key={3} value={3}>3</Option>
          <Option key={4} value={4}>4</Option>
          <Option key={5} value={5}>5</Option>
          <Option key={6} value={6}>6</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="min_split"
        name="min_split"
      >
        <Slider max={1} min={0.1} step={0.1} />
      </Form.Item>

      <Form.Item
        label="min_leaf"
        name="min_leaf"
      >
        <Slider max={0.5} min={0} step={0.01} />
      </Form.Item>


      <Form.Item  {...tailFormItemLayout} style={{ marginBottom: 0 }}>
        {children}
      </Form.Item>
    </Form>
  );
});

export default TreeOptionForm;
