import React, { ChangeEvent, useContext, useState } from 'react';
import styles from './styles.module.css';
import { observer, MobXProviderContext } from 'mobx-react';
import ContinueButton from 'components/Common/ContinueButton';
import { Input, message, Modal, Select, Spin } from 'antd';
import EN from '../../constant/en';
import { Show } from 'components/Common';
import moment from 'moment';
import { ProjectStore } from 'stores/ProjectStore';
import { DeploymentStore } from 'stores/DeploymentStore';
import { useRequest } from 'ahooks';
import { HttpRequestRes, ProjectRolesKey } from '@app/constant/types';
import { request } from '../../utils';
import { ProjectTab } from './types';

const { TextArea } = Input;

function Project() {
  // @ts-ignore
  const {
    projectStore: { project },
    deploymentStore,
  }: {
    projectStore: ProjectStore;
    deploymentStore: DeploymentStore;
  } = useContext(MobXProviderContext);

  if (!project) return null;
  const [name, setName] = useState(project?.name?.trim() || '');
  const [loading, upLoading] = useState(false);

  const nextStep = async () => {
    if (name && !(/^[a-zA-Z0-9\_\u4e00-\u9fa5]+$/.test(name))) {
      return message.error(EN.ProjectNameRoleError, 2)
    }
    upLoading(true);
    const _name: string = name || moment().format('YYYY/MM/DD HH:mm:SS');

    const hadName = await project.checkPName(_name);
    if (hadName) {
      upLoading(false);
      return Modal.error({
        title: '保存失败!',
        content: EN.ProjectNameNoD,
      });
      // return message.error(EN.ProjectNameNoD)
    }
    const deployment = deploymentStore.deployments.find(
      (d: any) => project.id === d.projectId,
    );
    if (deployment) deploymentStore.change(deployment.id, 'projectName', _name);
    return project.updateProject({
      name: _name,
      tabName: project.tabName,
      business: project.business,
      statement: project.statement,
      ...project.nextMainStep(1),
    });
  };

  const onChange = (type: string) => (e: ChangeEvent<HTMLTextAreaElement>) => {
    project[type] = e.target.value;
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onTagSelect = (value: string) => {
    project.tabName = value || null;
  };
  const allTabRequest = useRequest(
    () =>
      request
        .get<HttpRequestRes<ProjectTab>>('/api-v2/project-tab')
        .then(res => res.data.data),
    {
      cacheKey: 'project-tab',
      staleTime: -1,
    },
  );

  return (
    <Spin tip={EN.Loading} size="large" spinning={loading}>
      <div className={styles.project}>
        <div className={styles.row}>
          <label>{EN.ProjectName}</label>
          <Input
            placeholder={EN.ProjectN}
            value={name}
            onChange={onChangeName}
          />
        </div>
        <div className={styles.sep} />
        <label>{EN.ProjectTab}</label>
        <Select
          placeholder={EN.ProjectTab}
          showArrow
          allowClear
          value={project?.tabName}
          onChange={value => onTagSelect(value)}
          options={allTabRequest.data?.map(t => ({
            label: t.name,
            value: t.name,
          }))}
        />
        <div className={styles.textBox}>
          <label>{EN.ProblemStatement}</label>
          <TextArea
            defaultValue={project ? project.statement : ''}
            className={styles.textarea}
            onChange={onChange('statement')}
            rows={4}
            placeholder={` ${EN.Problemstatement}${EN.Predictimportantcustomers} `}
          />
        </div>
        <div className={styles.textBox}>
          <label>{EN.BusinessValue}</label>
          <TextArea
            defaultValue={project ? project.business : ''}
            className={styles.textarea}
            onChange={onChange('business')}
            rows={4}
            placeholder={`${EN.Businessvalue}${EN.Thiswillhelpproactively}`}
          />
        </div>
        <Show name={ProjectRolesKey.ProjectContinue}>
          <ContinueButton
            width={null}
            onClick={nextStep}
            disabled={loading}
            text={EN.Continue}
          />
        </Show>
      </div>
    </Spin>
  );
}

export default observer(Project);
