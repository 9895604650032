import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import moment from 'moment';
import styles from './styles.module.css';
import { Modal, DatePicker, TimePicker, Select, InputNumber } from 'antd';
import classnames from 'classnames';
import EN from '../../../constant/en';
const { Option } = Select;

const bound = 10000;

const ordinalNumberPostFix = number => {
  if ((number > 3 && number < 21) || number % 10 > 3) return 'th';
  return { 0: 'th', 1: 'st', 2: 'nd', 3: 'rd' }[number % 10];
};

interface Interface{
  visible:boolean
  onClose:any
  onSubmit:any
  options:Object
}

function AutoRepeat(props:Interface){
  const { visible, onClose, onSubmit,options } = props;
  const store = useLocalStore(()=>({
    repeatPeriod: 'week',
    repeatFrequency: 1,
    repeatOn: 1,
    starts: moment().unix(),
    ends: 'never' as any,
    ...options,
    updateField(data){
      Object.assign(this,data)
    }
  }))

  const getStartsTime = (value) => {
    let dayValue = value;
    const now = new Date();
    const currentDay = now.getDate();
    const currentOfTheWeek = now.getDay() + 1; // 本系统1-7定义的周日-周六。所以+1
    const currentHH = now.getHours();
    const currentmm = now.getMinutes();
    const startYear = now.getFullYear();
    let startMonth = now.getMonth();
    const startHH = moment.unix(store.starts).format('HH');
    const startmm = moment.unix(store.starts).format('mm');
    let startDay;
    if (store.repeatPeriod === 'week') {
      if ((dayValue < currentOfTheWeek) // 本星期触发日已过
        || (dayValue === currentOfTheWeek && moment(`${startHH}:${startmm}`,'HH:mm') <= moment(`${currentHH}:${currentmm}`, 'HH:mm'))) { // 或者当日。但时间已过
        // 触发日期 = 当前日期 + 下一周(+7)触发日期之间的间隔天数
        startDay = currentDay + (7 + dayValue - currentOfTheWeek);
      } else {
        // 触发日期 = 当前日期 + 本周触发日期之间的间隔天数
        startDay = currentDay + (dayValue - currentOfTheWeek);
      }
    } else if (store.repeatPeriod === 'month') {
      if (value && value > 28) {
        dayValue = 28;
      } else if (value && value < 1) {
        dayValue = 1;
      }
      if ((dayValue < currentDay) // 当月部署日期已过,
      || (dayValue === currentDay && moment(`${startHH}:${startmm}`,'HH:mm') <= moment(`${currentHH}:${currentmm}`, 'HH:mm'))) { // 或者当天时间已过
        // 本月日期已过，月数+1
        startMonth += 1
      }
      startDay = dayValue;
    }
    return {
      starts: moment((new Date(startYear, startMonth, startDay, +startHH, +startmm))).unix(),
      repeatOn: dayValue,
    };
  }

  const changeState = (key, value) => {
    let updateParams: any = { [key]: value };
    if (key === 'repeatOn') {
      updateParams = getStartsTime(value);
    }
    return store.updateField(updateParams)
  };
  const w = k => v => changeState(k, v);
  const c = (k, v) => () => changeState(k, v);
  // const t = k => event => changeState(k, event.target.value);
  const numberChange = k => v => changeState(k, parseInt(v, 10) || 0);

  useEffect(()=>{
    store.updateField(options)
  },[options])

    const max = { day: 365, week: 52, month: 12 }[store.repeatPeriod];
    return (
      <Modal
        className={styles.modal}
        closable={false}
        visible={visible}
        footer={null}
      >
        <div className={styles.title}>{EN.AutoRepeatSettings}</div>
        <div className={styles.line}>
          <span className={styles.label}>{EN.Repeatevery}</span>
          <div className={styles.options}>
            <InputNumber
              min={1}
              max={max}
              formatter={(v:string) => String(isNaN(parseInt(v, 10)) ? 1 : parseInt(v, 10))}
              value={store.repeatFrequency}
              onChange={numberChange('repeatFrequency')}
            />
            <div className={styles.vgap} />
            <Select
              value={store.repeatPeriod}
              onChange={w('repeatPeriod')}
            >
              <Option value="day">{EN.Day}</Option>
              <Option value="week">{EN.Week}</Option>
              <Option value="month">{EN.Month}</Option>
            </Select>
          </div>
        </div>
        {store.repeatPeriod === 'week' && (
          <div className={styles.line}>
            <span className={styles.label}>{EN.RepeatOn}</span>
            <div className={styles.options}>
              <div
                className={classnames(styles.day, {
                  [styles.active]: store.repeatOn === 1
                })}
                onClick={changeState.bind(this, 'repeatOn', 1)}
              >
                {EN.S}
              </div>
              <div
                className={classnames(styles.day, {
                  [styles.active]: store.repeatOn === 2
                })}
                onClick={changeState.bind(this, 'repeatOn', 2)}
              >
                {EN.M}
              </div>
              <div
                className={classnames(styles.day, {
                  [styles.active]: store.repeatOn === 3
                })}
                onClick={changeState.bind(this, 'repeatOn', 3)}
              >
                {EN.T}
              </div>
              <div
                className={classnames(styles.day, {
                  [styles.active]: store.repeatOn === 4
                })}
                onClick={changeState.bind(this, 'repeatOn', 4)}
              >
                {EN.W}
              </div>
              <div
                className={classnames(styles.day, {
                  [styles.active]: store.repeatOn === 5
                })}
                onClick={changeState.bind(this, 'repeatOn', 5)}
              >
                {EN.TH}
              </div>
              <div
                className={classnames(styles.day, {
                  [styles.active]: store.repeatOn === 6
                })}
                onClick={changeState.bind(this, 'repeatOn', 6)}
              >
                {EN.F}
              </div>
              <div
                className={classnames(styles.day, {
                  [styles.active]: store.repeatOn === 7
                })}
                onClick={changeState.bind(this, 'repeatOn', 7)}
              >
                {EN.SA}
              </div>
            </div>
          </div>
        )}

        {store.repeatPeriod === 'month' && (
          <div className={styles.line}>
            <span className={styles.label}>{EN.RepeatOn}</span>
            <div className={styles.options}>
              <InputNumber
                min={1}
                max={28}
                formatter={(v:string) => String((isNaN(parseInt(v, 10)) ? 1 : parseInt(v, 10)))}
                value={store.repeatOn}
                onChange={numberChange('repeatOn')}
              />
              <span className={styles.text}>
                {ordinalNumberPostFix(store.repeatOn)}
              </span>
            </div>
          </div>
        )}

        <div className={styles.line}>
          <span className={styles.label}>{EN.Starts}</span>
          <div className={styles.options}>
            {/* <i
              className={classnames(styles.pot, {
                [styles.active]: state.starts !== 'completed'
              })}
              onClick={this.c('starts', moment().unix())}
            /> */}
            <TimePicker
              use12Hours
              format="h:mma"
              value={moment.unix(store.starts)}
              placeholder={EN.SelectTime}
              onChange={date => changeState('starts', date.unix())}
            />
          </div>
        </div>
        {/* <div className={styles.line}>
          <span className={styles.label} />
          <div
            className={styles.options}
            onClick={this.c('starts', 'completed')}
          >
            <i
              className={classnames([styles.pot], {
                [styles.active]: state.starts === 'completed'
              })}
            />
            <span className={styles.text}>Start after settings completed</span>
          </div>
        </div> */}
        <div className={styles.line}>
          <span className={styles.label}>{EN.Ends}</span>
          <div className={styles.options} onClick={changeState.bind(this,'ends', 'never')}>
            <i
              className={classnames([styles.pot], {
                [styles.active]: store.ends === 'never'
              })}
            />
            <span className={styles.text}>{EN.Never}</span>
          </div>
        </div>
        <div className={styles.line}>
          <span className={styles.label} />
          <div className={styles.options}>
            <i
              className={classnames([styles.pot], {
                [styles.active]: store.ends !== 'never' && store.ends > bound
              })}
              onClick={changeState.bind(this, 'ends', moment().unix())}
            />
            <span className={styles.text}>{EN.On}</span>
            <div className={styles.vgap} />
            <DatePicker
              showTime={{
                use12Hours: true,
                format: 'h:mma'
              }}
              value={
                store.ends === 'never' || store.ends < bound
                  ? null
                  : moment.unix(store.ends)
              }
              format="MM/DD/YYYY h:mma"
              placeholder={EN.SelectTime}
              onChange={date => changeState('ends', date.unix())}
            />
          </div>
        </div>
        <div className={styles.line}>
          <span className={styles.label} />
          <div
            className={styles.options}
            onClick={changeState.bind(this, 'ends', store.ends < bound ? store.ends : 1)}
          >
            <i
              className={classnames([styles.pot], {
                [styles.active]: store.ends < bound
              })}
            />
            <span className={styles.text}>{EN.After}</span>
            <div className={styles.vgap} />
            <InputNumber
              min={1}
              max={9999}
              value={store.ends < bound ? store.ends : 1}
              formatter={(v:string) => String(isNaN(parseInt(v, 10)) ? 1 : parseInt(v, 10))}
              onChange={numberChange('ends')}
            />
            <div className={styles.vgap} />
            <span className={styles.text}>{EN.Occurrences}</span>
          </div>
        </div>
        <div className={styles.btns}>
          <a className={styles.cancel} onClick={onClose}>
            {EN.CANCEL}
          </a>
          <a className={styles.done} onClick={() => onSubmit(store)}>
            {EN.DONE}
          </a>
        </div>
      </Modal>
    );
}

export default observer(AutoRepeat)
