import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { EChartOption } from 'echarts';
import _ from 'lodash';

interface IProps {
  featureImportance: Record<string, number>;
}

export const FtImportance: React.FC<IProps> = ({ featureImportance }) => {
  const arr = Object.entries(featureImportance || {}).sort(
    (a: any, b: any) => a[1] - b[1]
  );

  const y_names = arr.map(i => i[0])
  const imports = arr.map(i => i[1])

  const option: EChartOption = {
    title: {
      text: 'Infomation Value Summary',
      left: 'center',
    },
    legend: {},
    tooltip: {},
    grid: {
      right: '20%',
      left: '20%',
    },
    xAxis: {
      show: true,
    },
    yAxis: {
      type: 'category',
      data: y_names,
      gridIndex: 0,
      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    visualMap: [{
      show: true,
      dimension: 0,
      top: 'center',
      right: 0,
      type: 'piecewise',
      pieces: [
        { min: 0.0, max: 0.2, color: '#bad3eb', label: 'Very Weak', },
        { min: 0.2, max: 0.4, color: '#8dbeda', label: 'Weak', },
        { min: 0.4, max: 0.6, color: '#579fcc', label: 'Average', },
        { min: 0.6, max: 0.8, color: '#2b6eaf', label: 'Strong', },
        { min: 0.8, max: 1, color: '#133f8a', label: 'Very Strong', },
      ],
    }],
    series: [{
      type: 'bar',
      data: imports,
      label: {
        show: true,
        position: 'left',
        formatter: '{b}',
        color: 'black',
        distance: 32,
      },
    }]
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: Math.max(80 + y_names.length * 24, 300), width: '100%' }}
      notMerge={true}
      lazyUpdate={true}
      theme="customed"
    />
  );
};
