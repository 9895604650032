import React, { ReactElement, useContext, useMemo } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import classnames from 'classnames';
import EN from '../../../constant/en';
import mockAvatar from '../Sider/mr-one-copy.svg';
import {
  LogoutOutlined,
  SettingOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import down from './combined-shape-copy.svg';
import ChangePasswdModal from './ChangePasswdModal';

function NormalHeader(): ReactElement {
  const {
    deploymentStore: { deployments },
    scheduleStore,
    userStore,
  } = useContext(MobXProviderContext);
  const history = useHistory();

  // const totalProject = useMemo(()=>{
  //     return sortedIncrementProjects.length + deployments.filter(itm=>!["Classification","Regression"].includes(itm.modelType)).length
  // },[sortedIncrementProjects,deployments])

  // const enabledProjectsCount = useMemo(()=>{
  //   return deployments.filter(_d => _d.enable).length;
  // },[deployments]);

  const normalProjectsCount = useMemo(() => {
    const isNormal = id => {
      return ['finished', 'issue'].includes(
        scheduleStore.getAllLastSchedule(id)?.status,
      );
    };
    return deployments.filter(_d => {
      return isNormal(_d.id);
    }).length;
  }, [deployments, scheduleStore.schedules?.length]);

  const issueProjectsCount = useMemo(() => {
    const isIssue = id => {
      // return scheduleStore.getLastSchedule(id, 'deployment')?.status === 'issue' &&
      // scheduleStore.getLastSchedule(id, 'performance')?.status === 'issue';
      return scheduleStore.getAllLastSchedule(id)?.status === 'issue';
    };
    return deployments.filter(_d => {
      return isIssue(_d.id);
    }).length;
  }, [deployments, scheduleStore.schedules?.length]);

  // const history = useHistory();
  //
  // const changepassword = () => {
  //   // history.push('/changepassword');
  //   location.href = '/changepassword'
  // };

  const menu = (
    <Menu className={styles.logout}>
      {/* <Menu.Item key="0">
          <a onClick={changepassword}><Icon type='unlock' />{EN.ChangePassword}</a>
        </Menu.Item> */}
      <Menu.Item key="1">
        <a onClick={() => userStore.logout()}>
          <LogoutOutlined />
          {EN.LogOut}
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a onClick={() => userStore.goManagement()}>
          <SettingOutlined />
          {EN.Management}
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a
          onClick={() => {
            userStore.changePasswdModalVisible(true);
          }}
        >
          <KeyOutlined />
          {EN.ChangePasswd}
        </a>
      </Menu.Item>
    </Menu>
  );
  const { from } = userStore.info;

  return (
    <div className={styles.header} onClick={() => history.push('/deploy')}>
      <ChangePasswdModal />
      <div className={classnames(styles.block, styles.total)}>
        <span className={styles.number}>{deployments.length}</span>
        <span className={styles.text}>{EN.TotalProject}</span>
      </div>
      {/*<div className={classnames(styles.block, styles.enabled)}>*/}
      {/*  <span className={styles.number}>{enabledProjectsCount}</span>*/}
      {/*  <span className={styles.text}>{EN.Enabled}</span>*/}
      {/*</div>*/}
      {/*<div className={classnames(styles.block, styles.disabled)}>*/}
      {/*  <span className={styles.number}>*/}
      {/*    {deployments.length - enabledProjectsCount}*/}
      {/*  </span>*/}
      {/*  <span className={styles.text}>{EN.Disabled}</span>*/}
      {/*</div>*/}
      {/*<div className={styles.gap} />*/}
      <div className={classnames(styles.block, styles.normal)}>
        <span className={styles.number}>{normalProjectsCount}</span>
        <span className={styles.text}>{EN.Deployed}</span>
      </div>
      <div className={classnames(styles.block, styles.issue)}>
        <span className={styles.number}>{issueProjectsCount}</span>
        <span className={styles.text}>{EN.Issue}</span>
      </div>
      <div className={styles.empty} />
      <div className={styles.user}>
        <img src={mockAvatar} alt="avatar" className={styles.avatar} />
        <div className={styles.userBottom}>
          <span className={styles.name}>{userStore.info.email}</span>
          {!from && (
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomCenter"
            >
              <div className={styles.down}>
                <img src={down} alt="down" />
              </div>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(NormalHeader);
