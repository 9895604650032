import { observer } from 'mobx-react';
import React, { ReactElement, useContext, useState } from 'react';
import { MobXProviderContext } from 'mobx-react';
import styles from './styles.module.css';
import moment from 'moment';
import EN from '../../../constant/en';
import {  Popover } from 'antd';
import { Modal } from '../../Common';
import Summary from '../Summary';
import { SocketStore } from 'stores/SocketStore';
import classnames from 'classnames'
import alertIcon from './fail.svg';

const Alert = ({ text }) => (
  <div className={styles.alert}>
    <div className={styles.alertHead}>
      <img className={styles.alertIcon} src={alertIcon} alt="alert" />
    </div>
    <div className={styles.alertContent}>
      {text}
    </div>
  </div>
);

interface Interface {
  schedule:any
  cddo:any,
  bigData: boolean
}

function ScheduleOperation(props:Interface): ReactElement {
  const { cddo, bigData } = props;
  // const {
  //   socketStore,
  // }: {
  //   socketStore: SocketStore;
  // } = useContext(MobXProviderContext);
  const [visible, upVisible] = useState(false);
  // const [loading, upLoading] = useState(false);
  // const [finished, upFinished] = useState(false);
  // const [summary, upSummary] = useState({});

  const showSummary = () => {
    // if (visible) return;
    // if (finished) return upVisible(true);
    // upLoading(true);
    upVisible(true);
    // socketStore
    //   .ready()
    //   .then(api =>
    //     api.getScheduleSummary({
    //       sid: schedule.schedule.id,
    //       pid: schedule.deployment.projectId,
    //       problemType: schedule.deployment.modelType,
    //     }),
    //   )
    //   .then(result => {
    //     upSummary({
    //       ...result.result,
    //       mapHeader: schedule.deployment.mapHeader,
    //       target: [],
    //     });
    //     upLoading(false);
    //     upFinished(true);
    //   });
  };

  const onClose = () => {
    upVisible(false);
  };

  const { schedule: s } = props;
  return (
    <div className={styles.project} key={s.schedule.id}>
      <span className={styles.modelName} title={s.schedule.name}>
        {s.schedule.name}
      </span>
      <span className={styles.deploymentTime}>
        {isNaN(s.schedule.actualTime || s.schedule.estimatedTime)
          ? s.schedule.actualTime || s.schedule.estimatedTime
          : moment
              .unix(s.schedule.actualTime || s.schedule.estimatedTime)
              .format('MM/DD/YYYY-hh:mma')}
      </span>
      <span className={styles.deploymentStyle}>
        {EN.Predictwith} {EN.DataSource}
        <br />
        <span title={s.schedule.file}>{s.schedule.file}</span>
      </span>
      <span className={styles.executionSpeed}>
        {s.schedule.status === 'finished'
          ? s.schedule.result && s.schedule.result.executeSpeed
          : ' - '}
      </span>
      <span className={styles.dataSize}>
        {s.schedule.status === 'finished'
          ? s.schedule.result && s.schedule.result.totalLines
          : ' - '}
      </span>
      {s.schedule.status !== 'issue' && (
        <span className={styles.status}>
          {s.schedule.status[0].toUpperCase() +
            s.schedule.status.substr(1, s.schedule.status.lenght)}
        </span>
      )}
      {s.schedule.status === 'issue' && (
        <Popover
          placement="left"
          overlayClassName={styles.popover}
          content={<Alert text={s.schedule.result['processError']} />}
        >
          <span className={classnames(styles.status, styles.issue)}>
            {EN.Issue}
          </span>
        </Popover>
      )}
      {s.schedule.status === 'finished' && s.schedule.location !== 'database' && s.schedule.result ? (
        <a
          className={styles.results}
          target="_blank"
          href={`/r2upload/download/${s.schedule.id}?filename=${
            typeof cddo.file === 'string'
              ? cddo.file
              : cddo.sourceOptions.databaseType
          }-${moment
            .unix(s.schedule.actualTime || s.schedule.estimatedTime)
            .format('MM-DD-YYYY_hh-mm')}-predict.csv`}
        >
          {EN.Download}
        </a>
      ) : (
        <span className={styles.emptyResults}> - </span>
      )}
      {s.schedule.result?.dataReport?.dataView && s.schedule.status === 'finished' ? (
        <a onClick={showSummary} className={styles.results}>
          <span className={styles.status}>{EN.DeploySummaryData}</span>
        </a>
      ) : (
        <span className={styles.emptyResults}> - </span>
      )}
      {s.schedule.status === 'finished'&&
        <Modal
          content={<Summary summary={{...s.schedule.result.dataReport, mapHeader: s.schedule.mapHeader || s.deployment.mapHeader, target: [] }} onClose={onClose} />}
          visible={visible}
          width="12em"
          title={EN.DeploySummary}
          onClose={onClose}
          closeByMask={true}
          showClose={true}
        />
      }
    </div>
  );
}
export default observer(ScheduleOperation);
