import React, { Component } from 'react';
import styles from '../styles.module.css';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Modal, NumberInput } from 'components/Common';
import { observable } from 'mobx'
import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { formatNumber } from '../../../../util'
import EN from '../../../../constant/en';
import { OutlierRange } from "components/Charts";
import Project from 'stores/Project';
const { Option } = Select;

interface FixIssueProps {
  saveDataFixes: () => void,
  closeFixes: () => void,
  project: Project,
  isTarget: boolean,
  nullCount: number,
  mismatchCount: number,
  outlierCount: number,
  issueRow: { mismatchRow: NumberObject, nullRow: NumberObject, outlierRow: NumberObject }
}

class FixIssue extends Component<FixIssueProps> {
  @observable editKey = ''
  @observable visible = false
  @observable progress = 0
  @observable fillMethod = { missing: { ...this.props.project.nullFillMethodTemp }, mismatch: { ...this.props.project.mismatchFillMethodTemp }, outlier: { ...this.props.project.outlierFillMethodTemp } };
  @observable checked = { null: [], mismatch: [], outlier: [] }
  @observable outLierDict = { ...this.props.project.outlierDictTemp };

  editRange(key) {
    this.editKey = key;
    this.visible = true;
  };

  closeEdit = () => {
    this.visible = false
    this.editKey = ''
  }

  saveEdit = (data) => {
    const { editKey } = this;
    this.outLierDict[editKey] = data
    this.visible = false
    this.editKey = ''
  }

  nullSelect = (key, e) => {
    let value = e.target.value
    value = isNaN(+(value)) ? value : parseFloat(value)
    const { missing } = this.fillMethod
    missing[key] = value === 'others' ? '' : value
    this.fillMethod.missing = { ...missing }
  }

  mismatchSelect = (key, e) => {
    let value = e.target.value
    value = isNaN(+(value)) ? value : parseFloat(value)
    const { mismatch } = this.fillMethod
    mismatch[key] = value === 'others' ? '' : value
    this.fillMethod.mismatch = { ...mismatch }
  }

  outlierSelect = (key, e) => {
    let value = e.target.value
    value = isNaN(+(value)) ? value : parseFloat(value)
    const { outlier } = this.fillMethod
    outlier[key] = value === 'others' ? '' : value
    this.fillMethod.outlier = { ...outlier }
  }

  save = () => {
    const { project } = this.props
    const realFillMethod: { missing?: { [key: string]: string }, mismatch?: { [key: string]: string }, outlier?: { [key: string]: string } } = {}
    const deleteColumns: Set<string> = new Set<string>()
    const dataHeader: Set<string> = new Set<string>([...project.dataHeader, ...project.deleteColumns])
    Object.keys(this.fillMethod).forEach(k => {
      realFillMethod[k] = {}
      Object.keys(this.fillMethod[k]).forEach(field => {
        const value = this.fillMethod[k][field]
        if (value === 'column') deleteColumns.add(field)
        if (value === 0 || !!value) realFillMethod[k][field] = value
      })
    })
    project.dataHeader = [...dataHeader]
    project.deleteColumns = [...deleteColumns]
    project.nullFillMethodTemp = { ...realFillMethod.missing }
    project.mismatchFillMethodTemp = { ...realFillMethod.mismatch }
    project.outlierFillMethodTemp = { ...realFillMethod.outlier }
    project.outlierDictTemp = { ...this.outLierDict }
    this.props.saveDataFixes()
  }

  formatCell = num => {
    if (typeof num === "number") return formatNumber(num.toString(), 2, true)
    if (typeof num === "string") return num
    return "N/A"
  }

  reasonSelect = (key, e) => {
    const value = e.target.value
    const { missingReasonTemp, colType } = this.props.project
    let defaultValue = colType[key] === 'Numerical' ? 'mean' : 'mode'
    if (colType[key] !== 'Numerical' && value === 'blank') {
      defaultValue = 'ignore'
    }
    missingReasonTemp[key] = value
    this.fillMethod.missing[key] = defaultValue
    this.props.project.missingReasonTemp = { ...missingReasonTemp }
    this.fillMethod.missing = { ...this.fillMethod.missing }
  }

  handleInput = (key, field, value) => {
    this.fillMethod[key][field] = value
  }

  handleCheckAll = (type) => (e) => {
    const checked = e.target.checked
    if (!checked) return this.checked = { ...this.checked, [type]: [] }
    const { issueRow } = this.props
    // const { target, targetIssuesCountsOrigin, variableIssues } = project
    const dataRow = issueRow[`${type}Row`]
    this.checked = { ...this.checked, [type]: Object.keys(dataRow) }
  }

  handleCheck = (type, field) => (e) => {
    const checked = e.target.checked
    let arr = this.checked[type]
    if (!checked && arr.includes(field)) arr = arr.filter(h => h !== field)
    if (checked && !arr.includes(field)) arr.push(field)
    this.checked = { ...this.checked, [type]: arr }
  }

  handleReset = (type) => () => {
    const { project, issueRow } = this.props
    const { colType, missingReasonTemp, rawDataView } = project
    const dataRow = issueRow[`${type}Row`]
    Object.keys(dataRow).forEach(k => {
      const isNum = colType[k] === 'Numerical';
      const mode = !rawDataView ? 'N/A' : (isNum ? 'N/A' : rawDataView[k].mode)
      const mean: number | string = !rawDataView ? 'N/A' : (isNum ? rawDataView[k].mean : 'N/A')
      const median = !rawDataView ? 'N/A' : (isNum ? rawDataView[k].median : 'N/A')
      if (type === 'mismatch') { // 数据类型错误
        if (isNum) { // 连续变量
          if (mean === 'inf' || mean === 'Infinity') {
            return this.fillMethod[type][k] = 'median';
          }
          return this.fillMethod[type][k] = 'mean';
        }
        // 离散变量
        return this.fillMethod[type][k] = 'mode';
      }
      if (type === 'null') { // 缺失值
        missingReasonTemp[k] = 'none'
        if (isNum) { // 连续变量
          if (mean === 'inf' || mean === 'Infinity') {
            return this.fillMethod.missing[k] = 'median';
          }
          return this.fillMethod.missing[k] = 'mean';
        }
        // 离散变量
        return this.fillMethod.missing[k] = 'mode';
      }
      // 异常值
      return this.fillMethod[type][k] = 'drop';
    })
  }

  handleSelect = (type, isNum = true) => (value) => {
    const { project } = this.props
    const { colType } = project
    if (type === 'null') {
      this.checked.null
        .filter(k => isNum ? colType[k] === 'Numerical' : colType[k] !== 'Numerical')
        .forEach(k => {
          this.fillMethod.missing = { ...this.fillMethod.missing, [k]: value }
        })
    } else {
      this.checked[type].forEach(k => {
        this.fillMethod[type] = { ...this.fillMethod[type], [k]: value }
      })
    }
  }

  render() {
    const { closeFixes, project, isTarget, nullCount, mismatchCount, outlierCount, issueRow: { mismatchRow, nullRow, outlierRow } } = this.props;
    const { mapHeader, colType, rawDataView, missingReasonTemp, dataHeader, deleteColumns, nullLineCounts, mismatchLineCounts, outlierLineCounts, outlierLoading } = project

    let nullNum = 0
    let nullStr = 0
    Object.keys(nullRow).forEach(h => {
      if (colType[h] === 'Numerical') nullNum++
      else nullStr++
    })

    const strArray = [{
      value: 'mode',
      label: EN.Replacewithmostfrequentvalue
    }, {
      value: 'drop',
      label: EN.Deletetherows
    }, {
      value: 'column',
      label: EN.Deletethecolumn
    }, {
      value: 'ignore',
      label: EN.Replacewithauniquevalue
    }]

    const numArray = [{
      value: 'mean',
      label: EN.Replacewithmeanvalue
    }, {
      value: 'drop',
      label: EN.Deletetherows
    }, {
      value: 'column',
      label: EN.Deletethecolumn
    }, {
      value: 'min',
      label: EN.Replacewithminvalue
    }, {
      value: 'max',
      label: EN.Replacewithmaxvalue
    }, {
      value: 'median',
      label: EN.Replacewithmedianvalue
    }, {
      value: 'zero',
      label: EN.ReplaceWith0
    }, {
      value: 'others',
      label: EN.Replacewithothers
    }]

    const outArray = [{
      value: 'ignore',
      label: EN.DoNothing
    }, {
      value: 'drop',
      label: EN.Deletetherows
    }, {
      value: 'mean',
      label: EN.Replacewithmeanvalue
    }, {
      value: 'median',
      label: EN.Replacewithmedianvalue
    }, {
      value: 'zero',
      label: EN.ReplaceWith0
    }, {
      value: 'respective',
      label: EN.ReplaceRespective
    }, {
      value: 'others',
      label: EN.Replacewithothers
    }]

    const timeArray = [{
      value: 'ignore',
      label: EN.DoNothing
    }, {
      value: 'drop',
      label: EN.Deletetherows
    }, {
      value: 'mean',
      label: EN.Replacewithmeanvalue
    }, {
      value: 'median',
      label: EN.Replacewithmedianvalue
    }, {
      value: 'zero',
      label: EN.ReplaceWith0
    }, {
      value: 'respective',
      label: EN.ReplaceRespective
    }, {
      value: 'others',
      label: EN.Replacewithothers
    }]

    return (
      <div className={styles.fixesContent}>
        <div className={styles.fixesBlock}>
          {!!mismatchCount && <div className={styles.fixesArea}>
            <div className={styles.typeBox}>
              <div className={styles.type}>
                <div className={classnames(styles.typeBlock, styles.mismatch)} />
                <span>{EN.DataTypeMismatch}</span>
              </div>
              {Object.keys(mismatchRow).length > 1 && <div className={styles.batch}>
                <Select placeholder={EN.BatchFix} value={undefined} onSelect={this.handleSelect('mismatch')} className={styles.batchSelect} >
                  {numArray.map(item => {
                    if (isTarget && item.value === 'column') return null
                    if (item.value === 'others') return null
                    return <Option value={item.value} key={item.value}>{item.label}</Option>
                  })}
                </Select>
              </div>}
            </div>
            <div className={styles.fixesTable}>
              <div className={classnames(styles.fixesRow, styles.fixesHeader)}>
                {Object.keys(mismatchRow).length > 1 && <div className={styles.fixedCheck}><input type="checkbox" defaultChecked={false} onChange={this.handleCheckAll('mismatch')} /></div>}
                <div className={classnames(styles.fixesTd, styles.fixesLarge)}><span>{EN.VariableName}</span></div>
                <div className={styles.fixesTd}><span>{EN.DataType}</span></div>
                <div className={styles.fixesTd}><span>{EN.QuantityofMismatch}</span></div>
                <div className={styles.fixesTd}><span>{EN.Mean}</span></div>
                <div className={styles.fixesTd}><span>{EN.Median}</span></div>
                <div className={styles.fixesTd}><span>{EN.MostFrequentValue}</span></div>
                <div className={classnames(styles.fixesTd, styles.fixesLarge)}>
                  <span>{EN.Fix}</span>
                  <span className={styles.reset} onClick={this.handleReset('mismatch')}>{EN.Reset}</span>
                </div>
              </div>
              <div className={styles.fixesBody}>
                {Object.keys(mismatchRow).map((k, i) => {
                  const percnet = mismatchRow[k]
                  if (!percnet) return null
                  const showType = colType[k] === 'Raw' ? 'Categorical' : colType[k]
                  const num = mismatchLineCounts[k]
                  if (showType === 'Categorical') return null
                  const rowText = num + ' (' + (percnet === 0 ? 0 : percnet < 0.01 ? '<0.01' : formatNumber(percnet.toString(), 2)) + '%)'
                  const mode = !rawDataView ? 'N/A' : (showType === 'Numerical' ? 'N/A' : rawDataView[k].mode)
                  const mean = !rawDataView ? 'N/A' : (showType === 'Numerical' ? rawDataView[k].mean : 'N/A')
                  const median = !rawDataView ? 'N/A' : (showType === 'Numerical' ? rawDataView[k].median : 'N/A')
                  const method = this.fillMethod.mismatch.hasOwnProperty(k) ?
                    this.fillMethod.mismatch[k] : 'mean'
                  const isOthers = !numArray.find(_a => _a.value === method)
                  return <div className={styles.fixesRow} key={i}>
                    {Object.keys(mismatchRow).length > 1 && <div className={styles.fixedCheck}><input type="checkbox" checked={this.checked.mismatch.includes(k)} onChange={this.handleCheck('mismatch', k)} /></div>}
                    <div className={classnames(styles.fixesCell, styles.fixesLarge)}><span title={mapHeader[k]}>{mapHeader[k]}</span></div>
                    <div className={styles.fixesCell}><span>{EN[showType]}</span></div>
                    <div className={styles.fixesCell}><span title={rowText}>{rowText}</span></div>
                    <div className={styles.fixesCell}><span title={this.formatCell(mean)}>{this.formatCell(mean)}</span></div>
                    <div className={styles.fixesCell}><span title={this.formatCell(median)}>{this.formatCell(median)}</span></div>
                    <div className={styles.fixesCell}><span title={this.formatCell(mode)}>{this.formatCell(mode)}</span></div>
                    <div className={classnames(styles.fixesCell, styles.fixesLarge)}>
                      <select value={isOthers ? 'others' : method} onChange={this.mismatchSelect.bind(null, k)}>
                        {numArray.map(item => {
                          if (isTarget && item.value === 'column') return null
                          return <option value={item.value} key={item.value}>{item.label}</option>
                        })}
                      </select>
                      {isOthers && <NumberInput value={method} onBlur={this.handleInput.bind(null, 'mismatch', k)} />}
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>}
          {!!nullCount && <div className={styles.fixesArea}>
            <div className={styles.typeBox}>
              <div className={styles.type}>
                <div className={classnames(styles.typeBlock, styles.missing)} />
                <span>{EN.MissingValue}</span>
              </div>
              {Object.keys(nullRow).length > 1 && <div className={styles.batch}>
                {nullNum > 0 && <Select placeholder={`${EN.BatchFix}(${EN.Numerical})`} value={undefined} onSelect={this.handleSelect('null', true)} className={styles.batchSelect} >
                  {numArray.map(item => {
                    if (isTarget && item.value === 'column') return null
                    if (item.value === 'others') return null
                    return <Option value={item.value} key={item.value}>{item.label}</Option>
                  })}
                </Select>}
                {nullStr > 0 && <Select placeholder={`${EN.BatchFix}(${EN.Categorical})`} value={undefined} onSelect={this.handleSelect('null', false)} className={styles.batchSelect} >
                  {strArray.map(item => {
                    if (isTarget && item.value === 'column') return null
                    return <Option value={item.value} key={item.value}>{item.label}</Option>
                  })}
                </Select>}
              </div>}
            </div>
            <div className={styles.fixesTable}>
              <div className={classnames(styles.fixesRow, styles.fixesHeader)}>
                {Object.keys(nullRow).length > 1 && <div className={styles.fixedCheck}><input type="checkbox" defaultChecked={false} onChange={this.handleCheckAll('null')} /></div>}
                <div className={styles.fixesTd}><span>{EN.VariableName}</span></div>
                <div className={styles.fixesTd}><span>{EN.MissingReason}</span></div>
                <div className={styles.fixesTd}><span>{EN.Data}</span></div>
                <div className={styles.fixesTd}><span>{EN.QuantityofMissingValue}</span></div>
                <div className={styles.fixesTd}><span>{EN.Mean}</span></div>
                <div className={styles.fixesTd}><span>{EN.Median}</span></div>
                <div className={styles.fixesTd}><span>{EN.MostFrequentValue}</span></div>
                <div className={classnames(styles.fixesTd, styles.fixesLarge)}>
                  <span>{EN.Fix}</span>
                  <span className={styles.reset} onClick={this.handleReset('null')}>{EN.Reset}</span>
                </div>
              </div>
              <div className={styles.fixesBody}>
                {Object.keys(nullRow).map((k, i) => {
                  const percnet = nullRow[k]
                  if (!percnet) return null
                  const num = nullLineCounts[k]
                  const showType = colType[k] === 'Raw' ? 'Categorical' : colType[k]
                  const options = showType === 'Numerical' ? numArray : strArray
                  const rowText = num + ' (' + (percnet === 0 ? 0 : percnet < 0.01 ? '<0.01' : formatNumber(percnet.toString(), 2)) + '%)'
                  const mode = !rawDataView ? 'N/A' : (showType === 'Numerical' ? 'N/A' : rawDataView[k].mode)
                  const mean = !rawDataView ? 'N/A' : (showType === 'Numerical' ? rawDataView[k].mean : 'N/A')
                  const median = !rawDataView ? 'N/A' : (showType === 'Numerical' ? rawDataView[k].median : 'N/A')
                  const method = this.fillMethod.missing.hasOwnProperty(k) ?
                    this.fillMethod.missing[k] : (showType === 'Categorical' ? 'mode' : 'mean')
                  const isOthers = !options.find(_a => _a.value === method)
                  return <div className={styles.fixesRow} key={i}>
                    {Object.keys(nullRow).length > 1 && <div className={styles.fixedCheck}><input type="checkbox" checked={this.checked.null.includes(k)} onChange={this.handleCheck('null', k)} /></div>}
                    <div className={styles.fixesCell}><span title={mapHeader[k]}>{mapHeader[k]}</span></div>
                    <div className={styles.fixesCell}><select value={missingReasonTemp[k]} onChange={this.reasonSelect.bind(null, k)}>
                      <option value='none' key="none">{EN.Idonknow}</option>
                      <option value="blank" key="blank">{EN.Leftblankonpurpose}</option>
                      <option value='fail' key='fail'>{EN.FailedtoCollectorDataError}</option>
                    </select></div>
                    <div className={styles.fixesCell}><span>{EN[showType]}</span></div>
                    <div className={styles.fixesCell}><span title={rowText}>{rowText}</span></div>
                    <div className={styles.fixesCell}><span title={this.formatCell(mean)}>{this.formatCell(mean)}</span></div>
                    <div className={styles.fixesCell}><span title={this.formatCell(median)}>{this.formatCell(median)}</span></div>
                    <div className={styles.fixesCell}><span title={this.formatCell(mode)}>{this.formatCell(mode)}</span></div>
                    <div className={classnames(styles.fixesCell, styles.fixesLarge)}>
                      <select value={isOthers ? 'others' : method} onChange={this.nullSelect.bind(null, k)}>
                        {options.map(item => {
                          if (isTarget && item.value === 'column') return null
                          return <option value={item.value} key={item.value}>{item.label}</option>
                        })}
                      </select>
                      {isOthers && <NumberInput value={method} onBlur={this.handleInput.bind(null, 'missing', k)} />}
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>}
          {!!outlierCount && <div className={styles.fixesArea}>
            <div className={styles.typeBox}>
              <div className={styles.type}>
                <div className={classnames(styles.typeBlock, styles.outlier)} />
                <span>{EN.OutlierDetection}</span>
              </div>
              {Object.keys(outlierRow).length > 1 && <div className={styles.batch}>
                <Select className={styles.batchSelect} placeholder={EN.BatchFix} value={undefined} onSelect={this.handleSelect('outlier')}>
                  {outArray.map(item => {
                    if (isTarget && item.value === 'column') return null
                    if (item.value === 'others') return null
                    return <Option value={item.value} key={item.value}>{item.label}</Option>
                  })}
                </Select>
              </div>}
            </div>
            <div className={styles.fixesTable}>
              <div className={classnames(styles.fixesRow, styles.fixesHeader)}>
                {Object.keys(outlierRow).length > 1 && <div className={styles.fixedCheck}><input type="checkbox" defaultChecked={false} onChange={this.handleCheckAll('outlier')} /></div>}
                <div className={styles.fixesTd}><span>{EN.VariableName}</span></div>
                <div className={styles.fixesTd}><span>{EN.ValidRange}</span></div>
                <div className={styles.fixesTd}><span>{EN.DataType}</span></div>
                <div className={styles.fixesTd}><span>{EN.QuantityofOutlier}</span></div>
                <div className={styles.fixesTd}><span>{EN.Mean}</span></div>
                <div className={styles.fixesTd}><span>{EN.Median}</span></div>
                <div className={classnames(styles.fixesTd, styles.fixesLarge)}>
                  <span>{EN.Fix}</span>
                  <span className={styles.reset} onClick={this.handleReset('outlier')}>{EN.Reset}</span>
                </div>
              </div>
              <div className={styles.fixesBody}>
                {Object.keys(outlierRow).map((k, i) => {
                  const percnet = outlierRow[k]
                  if (!percnet) return null
                  const num = outlierLineCounts[k]
                  const showType = colType[k] === 'Raw' ? 'Categorical' : colType[k]
                  if (showType !== 'Numerical') return null
                  const outlier = this.outLierDict[k] && this.outLierDict[k].length === 2 ? this.outLierDict[k] : [rawDataView[k].low, rawDataView[k].high];
                  const rowText = num + ' (' + (percnet === 0 ? 0 : percnet < 0.01 ? '<0.01' : formatNumber(percnet.toString(), 2)) + '%)'
                  const mean = !rawDataView ? 'N/A' : rawDataView[k].mean
                  const median = !rawDataView ? 'N/A' : rawDataView[k].median
                  const method = this.fillMethod.outlier.hasOwnProperty(k) ?
                    this.fillMethod.outlier[k] : 'ignore'
                  const isOthers = !outArray.find(_a => _a.value === method)
                  return (
                    <div className={styles.fixesRow} key={i}>
                      {Object.keys(outlierRow).length > 1 && <div className={styles.fixedCheck}><input type="checkbox" checked={this.checked.outlier.includes(k)} onChange={this.handleCheck('outlier', k)} /></div>}
                      <div className={styles.fixesCell}><span title={mapHeader[k]}>{mapHeader[k]}</span></div>
                      <div className={classnames(styles.fixesCell, styles.fixesBwtween)}>
                        <span title={formatNumber(outlier[0].toString(), 2) + "-" + formatNumber(outlier[1].toString(), 2)}>
                          {formatNumber(outlier[0].toString(), 2) + "-" + formatNumber(outlier[1].toString(), 2)}
                        </span><span className={classnames(styles.fixesEdit, {
                          [styles.fixesDisabled]: outlierLoading
                        })} onClick={outlierLoading ? () => { } : this.editRange.bind(this, k, project.etlIndex)}>{EN.Edit}</span>
                      </div>
                      <div className={styles.fixesCell}><span>{EN[showType]}</span></div>
                      <div className={styles.fixesCell}><span title={outlierLoading ? '' : rowText}>{outlierLoading ? <LoadingOutlined /> : rowText}</span></div>
                      <div className={styles.fixesCell}><span title={this.formatCell(mean)} >{this.formatCell(mean)}</span></div>
                      <div className={styles.fixesCell}><span title={this.formatCell(median)}>{this.formatCell(median)}</span></div>
                      <div className={classnames(styles.fixesCell, styles.fixesLarge)}>
                        <select value={isOthers ? 'others' : method} onChange={this.outlierSelect.bind(null, k)}>
                          {outArray.map(item => {
                            if (isTarget && item.value === 'column') return null
                            return <option value={item.value} key={item.value}>{item.label}</option>
                          })}
                        </select>
                        {isOthers && <NumberInput value={method} onBlur={this.handleInput.bind(null, 'outlier', k)} />}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>}
        </div>
        <div className={styles.fixesBottom}>
          <button className={styles.save} onClick={this.save} ><span>{EN.Save}</span></button>
          <button className={styles.cancel} onClick={closeFixes}><span>{EN.CANCEL}</span></button>
        </div>
        {
          this.visible && <Modal
            closeByMask={true}
            showClose={true}
            visible={this.visible}
            title={EN.OutlierDetection}
            onClose={this.closeEdit}
            mask={false}
            content={
              <OutlierRange
                closeEdit={this.closeEdit}
                saveEdit={this.saveEdit}
                field={this.editKey}
                id={project.originalIndex}
                project={project}
              />
            } />}
      </div>
    );
  }
}

export default observer(FixIssue)
