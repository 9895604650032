

export enum ETL_STATUS {
	UNUSE = 0, // 未导入
	UPLOADPENDING = 1,// toHadoop 等待（命令已发送）
	UPLOADING = 2, // toHadoop 进行中
	UPLOADED = 3, //toHadoop 完成
	UPLOADFAILED = -1, // 上传失败
	DETECTPENDING = 4, // detectDataView 等待（命令已发送）
	DETECTING = 5, // detectDataView 进行中
	DETECTED = 6, // detectDataView 完成
	DETECTFAILED = -2, // detectDataView失败
}

export enum LoginErrorCode {
	PasswordError = '403001', // 密码错误
	PasswordExpired = '403002', // 密码过期
	UserNotFound = '403003', // 用户不存在
	InvalidLoginDate = '403004', // 账户不在可登录时间段
	Invalid = '403005', // 账户不可用
	AuthServiceError = '403006', // 第三方验证错误
	Disabled = '403007', // 账户禁止
  }