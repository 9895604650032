import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { MobXProviderContext, observer, useLocalStore } from 'mobx-react';
import { Checkbox } from 'antd';
import { Confirm, ContinueButton, HeaderInfo, Hint, ProcessLoading, Show, Table } from 'components/Common';
import EN from '../../../constant/en';
import EditHeader from './EditHeader';
import { ProjectStore } from 'stores/ProjectStore';
import { UserStore } from 'stores/UserStore';
import SchemaTools from "./SchemaTools"
import { ProjectRolesKey } from '@app/constant/types';

type TableCell = {
  content: string | ReactElement;
  title: string;
  cn: string;
};

function DataSchema():ReactElement{
  // @ts-ignore
  const {
    projectStore,
    userStore,
  }:{
    projectStore:ProjectStore
    userStore:UserStore
  } = useContext(MobXProviderContext)

  const { project } = projectStore;
  const {
    dataHeader,
    target,
    deleteColumns,
    colType,
    targetUnique,
    readFile,
    originalIndex,
    endSchema,
    autoFixHeader,
    renameVariable,
    colValueCounts,
    etling,
    etlProgress,
    rawHeader,
    problemType,
    noComputeTemp,
    mapHeader,
    rawDataView,
    bigData
  } = project;

  const store = useLocalStore(()=>({
    checkList:rawHeader.filter(r=>!dataHeader.includes(r)&& target !== r && !deleteColumns.includes(r)),
    showSelect:false,
    dataType:{...colType},
    // target:project.target,
    target:dataHeader.includes(target)?target:'',
    visiable:false,
    item:dataHeader.length === 1 ? dataHeader[0] : '',
    uniques:targetUnique,
    showOrderIndex:false,
    orderIndex:[...project.orderIndex||[]],
    loadData:true,
    uploadData:[],
    updateField(data) {
      Object.assign(this, data)
    },
  }))


  // @observable checkList = this.props.projectStore.project.rawHeader.filter(
  //   r => !this.props.projectStore.project.dataHeader.includes(r) && this.props.projectStore.project.target !== r && !this.props.projectStore.project.deleteColumns.includes(r)
  // );
  // @observable showSelect = false;
  // @observable dataType = { ...this.props.projectStore.project.colType };
  // @observable visiable = false;
  // @observable target = this.props.projectStore.project.target;
  // @observable item = this.props.projectStore.project.dataHeader.length === 1 ? this.props.projectStore.project.dataHeader[0] : ''
  // @observable uniques = this.props.projectStore.project.targetUnique
  // @observable showOrderIndex = false
  // @observable orderIndex = [...this.props.projectStore.project.orderIndex||[]]
  // @observable loadData = true
  // @observable uploadData = []
  let tableRef = useRef(null)

  useEffect(()=>{
    readFile(originalIndex).then((data) => {
      // this.loadData = false
      // this.uploadData = data
      store.updateField({
        loadData:false,
        uploadData:data.data||data,
      })
    })
  },[]);

  // constructor(props: DataSchemaProps) {
  //   super(props);
  //   // this.tableRef = React.createRef();
  //   props.projectStore.project.readFile(props.projectStore.project.originalIndex).then((data) => {
  //     this.loadData = false
  //     this.uploadData = data
  //   })
  // }

  const doEtl = () => {
    // const { project } = this.props.projectStore;
    if (project.etlIndex || project.train2ing || !!project.models?.length){
      // return (this.visiable = true);
      return store.updateField({
        visiable:true
      })
    }

    onConfirm();
  };

  const onClose = () => {
    // this.visiable = false;
    store.updateField({
      visiable:false,
    })
  };

  const handleVariableEtlAbort = () => {
    project.abortEtlData();
  };


  const onConfirm = () => {
    // const { project } = this.props.projectStore;
    // const { rawHeader, problemType } = project;
    const isUnsupervised = ['Clustering', 'Outlier'].includes(problemType);
    const isAssociation = problemType === 'Association'
    const isMulti = problemType === 'MultiClassification'
    const isTimeSeries = ['Forecasting', 'Survival', 'Prediction'].includes(problemType)
    //无监督删除targetstore
    const newDataHeader = isAssociation ? [store.item] : rawHeader
        .filter(d => !store.checkList.includes(d) && (isUnsupervised ? d !== store.target : true));

    const mismatchFillMethod = {}
    const nullFillMethod = {}
    const outlierFillMethod = {}
    newDataHeader.forEach(k => {
      if (store.dataType[k] === 'Numerical') {
        if (rawDataView && (rawDataView[k].mean === 'inf' || rawDataView[k].mean === 'Infinity')) {
          mismatchFillMethod[k] = 'median';
          nullFillMethod[k] = 'median';
        } else {
          mismatchFillMethod[k] = 'mean';
          nullFillMethod[k] = 'mean';
        }
      } else {
        mismatchFillMethod[k] = 'mode';
        nullFillMethod[k] = 'mode';
      }
      outlierFillMethod[k] = 'drop';
    })
    // if (!isAssociation && !isTimeSeries) {
    //   if (store.target) {
    //     nullFillMethod[store.target] = 'drop'
    //     // 回归默认设置为drop
    //     if (store.dataType[store.target] === 'Numerical') {
    //       outlierFillMethod[store.target] = 'drop'
    //     }
    //   }

    //   //聚类设置为drop
    //   if (problemType === 'Clustering') {
    //     outlierFillMethod = newDataHeader.reduce((prev, h) => {
    //       prev[h] = 'drop';
    //       return prev;
    //     }, {});
    //   }
    // }

    // if (isTimeSeries) {
    //   [store.target, ...store.orderIndex].forEach(k => {
    //     nullFillMethod[k] = 'last'
    //     mismatchFillMethod[k] = 'last'
    //   })
    // }

    const data = {
      target: store.target || '',
      dataHeader: newDataHeader,
      colType: { ...store.dataType },
      outlierFillMethod: outlierFillMethod,
      outlierFillMethodTemp: outlierFillMethod,
      nullFillMethod: nullFillMethod,
      nullFillMethodTemp: nullFillMethod,
      mismatchFillMethod: mismatchFillMethod,
      mismatchFillMethodTemp: mismatchFillMethod,
      orderIndex: [...store.orderIndex],
      dataSort: 'asc',
      dataSortTemp: 'asc'
    };

    project.setProperty({ ...data, ...(isMulti ? { targetUnique: store.uniques } : {}) });
    endSchema()
      .catch(e => {
      window.alert(e)
    })

    onClose();
  };

  const targetSelect = value => {
    if (projectStore.project.roles[ProjectRolesKey.SchemaContinue]) {
      // this.target = value;
      // this.uniques = 0
      tableRef.current.updateGrids();
      // this.checkList = [...this.checkList.filter(v => v !== value)];
      // this.orderIndex = [...this.orderIndex.filter(v => v !== value)];
      store.updateField({
        target:value,
        uniques:0,
        checkList:[...store.checkList.filter(v => v !== value)],
        orderIndex:[...store.orderIndex.filter(v => v !== value)]
      })
    }
  };

  const toggleOrderIndex = () => {
    // this.showOrderIndex = !this.showOrderIndex
    // this.showSelect = false
    store.updateField({
      showOrderIndex:!store.showOrderIndex,
      showSelect:false,
    })
  }

  const checkedOrder = (key, e) => {
    const checked = e.target.checked;
    if (!!checked) {
      if (store.orderIndex.length) return
      // this.orderIndex = [...this.orderIndex, key];
      // this.checkList = [...this.checkList.filter(v => v !== key)];
      store.updateField({
        orderIndex:[...store.orderIndex, key],
        checkList:[...store.checkList.filter(v => v !== key)]
      })
    } else {
      // this.orderIndex = [...this.orderIndex.filter(v => v !== key)];
      store.updateField({
        orderIndex:[...store.orderIndex.filter(v => v !== key)]
      })
    }
    tableRef.current.updateGrids();
  }

  const handleUnique = value => {
    // this.uniques = value
    store.updateField({
      uniques:value
    })
  }

  const variableSelect = value => {
    // this.item = value
    store.updateField({
      item:value
    })
  }

  const checked = (key, e) => {
    const checked = e.target.checked;
    let checkList;
    if (!checked) {
      checkList = [...store.checkList, key];
    } else {
      checkList = [...store.checkList.filter(v => v !== key)];
    }
    store.updateField({
      checkList
    })
    tableRef.current.updateGrids();
  };

  const select = (key, e) => {
    // this.dataType[key] = e.target.value;
    store.updateField({
      dataType:{
        ...store.dataType,
        [key]:e.target.value
      }
    })
    tableRef.current.updateGrids();
  };

  const toggleSelect = () => {
    // this.showSelect = !this.showSelect;
    // this.showOrderIndex = false
    store.updateField({
      showOrderIndex:false,
      showSelect:!store.showSelect
    })
  };

  const checkNoCompute = e => {
    project.noComputeTemp = e.target.checked;
  };

  const autoFix = () => {
      autoFixHeader()
      .then(() => tableRef.current.updateGrids());
  };

  const formatTable = () => {
    const { target, loadData,showSelect, checkList,uploadData,showOrderIndex,dataType,orderIndex } = store;
    if (!bigData&&loadData) return []
    if (etling) return [];
    if (!bigData&&!uploadData.length) return [];
    const isAssociation = problemType === 'Association'
    const isTimeSeries = ['Forecasting', 'Survival', 'Prediction'].includes(problemType)

    // if(!dataHeader.includes(target)){
    //   target = ''
    // }

    // // Raw类型变量不可选择和查看
    // const headerList = target
    //   ? [target, ...rawHeader.filter(v => (v !== target && colType[v] !== "Raw"))]
    //   : rawHeader.filter(v => colType[v] !== "Raw");
    
    const headerList = target
    ? [target, ...rawHeader.filter(v => v !== target)]
    : rawHeader;
    
    /**
     * 根据showSelect, indexPosition变化
     * showSelect: true  显示勾选框
     * checkRow: 勾选框的行数
     * headerRow: 标题的行数
     * selectRow: 类型选择的行数
     * columnHeader: 表头的列数
     * rowHeader: 表头的行数
     */
    const index = {
      checkRow: -1 + (showSelect ? 1 : 0),
      headerRow: (showSelect ? 1 : 0),
      commentRow: (showSelect ? 2 : 1),
      selectRow: 1 + (showSelect ? 1 : 0),
      columnHeader: 1,
      rowHeader: 3 + (showSelect ? 1 : 0),
    };

    const realColumn = headerList.length + index.columnHeader;

    const checkArr = [];
    const headerArr = [];
    const selectArr = [];
    // orider index
    const orderArr = []
    const commentArr: Array<TableCell> = [
      {
        content: (<span>{EN.Comment}</span>),
        title: '',
        cn: styles.titleCell,
      },
      ...headerList.map(header => ({
        content: (<span>{project.detectedFields?.[header]?.comment || ''}</span>),
        title: `${project.detectedFields?.[header]?.comment || ''}`,
        cn: classnames(
          styles.titleCell,
          { [styles.target]: target && target === header },
          { [styles.checked]: !isAssociation && checkList.includes(header) },
          { [styles.order]: isTimeSeries && orderIndex?.includes(header) },
          { [styles.missed]: !header },
        ),
      }))
    ]

    for (let i = 0; i < realColumn; i++) {
      const header = headerList[i - index.columnHeader] || '';

      if (isTimeSeries && showOrderIndex) {
        const orderData: TableCell = {
          content: '',
          title: '',
          cn: styles.check,
        };
        if (i !== index.columnHeader - 1 && (dataType[header] === 'Datetime')) {
          orderData.content = (
            <Checkbox
              onChange={checkedOrder.bind(null, header)}
              checked={false}
            />
          );
          if (target && target === header) {
            orderData.cn = classnames(styles.check, styles.target);
            orderData.content = '';
          }
          if (orderIndex.includes(header)) {
            orderData.cn = classnames(styles.check, styles.order);
            orderData.content = (
              <Checkbox
                onChange={checkedOrder.bind(null, header)}
                checked={true}
              />
            );
          }
          // if (this.dataType[header] === 'Numerical' && rawDataView[header].doubleCount > rawDataView[header].doubleUniqueValue) {
          //   orderData.content = '';
          // }
        }
        orderArr.push(orderData)
      }

      if (index.checkRow > -1) {
        const checkData: TableCell = {
          content: '',
          title: '',
          cn: styles.check,
        };
        if (i !== index.columnHeader - 1) {
          checkData.content = (
            <Checkbox
              onChange={checked.bind(null, header)}
              checked={true}
            />
          );
          if (target && target === header) {
            checkData.cn = classnames(styles.check, styles.target);
            checkData.content = '';
          }
          if (!isAssociation && checkList.includes(header)) {
            checkData.cn = classnames(styles.check, styles.checked);
            checkData.content = (
              <Checkbox
                onChange={checked.bind(null, header)}
                checked={false}
              />
            );
          }
        }
        checkArr.push(checkData);
      }

      const headerData: TableCell = {
        content: '',
        title: '',
        cn: styles.titleCell,
      };
      if (i === index.columnHeader - 1) {
        headerData.content = (
          <HeaderInfo
            row={EN.Header}
            col={EN.Row}
            style={{ margin: '-3px -.1em 0', height: '34px', width: '110px' }}
            rotate={15.739}
          />
        );
        headerData.title = '';
      } else {
        const headerText = header;
        headerData.content = (
          <EditHeader value={headerText} key={i - index.columnHeader} />
        );
        headerData.title = headerText;
        if (target && target === header) {
          headerData.cn = classnames(headerData.cn, styles.target);
        }
        if (!isAssociation && checkList.includes(header)) {
          headerData.cn = classnames(headerData.cn, styles.checked);
        }
        if (isTimeSeries && orderIndex?.includes(header)) {
          headerData.cn = classnames(headerData.cn, styles.order);
        }
        if (!headerText) {
          headerData.cn = classnames(headerData.cn, styles.missed);
        }
        // if (headerText && temp[headerText] && temp[headerText].length > 1) {
        //   headerData.cn = classnames(headerData.cn, styles.duplicated);
        // }
      }
      headerArr.push(headerData);

      const selectData: TableCell = {
        content: '',
        title: '',
        cn: styles.check,
      };
      if (i === index.columnHeader - 1) {
        selectData.content = '';
      } else {
        let key = header;
        // if (!headerText) {
        //   key = `Unnamed: ${realColumn}`
        // }
        // if (header && temp[header] && temp[header].length > 1) {
        //   const tempIndex = temp[header].findIndex(c => c === realColumn);
        //   const suffix = tempIndex === 0 ? "" : '.' + tempIndex;
        //   key = header + suffix
        // }
        const canTransforToCategorical = !!Object.keys(colValueCounts[key]||{}).length
        const canTransforToNumerical = !!rawDataView[key]?.doubleCount
        // const canTransforToDatetime = !!this.props.projectStore.project.rawDataView[key].dateCount
        const colValue = dataType[key];
        selectData.content = (
          <select value={colValue} onChange={select.bind(null, key)}>
              {!canTransforToCategorical && (
              <option value="Raw">{EN.Categorical}</option>
            )}
            {canTransforToCategorical && (
              <option value="Categorical">{EN.Categorical}</option>
            )}
            {canTransforToNumerical && <option value="Numerical">{EN.Numerical}</option>}
            {/* {canTransforToDatetime && (
              <option value="Datetime">{EN.Datetime}</option>
            )} */}
            {colValue === 'Datetime' && <option value="Datetime">{EN.Datetime}</option>}
          </select>
        );
        selectData.title = {
          Numerical: EN.Numerical,
          Categorical: EN.Categorical,
          Raw: EN.Categorical + '(Raw)',
        }[colValue];
        if (target && target === key) {
          selectData.cn = classnames(styles.cell, styles.target);
          selectData.content = (
            <span>
              {
                {
                  Numerical: EN.Numerical,
                  Categorical: EN.Categorical,
                  Raw: EN.Categorical + '(Raw)',
                  Datetime: EN.Datetime
                }[colValue]
              }
            </span>
          );
        }
        if (orderIndex?.includes(key)) {
          selectData.cn = classnames(styles.cell, styles.order);
          selectData.content = (
            <span>
              {
                {
                  Numerical: EN.Numerical,
                  Categorical: EN.Categorical,
                  Raw: EN.Categorical + '(Raw)',
                  Datetime: EN.Datetime
                }[colValue]
              }
            </span>
          );
        }
      }
      selectArr.push(selectData);
    }

    const tableData = uploadData && uploadData.map((row, rowIndex) => {
      const arr: TableCell[] = [];
      if (index.columnHeader > 0) {
        arr.push({
          content: <span>{rowIndex + 1}</span>,
          title: (rowIndex + 1).toString(),
          cn: styles.cell,
        });
      }
      const dataArr = headerList.map(h => {
        const v = (row[h] === null || row[h] === 'nan') ? '' : row[h]
        const header = h
        const itemData = {
          content: <span>{(v === undefined ? "" : `${v}`)}</span>,
          title: (v === undefined ? "" : `${v}`),
          cn: styles.cell,
        };
        // const cellValue = data[rowIndex][realColumn]
        if (renameVariable&&target && target === header) {
          itemData.cn = classnames(itemData.cn, styles.target);
          itemData.content = <span>{((renameVariable[v] || v) === undefined ? "" : `${(renameVariable[v] || v)}`)}</span>;
          itemData.title = ((renameVariable[v] || v) === undefined ? "" : `${(renameVariable[v] || v)}`);
        }
        if (!isAssociation && checkList.includes(header)) {
          itemData.cn = classnames(itemData.cn, styles.checked);
        }
        if (isTimeSeries && orderIndex.includes(header)) {
          itemData.cn = classnames(itemData.cn, styles.order);
        }
        return itemData;
      })
      return arr.concat(dataArr);
    });
    return [orderArr, checkArr, headerArr, commentArr, selectArr, ...tableData].filter(
      row => row.length === realColumn,
    );
  };

    const tableData = formatTable();
    const isUnsupervised = ['Clustering', 'Outlier'].includes(problemType);
    const isAssociation = problemType === 'Association'
    const isMulti = problemType === 'MultiClassification'
    const isTimeSeries = ['Forecasting', 'Survival', 'Prediction'].includes(problemType)
    const maxCounts = Math.min((store.target ? rawDataView[store.target]?.uniqueValue : 0), 100)
    const newDataHeader = rawHeader.filter(d => !store.checkList.includes(d) && (isUnsupervised ? d !== store.target : true));
    //target选择列表
    const targetOption = rawHeader.reduce((prev, h) => {
      if (isAssociation) {
        prev[h] = mapHeader[h];
      } else {
        if (problemType === 'Regression' || isTimeSeries) {
          if (store.dataType[h] === 'Numerical') prev[h] = mapHeader[h];
        } else {
          if (store.dataType[h] === 'Categorical') {
            if (isMulti) {
              const count = rawDataView[h].uniqueValue
              if (count > 2) prev[h] = mapHeader[h];
            } else {
              prev[h] = mapHeader[h];
            }
          }
        }
      }
      return prev;
    }, {});

    const schemaText = isTimeSeries ? EN.TimeSeriesSchemaTitle : isAssociation ? EN.AssociationSchemaTitle : EN.Pleaseselectavariableasthetargetvariable
    return (
      <div className={styles.schema}>
        {(bigData && isUnsupervised)||problemType === "Outlier" ? null : <div className={styles.schemaInfo}>
          <div className={styles.schemaI}>
            <span>i</span>
          </div>
          <div className={styles.schemaText}>
            <span>{schemaText}</span>
          </div>
        </div>}
        <div className={styles.schemaContent} id="schemaContent">
          <SchemaTools problemType={problemType}
            targetOption={targetOption}
            maxCounts={maxCounts}
            target={store.target}
            item={store.item}
            // isMissed={isMissed}
            // isDuplicated={isDuplicated}
            uniques={store.uniques}
            targetSelect={targetSelect}
            variableSelect={variableSelect}
            handleUnique={handleUnique}
            toggleSelect={toggleSelect}
            autoFix={autoFix}
            bigData={bigData}
            toggleOrderIndex={toggleOrderIndex} />
          <div className={styles.content}>
            <Table
              ref={tableRef}
              columnWidth={110}
              rowHeight={34}
              columnCount={rawHeader.length + 1}
              rowCount={tableData.length}
              fixedColumnCount={1}
              fixedRowCount={(store.showSelect || store.showOrderIndex) ? 4 : 3}
              style={{ border: '1px solid #ccc' }}
              data={tableData}
            />
          </div>
        </div>
        <div className={styles.bottom}>
          <Show
            name={ProjectRolesKey.SchemaContinue}
          >
            <ContinueButton
              onClick={doEtl}
              disabled={
                etling ||
                (isUnsupervised ? false : !store.target) ||
                (isAssociation ? !store.item : newDataHeader.length < 1) ||
                (isMulti ? (store.uniques < 3 || store.uniques > maxCounts) : false)
                // ||
                // isMissed ||
                // isDuplicated
              }
              text={EN.Continue}
              width={null}
            />
          </Show>

          {(!isAssociation && !isTimeSeries) && <Show
            name={ProjectRolesKey.SchemaContinue}
          >
            <div className={styles.checkBox}>
              <input
                type="checkbox"
                id="noCompute"
                onChange={checkNoCompute}
                checked={noComputeTemp}
              />
              <label htmlFor="noCompute">{EN.SkipDataQualityCheck}</label>
              <Hint
                themeStyle={{
                  fontSize: '1.5rem',
                  lineHeight: '2rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
                content={EN.Ifyouknowthedataisclean}
              />
            </div>
          </Show>}
        </div>
        {etling && (
          <ProcessLoading
            onCancel={noComputeTemp ? handleVariableEtlAbort : undefined}
            progress={etlProgress}
            style={{ position: 'fixed' }}
          />
        )}
        <Confirm
          width={'6em'}
          visible={store.visiable}
          title={EN.Warning}
          content={EN.Thisactionmaywipeoutallofyourprevious}
          onClose={onClose}
          onConfirm={onConfirm}
          confirmText={EN.Continue}
          closeText={EN.Cancel}
        />
      </div>
    )

}

export default observer(DataSchema);
