import React, { Component } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { ContinueButton, Modal, ProcessLoading, Show } from 'components/Common';
import { observable } from 'mobx';
import ClassificationTarget from './Issues/ClassificationTarget';
import RegressionTarget from './Issues/RegressionTarget';
import RowIssue from './Issues/RowIssue';
import DataIssue from './Issues/DataIssue';
import FixIssue from './Issues/FixIssue';
import SelectTarget from './Issues/SelectTarget';
import { LoadingOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { formatNumber } from '../../../util';
import EN from '../../../constant/en';
import Project from 'stores/Project';
import moment from 'moment'
import { ProjectRolesKey } from '@app/constant/types';

interface TargetIssueProps {
  project: Project;
  changeTab: () => void;
}

@observer
class TargetIssue extends Component<TargetIssueProps> {
  constructor(props) {
    super(props)
    this.getData()
  }

  @observable visible = false;
  @observable missing = [];
  @observable mismatch = [];
  @observable outlier = [];
  @observable edit = false;
  @observable loadData = true;
  @observable uploadData = []

  backToConnect = () => {
    const { updateProject, nextSubStep } = this.props.project;
    return updateProject(nextSubStep(1, 2));
  };

  backToSchema = () => {
    const { updateProject, nextSubStep } = this.props.project;
    return updateProject(nextSubStep(2, 2));
  };

  editFixes = () => {
    this.visible = true;
  };

  closeFixes = () => {
    this.visible = false;
  };

  editTarget = () => {
    this.edit = true;
  };

  closeTarget = () => {
    this.edit = false;
  };
  handleTargetEtlAbort = () => {
    this.props.project.abortEtlData();
  };

  saveTargetFixes = () => {
    this.props.project.fixTarget();

    // message.destroy();
    // message.info(EN.Thechangeswillbeappliedintrainingsection, 5);
    this.closeTarget();
  };

  saveDataFixes = () => {
    this.props.project.fixFillMethod();
    // message.info(EN.Thechangeswillbeappliedintrainingsection, 5);
    this.closeFixes();
  };

  updateCondition = (column, type) => () => {
    ['missing', 'mismatch', 'outlier'].forEach(t => {
      if (t === type) return
      const index = this[t].indexOf(column)
      if (index !== -1) this[t].splice(index, 1)
    })
    const index = this[type].indexOf(column)
    if (index !== -1) this[type].splice(index, 1)
    if (index === -1) this[type].push(column)
    // this.getData()
  }

  getData = () => {
    this.loadData = true
    this.props.project.readFile(this.props.project.originalIndex).then(data => {
      this.loadData = false
      this.uploadData = data
    })
  }

  render() {
    const { project, changeTab } = this.props;
    const {
      issues,
      mapHeader,
      target,
      colType,
      rawHeader,
      nullLineCounts,
      mismatchLineCounts,
      outlierLineCounts,
      totalRawLines,
      totalLines,
      etling,
      bigData,
      etlProgress,
      renameVariable,
      targetCounts,
      rawDataView,
      targetIssuesCountsOrigin,
      targetArrayTemp,
      targetUnique,
      problemType,
      outlierDictTemp,
      outlierLoading
    } = project;
    const targetUniques = targetUnique || NaN
    const isNum = colType[target] === 'Numerical';
    const isDate = colType[target] === 'Datetime';
    let recomm = !isNum ? 2 : Math.min((rawHeader.length - 1) * 6, 1000);
    if(isNum&&bigData&&recomm<100){
      recomm = 100
    }
    const nullCount = Number.isInteger(nullLineCounts[target]) ? nullLineCounts[target] : 0;
    const mismatchCount = isNum ? mismatchLineCounts[target] : 0;
    const outlierCount = isNum ? outlierLineCounts[target] : 0;

    const issueRow = {
      mismatchRow: { [target]: targetIssuesCountsOrigin.mismatchRow / totalRawLines * 100 },
      nullRow: { [target]: targetIssuesCountsOrigin.nullRow / totalRawLines * 100 },
      outlierRow: { [target]: targetIssuesCountsOrigin.outlierRow / totalRawLines * 100 }
    }

    const targetPercent = {
      missing:
        nullCount === 0
          ? 0
          : (nullCount * 100) / (totalRawLines || 1) < 0.01
            ? '<0.01'
            : formatNumber(
              ((nullCount * 100) / (totalRawLines || 1)).toString(),
              2,
            ),
      mismatch:
        mismatchCount === 0
          ? 0
          : (mismatchCount * 100) / (totalRawLines || 1) < 0.01
            ? '<0.01'
            : formatNumber(
              ((mismatchCount * 100) / (totalRawLines || 1)).toString(),
              2,
            ),
      outlier:
        outlierCount === 0
          ? 0
          : (outlierCount * 100) / (totalRawLines || 1) < 0.01
            ? '<0.01'
            : formatNumber(
              ((outlierCount * 100) / (totalRawLines || 1)).toString(),
              2,
            ),
    };
    const warnings: string[] = [];
    const unique = rawDataView[target].uniqueValue;
    // const unique = targetArrayTemp.length || Object.keys(targetCounts).filter(k => k !== '').length
    if (!isNum) {
      const curUnique = targetArrayTemp.length || Object.keys(targetCounts).filter(k => k !== '').length
      const hasNull = !targetArrayTemp.length ? !!nullCount : false;
      // const isNa = isNaN(targetUniques)
      if (hasNull)
        warnings.push(`${EN.Thetargetvariablehassomenoise}${EN.Dot}`);//isNa ? EN.Thetargetvariablehassomenoise : `${EN.YourtargetvariableHas}${EN.Thantwouniquealues}`
      if (curUnique < targetUniques && !hasNull)
        warnings.push(`${EN.YourtargetvariableHas}${EN.Less}${targetUniques}${EN.GE}`);
      if (curUnique === targetUniques && !hasNull) {
        const defaultCount = problemType === 'MultiClassification' ? Math.min(Math.max(Math.ceil(totalLines / 100), 3), 10) : 3
        const smallKeys = Object.entries(targetCounts).filter((tc) => tc[1] < defaultCount).map(([key]) => key || 'NULL')
        if (!!smallKeys.length) warnings.push(`${EN.rowslessthantrainbefore}${smallKeys.length === 1 ? smallKeys[0] : `${smallKeys.slice(0, smallKeys.length - 1).join(' ,')}${EN.and}${smallKeys.slice(smallKeys.length - 1)}`}${EN.rowslessthantrainafter}`);
      }
    }
    if ((nullLineCounts[target] ? nullLineCounts[target] : 0) === totalRawLines)
      warnings.push(EN.Yourtargetvariableisempty);
    const cannotContinue =
      !!warnings.length ||
      (!isNum && issues.targetIssue);
    const isClean =
      !warnings.length &&
      !issues.targetIssue &&
      !issues.rowIssue &&
      !(issues.targetRowIssue);

    const isLess =
      Object.keys(targetCounts).filter(_k => _k !== '').length < targetUniques;
    const isMore = Object.keys(targetCounts).length > targetUniques;
    const isGood =
      targetArrayTemp.length ||
      (isNaN(targetUniques)
        ? !Object.keys(targetCounts).includes('')
        : !isLess && !isMore);
    const hasNull = !isGood && Object.keys(targetCounts).includes('');
    const error =
      Object.keys(targetCounts).filter(_k => _k !== '').length +
      (hasNull ? 1 : 0) <
      targetUniques;
    const text =
      (isGood && EN.Targetvariablequalityisgood) ||
      (error &&
        `${EN.YourtargetvariableHas}${EN.Less}${targetUniques}${EN.GE}`) /**onlyOnevalue */ ||
      (isMore &&
        `${EN.YourtargetvariableHas}${EN.More}${targetUniques}${EN.GE}`) /** Thantwouniquealues*/ ||
      `${EN.Thetargetvariablehassomenoise}${EN.Dot}`;
    return (
      <div className={styles.quality}>
        <div className={styles.issue}>
          {!!warnings.length && (
            <div className={styles.issueTitle}>
              <span>{EN.Warning}!</span>
            </div>
          )}
          {!!warnings.length && (
            <div className={styles.issueBox}>
              {warnings.map((v, k) => (
                <div className={styles.issueText} key={k}>
                  <div className={styles.point} />
                  <span>{v}</span>
                </div>
              ))}
            </div>
          )}
          {(issues.targetIssue ||
            issues.rowIssue ||
            issues.targetRowIssue) && (
              <div className={styles.issueTitle}>
                <span>
                  {EN.IssueS}
                  {+issues.targetIssue +
                    +issues.rowIssue +
                    +issues.targetRowIssue >
                    1 && EN.SS}{' '}
                  {EN.Found}!
              </span>
              </div>
            )}
          {(issues.targetIssue ||
            issues.rowIssue ||
            issues.targetRowIssue) && (
              <div className={styles.issueBox}>
                {issues.targetIssue && (
                  <div className={styles.issueText}>
                    <div className={styles.point} />
                    {!isNum ? (
                      <span>
                        {text}{EN.Dot}{EN.Pleasepress}{targetUniques}{EN.Beforeproceedingto}{EN.Dot}
                      </span>
                    ) : (
                        <span>
                          {EN.Yourtargetvariablehaslessthan}
                          {recomm}
                          {EN.Uniquevalueswhichisnot}
                        </span>
                      )}
                  </div>
                )}
                {issues.rowIssue && (
                  <div className={styles.issueText}>
                    <div className={styles.point} />
                    <span>{EN.Datasizeistoosmall}</span>
                  </div>
                )}
                {issues.targetRowIssue && (
                  <div className={styles.issueText}>
                    <div className={styles.point} />
                    <span>{EN.Somedataissueshighlightedincolor}</span>
                  </div>
                )}
              </div>
            )}
          {isClean && (
            <div className={styles.cleanTitle}>
              <span>{EN.Targetvariablequalitylooksgood}</span>
            </div>
          )}
        </div>
        <div className={styles.typeBox}>
          {!!mismatchCount && (
            <div className={styles.type}>
              <div
                className={classnames(styles.typeBlock, styles.mismatch)}
              />
              <span>{EN.DataTypeMismatch}</span>
            </div>
          )}
          {!!nullCount && (
            <div className={styles.type}>
              <div
                className={classnames(styles.typeBlock, styles.missing)}
              />
              <span>{EN.MissingValue}</span>
            </div>
          )}
          {!!outlierCount && (
            <div className={styles.type}>
              <div
                className={classnames(styles.typeBlock, styles.outlier)}
              />
              <span>{EN.OutlierDetection}</span>
            </div>
          )}
          <div className={styles.issueTabs}>
            <div
              className={styles.issueTab}
              style={{ borderBottomColor: '#1d2b3c' }}
            >
              <span style={{ fontWeight: 'bold' }}>{EN.TargetVariable}</span>
            </div>
            <div
              className={styles.issueTab}
              onClick={cannotContinue ? null : changeTab}
            >
              <span>{EN.PredictorVariables}</span>
            </div>
          </div>
        </div>
        <div className={styles.contentBox}>
          <div className={styles.list}>
            <div className={styles.table}>
              <div className={classnames(styles.cell, styles.target)}>
                <span>{EN.TargetVariable}</span>
              </div>
              <div className={classnames(styles.cell, styles.label)}>
                <span>{mapHeader[target]}</span>
              </div>
              <div className={classnames(styles.cell, styles.select)}>
                <span>{isDate ? EN.Datetime : isNum ? EN.Numerical : EN.Categorical}</span>
              </div>
              <div className={classnames(styles.cell, styles.error)}>
                {!!targetPercent.mismatch && (
                  <div
                    className={styles.errorBlock}
                    onClick={this.updateCondition(target, 'mismatch')}
                  >
                    <div className={styles.issueBackground}>
                      <div className={styles.mismatch}/>
                      <div className={classnames({ [styles.issueActive]: this.mismatch.indexOf(target) !== -1 })}/>
                    </div>
                    <span>{targetPercent.mismatch}%</span>
                  </div>
                )}
                {!!targetPercent.missing && (
                  <div
                    className={styles.errorBlock}
                    onClick={this.updateCondition(target, 'missing')}
                  >
                    <div className={styles.issueBackground}>
                      <div className={styles.missing}></div>
                      <div className={classnames({ [styles.issueActive]: this.missing.indexOf(target) !== -1 })}></div>
                    </div>
                    <span>{targetPercent.missing}%</span>
                  </div>
                )}
                {!!targetPercent.outlier && (outlierLoading ? <div
                  className={styles.errorBlock}
                >
                  <div className={styles.issueBackground}>
                    <div className={styles.outlier}></div>
                    <div className={classnames({ [styles.issueActive]: this.outlier.indexOf(target) !== -1 })}></div>
                  </div>
                  <div className={styles.blockLoading}><span><LoadingOutlined /></span></div>
                </div> :
                  <div
                    className={styles.errorBlock}
                    onClick={this.updateCondition(target, 'outlier')}
                  >
                    <div className={styles.issueBackground}>
                      <div className={styles.outlier}></div>
                      <div className={classnames({ [styles.issueActive]: this.outlier.indexOf(target) !== -1 })}></div>
                    </div>
                    <span>{targetPercent.outlier}%</span>
                  </div>
                )}
              </div>
              <div className={styles.tableBody}>
                {!!this.uploadData.length &&
                  this.uploadData.map((r, k) => {
                    const v = (r[target] === null || r[target] === 'nan') ? undefined : r[target]
                    const outlier = outlierDictTemp[target] && outlierDictTemp[target].length === 2 ? outlierDictTemp[target] : [rawDataView[target].low, rawDataView[target].high];
                    const [low = NaN, high = NaN] = outlier
                    const isMissing = isNaN(+v) ? !v : false;
                    const isMismatch = isDate ? !moment(v).isValid() : isNum ? isNaN(+v) : false;
                    const isOutlier = (isNum && !outlierLoading) ? +v < low || +v > high : false;
                    const showCell = this.missing.includes(target) ? isMissing : this.mismatch.includes(target) ? isMismatch : this.outlier.includes(target) ? isOutlier : true

                    return showCell ? <div
                      key={k}
                      className={classnames(styles.cell, {
                        [styles.mismatch]: isMismatch,
                        [styles.missing]: isMissing,
                        [styles.outlier]: isOutlier,
                      })}
                    >
                      <span title={((renameVariable[v] || v) === undefined ? "" : `${(renameVariable[v] || v)}`)}>
                        {((renameVariable[v] || v) === undefined ? "" : `${(renameVariable[v] || v)}`)}
                      </span>
                    </div> : null
                  })}
              </div>
            </div>
            <Show
              name={ProjectRolesKey.QualityContinue}
            >
              <ContinueButton
                disabled={cannotContinue}
                onClick={changeTab}
                text={EN.Continue}
                width="100%"
              />
            </Show>

          </div>
          <div className={styles.content}>
            {!isNum ? (
              <ClassificationTarget
                project={project}
                backToConnect={this.backToConnect}
                backToSchema={this.backToSchema}
                editTarget={this.editTarget}
              />
            ) : (
                <RegressionTarget
                  backToConnect={this.backToConnect}
                  backToSchema={this.backToSchema}
                  hasIssue={issues.targetIssue}
                  unique={unique}
                  recomm={recomm}
                />
              )}
            {issues.rowIssue && (
              <RowIssue
                backToConnect={this.backToConnect}
                totalRawLines={totalRawLines}
              />
            )}
            {issues.targetRowIssue && (
              <DataIssue
                backToConnect={this.backToConnect}
                editFixes={this.editFixes}
                targetIssuesCountsOrigin={targetIssuesCountsOrigin}
                totalRawLines={totalRawLines}
                totalLines={totalLines}
                percent={targetPercent}
                loading={outlierLoading}
              />
            )}
          </div>
          <Modal
            content={
              <FixIssue
                project={project}
                nullCount={targetIssuesCountsOrigin.nullRow}
                mismatchCount={targetIssuesCountsOrigin.mismatchRow}
                outlierCount={targetIssuesCountsOrigin.outlierRow}
                closeFixes={this.closeFixes}
                saveDataFixes={this.saveDataFixes}
                isTarget={true}
                issueRow={issueRow}
              />
            }
            visible={this.visible}
            width="12em"
            title={EN.HowR2LearnWillFixTheIssues}
            onClose={this.closeFixes}
            closeByMask={true}
            showClose={true}
          />
          <Modal
            content={
              <SelectTarget
                project={project}
                closeTarget={this.closeTarget}
                saveTargetFixes={this.saveTargetFixes}
              />
            }
            visible={this.edit}
            width="5.5em"
            title={EN.HowR2LearnWillFixTheIssues}
            onClose={this.closeTarget}
            closeByMask={true}
            showClose={true}
          />
        </div>
        {etling && (
          <ProcessLoading
            progress={etlProgress}
            style={{ position: 'fixed' }}
            onCancel={this.handleTargetEtlAbort}
          />
        )}
      </div>
    );
  }
}

export default observer(TargetIssue);
