import React, { ReactElement, useMemo } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { formatNumber } from '../../../util';
import EN from '../../../constant/en';
import { PIE } from '../../Charts';
import Project from 'stores/Project';
import { Show } from '../../Common';
import { ProjectRolesKey } from '@app/constant/types';

interface Interface {
  readonly project: Project;
  readonly editFixes?: () => void;
  readonly report?: boolean;
}

function TimeSeriesSummary(props: Interface): ReactElement {
  const {
    project: {
      mapHeader,
      target,
      colType,
      nullLineCounts,
      mismatchLineCounts,
      outlierLineCounts,
      totalRawLines,
      deletedCount,
      totalLines,
      totalFixedLines,
      problemType,
      issues,
      updateProject,
      nextMainStep,
      nextSubStep,
      orderIndex,
      variableIssueCount
    },
    editFixes,
    report = false,
  } = props;
  function backToConnect() {
    return updateProject(nextSubStep(1, 2));
  }

  const startTrain = async () => {
    // modeling
    // await timeSeriesEtl()
    await updateProject(nextMainStep(3))
    return
  }

  const deletePercent = formatNumber(
    ((deletedCount / totalRawLines) * 100).toString(),
    2,
  );
  const fixedPercent = formatNumber(
    ((totalFixedLines / totalRawLines) * 100).toString(),
    2,
  );
  const cleanPercent = formatNumber(
    (100 - +deletePercent - +fixedPercent).toString(),
    2,
  );
  const variableList = [target, ...orderIndex];
  const percentList = variableList.map(v => {
    const isNum = colType[v] === 'Numerical';
    const isDate = colType[v] === 'Datetime';
    const percent: {
      missing: number;
      mismatch: number;
      outlier: number;
      clean?: number;
    } = {
      missing: (nullLineCounts[v] || 0) / (totalRawLines || 1) * 100,
      mismatch: ((isDate || isNum) ? (mismatchLineCounts[v] || 0) : 0) / (totalRawLines || 1) * 100,
      outlier: 0//(isNum ? (outlierLineCounts[v] || 0) : 0) / (totalRawLines || 1) * 100,
    };
    percent.clean = 100 - percent.missing - percent.mismatch - percent.outlier;
    return percent;
  });

  const { nullCount, mismatchCount } = variableIssueCount

  return (
    <div className={styles.summary}>
      <div className={styles.summaryLeft}>
        <div className={styles.summaryTitle}>
          <span>{EN.Summaryofyourdata}</span>
        </div>
        <div className={styles.summaryTypeBox}>
          <div className={styles.summaryType}>
            <div
              className={styles.summaryCube}
              style={{ backgroundColor: '#00c855' }}
            />
            <span>{EN.CleanData}</span>
          </div>
          {(!!(mismatchCount + nullCount)) && (
            <div className={styles.summaryType}>
              <div
                className={styles.summaryCube}
                style={{ backgroundColor: '#819ffc' }}
              />
              <span>{EN.DataTypeMismatch}</span>
            </div>
          )}
        </div>
        <div
          className={styles.summaryTable}
          style={{
            paddingRight: '.2em',
            maxHeight: !!target ? '4em' : '3em',
            marginTop: !!target ? '10px' : 0,
          }}
        >
          <div className={styles.summaryTableLeft}>
            <div className={styles.summaryTableRow}>
              <div className={styles.summaryCell}>
                <span style={{ fontWeight: 'bold' }}>
                  {EN.PredictorVariables}
                </span>
              </div>
              <div className={styles.summaryCell}>
                <span style={{ fontWeight: 'bold' }}>{EN.CleanData}</span>
              </div>
            </div>
          </div>
          <div className={styles.summaryTableRight}>
            <div className={styles.summaryTableRow}>
              <div className={styles.summaryCell}>
                <span style={{ fontWeight: 'bold' }}>
                  {EN.DataComposition}{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.summaryTable}>
          <div className={styles.summaryTableLeft}>
            {variableList.map((v, k) => {
              const percent = percentList[k];
              return (
                <div className={styles.summaryTableRow} key={k}>
                  <div className={styles.summaryCell}>
                    <span>{mapHeader[v]}</span>
                  </div>
                  <div className={styles.summaryCell}>
                    <span>{formatNumber(percent.clean.toString(), 2)}%</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.summaryTableRight}>
            {variableList.map((v, k) => {
              const percent = percentList[k];
              return (
                <div className={styles.summaryTableRow} key={k}>
                  <div className={styles.summaryProgressBlock}>
                    <div
                      className={styles.summaryProgress}
                      style={{
                        width: percent.clean + '%',
                        backgroundColor: '#00c855',
                      }}
                    />
                    <div
                      className={styles.summaryProgress}
                      style={{
                        width: (percent.mismatch + percent.missing) + '%',
                        backgroundColor: '#819ffc',
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.summaryRight}>
        <div className={styles.summaryTitle}>
          <span>{EN.HowR2LearnWillFixTheIssues}</span>
        </div>
        <div className={styles.summaryPie}>
          {/*<div className={styles.summaryChart}>*/}
          {/*</div>*/}
          <PIE
            RowsWillBeFixed={fixedPercent}
            RowsWillBeDeleted={deletePercent}
            CleanData={cleanPercent}
          />
          <div className={styles.summaryParts}>
            <div className={styles.summaryPart}>
              <div className={styles.summaryPartText}>
                <div
                  className={styles.summaryCube}
                  style={{ backgroundColor: '#9cebff' }}
                />
                <span style={{ fontWeight: 'bold' }}>{EN.RowsWillBeFixed}</span>
              </div>
              <div className={styles.summaryPartText}>
                <div className={styles.summaryCube} />
                <span>{fixedPercent}%</span>
              </div>
            </div>
            <div className={styles.summaryPart}>
              <div className={styles.summaryPartText}>
                <div
                  className={styles.summaryCube}
                  style={{ backgroundColor: '#c4cbd7' }}
                />
                <span style={{ fontWeight: 'bold' }}>
                  {EN.RowsWillBeDeleted}
                </span>
              </div>
              <div className={styles.summaryPartText}>
                <div className={styles.summaryCube} />
                <span>{deletePercent}%</span>
              </div>
            </div>
            <div className={styles.summaryPart}>
              <div className={styles.summaryPartText}>
                <div
                  className={styles.summaryCube}
                  style={{ backgroundColor: '#00c855' }}
                />
                <span style={{ fontWeight: 'bold' }}>{EN.CleanData}</span>
              </div>
              <div className={styles.summaryPartText}>
                <div className={styles.summaryCube} />
                <span>{cleanPercent}%</span>
              </div>
            </div>
          </div>
        </div>
        {
          !report && <div className={styles.summaryBottom}>
            <Show name={ProjectRolesKey.QualityContinue}>
              <div
                className={classnames(
                  styles.summaryButton,
                  styles.summaryConfirm,
                  {
                    [styles.disabled]: totalLines === 0,
                  },
                )}
                onClick={totalLines === 0 ? () => { } : startTrain}
              >
                <span>{EN.Continue}</span>
              </div>
            </Show>
            <Show name={ProjectRolesKey.QualityContinue} >
              <div
                className={classnames(styles.summaryButton, {
                  [styles.disabled]: !issues.dataIssue,
                })}
                onClick={issues.dataIssue ? editFixes : () => { }}
              >
                <span>{EN.EditTheFixes}</span>
              </div>
            </Show>
            <Show name={ProjectRolesKey.QualityContinue}>
              <div className={styles.summaryButton} onClick={backToConnect}>
                <span>{EN.LoadaBetterDataset}</span>
              </div>
            </Show>
          </div>
        }
      </div>
    </div>
  );
}

export default observer(TimeSeriesSummary);
