import React, { useContext, useEffect } from 'react';
import { observer, MobXProviderContext, useLocalStore } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { action } from 'mobx';
import {
  CaretRightOutlined,
  PauseOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Select, InputNumber, Progress, Checkbox, Modal, Spin } from 'antd';
import moment from 'moment';
import styles from './styles.module.css';
import apiIcon from './icon-data-api.svg';
import sourceIcon from './icon-data-source.svg';
import databaseIcon from './icon-database.svg';
import fileIcon from './icon-file-local.svg';
import appIcon from './icon-inapp.svg';
import onceIcon from './icon-once.svg';
import Uploader from '../Uploader';
import { formatNumber } from '../../../util';
import EN from '../../../constant/en';
import { DeploymentStore } from 'stores/DeploymentStore';
import { UserStore } from 'stores/UserStore';
import { SocketStore } from 'stores/SocketStore';
import ApiInstruction from '../Deployment/ApiInstruction';
import { Domain, ProjectRolesKey } from '@app/constant/types';
import classNames from 'classnames';
import {
  DatabaseConfig,
  AutoRepeat,
  OneTime,
  Hint,
  ContinueButton,
  EmailAlert,
  Show,
} from 'components/Common';
import Email from '../Deployment/email.svg';

const { Option, OptGroup } = Select;
const ordinalNumberPostFix = number => {
  if ((number > 3 && number < 21) || number % 10 > 3) return 'th';
  return { 0: 'th', 1: 'st', 2: 'nd', 3: 'rd' }[number % 10];
};

const dateFormat = {
  day: () => '',
  week: number =>
    [
      '',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ][number],
  month: number => `${number}${ordinalNumberPostFix(number)}`,
};

interface Interface {
  match: any;
}

function Performance(props: Interface) {
  const { match } = props;
  // @ts-ignore
  const {
    userStore,
    deploymentStore: {
      currentDeployment: { projectId, modelName, bigData, modelType, loading },
      setUpdatedBySelf,
      currentDeployment,
      toggleEnable,
      deploySchedule,
      dataChange,
    },
    socketStore,
  }: {
    deploymentStore: DeploymentStore;
    userStore: UserStore;
    socketStore: SocketStore;
  } = useContext(MobXProviderContext);
  const history = useHistory();
  const store = useLocalStore(() => ({
    dialog: null,
    output: null,
    errorTimes: 0,
    uploadStatus: false as string | boolean,
    uploadPercentage: 0,
    modelEditing: false,
    emailSet: false,
    tempModelName: false,
    uploadOperator: null,
    uploadError: null,
    waiting: false,
    updateField(data) {
      Object.assign(this, data);
    },
  }));
  const selectionOption = async (key, value) => {
    currentDeployment.loading = true;
    const deepcopyPO = JSON.parse(
      JSON.stringify(currentDeployment.performanceOptions),
    );
    deepcopyPO[key] = value;
    setUpdatedBySelf();
    await currentDeployment.singleSave({ performanceOptions: deepcopyPO });
    // 修改deployments中的当前Deployment
    dataChange(currentDeployment.id, { performanceOptions: deepcopyPO });
    currentDeployment.loading = false;
  };

  const show = dialog => {
    store.updateField({
      dialog,
    });
  };

  const closeDialog = () => {
    store.updateField({
      dialog: null,
    });
  };

  const modelChange = tempModelName => {
    store.updateField({
      tempModelName,
    });
  };

  const showOutput = output => {
    store.updateField({
      output,
    });
  };

  const closeOutput = () => {
    store.updateField({
      output: null,
    });
  };

  const onSaveModel = async () => {
    let tempModelName = store.tempModelName;
    if (store.modelEditing && store.tempModelName) {
      currentDeployment.loading = true;
      currentDeployment.modelName = store.tempModelName;
      await currentDeployment.save();
      currentDeployment.loading = false;
      tempModelName = false;
    }

    store.updateField({
      modelEditing: !store.modelEditing,
      tempModelName,
    });
  };

  const pause = () => {
    if (!store.uploadOperator) return;
    if (store.uploadStatus === 'uploading') {
      socketStore.ready().then(api => {
        api.removeEventListener('offline', pause);
      });
      console.log('paused');
      store.uploadOperator.pause();
      store.updateField({
        uploadStatus: 'paused',
      });
      return;
    }
    store.uploadOperator.resume();
    store.updateField({
      uploadStatus: 'uploading',
    });
  };

  const cdpo: any = currentDeployment.performanceOptions;
  const uploader = {
    onError: (error, times = 1) => {
      socketStore.ready().then(api => {
        api.removeEventListener('offline', pause);
      });
      console.error(error);
      store.updateField({
        errorTimes: times,
        uploadStatus: 'error',
        uploadError: error,
      });
    },
    onFinished: async (response, file) => {
      currentDeployment.loading = true;
      socketStore.ready().then(api => {
        api.removeEventListener('offline', pause);
      });
      const deepcopyPO = JSON.parse(JSON.stringify(cdpo));
      deepcopyPO['file'] = file.name;
      deepcopyPO['fileId'] = response.originalIndex;
      deepcopyPO['source'] = 'file';
      store.updateField({
        uploadPercentage: 100,
        uploadStatus: false,
      });
      setUpdatedBySelf();
      await currentDeployment.singleSave({ performanceOptions: deepcopyPO });
      // 修改deployments中的当前Deployment
      dataChange(currentDeployment.id, { performanceOptions: deepcopyPO });
      currentDeployment.loading = false;
    },
    onProgress: (progress, speed) => {
      store.updateField({
        uploadPercentage: formatNumber(String(progress), 2),
        uploadStatus: 'uploading',
      });
    },
    onStart: opt => {
      socketStore.ready().then(api => {
        api.addEventListener('offline', pause);
      });
      store.updateField({
        uploadOperator: opt,
        uploadStatus: 'uploading',
        uploadPercentage: 0,
      });
    },
    operator: uploadOperator => {
      store.updateField({
        uploadOperator,
      });
    },
    params: {
      projectId,
      userId: userStore.info.id,
      type: 'deploy',
    },
    mapHeader: currentDeployment.mapHeader,
  };

  const updateFiled = (key, value) => {
    store.updateField({
      [key]: value,
    });
  };

  const noVersion = ['Outlier', 'Clustering'].includes(modelType);

  const deploy = () => {
    if (
      noVersion ||
      bigData ||
      (cdpo.frequency && currentDeployment?.version)
    ) {
      toggleEnable();
      history.push(`/deploy/project/${match.params.id}/status`);
      return deploySchedule(
        match.params.id,
        {
          type: cdpo.measurementMetric,
          value: cdpo.metricThreshold,
        },
        currentDeployment?.version,
      );
    }

    if (!noVersion && !bigData && !currentDeployment?.version) {
      store.updateField({
        waiting: true,
      });
    }
  };

  useEffect(() => {
    if (store.waiting && currentDeployment?.version) {
      deploy();
    }
  }, [store.waiting, currentDeployment?.version]);

  return (
    <Spin wrapperClassName={styles.loading} spinning={loading}>
      <div className={styles.performance}>
        <EmailAlert
          open={store.emailSet}
          Close={() => updateFiled('emailSet', false)}
        />
        {/* 广州银行隐藏邮件预警 (window as any).r2_env.domain !== Domain.GZCB*/}
        {(window as any).r2_env.domain !== Domain.GZCB && (
          <div
            className={styles.info}
            style={{
              fontSize: '1rem',
              padding: '1.2em',
              borderBottom: '1px dashed #d7d7d7',
              justifyContent: 'spaceBetween',
              display: 'flex',
            }}
          >
            <span style={{ flex: 2 }} className={styles.model}>
              {EN.CurrentModel}：{currentDeployment.modelName}
            </span>
            <div style={{ flex: 1 }}>
              <span className={styles.data}>
                {EN.PerformanceMonitorDataDefinition}
              </span>
              <Hint
                themeStyle={{ fontSize: '1rem' }}
                content={EN.ValidationDataDefinitionTip}
              />
              <a
                className={styles.download}
                target="_blank"
                href={`/r2upload/dataDefinition?projectId=${projectId}`}
              >
                &nbsp;&nbsp;{EN.Download}
              </a>
            </div>
            <div className={styles.email}>
              <img src={Email} alt="" />
              <a onClick={() => updateFiled('emailSet', true)}>
                {EN.EmailAlert}
              </a>
            </div>
          </div>
        )}

        <DeploymentOption
          cdpo={cdpo}
          selectionOption={selectionOption}
          bigData={currentDeployment.bigData}
          modelType={currentDeployment.modelType}
        />
        {cdpo.option === 'api' && (
          <ApiInstruction deployment={currentDeployment} type={'performance'} />
        )}

        {cdpo.option === 'data' && currentDeployment.modelType === 'Outlier' && (
          <div className={styles.blocks}>
            <span className={styles.labels}>
              {EN.Model}:{' '}
              {store.modelEditing ? (
                <Select
                  style={{ width: 400 }}
                  className={styles.selectionss}
                  value={store.tempModelName || modelName}
                  onChange={modelChange}
                >
                  {currentDeployment.modelList &&
                    Object.entries(currentDeployment.modelList).map(
                      ([settingName, models]: any) => (
                        <OptGroup key={settingName} label={settingName}>
                          {models.map(model =>
                            !!model ? (
                              <Option
                                key={model.modelId.model_name}
                                value={model.name}
                              >
                                {model.name}
                              </Option>
                            ) : null,
                          )}
                        </OptGroup>
                      ),
                    )}
                </Select>
              ) : (
                currentDeployment.modelName
              )}
            </span>
            <Hint
              themeStyle={{ fontSize: '1rem' }}
              content={currentDeployment?.currentModel?.performance}
            />
            <a className={styles.labels} onClick={onSaveModel}>
              {store.modelEditing ? EN.Save : EN.Change}
            </a>
          </div>
        )}

        {cdpo.option === 'data' &&
          currentDeployment.modelType === 'Clustering' && (
            <div className={styles.blocks}>
              <span className={styles.labels}>
                {EN.Model}:{' '}
                {store.modelEditing ? (
                  <Select
                    style={{ width: 400 }}
                    className={styles.selectionss}
                    value={store.tempModelName || currentDeployment.modelName}
                    onChange={modelChange}
                  >
                    {currentDeployment.modelList &&
                      Object.entries(currentDeployment.modelList).map(
                        ([settingName, models]: any) => {
                          let clusteringLists;
                          clusteringLists = models.filter(model => {
                            const model_name = model?.modelId?.model_name;
                            return (
                              model_name &&
                              model_name.indexOf('Agg') == -1 &&
                              model_name.indexOf('DBSCAN1') == -1 &&
                              model_name.indexOf('SpectralClustering') == -1
                            );
                          });
                          console.log(
                            clusteringLists,
                            'clusteringListsclusteringLists',
                          );
                          return (
                            <OptGroup key={settingName} label={settingName}>
                              {clusteringLists.map(
                                model =>
                                  model !== null && (
                                    <Option
                                      key={model.modelId}
                                      value={model.name}
                                    >
                                      {model.name}
                                    </Option>
                                  ),
                              )}
                            </OptGroup>
                          );
                        },
                      )}
                  </Select>
                ) : (
                  currentDeployment.modelName
                )}
              </span>
              <Hint
                themeStyle={{ fontSize: '1rem' }}
                content={currentDeployment.currentModel?.performance}
              />
              <a className={styles.labels} onClick={onSaveModel}>
                {store.modelEditing ? EN.Save : EN.Change}
              </a>
            </div>
          )}

        {!currentDeployment.bigData && cdpo.option === 'data' && (
          <div className={styles.block}>
            <span className={styles.label}>
              {/*  <span className={styles.text}>{EN.lidationDataDefinition}</span>*/}
              {/*  <Hint*/}
              {/*    themeStyle={{ fontSize: '1rem' }}*/}
              {/*    content={EN.ValidationDataDefinitionTip}*/}
              {/*  />*/}
              <span className={styles.text}>{EN.choosecharset}</span>
            </span>
            <div className={styles.selections}>
              {/*{!cd.bigData && <div style={{ display: "flex", marginRight: '4rem' }}>*/}
              {/*  <img*/}
              {/*    className={styles.downloadIcon}*/}
              {/*    src={downloadIcon}*/}
              {/*    alt="download"*/}
              {/*  />*/}
              {/*  <a*/}
              {/*    className={styles.download}*/}
              {/*    href={`/r2upload/dataDefinition?projectId=${cd.projectId}&type=performance`}*/}
              {/*  >*/}
              {/*    {EN.Download}*/}
              {/*  </a>*/}
              {/*</div>}*/}
              {/*<label className={styles.chooseCharset}>{EN.choosecharset}</label>*/}
              <Select
                style={{ width: '9rem' }}
                value={cdpo.charset}
                onChange={selectionOption.bind(null, 'charset')}
              >
                <Option value="auto">{EN.Auto}</Option>
                <Option value="utf-8">
                  <div dangerouslySetInnerHTML={{ __html: EN.UTF_8 }} />
                </Option>
                <Option value="gbk">{EN.GBK}</Option>
                <Option value="big5">{EN.BIG5}</Option>
              </Select>
            </div>
          </div>
        )}
        {cdpo.option === 'data' && (
          <DataSource
            cd={currentDeployment}
            cdpo={cdpo}
            show={k => show(k)}
            uploader={uploader}
            bigData={currentDeployment.bigData}
          />
        )}
        <Modal
          visible={!!store.uploadStatus}
          footer={null}
          width={700}
          maskClosable={false}
          closable={!!store.uploadOperator?.pause}
          onCancel={action(() => {
            updateFiled('uploadStatus', false);
            store.uploadOperator.pause();
          })}
        >
          <div className={styles.uploading}>
            <Progress
              percent={
                isNaN(+store.uploadPercentage)
                  ? 0
                  : parseFloat(String(store.uploadPercentage))
              }
            />
            <span className={styles.speed} />
            {store.uploadOperator?.pause && (
              <span className={styles.pause} onClick={pause}>
                {store.uploadStatus === 'uploading' ? (
                  <span>
                    <PauseOutlined />
                    {EN.Paused}
                  </span>
                ) : (
                  <span>
                    <CaretRightOutlined />
                    {EN.Resume}
                  </span>
                )}
              </span>
            )}
          </div>
          {store.uploadStatus === 'error' && (
            <div className={styles.uploadError}>
              {store.uploadError.toString()}
            </div>
          )}
        </Modal>
        {cdpo.option === 'data' && cdpo.source && (
          <MeasurementMetric
            cdpo={cdpo}
            type={currentDeployment.modelType}
            bigData={currentDeployment.bigData}
            selectionOption={selectionOption}
          />
        )}
        {cdpo.option === 'data' && cdpo.source && (
          <MetricThreshold
            cdpo={cdpo}
            type={currentDeployment.modelType}
            bigData={currentDeployment.bigData}
            selectionOption={selectionOption}
          />
        )}
        {cdpo.option === 'data' && cdpo.source && (
          <ResultLocation
            cdpo={cdpo}
            selectionOption={selectionOption}
            showOutput={showOutput}
            bigData={currentDeployment.bigData}
            modelType={currentDeployment.modelType}
          />
        )}
        {cdpo.option === 'data' && cdpo.source && cdpo.location && (
          <DeployFrequency
            cdpo={cdpo}
            selectionOption={selectionOption}
            show={k => show(k)}
          />
        )}
        <Show name={ProjectRolesKey.DeploymentOperation}>
          {cdpo.option === 'data' &&
            cdpo.source &&
            cdpo.location &&
            cdpo.frequency && (
              <div className={styles.block}>
                <div className={styles.selections}>
                  <span className={styles.label}>
                    <span className={styles.text} />
                  </span>
                  <ContinueButton
                    onClick={deploy}
                    disabled={store.waiting}
                    text={EN.DONE}
                  />
                </div>
              </div>
            )}
        </Show>

        <DatabaseConfig
          options={cdpo.sourceOptions}
          visible={store.dialog === 'databasesource'}
          validation
          projectId={projectId}
          mapHeader={currentDeployment.mapHeader}
          onClose={closeDialog}
          title={EN.ValidationDataSource + ' - ' + EN.Database}
          testApi={true}
          onSubmit={async options => {
            // options.csvLocation = options.result.result.csvLocation;
            // cdpo['file'] = options.sqlTable;
            currentDeployment.loading = true;
            const deepcopyPO = JSON.parse(JSON.stringify(cdpo));
            deepcopyPO['source'] = 'database';
            deepcopyPO['sourceOptions'] = options;
            setUpdatedBySelf();
            await currentDeployment.singleSave({
              performanceOptions: deepcopyPO,
            });
            // 修改deployments中的当前Deployment
            dataChange(currentDeployment.id, {
              performanceOptions: deepcopyPO,
            });
            currentDeployment.loading = false;
            closeDialog();
          }}
          bigData={currentDeployment.bigData}
          connectLocation="performanceSource"
        />
        <DatabaseConfig
          options={cdpo.locationOptions}
          visible={store.output === 'databasesource'}
          validation
          projectId={projectId}
          mapHeader={currentDeployment.mapHeader}
          onClose={closeOutput}
          sparkCheck
          title={EN.ValidationDataSource + ' - ' + EN.Database}
          testApi={true}
          skipTableCheck={true}
          onSubmit={async options => {
            // options.csvLocation = options.result.result.csvLocation;
            // cdpo['file'] = options.sqlTable;
            currentDeployment.loading = true;
            const deepcopyPO = JSON.parse(JSON.stringify(cdpo));
            deepcopyPO['location'] = 'database';
            deepcopyPO['locationOptions'] = options;
            console.log(options);

            setUpdatedBySelf();
            await currentDeployment.singleSave({
              performanceOptions: deepcopyPO,
            });
            // 修改deployments中的当前Deployment
            dataChange(currentDeployment.id, {
              performanceOptions: deepcopyPO,
            });
            currentDeployment.loading = false;
            closeOutput();
          }}
          bigData={currentDeployment.bigData}
          isOutput={true}
          connectLocation="performanceLocation"
        />
        <OneTime
          options={cdpo.frequencyOptions}
          visible={store.dialog === 'onetime'}
          onClose={closeDialog}
          onSubmit={action(async options => {
            currentDeployment.loading = true;
            closeDialog();
            const deepcopyPO = JSON.parse(JSON.stringify(cdpo));
            deepcopyPO['frequency'] = 'once';
            Object.keys(options).map(x => {
              deepcopyPO['frequencyOptions'][x] = options[x];
            });
            setUpdatedBySelf();
            await currentDeployment.singleSave({
              performanceOptions: deepcopyPO,
            });
            // 修改deployments中的当前Deployment
            dataChange(currentDeployment.id, {
              performanceOptions: deepcopyPO,
            });
            currentDeployment.loading = false;
          })}
        />
        <AutoRepeat
          options={cdpo.frequencyOptions}
          visible={store.dialog === 'autorepeat'}
          onClose={closeDialog}
          onSubmit={action(async options => {
            currentDeployment.loading = true;
            closeDialog();
            const deepcopyPO = JSON.parse(JSON.stringify(cdpo));
            deepcopyPO['frequency'] = 'repeat';
            Object.keys(options).map(x => {
              if (x !== 'updateField')
                deepcopyPO['frequencyOptions'][x] = options[x];
            });
            setUpdatedBySelf();
            await currentDeployment.singleSave({
              performanceOptions: deepcopyPO,
            });
            // 修改deployments中的当前Deployment
            dataChange(currentDeployment.id, {
              performanceOptions: deepcopyPO,
            });
            currentDeployment.loading = false;
          })}
        />
      </div>
    </Spin>
  );
}

const DeploymentOption = observer(
  ({ cdpo, selectionOption, bigData, modelType }) => (
    <div className={styles.block}>
      <span className={styles.label}>
        <span className={styles.text}>{EN.DeploymentOptionText}:</span>
      </span>
      <div className={styles.selections}>
        {cdpo.option === 'api' && (
          <div className={styles.selected}>
            <span className={styles.text}>
              <img alt="api" src={apiIcon} className={styles.selectionIcon} />
              {EN.PredictWithAPI}
            </span>
            <span className={styles.or}>
              <span className={styles.orText}>{EN.Or}</span>
            </span>
          </div>
        )}
        {cdpo.option === 'data' && (
          <div className={styles.selected}>
            <span className={styles.text}>
              <img
                alt="data source"
                src={sourceIcon}
                className={styles.selectionIcon}
              />
              {EN.PredictWithDataSource}
            </span>
            <span className={styles.or}>
              <span className={styles.orText}>{EN.Or}</span>
            </span>
          </div>
        )}
        {cdpo.option !== 'data' && (
          <div
            className={styles.selection}
            onClick={() => selectionOption('option', 'data')}
          >
            <span className={styles.text}>
              <img
                alt="data source"
                src={sourceIcon}
                className={styles.selectionIcon}
              />
              {EN.PredictWithDataSource}
            </span>
          </div>
        )}
        {cdpo.option !== 'api' && (
          <div
            className={styles.selection}
            onClick={() => selectionOption('option', 'api')}
          >
            <span className={styles.text}>
              <img alt="api" src={apiIcon} className={styles.selectionIcon} />
              {EN.PredictWithAPI}
            </span>
          </div>
        )}
      </div>
    </div>
  ),
);

const DataSource = observer(({ cd, cdpo, show, uploader, bigData }) => (
  <div className={styles.block}>
    <span className={styles.label}>
      <span className={styles.text}>{EN.DataSourceText}:</span>
    </span>
    <div className={styles.selections}>
      {cdpo.source === 'database' && (
        <div className={styles.selected} onClick={() => show('databasesource')}>
          <span className={styles.result}>
            <img
              alt="database"
              src={databaseIcon}
              className={styles.selectionIcon}
            />
            <span className={styles.resultText}>
              {EN.Database}
              <span className={styles.path}>{cdpo.sourceOptions.sqlTable}</span>
            </span>
          </span>
          {!bigData && (
            <span className={styles.or}>
              <span className={styles.orText}>{EN.Or}</span>
            </span>
          )}
        </div>
      )}
      {!bigData && cdpo.source === 'file' && (
        <div className={styles.selected}>
          <Uploader className={styles.resultText} {...uploader}>
            <img alt="file" src={fileIcon} className={styles.selectionIcon} />
            {EN.LocalFile}
            <span className={styles.path} title={cdpo.file}>
              {cdpo.file}
            </span>
          </Uploader>
          <span className={styles.or}>
            <span className={styles.orText}>{EN.Or}</span>
          </span>
        </div>
      )}

      {cdpo.source !== 'database' && (
        <div
          className={styles.selection}
          onClick={() => show('databasesource')}
        >
          <span className={styles.text}>
            <img
              alt="database"
              src={databaseIcon}
              className={styles.selectionIcon}
            />
            {EN.Database}
          </span>
        </div>
      )}
      {!bigData && cdpo.source !== 'file' && (
        <div className={styles.selectionWithoutHover} id="file">
          <Uploader className={styles.text} {...uploader}>
            <img alt="file" src={fileIcon} className={styles.selectionIcon} />
            {EN.LocalFile}
          </Uploader>
        </div>
      )}
    </div>
  </div>
));

const MeasurementMetric = observer(
  ({ cdpo, selectionOption, type, bigData }) => (
    <div className={styles.block}>
      <span className={styles.label}>
        <span className={styles.text}>{EN.MeasurementMetric}: </span>
      </span>
      <div className={styles.selections}>
        {type === 'Classification' && (
          <Select
            className={styles.select}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            value={cdpo.measurementMetric}
            onChange={value => selectionOption('measurementMetric', value)}
          >
            <Option value="AUC">{EN.AUC}</Option>
            <Option value="F1">F1</Option>
          </Select>
        )}
        {type === 'Regression' && (
          <Select
            className={styles.select}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            value={cdpo.measurementMetric}
            onChange={value => selectionOption('measurementMetric', value)}
          >
            <Option value="R2">R²</Option>
            <Option value="RMSE">{EN.RMSE}</Option>
            <Option value="MSE">MSE</Option>
          </Select>
        )}

        {type === 'Outlier' && (
          <Select
            className={styles.select}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            value={cdpo.measurementMetric}
            onChange={value => selectionOption('measurementMetric', value)}
          >
            <Option value="Accuracy">{EN.Accuracy}</Option>
          </Select>
        )}

        {type === 'Clustering' && (
          <Select
            className={styles.select}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            value={cdpo.measurementMetric}
            onChange={value => selectionOption('measurementMetric', value)}
          >
            {!bigData && <Option value="CVNN">CVNN</Option>}
            {!bigData && <Option value="CH">CH Index</Option>}
            <Option value="silhouette_euclidean">Silhouette Score</Option>
          </Select>
        )}
        {type === 'MultiClassification' && (
          <Select
            className={styles.select}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            value={cdpo.measurementMetric}
            onChange={value => selectionOption('measurementMetric', value)}
          >
            <Option value="macro_auc">Macro AUC</Option>
            <Option value="micro_auc">Micro AUC</Option>
            <Option value="macro_f1">Macro F1</Option>
            <Option value="micro_f1">Micro F1</Option>
            <Option value="macro_recall">Macro Recall</Option>
            <Option value="micro_recall">Micro Recall</Option>
            <Option value="macro_precision">Macro Precision</Option>
            <Option value="micro_precision">Micro Precision</Option>
          </Select>
        )}
      </div>
    </div>
  ),
);

const MetricThreshold = observer(({ cdpo, selectionOption, type, bigData }) =>
  type === 'Outlier' && bigData ? null : (
    <div className={styles.block}>
      <span className={styles.label}>
        <span className={styles.text}>{EN.MetricThreshold}:</span>
      </span>
      <div className={styles.selections}>
        <InputNumber
          className={styles.inputNumber}
          min={0}
          max={1}
          step={0.1}
          value={cdpo.metricThreshold}
          onChange={value => {
            selectionOption('metricThreshold', value || 0);
          }}
        />
      </div>
    </div>
  ),
);

const DeployFrequency = observer(({ cdpo, selectionOption, show }) => (
  <>
    <div className={styles.block}>
      <span className={styles.label}>
        <span className={styles.text}>{EN.DeployFrequency}:</span>
      </span>
      <div className={styles.selections}>
        {cdpo.frequency === 'once' && (
          <div className={styles.selected} onClick={() => show('onetime')}>
            <span className={styles.result}>
              <img alt="once" src={onceIcon} className={styles.selectionIcon} />
              <span className={styles.resultText}>
                {EN.OneTime}
                <span className={styles.detail}>
                  <span className={styles.bold}>{EN.Times}</span>
                  {cdpo.frequencyOptions.time === 'completed'
                    ? EN.Aftercompleted
                    : moment
                        .unix(cdpo.frequencyOptions.time)
                        .format('MM/DD/YYYY h:mma')}
                </span>
              </span>
            </span>
            <span className={styles.or}>
              <span className={styles.orText}>{EN.Or}</span>
            </span>
          </div>
        )}
        {cdpo.frequency === 'repeat' && (
          <div className={styles.selected} onClick={() => show('autorepeat')}>
            <span className={styles.result}>
              <SyncOutlined className={styles.antdIcon} />
              <span className={styles.resultText}>
                {EN.Redeployevery}{' '}
                {`${cdpo.frequencyOptions.repeatFrequency} ${
                  cdpo.frequencyOptions.repeatPeriod
                } ${
                  cdpo.frequencyOptions.repeatPeriod !== 'day' ? 'on' : ''
                } ${cdpo.frequencyOptions.repeatPeriod &&
                  dateFormat[cdpo.frequencyOptions.repeatPeriod](
                    cdpo.frequencyOptions.repeatOn,
                  )}`}
                <small className={styles.detail}>
                  <span className={styles.bold}>{EN.Starts}:</span>
                  {moment
                    .unix(cdpo.frequencyOptions.starts)
                    .format('MM/DD/YYYY h:mma')}
                  <br />
                  <span className={styles.bold}>{EN.Ends}:</span>
                  {cdpo.frequencyOptions.ends === 'never'
                    ? EN.NeverS
                    : cdpo.frequencyOptions.ends > 10000
                    ? moment
                        .unix(cdpo.frequencyOptions.ends)
                        .format('MM/DD/YYYY h:mma')
                    : `after ${cdpo.frequencyOptions.ends} occurrences`}
                </small>
              </span>
            </span>
            <span className={styles.or}>
              <span className={styles.orText}>{EN.Or}</span>
            </span>
          </div>
        )}
        {cdpo.frequency !== 'once' && (
          <div className={styles.selection} onClick={() => show('onetime')}>
            <span className={styles.text}>
              <img alt="once" src={onceIcon} className={styles.selectionIcon} />
              {EN.OneTime}
            </span>
          </div>
        )}
        {cdpo.frequency !== 'repeat' && (
          <div className={styles.selection} onClick={() => show('autorepeat')}>
            <span className={styles.text}>
              <SyncOutlined className={styles.antdIcon} />
              {EN.AutoRepeat}
            </span>
          </div>
        )}
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.holder} />
      <div className={styles.checkbox}>
        <Checkbox
          checked={cdpo.dataReport}
          // disabled
          // checked
          onChange={e => selectionOption('dataReport', e.target.checked)}
        >
          <span className={styles.checkboxText}>{EN.DataQualityReport}</span>
        </Checkbox>

        <Checkbox
          checked={cdpo.autoDisable}
          onChange={e => selectionOption('autoDisable', e.target.checked)}
        >
          <span className={styles.checkboxText}>
            {EN.Autodisableifanyissueoccurs}
          </span>
        </Checkbox>
      </div>
    </div>
  </>
));

const ResultLocation = observer(
  ({ cdpo, selectionOption, showOutput, bigData, modelType }) => (
    <div className={styles.block}>
      <span className={styles.label}>
        <span className={styles.text}>{EN.ResultLocation}:</span>
      </span>
      <div className={styles.selections}>
        {cdpo.location === 'app' && (
          <div className={styles.selected}>
            <span className={styles.text}>
              <img alt="app" src={appIcon} className={styles.selectionIcon} />
              {EN.InApp}
            </span>
            {bigData && cdpo.location === 'database' && (
              <span className={styles.or}>
                <span className={styles.orText}>{EN.Or}</span>
              </span>
            )}
          </div>
        )}
        {cdpo.location === 'database' && bigData && (
          <div
            className={styles.selected}
            onClick={() => showOutput('databasesource')}
          >
            <span className={styles.result}>
              <img
                alt="database"
                src={databaseIcon}
                className={styles.selectionIcon}
              />
              <span className={styles.resultText}>
                {EN.Database}
                <span className={styles.path}>
                  {cdpo.locationOptions.sqlTable}
                </span>
              </span>
            </span>
            <span className={styles.or}>
              <span className={styles.orText}>{EN.Or}</span>
            </span>
          </div>
        )}
        {cdpo.location !== 'app' && (
          <div
            className={styles.selection}
            onClick={() => selectionOption('location', 'app')}
          >
            <span className={styles.text}>
              <img alt="app" src={appIcon} className={styles.selectionIcon} />
              {EN.InApp}
            </span>
          </div>
        )}
        {cdpo.location !== 'database' && bigData && (
          <div
            className={styles.selection}
            onClick={() => showOutput('databasesource')}
          >
            <span className={styles.text}>
              <img
                alt="database"
                src={databaseIcon}
                className={styles.selectionIcon}
              />
              {EN.Database}
            </span>
          </div>
        )}
      </div>
    </div>
  ),
);

export default observer(Performance);
