import React, { ReactElement } from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import Project from 'stores/Project';
import { formatNumber } from '../../util';

interface Interface {
  message: {
    avg: {
      data: any;
      name: {
        x: string;
      };
    };
    freq: {
      data: any;
      name: {
        x: string;
      };
    };
  };
  project: Project;
}

export default function MIBoxPlots(props: Interface): ReactElement {
  const {
    message: {
      avg: {
        name: { x },
        data: avg_data,
      },
      freq: { data: freq_data },
    },
    project: { mapHeader, colType, colMap, newType },
  } = props;

  const avg_boxData = [],
    freq_boxData = [],
    avg_x_keys = [],
    freq_x_keys = [],
    avg_freqs: number[] = [],
    freq_freqs: number[] = [];

  const Axis = {
    name: mapHeader[x] || x,
    boundaryGap: true,
    nameGap: 30,
    splitArea: {
      show: false,
    },
    axisLabel: {
      interval: 0,
      rotate: 30,
    },
    splitLine: {
      show: false,
    },
    type: 'category',
  };
  const avg_xAxis: any = {
    ...Axis,
    data: avg_data.map(itm => itm.x.toFixed(6)),
  };
  const freq_xAxis: any = {
    ...Axis,
    data: freq_data.map(itm => itm.x.toFixed(6)),
  };

  avg_data.forEach(itm => {
    const { data, x, freq } = itm;
    const dt = _.cloneDeep(data.map(itm => itm.toFixed(6)));
    avg_boxData.push([...dt, freq.toFixed(6), freq]);
    avg_x_keys.push(mapHeader[x] || x);
    avg_freqs.push(freq);
  });

  freq_data.forEach(itm => {
    const { data, x, freq } = itm;
    const dt = _.cloneDeep(data.map(itm => itm.toFixed(6)));
    freq_boxData.push([...dt, freq.toFixed(6), freq]);
    freq_x_keys.push(mapHeader[x] || x);
    freq_freqs.push(freq);
  });

  const CType = Object.assign({}, colType, newType);

  if (CType[x] === 'Categorical' || !colType[x]) {
    const ent = Object.entries(colMap[x] || {});
    let _data = [];
    avg_data.forEach(itm => {
      const type = ent.filter(it => it[1] === itm.x);
      if (type[0] && !_data.includes(type[0][0])) {
        _data.push(type[0][0]);
      } else {
        _data.push(itm.x);
      }
    });
    avg_xAxis.data = _data;

    _data = [];
    freq_data.forEach(itm => {
      const type = ent.filter(it => it[1] === itm.x);
      if (type[0] && !_data.includes(type[0][0])) {
        _data.push(type[0][0]);
      } else {
        _data.push(itm.x);
      }
    });
    freq_xAxis.data = _data;
  }

  const avg_max = Math.max(...avg_freqs)+0.01;
  const avg_min = Math.min(...avg_freqs)-0.01;

  const freq_max = Math.max(...freq_freqs)+0.01;
  const freq_min = Math.min(...freq_freqs)-0.01;

  const _series = {
    type: 'boxplot',
    tooltip: {
      formatter: param => {
        return [
          param.name + ': ',
          'max: ' + param.data[5],
          'q75: ' + param.data[4],
          'median: ' + param.data[3],
          'q25: ' + param.data[2],
          'min: ' + param.data[1],
          'mean: ' + param.data[6],
          'freq:' + param.data[7],
        ].join('<br/>');
      },
    },
  };

  const visualMap = {
    orient: 'horizontal',
    left: 'center',
    inRange: {
      color: ['#80bdfd', '#b0e39b'],
    },
    formatter: value => {
      return value.toFixed(3);
    },
    dimension: 7,
  };

  const axisLabel = {
    formatter: function (value) {
      return formatNumber(value,1)
    }
  };

  const option:any =  {
    baseOption: {
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        right: '10%',
        bottom: '25%',
      },
      yAxis: {
        type: 'value',
        name: '',
        splitArea: {
          show: true,
        },
        axisLabel,
      },
    },
    timeline: {
      axisType: 'category',
      loop: false,
      data: ['Avg', 'Freq'],
      controlStyle: {
        show: false,
      },
      right: '85%',
      left: '5%',
    },
    options: [
      {
        xAxis: avg_xAxis,
        visualMap: {
          ...visualMap,
          min: avg_min,
          max: avg_max,
        },
        series: {
          ..._series,
          data: avg_boxData,
        },
      },
      {
        xAxis: freq_xAxis,
        visualMap: {
          ...visualMap,
          min: freq_min,
          max: freq_max,
        },
        series: {
          ..._series,
          data: freq_boxData,
        },
      },
    ],
  };
  return (
    <ReactEcharts
      option={option}
      style={{ height: '100%', width: '100%' }}
      notMerge={true}
      lazyUpdate={true}
      theme="customed"
    />
  );
}
