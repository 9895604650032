import dependece from '@app/assets/explain/dependence.jpg';
import importance from '@app/assets/explain/importance.jpg';
import tree from '@app/assets/explain/tree.jpg';
import shapScatter from '@app/assets/explain/shapScatter.png';
import shapImportance from '@app/assets/explain/shapImportance.png';
import shapSingleSample from '@app/assets/explain/shapSingleSample.png';
import lime from '@app/assets/explain/lime.png';

export enum ExplainViewType {
  ftImportance = 'ftImportance',
  dependence = 'dependence',
  tree = 'tree',
  shapScatter = 'shapScatter',
  shapImportance = 'shapImportance',
  shapSingleSample = 'shapSingleSample',
  lime = 'lime',
}

export enum TypeClassification {
  global = 'global',
  part = 'part',
}

export const TypeClassificationLabel = {
  [TypeClassification.global]: '全局解释',
  [TypeClassification.part]: '局部解释',
}

export const TypeClassificationHint = {
  [TypeClassification.global]: '全局解释是解释模型的全局行为，即从全量数据的角度洞察预测变量和目标变量间关系。',
  [TypeClassification.part]: '局部解释是解释具体样本的行为，即从单条数据的角度洞察预测变量和目标变量间关系.',
}

export const TypeClassificationMap = {
  [ExplainViewType.ftImportance]: TypeClassification.global,
  [ExplainViewType.dependence]: TypeClassification.global,
  [ExplainViewType.tree]: TypeClassification.global,
  [ExplainViewType.shapScatter]: TypeClassification.global,
  [ExplainViewType.shapImportance]: TypeClassification.global,
  [ExplainViewType.shapSingleSample]: TypeClassification.part,
  [ExplainViewType.lime]: TypeClassification.part,
}

export const ExplainViewLabel = {
  [ExplainViewType.ftImportance]: '特征重要性',
  [ExplainViewType.dependence]: '部分依赖性',
  [ExplainViewType.tree]: '树模型解释',
  [ExplainViewType.shapScatter]: 'SHAP_密度散点',
  [ExplainViewType.shapImportance]: 'SHAP_重要性',
  [ExplainViewType.shapSingleSample]: 'SHAP_单样本',
  [ExplainViewType.lime]: 'LIME',
}

export interface ftImportancePlotData {
	featureImportance: {},
	importanceCoefficient: {},
}

export interface dependencePlotData {
  data: Array<{
    x: string,
    y: number,
    freq: number,
    data: number,
  }>,
  name: {
    x: string,
    y: string,
  },
  option: {
    featureLabel: string,
    target: string,
  },
}
export interface treePlotData {
  image: string;
  option: TreeExplainOption,
}

export interface shapScatterPlotData {
  image: string;
}

export interface shapImportancePlotData {
  image: string;
}

export interface shapSingleSamplePlotData {
  image: string;
  option: ShapSingleSampleExplainOption,
  htmlLocation: string
}

export interface limePlotData {
  image: string;
  option: ShapSingleSampleExplainOption,
  htmlLocation: string
}

export interface updatePlotData {
  plotData: limePlotData | shapSingleSamplePlotData
}

export interface BaseExplain {
  id: number;
  projectId: number;
  modelId: number;
  explainModelName: string;
  explaining: boolean;
  viewType: ExplainViewType;
  plotData: any;
  errorMsg: string;
  created_by: number;
  updated_by: number;
  created_at: Date;
  updated_at: Date;
  isMock?: boolean;
}

export interface FtImportanceExplain extends BaseExplain {
  viewType: ExplainViewType.ftImportance;
  plotData: ftImportancePlotData;
}

export interface DependenceExplain extends BaseExplain {
  viewType: ExplainViewType.dependence;
  plotData: dependencePlotData;
}

export interface TreeExplain extends BaseExplain {
  viewType: ExplainViewType.tree;
  plotData: treePlotData;
}

export interface ShapScatterExplain extends BaseExplain {
  viewType: ExplainViewType.shapScatter;
  plotData: shapScatterPlotData;
}

export interface ShapImportanceExplain extends BaseExplain {
  viewType: ExplainViewType.shapImportance;
  plotData: shapImportancePlotData;
}

export interface ShapSingleSampleExplain extends BaseExplain {
  viewType: ExplainViewType.shapSingleSample;
  plotData: shapSingleSamplePlotData;
}

export interface LimeExplain extends BaseExplain {
  viewType: ExplainViewType.lime;
  plotData: limePlotData;
}

export type Explain = FtImportanceExplain | DependenceExplain | TreeExplain | ShapScatterExplain | ShapImportanceExplain | ShapSingleSampleExplain | LimeExplain;

interface BaseExplainParam {
	projectId: number,
	// viewType: ExplainViewType,
}

interface CustomExplainParam {
  plotType: ExplainViewType.ftImportance | ExplainViewType.lime | ExplainViewType.shapImportance | ExplainViewType.shapScatter | ExplainViewType.shapSingleSample
}

export interface TreeExplainOption {
	crit: string,
	split: string,
	depth: number,
	min_split: number,
	min_leaf: number,
}
export interface DPDExplainOption {
	featureLabel: string,
	target: string,
}
export interface ShapSingleSampleExplainOption {
	data_index: number,
	random_sample: number
}

interface ShapSingleSampleExplainParam extends BaseExplainParam {
  plotType: ExplainViewType.shapSingleSample,
  explainOption: ShapSingleSampleExplainOption,
}

export interface LimeExplainOption {
	data_index: number,
	random_sample: number
}
interface LimeExplainParam extends BaseExplainParam {
  plotType: ExplainViewType.lime,
  explainOption: LimeExplainOption,
}

interface TreeExplainParam extends BaseExplainParam {
  plotType: ExplainViewType.tree,
  explainOption: TreeExplainOption,
}

interface DependenceExplainParam extends BaseExplainParam {
  plotType: ExplainViewType.dependence,
  explainOption: DPDExplainOption,
}

export type ExplainParam = CustomExplainParam | TreeExplainParam | DependenceExplainParam | ShapSingleSampleExplainParam | LimeExplainParam;


const BaseMockData: BaseExplain = {
  id: 0,
  projectId: 0,
  modelId: 0,
  explainModelName: 'mock',
  explaining: false,
  viewType: ExplainViewType.ftImportance,
  plotData: {},
  errorMsg: '',
  created_by: 0,
  updated_by: 0,
  created_at: new Date(),
  updated_at: new Date(),
}

export const mockExplainData: Record<ExplainViewType, Explain> = {
  [ExplainViewType.ftImportance]: {
    ...BaseMockData,
    id: 101,
    viewType: ExplainViewType.ftImportance,
    plotData: {
      featureImportance: {},
      importanceCoefficient: {},
    },
    isMock: true,
  },
  [ExplainViewType.dependence]: {
    ...BaseMockData,
    id: 102,
    viewType: ExplainViewType.dependence,
    plotData: {
      data: [
        { x: '0', y: 0, freq: 0.821, data: 0.179 },
        { x: '0', y: 1, freq: 0, data: 0.179 },
        { x: '1', y: 0, freq: 0, data: 0.179 },
        { x: '1', y: 1, freq: 0.179, data: 0.179 },
      ],
      name: {
        x: "loan_condition_cat",
        y: "loan_condition",
      },
      option: {
        featureLabel: "id",
        target: "loan_condition",
      },
    },
    isMock: true,
  },
  [ExplainViewType.tree]: {
    ...BaseMockData,
    id: 103,
    viewType: ExplainViewType.tree,
    plotData: {
      image: tree,
      option: {
        crit: "gini",
        split: "best",
        depth: 4,
        min_split: 2,
        min_leaf: 1,
      }
    },
    isMock: true,
  },
  [ExplainViewType.shapScatter]: {
    ...BaseMockData,
    id: 104,
    viewType: ExplainViewType.shapScatter,
    plotData: {
      image: shapScatter,
    },
    isMock: true,
  },
  [ExplainViewType.shapImportance]: {
    ...BaseMockData,
    id: 105,
    viewType: ExplainViewType.shapImportance,
    plotData: {
      image: shapImportance,
    },
    isMock: true,
  },
  [ExplainViewType.shapSingleSample]: {
    ...BaseMockData,
    id: 106,
    viewType: ExplainViewType.shapSingleSample,
    plotData: {
      image: null,
      htmlLocation: null,
      option: {
        data_index: null,
        random_sample: 1
      }
    },
    isMock: true,
  },
  [ExplainViewType.lime]: {
    ...BaseMockData,
    id: 107,
    viewType: ExplainViewType.lime,
    plotData: {
      image: null,
      htmlLocation: null,
      option: {
        data_index: null,
        random_sample: 1
      }
    },
    isMock: true,
  },
}

const importanceDescribe = {
  type: 'horizontal',
  image: importance,
  describe: '邮件点击率预测案例中，点击率为我们的目标变量，其余特征为预测变量。如左图所示，与预测变量相关性最强的三个特征分别为“邮件发送时间”、“用户历史购买产品次数”及“邮件ID”，用户使用此功能模块过程中，可根据需求重点关注与目标变量相关性高的特征，从而调整运营策略',
}

const dependenceDescribe = {
  type: 'horizontal',
  image: dependece,
  describe: `通过邮件点击率预测模型来进行模型解释性讲解：假设某公司通过发送市场邮件的方式触达客户，并希望提升客户对邮件内公司链接的点击率。业务部门希望您提供关于如何提升邮件点击率的方法。

  邮件点击率预测模型为例，现实场景中，很少有业务方能够运用“从特征重要性来看，邮件发送时间是很重要的特征”、“用户来自的地区很重要”这类模型洞察结果。相比上述内容而言，业务方更感兴趣的是了解“邮件在什么时间发送效果最好”，“哪些国家/地区对邮件点击更敏感”。

  在左侧的部分依赖图中，预测变量为“用户所属的国家”，目标变量为“邮件点击率”，我们希望结合业务经验分析“哪个国家/地区对邮件点击更敏感”。如图所示，美国和英国在邮件点击敏感度中表现最好；除此之外，以先验业务经验为判断，法国和英国作为对比国家，点击率的敏感程度不应该差太多，这里可能是邮件发送时翻译的准确度或某些原因出了问题，这些问题将会是具体执行提升邮件点击率方案时关注的方向
  `,
}
const treeDescribe = {
  type: 'vertical',
  image: tree,
  describe: `全局解释_树模型解释_参数设置

  树模型解释图中，非叶节点包含四个值，叶节点包含三个值，自上而下为：
  ①分裂点：决策树向下细分的分裂阈值，叶节点不含此项；
  ②基尼系数：代表节点的纯净性。0.5为随机推测，是最差的取值结果；0为最优分类，是最好取值结果。这里我们需要寻找基尼系数接近于0的取值
  ③样本：该节点占据总样本数量的比例，样本比例越高越好，其意味着该节点非常重要，覆盖了众多样本点
  ④取值：代表了“标签0”与“标签1”的比例，两部分比例之和始终为1。同基尼系数类似，它会告诉我们节点的纯净度，理想状态下，我们希望寻找到两个取值，一个接近于1，另一个接近于0，这种情况下基尼系数也会很小

  视图中自上而下为例，在最上方的节点中(根节点)包含全量数据，此案例数据中标签0与标签1的比例为50/50[平衡后结果]，基尼系数为0.5，意味着整个样本空间初始是纯粹的随机推测。从此节点开始，第一个分裂点为判定特征“用户历史购买次数”是否超过3.5次：

  - 如果某用户“历史购买次数”小于3.5次，则跟随左侧箭头至第二层左节点。此节点基尼系数为0.44，占据总样本的52.7%。在这52.7%的样本中，67.4%的用户没有触发点击行为，32.6%的用户点击了邮件链接。节点分裂相比最初的全量数据，帮助我们定位到更精细的分群
  - 如果某用户“历史购买次数”大于3.5次，则跟随右侧箭头至第二层右节点。此节点基尼系数为0.474，占据总样本的47.3%。其中class0与class1的比例为38.6%与61.4%。这里我们发现了第一个点击率相对非常高的细分群，细分群的定位可以帮助我们用更少的精力解决业务问题与变现潜在商机。

  随着自上而下的分裂，最终树模型解释会触达叶节点，即树最终的分类方法。举例来说，自上而下的每层分类策略，所触达的用户分别为：
  ①“用户历史购买次数”是否小于3.5次
  ②“用户历史购买次数”是否大于0.5次
  ③“个性化邮件”策略是否采用
  ④“用户所在国家”是否为法国

  以最下层左侧节点为例，此部分用户占据了全量数据的17.5%，在这些用户中，68.9%没有点击邮件链接，31.1点击了邮件链接。如果新用户路径分类截止到此节点，将会预测邮件未被点击。

  模型洞察：
  截止目前各分裂层的分裂结果，第一层的分裂点阐述了最重要的模型洞察含义。这里模型告诉我们，最重要的分群策略是”用户历史购买行为”是否超过三次。因此，提升“用户超过三次的历史购买行为”占比会是公司非常好的全年目标之一。【魔法数字】

  在第三层的最左侧节点中，如果用户没有发生过购买行为，则树模型将不会向下分裂。这意味着如果用户不曾进行购买，其余的特征维度与目标变量会毫无相关性。这里我们改变邮件发送的日期，时间，主题都将毫无意义；对于此部分用户，下一步的计划是重新定制与之前完全不同的邮件发送方式和邮件信息策略。这部分是非常困难的获客，但是也具备用户价值；这部分用户触发了我们发送邮件的策略，对现在的活动感兴趣，但是从来没有购买行为。了解他们为什么感兴趣却未进行购买，可以帮助我们更好的感知客群需求，并进行客群转化
  【新获客场景】

  树模型解释图的右侧，如果用户有超过7次的历史购买行为，当用户收到市场邮件时，大概率会打开邮件中的链接并查阅内容，其余的特征维度对目标变量的影响很小，说明此类用户群体已经对公司品牌和产品有了一定的忠诚度。对于此类用户，营销的形式已经不再关键，只要能让客户时刻关注到公司的产品和活动即可。而此类用户多是公司利润贡献的主要来源，我们更应关注此类客户的关系维护和流失预警，如邮件开头部分是否通过用户姓名打招呼，选择“是”的分支，都明显出现了点击率上升的趋势，我们就可以在有限条件下进行邮件信息策略的调整。
  【高价值客户营销】【高价值客户流失预警】

  在上述树模型解释中，没有除上述触达策略外的分裂点。这里是因为我们搭建的是深度较小的树模型，所以树结构仅关注宏观的信息。举例来说，左侧树模型结构图中，并未告诉我们邮件发送的最佳时间。如果我们在已触达的最下层的节点中加入“邮件发送时间”的策略判定，节点将会基于前向策略告诉我们最佳邮件发送时间的细分客群及表现效果，运营团队后续可针对分裂节点策略组合进行点击率的方案优化

  使用说明示例：
  用户在进行模型洞察并分析节点时，可以得出以下直观结论：
  细分节点占总样本比例的X%，且它们的点击次数为Y，如果我们采取某种分裂方式【分类策略】，可以期望点击率结果增加至Z%
  `,
}
const shapScatterDescribe = {
  type: 'horizontal',
  image: shapScatter,
  describe: `在左侧SHAP_密度散点图中，横坐标为shap值，特征自上而下按shap平均绝对值排序；如图所示，“用户历史购买次数”和“个性化邮件”策略为最重要的两个特征。
  图中每个点代表一个样本，颜色越红说明特征本身数值越大，颜色越蓝数值越小；此外，宽的地方表示样本大量聚集，分布越分散表示特征影响越大。

  以最重要的特征为例，“用户历史购买次数”分布非常分散，中轴左侧的蓝色代表用户未发生购买行为或购买次数较少；对于为产生购买行为的用户，我们观察到对应的SHAP值非常小，尤其是最左侧集中的蓝色部分；这部分内容解读为，对于未发生过购买行为的用户，非常容易忽略点击邮件中的链接，但此部分用户属于我们邮件的触达用户；了解用户有兴趣但未产生购买的原因，是我们建议后期获客策略调整的方向之一
  `,
}

const shapImportanceDescribe = {
  type: 'horizontal',
  image: shapImportance,
  describe: `
  在左侧SHAP_重要性中，横坐标为shap值，特征自上而下按shap平均绝对值排序，标签“1”的重要度用蓝色表示，标签“0”的重要度按红色表示；如图所示，与目标变量相关性最强的预测变量为“用户历史购买次数”、“用户所在地是否为美国”、“个性化邮件”；客户使用此功能模块过程中，可根据需求重点关注与目标变量相关性高的特征，从而调整运营策略；
  `,
}

const shapSingleSampleDescribe = {
  type: 'vertical',
  image: shapSingleSample,
  describe: `SHAP_单样本中，展示图为您所选的数据点预测值，在此案例中为邮件触达的某价值用户点击邮件链接的概率。
  红色部分为正相关变量，蓝色部分为负相关变量，预测变量长度受重要性影响，预测变量长度越长代表对目标变量影响越强烈，反之越弱 ；

  在当前案例中，目标变量为用户是否会打开邮件中的链接，若预测概率大于设定阈值(通常为0.5)，判断为用户会打开邮件中的触达链接；预测概率小于设定阈值，则认为用户不会打开链接

  图示变量的预测点击率为0.91，即该用户非常有可能打开邮件链接；在所选的客户画像中，可以发现用户曾经通过邮件链接购买过8次商品，属于忠诚度较高的粘性客户，对商品和公司非常关注，这是模型预测该客户会点击的主要原因；其次，在购买多次的基础上，邮件以个性化的方式在周三发送到客户邮箱，这种触达策略很可能让客户有时间观看邮件并且愿意点击链接

  结合其他可解释性方法我们可以得出结论，在周五及非工作日通过邮件触达客户看起来效果不是很好，周中看起来效果不错，但总体而言，周一到周四表现差不多；运营团队应该花时间在周一至周四进行信息推广，周中的时间可能会表现更好
  `,
}

const limeDescribe = {
  type: 'horizontal',
  image: lime,
  describe: `LIME单样本图中，展示图为您所选的数据点预测值，在此案例中为邮件触达的某价值用户点击邮件链接的概率。

  展示结果分为三部分，最左侧的预测概率图代表模型预测该样本点的标签，中间部分为判断该样本点为某类标签的原因；右侧图示为该样本点对应的特征属性值

  在当前案例中，模型预测该用户点击邮件内部链接的概率为0.6，最重要的原因组合为“用户历史购买产品数量”，“用户所在国家”及“个性化邮件”；其中用户历史购买数量影响权重最大，我们发现，该美国客户触达方式为个性化邮件推送，曾购买过8次产品，属于高粘性客户，且对我们的触达方式持积极态度，具备高净值高意愿的属性，后续可以从重要维度进行群组分析筛选，为精细化高净值客户价值挖掘做运营准备
  `,
}

export const DescribeMap = {
  [ExplainViewType.ftImportance]: importanceDescribe,
  [ExplainViewType.dependence]: dependenceDescribe,
  [ExplainViewType.tree]: treeDescribe,
  [ExplainViewType.shapScatter]: shapScatterDescribe,
  [ExplainViewType.shapImportance]: shapImportanceDescribe,
  [ExplainViewType.shapSingleSample]: shapSingleSampleDescribe,
  [ExplainViewType.lime]: limeDescribe,
}

export const DESCRIBE_INFO = '通过邮件点击率预测模型来进行模型解释性讲解：假设某公司通过发送市场邮件的方式触达客户，并希望提升客户对邮件内公司链接的点击率。业务部门希望您提供关于如何提升邮件点击率的方法。'
