import {observer } from 'mobx-react';
import React, { ReactElement, useMemo } from 'react';
import styles from '../styles.module.css';
import EN from '../../../../../constant/en';
import { LoadingOutlined, MinusOutlined, UpOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import ModelDetail from './ModelDetail';
import classnames from 'classnames';
import { Hint, TrainModel } from 'components/Common';
import { Show } from '../../../../Common';
import Model from 'stores/Model';
import Project from 'stores/Project';
import { ProjectRolesKey } from '@app/constant/types';
interface Interface {
  abortTrain: (s: string) => void;
  models: Model[];
  sort: {
    key: string;
    value: number;
  };
  onSelect: (m: Model) => void;
  train2Finished: boolean;
  current: Model;
  trainModel: unknown;
  isAbort: boolean;
  recommendId: string;
  text: string;
  handleSort: (s: string) => void;
  mapHeader: any;
  project: Project;
  stopIds: string[];
  checkItems:String[];
  bigData: boolean,
  allStopIds: any[]
}

function ModelTable(props: Interface):ReactElement {
  const {
    abortTrain,
    onSelect,
    train2Finished,
    current,
    trainModel,
    isAbort,
    recommendId,
    text,
    sort,
    handleSort,
    mapHeader,
    project,
    stopIds,
    models,
    bigData,
    sort: { key, value },
    allStopIds
  } = props;
  const _abortTrain = stopId => {
    abortTrain(stopId);
  };

  // const err_models = models.filter(itm=>{
  //   return !!itm.errorMsg
  // })

  const sortModels = useMemo(() => {
    const fn = (a, b) => {
      switch (key) {
        case 'acc':
          return (a.accValidation - b.accValidation) * value;
        case 'auc':
          return (
            (a.score.validateScore.auc - b.score.validateScore.auc) * value
          );
        case 'speed':
          return (a.executeSpeed - b.executeSpeed) * value;
        case 'time':
          return ((a.createTime || 0) - (b.createTime || 0)) * value;
        case 'name':
        default:
          return a.modelName > b.modelName ? value : -value;
      }
    };
    return models.filter(itm=>!itm.errorMsg).sort(fn);
  }, [key, value, models]);

  return (
    <div className={styles.table}>
      <div className={styles.rowHeader}>
        <ul className={styles.demo}>
          <li><i/>{EN.IncrementalModel}</li>
          <li><i/>{EN.NonIncrementalModel}</li>
        </ul>
        <div className={styles.rowData}>
          <div
            className={classnames(styles.cell, styles.name, styles.cellHeader)}
            onClick={handleSort.bind(null, 'name')}
          >
            <span>
              {EN.ModelName}
              {sort.key !== 'name' ? (
                <MinusOutlined />
              ) : (
                <UpOutlined
                  style={
                    sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                  } />
              )}
            </span>
          </div>
          <div
            className={classnames(
              styles.cell,
              styles.predict,
              styles.cellHeader,
            )}
          />
          <div
            className={classnames(styles.cell, styles.cellHeader)}
            onClick={handleSort.bind(null, 'acc')}
          >
            <span>
              {EN.Accuracys}
              <Hint content={EN.Givenaparticularpopulation} placement="right" />
              {sort.key !== 'acc' ? (
                <MinusOutlined />
              ) : (
                <UpOutlined
                  style={
                    sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                  } />
              )}
            </span>
          </div>
          <div
            className={classnames(styles.cell, styles.cellHeader)}
            onClick={handleSort.bind(null, 'auc')}
          >
            <span>
              {EN.PerformanceAUC}
              {sort.key !== 'auc' ? (
                <MinusOutlined />
              ) : (
                <UpOutlined
                  style={
                    sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                  } />
              )}
            </span>
          </div>
          <div
            className={classnames(styles.cell, styles.cellHeader)}
            onClick={handleSort.bind(null, 'speed')}
          >
            <span>
              {EN.ExecutionSpeed}
              {sort.key !== 'speed' ? (
                <MinusOutlined />
              ) : (
                <UpOutlined
                  style={
                    sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                  } />
              )}
            </span>
          </div>
          <div
            className={classnames(styles.cell, styles.cellHeader)}
            onClick={handleSort.bind(null, 'time')}
          >
            <span>
              {EN.Time}
              {sort.key !== 'time' ? (
                <MinusOutlined />
              ) : (
                <UpOutlined
                  style={
                    sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                  } />
              )}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.cellHeader)}>
            <span>{EN.VariableImpact}</span>
          </div>
          <div className={classnames(styles.cell, styles.cellHeader)}>
            <span>{EN.ModelInterpretation}</span>
          </div>
          <div className={classnames(styles.cell, styles.cellHeader)}>
            <span>{EN.ModelProcessFlow}</span>
          </div>
          <div className={classnames(styles.cell, styles.cellHeader)}>
            <span>{EN.Report}</span>
          </div>
          <div className={classnames(styles.cell, styles.cellHeader)}>
            <span>{EN.Delete}</span>
          </div>
        </div>
      </div>
      <div className={styles.data}>
        {sortModels.map((model) => {
          return (
            <ModelDetail
              key={model.id}
              model={model}
              isSelect={model.id === current.id}
              onSelect={onSelect}
              isRecommend={model.id === recommendId}
              text={text}
              mapHeader={mapHeader}
              project={project}
            />
          );
        })}
        {!train2Finished && (
          <div className={styles.rowData}>
            <div className={styles.trainingModel}>
              <Tooltip title={EN.ModelProcessing}>{EN.ModelProcessing}</Tooltip>
            </div>
            <Show name={ProjectRolesKey.ModelingAbort}>
              <div
                className={`${styles.abortButton} ${styles.abortButtonAll}`}
                onClick={!isAbort ? () => project.abortTrainByEtl() : null}
              >
                {isAbort ? (
                  <LoadingOutlined />
                ) : (
                  <span>{EN.AbortTrainingAll}</span>
                )}
              </div>
            </Show>
          </div>
        )}
        {!train2Finished &&
          allStopIds.map(stopId => {
            if (stopId.value.data) {
              return (
                <TrainModel
                  showAbortButton={true}
                  trainingModel={stopId.value.data}
                  isAbort={isAbort}
                  abortTrain={_abortTrain}
                  key={stopId.key}
                />
              );
            }
          })}
      </div>
    </div>
  );
}
export default observer(ModelTable);
