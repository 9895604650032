import { observer } from 'mobx-react';
import React from 'react';
import styles from '../styles.module.css';
import { Progress } from 'antd';
import { formatNumber } from '../../../../../util';
import EN from '../../../../../constant/en';
import { Hint } from 'components/Common';
import Predicted from './Predicted';
import Model from 'stores/Model';
interface Interface {
  readonly current: Model;
  readonly yes: string;
  readonly no: string;
}

function Performance(props: Interface) {
  const { current, yes, no } = props;
  return (
    <div className={styles.performanceBox}>
      <div className={styles.performance}>
        <Progress
          width={84}
          type="circle"
          percent={current.score.validateScore.auc * 100}
          format={percent => (
            <span className={styles.performanceScore}>
              {formatNumber(String(percent / 100), 2)}
            </span>
          )}
          strokeColor={'#f5a623'}
        />
        <div className={styles.performanceText}>
          <span className={styles.normalized}>
            <Hint content={EN.Areaunderthecurve} /> {EN.PerformanceAUC}
          </span>
        </div>
      </div>
      <Predicted model={current} yes={yes} no={no} />
    </div>
  );
}

export default observer(Performance);
