import { observable, when, action } from 'mobx';
import socketStore from 'stores/SocketStore';
import projectStore from 'stores/ProjectStore';
import userStore from 'stores/UserStore';
import _ from 'lodash'

export interface Progress {
  requestId: string,
  value: number,
  state: ProgressState,
  type?: ProgressType,
  data?: object
}

export enum ProgressState {
  COMPLETED = 'completed', 
  PROCESSING = 'processing',
  FAILED = 'failed'
}

export enum ProgressType {
  TRAIN = 'train',
  DEPLOY = 'deploy',
  EXPLAIN = 'explain',
  ETL = 'etl',
  DATABASE = 'database',
  ETL_TRANSFORM = 'etl_transform'
}

class ProgressStore {

  @observable progresses = {};
  @observable init;
  @observable listRequestId: string[] = [];
  @observable watchingList: boolean = false

  constructor() {
    socketStore.ready().then((api: any) => {
      this.initWatch()
      api.addEventListener('online', this.initWatch)
      api.addEventListener('offline', this.onOffline)
    })
  }

  @action
  initWatch = () => {
    when(
      () => userStore.status === 'login',
      () =>
        socketStore.ready().then((api: any) => {
          const callback = action(async (result) => {
            if (!result.list) return;
            const _progresses = this.progresses;
            Object.keys(result.list).map(x=> {
              Object.assign(_progresses,{[x]:result.list[x]});
            })
            this.progresses = {..._progresses};
            this.watchingList = true
          })
          api.watchTrainProgressList();
          api.addEventListener('watchTrainProgressList', callback)
        })
    );
  }

  onOffline = action(() => {
    this.watchingList = false;
  })

  @action
  getPrejectTrainProgresses = async (stopIds) => {
    const _stopIds = stopIds.map(x=> x);
    if (!stopIds || stopIds.length < 1) return;
    socketStore.ready().then((api: any) => {
      api.getProgressByRequestIds({requestIds: _stopIds}).then(result => {
        if (result.status == 200 && result.list) {
          const _progresses = this.progresses;
          Object.keys(result.list).map(x=> {
            Object.assign(_progresses,{[x]:result.list[x]});
          })
          this.progresses = {..._progresses};
        }
      });
    })
    // const api = await socketStore.ready();
    // const result = await api.getProgressByRequestIds(stopIds);
    // console.log(result);
    
    // if (result.status == 200 && result.list) {
    //   Object.keys(result.list).map(x=> {
    //     Object.assign(this.progresses,{[x]:result.list[x]});
    //   })
    // }
    // console.log(this.progresses);
  }

}

export default new ProgressStore();

export { ProgressStore }
