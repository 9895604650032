import React, { ReactElement, useEffect, useState } from 'react';
import styles from './styles.module.css';
import  './style.css';
import { Progress, Spin, Button } from 'antd';
import { observer } from 'mobx-react';
import { formatNumber } from '../../../util'
import EN from '../../../constant/en';

interface Interface {
  readonly progress?:any
  readonly style?:any
  readonly onCancel?:any
  readonly spinColor?:any
}

function ProcessLoading(props:Interface):ReactElement{
  const {progress,style, onCancel, spinColor} = props;
  const [value,upValue] = useState(progress);

  useEffect(()=>{
    if(isNaN(+progress)) return;
    upValue(Math.max(progress, (value || 0)))
  },[progress]);

    return <div className={!spinColor ? styles.load : `${styles.load} customSpin`} style={style}>
      <div className={styles.block}>
        {typeof value === 'number' ?
          <Progress
            percent={value}
            strokeWidth={12}
            format={percent => <div className={styles.text}>{formatNumber(String(percent), 2)}%</div>}
            type="circle"
          /> :
          <Spin tip={EN.Loading} style={spinColor ? { color: spinColor }: {}} size="large"/>}
      </div>
      <div className={styles.block}>
      {typeof onCancel === 'function' && <Button className={styles.cancelBtn} onClick={onCancel}>{EN.Cancel}</Button>}
      </div>
    </div>
}
export default observer(ProcessLoading)
