import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import classnames from 'classnames';
import { MobXProviderContext, observer } from 'mobx-react';
import { action } from 'mobx';
import { ExplainView } from '../ExplainView';
import AdvancedView from '../AdvancedView';
import SimpleView from './SimpleView';
import { ProcessLoading, ProgressBar, Show } from 'components/Common';
import { Button, Modal, Tooltip, Spin } from 'antd';
import EN from '../../../../constant/en';
import { DeploymentStore } from 'stores/DeploymentStore';
import { ProjectStore } from 'stores/ProjectStore';
import { Domain, ProjectRolesKey } from '@app/constant/types';

interface Interface {
  resetSide: () => void;
}

const ALLOW_EXPALIN_TYPES = ['Classification'];

function Supervised(props: Interface): ReactElement {
  // @ts-ignore
  const {
    deploymentStore: { addDeployment },
    projectStore: {
      project: {
        measurement,
        selectModel,
        id,
        name,
        mapHeader,
        models,
        isHoldout,
        etlIndex,
        fileName,
        target,
        loadModel,
        settings,
        bucket,
        cancelReport,
        queryCreatingContainer,
        bigData,
        incrementMode,
        checkCreatingContainerState,
        editCreatingContainerState,
      },
      project,
    },
  }: {
    deploymentStore: DeploymentStore;
    projectStore: ProjectStore;
  } = useContext(MobXProviderContext);
  const { resetSide } = props;
  const history = useHistory();
  const [loading, upLoading] = useState(false);
  const [view, upView] = useState('simple');
  const [sort, upSort] = useState({
    simple: {
      key: 'time',
      value: 1,
    },
    advanced: {
      key: 'time',
      value: 1,
    },
  });

  const [metric, upMetric] = useState(measurement);
  // const [goIncrement, upGoIncrement] = useState(false);
  // const [cancel, upCancel] = useState(null);
  const [currentSettingId, upCurrentSettingId] = useState('all');

  const changeView = view => {
    upView(view);
  };

  useEffect(() => {
    filterModels = [...models];
    if (currentSetting)
      filterModels = filterModels.filter(
        model => model.settingId === currentSetting.id,
      );
  }, [models]);

  const handleSort = (view, key) => {
    const _sort = sort[view];
    if (!_sort) return;
    if (_sort.key === key) {
      _sort.value = -_sort.value;
    } else {
      _sort.key = key;
      _sort.value = 1;
    }
    upSort({
      ...sort,
      [view]: _sort,
    });
  };

  const handleChange = action((value: string) => upMetric(value));

  useEffect(() => {
    resetSide();
  }, [view]);

  const changeSetting = action((settingId: string) => {
    upCurrentSettingId(settingId);
  });

  const createPmml = () => {
    if (bigData) return;
    selectModel.createPmml().then(result => {
      if (result.status < 0) {
        Modal.error({
          title: result.errorCode ? `[${result.errorCode}]` : EN.FailedToGeneratePPML,
          content: result.message,
        });
      }
    });
  };

  const createContainer = async () => {
    if (bigData) {
      selectModel.createContainer().then(result => {
        if (result.status < 0)
          Modal.error({
            title: result.errorCode ? `[${result.errorCode}]` : '容器生成失败!',
            content: result.message,
            centered: true,
            okText: EN.ok,
          });
      });
      return;
    }
    upLoading(true);
    const data = await checkCreatingContainerState();
    if (data.creatingContainerState === 'processing') {
      upLoading(false);
      return Modal.error({
        title: '容器生成失败!',
        content: '资源占用中,请稍后再试!',
      });
    }
    queryCreatingContainer().then(async result => {
      if (!!result.hasRunningContainer.length) {
        upLoading(false);
        return Modal.error({
          title: '容器生成失败!',
          content: '资源占用中,请稍后再试!',
        });
      }
      await editCreatingContainerState(data.creatingContainerId, 'processing');
      upLoading(false);
      selectModel.createContainer().then(result => {
        if (result.status < 0)
          Modal.error({
            title: result.errorCode ? `[${result.errorCode}]` : '容器生成失败!',
            content: result.message,
            centered: true,
            okText: EN.ok,
          });
      });
    });
  };

  const downloadContainer = async () => {
    const url = `/r2upload/download/container?projectId=${id}&mid=${selectModel.modelName}`;
    window.open(url);
  };

  if (!models.length) return null;
  if (loadModel) return <ProcessLoading style={{ position: 'fixed' }} />;

  const { modelName } = selectModel;

  const cannotDownload =
    !isHoldout &&
    selectModel.isCV &&
    (modelName.startsWith('Ensemble') || modelName.includes('-solution-DNN'));
  const cannotCreate =
    modelName.includes('-solution') ||
    modelName.startsWith('Ensemble') ||
    modelName.startsWith('dummy') ||
    modelName.includes('-solution-DNN');
  const cannotCreateContainer = modelName.includes('-solution-DNN'); // || modelName.includes('-solution-spark_');

  const type = isHoldout ? 'holdout' : 'validate';
  const realName = fileName?.endsWith('.csv')
    ? fileName.slice(0, -4)
    : fileName;

  let filterModels = [...models];
  const currentSetting =
    currentSettingId === 'all'
      ? null
      : settings.find(setting => setting.id === currentSettingId);
  if (currentSetting)
    filterModels = filterModels.filter(
      model => model.settingId === currentSetting.id,
    );

  const deploy = () => {
    addDeployment(
      id,
      name,
      selectModel.modelName,
      selectModel.problemType,
      mapHeader,
      bucket,
      bigData,
      selectModel.supportIncrement,
    ).then(id => history.push('/deploy/project/' + id));
  };

  const TABS = [
    { key: 'simple', label: EN.SimpleView },
    { key: 'advanced', label: EN.AdvancedView },
  ];
  // TODO: 临时功能权限管理
  const explainVisibleCheck = (): boolean => {
    if (!ALLOW_EXPALIN_TYPES.includes(project.problemType)) return false; // 二分类要展示
    if (!bigData) return false; // python不展示
    if ([Domain.SCTAX, Domain.GZCB].includes((window as any).r2_env.domain))
      return false; // 对应企业不展示
    return true;
  };
  if (explainVisibleCheck()) {
    TABS.push({ key: 'explain', label: EN.ModelExplain });
  }

  return (
    <Spin
      tip={EN.Loading}
      wrapperClassName={styles.modelLoading}
      size="large"
      spinning={loading}
    >
      <div className={styles.modelResult}>
        <div className={styles.tabBox}>
          {TABS.map(tab => (
            <div
              key={tab.key}
              className={classnames(styles.tab, {
                [styles.active]: view === tab.key,
              })}
              onClick={changeView.bind(null, tab.key)}
            >
              <span>{tab.label}</span>
            </div>
          ))}
        </div>
        {view === 'simple' && (
          <SimpleView
            models={filterModels}
            project={project}
            sort={sort.simple}
            handleSort={handleSort.bind(null, 'simple')}
          />
        )}
        {view === 'advanced' && (
          <AdvancedView
            models={models}
            project={project}
            sort={sort.advanced}
            handleSort={handleSort.bind(null, 'advanced')}
            metric={metric}
            handleChange={handleChange}
            currentSettingId={currentSettingId}
            changeSetting={changeSetting}
          />
        )}
        {view === 'explain' && <ExplainView project={project} />}
        <div className={styles.buttonBlock}>
          <Show name={ProjectRolesKey.ModelDeploy}>
            {view !== 'explain' && (
              <button className={styles.button} onClick={deploy}>
                <span>{EN.DeployTheModel}</span>
              </button>
            )}
          </Show>
          <Show name={ProjectRolesKey.ModelExport}>
            {view === 'advanced' &&
              (cannotDownload || !selectModel?.validateDeployData ? (
                <button className={classnames(styles.button, styles.disabled)}>
                  <span>{`${EN.Exportmodelresults}(${
                    isHoldout ? EN.Holdout : EN.Validation
                  })`}</span>
                </button>
              ) : (
                <a
                  href={`/r2upload/download/result?projectId=${id}&filename=${encodeURIComponent(
                    `${realName}-${selectModel.modelName}-${type}.csv`,
                  )}&mid=${
                    selectModel.modelName
                  }&etlIndex=${etlIndex}&type=${type}&target=${target}`}
                  target="_blank"
                >
                  <button className={styles.button}>
                    <span>{`${EN.Exportmodelresults}(${
                      isHoldout ? EN.Holdout : EN.Validation
                    })`}</span>
                  </button>
                </a>
              ))}
            {view === 'advanced' &&
              !incrementMode &&
              (!selectModel.getPmml ? (
                cannotCreate ? (
                  <Tooltip title={EN.CannotCreatePmml}>
                    <button
                      className={classnames(styles.button, styles.disabled)}
                    >
                      <span>{EN.CreatePmml}</span>
                    </button>
                  </Tooltip>
                ) : (
                  <button className={styles.button} onClick={createPmml}>
                    <span>{EN.CreatePmml}</span>
                  </button>
                )
              ) : !selectModel.pmmlData ? (
                <Tooltip title={EN.PmmlCreating}>
                  <button
                    className={classnames(styles.button, styles.disabled)}
                  >
                    <span>{`${EN.DownloadPmml}`}</span>
                  </button>
                </Tooltip>
              ) : (
                <a
                  href={`/r2upload/download/pmml?projectId=${id}&mid=${selectModel.modelName}`}
                  target="_blank"
                >
                  <button className={styles.button}>
                    <span>{`${EN.DownloadPmml}`}</span>
                  </button>
                </a>
              ))}
            {((window as any).r2_env.domain !== Domain.CMB && (window as any).r2_env.domain !== Domain.R2) && (
              <>
                {view === 'advanced' &&
                  !incrementMode &&
                  (!selectModel.getContainer ? (
                    cannotCreateContainer ? (
                      <Tooltip title={EN.CannotCreatePmml}>
                        <button
                          className={classnames(styles.button, styles.disabled)}
                        >
                          <span>
                            {bigData
                              ? EN.CreateModelResult
                              : EN.CreateContainer}
                          </span>
                        </button>
                      </Tooltip>
                    ) : (
                      <button
                        className={styles.button}
                        onClick={createContainer}
                      >
                        <span>
                          {bigData ? EN.CreateModelResult : EN.CreateContainer}
                        </span>
                      </button>
                    )
                  ) : !selectModel.containerData ? (
                    <Tooltip
                      title={
                        bigData
                          ? EN.ModelGenerationInProgress
                          : EN.ContainerGenerationInProgress
                      }
                    >
                      <button
                        className={classnames(styles.button, styles.disabled)}
                      >
                        <span>
                          {bigData
                            ? EN.ExportModelResult
                            : EN.DownloadContainer}
                        </span>
                      </button>
                    </Tooltip>
                  ) : (
                    <button
                      className={styles.button}
                      onClick={downloadContainer}
                    >
                      <span>
                        {bigData ? EN.ExportModelResult : EN.DownloadContainer}
                      </span>
                    </button>
                  ))}
              </>
            )}
          </Show>
        </div>
        <Modal
          title={EN.ExportingReport}
          visible={project.reportProgressText !== 'init'}
          closable={true}
          footer={null}
          onCancel={cancelReport}
          maskClosable={false}
        >
          <div className={styles.reportProgress}>
            <ProgressBar
              progress={project.reportProgress}
              allowRollBack={true}
            />
            <span className={styles.reportProgressText}>
              {project.reportProgressText}
            </span>
            <Button onClick={cancelReport} className={styles.reportCancel}>
              {EN.CANCEL}
            </Button>
          </div>
        </Modal>
      </div>
    </Spin>
  );
}
export default observer(Supervised);
