import React, { useContext, useEffect, useRef } from 'react';
import EN from '../../../constant/en';
import { Button, Input, message, Modal, Popconfirm } from 'antd';
import { MobXProviderContext, observer, useLocalStore } from 'mobx-react';
import { DeploymentStore } from 'stores/DeploymentStore';
import { UserStore } from 'stores/UserStore';
import { Switch } from '../index';
import styles from '../../Detail/Deployment/styles.module.css';

export default observer(EmailAlert)

function EmailAlert(props){
	const {open,Close} = props;
	// @ts-ignore
	const {
		deploymentStore: {
			currentDeployment: {
				warning,
				warningEmails,
				id,
			},
			setWarning,
		},
		userStore,
	}:{
		deploymentStore:DeploymentStore
		userStore:UserStore
	} = useContext(MobXProviderContext);

	const localStore = useLocalStore(() => ({
		warningEmails,
		warning,
		loading:false,
		updateField(data) {
			Object.assign(this, data)
		},
	}));

	useEffect(()=>{
		if(id&&!localStore.warningEmails?.length){
			localStore.updateField({
				warningEmails: [{
					email:userStore.info.email
				}]
			})
			setWarning(id,localStore.warning, [{
				email:userStore.info.email
			}])
		}
	},[id])

	useEffect(()=>{
		if(open){
			localStore.updateField({
				warningEmails,
				warning,
				loading:false
			})
		}
	},[open]);

	const addInput:any = useRef()
	function emailListAdd(){
		const { value } = addInput.current.state;
		const check = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value)
		if(check){
			const warningEmails = [...new Set([...localStore.warningEmails.map(itm=>itm.email),value])]
			localStore.updateField({
				warningEmails:warningEmails.map(email=>({email}))
			})
			addInput.current.state.value = '';
		}else{
			message.warning(EN.IncorrectFormat)
		}
	}

	async function AlertSave(){
		localStore.updateField({
			loading:true
		})
		await setWarning(id,localStore.warning,localStore.warningEmails)
		message.success(EN.DONE)
		Close()
	}

	return <Modal
		title={EN.EmailAlert}
		visible={open}
		onOk={AlertSave}
		onCancel={Close}
		okText={EN.DONE}
		cancelText={EN.Cancel}
		confirmLoading={localStore.loading}
	>
		<div style={{
			textAlign:'right',
		}}>
			{EN.EnablEmailAlerts}:&nbsp;
			<Switch checked={localStore.warning}
			       onClick={()=>{
				       localStore.updateField({
					       warning:!localStore.warning
				       })
			       }
	       }/>
		</div>
		<div className={styles.addEmail}>
			{EN.AddAlertTarget}:<Input
			placeholder='email'
			ref={addInput} />
			<Button onClick={emailListAdd}>{EN.Add}</Button>
		</div>
		<dl className={styles.emailList}>
			{localStore.warningEmails?.map(itm=>{
				return <dd key={itm.email}>
					{itm.email}
					{localStore.warningEmails.length>1&&<Popconfirm
                      placement="topRight"
                      title={EN.ConfirmDeletion}
                      onConfirm={() => {
						  const warningEmails = localStore.warningEmails.filter(it => it.email !== itm.email);
						  localStore.updateField({
							  warningEmails,
						  });
					  }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button>{EN.Delete}</Button>
                    </Popconfirm>}
				</dd>
			})}
		</dl>
	</Modal>
}
