import { observable, action, computed } from 'mobx';
import socketStore from "./SocketStore";
import { debounce } from 'lodash'

export interface graphicListItem {
  name: string,
  data: {
    fields: string[],
    id: string,
  }
}

export interface ChartDataRoc {
  AUCPR: number[],
  AUCROC: number[],
  F1: number[],
  FN: number[],
  FP: number[],
  FPR: number[],
  F_BETA: number[],
  KS: number[],
  LOGLOSS: number[],
  Precision: number[],
  Recall: number[],
  TN: number[],
  TP: number[],
  TPR: number[],
  Threshold: number[],
  Youden: number[],
  Kappa: number[],
}

export interface MultiRoc {
  macro: number,
  micro: number
}

export interface MultiDataRoc {
  macro: number[],
  micro: number[]
}

export interface ChartData {
  roc?: ChartDataRoc,
  lift?: unknown,
  density?: unknown,
  fpr?: MultiDataRoc,
  roc_auc?: MultiRoc,
  thresholds?: MultiDataRoc,
  tpr?: MultiDataRoc,
}

export interface ClaregScore {
  acc?: number
  auc?: number
  f1?: number
  logLoss?: number
  precision?: number
  recall?: number
  adjustR2?: number
  mae?: number
  mse?: number
  msle?: number
  nmse?: number
  nrmse?: number
  r2?: number
  rmse?: number
  rmsle?: number
  Kappa?: number,
  Accuracy?: number
  HammingLoss?: number
  Jaccard?: number
  MCC?: number
  macro_F1?: number
  macro_P?: number
  macro_R?: number
  micro_F1?: number
  micro_P?: number
  micro_R?: number
}

export interface Score {
  score?: number,
  auc?: number,
  validateScore?: ClaregScore,
  holdoutScore?: ClaregScore,
  trainScore?: ClaregScore,
  CVNN?: number | 'null' | 'inf',
  RSquared?: number | 'null' | 'inf',
  RMSSTD?: number | 'null' | 'inf',
  CH?: number | 'null' | 'inf',
  silhouette_cosine?: number | 'null' | 'inf',
  silhouette_euclidean?: number | 'null' | 'inf',
  rmse?: number,
  mae?: number,
  r2?: number,
  clusters?: number
}

export interface LabelWithImportance {
  [key: string]: {
    indexes?: string[];
    values?: string[];
    distanceInsideCluster?: number
    numberInsideCluster?: number
    histogramIndex?: (string | number)[][]
    originalDiffCluster?: number[][]
    originalSameCluster?: number[][]
  };
};

class Model {
  projectId: string
  _id: string
  id: string
  initialFitIndex: number
  dataFlow: any[];
  graphicList: { [key: string]: {[key: string]: graphicListItem}  }
  created_at: String;
  validatePlotData: string
  holdoutPlotData: string;
  residualPlotPath: string
  settingId: string;
  dbscanClusters: number = 2
  realLabelScore = {}
  correlationData: string
  graphData: string
  plotData: string
  rulesData: string
  originRows: number = 0
  version: string = ''
  modelCost: number = 0
  @observable score: Score;
  @observable backend: string;
  @observable featureImportance: NumberObject;
  @observable importanceCoefficient: NumberObject;
  @observable executeSpeed: number = 0;
  @observable dependenceData: string = '';
  @observable modelInsightList: string[] = new Array(2);
  @observable modelName: string = "";
  @observable fitIndex: number = 0;
  @observable chartData?: ChartData;
  @observable problemType: string
  @observable labelWithImportance: LabelWithImportance = {}
  @observable multiVarPlotData: string = ''
  @observable parallelPlotData: string = ''
  @observable outlierPlotData: string = '';
  @observable pointToShowData: string = '';
  @observable predictData: string = '';
  @observable fitAndResidualPlotData: string = ''
  @observable outlierPlotLoading: boolean = false
  @observable featureList: string[] = []
  @observable rate: number = 0
  @observable pcaPlotData: string = ''
  @observable featureLabel: string[] = []
  @observable holdoutChartData: ChartData;
  @observable target: string[] = [];
  @observable esIndex: string = '';
  @observable accuracyData: NumberObject = {}
  @observable getPmml: boolean = false
  @observable pmmlData: string = ''
  @observable getContainer: boolean = false
  @observable containerData: string = ''
  @observable runTimeData: string = ''
  // @observable featureImportanceDetail = {}
  @observable linearData = false;
  @observable treeData = false;
  @observable increment = false;
  @observable supportIncrement = false;

  @observable acfPlotData: string = ''
  @observable densityPlotData: string = ''
  estimatorInfoData: string = ''
  forecastAllData: string = ''
  forecastData: string = ''
  @observable qqPlotData: string = ''
  resultPlotData: string = ''
  @observable errorMsg = ''

  constructor(projectId: string, model: unknown, modelName?: string) {
    this.projectId = projectId;
    this._id = modelName;
    Object.assign(this, model);

    this.updateModel = debounce(this.updateModel, 1000).bind(this);
  }

  @action
  setProperty(data: Partial<Model>) {
    if (typeof data !== 'object') {
      return false;
    }
    if (Array.isArray(data)) {
      return false;
    }
    Reflect.deleteProperty(data, 'id')
    Reflect.deleteProperty(data, 'modelName')
    Reflect.deleteProperty(data, 'projectId')

    for (let key in data) {
      // const value = data[key]
      const value = Reflect.get(data, key)
      // (data as Model)[(key as keyof Model)]
      if (typeof value === 'function') {
        Reflect.deleteProperty(data, key)
      }
    }
    Object.assign(this, data)
  }
  @action
  setFitIndex = (index: number) => {
    this.fitIndex = index;
    return this.updateModel({
      fitIndex: index,
    })
  };

  @action
  resetFitIndex = () => {
    const { initialFitIndex: fitIndex } = this;
    this.fitIndex = fitIndex;
    return this.updateModel({
      fitIndex,
    })
  };

  getBenefit = (ITP: number, IFN: number, IFP: number, ITN: number, IPN: number, IPO: number) => {
    const data = this.chartData
    const { roc={} } = data;
    const { TP, FN, FP, TN } = roc as any;
    if (!ITP && !IFN && !IFP && !ITN) return {
      benefit: 0,
      index: this.initialFitIndex
    }
    if (!TP && !FN && !FP && !TN) return {
      benefit: 0,
      index: this.initialFitIndex,
      text: `(${IPN}/${IPO})*(${ITP}*${0}(TP)-${IFN}*${0}(FN))+(${1 - IPN}/${1 - IPO})*(${ITN}*${0}(TN)-${IFP}*${0}(FP)) = ${0}`
    };
    let maxIndex = this.fitIndex;
    for (let i = 1; i < 100; i++) {
      const benefit = TP[i] * ITP - FN[i] * IFN - FP[i] * IFP + TN[i] * ITN
      const maxBenefit = TP[maxIndex] * ITP - FN[maxIndex] * IFN - FP[maxIndex] * IFP + TN[maxIndex] * ITN
      if (benefit > maxBenefit) maxIndex = i
    }
    const realBenefit = (IPN / IPO) * (TP[maxIndex] * ITP - FN[maxIndex] * IFN) + ((1 - IPN) / (1 - IPO)) * (TN[maxIndex] * ITN - FP[maxIndex] * IFP)
    // this.fitIndex = maxIndex
    return {
      benefit: realBenefit,
      index: maxIndex,
      text: `(${IPN}/${IPO})*(${ITP}*${TP[maxIndex]}(TP)-${IFN}*${FN[maxIndex]}(FN))+(${1 - IPN}/${1 - IPO})*(${ITN}*${TN[maxIndex]}(TN)-${IFP}*${FP[maxIndex]}(FP)) = ${realBenefit}`
      //`${ITP} * ${TP[maxIndex]}(TP) - ${IFN} * ${FN[maxIndex]}(FN) - ${IFP} * ${FP[maxIndex]}(FP) + ${ITN} * ${TN[maxIndex]}(TN) = ${realBenefit}`
    }
  }
  @computed
  get predicted() {
    const { chartData, fitIndex, problemType } = this
    if (problemType !== 'Classification') return [1, 1]
    if (!chartData || !chartData.roc) {
      return [1, 1]
    }

    const { TP, FN, FP, TN } = chartData.roc
    return [TN[fitIndex] / (TN[fitIndex] + FP[fitIndex]), TP[fitIndex] / (TP[fitIndex] + FN[fitIndex])]
  }
  @computed
  get accValidation() {
    const data = this.chartData
    const roc = data.roc
    if (!roc) return this.score.validateScore.acc
    const { TP, FN, FP, TN } = roc
    if (!TP || !FN || !FP || !TN) return this.score.validateScore.acc
    return (TP[this.fitIndex] + TN[this.fitIndex]) / (TP[this.fitIndex] + FN[this.fitIndex] + FP[this.fitIndex] + TN[this.fitIndex])
  }
  @computed
  get accHoldout() {
    const data = this.holdoutChartData
    const roc = data.roc
    if (!roc) return this.score.holdoutScore.acc
    const { TP, FN, FP, TN } = roc
    if (!TP || !FN || !FP || !TN) return this.score.holdoutScore.acc
    return (TP[this.fitIndex] + TN[this.fitIndex]) / (TP[this.fitIndex] + FN[this.fitIndex] + FP[this.fitIndex] + TN[this.fitIndex])
  }
  @computed
  get precisionValidation() {
    const data = this.chartData
    const roc = data.roc
    if (!roc) return this.score.validateScore.precision
    const { TP, FP } = roc
    if (!TP || !FP) return this.score.validateScore.precision
    return TP[this.fitIndex] / (TP[this.fitIndex] + FP[this.fitIndex])
  }
  @computed
  get precisionHoldout() {
    const data = this.holdoutChartData
    const roc = data.roc
    if (!roc) return this.score.validateScore.precision
    const { TP, FP } = roc
    if (!TP || !FP) return this.score.holdoutScore.precision
    return TP[this.fitIndex] / (TP[this.fitIndex] + FP[this.fitIndex])
  }
  @computed
  get recallValidation() {
    const data = this.chartData
    const roc = data.roc
    if (!roc) return this.score.validateScore.precision
    const { TP, FN } = roc
    if (!TP || !FN) return this.score.validateScore.precision
    return TP[this.fitIndex] / (TP[this.fitIndex] + FN[this.fitIndex])
  }
  @computed
  get recallHoldout() {
    const data = this.holdoutChartData
    const roc = data.roc
    if (!roc) return this.score.validateScore.precision
    const { TP, FN } = roc
    if (!TP || !FN) return this.score.holdoutScore.precision
    return TP[this.fitIndex] / (TP[this.fitIndex] + FN[this.fitIndex])
  }
  @computed
  get fprValidation() {
    const data = this.chartData
    const roc = data.roc
    if (!roc) return this.score.validateScore.precision
    const { FP, TN } = roc
    if (!FP || !TN) return this.score.validateScore.precision
    return FP[this.fitIndex] / (FP[this.fitIndex] + TN[this.fitIndex])
  }
  @computed
  get fprHoldout() {
    const data = this.holdoutChartData
    const roc = data.roc
    if (!roc) return this.score.validateScore.precision
    const { FP, TN } = roc
    if (!FP || !TN) return this.score.holdoutScore.precision
    return FP[this.fitIndex] / (FP[this.fitIndex] + TN[this.fitIndex])
  }
  @computed
  get ksValidation() {
    return this.recallValidation - this.fprValidation
  }
  @computed
  get ksHoldout() {
    return this.recallHoldout - this.fprHoldout
  }
  @computed
  get f1Validation() {
    const { precisionValidation, recallValidation } = this
    return 2 * precisionValidation * recallValidation / (precisionValidation + recallValidation)
  }
  @computed
  get f1Holdout() {
    const { precisionHoldout, recallHoldout } = this
    return 2 * precisionHoldout * recallHoldout / (precisionHoldout + recallHoldout)
  }
  fbeta = (beta = 1, type = 'Validation') => {
    beta = +beta
    if (type !== 'Validation' && type !== 'Holdout') type = 'Validation'
    if (isNaN(beta) || beta < 0.1 || beta > 10) beta = 1
    return (1 + beta * beta) * this[`precision${type}`] * this[`recall${type}`] / ((beta * beta * this[`precision${type}`]) + this[`recall${type}`])
  }
  @computed
  get cutoff() {
    let cut;
    try {
      const { chartData: { roc: { Threshold } }, fitIndex } = this;
      cut = Threshold[fitIndex]
    } catch (e) {
      cut = 0.5;
    }
    return cut;
  }
  @computed
  get loglossValidation() {
    return this.score.validateScore.logLoss
    // const { chartData: { roc: { LOGLOSS } }, fitIndex } = this
    // return LOGLOSS[fitIndex]
  }
  @computed
  get loglossHoldout() {
    return this.score.holdoutScore.logLoss
    // const { holdoutChartData: { roc: { LOGLOSS } }, fitIndex } = this
    // return LOGLOSS[fitIndex]
  }
  @computed
  get aucValidation() {
    const { score: { validateScore: { auc } } } = this
    return auc
  }
  @computed
  get aucHoldout() {
    const { score: { holdoutScore: { auc } } } = this
    return auc
  }
  @computed
  get KappaValidation() {
    const { chartData: { roc }, fitIndex } = this
    if (!roc) {
      return null
    }

    const { Kappa } = roc;
    const value = (Kappa || [])[fitIndex]
    return value || null
  }
  @computed
  get KappaHoldout() {
    const { holdoutChartData: { roc }, fitIndex } = this
    if (!roc) {
      return null
    }

    const { Kappa } = roc;
    const value = (Kappa || [])[fitIndex]
    return value || null
  }
  getOutlierData = () => {
    return socketStore.ready().then(api => {
      return api.getOutlierData({ id: this.id, projectId: this.projectId, count: Math.floor(this.rate * this.originRows) })
    })
  };

  @action
  saveFeatureList = async (featureList: [string, string],bigData) => {
    if (!Array.isArray(featureList) || featureList.length !== 2) return console.log('error featureList');
    // if (featureList[0] === this.featureList[0] && featureList[1] === this.featureList[1]) return console.log("same");
    this.outlierPlotLoading = true;
    return socketStore.ready().then(api => {
      let cmd = 'outlier.outlierPlot';
      if(bigData){
        cmd = 'spark.outlier.outlierPlot';
      }
      const command = {
        command: cmd,
        projectId: this.projectId,
        id: this.id,
        featureList: [featureList],
        randomSeed: 0,
        bigData,
      };
      return api.outlierPlot(command)
    })
  };

  @action
  saveModelInsight = async (List: string[], cutoff = 0.5) => {
    return socketStore.ready().then(api => {
      let cmd = `clfreg.${!!this.version ? `${this.version}.` : ''}dependencePlot`;
      const command = {
        command: cmd,
        projectId: this.projectId,
        id: this.id,
        featureLabel: List,
        cutoff,
        version: this.modelName,
      };
      return api.modelInsight(command)
    })
  };

  createPmml = () => {
    if (this.id.startsWith('r2-solution-')) return Promise.resolve({ status: 100 })
    if (this.getPmml) return Promise.resolve({ status: 100 })
    if (this.pmmlData) return Promise.resolve({ status: 100 })
    this.getPmml = true
    const prev = ((this.problemType === 'Classification' || this.problemType === 'Regression') && 'clfreg') || (this.problemType === 'Clustering' && 'clustering') || (this.problemType === 'Outlier' && 'outlier') || (this.problemType === 'MultiClassification' && 'multi') || ''
    if (!prev) return Promise.resolve()
    return socketStore.ready().then(api => {
      return api.createPmml({ command: `${prev}.${!!this.version ? `${this.version}.` : ''}pmml`, id: this.id, projectId: this.projectId })
    })
  }

  createContainer = () => {
    if (this.getContainer) return Promise.resolve({ status: 100 })
    if (this.containerData) return Promise.resolve({ status: 100 })
    this.getContainer = true;
    return socketStore.ready().then(api => {//`top.${!!this.version ? `${this.version}.` : ''}dockerRuntime`
      return api.createContainer({
        command: 'docker.dockerRuntime',
        id: this.id,
        projectId: this.projectId,
        version: this.version,
        problemType: this.problemType
      })
    })
  }
  // outlierPlot = (featureList) => {
  //   this.outlierPlotLoading = true
  //   return socketStore.ready().then(api => {
  //     let cmd = 'outlier.outlierPlot';
  //     const command = {
  //       command: cmd,
  //       projectId: this.projectId,
  //       id: this.id,
  //       featureList: [this.featureList],
  //       randomSeed: 0
  //     }
  //     return api.outlierPlot(command)
  //   })
  // }
  updateModel(data: Object) {
    this.setProperty(data);
    return socketStore.ready().then(api => api.updateModel({
      data,
      id: this.id,
      projectId: this.projectId,
    }));
  }
}

export default Model
