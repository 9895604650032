import React, { ReactElement } from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import { Spin } from 'antd';
interface Interface {
	readonly width?:number
	readonly height?:number
	readonly data:any
}
export default function QQPlot(props:Interface):ReactElement{
		if(!props.data){
			return <Spin>
				<div style={{width:500,height:350}}/>
			</Spin>
		}

		const {width=500,height=350,data:{x,dataY,plotY}} = props;
		const maxL = String(_.floor(_.max([...dataY,...plotY]))).length;

		const data_line = [[x[0],plotY[0]],[x[x.length-1],plotY[x.length-1]]];

		const option:any =  {
			animation: false,
			title: {
				text: 'QQ PLOT',
				subtext: ''
			},
			grid:{
				left:20+10*maxL,
			},
			xAxis:{
				name:'Theoretical Quantiles',
				nameLocation:'middle',
				nameGap:25,
			},
			yAxis:{
				name:'Sample Quantiles'
			},
			series : [
				{
					type:'line',
					data:data_line,
					symbolSize: 0,
					lineStyle:{
						color:'#f3973a'
					}
				},{
					type:'scatter',
					large: true,
					data:_.zip(x,dataY),
					symbolSize:3,
					itemStyle:{
						color:'#94bae7'
					}
				}
			],
		};

	return <ReactEcharts
		option={option}
		style={{height, width}}
		notMerge={true}
		lazyUpdate={true}
	/>
}
