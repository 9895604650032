import React, { ReactElement } from 'react';
import ReactEcharts from 'echarts-for-react';
import Project from 'stores/Project';
import _ from 'lodash';
import { formatNumber } from '../../../../util';

interface Interface {
  message: {
    avg: {
      data: any;
      name: {
        x: string;
      };
    };
    freq: {
      data: any;
      name: {
        x: string;
      };
    };
  };
  project: Project;
}

export default function MIBar(props: Interface): ReactElement {
  const {
    message: {
      avg: {
        name: { x },
        data: avg_data,
      },
      freq: { data: freq_data },
    },
    project: { mapHeader, colType, colMap, newType },
  } = props;
  const nameTextStyle = {
    color: '#000',
  };

  const avg_freqs: number[] = avg_data.map(itm => itm.freq);
  const freq_freqs: number[] = freq_data.map(itm => itm.freq);

  const avg_max = _.max(avg_freqs) * 1.01;
  const avg_min = _.min(avg_freqs) - 0.01;

  const freq_max = _.max(freq_freqs) * 1.01;
  const freq_min = _.min(freq_freqs) - 0.01;

  const x_name = mapHeader[x] || x;
  const xAxis = {
    name: x_name,
    nameTextStyle,
    scale: true,
    minInterval: 1,
    type: 'category',
    nameLocation: 'center',
    nameGap: 20,
  };

  const avg_xAxis: any = {
    ...xAxis,
  };
  const freq_xAxis: any = {
    ...xAxis,
  };

  const encode = {
    tooltip: [1, 2],
  };

  const cMap = Object.assign({}, colType, newType);

  if (cMap[x] === 'Categorical' || !colType[x]) {
    const ent = Object.entries(colMap[x] || {});
    let _data = [];
    avg_data.forEach((itm, index) => {
      const type = ent.filter(it => it[1] === itm.x);
      if (type[0]) {
        _data.push(type[0][0]);
        avg_data[index].x = type[0][0];
      } else {
        _data.push(itm.x);
      }
    });
    avg_xAxis.data = _data;

    _data = [];
    freq_data.forEach((itm, index) => {
      const type = ent.filter(it => it[1] === itm.x);
      if (type[0]) {
        _data.push(type[0][0]);
        freq_data[index].x = type[0][0];
      } else {
        _data.push(itm.x);
      }
    });
    freq_xAxis.data = _data;
  }

  const visualMap = {
    orient: 'horizontal',
    left: 'center',
    inRange: {
      color: ['#80bdfd', '#b0e39b'],
    },
    formatter: value => {
      return value.toFixed(6);
    },
  };

  function series_data(data) {
    return data.map((itm: any) => [
      typeof itm.x === 'number' ? itm.x.toFixed(6) : itm.x,
      itm.data.toFixed(6),
      itm.freq.toFixed(6),
    ]);
  }

  const series = {
    type: 'bar',
    barWidth: '50%',
    label: {
      show: false,
      position: 'top',
    },
    dimensions: [x_name, 'value', 'freq'],
    encode,
  };

  const axisLabel = {
    formatter: function (value) {
      return formatNumber(value,1)
    }
  };

  const option:any =  {
    baseOption: {
      xAxis: {},
      tooltip: {},
      grid: {
        bottom: 70,
      },
      yAxis: {
        nameTextStyle,
        type: 'value',
        axisLabel,
      },
    },
    timeline: {
      axisType: 'category',
      loop: false,
      data: ['Avg', 'Freq'],
      controlStyle: {
        show: false,
      },
      right: '85%',
      left: '5%',
    },
    options: [
      {
        xAxis: avg_xAxis,
        visualMap: {
          ...visualMap,
          min: avg_min,
          max: avg_max,
        },
        series: {
          ...series,
          data: series_data(avg_data),
        },
      },
      {
        xAxis: freq_xAxis,
        visualMap: {
          ...visualMap,
          min: freq_min,
          max: freq_max,
        },
        series: {
          ...series,
          data: series_data(freq_data),
        },
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: '100%', width: '100%' }}
      notMerge={true}
      lazyUpdate={true}
      theme="customed"
    />
  );
}
