import { observable, action, when, computed } from "mobx";
import socketStore from "./SocketStore";
import Project from "./Project";
import Model from './Model'
import { v4 as uuidV4 } from "uuid";
import moment from 'moment'
import { message, Modal } from 'antd';
import EN from '../constant/en'

export interface ProjectListFilters {
  current: number,
  limit: number,
  offset: number,
  sort: string,
  from:string
  keywords?:string,
  tabName?:string,
  problemType:string,
  isSpark:string,
  createDate?: [moment.Moment, moment.Moment],
}

export interface WatchResult {
  status: number,
  id: string,
  result: Partial<Project>,
  model: Model,
  modelResult: Partial<Model>,
  isCreate?: boolean
}

class ProjectStore {
  @observable loading: boolean = true;
  @observable init: boolean = false;
  @observable isOnline: boolean = true;//在线
  @observable watchList: boolean = false;//watchProjectList open
  @observable currentId: string = "";//当前项目ID
  @observable projectInit: boolean = false//项目初始化完成
  @observable initList: boolean = false//项目列表获取

  @observable list: Project[] = [];//主页的project信息列表
  @observable total: number = 0;//项目总数
  @observable projectListFilters: ProjectListFilters = {//首页分页信息
    current: 1,
    limit: 10,
    offset: 0,
    sort: 'createTime',
    from:'all',
    keywords: '', // 搜索关键字
    tabName: undefined,
    problemType: 'all',
    isSpark: 'all',
    createDate: undefined,
  };
  @observable broadcastId: string = "";//初始化项目时生成
  @observable conflict: boolean = false;//您的账号已在另一个页面登录-提示|有效?
  @observable stopFilter: boolean = false;//?
  oldfiltedModels: Model[] | null = null;
  newfiltedModels: Model[] | null = null;
  @observable userList = [];
  @observable roleList = [];
  @observable roles = {};

  getRoleList = () =>{
    socketStore.ready().then(api => {
      api.getRoleList().then(result=>{
        this.roleList = result.list
      })
    })
  }

  addUserToProject = (projectId,email,roleId) =>{
    socketStore.ready().then(api => {
      api.addUserToP({
        projectId,
        email,
        roleId
      }).then(()=>{
        return this.getUserList(projectId)
      })
    })
  }

  removeUserFromProject = (pid,id) =>{
    socketStore.ready().then(api => {
      api.removeUserFromP({
        id
      }).then(()=>{
        return this.getUserList(pid)
      })
    })
  }

  updateRoleAboutProject = (id,roleId) =>{
    socketStore.ready().then(api => {
      api.updateRoleAboutP({
        id,
        roleId,
      }).then(()=>{
        message.success(EN.ModifySuccess)
      })
    })
  }

  // continueProjectEtl = (projectId) =>{
  //   socketStore.ready().then(api => {
  //     api.continueEtl({ projectId });
  //   })
  // }

  @action
  changeStopFilter = (stopFilter: boolean) => {
    this.stopFilter = stopFilter;
    if (!stopFilter) {
      this.oldfiltedModels = this.newfiltedModels
    }
  };

  @action
  changeNewfiltedModels = (models: Model[]) => {
    this.newfiltedModels = models
  };

  @action
  changeOldfiltedModels = (models: Model[]) => {
    this.oldfiltedModels = models
  };


  constructor() {
    if ((window as any).r2Report) {
      const list = (window as any).r2Report
      this.list = list.map((row: Project) => {
        const project = new Project(row.id + "", { ...row, ...{ isAbort: false } })
        project.models.forEach(m => project.setModel(m))
        return project
      })
      return;
    }
    this.initWatch()
    this.initReload()
    // autorun(() => {
    //   if (this.project) {
    //     this.project.clean();
    //     this.queryProjectList();
    //     this.project.initProject()
    //   }
    // })
    setInterval(()=>{
      if(!this.init){
        this.initWatch()
      }
    },2000)
  }

  initReload = () => {
    socketStore.ready().then(api => {
      api.addEventListener('offline', this.offline)
      api.addEventListener('online', this.initWatch)
    })
  }

  initWatch = () => {
    this.isOnline = true
    if (this.init) return
    this.watchProjectList();
    when(
      () => this.watchList,
      () => {
        this.queryProjectList()
        if (this.currentId) this.initProject(this.currentId)
      }
    )
      
    when(
        () => this.project?.init&&!this.project.roles?.name,
        () => {
          message.error('没有此项目权限')
          location.replace('/')
        }
    )
  }

  offline = () => {
    this.watchList = false;
    this.init = false
    this.initList = false
    this.isOnline = false
    this.projectInit = false
    if (this.project) this.project.clean();
  }

  // @computed
  // get sortList() {
  //   const { sort, keywords } = this.projectListFilters;
  //   return this.list.filter(p => p.visiable &&
  //     (!keywords ||
  //       String(p.id).includes(keywords) ||
  //       (p.name || '').includes(keywords))).sort((a, b) => {
  //     return Reflect.get(b, sort) - Reflect.get(a, sort)
  //   }).slice(0, this.projectListFilters.limit)
  // }

  @computed
  get project() {
    return this.currentId ? this.list.find(row => +row.id === +this.currentId) : null
  }

  @action
  changeOption = (k: string, v: string | number | [moment.Moment, moment.Moment]) => {
    if (v && !isNaN(+(v.toString()))) {
      v = parseInt(v.toString(), 10)
    }
    Reflect.set(this.projectListFilters, k, v)
    return this.queryProjectList()
  }

  // 重置主页项目列表过滤选项
  @action
  resetProjectListFilters = () => {
    this.projectListFilters = {
      current: 1,
      limit: 10,
      offset: 0,
      sort: 'createTime',
      from:'all',
      keywords: '', // 搜索关键字
      tabName: undefined,
      problemType: 'all',
      isSpark: 'all',
      createDate: undefined,
    };
    this.queryProjectList();
  }

  @action
  changePage = (page: number) => {
    const { current, limit } = this.projectListFilters
    if (current === page) return;
    const offset = (page - 1) * limit
    Object.assign(this.projectListFilters, { offset: offset, current: page })
    return this.queryProjectList()
  }

  @action
  watchProjectList = () => {
    socketStore.ready().then(api => {
      api.watchProjectList().then((watch: { status: number, id?: string }) => {
        if (watch.status === 200) {
          this.watchList = true
          this.init = true
          api.addEventListener(watch.id, (data: WatchResult) => {
            const { status, id, result, model, modelResult, isCreate } = data
            if (status === 200) {
              const project = this.list.find(p => +p.id === +id)
              if (!project) {
                if (!result) return
                if (!isCreate) return
                this.queryProjectList()
              } else {
                if (+id !== +this.currentId) {
                  if (result) project.setDefaultProperty(result)
                  return
                }
                if (result) project.setProperty(result)
                if (model) project.setModel(model, false, true)
                if (modelResult) project.setModelField(modelResult)
              }
            }
          })
          api.addEventListener("inProject", (data: { id: string, broadcastId: string }) => {
            const { id, broadcastId } = data
            if (broadcastId === this.broadcastId) return
            if (id !== this.currentId) return
            // this.showConflict()
          })
        }
      })
    })
  }

  @action
  queryProjectList = () => {
    this.loading = true;
    return socketStore.ready().then(api => {
      return api.queryProjectList(this.projectListFilters).then((result: { status: number, message: string, list: Project[], count: number }) => {
        const { status, message, list, count } = result
        if (status !== 200) {
          this.loading = false;
          return Modal.error({
            title: EN.SystemDataAnomaly,
            content:message
          });
          // return antdMessage.error(message)
        }
        let newList = list.map(row => new Project(row.id + "", row))
        const current = this.project
        if (current) {
          newList = newList.filter(p => p?.id !== current?.id)
          current.visiable = newList.length !== list.length;
          newList.push(current)
        }
        this.list = [...newList]
        // this.list = list.map(row => new Project(row.id + "", row))
        this.total = count
        this.loading = false;
        this.initList = true
      })
    })
  }

  getUserList = (projectId=this.currentId)=>{
    return socketStore.ready().then(api=>{
        api.projectUserList({
          projectId
        }).then(result=>{
          this.userList = result.list;
        })
    })
  }

  @action
  addProject = () => {
    if (this.loading) return
    this.loading = true;
    return socketStore.ready().then(api => {
      return api.addProject().then((result: { status: number, message: string, id: string }) => {
        const { status, id } = result
        if (status !== 200) {
          let message = result.message
          if (status === -5) message = EN.ProjectLimitExceed
          if (status === -6) message = EN.ProjectCreateFailed
          this.loading = false;
          return { error: message }
        }
        this.list.push(new Project(id + "", { createTime: moment().unix(), updateTime: moment().unix(), visiable: false }))
        this.loading = false;
        return { id }
      })
    })
  }

  @action
  deleteProjects = (ids: string[]) => {
    if (this.loading) return Promise.resolve()
    this.loading = true;
    return socketStore.ready().then(api => {
      //移除删除项目所有命令
      const arr = this.list.filter(p => ids.includes(p.id)).map(_p => _p.abortAll())

      return Promise.all(arr).then(() => {
        return api.deleteProjects({ ids })
            .then((result: { status: number, message: string }) => {
                const { status, message } = result
                this.loading = false;
                if (status !== 200) {
                  return Modal.error({
                    title: '删除失败!',
                    content:message
                  });
                  // return antdMessage.error(message)
                }
                return this.queryProjectList()
              })
      })
    })
  }

  @action
  initProject = (id: string) => {
    if (this.projectInit) return Promise.resolve(true)
    this.projectInit = true
    return new Promise(resolve => {
      let project;
      when(
        () => this.initList,
        () => {
          if (this.list.length) {
            project = this.list.find(row => {
              return row.id === id
            })
            if (project) {
              project.initProject()
              // project.initModels()
              this.currentId = id
              this.inProject(id)
              // this.projectInit = false
              return resolve(true)
            }
          }
          socketStore.ready().then(api => {
            api.checkProject({ id }).then((result: { status: number, result: Project, message: string }) => {
              const { status, result:data, message } = result
              if (status !== 200) {
                Modal.error({
                  title: EN.SystemDataAnomaly,
                  content:message
                });
                // antdMessage.error(message)
                return resolve(false)
              }
              const hiddenProject = new Project(id, {
                id: data.id,
                fileName: data.fileName,
                createTime: data.createTime,
                updateTime: data.updateTime,
                name: data.name,
                problemType: data.problemType,
                train2ing: data.train2ing,
                modelCount: data.modelCount,
                visiable: false
              })
              this.list.push(hiddenProject)
              hiddenProject.initProject()
              // hiddenProject.initModels()
              this.currentId = id
              this.inProject(id)
              // this.projectInit = false
              resolve(true)
            })
          })
        }
      )
    })
  }

  @action
  inProject = (id: string) => {
    return socketStore.ready().then(api => {
      this.broadcastId = uuidV4()
      return api.inProject({ id, broadcastId: this.broadcastId })
    })
  }

  @action
  outProject = () => {
    this.currentId = ""
    this.conflict = false
  }

  @action
  showConflict = () => {
    this.conflict = true
  }

  @action
  notExit = () => {
    this.conflict = false
    return this.inProject(this.currentId)
  }

  @action
  clean = () => {
    if (this.currentId) {
      const projectIndex = this.list.findIndex(p => +p.id === +this.currentId)

      // //重新初始化
      if(this.list[projectIndex]){
        const p = this.list[projectIndex];
        this.list[projectIndex] = new Project(this.currentId, {
          id: p.id,
          fileName: p.fileName,
          createTime: p.createTime,
          updateTime: p.updateTime,
          name: p.name,
          problemType: p.problemType,
          train2ing: p.train2ing,
          modelCount: p.modelCount,
          visiable: p.visiable,
          role:p.role,
        })
      }
      // projectIndex&&(this.list[projectIndex] = new Project(this.currentId, {
      //   id: this.list[projectIndex].id,
      //   fileName: this.list[projectIndex].fileName,
      //   createTime: this.list[projectIndex].createTime,
      //   updateTime: this.list[projectIndex].updateTime,
      //   name: this.list[projectIndex].name,
      //   problemType: this.list[projectIndex].problemType,
      //   train2ing: this.list[projectIndex].train2ing,
      //   modelCount: this.list[projectIndex].modelCount,
      //   visiable: this.list[projectIndex].visiable,
      //   role:this.list[projectIndex].role,
      // }))
    }
    this.currentId = ''
    this.projectInit = false
  }
}

export { ProjectStore }

export default new ProjectStore()
