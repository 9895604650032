import React, { useContext, useState, useEffect } from 'react';
import styles from '../styles.module.css';
import classnames from 'classnames';
import { Tooltip, Radio, Modal, Button, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { MobXProviderContext, observer, useLocalStore } from 'mobx-react';
import { NumberInput } from 'components/Common';
import { formatNumber } from '../../../../../util';
import EN from '../../../../../constant/en';
import ModelTable from './ModelTable'
import Performance from './Performance'
import Project from 'stores/Project';
import Model from 'stores/Model';
import { ProjectStore } from 'stores/ProjectStore';
import { ProgressStore } from 'stores/ProgressStore';

const { Text, Title, Paragraph } = Typography;

interface Interface {
  project: Project
  models: Model[]
  sort: {
    key: string,
    value: number
  }
  handleSort: (s: string) => void
}

function ClassificationResult(props:Interface){
  const { sort, handleSort,models } = props;
  // @ts-ignore
  const {
    projectStore:{
      project,
      project:{
        costOption,
        distribution,
        updateProject,
        setSelectModel,
        targetArray,
        targetColMap,
        renameVariable,
        targetCounts,
        train2Finished,
        trainModel,
        abortTrain,
        selectModel: current,
        recommendModel,
        criteria,
        costOption: { TP, FN, FP, TN },
        targetArrayTemp,
        isAbort,
        mapHeader,
        newVariable,
        stopIds,
        checkItems,
        bigData
      },
    },
    progressStore: {
      progresses,
      getPrejectTrainProgresses
    }
  }:{
    projectStore:ProjectStore
    progressStore:ProgressStore
  } = useContext(MobXProviderContext);
  
  const store = useLocalStore(() => ({
    showCost:false,
    costOption:{...costOption},
    showTip:false,
    distribution:distribution||'',
    updateField(data) {
      Object.assign(this, data);
    },
  }));

  useEffect(() => {
    getPrejectTrainProgresses(stopIds)
  }, []);

  const [allStopIds, setAllStopIds] = useState([]);

  useEffect(() => {
    let _stopIds = []
    stopIds.map(x => {
      if (progresses[x])
        _stopIds.push({key: x , value: progresses[x]})
      else _stopIds.push({key: x , value: {data: {requestId: x}}})
    });
    setAllStopIds(_stopIds);
  }, [stopIds, progresses]);

  const onChange = async e => {
    const criteria = e.target.value;
    store.updateField({
      showCost:criteria === 'cost',
      showTip:false,
    })
    const data: any = { criteria };
    if (!store.showCost) {
      data.selectId = '';
      models.map(async m => {
        if (!m.initialFitIndex) return;
        await m.updateModel({ fitIndex: m.initialFitIndex });
      });
    } else {
      await handleSubmit();
    }
    return updateProject(data);
  };

  const onSelect = model => {
    return setSelectModel(model.id);
  };

  const onHide = () => {
    store.updateField({
      showCost:false,
      showTip:false,
    })
  };

  const costInput = (row, col, defaultValue: any = false) => {
    const field = (row === col ? 'T' : 'F') + (col === 1 ? 'P' : 'N');
    const target = targetArray.length
      ? targetArray
      : Object.keys(targetColMap);
    let _targetArray = defaultValue ? ['no', 'yes'] : target;
    let event = _targetArray[row];
    if (renameVariable && renameVariable[event]) {
      event = renameVariable[event];
    }
    const correct = col === row ? EN.correctly : EN.incorrectly;
    const bool = row === col ? EN.True : EN.False;
    const posNeg = col === 1 ? EN.Positive : EN.Negative;
    return (
      <div className={styles.costTd}>
        <div className={styles.newCostTitle}>
          <div>
            {EN.Predict} {`<${event}>`} {correct}
          </div>
          <div>
            ({bool}
            {posNeg},{field})
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={styles.costInput} style={{ marginRight: 8 }}>
            <NumberInput
              disabled={defaultValue}
              value={defaultValue || costOption[field]}
              onBlur={handleChange.bind(null, field)}
              min={0.0}
              max={1000000000.0}
              isInt={false}
              digits={2}
              cut={true}
            />
          </div>
          <div className={styles.costUnits}>
            <span>{EN.Units}</span>
          </div>
        </div>
      </div>
    );
  };
  const handleChange = (field, value) => {
    store.updateField({
      costOption:{
          ...store.costOption,
          [field]:value,
      }
    })
  };

  const handleChangeEvent = value => {
    store.updateField({
      distribution:value,
    })
  };

  const handleSubmit = () => {
    const [v0, v1] = Object.values(targetCounts);
    const percent0 = parseFloat(formatNumber(String(v1 / (v0 + v1)), 4));
    const percentNew =
      typeof store.distribution === 'number'
        ? store.distribution / 100
        : percent0;
    models.forEach(async m => {
      const benefit = m.getBenefit(TP, FN, FP, TN, percentNew, percent0);
      if (benefit.index !== m.fitIndex)
        await m.updateModel({ fitIndex: benefit.index });
    });
    return updateProject({
      costOption: { ...store.costOption },
      selectId: '',
      distribution: store.distribution,
    });
  };

  const reset = () => {
    const [v0, v1] = Object.values(targetCounts);
    const percent = parseInt(String((v1 / (v0 + v1)) * 10000), 0);
    store.updateField({
      distribution:percent / 100,
      costOption: { TP: 0, FN: 0, FP: 0, TN: 0 }
    })
    models.forEach(async m => {
      if (!m.initialFitIndex) return;
      await m.updateModel({ fitIndex: m.initialFitIndex });
    });
    return updateProject({
      costOption: store.costOption,
      selectId: '',
      distribution: percent / 100,
    });
  };


    if (!current) return null;
    const { fitIndex, chartData } = current;
    const { roc } = chartData;

    const Threshold = roc&&(roc.Threshold && roc.Threshold[fitIndex]) || -1;

    const tc: any = Object.values(targetCounts);
    const event = parseInt(String((tc[1] / (tc[0] + tc[1])) * 10000), 0);

    const currentPerformance = current
      ? (current.score.validateScore.auc > 0.8 && EN.GOOD) ||
      (current.score.validateScore.auc > 0.6 && EN.OK) ||
      EN.NotSatisfied
      : '';
    const [v0, v1] = !targetArrayTemp.length
      ? Object.keys(targetColMap)
      : targetArrayTemp;
    const [no, yes] = [renameVariable[v0] || v0, renameVariable[v1] || v1];
    const text =
      criteria === 'cost' && (TP || FN || FP || TN)
        ? EN.BenefitCost
        : EN.Recommended;
    const curBenefit = current.getBenefit(
      TP,
      FN,
      FP,
      TN,
      typeof distribution === 'number' ? distribution / 100 : event / 10000,
      event / 10000,
    );
    const newMapHeader:any = {
      ...(Array.isArray(mapHeader) ? mapHeader.reduce((prev, v, k) => Object.assign(prev, { [k]: v }), {}) : mapHeader),
      ...newVariable.reduce((prev, v) => Object.assign(prev, { [v]: v }), {}),
    };
    return (
      <>
        <div className={styles.result}>
          <div className={styles.box}>
            <div className={styles.title}>
              <span>{EN.RecommendedAModel}</span>
            </div>
            <div className={styles.row}>
              <span>{EN.ModelingResult} : </span>
              <div className={styles.status}>
                &nbsp;&nbsp;{currentPerformance}
              </div>
            </div>
            <div className={styles.row}>
              <span>
                {EN.SelectedModel} :
                <a className={styles.nohover}>&nbsp;{current.modelName}</a>
              </span>
            </div>
            <div className={styles.row}>
              <span>
                {EN.Target} :
                <a className={styles.nohover}>
                  &nbsp;{mapHeader[project.target]}
                </a>
              </span>
            </div>
          </div>
          <Performance current={current} yes={yes} no={no} />
        </div>
        <div className={styles.line} />
        <div className={styles.selectBlock} style={{ marginBottom: 16 }}>
          <div className={styles.selectText}>
            <span> {EN.Selectyourmodelbasedonyourown}</span>
          </div>

          <Radio.Group onChange={onChange} value={criteria}>
            <Radio value="default">{EN.R2LearnsDefaultSelection}</Radio>
            <Radio value="cost">
              {EN.BenefitCost}
              <Tooltip title={EN.Ifyoucanestimatethebusiness}>
                <QuestionCircleOutlined style={{ marginLeft: 4 }} />
              </Tooltip>
            </Radio>
          </Radio.Group>
        </div>
        <ModelTable
          models={models}
          current={current}
          checkItems={checkItems}
          onSelect={onSelect}
          train2Finished={train2Finished}
          trainModel={trainModel}
          abortTrain={abortTrain}
          isAbort={isAbort}
          project={project}
          recommendId={recommendModel.id}
          text={text}
          sort={sort}
          handleSort={handleSort}
          mapHeader={newMapHeader}
          stopIds={stopIds}
          bigData={bigData}
          allStopIds={allStopIds}
        />
        <Modal
          visible={store.showCost}
          onCancel={onHide}
          closable={false}
          footer={null}
        >
          <Typography>
            <Title level={5}>{EN.Input}</Title>
            <Paragraph>{EN.Basedonyourbizscenario}</Paragraph>
            <Paragraph><Text strong>{EN.A}</Text> {EN.Pleaseenterbenefitandcostin}</Paragraph>
            <Paragraph><Text strong>{EN.B}</Text> {EN.Pleaseenterbenefitandcostins}</Paragraph>
            <Paragraph style={{ color: '#f5a623', fontStyle: 'italic' }}>{EN.Noteifacorrectpredictionbringsyouprofit}</Paragraph>
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
            <div className={styles.eventInput}>
              <span>{EN.EventDistribution}</span>
              <NumberInput
                value={
                  typeof store.distribution === 'number'
                    ? store.distribution
                    : event / 100
                }
                onBlur={handleChangeEvent}
                min={0.0}
                max={100.0}
                isInt={false}
                digits={2}
                cut={true}
              />
              <Button type='link' className={styles.reset} onClick={reset}>
                {EN.Reset}
              </Button>
            </div>
            <Button
              style={{ marginLeft: 'auto' }}
              type='primary'
              onClick={() => store.updateField({ showTip: true })}
            >
              {EN.Tips}
            </Button>
          </div>
          <div className={styles.costBox}>
            <div className={styles.costTable}>
              <div className={styles.costRow}>
                <div className={styles.costName}>
                  <div
                    className={classnames(styles.costColor, styles.cost1)}
                  />
                  <span>{EN.Benefit}</span>
                </div>
                <div className={styles.costCell}>
                  {costInput(1, 1)}
                </div>
                <div className={styles.costCell}>
                  {costInput(0, 0)}
                </div>
              </div>
              <div className={styles.costRow}>
                <div className={styles.costName}>
                  <div
                    className={classnames(styles.costColor, styles.cost2)}
                  />
                  <span>{EN.Cost}</span>
                </div>
                <div className={styles.costCell}>
                  {costInput(1, 0)}
                </div>
                <div className={styles.costCell}>
                  {costInput(0, 1)}
                </div>
              </div>
            </div>
          </div>
          {!!(TP || FN || FP || TN) && (
            <div className={styles.costTextBox}>
              <div>
                <span className={styles.newStext}>
                  <b>
                    {EN.Resultbasedonthedataset}
                    {`<${
                      typeof distribution === 'number'
                        ? distribution
                        : event / 100
                      }%>`}
                    {EN.Events}
                  </b>
                </span>
              </div>
              <div style={{ display: Threshold !== -1 ? '' : 'none' }}>
                <span className={styles.newStext}>
                  {EN.Theoptimalthreshold}
                  {`<${formatNumber(Threshold, 3)}>`}
                </span>
              </div>
              <div style={{ display: Threshold !== -1 ? '' : 'none' }}>
                <span className={styles.newStext}>
                  {EN.Theoverallbenefit}
                  {`<${
                    curBenefit.benefit > Math.pow(10, 7)
                      ? curBenefit.benefit.toPrecision(3)
                      : formatNumber(curBenefit.benefit, 2)
                    }>`}
                </span>
              </div>
              {/* <div className={styles.costText}><span>{curBenefit.text}</span></div> */}
            </div>
          )}
          <div className={styles.costButton}>
            <Button onClick={handleSubmit} type='primary' block>
              {EN.Submit}
            </Button>
          </div>
        </Modal>
        <Modal
          visible={store.showTip}
          onCancel={() => store.updateField({ showTip:false })}
          closable={false}
          footer={null}
        >
          <div className={styles.costBlock}>
            <div>
              <section className={styles.newTitle}>
                <label>{EN.Input}</label>
                <dl>
                  <dt
                    className={styles.newTitleDt}
                    style={{ fontSize: '12px', display: 'block !important' }}
                  >
                    <p><b>{EN.A}</b>
                      {EN.Boththebenefitandcost}</p>
                    <p><b>{EN.B}</b>
                      {EN.Eventdistributioninputranges}</p>
                    <p><span style={{ color: '#f5a623', fontStyle: 'italic' }}>
                    {EN.NoteAllinputsentered}
                  </span></p>
                  </dt>
                  {/*<dt>*/}
                  {/*  <span><span style={{ display: 'block' }}><b>{EN.A}</b>{EN.Boththebenefitandcost}</span></span>*/}
                  {/*  <span><span style={{ display: 'block' }}><b>{EN.B}</b>{EN.Eventdistributioninputranges}</span></span>*/}
                  {/*  <span style={{ color: '#f5a623', fontStyle: 'italic' }}>{EN.NoteAllinputsentered}</span>*/}
                  {/*</dt>*/}
                </dl>
                <label>{EN.BenefitCost}</label>
                <dl>
                  <dt>
                  <span>
                    {EN.Benefitcostiscalculated}
                    (r<sub>modified</sub>/r<sub>0</sub>)*({EN.Benefits}*TP–
                    {EN.Costs}*FN)+(1-r<sub>modified</sub>) /(1-r
                    <sub>0</sub>)*({EN.Benefits}*TN–{EN.Costs}*FP);
                    {EN.TPTruePositiveTNTrueNegative}
                  </span>
                  </dt>
                  {/*<dt>*/}
                  {/*  <span>{EN.Benefitcostiscalculated}</span>*/}
                  {/*  <span><span style={{ display: 'block' }}>(r<sub>modified</sub>/r<sub>0</sub>)*({EN.Benefits}*TP–{EN.Costs}*FN)+(1-r<sub>modified</sub>) /(1-r<sub>0</sub>)*({EN.Benefits}*TN–{EN.Costs}*FP);</span></span>*/}
                  {/*  <span>{EN.TPTruePositiveTNTrueNegative}</span>*/}
                  {/*</dt>*/}
                </dl>
                <label>{EN.EventDistribution}</label>
                <dl>
                  <dt>
                  <span>
                    {EN.Eventdistributionistheproportion}r<sub>0</sub>
                    {EN.Isthedefaulteventdistribution}r<sub>modified</sub>
                    {EN.Istheuserprovideddistribution}
                  </span>
                  </dt>
                </dl>
                <label>{EN.Example}</label>
                <dl>
                  <dt>
                    <span>{EN.Inthisloandefaultexample}</span>
                  </dt>
                </dl>
                <ul>
                  <li>{EN.Acorrectpredictionof}</li>
                  <li>{EN.Acorrectpredictionofnondefault}</li>
                  <li>{EN.Anincorrectpredictionofthedefault}</li>
                  <li>{EN.Andanincorrectpredictionofthenondefault}</li>
                  <li>{EN.Thedistributionof}</li>
                </ul>
                <p>{EN.Youcaninputthisinformationinto}</p>
              </section>
              <section className={styles.newTitle} style={{ marginBottom: 8 }}>
                <dl style={{ margin: '0.1em 0' }}>
                  <dt>
                    <div className={styles.eventInput}>
                    <span style={{ marginRight: '0.5em' }}>
                      {EN.EventDistribution}
                    </span>
                      <input disabled value={20} onChange={() => { }} />
                      <span style={{ marginLeft: '0.5em' }}>%</span>
                    </div>
                  </dt>
                </dl>
              </section>
              <div className={styles.costBox}>
                <div className={styles.costTable}>
                  <div className={styles.costRow}>
                    <div className={styles.costName}>
                      <div
                        className={classnames(styles.costColor, styles.cost1)}
                      />
                      <span>{EN.Benefit}</span>
                    </div>
                    <div className={styles.costCell}>
                      {costInput(1, 1, 200)}
                    </div>
                    <div className={styles.costCell}>
                      {costInput(0, 0, 100)}
                    </div>
                  </div>
                  <div className={styles.costRow}>
                    <div className={styles.costName}>
                      <div
                        className={classnames(styles.costColor, styles.cost2)}
                      />
                      <span>{EN.Cost}</span>
                    </div>
                    <div className={styles.costCell}>
                      {costInput(1, 0, 200)}
                    </div>
                    <div className={styles.costCell}>
                      {costInput(0, 1, 100)}
                    </div>
                  </div>
                </div>
              </div>
              <p>
                {EN.R2Learnthenautomaticallyfinds}
                {EN.Thatmaximizethebenefit}
              </p>
              <p>{EN.Currentbenefitcostequals}</p>
              <p>
                (0.2/0.1)*(200*2000(TP) –
                200*100(FN))+(0.8/0.9)*(100*10000(TN)-100*200(FP)) =
                11631111.11
              </p>
            </div>
          </div>
        </Modal>
      </>
    );
}

export default observer(ClassificationResult)
