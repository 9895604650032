import React, { ReactElement, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import classnames from 'classnames';
import home from './icon-home.svg';
import homeActive from './icon-home-active.svg';
import help from './icon-help.svg';
import helpActive from './icon-help-active.svg';
import community from './community.png'
import switchIcon from './switch.svg';
import { MobXProviderContext, observer } from 'mobx-react';
import EN from '../../../constant/en';
import { DashboardOutlined, UserOutlined } from '@ant-design/icons';
import { Show } from 'components/Common';
import { UserStore } from 'stores/UserStore';
import { DeploymentStore } from 'stores/DeploymentStore';
import { ProjectStore } from 'stores/ProjectStore';

function Sider():ReactElement{
  
  // @ts-ignore
  const {
    userStore:{
      labUrl,
      dashUrl,
      info,
      status,
    },
    deploymentStore,
    projectStore,
  }:{
    userStore: UserStore,
    deploymentStore: DeploymentStore,
    projectStore: ProjectStore
  } = useContext(MobXProviderContext)
  const history = useHistory();
  let [isDeploy ,setDeploy] = useState(history.location.pathname.includes('deploy'));
  let [isSupport ,setSupport] = useState(history.location.pathname.includes('support'));

  const changeRender = () => {
    setSupport(history.location.pathname.includes('support'));
    setDeploy(history.location.pathname.includes('deploy'));
  }
  const switchClick = (project) => {
    if (!project) {
      return;
    } 
    const userId = info.id;
    if (history.location.pathname.indexOf('/deploy/project/') !== -1) {     
      projectStore.clean();
      // const deploymentId = location.pathname.split('/')[3];
      // const projectId = deploymentStore.deployments.find(d => +d.id === parseInt(deploymentId, 10)).project.id;
      const projectId = deploymentStore.currentDeployment.projectId
      history.push('/project/' + projectId);
      changeRender();
      return;
    }
    isDeploy || !userId ? history.push('/') : history.push('/deploy');
    changeRender();
  };

    const isLogin = status === 'login';
    
    const {local} = (window as any)?.r2_env || {};

    const { project = true } = info.role;

    const {logoName,showSupport} = (window as any).r2_env;
    return (
      <aside className={styles.sider}>
        <div className={styles.logo}>
          { !!logoName && <img className={styles.logoImg} src={require(`./${logoName}`)} alt="logo"/>}
        </div>
        <div className={styles.menus}>
          {/*<Show*/}
          {/*  name='project'*/}
          {/*>*/}
            <a
              className={styles.home}
              onClick={() => {
                if (!isLogin) return;
                history.push('/');
                changeRender();
              }}
            >
              {!isDeploy && !isSupport ? <img alt="home" src={homeActive} /> : <img alt="home" src={home} />}
              <h4 className={classnames(styles.nav, {
                [styles.active]: !isDeploy && !isSupport
              })}>{EN.Home}</h4>
            </a>
          {/*</Show>*/}


            {showSupport && (window as any).r2_env.domain !== 'CMB' && <a className={styles.support}
              onClick={() => {
                history.push('/support');
                changeRender();
              }}>
              {isSupport ? <img alt="support" src={helpActive} /> : <img alt="support" src={help} />}
              <h4 className={classnames(styles.nav, {
                [styles.active]: isSupport
              })}>{EN.Support}</h4>
            </a>}

          {/* <Show
            name='JupyterLab'
          > */}
           {/* <a className={styles.support}
              style={{ display: (labUrl ? '' : 'none') }}
              onClick={() => labUrl && window.open(labUrl, '_blank')}>
              <img alt="support" src={community} className={styles.community}/>
              <h4 className={styles.nav}>JupyterLab</h4>
          </a> */}
          {/* </Show> */}

          {/*<Show*/}
          {/*  name='Dashboard'*/}
          {/*>*/}
            <a className={styles.support}
              style={{ display: (dashUrl ? '' : 'none') }}
              onClick={() => dashUrl && window.open(dashUrl, '_blank')}>
              <DashboardOutlined style={{ fontSize: '32px', color: '#2987a4', marginBottom: '5px' }} />
              <h4 className={styles.nav}>Dashboard</h4>
            </a>
          {/*</Show>*/}
          {/* {isLogin && !local && <Show
            name='Account'
          > */}
            {
              isLogin && !local && <a className={styles.support}
              onClick={() => history.push('/account')}>
              <UserOutlined style={{ fontSize: '32px', color: '#2987a4', marginBottom: '5px' }} />
              <h4 className={styles.nav}>Account Info</h4>
            </a>
            }
          {/* </Show>} */}
        </div>
        <a className={styles.bottom} onClick={switchClick.bind(this, project)}>
          <img alt="switch" src={switchIcon} />
          {isDeploy || !isLogin ? (
            <h4 className={styles.nav}>
              {EN.Model}<br />{EN.TrainingS}
            </h4>
          ) : (
              <h4 className={styles.nav}>
                {EN.Deployments}<br />{EN.Console}
              </h4>
            )}
        </a>
      </aside>
    );
}

export default observer(Sider)

// export default withRouter(Sider)
