import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import styles from './styles.module.css';
import { CorrelationMatrixs } from '../../../Charts';
import { CloseOutlined } from '@ant-design/icons';

interface Interface {
  onClose: (e: any) => void;
  CorrelationMatrixData: any;
}

function CorrelationPlot(props: Interface): ReactElement {
  const { onClose, CorrelationMatrixData } = props;
  return (
    <div className={styles.correlationPlot}>
      <div onClick={onClose} style={{ zIndex: 5 }} className={styles.plotClose}>
        <span>
          <CloseOutlined
            style={{
              float: 'right',
              height: 23,
              alignItems: 'center',
              display: 'flex',
            }}
            onClick={onClose} />
        </span>
      </div>
      <CorrelationMatrixs message={CorrelationMatrixData} />
    </div>
  );
}

export default observer(CorrelationPlot);
