import * as React from 'react';

const useStyles = {
  card: {
    paddingBottom: '.5rem',
    border: '1px solid #b5c2d8',
  },
};

interface InterfaceMuiCardProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

function MuiCard(props: InterfaceMuiCardProps) {
  // initial styles

  const { children, style, className } = props;

  return (
    <div className={`${className}`} style={{ ...style, ...useStyles.card}}>
      {children}
    </div>
  );
}

export default  MuiCard