import React, { ReactElement, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  MobXProviderContext,
  observer,
  useLocalStore,
  useObserver,
} from 'mobx-react';
import {
  CaretRightOutlined,
  PauseOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Checkbox, Progress, Select, Modal, Spin } from 'antd';
import { action } from 'mobx';
import moment from 'moment';
import styles from './styles.module.css';
import apiIcon from './icon-data-api.svg';
import sourceIcon from './icon-data-source.svg';
import databaseIcon from './icon-database.svg';
import fileIcon from './icon-file-local.svg';
import Email from './email.svg';
import appIcon from './icon-inapp.svg';
import onceIcon from './icon-once.svg';
import ApiInstruction from './ApiInstruction';
import Uploader from '../Uploader';
import { formatNumber } from '../../../util';
import EN from '../../../constant/en';
import {
  Show,
  DatabaseConfig,
  AutoRepeat,
  OneTime,
  Hint,
  EmailAlert,
  ContinueButton,
} from 'components/Common';
import { UserStore } from 'stores/UserStore';
import { DeploymentStore } from 'stores/DeploymentStore';
import { SocketStore } from 'stores/SocketStore';
import { Domain, ProjectRolesKey } from '@app/constant/types';
const { Option, OptGroup } = Select;

const ordinalNumberPostFix = number => {
  if ((number > 3 && number < 21) || number % 10 > 3) return 'th';
  return { 0: 'th', 1: 'st', 2: 'nd', 3: 'rd' }[number % 10];
};

const dateFormat = {
  day: () => '',
  week: number =>
    [
      '',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ][number],
  month: number => `${number}${ordinalNumberPostFix(number)}`,
};

interface Interface {
  match: any;
}

function Deployment(props: Interface): ReactElement {
  const { match } = props;
  // @ts-ignore
  const {
    userStore,
    deploymentStore: {
      currentDeployment: {
        deploymentOptions,
        save,
        bigData,
        deployment_increments = [],
        modelName,
        deploymentName,
        warning,
        warningEmails,
        version,
        loading,
      },
      setUpdatedBySelf,
      dataChange,
      currentDeployment,
      toggleEnable,
      deploySchedule,
    },
    socketStore,
  }: {
    deploymentStore: DeploymentStore;
    userStore: UserStore;
    socketStore: SocketStore;
  } = useContext(MobXProviderContext);
  const history = useHistory();
  const localStore = useLocalStore(() => ({
    output: null,
    dialog: null,
    localEmail: '',
    emailEditing: false,
    errorTimes: 0,
    uploadStatus: false as boolean | string,
    uploadPercentage: 0 as any,
    modelEditing: false,
    tempModelName: false,
    incrementName: false,
    name: false,
    name_increment: false,
    supportIncrement: false,
    emailSet: false,
    warningEmails,
    warning,
    waiting: false,
    updateField(data) {
      Object.assign(this, data);
    },
    uploadOperator: null as any,
    uploadError: null as any,
  }));
  const selectionOption = async (key, value) => {
    currentDeployment.loading = true;
    const deepcopyDO = JSON.parse(
      JSON.stringify(currentDeployment.deploymentOptions),
    );
    deepcopyDO[key] = value;
    setUpdatedBySelf();
    await currentDeployment.singleSave({ deploymentOptions: deepcopyDO });
    // 修改deployments中的当前Deployment
    dataChange(currentDeployment.id, { deploymentOptions: deepcopyDO });
    currentDeployment.loading = false;
  };

  const charsetChange = value => {
    selectionOption('charset', value);
  };

  const show = dialog =>
    localStore.updateField({
      dialog,
    });

  const closeDialog = () =>
    localStore.updateField({
      dialog: null,
    });

  const showOutput = output =>
    localStore.updateField({
      output,
    });

  const closeOutput = () =>
    localStore.updateField({
      output: null,
    });

  const modelChange = data => {
    const [
      tempModelName,
      incrementName,
      name,
      name_increment,
      supportIncrement,
    ] = JSON.parse(data);

    // _oldName,//DeploymentName 显示名称
    // _newName,//IncrementName
    // old,//Model_name //训练名称
    // itm.new,//Increment_name

    localStore.updateField({
      tempModelName, //DeploymentName
      incrementName,
      name, //训练名称
      name_increment, //Increment_name
      supportIncrement,
    });
  };

  const onSaveModel = action(async () => {
    const cd = currentDeployment;
    let upStore: any = {
      modelEditing: !localStore.modelEditing,
    };
    if (localStore.modelEditing && localStore.tempModelName) {
      cd.loading = true;
      const {
        tempModelName,
        incrementName,
        name,
        name_increment,
        supportIncrement,
      } = localStore;
      cd.modelName = name; //训练名称
      cd.incrementName = incrementName;
      cd.deploymentName = tempModelName; //DeploymentName
      cd.increment_name = name_increment;
      cd.supportIncrement = supportIncrement;
      await cd.save();
      upStore.tempModelName = false;
      cd.loading = false;
    }
    localStore.updateField(upStore);
  });

  const pause = () => {
    if (!localStore.uploadOperator) return;
    if (localStore.uploadStatus === 'uploading') {
      socketStore.ready().then(api => {
        api.removeEventListener('offline', pause);
      });
      console.log('paused');
      localStore.uploadOperator.pause();
      localStore.updateField({
        uploadStatus: 'paused',
      });
      return;
    }
    localStore.uploadOperator.resume();
    localStore.updateField({
      uploadStatus: 'uploading',
    });
  };

  const cd = currentDeployment;
  const cddo: any = cd.deploymentOptions;
  const uploader = {
    onError: (error, times = 1) => {
      console.error(error);
      socketStore.ready().then(api => {
        api.removeEventListener('offline', pause);
      });
      localStore.updateField({
        errorTimes: times,
        uploadStatus: 'error',
        uploadError: error,
      });
    },
    onFinished: async (response, file) => {
      cd.loading = true;
      socketStore.ready().then(api => {
        api.removeEventListener('offline', pause);
      });
      const deepcopyDO = JSON.parse(JSON.stringify(cddo));
      deepcopyDO['file'] = file.name;
      deepcopyDO['fileId'] = response.originalIndex;
      deepcopyDO['source'] = 'file';
      localStore.updateField({
        uploadPercentage: 100,
        uploadStatus: false,
      });
      setUpdatedBySelf();
      await cd.singleSave({ deploymentOptions: deepcopyDO });
      // 修改deployments中的当前Deployment
      dataChange(cd.id, { deploymentOptions: deepcopyDO });
      cd.loading = false;
    },
    onProgress: progress => {
      localStore.updateField({
        uploadStatus: 'uploading',
        uploadPercentage: formatNumber(String(progress), 2),
      });
    },
    onStart: opt => {
      socketStore.ready().then(api => {
        api.addEventListener('offline', pause);
      });
      localStore.updateField({
        uploadStatus: 'uploading',
        uploadPercentage: 0,
        uploadOperator: opt,
      });
    },
    operator: uploadOperator => {
      localStore.updateField({
        uploadOperator,
      });
    },
    params: {
      projectId: cd.projectId,
      userId: userStore.info.id,
      type: 'deploy',
    },
    mapHeader: cd.mapHeader,
  };

  const deploy = () => {
    if (bigData || (cddo.frequency && version)) {
      toggleEnable();
      history.push(`/deploy/project/${match.params.id}/operation`);
      return deploySchedule(match.params.id, null, version);
    }
    if (!version && !bigData) {
      localStore.updateField({
        waiting: true,
      });
    }
  };

  useEffect(() => {
    console.log('waiting & version', localStore.waiting, version);
    if (localStore.waiting && version) {
      deploy();
    }
  }, [localStore.waiting, version]);

  return useObserver(() => (
    <Spin wrapperClassName={styles.loading} spinning={loading}>
      <div className={styles.deployment}>
        <div className={styles.info}>
          <span className={styles.model}>
            {EN.Model}:{' '}
            {localStore.modelEditing ? (
              <Select
                value={localStore.tempModelName || deploymentName || modelName}
                onChange={modelChange}
                style={{
                  width: 400,
                }}
              >
                {cd.modelList &&
                  Object.entries(cd.modelList).map(([settingName, models]) => (
                    <OptGroup key={settingName} label={settingName}>
                      {(models as any).map(model => {
                        if (model) {
                          if (!!deployment_increments && deployment_increments.length > 0) {
                            const incre = deployment_increments.find(
                              itm => itm.model_name === model.name,
                            );
                            if (incre) {
                              return incre.increments.map((itm, index) => {
                                let { old, version } = itm;
                                // version = old?version+1:version;
                                const _newName = `${model.name}[${index +
                                  1}.${version}]`;
                                const _oldName = `${model.name}[${index +
                                  1}.${version - 1}]`;
                                return [
                                  <Option
                                    key={itm.new}
                                    // value={itm._new.name}
                                    value={JSON.stringify([
                                      _newName,
                                      _newName,
                                      itm.new,
                                      itm.new,
                                      model.supportIncrement,
                                    ])}
                                  >
                                    {_newName}
                                  </Option>,
                                  <Option
                                    style={{
                                      display: old ? '' : 'none',
                                    }}
                                    key={old}
                                    value={JSON.stringify([
                                      _oldName, //DeploymentName 显示名称
                                      _newName, //IncrementName
                                      old, //Model_name //训练名称
                                      itm.new, //Increment_name
                                      model.supportIncrement,
                                    ])}
                                  >
                                    {_oldName}
                                  </Option>,
                                ];
                              });
                            }
                          }
                          return (
                            <Option
                              key={model.modelId?.model_name}
                              value={JSON.stringify([
                                model.name,
                                model.name,
                                model.name,
                                model.name,
                                model.supportIncrement,
                              ])}
                            >
                              {model.name}
                            </Option>
                          );
                        }
                      })}
                    </OptGroup>
                  ))}
              </Select>
            ) : (
              deploymentName || modelName
            )}
          </span>
          <Hint
            themeStyle={{ fontSize: '1rem' }}
            content={cd.currentModel?.performance}
          />
          <a className={styles.change} onClick={onSaveModel}>
            <Show name={ProjectRolesKey.DeploymentOperation}>
              {localStore.modelEditing ? EN.Save : EN.Change}
            </Show>
          </a>
          <span className={styles.data}>{EN.DeploymentDataDefinition}</span>
          <Hint
            themeStyle={{ fontSize: '1rem' }}
            content={EN.ValidationDataDefinitionTip}
          />
          <a
            className={styles.download}
            target="_blank"
            href={`/r2upload/dataDefinition?projectId=${cd.projectId}`}
          >
            {EN.Download}
          </a>
          <label className={styles.chooseCharset}>{EN.choosecharset}</label>
          <Select
            style={{ width: '9rem' }}
            value={cddo.charset}
            onChange={charsetChange}
          >
            <Option value="auto">{EN.Auto}</Option>
            <Option value="utf-8">
              <div dangerouslySetInnerHTML={{ __html: EN.UTF_8 }} />
            </Option>
            <Option value="gbk">{EN.GBK}</Option>
            <Option value="big5">{EN.BIG5}</Option>
          </Select>
          {/* 广州银行隐藏邮件预警 (window as any).r2_env.domain !== Domain.GZCB*/}
          {(window as any).r2_env.domain !== Domain.GZCB && (
            <div className={styles.email}>
              <img src={Email} alt="" />
              <a
                onClick={() => {
                  localStore.updateField({
                    emailSet: true,
                  });
                }}
              >
                {EN.EmailAlert}
              </a>
            </div>
          )}
        </div>
        <DeploymentOption cddo={cddo} selectionOption={selectionOption} />
        {cddo.option === 'api' && (
          <ApiInstruction deployment={cd} type={'deploy'} />
        )}
        {cddo.option === 'data' && (
          <DataSource
            cddo={cddo}
            show={show}
            uploader={uploader}
            deploymentOptions={deploymentOptions}
            selectionOption={selectionOption}
            bigData={bigData}
          />
        )}
        <Modal
          visible={!!localStore.uploadStatus}
          width={700}
          maskClosable={false}
          footer={null}
          closable={!!localStore.uploadOperator?.pause}
          onCancel={() => {
            localStore.updateField({
              uploadStatus: false,
            });
            localStore.uploadOperator.pause();
          }}
        >
          <div className={styles.uploading}>
            <Progress
              percent={
                isNaN(localStore.uploadPercentage)
                  ? 0
                  : parseFloat(localStore.uploadPercentage)
              }
            />
            <span className={styles.speed} />
            {localStore.uploadOperator?.pause && (
              <span className={styles.pause} onClick={pause}>
                {localStore.uploadStatus === 'uploading' ? (
                  <span>
                    <PauseOutlined />
                    {EN.Paused}
                  </span>
                ) : (
                  <span>
                    <CaretRightOutlined />
                    {EN.Resume}
                  </span>
                )}
              </span>
            )}
          </div>
          {localStore.uploadStatus === 'error' && (
            <div className={styles.uploadError}>
              {localStore.uploadError.toString()}
            </div>
          )}
        </Modal>
        {cddo.option !== 'api' && cddo.source && (
          <ResultLocation
            cddo={cddo}
            selectionOption={selectionOption}
            showOutput={showOutput}
            bigData={bigData}
          />
        )}
        {cddo.option !== 'api' && cddo.source && cddo.location && (
          <DeployFrequency
            show={show}
            cddo={cddo}
            selectionOption={selectionOption}
          />
        )}
        <Show name={ProjectRolesKey.DeploymentOperation}>
          {cddo.option !== 'api' &&
            cddo.source &&
            cddo.location &&
            cddo.frequency && (
              <div className={styles.done}>
                <div className={styles.selections}>
                  <span className={styles.label}>
                    <span className={styles.text} />
                  </span>
                  <ContinueButton
                    onClick={deploy}
                    disabled={localStore.waiting}
                    text={EN.DONE}
                  />
                </div>
              </div>
            )}
        </Show>

        <DatabaseConfig
          options={cddo.sourceOptions}
          visible={localStore.dialog === 'databasesource'}
          onClose={closeDialog}
          title={EN.DataSourceDatabase}
          mapHeader={cd.mapHeader}
          projectId={cd.projectId}
          testApi={true}
          onSubmit={action(async options => {
            currentDeployment.loading = true;
            const deepcopyDO = JSON.parse(JSON.stringify(cddo));
            deepcopyDO['source'] = 'database';
            deepcopyDO['sourceOptions'] = options;
            setUpdatedBySelf();
            await currentDeployment.singleSave({
              deploymentOptions: deepcopyDO,
            });
            // 修改deployments中的当前Deployment
            dataChange(currentDeployment.id, { deploymentOptions: deepcopyDO });
            currentDeployment.loading = false;
            closeOutput();
          })}
          bigData={bigData}
          connectLocation="deploySource"
        />
        <DatabaseConfig
          options={cddo.locationOptions}
          visible={localStore.output === 'databasesource'}
          validation
          sparkCheck
          projectId={cd.projectId}
          mapHeader={cd.mapHeader}
          onClose={closeOutput}
          title={EN.ValidationDataSource + ' - ' + EN.Database}
          testApi={true}
          skipTableCheck={true}
          onSubmit={action(async options => {
            currentDeployment.loading = true;
            const deepcopyDO = JSON.parse(JSON.stringify(cddo));
            deepcopyDO['location'] = 'database';
            deepcopyDO['locationOptions'] = options;
            setUpdatedBySelf();
            await currentDeployment.singleSave({
              deploymentOptions: deepcopyDO,
            });
            // 修改deployments中的当前Deployment
            dataChange(currentDeployment.id, { deploymentOptions: deepcopyDO });
            currentDeployment.loading = false;
            closeOutput();
          })}
          bigData={cd.bigData}
          isOutput={true}
          connectLocation="deployLocation"
        />
        <OneTime
          options={cddo.frequencyOptions}
          visible={localStore.dialog === 'onetime'}
          onClose={closeDialog}
          onSubmit={action(async options => {
            currentDeployment.loading = true;
            closeDialog();
            const deepcopyDO = JSON.parse(JSON.stringify(cddo));
            deepcopyDO['frequency'] = 'once';
            Object.keys(options).map(x => {
              deepcopyDO['frequencyOptions'][x] = options[x];
            });
            setUpdatedBySelf();
            await currentDeployment.singleSave({
              deploymentOptions: deepcopyDO,
            });
            // 修改deployments中的当前Deployment
            dataChange(currentDeployment.id, { deploymentOptions: deepcopyDO });
            currentDeployment.loading = false;
          })}
        />
        <AutoRepeat
          options={cddo.frequencyOptions}
          visible={localStore.dialog === 'autorepeat'}
          onClose={closeDialog}
          onSubmit={action(async options => {
            currentDeployment.loading = true;
            closeDialog();
            const deepcopyDO = JSON.parse(JSON.stringify(cddo));
            deepcopyDO['frequency'] = 'repeat';
            Object.keys(options).map(x => {
              if (x !== 'updateField')
                deepcopyDO['frequencyOptions'][x] = options[x];
            });
            setUpdatedBySelf();
            await currentDeployment.singleSave({
              deploymentOptions: deepcopyDO,
            });
            // 修改deployments中的当前Deployment
            dataChange(currentDeployment.id, { deploymentOptions: deepcopyDO });
            currentDeployment.loading = false;
          })}
        />
        <EmailAlert
          open={localStore.emailSet}
          Close={() =>
            localStore.updateField({
              emailSet: false,
            })
          }
        />
      </div>
    </Spin>
  ));
}

const DeploymentOption = observer(({ cddo, selectionOption }) => (
  <div className={styles.deploymentOption}>
    <span className={styles.label}>
      <span className={styles.text}>{EN.DeploymentOptionText}:</span>
    </span>
    <div className={styles.selections}>
      {cddo.option === 'api' && (
        <div className={styles.selected}>
          <span className={styles.text}>
            <img alt="api" src={apiIcon} className={styles.selectionIcon} />
            {EN.PredictWithAPI}
          </span>
          <span className={styles.or}>
            <span className={styles.orText}>{EN.Or}</span>
          </span>
        </div>
      )}
      {cddo.option === 'data' && (
        <div className={styles.selected}>
          <span className={styles.text}>
            <img
              alt="data source"
              src={sourceIcon}
              className={styles.selectionIcon}
            />
            {EN.PredictWithDataSource}
          </span>
          <span className={styles.or}>
            <span className={styles.orText}>{EN.Or}</span>
          </span>
        </div>
      )}
      {cddo.option !== 'data' && (
        <div
          className={styles.selection}
          onClick={() => selectionOption('option', 'data')}
        >
          <span className={styles.text}>
            <img
              alt="data source"
              src={sourceIcon}
              className={styles.selectionIcon}
            />
            {EN.PredictWithDataSource}
          </span>
        </div>
      )}
      {cddo.option !== 'api' && (
        <div
          className={styles.selection}
          onClick={() => selectionOption('option', 'api')}
        >
          <span className={styles.text}>
            <img alt="api" src={apiIcon} className={styles.selectionIcon} />
            {EN.PredictWithAPI}
          </span>
        </div>
      )}
    </div>
  </div>
));

const DataSource = observer(
  ({ cddo, show, uploader, deploymentOptions, selectionOption, bigData }) => {
    const Or = (
      <span className={styles.or}>
        <span className={styles.orText}>{EN.Or}</span>
      </span>
    );

    return (
      <div className={styles.dataSource}>
        <span className={styles.label}>
          <span className={styles.text}>{EN.DataSourceText}:</span>
        </span>
        <div className={styles.selections} style={{ alignItems: 'center' }}>
          {cddo.source === 'database' && (
            <div
              className={styles.selected}
              onClick={show.bind(this, 'databasesource')}
            >
              <span className={styles.result}>
                <img
                  alt="database"
                  src={databaseIcon}
                  className={styles.selectionIcon}
                />
                <span className={styles.resultText}>
                  {EN.Database}
                  <span className={styles.path}>
                    {cddo.sourceOptions.sqlTable}
                  </span>
                </span>
              </span>
              {/*<span className={styles.or}>*/}
              {/*  <span className={styles.orText}>{EN.Or}</span>*/}
              {/*</span>*/}
            </div>
          )}
          {!bigData && cddo.source === 'file' && (
            <>
              <div className={styles.selected} id="file">
                <Uploader className={styles.resultText} {...uploader}>
                  <img
                    alt="file"
                    src={fileIcon}
                    className={styles.selectionIcon}
                  />
                  {EN.LocalFile}
                  <span className={styles.path} title={cddo.file}>
                    {cddo.file}
                  </span>
                </Uploader>
                {Or}
                {/*<span className={styles.or}>*/}
                {/*  <span className={styles.orText}>{EN.Or}</span>*/}
                {/*</span>*/}
              </div>
            </>
          )}

          {cddo.source !== 'database' && (
            <>
              <div
                className={styles.selection}
                onClick={show.bind(null, 'databasesource')}
              >
                <span className={styles.text}>
                  <img
                    alt="database"
                    src={databaseIcon}
                    className={styles.selectionIcon}
                  />
                  {EN.Database}
                </span>
              </div>
            </>
          )}
          {!bigData && cddo.source !== 'file' && (
            <>
              <div className={styles.selectionWithoutHover} id="file">
                <Uploader className={styles.text} {...uploader}>
                  <img
                    alt="file"
                    src={fileIcon}
                    className={styles.selectionIcon}
                  />
                  {EN.LocalFile}
                </Uploader>
              </div>
            </>
          )}
        </div>
      </div>
    );
  },
);

const ResultLocation = observer(
  ({ cddo, selectionOption, showOutput, bigData }) => (
    <div className={styles.resultLocation}>
      <span className={styles.label}>
        <span className={styles.text}>{EN.ResultLocation}:</span>
      </span>
      <div className={styles.selections}>
        {cddo.location === 'app' && (
          <div className={styles.selected}>
            <span className={styles.text}>
              <img alt="app" src={appIcon} className={styles.selectionIcon} />
              {EN.InApp}
            </span>
            {bigData && (
              <span className={styles.or}>
                <span className={styles.orText}>{EN.Or}</span>
              </span>
            )}
          </div>
        )}
        {cddo.location === 'database' && bigData && (
          <div
            className={styles.selected}
            onClick={() => showOutput('databasesource')}
          >
            <span className={styles.result}>
              <img
                alt="database"
                src={databaseIcon}
                className={styles.selectionIcon}
              />
              <span className={styles.resultText}>
                {EN.Database}
                <span className={styles.path}>
                  {cddo.locationOptions.sqlTable}
                </span>
              </span>
            </span>
            <span className={styles.or}>
              <span className={styles.orText}>{EN.Or}</span>
            </span>
          </div>
        )}
        {cddo.location !== 'app' && (
          <div
            className={styles.selection}
            onClick={() => selectionOption('location', 'app')}
          >
            <span className={styles.text}>
              <img alt="app" src={appIcon} className={styles.selectionIcon} />
              {EN.InApp}
            </span>
          </div>
        )}
        {cddo.location !== 'database' && bigData && (
          <div
            className={styles.selection}
            onClick={() => showOutput('databasesource')}
          >
            <span className={styles.text}>
              <img
                alt="database"
                src={databaseIcon}
                className={styles.selectionIcon}
              />
              {EN.Database}
            </span>
          </div>
        )}
      </div>
    </div>
  ),
);

const DeployFrequency = observer(({ cddo, selectionOption, show }) => (
  <>
    <div className={styles.deployFrequency}>
      <span className={styles.label}>
        <span className={styles.text}>{EN.DeployFrequency}</span>
      </span>
      <div className={styles.selections}>
        {cddo.frequency === 'once' && (
          <div className={styles.selected} onClick={show.bind(null, 'onetime')}>
            <span className={styles.result}>
              <img alt="once" src={onceIcon} className={styles.selectionIcon} />
              <span className={styles.resultText}>
                {EN.OneTime}
                <span className={styles.detail}>
                  <span className={styles.bold}>{EN.Times} </span>
                  {cddo.frequencyOptions.time === 'completed'
                    ? EN.Aftercompleted
                    : moment
                        .unix(cddo.frequencyOptions.time)
                        .format('MM/DD/YYYY h:mma')}
                </span>
              </span>
            </span>
            <span className={styles.or}>
              <span className={styles.orText}>{EN.Or}</span>
            </span>
          </div>
        )}
        {cddo.frequency === 'repeat' && (
          <div
            className={styles.selected}
            onClick={show.bind(null, 'autorepeat')}
          >
            <span className={styles.result}>
              {/* <SyncOutlined className={styles.selectionIcon} /> */}
              <SyncOutlined className={styles.antdIcon} />
              <span className={styles.resultText}>
                {EN.Redeployevery}{' '}
                {`${cddo.frequencyOptions.repeatFrequency} ${
                  cddo.frequencyOptions.repeatPeriod
                } ${
                  cddo.frequencyOptions.repeatPeriod !== 'day' ? 'on' : ''
                } ${cddo.frequencyOptions.repeatPeriod &&
                  dateFormat[cddo.frequencyOptions.repeatPeriod](
                    cddo.frequencyOptions.repeatOn,
                  )}`}
                <small className={styles.detail}>
                  <span className={styles.bold}>{EN.Starts}:</span>
                  {moment
                    .unix(cddo.frequencyOptions.starts)
                    .format('MM/DD/YYYY h:mma')}
                  <br />
                  <span className={styles.bold}>{EN.Ends}:</span>
                  {cddo.frequencyOptions.ends === 'never'
                    ? 'never'
                    : cddo.frequencyOptions.ends > 10000
                    ? moment
                        .unix(cddo.frequencyOptions.ends)
                        .format('MM/DD/YYYY h:mma')
                    : `after ${cddo.frequencyOptions.ends} occurrences`}
                </small>
              </span>
            </span>
            <span className={styles.or}>
              <span className={styles.orText}>{EN.Or}</span>
            </span>
          </div>
        )}
        {cddo.frequency !== 'once' && (
          <div
            className={styles.selection}
            onClick={show.bind(null, 'onetime')}
          >
            <span className={styles.text}>
              <img alt="once" src={onceIcon} className={styles.selectionIcon} />
              {EN.OneTime}
            </span>
          </div>
        )}
        {cddo.frequency !== 'repeat' && (
          <div
            className={styles.selection}
            onClick={show.bind(null, 'autorepeat')}
          >
            <span className={styles.text}>
              <SyncOutlined className={styles.antdIcon} />
              {/* <SyncOutlined className={styles.selectionIcon} /> */}
              {EN.AutoRepeat}
            </span>
          </div>
        )}
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.holder} />
      <div className={styles.checkbox}>
        <Checkbox
          checked={cddo.dataReport}
          // disabled
          // checked
          onChange={e => selectionOption('dataReport', e.target.checked)}
        >
          <span className={styles.checkboxText}>{EN.DataQualityReport}</span>
        </Checkbox>

        <Checkbox
          checked={cddo.autoDisable}
          onChange={e => selectionOption('autoDisable', e.target.checked)}
        >
          <span className={styles.checkboxText}>
            {EN.Autodisableifanyissueoccurs}
          </span>
        </Checkbox>
      </div>
    </div>
  </>
));
export default observer(Deployment);
