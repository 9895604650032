import { observer, MobXProviderContext } from 'mobx-react';
import React, { ReactElement, useEffect, useState, useContext } from 'react';
import styles from './styles.module.css';
import { Tooltip, Popconfirm, Button } from 'antd';
import EN from '../../../../constant/en';
import { formatNumber } from '../../../../util';
import moment from 'moment';
import Variable from '../Variable.svg';
import Process from '../Process.svg';
import classnames from 'classnames';
import Model from 'stores/Model';
import Project from 'stores/Project';
import { ProjectStore } from 'stores/ProjectStore';
import MPF from '../MPF';
import { ACF_PACF, DensityPlot, QQPlot } from 'components/Charts';
import { ProjectRolesKey } from '@app/constant/types';
import { Show } from '@app/components/Common';

interface Interface {
  model: Model;
  onSelect: (s: string) => void;
  isRecommend: boolean;
  isSelect: boolean;
  project: Project;
}

function ModelDetail(props: Interface): ReactElement {
  // @ts-ignore
  const {
    projectStore: { project: { deleteModelByModelId } },
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext);
  const {
    model: {
      modelName,
      created_at,
      executeSpeed,
      id,
      score,
    },
    model:{
      acfPlotData,
      densityPlotData,
      qqPlotData,
    },
    model,
    onSelect,
    isRecommend,
    isSelect,
    project,
    project:{
      readFile
    },
  } = props;
  const [type, upType] = useState('');
  const [visible, upVisible] = useState(false);
  const [acf, upAcf] = useState(null);
  const [density, upDensity] = useState(null);
  const [qq, upQq] = useState(null);

  function toggleImpact(_type) {
    if (!visible) {
      //本来是关着的
      upType(_type);
      upVisible(true);
      return;
    }
    if (type === _type) {
      upVisible(false);
    } else {
      upType(_type);
    }
  }

  useEffect(()=>{
    if(acfPlotData){
      readFile(acfPlotData).then(actData => {
        upAcf(<ACF_PACF
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          data={actData} />)
      })
    }
  },[acfPlotData]);

  useEffect(()=>{
    if(densityPlotData){
      readFile(densityPlotData).then((data:any) => {
        upDensity(<DensityPlot
          width={500}
          height={350}
          data={data} />)
      })
    }
  },[densityPlotData]);

  useEffect(()=>{
    if(qqPlotData){
      readFile(qqPlotData).then((data) => {
        upQq(<QQPlot data={data} />)
      })
    }
  },[qqPlotData]);

  return (
    <div className={styles.rowBox}>
      <Tooltip
        placement="left"
        title={isRecommend ? EN.Recommended : EN.Selected}
        visible={isSelect || isRecommend}
        overlayClassName={styles.recommendLabel}
        autoAdjustOverflow={false}
        arrowPointAtCenter={true}
        getPopupContainer={el => el.parentElement}
      >
        <div className={styles.rowData}>
          <div className={styles.modelSelect}>
            <input
              type="radio"
              name="modelSelect"
              checked={isSelect}
              onChange={onSelect.bind(null, model)}
            />
          </div>
          <div className={classnames(styles.cell, styles.name)}>
            <Tooltip title={modelName}>{modelName}</Tooltip>
          </div>
          <div className={styles.cell}>
            <span>{formatNumber((score.rmse || "null").toString())}</span>
          </div>
          <div className={styles.cell}>
            <span>{formatNumber((score.mae || "null").toString())}</span>
          </div>
          <div className={styles.cell}>
            <span>{formatNumber((score.r2 || "null").toString())}</span>
          </div>
          <div className={styles.cell}>
            <span>{formatNumber((executeSpeed  || "").toString()) + EN.Rowss}</span>
          </div>
          <div className={styles.cell}>
            <span>
              {created_at
                ? moment(String(created_at)).format('YYYY/MM/DD HH:mm')
                : ''}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <img src={Variable} alt="" />
            <span onClick={toggleImpact.bind(this, 'impact')}>
              {EN.check}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <img src={Process} alt="" />
            <span onClick={toggleImpact.bind(this, 'process')}>
              {EN.Compute}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <Show name={ProjectRolesKey.ModelEdit}>
              <Popconfirm
                title={`${EN.ConfirmDeletion}该模型`}
                onConfirm={deleteModelByModelId.bind(this, model.id)}
                okText={EN.confirm}
                cancelText={EN.Cancel}
              >
                <span >{EN.Delete}</span>
              </Popconfirm>
            </Show>
          </div>
        </div>
      </Tooltip>
      {visible && type === 'impact' && <>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          {density}
          {qq}
        </div>
        {acf}
      </>}
      {visible && type === 'process' && (
        <MPF project={project} model={model} modelId={id} />
      )}
    </div>
  );
}
export default observer(ModelDetail);
