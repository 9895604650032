import React from 'react'
import ReactEcharts from 'echarts-for-react';
import moment from 'moment'
import { Spin } from 'antd';
import _ from 'lodash';

interface Interface {
	data:any
  time?:boolean
	selected?:any
	width?:number|string
	height?:number
	x_names?
	dataZoom?:boolean
}
export default function Lines(props:Interface){
	const result = {
		x_name:'',
		y_name:'',
		title:'',
	};

	const {data,time=false,selected={},width=400,height=300,x_names={},dataZoom=false} = props;

	if(!data){
		return <Spin>
			<div style={{width,height}}/>
		</Spin>
	}

	const {x_name,y_name,title} = result;

	const xDate = time?data.index.map(itm=>moment(+(itm+'000')).format('YYYY/MM/DD')):data.index;

	let maxL = 1;

	const series = Object.keys(data).filter(itm=>itm!=='index').map((name,index)=>{
		const dt = data[name];//.map(itm=>parseInt(itm));
		maxL = _.max([maxL,String(parseInt(String(_.max(dt.filter(itm => !isNaN(itm)))))).length]);
		return {
			type:'line',
			symbol: 'none',
			data:dt,
			name:x_names[name]||name,
			large: true,
			itemStyle:{
				color:['#B4E7CC','#81B2FC','#FF97A7','#F9CF37'][index],
			}
		}
	});

	const option:any =  {
		grid:{
			left:40+10*maxL,
		},
		animation: false,
		dataZoom:{
			show: dataZoom,
			realtime: false,
			showDataShadow: false,
			showDetail:false,
		},
		title: {
			text: title,
			subtext: ''
		},
		xAxis: {
			name:x_name,
			data: xDate,
		},
		yAxis: {
			name:y_name,
			type: 'value'
		},
		series,
		legend: {
			selected,
		},
	};
	return <ReactEcharts
		option={option}
		style={{height, width}}
		notMerge={true}
		lazyUpdate={true}
		theme="customed"
	/>
}
