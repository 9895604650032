import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Show } from 'components/Common';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';
import { MobXProviderContext, observer } from 'mobx-react';
import { runInAction } from 'mobx';
import classnames from 'classnames';
import deploymentIcon from './icon-tab-deployment.svg';
import operationIcon from './icon-tab-operation.svg';
import performanceIcon from './icon-tab-performance.svg';
import statusIcon from './icon-tab-performance.svg';
import incrementIcon from './icon-tab-increment.png';
import Deployment from './Deployment';
import Operation from './Operation';
import Performance from './Performance';
import Increment from './Increment';
import Status from './Status';
import styles from './styles.module.css';
import EN from '../../constant/en';
import { ProjectStore } from 'stores/ProjectStore';
import { DeploymentStore } from 'stores/DeploymentStore';
import { ProjectRolesKey } from '@app/constant/types';

interface Interface {
  match: any;
  location: any;
}

function Detail(props: Interface): ReactElement {
  const { match, location } = props;
  // @ts-ignore
  const {
    deploymentStore,
    projectStore,
  }: {
    projectStore: ProjectStore;
    deploymentStore: DeploymentStore;
  } = useContext(MobXProviderContext);
  const history = useHistory();

  deploymentStore.setCurrentDeployment(match.params.id);

  const { projectId, bigData } = deploymentStore.currentDeployment;

  useEffect(() => {
    if (projectStore.watchList && projectStore.currentId !== projectId) {
      projectStore.currentId = projectId;
      projectStore.init = false;
    }
  }, [projectId, deploymentStore.watchingList]);

  const cd = deploymentStore.currentDeployment || {
    modelType: '',
  };
  const isUnsupervised = ['Clustering', 'Outlier'].includes(cd.modelType);

  return (
    <div className={styles.detail}>
      <a style={{ fontSize: 16 }} onClick={() => history.goBack()}>
        {'< Back'}
      </a>
      <div className={styles.tabs}>
        {!isUnsupervised && (
          <div style={{ display: 'flex', flex: 'auto' }}>
            <Show name={ProjectRolesKey.DeploymentOperation}>
              <div
                className={classnames([styles.tab, styles.deployment], {
                  [styles.active]: location.pathname.indexOf('deployment') >= 0,
                })}
                onClick={() =>
                  history.replace(
                    `/deploy/project/${match.params.id}/deployment`,
                  )
                }
              >
                <img
                  className={styles.icon}
                  src={deploymentIcon}
                  alt="deployment"
                />
                <span className={styles.text}>{EN.Deployments}</span>
              </div>
            </Show>
            <Show name={ProjectRolesKey.DeploymentIncrementalModel}>
              {!bigData && (
                <div
                  className={classnames([styles.tab, styles.deployment], {
                    [styles.active]:
                      location.pathname.indexOf('increment') >= 0,
                  })}
                  onClick={() =>
                    history.replace(
                      `/deploy/project/${match.params.id}/increment`,
                    )
                  }
                >
                  <img
                    className={styles.icon}
                    src={incrementIcon}
                    alt="increment"
                  />
                  <span className={styles.text}>{EN.ModelIncremental}</span>
                </div>
              )}
            </Show>

            <div
              className={classnames([styles.tab, styles.operation], {
                [styles.active]: location.pathname.indexOf('operation') >= 0,
              })}
              onClick={() =>
                history.replace(`/deploy/project/${match.params.id}/operation`)
              }
            >
              <img
                className={styles.icon}
                src={operationIcon}
                alt="operation"
              />
              <span className={styles.text}>{EN.OperationMonitor}</span>
            </div>
            <Show name={ProjectRolesKey.DeploymentPerformance}>
              <div
                className={classnames([styles.tab, styles.performance], {
                  [styles.active]:
                    location.pathname.indexOf('performance') >= 0,
                })}
                onClick={() =>
                  history.replace(
                    `/deploy/project/${match.params.id}/performance`,
                  )
                }
              >
                <img
                  className={styles.icon}
                  src={performanceIcon}
                  alt="performance"
                />
                <span className={styles.text}>{EN.PerformanceMonitor}</span>
              </div>
            </Show>

            <div
              className={classnames([styles.tab, styles.status], {
                [styles.active]: location.pathname.indexOf('status') >= 0,
              })}
              onClick={() =>
                history.replace(`/deploy/project/${match.params.id}/status`)
              }
            >
              <img className={styles.icon} src={statusIcon} alt="status" />
              <span className={styles.text}>{EN.PerformanceStatus}</span>
            </div>
          </div>
        )}

        {isUnsupervised && (
          <div style={{ display: 'flex', flex: 'auto' }}>
            <Show name={ProjectRolesKey.DeploymentOperation}>
              <div
                className={classnames([styles.tab, styles.performance], {
                  [styles.active]:
                    location.pathname.indexOf('performance') >= 0,
                })}
                onClick={() =>
                  history.push(`/deploy/project/${match.params.id}/performance`)
                }
              >
                <img
                  className={styles.icon}
                  src={performanceIcon}
                  alt="performance"
                />
                <span className={styles.text}>{EN.Deployment}</span>
              </div>
              </Show>
              <div
                className={classnames([styles.tab, styles.status], {
                  [styles.active]: location.pathname.indexOf('status') >= 0,
                })}
                onClick={() =>
                  history.push(`/deploy/project/${match.params.id}/status`)
                }
              >
                <img className={styles.icon} src={statusIcon} alt="status" />
                <span className={styles.text}>{EN.PerformanceMonitors}</span>
              </div>

          </div>
        )}
      </div>
      <div className={styles.content}>
        <Switch>
          {!isUnsupervised && (
            <Route
              path="/deploy/project/:id/deployment"
              component={props => <Deployment {...props} />}
            />
          )}

          {!isUnsupervised && !bigData && (
            <Route
              path="/deploy/project/:id/increment"
              component={props => <Increment {...props} />}
            />
          )}

          {!isUnsupervised && (
            <Route
              path="/deploy/project/:id/operation"
              component={props => <Operation {...props} />}
            />
          )}

          {
            <Route
              path="/deploy/project/:id/performance"
              component={props => <Performance {...props} />}
            />
          }
          <Route
            path="/deploy/project/:id/status"
            component={props => <Status {...props} />}
          />
          <Route
            render={() => (
              <Redirect
                to={`/deploy/project/${match.params.id}/${
                  isUnsupervised ? 'performance' : 'deployment'
                }`}
              />
            )}
          />
        </Switch>
      </div>
    </div>
  );
}
export default observer(Detail);
