import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import EN from '../../../constant/en';
import styles from './AdvancedView.module.css';
import { toJS } from 'mobx';
import Chart from '../../Charts/Chart';
import MultiClassificationModelRow from './MultiClassificationModelRow';
import ClassificationModelRow from './ClassificationModelRow';
import RegressionModleRow from './RegressionModleRow';
import ClusteringModelRow from './ClusteringModelRow'

interface Interface {
  readonly models: any;
  readonly project: any;
  readonly sort: any;
  readonly handleSort: any;
}

const AdvancedModelTable = observer((props: Interface): ReactElement => {
  const {
    models,
    project: {
      problemType,
      targetArray,
      targetColMap,
      renameVariable,
      target,
      mapHeader,
    },
    project,
  } = props;

  const [v0, v1] = !targetArray.length
    ? Object.keys(targetColMap)
    : targetArray;
  const [no, yes] = [renameVariable[v0] || v0, renameVariable[v1] || v1];

  const dataSource = models.map(m => {
    switch (problemType) {
      case 'Clustering':
        return (
          <ClusteringModelRow
            project={project}
            key={m.id}
            model={m}
          />
        );
      case 'Classification':
        return (
          <ClassificationModelRow
            project={project}
            no={no}
            yes={yes}
            key={m.id}
            model={m}
          />
        );
      case 'MultiClassification':
        return (
          <MultiClassificationModelRow
            project={project}
            key={m.id}
            model={m}
          />
        );
      default:
        return <RegressionModleRow
          project={project}
          key={m.id}
          model={m}
        />;
    }
  });
  const RegressChart = () => {
    if (problemType === 'Regression') {
      const { pva } = toJS(models[0].graphicList)||{};
      // graphicList.pop();
      // graphicList.pop();
      if(!pva)return
      return (
        <Chart
          y_name={
            (window as any).r2_env.isEN
              ? `${EN.Groupaverage} ${mapHeader[target]}`
              : `${mapHeader[target]} ${EN.Groupaverage}`
          }
          data={[pva.holdout, pva.validate]}
          name="predicted-vs-actual-plot"
          project={project}
          report={true}
        />
      );
    }
    return <React.Fragment />;
  };

  return (
    <div className={styles.advancedModelTableDiv}>
      <RegressChart />
      <div className={styles.advancedModelTable}>{dataSource}</div>
    </div>
  );
});

export default AdvancedModelTable;
