import Model from 'stores/Model';
import Project from 'stores/Project';
import { observer } from 'mobx-react';
import React, { MouseEvent } from 'react';
import styles from './Table.module.css';
import { DownOutlined } from '@ant-design/icons';
import { Checkbox, Radio, Tooltip } from 'antd';
import EN from '../../../../constant/en';
import moment from 'moment';
import { formatNumber } from '../../../../util';
import RegressionDetailCurves from './RegressionDetailCurves';

interface Interface {
  model: Model;
  metric: string;
  project: Project;
  detail: boolean;
  handleDetail: (s: string) => void;
  report: boolean
}

const RegressionTableRow = observer((props: Interface) => {
  const { model, project, metric, detail, handleDetail, report = false } = props;
  const {
    isHoldout,
    mapHeader,
    newVariable,
    selectModel,
    recommendModel,
  } = project;
  const { score } = model;
  const newMapHeader: any = {
    ...(Array.isArray(mapHeader) ? mapHeader.reduce((prev, v, k) => Object.assign(prev, { [k]: v }), {}) : mapHeader),
    ...newVariable.reduce((prev, v) => Object.assign(prev, { [v]: v }), {}),
  };

  const modelScore = isHoldout ? score.holdoutScore : score.validateScore;
  const isRecommend = recommendModel.id === model.id;
  const handleResult = id => () => {
    handleDetail(id);
  };

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (selectModel.id === model.id) return;
    return project.updateProject({ selectId: model.id });
  };

  const isChecked = project.checkItems.includes(model.id)

  const onCheck = (model,checked) =>{
    return project.setCheckedModel(model.id,checked);
  };

  return (
    <div className={styles.rowBody} style={{ position: (report ? 'unset' : 'relative') }}>
      <Tooltip
        placement="left"
        title={isRecommend ? EN.Recommended : EN.Selected}
        visible={selectModel.id === model.id || isRecommend}
        overlayClassName={styles.recommendLabel}
        autoAdjustOverflow={false}
        arrowPointAtCenter={true}
        getPopupContainer={el => el.parentElement}
      >
        <div className={styles.row} onClick={!report && handleResult(model.id)}>
          {/*{*/}
          {/*  !report && <div id="radio" className={styles.a_radio}>*/}
          {/*    <Radio checked={selectModel.id === model.id} onClick={handleClick}/>*/}
          {/*  </div>*/}
          {/*}*/}
          {
            !report && (project.incrementMode ? <Checkbox
                checked={isChecked}
                disabled={model.increment}
                onClick={e=>e.stopPropagation()}
                onChange={({target})=>{
                  return onCheck(model,target.checked)
                }}/> : <div id="radio" className={styles.a_radio}>
              <Radio checked={selectModel.id === model.id} onClick={handleClick}/>
            </div>)
          }
          <div className={`${styles.cell} ${styles.name}`}>
            <span className={styles.text} title={model.id}>
              {model.id}
            </span>
            {!report && <span className={styles.icon}>
              <DownOutlined style={detail ? { transform: 'rotateZ(180deg)' } : {}} />
            </span>}
          </div>
          <div className={styles.cell}>
            <span
              className={styles.text}
              title={moment(String(model.created_at)).format('YYYY/MM/DD HH:mm')}
            >
              {moment(String(model.created_at)).format('YYYY/MM/DD HH:mm')}
            </span>
          </div>
          <div className={styles.cell}>
            <span
              className={styles.text}
              title={formatNumber((modelScore.nrmse || 'null').toString())}
            >
              {formatNumber((modelScore.nrmse || 'null').toString())}
            </span>
          </div>
          <div className={styles.cell}>
            <span
              className={styles.text}
              title={formatNumber((modelScore.rmse || 'null').toString())}
            >
              {formatNumber((modelScore.rmse || 'null').toString())}
            </span>
          </div>
          <div className={styles.cell}>
            <span
              className={styles.text}
              title={formatNumber((modelScore.msle || 'null').toString())}
            >
              {formatNumber((modelScore.msle || 'null').toString())}
            </span>
          </div>
          <div className={styles.cell}>
            <span
              className={styles.text}
              title={formatNumber((modelScore.rmsle || 'null').toString())}
            >
              {formatNumber((modelScore.rmsle || 'null').toString())}
            </span>
          </div>
          <div className={styles.cell}>
            <span
              className={styles.text}
              title={formatNumber((modelScore.mse || 'null').toString())}
            >
              {formatNumber((modelScore.mse || 'null').toString())}
            </span>
          </div>
          <div className={styles.cell}>
            <span
              className={styles.text}
              title={formatNumber((modelScore.mae || 'null').toString())}
            >
              {formatNumber((modelScore.mae || 'null').toString())}
            </span>
          </div>
          <div className={styles.cell}>
            <span
              className={styles.text}
              title={formatNumber((modelScore.r2 || 'null').toString())}
            >
              {formatNumber((modelScore.r2 || 'null').toString())}
            </span>
          </div>
          <div className={styles.cell}>
            <span
              className={styles.text}
              title={formatNumber((modelScore.adjustR2 || 'null').toString())}
            >
              {formatNumber((modelScore.adjustR2 || 'null').toString())}
            </span>
          </div>
          <div className={styles.scoreCell}>
            <div className={styles.cell}>
              <span
                className={styles.text}
                title={formatNumber((model.score.validateScore[metric] || 'null').toString())}
              >
                {formatNumber((model.score.validateScore[metric] || 'null').toString())}
              </span>
            </div>
            <div className={styles.cell}>
              <span
                className={styles.text}
                title={formatNumber((model.score.holdoutScore[metric] || 'null').toString())}
              >
                {formatNumber((model.score.holdoutScore[metric] || 'null').toString())}
              </span>
            </div>
          </div>
        </div>
      </Tooltip>
      {detail && !report && (
        <RegressionDetailCurves
          project={project}
          model={model}
          mapHeader={newMapHeader}
        />
      )}
    </div>
  );
});
export default RegressionTableRow;
