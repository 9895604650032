import React, { ReactElement, useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { ProjectStore } from 'stores/ProjectStore';

interface Interface {
  name: string;
  children: any;
  none?:ReactElement
}

function Show(props: Interface): ReactElement {
  const { name, children,none=null } = props;
  // @ts-ignore
  const {
    projectStore: {
      project,
    },
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext);
  if (!project) {
    return none;
  }
  const { roles = {} } = project; 
  if (roles[name] !== false && roles[name] !== null) {
    return children || none;
  }
  return none;
}

export default Show;
