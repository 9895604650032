import { observer, MobXProviderContext } from 'mobx-react';
import React, { ReactElement, useState, useContext } from 'react';
import styles from '../styles.module.css';
import { Radio, Tooltip, Popconfirm, Button } from 'antd';
import EN from '../../../../../constant/en';
import { formatNumber } from '../../../../../util';
import moment from 'moment';
import Variable from '../../Variable.svg';
import Process from '../../Process.svg';
import VariableImpact from '../../VariableImpact';
import classnames from 'classnames';
import Model from 'stores/Model';
import Project from 'stores/Project';
import { ProjectStore } from 'stores/ProjectStore';
import MPF from '../../MPF';
import ModelInterpretation from '../ModelInterpretation';
import { ProjectRolesKey } from '@app/constant/types';
import { Show } from '@app/components/Common';

interface Interface {
  model: Model;
  onSelect: (s: string) => void;
  isRecommend: boolean;
  isSelect: boolean;
  mapHeader: any;
  project: Project;
}

function ModelDetail(props: Interface):ReactElement {
  // @ts-ignore
  const {
    projectStore: { project: { deleteModelByModelId, exportModelReport } },
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext);
  const {
    model: {
      linearData = false,
      treeData = false,
      modelName,
      created_at,
      executeSpeed,
      id,
      score: {
        validateScore: { rmse, r2 },
      },
    },
    model,
    onSelect,
    isRecommend,
    isSelect,
    mapHeader,
    project,
  } = props;
  const [type, upType] = useState('');
  const [visible, upVisible] = useState(false);

  function toggleImpact(_type) {
    if (!visible) {
      //本来是关着的
      upType(_type);
      upVisible(true);
      return;
    }
    if (type === _type) {
      upVisible(false);
    } else {
      upType(_type);
    }
  }

  const _check = linearData || treeData;

  // const isChecked = project.checkItems.includes(model.id)

  // const onCheck = (model,checked) =>{
  //   return project.setCheckedModel(model.id,checked);
  // };


  return (
    <div className={styles.rowBox}>
      <Tooltip
        placement="left"
        title={isRecommend ? EN.Recommended : EN.Selected}
        visible={isSelect || isRecommend}
        overlayClassName={styles.recommendLabel}
        autoAdjustOverflow={false}
        arrowPointAtCenter={true}
        getPopupContainer={el => el.parentElement}
      >
        <div className={styles.rowData}>
          {/*<div id="radio" className={styles.a_radio} style={{top:12}}>*/}
          {/*  <Radio checked={isSelect} onClick={onSelect.bind(null, model)}/>*/}
          {/*</div>*/}
          {/*{*/}
          {/*  project.incrementMode ? <Checkbox*/}
          {/*      checked={isChecked}*/}
          {/*      disabled={model.increment}*/}
          {/*      onChange={({target})=>{*/}
          {/*        return onCheck(model,target.checked)*/}
          {/*      }}/> : <div id="radio" className={styles.a_radio} style={{top:12}}>*/}
          {/*    <Radio checked={isSelect} onChange={onSelect.bind(this,model)}/>*/}
          {/*  </div>*/}
          {/*}*/}

          <div id="radio" className={styles.a_radio} style={{top:12}}>
            <Radio checked={isSelect} onChange={onSelect.bind(this,model)}/>
          </div>
          <div className={classnames(styles.cell, styles.name)}>
            <Tooltip title={modelName}>
              <span style={{
                color:model.supportIncrement ? '#8242DD':"#D0A409"
              }}>{modelName}</span></Tooltip>
          </div>
          <div className={styles.cell}>
            <span>{formatNumber((rmse || 'null').toString())}</span>
          </div>
          <div className={styles.cell}>
            <span>{formatNumber((r2 || "null").toString())}</span>
          </div>
          <div className={styles.cell}>
            <span>{formatNumber((executeSpeed || '').toString()) + EN.Rowss}</span>
          </div>
          <div className={styles.cell}>
            <span>
              {created_at
                ? moment(String(created_at)).format('YYYY/MM/DD HH:mm')
                : ''}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <img src={Variable} alt="" />
            <span onClick={toggleImpact.bind(this, 'impact')}>
              {EN.Compute}
            </span>
          </div>
          <div
            className={classnames(
              styles.cell,
              styles.compute,
              _check ? '' : styles.disable,
            )}
          >
            <i
              className={classnames(styles.check, _check ? '' : styles.uncheck)}
            />
            <span onClick={() => _check && toggleImpact('check')}>
              {EN.check}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <img src={Process} alt="" />
            <span onClick={toggleImpact.bind(this, 'process')}>
              {EN.Compute}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <span onClick={exportModelReport.bind(this, model.id)}>{EN.Export}</span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <Show name={ProjectRolesKey.ModelEdit}>
              <Popconfirm
                title={`${EN.ConfirmDeletion}该模型`}
                onConfirm={deleteModelByModelId.bind(this, model.id)}
                okText={EN.confirm}
                cancelText={EN.Cancel}
              >
                <span >{EN.Delete}</span>
              </Popconfirm>
            </Show>
          </div>
          
        </div>
      </Tooltip>
      {visible && type === 'impact' && (
        <VariableImpact model={model} mapHeader={mapHeader} />
      )}
      {visible && type === 'process' && (
        <MPF project={project} model={model} modelId={id} />
      )}
      {visible && type === 'check' && (
        <ModelInterpretation
          treeData={treeData}
          linearData={linearData}
          modelName={modelName}
        />
      )}
    </div>
  );
}
export default observer(ModelDetail);
