import { MobXProviderContext, observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styles from '../styles.module.css';
import EN from '../../../../../constant/en';
import { LoadingOutlined, MinusOutlined, UpOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import ModelDetail from './ModelDetail';
import classnames from 'classnames';
import { Hint, Show, TrainModel } from 'components/Common';
import Model from 'stores/Model';
import Project from 'stores/Project';
import moment from 'moment';
import { ProjectRolesKey } from '@app/constant/types';

interface Interface {
  onSelect: (s: string) => void;
  train2Finished: boolean;
  current: Model;
  trainModel: unknown;
  isAbort: boolean;
  recommendId: string;
  sort: {
    key: string;
    value: number;
  };
  handleSort: (s: string) => void;
  mapHeader: any;
  project: Project;
  stopIds: string[];
  abortTrain: (s: string) => void;
  models: Model[];
  bigData: boolean,
  allStopIds:any[]
}
export default observer(ModelTable);
function ModelTable(props: Interface) {
  const {
    abortTrain,
    onSelect,
    train2Finished,
    current,
    trainModel,
    isAbort,
    recommendId,
    sort,
    handleSort,
    mapHeader,
    project,
    stopIds,
    models,
    bigData,
    sort: { key, value },
    allStopIds
  } = props;
  const _abortTrain = stopId => {
    abortTrain(stopId);
  };

  const sortModels = useMemo(() => {
    const fn = (a: Model, b: Model) => {
      switch (key) {
        case 'kappa':
          return (
            (a.score.validateScore.Kappa - b.score.validateScore.Kappa) * value
          );
        case 'auc':
          return (
            (a.chartData.roc_auc.macro - b.chartData.roc_auc.macro) * value
          );
        case 'speed':
          return (a.executeSpeed - b.executeSpeed) * value;
        case 'time':
          return ((moment(String(a.created_at)).valueOf() || 0) - (moment(String(b.created_at)).valueOf()  || 0)) * value;
        case 'name':
        default:
          return a.modelName > b.modelName ? value : -value;
      }
    };
    return models.sort(fn);
  }, [key, value, models]);

  return (
    <div className={styles.table} style={{paddingRight:40}}>
      <div className={styles.rowHeader}>
        <div className={styles.rowData}>
          <div
            className={classnames(styles.cell, styles.name, styles.cellHeader)}
            onClick={handleSort.bind(null, 'name')}
          >
            <span>
              {EN.ModelName}
              {sort.key !== 'name' ? (
                <MinusOutlined />
              ) : (
                <UpOutlined
                  style={
                    sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                  } />
              )}
            </span>
          </div>
          <div
            className={classnames(styles.cell, styles.cellHeader)}
            onClick={handleSort.bind(null, 'kappa')}
          >
            <span>
              <Hint content={EN.KappaHint} />
              <i style={{ width: 4 }} />
              Kappa
              {sort.key !== 'kappa' ? (
                <MinusOutlined />
              ) : (
                <UpOutlined
                  style={
                    sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                  } />
              )}
            </span>
          </div>
          <div
            className={classnames(styles.cell, styles.cellHeader)}
            onClick={handleSort.bind(null, 'auc')}
          >
            <span>
              <Hint content={EN.MacroAUCHint} />
              <i style={{ width: 4 }} />
              Macro-AUC
              {sort.key !== 'auc' ? (
                <MinusOutlined />
              ) : (
                <UpOutlined
                  style={
                    sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                  } />
              )}
            </span>
          </div>
          <div
            className={classnames(styles.cell, styles.cellHeader)}
            onClick={handleSort.bind(null, 'speed')}
          >
            <span>
              {EN.ExecutionSpeed}
              {sort.key !== 'speed' ? (
                <MinusOutlined />
              ) : (
                <UpOutlined
                  style={
                    sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                  } />
              )}
            </span>
          </div>
          <div
            className={classnames(styles.cell, styles.cellHeader)}
            onClick={handleSort.bind(null, 'time')}
          >
            <span>
              {EN.Time}
              {sort.key !== 'time' ? (
                <MinusOutlined />
              ) : (
                <UpOutlined
                  style={
                    sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                  } />
              )}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.cellHeader)}>
            <span>{EN.VariableImpact}</span>
          </div>
          <div className={classnames(styles.cell, styles.cellHeader)}>
            <span>{EN.ModelInterpretation}</span>
          </div>
          <div className={classnames(styles.cell, styles.cellHeader)}>
            <span>{EN.ModelProcessFlow}</span>
          </div>
          <div className={classnames(styles.cell, styles.cellHeader)}>
            <span>{EN.Report}</span>
          </div>
          <div className={classnames(styles.cell, styles.cellHeader)}>
              <span>{EN.Delete}</span>
            </div>
        </div>
      </div>
      <div className={styles.data}>
        {sortModels.map((model) => {
          return (
            <ModelDetail
              key={model.id}
              model={model}
              isSelect={model.id === current.id}
              onSelect={onSelect}
              isRecommend={model.id === recommendId}
              mapHeader={mapHeader}
              project={project}
            />
          );
        })}
        {!train2Finished && (
          <div className={styles.rowData}>
            <div className={styles.trainingModel}>
              <Tooltip title={EN.ModelProcessing}>{EN.ModelProcessing}</Tooltip>
            </div>
            <Show name={ProjectRolesKey.ModelingAbort}>
              <div
                className={`${styles.abortButton} ${styles.abortButtonAll}`}
                onClick={!isAbort ? () => project.abortTrainByEtl() : null}
              >
                {isAbort ? (
                  <LoadingOutlined />
                ) : (
                  <span>{EN.AbortTrainingAll}</span>
                )}
              </div>
            </Show>
          </div>
        )}
        {!train2Finished &&
          allStopIds.map(stopId => {
            if (stopId.value.data) {
              return (
                <TrainModel
                  showAbortButton={true}
                  trainingModel={stopId.value.data}
                  isAbort={isAbort}
                  abortTrain={_abortTrain}
                  key={stopId.key}
                />
              );
            }
          })}
      </div>
    </div>
  );
}
