import React, { ChangeEvent, useEffect, useMemo } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { Hint, NumberInput, Range } from 'components/Common';
import { Select, Tooltip, Checkbox, Modal, InputNumber, Popover, Radio, Space } from 'antd';
import Algorithms from './algorithms.json';
import Feature from './feature.json';
import EN from '../../../../constant/en';
import Project, { Settings} from 'stores/Project';
import CustomRange from './CustomRange'
import { Domain } from '@app/constant/types';

const lang = !!(window as any).r2_env.isEN ? 'en' : 'zh'
const { Option } = Select;

interface Interface {
  setSettingName: (s: string) => void,
  setSelectedSetting: (i: string) => void,
  project: Project,
  setSetting: (a: unknown) => void,
  hidden: boolean,
  setting: Settings,
  selectedSetting: string
}
    // XGBoost disabled in algorithms.json
    // {
    //   "value": "xgradient_boosting",
    //   "label": "XGBoost"
    // }
function AdvancedView(props:Interface){
  const {
    project,
    setting,
    setSetting,
    setSettingName,
    setSelectedSetting,
    selectedSetting,
    hidden,
  } = props;
  const {
    targetCounts,
    problemType,
    validationRate,
    holdoutRate,
    runWith,
    features,
    crossCount,
    version,
    defaultAlgorithms,
    defaultVersion,
    defaultSparkAlgorithms,
    defaultIncrementalAlgorithms,
    sparkAlgorithms,
    bigData,
    algorithms,
    // incrementalAlgorithms,
    settings,
    randSeed,
    measurement,
    resampling,
    dataRange,
    customField,
    customRange,
    dataHeader,
    colType,
    dataViews,
    speedVSaccuracy,
    ensembleSize,
    totalLines,
    surrogate,
    numIter,
  } = project;
  useEffect(()=>{
    initSelectedSetting();
  },[selectedSetting]);

  const handleName = e => {
    setSettingName(e.target.value.replace(/\./g,'').replace(/\:/g,''))
  }

  const handleSize = value => {
    project.ensembleSize = value;
    setSetting({ ensembleSize: value })
  }

  const handleSlider = (value: [number, number]) => {
    const [min, max] = value
    if (max === min) return
    project.holdoutRate = 100 - max;
    project.validationRate = max - min;
    setSetting({ holdoutRate: 100 - max, validationRate: max - min })
  }

  const handleDrag = (value: number) => {
    project.holdoutRate = 100 - value;
    setSetting({ holdoutRate: 100 - value })
  }

  const changeCrossCount = value => {
    let crossCountMax = problemType !== 'Regression' ? Math.min(...Object.values(targetCounts)) : Infinity
    if(bigData){
      crossCountMax = Math.max(crossCountMax,6)
    }
    if (value >= crossCountMax) {
      // message.destroy();
      // return message.error(`${EN.Oneoftheclasseshasnumber} ${crossCountMax} ${EN.Pleaseselectalowerfoldcv}`)
      return Modal.error({
        title: '提示!',
        content:`${EN.Oneoftheclasseshasnumber} ${crossCountMax} ${EN.Pleaseselectalowerfoldcv}`
      });
    }
    project.crossCount = value;
    setSetting({ crossCount: value })
  }

  const handleRandSeed = value => {
    project.randSeed = value;
    setSetting({ randSeed: value })
  }

  const handleMeasurement = value => {
    project.measurement = value
    setSetting({ measurement: value })
  }

  const handleHyperParamChange = (value, key) => {
    if (value === 'BayesianOptimization') {
      if (project.surrogate === "Grid" || project.surrogate === "Random") {
        project.surrogate = 'GaussianProcess'
        setSetting({ surrogate: 'GaussianProcess' })
        return;
      }
    }
    project.surrogate = value
    setSetting({ surrogate: value })
  }

  const handleBayesianOptChange = (value) => {
    project.surrogate = value.target.value
    setSetting({ surrogate: value.target.value })
  }

  const handleHyperParamNumIterChange = value => {
    project.numIter = value
    setSetting({ numIter: value })
  }

  const handleRunWith = v => {
    if (v === 'holdout') {
      if (validationRate + holdoutRate > 99) {
        project.holdoutRate = Math.min((99 - validationRate), 10);
        setSetting({ holdoutRate: Math.min((99 - validationRate), 10) })
      }
    }
    project.runWith = v;
    setSetting({ runWith: v })
  }

  const handleResampling = v => {
    project.resampling = v;
    setSetting({ resampling: v })
  }

  const crossPercent = () => {
    const percent = 100 - holdoutRate;
    const arr = [];
    for (let i = 0; i < crossCount; i++) {
      arr.push(<div className={styles.advancedPercentCross} style={{ width: (percent / crossCount) + '%' }} key={i}/>)
    }
    return arr
  }

  const handleFeaturesAll = value => {
    if (!value) {
      project.features = []
      setSetting({ features: [] })
      return
    }
    project.features = ['Extra Trees', 'Random Trees', 'Fast ICA', 'Kernel PCA', 'PCA', 'Polynomial', 'Feature Agglomeration', 'Kitchen Sinks', 'Linear SVM', 'Nystroem Sampler', 'Select Percentile', 'Select Rates']
    setSetting({ features: ['Extra Trees', 'Random Trees', 'Fast ICA', 'Kernel PCA', 'PCA', 'Polynomial', 'Feature Agglomeration', 'Kitchen Sinks', 'Linear SVM', 'Nystroem Sampler', 'Select Percentile', 'Select Rates'] })
  };

  //全选
  const handleSelectAll = value => {
    
    if (!value) {
      // const version = ['a', 'b']
      project.algorithms = []
      project.version = bigData ? [] : version.filter(v => v === 'a' || v === 'b')
      setSetting({ algorithms: [], version });
      project.sparkAlgorithms = [];
      project.incrementalAlgorithms = [];
      return
    }
    project.algorithms = defaultAlgorithms
    // project.version = defaultVersion
    setSetting({ version: defaultVersion, algorithms: defaultAlgorithms })

    project.sparkAlgorithms = !(window as any).r2_env.useSpark ? [] : defaultSparkAlgorithms;
    project.incrementalAlgorithms = defaultIncrementalAlgorithms
    project.version = [...new Set([...defaultIncrementalAlgorithms,...defaultVersion])]
  };

  const handle = (type,key,e)=>{
    if (bigData) return
    if (e === true || e?.target?.checked) {
      if (project[type].includes(key)) return
      project[type] = [...new Set([...project[type], key])]
    } else {
      if (!project[type].includes(key)) return
      project[type] = [...new Set(project[type].filter(v => v !== key))]
    }
    setSetting({ [type]: [...project[type]] })
  }
  const singleHandle = (type,key,e)=>{
    if (bigData) return
    // 选中状态
    if (e === true || e?.target?.checked) {
      // 当前项目所选算法只有一个时,不进行操作
      if (project[type][0] && project[type][0] === key) return;
      if (type === 'version') {
        project['version'] = [key];
        project['algorithms'] = [];
        setSetting({ ['version']: [key], ['algorithms']: [] })
      } else if (type === 'algorithms') {
        project['algorithms'] = [key];
        project['version'] = [];
        setSetting({ ['algorithms']: [key], ['version']: [] })
      }
    } else {
      // if (project[type][0] && project[type][0] !== key) return
      // project[type] = [];
      // setSetting({ [type]:[] })
    }
  }
  // const handleCheck = (key, e) => {
  //   if (bigData) return
  //   if (e.target.checked) {
  //     if (algorithms.includes(key)) return
  //     project.algorithms = [...algorithms, key]
  //     setSetting({ algorithms: [...algorithms, key] })
  //   } else {
  //     if (!algorithms.includes(key)) return
  //     project.algorithms = algorithms.filter(v => v !== key)
  //     setSetting({ algorithms: algorithms.filter(v => v !== key) })
  //   }
  // }

  // const handleFeatures = (key, e) => {
  //   if (e.target.checked) {
  //     if (features.includes(key)) return
  //     project.features = [...features, key]
  //     setSetting({ features: [...features, key] })
  //   } else {
  //     if (!features.includes(key)) return
  //     project.features = features.filter(v => v !== key)
  //     setSetting({ features: features.filter(v => v !== key) })
  //   }
  // }

  const handleSolution = (num, e) => {
    if (bigData) return
    if (e.target.checked) {
      if (version.includes(num)) return
      project.version = [...version, num]
      setSetting({ version: [...version, num] })
    } else {
      if (!version.includes(num)) return
      project.version = version.filter(n => n !== num)
      setSetting({ version: version.filter(n => n !== num) })
    }
  }

  const handleSpeed = value => {
    project.speedVSaccuracy = value
    setSetting({ speedVSaccuracy: value })
  }

  const changeSpeed = (isSpeed, value) => {
    if (!isSpeed) value = 10 - value
    if (value < 1 || value > 9) return
    project.speedVSaccuracy = value
    setSetting({ speedVSaccuracy: value })
  }



  const reset = action(() => {
    const min = problemType !== 'Regression' ? Math.min(...Object.values(targetCounts)) : Infinity
    project.holdoutRate = 20
    project.validationRate = 20
    project.crossCount = Math.min((min - 1), 5)
    setSetting({ holdoutRate: 20, validationRate: 20, crossCount: Math.min((min - 1), 5) })
  })

  const resetSpeed = action(() => {
    project.speedVSaccuracy = 5
    setSetting({ speedVSaccuracy: 5 })
  })

  const changeSetting = action((e: ChangeEvent<HTMLSelectElement>) => {
    const selected = project.settings.find(s => s.id === e.target.value)
    let settingData = selected ? selectSetting(selected,project) : resetSetting(project);
    setSelectedSetting(selected ? selected.id : 'default');
    setSetting(settingData);
  })

  const selectSetting = action((selected, project) => {
    let setting = {};
    if (!bigData) {
      Object.entries(selected.setting).forEach(([key, value]) => {
        project[key] = value
      });
      setting = selected.setting;
    } else {
      project.sparkAlgorithms = selected.setting.sparkAlgorithms;
      setting = { sparkAlgorithms: selected.setting.sparkAlgorithms };
    }
    return setting;
  })

  const resetSetting = action((project) => {
    let setting = {};
    if (!bigData) {
      setting = project.newSetting();
      setting['version'] = version;
      setting['algorithms'] = algorithms;
      Object.entries(setting).forEach(([key, value]) => {
        project[key] = value
      })
    } else {
      project.sparkAlgorithms = defaultSparkAlgorithms;
      setting = { sparkAlgorithms: defaultSparkAlgorithms };
    }
    return Object.assign(setting, {seleted: true});
  })

  const initSelectedSetting = ()=> {
    const replaceSettings = project.settings.filter(x => x.name.indexOf('auto') < 0)
    const selected = replaceSettings.find(s => s.id === selectedSetting)
    selected ? selectSetting(selected,project) : resetSetting(project);
  }

  const handleSpark = (key, e) => {
    // if (!(window as any).r2_env.useSpark) return
    const isCheck = e.target.checked
    const { sparkAlgorithms, bigData } = project
    if (!bigData) return
    if (isCheck) {
      if (sparkAlgorithms.includes(key)) return
      project.sparkAlgorithms = [...sparkAlgorithms, key]
      setSetting({ sparkAlgorithms: [...sparkAlgorithms, key] })
    } else {
      if (!sparkAlgorithms.includes(key)) return
      project.sparkAlgorithms = sparkAlgorithms.filter(v => v !== key)
      setSetting({ sparkAlgorithms: sparkAlgorithms.filter(v => v !== key) })
    }
  }

    const measurementList = problemType === "Classification" ?
      // [{ value: "acc", label: 'Accuracy' }, { value: "auc", label: 'AUC' }, { value: "f1", label: 'F1' }, { value: "precision", label: 'Precision' }, { value: "recall", label: 'Recall' }] :
      [{ value: "auc", label: 'AUC' }, { value: "log_loss", label: 'LogLoss' }] : problemType === 'Regression' ?
        [{ value: "r2", label: <div>R<sup>2</sup></div> }, { value: "mse", label: 'MSE' }, { value: "rmse", label: 'RMSE' }] :
        [{ value: "macro_auc", label: 'Macro AUC' }, { value: "macro_f1", label: 'Macro F1' }, { value: "micro_f1", label: 'Micro F1' }, { value: "macro_recall", label: 'Macro Recall' }, { value: "micro_recall", label: 'Micro Recall' }, { value: "macro_precision", label: 'Macro Precision' }, { value: "micro_precision", label: 'Micro Precision' }]

    const customFieldList = dataHeader.filter(v => colType[v] === "Numerical")
    // const algorithmList = problemType === "Classification" ? ClassificationAlgorithms : RegressionAlgorithms
    let featureList = []
    if (problemType !== "Regression") {
      if (algorithms.some(al => [
        'adaboost',
        'decision_tree',
        'extra_trees',
        'gradient_boosting',
        'k_nearest_neighbors',
        'liblinear_svc',
        'random_forest',
        'gaussian_nb',
        'xgradient_boosting', // xgradient_boosting test
      ].includes(al))) featureList = featureList.concat(['Extra Trees', 'Random Trees', 'fast ICA', 'PCA', 'Polynomial', 'feature agglomeration', 'linear SVM', 'Select Percentile', 'Select Rates'])
      if (algorithms.includes('multinomial_nb')) featureList = featureList.concat(['Extra Trees', 'Random Trees', 'Polynomial', 'Feature Agglomeration', 'linear SVM', 'Nystroem Sampler', 'Select Percentile', 'Select Rates'])
      if (algorithms.some(al => ["bernoulli_nb", "lda", "libsvm_svc", "passive_aggressive", "qda", "sgd"].includes(al))) featureList = featureList.concat(["Fast ICA", "Kernel PCA", "Kitchen Sinks", "Linear SVM"])
    } else {
      if (algorithms.some(al => [
        'adaboost',
        'decision_tree',
        'extra_trees',
        'gradient_boosting',
        'k_nearest_neighbors',
        'random_forest',
        'gaussian_process',
        'xgradient_boosting', // xgradient_boosting test
      ].includes(al))) featureList = featureList.concat(['Extra Trees', 'Random Trees', 'fast ICA', 'PCA', 'Polynomial', 'Feature Agglomeration', 'linear SVM', 'Nystroem Sampler', 'Select Percentile', 'Select Rates'])
      if (algorithms.some(al => ["ard_regression", "liblinear_svr", "libsvm_svr", "ridge_regression", "sgd"].includes(al))) featureList = featureList.concat(["Fast ICA", "Kernel PCA", "Kitchen Sinks", "Linear SVM"])
    }

    const Incremental= Algorithms.Incremental.filter(itm=>!(problemType === 'Regression'&&['snm','t'].includes(itm.value)));

    // const incrementalChecked = version.filter(itm=>Incremental.map(it=>it.value).includes(itm)).length === Incremental.length&&version.includes('a');
    const incrementalChecked = useMemo(()=>{
      if(!['Regression','Classification'].includes(problemType)){
        return true;
      }
      return version.filter(itm=>Incremental.map(it=>it.value).includes(itm)).length === Incremental.length&&version.includes('a');
    },[version])
    // console.log(incrementalAlgorithms.length,Incremental,version.filter(itm=>Incremental.map(it=>it.value).includes(itm)))
    const isDefaultSparkAlgorithms = (()=>{
      let defaultdata = true;
      if (sparkAlgorithms && !!sparkAlgorithms.length)
        sparkAlgorithms.forEach(x=>{
          if (!defaultSparkAlgorithms.includes(x)) return defaultdata = false;
        })
      return defaultdata;
    })();

  const canIncrement = ['Regression','Classification'].includes(problemType)
  return <div className={styles.advanced}>
      <div className={styles.advancedRow}>
        <div className={styles.advancedLeft}>
          <div className={styles.advancedBlock}>
            <div className={classnames(styles.advancedTitle, styles.limit)}>
              <span>{EN.SelectFromPreviousSettings}:</span>
            </div>
            <div className={styles.advancedOption}>
              <select value={selectedSetting} onChange={changeSetting}>
                <option value={'default'}>{EN.DefaultSetting}</option>
                {settings && settings.filter(x => x.name.indexOf('auto') < 0 ).map(setting => <option key={setting.id} value={setting.id}>{setting.name}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className={styles.advancedRight}>
          <div className={styles.advancedBlock}>
            <div className={classnames(styles.advancedTitle, styles.limit)}>
              <span>{EN.NameYourModelSettings}:</span>
            </div>
            <div className={styles.advancedOption}>
              <input type="text" value={setting.name} onChange={handleName} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.advancedRow}>
        <div className={styles.advancedLeft} style={{ flex: '2' }}>
          <div className={styles.advancedBlock}>
            <div className={classnames(styles.advancedTitle, styles.limit)}>
              <span>{EN.SelectAlgorithm}:</span>
            </div>
            <div className={styles.advancedOption}>
              <Checkbox
                  onChange={({target})=>{
                    if(target.checked){
                      handleSelectAll(true)
                      // const list =['a','snm','q','r','t'];
                      // list.map(res =>  handle('version',res,true))
                    }else{
                      handleSelectAll(false)

                      const list =['a','snm','q','r','t','b'];
                      list.map(res =>  handle('version',res,false))
                    }
                  }}
                  // checked={algorithms.length === defaultAlgorithms.length&&incrementalChecked&&(!bigData&&version.includes('b')||bigData)}
                  checked={(bigData&&defaultSparkAlgorithms.length === sparkAlgorithms.length&&isDefaultSparkAlgorithms) || (!bigData&&algorithms.length === defaultAlgorithms.length&&incrementalChecked&&version.includes('b'))}
              >
                {EN.SelectAllDefault}
              </Checkbox>
            </div>
          </div>
          <div className={styles.advancedBlock}>
            <div className={styles.advancedAlgorithmList} style={{border:"1px solid #7d7d7d"}}>
              {!bigData&&<>
                <ul className={styles.demo}>
                  <li><i/>{EN.IncrementalAlgorithm}</li>
                  <li><i/>{EN.NonIncrementalAlgorithm}</li>
                </ul>
                <div className={styles.checks}>
                  {!canIncrement&&<Checkbox
                      style={{
                        margin: 0,
                        color: '#8242DD'
                      }}
                      checked={version.includes('a') }
                      disabled={totalLines > 5000000}
                      onChange={handle.bind(null, 'version', 'a')}
                  >HG-solution-a</Checkbox>}
                  <Checkbox
                      style={{
                        margin: 0,
                        color: "#D0A409"
                      }}
                      checked={version.includes('b')}
                      disabled={totalLines > 5000000}
                      onChange={handle.bind(null, 'version','b')}
                  >HG-solution-b</Checkbox>
                  {
                    Algorithms[problemType].map((v,k)=>{
                      console.log(v);
                      console.log(k);
                      
                      return <Checkbox
                          key={k}
                          style={{
                            margin: 0,
                            color: "#D0A409"
                          }}
                          checked={algorithms.includes(v.value)}
                          disabled={totalLines > 1000000}
                          onChange={handle.bind(null,'algorithms', v.value)}

                      >{v.label}</Checkbox>
                    })
                  }
                  { problemType !== 'MultiClassification' &&  <Checkbox
                      style={{
                        margin: 0,
                        color: "#D0A409"
                      }}
                      checked={version.includes('d')}
                      disabled={!(window as any).r2_env.supportDnn || totalLines > 5000000}
                      onChange={handle.bind(null, 'version','d')}
                  >DNN</Checkbox>}

                  {( problemType === 'Classification') && <Checkbox
                      style={{
                        margin: 0,
                        color: "#D0A409"
                      }}
                      checked={version.includes('e')}
                      disabled={totalLines > 1000000}
                      onChange={handle.bind(null, 'version','e')}
                  >Logistic</Checkbox>}
                </div>
                {/* <div className={styles.checks}
                     style={{
                       borderBottom:"1px solid #e2e2e2",
                       lineHeight:3,
                       display:(canIncrement?'':'none')
                     }}>
                  <Checkbox
                      onChange={({target})=>{
                        if(target.checked){
                          project.incrementalAlgorithms = defaultIncrementalAlgorithms;
                          const list =['a','snm','q','r','t'];
                          list.map(res =>  singleHandle('version',res,true))
                        }else{
                          // algorithms
                          project.incrementalAlgorithms = [];
                          const list =['a','snm','q','r','t'];
                          list.map(res =>  singleHandle('version',res,false))
                        }
                      }}
                      checked={incrementalChecked}
                      
                  >
                    Incremental
                  </Checkbox>
                </div> */}
                <div className={styles.checks} style={{display:(canIncrement?'':'none')}}>
                  <Checkbox
                      checked={version.includes('a')}
                      style={{
                        margin: 0,
                        color: '#8242DD'
                      }}
                      disabled={totalLines > 5000000}
                      onChange={handle.bind(null, 'version','a')}
                  >HG-solution-a</Checkbox>
                  {
                    Incremental.map((v,k)=>{
                      return <Checkbox
                          key={k}
                          style={{
                            margin: 0,
                            color: '#8242DD'
                          }}
                          checked={version.includes(v.value)}
                          disabled={totalLines > 1000000}
                          onChange={ handle.bind(null,'version', v.value)}
                      >{v.label}</Checkbox>
                    })
                  }
                </div>
              </>}
              {bigData && Algorithms['Spark'][problemType].map((v, k) => {
                return <div className={styles.advancedAlgorithm} key={k}>
                  <input id={"spark" + k}
                         type='checkbox'
                         checked={sparkAlgorithms.includes(v.value)}
                         disabled={!bigData}
                         onChange={handleSpark.bind(null, v.value)} />
                  <label htmlFor={"spark" + k}>{v.label}</label>
                  {v.content&&<Hint
                      themeStyle={{ fontSize: '1rem' }}
                      content={v.content[lang]}
                  />}
                </div>
              })}
            </div>
          </div>
        </div>
        {!bigData&&<div className={styles.advancedRight} style={{ flex: 1 }}>
          <div className={styles.advancedBlock}>
            <div className={classnames(styles.advancedTitle, styles.limit)}>
              <span>{EN.SelectFeature}:</span>
            </div>

            <Checkbox
                onChange={({ target }) => {
                  if (target.checked) {
                    handleFeaturesAll(true);
                  } else {
                    handleFeaturesAll(false);
                  }
                }}
                style={{
                  margin: 0
                }}
                disabled={!Feature.filter(itm => featureList.includes(itm.value)).length}
                checked={project.features.filter(itm => featureList.includes(itm)).length === Feature.filter(itm => featureList.includes(itm.value)).length}
            >
              {EN.SelectAll}
            </Checkbox>
          </div>
          <div className={styles.advancedBlock}>
            <div className={styles.advancedAlgorithmList} style={{
              border: '1px solid #bebebf',
              paddingLeft: '0.25em',
              display: 'flex',
              flex: 'none',
              flexWrap: 'wrap',
              width: '100%',
              justifyContent: 'space-between'
            }}>
              {Feature.map((v, k) => {
                const disabled = !featureList.includes(v.value);
                return <Checkbox
                    key={k}
                    style={{
                      margin: 0,
                      flex: '0 0 35%',
                      whiteSpace: 'nowrap',
                    }}
                    checked={!disabled && features.includes(v.value)}
                    disabled={disabled}
                    onChange={disabled ? () => {
                    } : handle.bind(null, 'features', v.value)}
                >{v.label}</Checkbox>;
              })}
            </div>
          </div>
        </div>}
      </div>
      <div className={styles.advancedRow}>
        <div className={styles.advancedBox}>
          <div className={styles.advancedLine}>
            <div className={styles.advancedFlex}>
              <div className={classnames(styles.advancedTitle, styles.otherLabel)}>
                <span>{EN.SetMeasurement}:</span>
              </div>
              <div className={styles.advancedOption}>
                <Select className={styles.antdAdvancedSize} value={measurement} onChange={handleMeasurement} style={{ minWidth: 140 }}>
                  {measurementList.map((i, k) => <Option value={i.value} key={k}>{i.label}</Option>)}
                </Select>
              </div>
            </div>
            <div className={styles.advancedFlex} style={{ margin: '0 0.1em' }}>
              <div className={styles.advancedTitle} style={{ padding: '0 0 0 0.5em'}}>
              <Tooltip title={`${EN.ValueBetween} 0 - 99999999`}>
                <span>{EN.RandomSeed}:</span>
              </Tooltip>
              </div>
              <div className={styles.advancedOption}>
                <InputNumber
                  value={randSeed}
                  onChange={handleRandSeed}
                  min={0}
                  bordered
                  max={99999999}
                />
                {/* <NumberInput className={classnames(styles.advancedSize, styles.inputLarge)} value={randSeed} onBlur={handleRandSeed} min={0} max={99999999} isInt={true} /> */}
              </div>
            </div>
            {!bigData && 
              <div className={styles.advancedFlex}>
              <div className={classnames(styles.advancedTitle, styles.limit)}>
                <span>{EN.SetModelEnsembleSize}:</span>
                <span className={styles.advancedDesc}>{EN.SetModelEnsembleSizeTip}</span>
              </div>
              <div className={styles.advancedOption}>
                <NumberInput className={styles.advancedSize} value={ensembleSize} onBlur={handleSize} min={1} max={30} isInt={true} />
                <span>(1~30)</span>
              </div>
            </div>
            }
          </div>
          {(problemType === "Classification") && <div className={styles.advancedLine}>
            {/* <div className={styles.advancedResampling}> */}
            <div className={classnames(styles.advancedTitle, styles.otherLabel)}>
              <span>{EN.ResamplingSetting}</span>
            </div>
            <div className={styles.advancedOptionBox}>
              <input id="resampling1" type='radio' name="resampling" checked={resampling === "up"} onChange={handleResampling.bind(null, 'up')} />
              <label htmlFor="resampling1">{EN.Autoupsampling}</label>
            </div>
            <div className={styles.advancedOptionBox}>
              <input id="resampling2" type='radio' name="resampling" checked={resampling === "down"} onChange={handleResampling.bind(null, 'down')} />
              <label htmlFor="resampling2">{EN.Autodownsampling}</label>
            </div>
            <div className={styles.advancedOptionBox}>
              <input id="resampling3" type='radio' name="resampling" checked={resampling === "no"} onChange={handleResampling.bind(null, 'no')} />
              <label htmlFor="resampling3">{EN.Noresampling}</label>
            </div>
          </div>}
        </div>
      </div>
      {((window as any).r2_env.domain !== Domain.CMB &&  (window as any).r2_env.domain !== Domain.R2 &&  bigData) && <div className={styles.advancedHyperParamRow}>
        <div className={styles.advancedLeft}>
          <div className={styles.advancedBlock}>
            <div className={styles.advancedTitle}>
              <span>{EN.HyperParameterTurning}:</span>
            </div>
            <div className={styles.advancedOption}>
              <div className={styles.advancedOptionBox}>
                <input id="Grid" type='radio' name="surrogate" checked={surrogate === "Grid"} onChange={handleHyperParamChange.bind(null, 'Grid')} />
                <label htmlFor="Grid">{EN.Grid}</label>
              </div>
              <div className={styles.advancedOptionBox}>
                <input id="Random" type='radio' name="surrogate" checked={surrogate === "Random"} onChange={handleHyperParamChange.bind(null, 'Random')} />
                <label htmlFor="Random">{EN.Random}</label>
              </div>
              <Popover
                placement="right"
                visible={surrogate === "GaussianProcess" || surrogate === "RandomForest"}
                arrowPointAtCenter
                overlayStyle={{ borderStyle: "8px" }}
                content={<Radio.Group name="radiogroup" defaultValue={'GaussianProcess'} onChange={handleBayesianOptChange.bind('null')}>
                  <Space direction="vertical">
                    <Radio value='GaussianProcess'>{EN.GaussianProcess}</Radio>
                    <Radio value='RandomForest'>{EN.RandomForest}</Radio>
                  </Space>
              </Radio.Group>}
              >
                <div className={styles.advancedOptionBox}>
                <input id="BayesianOptimization" type='radio' name="surrogate" checked={surrogate === "GaussianProcess" || surrogate === "RandomForest"} onChange={handleHyperParamChange.bind(null, 'BayesianOptimization')} />
                <label htmlFor="BayesianOptimization">{EN.BayesianOptimization}</label>
                </div>
              </Popover>
            </div>
          </div>
        </div>
        <div className={classnames(styles.advancedRight, styles.advancedRightHyperParamLimit)}>
          <div className={styles.advancedBlock}>
            <div className={styles.advancedTitle}>
              <span>{EN.NumberOfSearch}:</span>
            </div>
            <div className={styles.advancedOption}>
              <InputNumber
                width={'200px'}
                value={numIter}
                onChange={handleHyperParamNumIterChange}
                defaultValue={50}
                min={0}
                max={99999999}
              />
            </div>
          </div>
        </div>
      </div>}
      <div className={styles.advancedRow}>
        <div className={styles.advancedLeft}>
          {dataRange === "custom" && <CustomRange customRange={customRange} customFieldList={customFieldList} dataViews={dataViews} customField={customField} project={project} />}
          {dataRange === "all" && <div className={styles.advancedBlock}>
            <div className={styles.advancedTitle}>
              <span>{EN.RunModelsWith}:</span>
            </div>
            <div className={styles.advancedOption}>
              <Tooltip
                getPopupContainer={el => el.parentElement}
                title={<span className={styles.crossWarning}>
                  {EN.Performingcrossvalidation} <br />
                  {EN.Hencewerecommendchoosing}
                </span>}
                visible={!hidden && (runWith === "cross" && totalLines > 200000)}
                overlayStyle={{ maxWidth: '100%' }}>
                <div className={styles.advancedOptionBox}>
                  <input id="runwith1" type='radio' name="runWith" checked={runWith === "cross"} onChange={handleRunWith.bind(null, 'cross')} />
                  <label htmlFor="runwith1">{EN.CrossValidation}</label>
                </div>
              </Tooltip>
              <div className={styles.advancedOptionBox}>
                <input id="runwith2" type='radio' name="runWith" checked={runWith === "holdout"} onChange={handleRunWith.bind(null, 'holdout')} />
                <label htmlFor="runwith2">{EN.TrainValidationHoldout}</label>
              </div>
            </div>
          </div>}
          {dataRange === "all" && <div className={styles.advancedBlock}>
            <div className={styles.advancedBox}>
              <div className={styles.advancedTitle}>
                <span>{EN.SetPercentage}:<a className={styles.reset} onClick={reset}>{EN.Reset}</a></span>
              </div>
              {runWith === "holdout" ? <div className={styles.advancedPercentBlock}>
                <div className={styles.advancedPercent}>
                  <div className={styles.advancedPercentTrain} style={{ width: (100 - validationRate - holdoutRate) + '%' }}/>
                  <div className={styles.advancedPercentValidation} style={{ width: validationRate + '%' }}/>
                  <div className={styles.advancedPercentHoldout} style={{ width: holdoutRate + '%' }}/>
                </div>
                <Range
                  range={true}
                  step={1}
                  min={1}
                  max={99}
                  onChange={handleSlider}
                  value={[100 - parseInt((validationRate || '0').toString(), 10) - parseInt((holdoutRate || '0').toString(), 10), 100 - parseInt((holdoutRate || '0').toString(), 10)]}
                  tooltipVisible={false}
                />
              </div> : <div className={styles.advancedPercentBlock} >
                  <div className={styles.advancedPercent}>
                    {crossPercent()}
                    <div className={styles.advancedPercentHoldout} style={{ width: holdoutRate + '%' }}/>
                  </div>
                  <Range
                    range={false}
                    step={1}
                    min={1}
                    max={99}
                    onChange={handleDrag}
                    value={100 - parseInt((holdoutRate || '0').toString(), 10)}
                    tooltipVisible={false}
                  />
                </div>}
              {runWith === "holdout" ? <div className={styles.advancedPercentBox}>
                <div className={styles.advancedPercentInput}>
                  <div className={styles.advancedPercentText}>
                    <div className={classnames(styles.advancedPercetColor, styles.advancedPercentTrain)} />
                    <span>{EN.Training}</span>
                  </div>
                  {/* <input disabled={true} value={100 - parseInt(validationRate, 10) - parseInt(holdoutRate, 10)} /> */}
                  <span>{100 - parseInt((validationRate || '0').toString(), 10) - parseInt((holdoutRate || '0').toString(), 10)}%</span>
                </div>
                <div className={styles.advancedPercentInput}>
                  <div className={styles.advancedPercentText}>
                    <div className={classnames(styles.advancedPercetColor, styles.advancedPercentValidation)} />
                    <span>{EN.Validation}</span>
                  </div>
                  <span>{parseInt((validationRate || '0').toString(), 10)}%</span>
                </div>
                <div className={styles.advancedPercentInput}>
                  <div className={styles.advancedPercentText}>
                    <div className={classnames(styles.advancedPercetColor, styles.advancedPercentHoldout)}/>
                    <span>{EN.Holdout}</span>
                  </div>
                  <span>{parseInt((holdoutRate || '0').toString(), 10)}%</span>
                </div>
              </div> : <div className={styles.advancedPercentBox}>
                  <div className={styles.advancedPercentInput}>
                    <div className={styles.advancedPercentText}>
                      <div className={classnames(styles.advancedPercetColor, styles.advancedPercentCross)}/>
                      <span>{EN.SelectNumberofCVfolds}</span>
                    </div>
                    <NumberInput value={crossCount} onBlur={changeCrossCount} min={2} max={10} isInt={true} />
                  </div>
                  <div className={styles.advancedPercentInput}>
                    <div className={styles.advancedPercentText}>
                      <div className={classnames(styles.advancedPercetColor, styles.advancedPercentHoldout)}/>
                      <span>{EN.Holdout}</span>
                    </div>
                    <span>{parseInt((holdoutRate || '0').toString(), 10)}%</span>
                  </div>
                </div>}
            </div>
          </div>}
        </div>
        <div className={styles.advancedRight}>
          <div className={styles.advancedSpeed}>
            <div className={styles.advancedBox}>
              <div className={styles.advancedTitle}>
                <span>{EN.SpeedVSAccuracy}:<a className={styles.reset} onClick={resetSpeed}>{EN.Reset}</a></span>
              </div>
              <div className={styles.advancedPercentBlock}>
                <div className={styles.advancedPercent}>
                  <div className={styles.advancedPercentCross} style={{ width: ((speedVSaccuracy - 1) / 8 * 100) + '%' }}/>
                  <div className={styles.advancedPercentHoldout} style={{ width: ((9 - speedVSaccuracy) / 8 * 100) + '%' }}/>
                </div>
                <Range
                  range={false}
                  step={1}
                  min={1}
                  max={9}
                  onChange={handleSpeed}
                  value={speedVSaccuracy}
                  tooltipVisible={false}
                />
              </div>
              <div className={styles.advancedPercentBox}>
                <div className={styles.advancedPercentInput}>
                  <div className={styles.advancedPercentText}>
                    <div className={classnames(styles.advancedPercetColor, styles.advancedPercentCross)}/>
                    <span>{EN.Speed}</span>
                  </div>
                  <NumberInput value={speedVSaccuracy} onBlur={changeSpeed.bind(null, true)} min={1} max={9} isInt={true} />
                </div>
                <div className={styles.advancedPercentInput}>
                  <div className={styles.advancedPercentText}>
                    <div className={classnames(styles.advancedPercetColor, styles.advancedPercentHoldout)}/>
                    <span>{EN.Accuracy}</span>
                  </div>
                  <NumberInput value={10 - speedVSaccuracy} onBlur={changeSpeed.bind(null, false)} min={1} max={9} isInt={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
}

export default observer(AdvancedView);
