import React, { ReactElement, useContext, useMemo, useState } from 'react';
import {
	observer,
	MobXProviderContext,
} from 'mobx-react';
import styles from './styles.module.css';
import { CheckOutlined, SyncOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Modal, Progress, Button, Popconfirm, Popover } from 'antd';
import { ProjectStore } from 'stores/ProjectStore';
import r2LoadGif from './R2Loading.gif';
import EN from '../../../../constant/en';
import { ETL_STATUS } from '../../../../constant/common'

function ImportDatabaseModal(props): ReactElement {
	// @ts-ignore
	const {
		projectStore: {
			project: {
				etlProgress,
				etling,
				databaseConnectType,
				etlStatus,
				redoUploadData,
				redoDetectDataView,
				continueTask,
				importDbMsg
			},
		},
	}: {
		projectStore: ProjectStore
	} = useContext(MobXProviderContext);
	const {
		onClose,
		handleParse,
		handleResume,
		isPause,
		visible,
		showPauseButton,
		process,
	} = props;

	const [progressPercent, setProgressPercent] = useState(etlProgress || 0);
	const stepMsgList = [{
		text: EN.PrepareForUpload,
		etlStatus: ETL_STATUS.UPLOADPENDING,
		show: false,
		styles: styles.progressText,
	}, {
		text: EN.DataUploading,
		etlStatus: ETL_STATUS.UPLOADING,
		show: false,
		styles: styles.progressText,
	}, {
		text: EN.DataUploadCompleted,
		etlStatus: ETL_STATUS.UPLOADED,
		show: false,
		styles: styles.progressText,
	}, {
		text: EN.PrepareForDataDetect,
		etlStatus: ETL_STATUS.DETECTPENDING,
		show: false,
		styles: styles.progressText,
	}, {
		text: EN.DataDetecting,
		etlStatus: ETL_STATUS.DETECTING,
		show: false,
		styles: styles.progressText,
	}, {
		text: EN.DataDetected,
		etlStatus: ETL_STATUS.DETECTED,
		show: false,
		styles: styles.progressText,
		icon: null,
	}];
	let currentViewStep = etlStatus;
	if (currentViewStep === ETL_STATUS.UPLOADFAILED) {
		currentViewStep = ETL_STATUS.UPLOADING;
	} else if (currentViewStep === ETL_STATUS.DETECTFAILED) {
		currentViewStep = ETL_STATUS.DETECTING;
	}
	for (let i = 0; i < stepMsgList.length; i++) {
		const step = stepMsgList[i];
		if (i === 0 && currentViewStep <= step.etlStatus) {
			step.show = true;
			step.styles = styles.progressImportText;
			step.icon = <SyncOutlined spin={!isPause} />
		} else if (currentViewStep > step.etlStatus) {
			step.show = true;
			step.styles = styles.progressText;
			step.icon = <CheckOutlined style={{ alignSelf: "center", color: '#018d06' }} />
		} else if (currentViewStep === step.etlStatus) {
			step.show = true;
			step.styles = styles.progressImportText;
			step.icon = etlStatus < 0 ? <CloseCircleOutlined style={{ color: 'red' }} /> : <SyncOutlined spin={!isPause} />;
		} else {
			step.show = false;
		}
	}
	// const handleRedo = () => {
	// 	continueProjectEtl(id);
	// }
	useMemo(() => {
		const currentProgress = etlProgress || 0;
		let currentViewProgressStatus = etlStatus;
		if (currentViewProgressStatus === ETL_STATUS.UPLOADFAILED) {
			currentViewProgressStatus = ETL_STATUS.UPLOADING;
		} else if (currentViewProgressStatus === ETL_STATUS.DETECTFAILED) {
			currentViewProgressStatus = ETL_STATUS.DETECTING;
		}
		if (databaseConnectType === 'sql') {
			let viewProgress = currentProgress;
			if ([ETL_STATUS.UPLOADPENDING, ETL_STATUS.UPLOADING].includes(currentViewProgressStatus)) {
				viewProgress = currentProgress / 2;
			} else if ([ETL_STATUS.UPLOADED, ETL_STATUS.DETECTPENDING].includes(currentViewProgressStatus)) {
				viewProgress = 50;
			} else if ([ETL_STATUS.DETECTING, ETL_STATUS.DETECTED].includes(currentViewProgressStatus)) {
				viewProgress = currentProgress / 2 + 50;
			}
			return setProgressPercent(viewProgress);
		}
		if (ETL_STATUS.DETECTPENDING === currentViewProgressStatus) {
			return setProgressPercent(50);
		}
		if (etling) {
			return setProgressPercent((currentProgress / 2) + 50);
		}
		return setProgressPercent(process); // 案例数据与本地上传etl之前进度
	}, [etlProgress, process, etlStatus]);
	let footer = null;
	if (showPauseButton) {
		footer = [
			!isPause ? <Button key="back" onClick={handleParse}>{EN.Paused}</Button> :
				<Button key="back" type="primary" onClick={handleResume}>{EN.Resume}</Button>
		];
	}
	return <Modal
		className={styles.Modal}
		title={EN.DataImport}
		transitionName={''} // TODO 父组件重新render导致modal闪动，
		maskTransitionName={''}
		width={650}
		visible={visible}
		maskClosable={false}
		onCancel={onClose}
		destroyOnClose
		closable
		centered
		footer={footer}
	>
		<div className={styles.progressLoad}>
			<img src={r2LoadGif} alt="loading" />
		</div>
		<Progress
			className={styles.Progress}
			format={(percent) => `${percent}%`}
			strokeColor={"#455267"}
			percent={+progressPercent.toFixed(2)}
			status="active"
			strokeWidth={15}
		/>
		<div className={styles.progressMessage}>
			<div className={styles.progressLeftText}>
				{stepMsgList[0].show && <div className={stepMsgList[0].styles}>{stepMsgList[0].text}{stepMsgList[0].icon}</div>}
				{stepMsgList[1].show &&
					<Popconfirm
						title={<Popover title={EN.FailedReason} content={importDbMsg}><p>{EN.DataUploadFailed}</p></Popover>}
						visible={etlStatus === ETL_STATUS.UPLOADFAILED}
						placement="rightBottom"
						okText={EN.Retry}
						cancelText={EN.CancelImport}
						onConfirm={redoUploadData}
						onCancel={onClose}
						icon={<ExclamationCircleOutlined color='red' />}
					>
						<div className={stepMsgList[1].styles}>{stepMsgList[1].text}{stepMsgList[1].icon}</div>
					</Popconfirm>}
				{stepMsgList[2].show && <div className={stepMsgList[2].styles}>{stepMsgList[2].text}{stepMsgList[2].icon}</div>}
			</div>
			<div className={styles.progressRightText}>
				{stepMsgList[3].show && <div className={stepMsgList[3].styles}>{stepMsgList[3].text}{stepMsgList[3].icon}</div>}
				{stepMsgList[4].show &&
					<Popconfirm
						title={<Popover title={EN.FailedReason} content={importDbMsg}><p>{EN.DataDetectFailed}</p></Popover>}
						visible={etlStatus === ETL_STATUS.DETECTFAILED}
						placement="rightBottom"
						okText={EN.Retry}
						cancelText={EN.CancelImport}
						onConfirm={redoDetectDataView}
						onCancel={onClose}
						icon={<ExclamationCircleOutlined color='red' />}
					>
						<div className={stepMsgList[4].styles}>{stepMsgList[4].text}{stepMsgList[4].icon}</div>
					</Popconfirm>}
				{stepMsgList[5].show && 
					<Popconfirm
						title={importDbMsg}
						visible={etlStatus === ETL_STATUS.DETECTED && !!importDbMsg}
						placement="rightBottom"
						okText={EN.ContinueTask}
						cancelText={EN.CancelTask}
						onConfirm={continueTask}
						onCancel={onClose}
						icon={<ExclamationCircleOutlined color='#FAB733' />}
					>
					<div className={stepMsgList[5].styles}>{stepMsgList[5].text}{stepMsgList[5].icon}</div>
				</Popconfirm>
				}
			</div>
		</div>
	</Modal>;
}
export default observer(ImportDatabaseModal)
