import React, { ReactElement } from 'react';
import ReactEcharts from 'echarts-for-react';
import { EChartOption } from 'echarts';
import _ from 'lodash';
import { formatNumber } from '@app/util';

interface DependentPlotsChartProps {
  data: Array<{
    key: string,
    freq: number,
  }>,
}

export const DependentPlotsChart: React.FC<DependentPlotsChartProps> = ({
  data,
}) => {

  const option: EChartOption = {
    tooltip: {},
    xAxis: {
      type: 'category',
      data: data.map(d => d.key).map(d =>  parseFloat(d) ? (+d).toFixed(2) : d),
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      data: data.map(d => d.freq).map(d => (+d).toFixed(3)),
      type: 'bar',
      label: {
        show: true,
        position: 'top',
      },
    }]
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: '100%', width: '100%' }}
      notMerge={true}
      lazyUpdate={true}
      theme="customed"
    />
  );
}

export default DependentPlotsChart
