import { MobXProviderContext, observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import ModelError from './Error';
import Loading from './Loading';
import ModelResult from './Result';
import { ProjectStore } from 'stores/ProjectStore';

interface Interface {
	resetSide: () => void
}

function TrainResult(props:Interface){
	const {resetSide} = props;

	// @ts-ignore
	const {
		projectStore: {
			project: {
				modelCount,
				models,
				train2Error,
				stopIds,
				updateProject
			},
		},
	}:{
		projectStore:ProjectStore
	} = useContext(MobXProviderContext);

	useEffect(() => {
		if(!modelCount&&!stopIds.length){
			updateProject({
				subStepActive: 1,
				lastSubStep:1,
				train2ing:false,
				trainModel:{}
			})
		}
	}, [modelCount]);

	if (train2Error) return <ModelError />;
	if (!models.length){
		if(!modelCount&&!stopIds.length){
			updateProject({
				subStepActive: 1,
				lastSubStep:1,
				train2ing:false,
				trainModel:{}
			})
		}
		return <Loading />;
	}
	return <ModelResult
		resetSide={resetSide}
	/>
}
export default observer(TrainResult)
