import React, { ReactElement, useContext, useEffect } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import Route from 'components/App/Route';
import LoginRouter from 'components/App/LoginRoute';
import ErrorBoundary from 'components/Common/ErrorBoundary';
import Sider from 'components/Layout/Sider';
import Header from 'components/Layout/Header';
import styles from './styles.module.css';
import { Spin, Modal } from 'antd';
import EN from '../../constant/en';

const Layout: React.FC = observer((props) => {
  const {
    userStore: { status, exceptionListener },
    projectStore: { init, isOnline },
  } = useContext(MobXProviderContext);
  const useLoginRouter = status === 'unlogin';
  const showMask = status === 'unlogin' ? false : status !== 'unlogin' && !init;
  const text = isOnline ? EN.Loading : EN.Reconnecting;
  const style = isOnline
    ? null
    : { backgroundColor: 'rgba(255, 255, 255, 0.8)' };
  useEffect(()=>{
    exceptionListener(); // 当前用户操作异常提示监听
  },[])

  window.alert = msg => {
    Modal.warning({
      title: EN.KindlyReminder,
      content: (
        <div>
          {msg}
        </div>
      ),
      okText: EN.ok,
      onOk() { },
    });
  };
  const renderLogin = !showMask && status !== 'init' && useLoginRouter

  return (
    <div className={styles.app}>
      {!renderLogin &&
        <Sider />
      }
      <div className={styles.main}>
        {!renderLogin &&
          <Header />
        }
        <ErrorBoundary>
          <div className={styles.route}>
            {showMask ? (
              <div className={styles.load} style={style}>
                <Spin tip={text} size="large" />
              </div>
            ) : renderLogin ? <LoginRouter /> : <Route />}
          </div>
        </ErrorBoundary>
      </div>
    </div>
  )
});

export default Layout;
