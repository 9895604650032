import React, {ChangeEvent, useEffect, useContext } from 'react';
import styles from "./styles.module.css";
import classnames from "classnames";
import { MobXProviderContext, observer } from 'mobx-react';
import { action } from "mobx";
import { NumberInput, Hint, Range } from "components/Common";
import { CloseOutlined } from '@ant-design/icons';
import { Select, message, Tooltip, Popover } from "antd";
import Algorithms from "./algorithms.json";
import InputNumber from "antd/es/input-number";
import EN from '../../../../constant/en';
import Button from "@material-ui/core/Button";
const { Option } = Select;
import {
  SSPlot
} from "../../../Charts"
import Project, { Settings } from "stores/Project";
import { ProjectStore } from 'stores/ProjectStore';

interface Interface {
  setSettingName: (s: string) => void,
  setSetting: (o: unknown) => void,
  setSelectedSetting: (i: string) => void,
  project: Project,
  hidden: boolean,
  setting: Settings,
  selectedSetting: string
}

function AdvancedView(props:Interface){
  const {
    setSettingName,
    setSetting,
    setSelectedSetting,
    selectedSetting,
    hidden, 
    setting 
  } = props;
  // @ts-ignore
  const {
    projectStore: {
      project
    }
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext)


  useEffect(()=>{
    initSelectedSetting();
  },[selectedSetting]);

  const handleName = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingName(e.target.value)
  }


  const handleRandSeed = (value: number) => {
    project.randSeed = value
    setSetting({ randSeed: value })
  };

  const handleMeasurement = value => {
    project.measurement = value
    setSetting({ measurement: value })
  };

  const handleSelectAll = value => {
    let algorithms = [];
    if (value === 'none') {
      algorithms = [];
    } else if (value === 'all') {
      algorithms = project.bigData ? [] : Algorithms[project.problemType].map(v => v.value);
    } else if (value === 'default') {
      algorithms = project.defaultAlgorithms
    }
    project.algorithmRadio = value
    project.algorithms = algorithms
    setSetting({ algorithms })
  };

  const handleCheck = (key, e) => {
    const isCheck = e.target.checked;
    const { algorithms, bigData } = project;
    if (bigData) return
    let _algorithms = [];
    if (isCheck) {
      if (algorithms.includes(key)) return;
      _algorithms = [...algorithms, key];
    } else {
      if (!algorithms.includes(key)) return;
      _algorithms = algorithms.filter(v => v !== key);
    }
    project.algorithms = _algorithms
    setSetting({ algorithms: _algorithms })
  };

  const handleSparkCheck = (key, e) => {
    const isCheck = e.target.checked;
    const { sparkAlgorithms, bigData } = project;
    if (!bigData) return
    let _algorithms = [];
    if (isCheck) {
      if (sparkAlgorithms.includes(key)) return;
      _algorithms = [...sparkAlgorithms, key];
    } else {
      if (!sparkAlgorithms.includes(key)) return;
      _algorithms = sparkAlgorithms.filter(v => v !== key);
    }
    project.sparkAlgorithms = _algorithms
    setSetting({ sparkAlgorithms: _algorithms })
  };

  const changeSetting = action((e: ChangeEvent<HTMLSelectElement>) => {
    const selected = project.settings.find(s => s.id === e.target.value)
    const settingData = selected ? selectSetting(selected,project) : resetSetting(project);;
    setSelectedSetting(selected ? selected.id : 'default');
    setSetting(settingData);
  })

  const selectSetting = action((selected, project) => {
    let setting = {};
    if (!bigData) {
      Object.entries(selected.setting).forEach(([key, value]) => {
        project[key] = value
      });
      setting = selected.setting;
    } else {
      project.sparkAlgorithms = selected.setting.sparkAlgorithms;
      setting = { sparkAlgorithms: selected.setting.sparkAlgorithms };
    }
    return setting;
  })

  const resetSetting = action((project) => {
    let setting = {};
    if (!bigData) {
      setting = project.newSetting()
      Object.entries(setting).forEach(([key, value]) => {
        project[key] = value
      })
    } else {
      project.sparkAlgorithms = project.defaultSparkAlgorithms;
      setting = { sparkAlgorithms: project.defaultSparkAlgorithms };
    }
    return Object.assign(setting, {seleted: true});
  })
  
  const initSelectedSetting = ()=> {
    const selected = project.settings.find(s => s.id === selectedSetting)
    selected ? selectSetting(selected,project) : resetSetting(project);
  }

  const handleNum = value => {
    project.kValue = value
    setSetting({ kValue: value })
  };

  const handleMode = (type) => {
    project.kType = type
    setSetting({ kType: type })
  };

  // const handleType = (e) => {
  //   project.kType = e.target.value
  //   setSetting({ kType: e.target.value })
  // }

  const handleSpeed = value => {
    project.speedVSaccuracy = value
    setSetting({ speedVSaccuracy: value })
  }

  const changeSpeed = (isSpeed, value) => {
    if (!isSpeed) value = 10 - value
    if (value < 1 || value > 9) return
    project.speedVSaccuracy = value
    setSetting({ speedVSaccuracy: value })
  }

  const resetSpeed = () => {
    project.speedVSaccuracy = 5
    setSetting({ speedVSaccuracy: 5 })
  }

  const handleKs = value => {
    if (!Array.isArray(value)) value = [value];
    project.ks = value
  }

  const handleContamination = value => {
    project.Contamination = value
  }
    const { settings, problemType, showSsPlot, algorithmRadio, speedVSaccuracy, bigData } = project;
    const measurementList =
      problemType === "Outlier"
        ? [{ value: "score", label: EN.Accuracy, hint: EN.ScoreHint }]
        : bigData ? [
          { value: "silhouette_euclidean", label: "Silhouette Score", hint: EN.SihouetteScoreHint },
        ] : [
            { value: "CVNN", label: "CVNN", hint: EN.CVNNHint },
            { value: "CH", label: "CH Index", hint: EN.CHIndexHint },
            { value: "silhouette_euclidean", label: "Silhouette Score", hint: EN.SihouetteScoreHint },
          ];
    return (
      <div className={styles.advanced}>
        <div className={styles.advancedRow}>
          <div className={styles.advancedLeft}>
            <div className={styles.advancedBlock}>
              <div className={`${styles.advancedTitle} ${styles.limit}`}>
                <span>{EN.SelectFromPreviousSettings}:</span>
              </div>
              <div className={styles.advancedOption}>
                <select value={selectedSetting} onChange={changeSetting}>
                  <option value={"default"}>{EN.DefaultSetting}</option>
                  {settings.map(setting => (
                    <option key={setting.id} value={setting.id}>
                      {setting.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={styles.advancedRight}>
            <div className={styles.advancedBlock}>
              <div className={`${styles.advancedTitle} ${styles.limit}`}>
                <span>{EN.NameYourModelSettings}:</span>
              </div>
              <div className={styles.advancedOption}>
                <input
                  type="text"
                  value={setting.name}
                  onChange={handleName}
                />
              </div>
            </div>
          </div>
        </div>
        {problemType === "Outlier" ? null : <div className={styles.advancedRow}>
          <div className={styles.advancedBox}>
            <div className={styles.advancedBlock} style={{ alignItems: 'center' }}>
              <div className={styles.advancedTitle} id='advancedTitle'>
                <span>{EN.SpecifytheNumberofClusterstoForm}</span>
              </div>
              <Popover
                placement="bottomLeft"
                trigger="click"
                getPopupContainer={el => el.parentElement}
                visible={showSsPlot}
                onVisibleChange={() => {
                  project.showSsPlot = !showSsPlot
                }}
                content={<SSPlot
                  height={300} width={600}
                  project={project}
                />}
                title={<CloseOutlined
                  onClick={() => {
                    project.showSsPlot = false
                  }
                  }
                  className={styles.ssPlot} />}>
                <Button
                  className={styles.button}>{EN.WithinGroupSsPlot}</Button>
              </Popover>
              <Hint
                themeStyle={{ fontSize: '1rem' }}
                content={EN.WithinGroupSsSquares}
              />

            </div>
            <div className={styles.advancedBlock}>
              {(
                <div className={styles.advancedOption}>
                  <div className={styles.advancedOptionBox}>
                    <input
                      id="number_auto"
                      type="radio"
                      name="numberselect"
                      checked={project.kType === "auto"}
                      readOnly
                      onClick={handleMode.bind(this, "auto")}
                    />
                    <label htmlFor="number_auto">{EN.Auto}</label>
                  </div>
                  <div className={styles.advancedOptionBox}>
                    <input
                      id="number_custom"
                      type="radio"
                      name="numberselect"
                      checked={project.kType === "no_more_than"}
                      readOnly
                      onClick={handleMode.bind(this, "no_more_than")}
                    />
                    <label htmlFor="number_custom">{EN.NoMoreThan}</label>
                    <InputNumber
                      value={project.kValue}
                      max={15}
                      min={2}
                      step={1}
                      onChange={handleNum}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>}
        <div className={styles.advancedRow}>
          <div className={styles.advancedBox}>
            <div className={styles.advancedBlock}>
              <div className={`${styles.advancedTitle} ${styles.limit}`}>
                <span>{EN.SelectAlgorithm}:</span>
              </div>
              {!bigData && <div className={styles.advancedOption}>
                <div className={styles.advancedOptionBox}>
                  <input
                    id="algorithmSelect1"
                    type="radio"
                    name="algorithmSelect"
                    checked={algorithmRadio === 'all'}
                    readOnly
                    onClick={handleSelectAll.bind(null, 'all')}
                  />
                  <label htmlFor="algorithmSelect1">{EN.SelectAll}</label>
                </div>
                  &nbsp;
                  <div className={styles.advancedOptionBox}>
                  <input
                    id="algorithmSelect2"
                    type="radio"
                    name="algorithmSelect"
                    checked={algorithmRadio === 'none'}
                    readOnly
                    onClick={handleSelectAll.bind(null, 'none')}
                  />
                  <label htmlFor="algorithmSelect2">{EN.DeselectAll}</label>
                </div>
                <div className={styles.advancedOptionBox}>
                  <input
                    id="algorithmSelect3"
                    type="radio"
                    name="algorithmSelect"
                    checked={algorithmRadio === 'default'}
                    readOnly
                    onClick={handleSelectAll.bind(null, 'default')}
                  />
                  <label htmlFor="algorithmSelect3">{EN.SelectDefault}</label>
                </div>
              </div>}
            </div>
            <div className={styles.advancedBlock}>
              <div className={styles.advancedAlgorithmList}>
                {bigData ? Algorithms.Spark[project.problemType].map((v, k) => {
                  const tooLarge = [] //project.totalLines > 50000 ? ['Agg', 'DBSCAN', 'SpectralClustering'] : project.totalLines > 20000 ? ['SpectralClustering'] : []
                  const notSupport = [] //project.kType === 'no_more_than' ? ['DBSCAN', 'MeanShift'] : []
                  const checked = project.sparkAlgorithms ? project.sparkAlgorithms.includes(v.value) : false;
                  return <AlgorithmCheckBox hidden={hidden} key={k} tooLarge={tooLarge.includes(v.value)} notSupport={notSupport.includes(v.value)} checked={checked} value={v} handleCheck={handleSparkCheck} />
                }) : project.problemType === 'Clustering' ? Algorithms[project.problemType].map((v, k) => {
                  const tooLarge = project.totalLines > 50000 ? ['Agg', 'DBSCAN', 'SpectralClustering'] : project.totalLines > 20000 ? ['SpectralClustering'] : []
                  const notSupport = project.kType === 'no_more_than' ? ['DBSCAN', 'MeanShift'] : []
                  const checked = project.algorithms.includes(v.value)
                  return <AlgorithmCheckBox hidden={hidden} key={k} tooLarge={tooLarge.includes(v.value)} notSupport={notSupport.includes(v.value)} checked={checked} value={v} handleCheck={handleCheck} />
                }) : Algorithms[project.problemType].map((v, k) => {
                  return (
                    <div className={styles.advancedAlgorithm} key={k}>
                      <input
                        id={"algorithm" + k}
                        type="checkbox"
                        checked={project.algorithms.includes(v.value)}
                        onChange={handleCheck.bind(null, v.value)}
                      />
                      <label htmlFor={"algorithm" + k}>{v.label}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {project.problemType === "Clustering" && <div className={styles.advancedRow}>
          <div className={styles.advancedBlock}>
            <div className={styles.advancedTitle}>
              <span>{EN.SetMeasurement}:</span>
            </div>
            <div className={styles.advancedOption}>
              {measurementList.length > 1 ? <Select
                  className={styles.antdAdvancedSize}
                  value={project.measurement}
                  onChange={handleMeasurement}
              >
                {measurementList.map((i, k) => (
                    <Option value={i.value} key={k}>
                      {i.label}
                      <Hint content={i.hint}/>
                    </Option>
                ))}
              </Select>:<span>{measurementList[0].label}</span>}
            </div>
          </div>
          <div className={styles.advancedBlock} style={{ marginLeft: "30px" }}>
            <div className={styles.advancedTitle}>
              <span>{EN.RandomSeed}:</span>
              <span className={styles.advancedDesc}>
                {EN.ValueBetween} 0 - 99999999
                </span>
            </div>
            <div className={styles.advancedOption}>
              <NumberInput
                className={`${styles.advancedSize} ${styles.inputLarge}`}
                value={project.randSeed}
                onBlur={handleRandSeed}
                min={0}
                max={99999999}
                isInt={true}
              />
            </div>
          </div>
        </div>}
        <div className={styles.advancedRow}>
          <div className={styles.advancedSpeed}>
            <div className={styles.advancedBox}>
              <div className={styles.advancedTitle}>
                <span>{EN.SpeedVSPerformance}:<a className={styles.reset} onClick={resetSpeed}>{EN.Reset}</a></span>
              </div>
              <div className={styles.advancedPercentBlock}>
                <div className={styles.advancedPercent}>
                  <div className={styles.advancedPercentCross} style={{ width: ((speedVSaccuracy - 1) / 8 * 100) + '%' }}/>
                  <div className={styles.advancedPercentHoldout} style={{ width: ((9 - speedVSaccuracy) / 8 * 100) + '%' }}/>
                </div>
                <Range
                  range={false}
                  step={1}
                  min={1}
                  max={9}
                  onChange={handleSpeed}
                  value={speedVSaccuracy}
                  tooltipVisible={false}
                />
              </div>
              <div className={styles.advancedPercentBox}>
                <div className={styles.advancedPercentInput}>
                  <div className={styles.advancedPercentText}>
                    <div className={classnames(styles.advancedPercetColor, styles.advancedPercentCross)}/>
                    <span>{EN.Speed}</span>
                  </div>
                  <NumberInput value={speedVSaccuracy} onBlur={changeSpeed.bind(null, true)} min={1} max={9} isInt={true} />
                </div>
                <div className={styles.advancedPercentInput}>
                  <div className={styles.advancedPercentText}>
                    <div className={classnames(styles.advancedPercetColor, styles.advancedPercentHoldout)}/>
                    <span>{EN.sPerformance}</span>
                  </div>
                  <NumberInput value={10 - speedVSaccuracy} onBlur={changeSpeed.bind(null, false)} min={1} max={9} isInt={true} />
                </div>
              </div>
            </div>
          </div>
          {project.problemType === "Outlier" && <div className={styles.advancedBlock} style={{ marginLeft: "30px" }}>
            <div className={styles.advancedTitle}>
              <span>{EN.RandomSeed}:</span>
              <span className={styles.advancedDesc}>
                {EN.ValueBetween} 0 - 99999999
                </span>
            </div>
            <div className={styles.advancedOption}>
              <NumberInput
                className={`${styles.advancedSize} ${styles.inputLarge}`}
                value={project.randSeed}
                onBlur={handleRandSeed}
                min={0}
                max={99999999}
                isInt={true}
              />
            </div>
          </div>}
        </div>
        {project.problemType === "Outlier" && <div className={styles.empty}/>}
      </div>
    );
}

export default observer(AdvancedView)

const AlgorithmCheckBox = (props) => {
  const [visiable, setVisiable] = React.useState(false)
  const { tooLarge, notSupport, checked, value, handleCheck, hidden } = props
  const disabled = tooLarge || notSupport
  const text = tooLarge ? '该算法内存消耗大，当前数据量下不推荐使用。' : notSupport ? '该算法不支持簇的数目选择。' : ''
  return <Tooltip placement='top' title={text} visible={!hidden && disabled && visiable}>
    <div className={classnames(styles.advancedAlgorithm, {
      [styles.algorithmNotAllow]: disabled
    })} onMouseOut={() => setVisiable(false)} onMouseOver={() => setVisiable(true)}>
      <input
        id={"algorithm" + value.value}
        type="checkbox"
        disabled={disabled}
        checked={!disabled && checked}
        onChange={disabled ? () => { } : handleCheck.bind(null, value.value)}
      />
      <label htmlFor={"algorithm" + value.value}>{value.label}</label>
    </div>
  </Tooltip>
}
