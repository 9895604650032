import { observer, MobXProviderContext } from 'mobx-react';
import React, { ReactElement, useMemo, useState, useContext } from 'react';
import styles from '../styles.module.css';
import { Radio, Tooltip, Popconfirm } from "antd";
import EN from '../../../../../constant/en';
import { formatNumber } from '../../../../../util';
import moment from 'moment';
import Variable from '../../Variable.svg';
import Process from '../../Process.svg';
import VariableImpact from '../../VariableImpact';
import MPF from '../../MPF';
import classnames from 'classnames';
import PredictedProgress from './PredictedProgress';
import Model from 'stores/Model';
import Project from 'stores/Project';
import ModelInterpretation from '../ModelInterpretation';
import { ProjectStore } from 'stores/ProjectStore';
import { Show } from '@app/components/Common';
import { ProjectRolesKey } from '@app/constant/types';

interface Interface {
  readonly model: Model;
  readonly onSelect: (m: Model) => void;
  readonly isSelect: boolean;
  readonly isRecommend: boolean;
  readonly text: string;
  readonly mapHeader: any;
  readonly project: Project;
}

function ModelDetail(props: Interface): ReactElement{
  const {
    model: {
      linearData = false,
      treeData = false,
      modelName,
      created_at,
      executeSpeed,
      id,
      predicted,
      accValidation,
      score: {
        validateScore: { auc },
      },
    },
    model,
    onSelect,
    isRecommend,
    isSelect,
    mapHeader,
    project,
    text,
  } = props;
  // @ts-ignore
  const {
    projectStore: { project: { deleteModelByModelId, exportModelReport } },
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext);
  const [type, upType] = useState('');
  const [visible, upVisible] = useState(false);
  function toggleImpact(_type) {
    if (!visible) {
      //本来是关着的
      upType(_type);
      upVisible(true);
      return;
    }
    if (type === _type) {
      upVisible(false);
    } else {
      upType(_type);
    }
  }

  const _check = linearData || treeData;

  const _VariableImpact = useMemo(()=>{
    return visible && type === 'impact' && (
      <VariableImpact model={model} mapHeader={mapHeader} />
    )
  },[visible,type === 'impact']);


  return (
    <div className={styles.rowBox}>
      <Tooltip
        placement="left"
        title={isRecommend ? text : EN.Selected}
        visible={isSelect || isRecommend}
        overlayClassName={styles.recommendLabel}
        autoAdjustOverflow={false}
        arrowPointAtCenter={true}
        getPopupContainer={el => el.parentElement}
      >
        <div className={styles.rowData}>
          <div id="radio" className={styles.a_radio}>
            <Radio checked={isSelect} onChange={onSelect.bind(this,model)}/>
          </div>
          <div className={classnames(styles.cell, styles.name)}>
            <Tooltip title={modelName}>
              <span style={{
                color:model.supportIncrement ? '#8242DD':"#D0A409"
              }}>{modelName}</span>
            </Tooltip>
          </div>
          <div className={classnames(styles.cell, styles.predict)}>
            <PredictedProgress
              predicted={predicted[0]}
              width={1.5}
              height={0.25}
              type={'success'}
              failType={'fail'}
            />
            <div className={styles.space} />
            <PredictedProgress
              predicted={predicted[1]}
              width={1.5}
              height={0.25}
              type={'predicted'}
              failType={'failPredicted'}
            />
          </div>
          <div className={styles.cell}>
            <span>{formatNumber((accValidation || '').toString())}</span>
          </div>
          <div className={styles.cell}>
            <span>{formatNumber((auc || '').toString())}</span>
          </div>
          <div className={styles.cell}>
            <span>{formatNumber((executeSpeed || '').toString()) + EN.Rowss}</span>
          </div>
          <div className={styles.cell}>
            <span>
              {created_at
                ? moment(String(created_at)).format('YYYY/MM/DD HH:mm')
                : ''}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <img src={Variable} alt="" />
            <span onClick={toggleImpact.bind(this, 'impact')}>
              {EN.Compute}
            </span>
          </div>
          <div
            className={classnames(
              styles.cell,
              styles.compute,
              _check ? '' : styles.disable,
            )}
          >
            <i
              className={classnames(styles.check, _check ? '' : styles.uncheck)}
            />
            <span onClick={() => _check && toggleImpact('check')}>
              {EN.check}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <img src={Process} alt="" />
            <span onClick={toggleImpact.bind(this, 'process')}>
              {EN.Compute}
            </span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <span onClick={exportModelReport.bind(this, model.id)}>{EN.Export}</span>
          </div>
          <div className={classnames(styles.cell, styles.compute)}>
            <Show name={ProjectRolesKey.ModelEdit}>
              <Popconfirm
                title={`${EN.ConfirmDeletion}该模型`}
                onConfirm={deleteModelByModelId.bind(this, model.id)}
                okText={EN.confirm}
                cancelText={EN.Cancel}
              >
                <span >{EN.Delete}</span>
              </Popconfirm>
            </Show>
          </div>
        </div>
      </Tooltip>
      {_VariableImpact}
      {visible && type === 'process' && (
        <MPF modelId={id} project={project} model={model} />
      )}
      {visible && type === 'check' && (
        <ModelInterpretation
          treeData={treeData}
          linearData={linearData}
          modelName={modelName}
        />
      )}
    </div>
  );
}
export default observer(ModelDetail);
