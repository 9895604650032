import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import MultiClassificationDetailCurves from '../../Modeling/Result/AdvancedView/MultiClassificationDetailCurves';
import Model from 'stores/Model';
import Project from 'stores/Project';
interface Interface {
  readonly project:Project
  readonly model:Model
}
const MultiClassificationModelRow = observer((props:Interface):ReactElement=>{
  const { model, project} = props;
  if (!model.chartData) return null;

  return (
    <div>
      <MultiClassificationDetailCurves
        project={project}
        model={model}
      />
    </div>
  )
});

export default MultiClassificationModelRow;
