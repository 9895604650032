import React, { ReactElement, useContext, useEffect } from 'react';
import { inject, MobXProviderContext, observer } from 'mobx-react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Project from 'components/Project';
import Problem from 'components/Problem';
import Data from 'components/Data';
import Modeling from 'components/Modeling';
import { ProcessLoading, Confirm, Background } from 'components/Common';
import { message, Modal, Popover } from 'antd';
import styles from './styles.module.css';
import EN from '../../constant/en';
import { ProjectStore } from 'stores/ProjectStore';
import { UserStore } from 'stores/UserStore';
import moment from 'moment';

const Main: React.FC = observer(() => {
  const history = useHistory();
  const match = useRouteMatch<{ pid: string }>();
  const { pid } = match.params;

  // @ts-ignore
  const {
    projectStore: {
      project,
      conflict,
      notExit,
      init,
      initProject,
      outProject,
    },
    userStore: {
      status
    },
  }: {
    projectStore: ProjectStore
    userStore: UserStore
  } = useContext(MobXProviderContext)

  let step = -1
  useEffect(() => {
    if (status !== "login") return
    initProject(pid).then((init: boolean) => {
      if (!init) {
        Modal.error({
          title: '权限异常!',
          content: "很抱歉，您无权进入此项目。"
        });
        // message.error("Sorry but you don't have the authority for entering this project.")
        history.push("/")
      }
    })
  }, [status])

  useEffect(() => {
    if (!project || !init) return
    const { curStep = 0, id = '' } = project
    if (curStep === step) return
    step = curStep
    let url;
    switch (curStep) {
      case 1:
        url = `/project/${id}/problem`
        break
      case 2:
        url = `/project/${id}/data`
        break
      case 3:
        url = `/project/${id}/modeling`
        break
      default:
        url = `/project/${id}/project`
        break
    }
    if (!url) return history.push('/')
    if (!history.location.pathname.startsWith(`/project/${id}`)) return
    if (history.location.pathname.includes(url)) return
    return history.replace(url)
  }, [project, project?.init, project?.curStep])

  useEffect(() => {
    if (!project) return
    if (project.exist) {
      return project.initProject()
    }
    message.warn("project not exist")
    history.push("/")
  }, [project, (project || {}).exist])

  const exit = () => {
    history.push("/")
    outProject()
  }
  const formatStatsTime = () => {
    return moment(Number(project.statsTime)).format("YYYY-MM-DD HH:mm:ss")
  }
  const renderContent = () => {
    const dataSource = project.databaseConnectType == 'sample' ? "案例数据" : project.databaseConnectType == 'sql' ? "数据库" : project.databaseConnectType == 'upload' ? "本地上传" : "未知数据源";
    const dataPath = project.bigData == true ? project.fileId : "/data/user-"+ project.id +"/data/raw/"+ project.fileId ;
    return (
      <>
      {!!project.fileName && <div className={styles.popover}>
        <span className={styles.plabel}>{EN.DatasetName}: </span>
        <span className={styles.pvalue}> {project.fileName}</span>
      </div>}
      {!!project.databaseConnectType && <div className={styles.popover}>
        <span className={styles.plabel}>{EN.DatasetSource}: </span>
        <span className={styles.pvalue}> { dataSource  }</span>
      </div>}
      {!!project.fileSize && <div className={styles.popover}>
        <span className={styles.plabel}>{EN.DatasetSize}: </span>
        <span className={styles.pvalue}> { (project.fileSize / Math.pow(1024.00, 2)).toFixed(2) + "M"}</span>
      </div>}
      {!!project.rowsCount && <div className={styles.popover}>
        <span className={styles.plabel}>{EN.DatasetRows}: </span>
        <span className={styles.pvalue}> { project.rowsCount + '行' }</span>
      </div>}
      {!!project.colsCount && <div className={styles.popover}>
        <span className={styles.plabel}>{EN.DatasetCols}: </span>
        <span className={styles.pvalue}> { project.colsCount + '列' }</span>
      </div>}
      {!!project.statsTime && <div className={styles.popover}>
        <span className={styles.plabel}>{EN.StatsTime}: </span>
        <span className={styles.pvalue}>{ project.formatStatsTime }</span>
      </div>}
      {!!project.fileId && <div className={styles.popover}>
        <span className={styles.plabel}>{EN.DataPath}: </span>
        <span className={styles.pvalue}>{dataPath}</span>
      </div>}
      {(!!project.databaseConnectType && project.databaseConnectType == 'sql' && project.sqlSource) && 
      <>
        {!!project.sqlSource.databaseType &&<div className={styles.popover}>
          <span className={styles.plabel}>{EN.DatabaseType}: </span>
          <span className={styles.pvalue}> { project.sqlSource.databaseType }</span>
        </div>}
        {!!project.sqlSource.sqlDatabase &&<div className={styles.popover}>
          <span className={styles.plabel}>{EN.DatabaseName}: </span>
          <span className={styles.pvalue}> { project.sqlSource.sqlDatabase }</span>
        </div>}
        {!!project.sqlSource.sqlHostName &&<div className={styles.popover}>
          <span className={styles.plabel}>{EN.Hostname}: </span>
          <span className={styles.pvalue}> { project.sqlSource.sqlHostName }</span>
        </div>}
        {!!project.sqlSource.sqlPort &&<div className={styles.popover}>
          <span className={styles.plabel}>{EN.Port}: </span>
          <span className={styles.pvalue}> { project.sqlSource.sqlPort }</span>
        </div>}
        {!!project.sqlSource.auth_type &&<div className={styles.popover}>
          <span className={styles.plabel}>{EN.AuthenticationMethod}: </span>
          <span className={styles.pvalue}> { project.sqlSource.auth_type }</span>
        </div>}
        {!!project.sqlSource.sqlUserName &&<div className={styles.popover}>
          <span className={styles.plabel}>{EN.Yourdatabaseusername}: </span>
          <span className={styles.pvalue}> { project.sqlSource.sqlUserName }</span>
        </div>}
      </>
      }
      </>
    )
  }
  if (!project || !init || project.loadModel) return <ProcessLoading style={{ position: 'fixed' }} />
  return <>
    <div className={styles.header}>
      {project.name && <div className={styles.projectName}>
        <span className={styles.label}>{EN.Project}: </span>
        <span className={styles.value}> {project.name}</span>
      </div>}
      {!!project.fileName && <div className={styles.dataset}>
        <Popover
            placement="bottom"
            color = "rgba(0, 0, 0, 0.75)"
            // trigger="click"
            content={renderContent}
          >
          <span className={styles.label}>{EN.Dataset}: </span>
          <span className={styles.value}> {project.fileName}</span>
        </Popover>
      </div>}
    </div>
    <Switch>
      <Route path="/project/:id/problem" component={(props) => <Problem {...props} />} />
      <Route path="/project/:id/data" component={(props) => <Data {...props} />} />
      <Route path="/project/:id/modeling" component={(props) => <Modeling {...props} />} />
      <Route path="/project/:id/project" component={(props) => <Project {...props} />} />
    </Switch>
    <Confirm
      width="6em"
      title={EN.YouHaveBeenKickedOut}
      visible={conflict}
      onClose={exit}
      onConfirm={notExit}
      closeByMask={false}
      showClose={false}
      confirmText={EN.GoBacktotheProject}
      closeText={EN.GotoHomePage}
      content={EN.YouHaveBeenKickedOutOf} />
  </>
})

export default Main
