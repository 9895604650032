import React from 'react';
import { PIE_USER } from '../Charts';
import styles from './styles.module.css';

interface ChartsProps {
  restriction:{
    used:{
      projectCount,
      modelCount,
      storageCount,
      deployCount,
    }
    restriction:any
  }
}

const Charts = (props: ChartsProps) => {
  const {
    used: {
      projectCount: _projectCount,
      modelCount: _modelCount,
      storageCount: _storageCount,
      deployCount: _deployCount,
    },
    restriction:{
      NoOfModeling,
      NoOfPredictions,
      StorageSpace,
      Projects,
    }
  } = props.restriction;

  const Text = num => {
    let unit = "bytes";
    if(num == Infinity ){
      return 'Unlimited';
    }
    if (num > 1024 * 1024 * 1024) {
      unit = " Gb";
      return (num / (1024 * 1024 * 1024)).toFixed(2) + unit;
    }
    if (num > 1024 * 1024) {
      unit = " Mb";
      return (num / (1024 * 1024)).toFixed(2) + unit;
    }
    if (num > 1024) {
      unit = " kb";
      return (num / 1024).toFixed(2) + unit;
    }

    return num.toFixed(2) + unit;
  };

  return (
    <section className={styles.charts}>
      <PIE_USER
        all={NoOfModeling}
        used={+_modelCount}
        title='No. of Modeling'
      />
      <PIE_USER
        all={NoOfPredictions}
        used={+_deployCount}
        title={'No. of Predictions'}
      />
      <PIE_USER
        all={Projects}
        used={+_projectCount}
        title={'No. of Projects'}
      />
      <PIE_USER
        all={StorageSpace * 1024*1024*1024}
        used={+_storageCount}
        title={'Storage Space'}
        text={`${Text(_storageCount)}/${StorageSpace}GB`}
      />
    </section>
  );
};

export default Charts;
