import React, { ReactElement } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import EN from '../../constant/en';
import moment from 'moment';
import checkedIcon from './checked.svg';
import deleteIcon from './delete.svg';
import shareIcon from './share.png';
import classnames from 'classnames';
import _Project from 'stores/Project';
import { message, Tag } from 'antd';
import IconFont from '@app/constant/common/iconFont';

interface Interface {
  readonly selectId: any;
  readonly selected: any;
  readonly project: _Project;
  readonly actions: any;
  readonly roles?: any;
}
function Project(props: Interface): ReactElement {
  const {
    roles,
    selectId,
    selected,
    project: {
      id,
      name,
      problemType,
      train2ing,
      fileName,
      createTime,
      modelCount,
      bigData,
      role,
      email,
      deployment,
      tabName,
    },
    actions,
  } = props;
  const history = useHistory();

  const owner = role === 'Project Owners';

  const store = useLocalStore(() => ({
    cover: false,
    updateField(data) {
      Object.assign(this, data);
    },
  }));

  const hideCover = () => {
    store.updateField({
      cover: false,
    });
  };

  const showCover = () => {
    store.updateField({
      cover: true,
    });
  };

  const toggleSelect = () => {
    //统计总数
    selectId(selected, id);
  };

  const handleOpen = () => {
    history.push('/project/' + id);
  };

  const deleteProject = () => {
    if (deployment) {
      return message.warning(
        '提醒：此项目已被部署，请先删除部署模型，再重试删除此项目。',
      );
    }
    actions('delete', id);
  };

  return (
    <>
      <div
        className={styles.project}
        onMouseEnter={showCover}
        onMouseLeave={hideCover}
      >
        <div className={styles.info}>
          <div className={styles.name}>{name}</div>
          <div className={styles.description}>
            {!!problemType && (
              <div className={styles.problemType}>
                {EN.ProblemType}:{EN[problemType]}
              </div>
            )}
            {!!train2ing && (
              <div className={styles.train}>
                {EN.Status}:{EN.HomeTraining}
              </div>
            )}
            {!!modelCount ? (
              <div className={styles.count}>
                {EN.ModelCount}:{modelCount}
              </div>
            ) : (
              <div className={styles.count}>{EN.NoModelCount}</div>
            )}
          </div>
          {tabName && <div
            style={{
              background: '#ffffff',
              border: 0,
              borderRadius: '2px 2px 2px 2px',
              fontWeight: '500',
            }}
          >
            <span style={{ background: '#F0F2F6', fontSize: '0.8rem', padding: '2px 5px' }}>
              <IconFont type="icon-project-tag" />
            </span>
            <span
              style={{
                color: '#5B657B',
                background: '#F0F2F6',
                fontSize: '0.8rem',
                padding: '2px 5px',
              }}
            >
              {tabName}
            </span>
          </div>}
          <div className={styles.detail}>
            <span
              style={{
                width: 'fit-content',
                fontSize: 14,
              }}
            >
              {problemType && (bigData ? 'Spark' : 'Python')}
            </span>

            <div className={styles.shareMessage}>
              <div>
                {EN.ProjectPermission}：{role}
              </div>
              {!owner && (
                <label>
                  {EN.ShareBy}
                  {email}
                </label>
              )}
            </div>
          </div>
        </div>
        <div className={styles.sub}>
          <div className={styles.partner}>
            {id} {fileName}
          </div>
          <div className={styles.time}>
            {EN.CreatedDate}: {moment.unix(createTime).fromNow()}
          </div>
        </div>
        <div
          className={classnames(styles.cover, {
            [styles.active]: store.cover || selected,
          })}
        >
          {owner && (
            <div className={styles.actionBox}>
              <div className={styles.select}>
                {selected ? (
                  <img
                    className={styles.checked}
                    onClick={toggleSelect}
                    src={checkedIcon}
                    alt="checked"
                  />
                ) : (
                  <div className={styles.circle} onClick={toggleSelect} />
                )}
              </div>

              <div className={styles.action}>
                <img
                  onClick={actions.bind(null, 'share', id)}
                  src={shareIcon}
                  alt="share"
                />
              </div>

              {roles?.main_delete && (
                <div className={styles.action}>
                  <img onClick={deleteProject} src={deleteIcon} alt="delete" />
                </div>
              )}
            </div>
          )}

          <div className={styles.openBox}>
            <div className={styles.open} onClick={handleOpen}>
              <span>{EN.Open}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default observer(Project);
