import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { computed } from 'mobx';
import styles from './styles.module.css';
import EN from '../../../../constant/en';
import { LoadingOutlined, MinusOutlined, UpOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import ModelDetail from './ModelDetail';
import classnames from 'classnames'
import { Hint, Show, TrainModel } from 'components/Common';
import Model from 'stores/Model';
import Project from 'stores/Project';
import { ProjectRolesKey } from '@app/constant/types';

interface Interface {
  onSelect: (s: string) => void
  train2Finished: boolean
  current: Model
  trainModel: unknown
  isAbort: boolean
  recommendId: string
  sort: {
    key: string,
    value: number
  }
  handleSort: (s: string) => void
  mapHeader: any
  project: Project
  stopIds: string[]
  abortTrain: (s: string) => void
  models: Model[]
  bigData: boolean,
  allStopIds?: any[]
}
@observer
export default class ModelTable extends Component<Interface> {
  abortTrain = stopId => {
    this.props.abortTrain(stopId);
  };

  @computed
  get sortModels() {
    const {
      props: {
        models,
        sort: { key, value },
      },
    } = this;
    const fn = (a, b) => {
      switch (key) {
        case 'rmse':
          return (
            (a.score.rmse - b.score.rmse) * value
          );
        case 'mae':
          return (
            (a.score.mae - b.score.mae) * value
          );
        case 'r2':
          return (a.score.r2 - b.score.r2) * value;
        case 'speed':
          return (a.executeSpeed - b.executeSpeed) * value;
        case 'time':
          return ((a.createTime || 0) - (b.createTime || 0)) * value;
        case 'name':
        default:
          return a.modelName > b.modelName ? value : -value;
      }
    };
    return models.sort(fn);
  }

  render() {
    const {
      onSelect,
      train2Finished,
      current,
      trainModel,
      isAbort,
      recommendId,
      sort,
      handleSort,
      // mapHeader,
      project,
      stopIds,
      bigData,
      allStopIds
    } = this.props;
    return (
      <div className={styles.table}>
        <div className={styles.rowHeader}>
          <div className={styles.rowData}>
            <div
              className={classnames(
                styles.cell,
                styles.name,
                styles.cellHeader,
              )}
              onClick={handleSort.bind(null, 'name')}
            >
              <span>
                {EN.ModelName}
                {sort.key !== 'name' ? (
                  <MinusOutlined />
                ) : (
                    <UpOutlined
                      style={
                        sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                      } />
                  )}
              </span>
            </div>
            <div
              className={classnames(styles.cell, styles.cellHeader)}
              onClick={handleSort.bind(null, 'rmse')}
            >
              <span>
                <Hint content={EN.RootMeanSquareErrorprediction} />
                <i style={{ width: 4 }} />
                RMSE
                {sort.key !== 'rmse' ? (
                  <MinusOutlined />
                ) : (
                    <UpOutlined
                      style={
                        sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                      } />
                  )}
              </span>
            </div>
            <div
              className={classnames(styles.cell, styles.cellHeader)}
              onClick={handleSort.bind(null, 'mae')}
            >
              <span>
                <i style={{ width: 4 }} />
                MAE
                {sort.key !== 'mae' ? (
                  <MinusOutlined />
                ) : (
                    <UpOutlined
                      style={
                        sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                      } />
                  )}
              </span>
            </div>
            <div
              className={classnames(styles.cell, styles.cellHeader)}
              onClick={handleSort.bind(null, 'r2')}
            >
              <span>
                <Hint content={EN.R2isastatisticalmeasure} />
                <i style={{ width: 4 }} />R<sup>2</sup>
                {sort.key !== 'r2' ? (
                  <MinusOutlined />
                ) : (
                    <UpOutlined
                      style={
                        sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                      } />
                  )}
              </span>
            </div>
            <div
              className={classnames(styles.cell, styles.cellHeader)}
              onClick={handleSort.bind(null, 'speed')}
            >
              <span>
                {EN.ExecutionSpeed}
                {sort.key !== 'speed' ? (
                  <MinusOutlined />
                ) : (
                    <UpOutlined
                      style={
                        sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                      } />
                  )}
              </span>
            </div>
            <div
              className={classnames(styles.cell, styles.cellHeader)}
              onClick={handleSort.bind(null, 'time')}
            >
              <span>
                {EN.Time}
                {sort.key !== 'time' ? (
                  <MinusOutlined />
                ) : (
                    <UpOutlined
                      style={
                        sort.value === 1 ? {} : { transform: 'rotateZ(180deg)' }
                      } />
                  )}
              </span>
            </div>
            <div className={classnames(styles.cell, styles.cellHeader)}>
              <span>{EN.ResidualAnalysis}</span>
            </div>
            <div className={classnames(styles.cell, styles.cellHeader)}>
              <span>{EN.ModelProcessFlow}</span>
            </div>
            <div className={classnames(styles.cell, styles.cellHeader)}>
                <span>{EN.Delete}</span>
              </div>
          </div>
        </div>
        <div className={styles.data}>
          {this.sortModels.map((model, key) => {
            return (
              <ModelDetail
                key={key}
                model={model}
                isSelect={model.id === current.id}
                onSelect={onSelect}
                isRecommend={model.id === recommendId}
                // mapHeader={mapHeader}
                project={project}
              />
            );
          })}
          {!train2Finished && <div className={styles.rowData}>
            <div className={styles.trainingModel}>
              <Tooltip title={EN.ModelProcessing}>
                {EN.ModelProcessing}
              </Tooltip>
            </div>
            <Show
              name={ProjectRolesKey.ModelingAbort}
            >
              <div
                className={`${styles.abortButton} ${styles.abortButtonAll}`}
                onClick={
                  !isAbort
                    ? () => project.abortTrainByEtl()
                    : null
                }
              >
                {isAbort ? (
                  <LoadingOutlined />
                ) : (
                    <span>{EN.AbortTrainingAll}</span>
                  )}
              </div>
            </Show>
          </div>}
          {!train2Finished &&
            allStopIds.map(stopId => {
              if (stopId.value.data) {
                return (
                  <TrainModel
                    showAbortButton={true}
                    trainingModel={stopId.value.data}
                    isAbort={isAbort}
                    abortTrain={this.abortTrain}
                    key={stopId.key}
                  />
                );
              }
            })}
        </div>
      </div>
    );
  }
}
