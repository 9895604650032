import React, { useState } from 'react';
import { Button, Popover, PopoverProps, Table, Tooltip } from 'antd';
import EN from '../../../constant/en';
import { useRequest } from 'ahooks';
import { request } from '../../../utils';
import { ConnectData } from './types';

const { Column } = Table;


interface DBHistory {
  id: number;
  userId: number;
  data: Record<string, string>;
  created_at: Date;
  updated_at: Date;
}

interface DBHistoryResponse {
  data: Array<DBHistory>,
  status: number
}

interface IProps extends Omit<PopoverProps, 'content'> {
  onSelect: (data: ConnectData) => void,
}

const DBHistoryPopover: React.FC<IProps> = ({
  onSelect,
  children,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const { data: dbHistories, error: dbHistoriesError, loading: dbHistoriesLoading } = useRequest(
    () => request.get<DBHistoryResponse>('/api-v2/db-history/').then((res) => res.data.data),
    {
      cacheKey: 'dbHistories',
      staleTime: -1,
    },
  )

  const renderContent = () => {
    if (dbHistoriesError) {
      return dbHistoriesError.stack
    }   
    return (
      <Table
        size="middle"
        loading={dbHistoriesLoading}
        dataSource={dbHistories?.map(history => ({ id: history.id, updatedAt: history.updated_at, ...history.data }))}
        pagination={false}
        rowKey='id'
      >
        <Column title={EN.Hostname} dataIndex="sqlHostName" key="sqlHostName" />
        <Column title={EN.Port} dataIndex="sqlPort" key="sqlPort" />
        <Column title={EN.DatabaseName} dataIndex="sqlDatabase" key="sqlDatabase" />
        <Column title={EN.DatabaseType} dataIndex="databaseType" key="databaseType" />
        <Column title={EN.TableName} dataIndex="sqlTable" key="sqlTable" />
        <Column 
          title='SQL' 
          dataIndex="sqlQueryStr" 
          key="sqlQueryStr"
          onCell = {() => {
            return {
              style: {
                maxWidth: 300,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow:'ellipsis',
                cursor:'pointer'
              }
            }
          }}
          render = {(text) => <Tooltip placement="topLeft" title={text} zIndex={99999}>{text}</Tooltip>} 
        />
        <Column title={EN.Username} dataIndex="sqlUserName" key="sqlUserName" />
        <Column title={EN.UseTime} dataIndex="updatedAt" key="updatedAt" render={(value) => new Date(value).toLocaleDateString()} />
        <Column
          title=''
          dataIndex="operation"
          key="operation"
          render={(_, record: ConnectData) => (
            <a onClick={() => {
              onSelect(record);
              setVisible(false);
            }}>
              {EN.Use}
            </a>
          )}
        />
      </Table>
    )
  }

  return (
    <Popover
      content={renderContent()}
      visible={visible}
      onVisibleChange={(v) => setVisible(v)}
      {...props}
    >
      {children}
    </Popover>
  );
};

export { DBHistoryPopover }
