import React, { createRef, ReactElement, useContext } from 'react';
import { Uploads } from 'components/Common';
import { MobXProviderContext, observer, useLocalStore } from 'mobx-react';
import { DeploymentStore } from 'stores/DeploymentStore';
import { UserStore } from 'stores/UserStore';
import axios from 'axios'

function Uploader(props): ReactElement {
  const { className = 'uploader', id = `uploader-${Math.floor(Math.random() * 1000)}`, children, onProgress, onStart, onFinished, onError,params:{
    projectId
  } } = props;
  const {
    deploymentStore: {
      currentDeployment: {
        bucket
      }
    },
    userStore: { info }
  } = useContext(MobXProviderContext);

  const uploadRef: any = createRef();

  const localStore = useLocalStore(() => ({
    process: 0,
    visiable: false,
    isPause: false,
    uploading: false,
    sample: false,
    file: null,
    sql: false,
    isSql: false,
    sqlProgress: 0,
    key: '',
    pause: null,
    resume: null,
    abort: null,
    updateField(data) {
      Object.assign(this, data);
    },
  }));

  function onComplete({ fileId, fileName }) {
    localStore.updateField({
      process: 50,
    });

    onFinished({
      originalIndex: fileId,
    }, {
      name: fileName,
    });
  }

  const onCheck = async (file: File) => {
    const { data } = await axios.post('/r2upload/check', {
      fileSize: file.size,
      type: 'modeling',
      projectId
    })
    return data
  }

  const handleParse = () => {
    if (localStore.isPause) return;
    localStore.pause && localStore.pause();
    localStore.updateField({
      isPause: true,
    });
  };

  // const onError = (error: Error) => {
  //   handleParse();
  //   antdmessage.error(error.toString() + `, retry after 5s`);
  // };

  const onClick = (key: any) => {
    if (localStore.uploading) return;
    localStore.updateField({
      key,
    });
    uploadRef.current.click()
  };

  // const onClose = () => {
  //   localStore.updateField({
  //     visiable: false,
  //   });
  // };

  return <>
    <label onClick={onClick} className={className} htmlFor={id}>{children}</label>
    <Uploads
      onStart={onStart}
      ref={uploadRef}
      onProgress={onProgress}
      onComplete={onComplete}
      style={{ display: 'none' }}
      file={localStore.file}
      onError={onError}
      bucket={bucket}
      onCheck={onCheck}
    />
  </>
}

export default observer(Uploader)
