import React, { useEffect, useState } from 'react';
import { Button, message, Result, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import classnames from 'classnames';
import { request } from '@app/utils';
import Project from '@app/stores/Project';
import { useRequest, useLocalStorageState, useUpdateEffect } from 'ahooks';
import { Explain, TypeClassificationMap, ExplainViewType, TypeClassificationLabel, TypeClassificationHint, ExplainViewLabel, mockExplainData, ExplainParam, DependenceExplain, TreeExplain, ShapSingleSampleExplain, LimeExplain, updatePlotData, DESCRIBE_INFO } from './type';
import { FunctionDescribeModel } from './FunctionDescribeModel';
import { ImageView } from './ImageView';
import { DependentPlots } from './DependentPlots';
import { ShapSinglePlots } from './ShapSinglePlots';
import { LimePlots } from './LimePlots';
import { TreePlots } from './TreePlots';
import qs from 'qs';
import { FtImportance } from './FtImportance';
import r2LoadGif from '@app/assets/R2Loading.gif'
import styles from './ExplainView.module.less';

interface IProps {
  project: Project;
}

const execExplainByType = <E extends Explain>(param: ExplainParam) => {
  return request.post<{ data: E }>(`/api-v2/explain/bytype`, param).then((res) => {
    return res.data.data;
  })
}

const readFile = <E extends Explain>(url : string) => {
  return request.get<{ data: E }>(`/api-v2/r2upload/readFile?url=${url}`).then((res) => {
    return res.data.data;
  })
}


const modfiyExplainPlotData = <E extends Explain>(id: number, param: updatePlotData) => {
  return request.post<{ data: E }>(`/api-v2/explain/update/${id}`, {plotData :param.plotData}).then((res) => {
    return res.data.data;
  })
}

const fetchExplain = <E extends Explain>(id: number) => {
  return request.get<{ data: E }>(`/api-v2/explain/${id}`).then((res) => {
    return res.data.data;
  })
}



const explainClfreg = (projectId: string) => {
  return request.post('/api-v2/explain/clfreg', { projectId }).then((res) => res.data.data)
}

const insertMockData = (data: Array<Explain>): Array<Explain> => {
  const allKeys = Object.keys(mockExplainData);
  const keys = data.map(exp => exp.viewType);
  const missKeys = allKeys.filter(key => keys.findIndex(k => k === key) === -1)

  return [
    ...data,
    ...missKeys.map((key) => mockExplainData[key])
  ]
  // misskey = data
}

const fetchAllExplains = (projectId: string) => {
  return request.get<{
    data: Array<Explain>,
  }>(`/api-v2/explain/views?${qs.stringify({ projectId })}`).then((res) => {
    if (res.data.data.length === 0) {
      return res.data.data;
    }

    return insertMockData(res.data.data);
  })
}

const MINUTE = 60 * 1000;

export const ExplainView: React.FC<IProps> =({
  project,
  ...props
}: IProps) => {
  const [descriveVisible, setDescribeVisible] = useState(false);
  const [jobTimestamp, setJobTimestamp] = useLocalStorageState<Date>(`job-timestamp-${project.id}`, {
    serializer: (value) => value.toString(),
    deserializer: (value) => new Date(value),
  });

  const { run: syncProject, cancel: cancelSyncProject } = useRequest(project.refreshProject, {
    pollingInterval: 3000,
    manual: true,
  });

  const { data: explains, loading: fetchExplainsLoading, run: runFetchAllExplains, mutate: refreshExplains } = useRequest(
    () => fetchAllExplains(project.id),
  )

  const { loading: explainRequestLoading, run: requestExplainClfreg } = useRequest(
    explainClfreg,
    {
      manual: true,
      onSuccess: () => {
        setJobTimestamp(new Date())
        project.refreshProject()
      },
      onError: (err) => {
        message.error(err.message);
      }
    }
  )

  const [activeTab, setActiveTab] = useState<string>();

  const requestExplain = () => requestExplainClfreg(project.id);

  useEffect(() => {
    const func = (project.modelExplainError || !project.modelExplaining) ? cancelSyncProject : syncProject;
    func();

    return cancelSyncProject;
  }, [project.modelExplaining, project.modelExplainError])

  useUpdateEffect(() => {
    if (!project.modelExplaining && !project.modelExplainError) {
      runFetchAllExplains()
    }
  }, [project.modelExplaining, project.modelExplainError])

  useEffect(() => {
    if (activeTab) {
      return;
    }

    if (explains && explains.length > 0) {
      setActiveTab(explains[0].viewType);
    }
  }, [explains, activeTab])

  if (explains === undefined || (explains.length === 0 && fetchExplainsLoading)) {
    return null;
  }

  const activeExplain = explains.find(exp => exp.viewType === activeTab);
  
  if (project.modelExplaining) {
    return (
      <Result
        icon={(<img src={r2LoadGif} alt="loading" />)}
        title="正在解释模型"
        extra={(!jobTimestamp || new Date().getTime() > jobTimestamp.getTime() + 3 * MINUTE) && (
          <Button type='primary' onClick={requestExplain} loading={explainRequestLoading}>
            等待时间过长？试试重新请求
          </Button>
        )}
      />
    )
  }

  if (project.modelExplainError) {
    return (
      <Result
        status="error"
        title="模型解释失败"
        subTitle={project.modelExplainError}
        extra={(
          <Button type='primary' onClick={requestExplain} loading={explainRequestLoading}>
            重新解释模型
          </Button>
        )}
      />
    )
  }

  if (explains.length === 0) {
    return (
      <Result
        status={'info'}
        title='没有找到解释，要开始解释么？'
        extra={(
          <Button type='primary' onClick={requestExplain} loading={explainRequestLoading}>
            解释模型
          </Button>
        )}
      />
    )
  }

  const explainSiderMap: Record<string, Array<Explain>> = explains.reduce((a, b) => (
    { ...a, [TypeClassificationMap[b.viewType]]: [...(a[TypeClassificationMap[b.viewType]] || []), b] }
    ), {}
  )
  const explainSiderSortMap: Record<string, Array<Explain>> = { global : explainSiderMap['global'] , part : explainSiderMap['part']};
  const renderContent = () => {
    if (!activeExplain) {
      return (
        <Result
          status='warning'
          title="请从左侧选择一项解释"
        />
      )
    }

    switch (activeExplain.viewType) {
      case ExplainViewType.ftImportance:
        return (
          <FtImportance featureImportance={activeExplain.plotData.featureImportance}/>
        )
      case ExplainViewType.dependence:
        return (
          <DependentPlots
            explain={activeExplain}
            project={project}
            predictOptions={[...project.dataHeader.filter(k => project.trainHeader.findIndex(i => i === k) === -1), ...project.newVariable]}
            onExplain={(explainOption) => execExplainByType<DependenceExplain>({ projectId: parseInt(project.id), plotType: ExplainViewType.dependence, explainOption })}
            refreshExplain={fetchExplain}
            onUpdateExplain={(explain) => {
              runFetchAllExplains()
              refreshExplains(oldExps => {
                const index = oldExps.findIndex(exp => exp.viewType === explain.viewType);
                oldExps[index] = explain;
                return [...oldExps];
              });
            }}
          />
        )
      case ExplainViewType.tree:
        return (
          <TreePlots
            explain={activeExplain}
            onExplain={(explainOption) => execExplainByType<TreeExplain>({ projectId: parseInt(project.id), plotType: ExplainViewType.tree, explainOption })}
            refreshExplain={fetchExplain}
            onUpdateExplain={(explain) => {
              runFetchAllExplains()
              refreshExplains(oldExps => {
                const index = oldExps.findIndex(exp => exp.viewType === explain.viewType);
                oldExps[index] = explain;
                return [...oldExps];
              });
            }}
          />
        )
      case ExplainViewType.shapScatter:
        return (
          <ImageView image={activeExplain.plotData.image}/>
        )
      case ExplainViewType.shapImportance:
        return (
          <ImageView image={activeExplain.plotData.image}/>
        )
      case ExplainViewType.shapSingleSample:
        return (
          <ShapSinglePlots
            explain={activeExplain}
            onExplain={(explainOption) => execExplainByType<ShapSingleSampleExplain>({ projectId: parseInt(project.id), plotType: ExplainViewType.shapSingleSample, explainOption })}
            refreshExplain={fetchExplain}
            onUpdateExplain={(explain) => {
              runFetchAllExplains()
              refreshExplains(oldExps => {
                const index = oldExps.findIndex(exp => exp.viewType === explain.viewType);
                oldExps[index] = explain;
                return [...oldExps];
              });
            }}
            updatePlotData={modfiyExplainPlotData}
            readHdfsFile={readFile}
          />
        )
      case ExplainViewType.lime:
        return (
          <LimePlots
            explain={activeExplain}
            onExplain={(explainOption) => execExplainByType<LimeExplain>({ projectId: parseInt(project.id), plotType: ExplainViewType.lime, explainOption })}
            refreshExplain={fetchExplain}
            onUpdateExplain={(explain) => {
              runFetchAllExplains()
              refreshExplains(oldExps => {
                const index = oldExps.findIndex(exp => exp.viewType === explain.viewType);
                oldExps[index] = explain;
                return [...oldExps];
              });
            }}
            updatePlotData={modfiyExplainPlotData}
            readHdfsFile={readFile}
          />
        )
      default:
        return (
          <Result
            status='error'
            title="暂不支持该类型的渲染"
          />
        )
    }
  }

  return (
    <React.Fragment>
      <div className={styles.view}>
        <div className={styles.sider} style={{ marginRight: 16 }}>
          {(Object.keys(explainSiderSortMap)).map(classification => (
            <React.Fragment key={classification}>
              <div className={classnames(styles.siderClassification, { [styles.active]: activeExplain && TypeClassificationMap[activeExplain.viewType] === classification })} >
                {TypeClassificationLabel[classification]}
                {TypeClassificationHint[classification] &&
                  <Tooltip title={TypeClassificationHint[classification]}>
                    <QuestionCircleFilled className={styles.categoryInfo} style={{ marginLeft: 16 }}/>
                  </Tooltip>
                }
              </div>

              <div style={{ marginBottom: 32 }}>
                {explainSiderSortMap[classification].map((option) => (
                  <div
                    className={classnames(styles.siderItem, { [styles.active]: activeTab === option.viewType })}
                    key={option.id}
                    onClick={() => setActiveTab(option.viewType)}
                  >
                    {ExplainViewLabel[option.viewType]}
                    {option.isMock && <span className={styles.siderItemTag}>[mock]</span>}
                  </div>
                ))}
              </div>
            </React.Fragment>
          ))}

          <Button onClick={requestExplain}>
            重新训练全部解释
          </Button>
        </div>
        <div className={styles.content}>
          {activeExplain &&
            <div className={styles.contentHeader}>
              <Button type='primary' ghost style={{ marginLeft: 'auto' }} onClick={() => setDescribeVisible(true)} >
                方法解释
              </Button>

              <Tooltip title={DESCRIBE_INFO}>
                <QuestionCircleFilled className={styles.categoryInfo} style={{ marginLeft: 16 }}/>
              </Tooltip>
            </div>
          }
          {renderContent()}
        </div>
      </div>
      {activeExplain &&
        <FunctionDescribeModel
          type={activeExplain.viewType}
          visible={descriveVisible}
          onCancel={() => setDescribeVisible(false)}
        />
      }
    </React.Fragment>
  )
}
