import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { PlayCircleTwoTone, setTwoToneColor } from '@ant-design/icons';
import { Tree, Input, Tabs, Modal } from 'antd';
import { observable, action, toJS } from 'mobx';
import { observer, MobXProviderContext } from 'mobx-react';
import ArticleCH from './ArticleCH';
import Article from './Article';
import PdfView from './pdfView';
import classnames from 'classnames';
import axios from 'axios';
import EN from '../../constant/en';
import { UserStore } from 'stores/UserStore';
import Video from './videos/video'
import { Domain } from '@app/constant/types';

(window as any).HELP_IMPROVE_VIDEOJS = false;

const { TreeNode, DirectoryTree } = Tree;
const { TextArea } = Input;
const _data = observable({
  selectedKeys: ['1.1'],
  expandedKeys: ['1'],
  waiting: false,
  type: 1,
  text: '',
  email: '',
});

setTwoToneColor('#1d2b3c');

const _change = action((name, value) => (_data[name] = value));

const TabPane = Tabs.TabPane;

interface Interface {
  location: any;
}

function Support(props: Interface): ReactElement {
  const {
    location: { state },
  } = props;
  const {
    userStore: {
      info: { email },
    },
  } = useContext(MobXProviderContext);

  const {local, domain} = (window as any).r2_env;

  const section:any = useRef();
  
  const files = [{
    id:0,
    name: domain === Domain.GZCB ? '机器学习系统3.13产品操作手册（分布式）' : (window as any).r2_env.isEN ? `R2-Learn 3.15 Product Operations Manual(DISTRIBUTED)` : '机器学习系统3.15产品操作手册（分布式）' ,
    url: domain === Domain.GZCB ? require(`./operationManual(distributed)_GZCB.pdf`) : (window as any).r2_env.isEN ? require('./R2-Learn 3.15 Product Operations Manual(DISTRIBUTED).pdf') : require(`./operationManual(distributed).pdf`),
    size: (window as any).r2_env.isEN ? '2.9 MB' : '2.6 MB',
    createdAt: '2022-8-23 13:25:55'
  }, {
    id:1,
    name: domain === Domain.GZCB ? '机器学习系统3.13产品操作手册（非分布式）' : (window as any).r2_env.isEN ? `R2-Learn 3.15 Product Operations Manual(NON-DISTRIBUTED)` : '机器学习系统3.15产品操作手册（非分布式）' ,
    url: domain === Domain.GZCB ? require(`./operationManual_GZCB.pdf`) : (window as any).r2_env.isEN ?  require('./R2-Learn 3.15 Product Operations Manual(NON-DISTRIBUTED).pdf') : require(`./operationManual.pdf`),
    size: (window as any).r2_env.isEN ? '3.2 MB' :'3.4 MB',
    createdAt: '2022-8-23 13:25:55'
  }]
  const [tabKey, upTabKey] = useState(state && state.key === 'loginTo' ? 2 : 1);
  const [videoKey, upVideoKey] = useState(0);
  const videoData = ['Binary Classification','Multiclass Classification','Regression','Clustering',
    'Anomaly Detection','Association Analysis','Forecasting','Model Interpretation','Deployment','Account Information'];
  const [videoArticle, upVideoArticle] = useState({
    title:videoData[0],
    html:'',
  });

  let waiting = false;

  useEffect(() => {
    _change('email', email);
    _change("type", 1);
  }, []);

  function onSelect(selectedKeys) {
    _change('selectedKeys', selectedKeys);
    waiting = true;
    setTimeout(() => {
      waiting = false;
    }, 500);
    window.location.hash = selectedKeys[0];
  }

  function changeSelectedKeys(selectedKeys) {
    if (!waiting) {
      const expandedKeys = toJS(_data.expandedKeys);
      changeSK(expandedKeys, selectedKeys);
    }
  }

  function changeSK(expandedKeys, selectedKeys) {
    if (selectedKeys.length === 1) {
      _change('selectedKeys', [selectedKeys]);
      return;
    }

    if (expandedKeys.includes(selectedKeys)) {
      _change('selectedKeys', [selectedKeys]);
    } else if (selectedKeys) {
      const s = selectedKeys.substring(0, selectedKeys.length - 2);
      if (expandedKeys.includes(s)) {
        _change('selectedKeys', [selectedKeys]);
        return;
      }

      if (s.length === 1) {
        _change('selectedKeys', [s]);
        return;
      }
      changeSK(expandedKeys, s);
    }
  }

  function _onExpand(expandedKeys) {
    _change('expandedKeys', expandedKeys);
  }

  function handleChangeArea(e) {
    _change('text', e.target.value);
  }

  function handleChange(e) {
    _change('email', e.target.value);
  }

  const tabClick = key => {
    // change('tabKey')(key);
    // change('videoKey')('1');
    upTabKey(+key);
    upVideoKey(0);
  };

  const topTabsClick = type => {
    _change('type', parseInt(type, 10));
  };



  const changeVideo = index => {
    return () => {
      // change('videoKey')(id);
      upVideoKey(+index);
      // if (id === '1') {
      //   sourceChange.current.src = require('./resource/English_video.mp4');
      //   videoChange.current.load();
      // } else {
      //   sourceChange.current.src = require('./resource/Chinese_video.mp4');
      //   videoChange.current.load();
      // }

      upVideoArticle({
        title:videoData[index],
        html:'',
      })
      section.current.scroll(0,0);
    };
  };

  function submit() {
    const { email, type, text } = toJS(_data);
    const typeText =
      (type === 1 && 'Bug') ||
      (type === 2 && 'Feature') ||
      (type === 3 && 'Question') ||
      '';
    if (!typeText) return;
    if (!text) {
      return Modal.info({
        title: '校验失败!',
        content: EN.Pleaseinputsomecontentsforthesubmissio
      });

      // message.destroy();
      // return message.info(EN.Pleaseinputsomecontentsforthesubmissio);
    }
    if (!email) return;
    axios
      .post('/user/report', { type: typeText, text, email })
      .then(res => {
        if (res.status === 200) {
          _change('email', email);
          _change('text', '');
          _change('type', 1);
          // message.success(EN.Reportsuccess);
          Modal.success({
            title: EN.Success,
            content: EN.Reportsuccess
          });
        } else {
          Modal.error({
            title: EN.DataException,
            content: (res.data || {}).message || EN.Reporterror
          });
          // message.destroy();
          // message.error((res.data || {}).message || EN.Reporterror);
        }
      })
      .catch(() => (window as any).stores.userStore._status());
  }

  const selectedKeys = toJS(_data.selectedKeys);
  const expandedKeys = toJS(_data.expandedKeys);

  const Articles = (window as any).r2_env.isEN ? (
    <Article changeSelectedKeys={changeSelectedKeys} local={local}/>
  ) : (
      <ArticleCH changeSelectedKeys={changeSelectedKeys} />
    );

  return (
    <section style={{ width: '100%', background: '#fff',height:(window as any).innerHeight}}>
      <div className={styles.main}>
        <div className={styles.content}>
          <PdfView files={files}></PdfView>
        </div>
        {/* <div className={styles.support}>
          <div className={styles.report}>
            <div className={styles.types}>
              <Tabs defaultActiveKey="1" onChange={topTabsClick}>
                <TabPane tab={EN.ReportBug} key='1'>{}</TabPane>
                <TabPane tab={EN.RequestFeature} key='2'>{}</TabPane>
                <TabPane tab={EN.AskQuestion} key='3'>{}</TabPane>
              </Tabs>
            </div>
            <div className={styles.text}>
              <TextArea
                className={styles.textArea}
                rows={4}
                value={_data.text}
                onChange={handleChangeArea}
              />
            </div>
            <div className={styles.email}>
              <Input
                placeholder="Enter your email"
                value={_data.email}
                onChange={handleChange}
                disabled
              />
            </div>
            <div className={styles.button}>
              <div
                className={classnames(styles.type, styles.checked)}
                onClick={submit}
              >
                <span>{EN.Submit}</span>
              </div>
            </div>
          </div>
          <div className={styles.menu}>
            <Tabs defaultActiveKey={String(tabKey)} onChange={tabClick}>
              <TabPane tab={EN.UserManual} key="1">
                <DirectoryTree
                  showLine
                  onSelect={onSelect}
                  selectedKeys={selectedKeys}
                  expandedKeys={expandedKeys}
                  onExpand={_onExpand}
                >
                  <TreeNode title={EN.Overview} key="1">
                    <TreeNode title={EN.Machinelearning} key="1.1" />
                    <TreeNode title={'1.2.' + EN.MachinewithR2} key="1.2" />
                    <TreeNode title={'1.3.' + EN.GettingstartedwithR2} key="1.3">
                      <TreeNode title={'1.3.1.' + EN.Softwarerequirements} key="1.3.1" />
                      <TreeNode title={'1.3.2.' + EN.Projecthome} key="1.3.2" />
                      <TreeNode title={'1.3.3.Deployment Console'} key="1.3.3" />
                      <TreeNode title={'1.3.4.Model Management Console -- Dashboard'} key="1.3.4" />
                      <TreeNode title={'1.3.5.Manual Modeling – JupyterLab'} key="1.3.5" />
                    </TreeNode>
                  </TreeNode>

                  <TreeNode title={EN.Createproject} key="2" />
                  <TreeNode title={EN._3ChooseProblemType} key="3" />
                  <TreeNode title={EN._4DataPreprocessing} key="4">
                    <TreeNode title={'4.1.' + EN.DataConnect} key="4.1" />
                    <TreeNode title={'4.2.' + EN.DataSchema} key="4.2" />
                    <TreeNode title={'4.3.' + EN.DataQuality} key="4.3" />
                  </TreeNode>
                  <TreeNode title={EN._5Modeling} key="5">
                    <TreeNode title={'5.1.' + EN.AutomaticModeling} key="5.1" />
                    <TreeNode title={'5.2.' + EN.AdvancedModeling} key="5.2">
                      <TreeNode title={'5.2.1.Advanced Variable Setting'} key="5.2.1" />
                      <TreeNode title={'5.2.2.Advanced Modeling Setting for Binary Classification'} key="5.2.2" />
                      <TreeNode title={'5.2.3.Advanced Modeling Setting for Regression'} key="5.2.3" />
                      <TreeNode title={'5.2.4.Advanced Modeling Setting for Multiclass Classification'} key="5.2.4" />
                      <TreeNode title={'5.2.5.Advanced Modeling Setting for Clustering'} key="5.2.5" />
                      <TreeNode title={'5.2.6.Advanced Modeling Setting for Anamoly Detection'} key="5.2.6" />
                      <TreeNode title={'5.2.7.Advanced Modeling Setting for Association Analysis'} key="5.2.7" />
                      <TreeNode title={'5.2.8.Advanced Modeling Setting for Time Series Forecasting'} key="5.2.8" />
                    </TreeNode>
                    <TreeNode title={'5.3.Training Your Model'} key="5.3" />
                    <TreeNode title={'5.4.Model Selection'} key="5.4" >
                      <TreeNode title={'5.4.1.Model Selection for Binary Classification'} key="5.4.1" />
                      <TreeNode title={'5.4.2.Model Selection for Regression'} key="5.4.2" />
                      <TreeNode title={'5.4.3.Model Selection for Multiclass Classification'} key="5.4.3" />
                      <TreeNode title={'5.4.4.Model Selection for Clustering'} key="5.4.4" />
                      <TreeNode title={'5.4.5.Model Selection for Anamoly Detection'} key="5.4.5" />
                      <TreeNode title={'5.4.6.Association Rules for Association Analysis'} key="5.4.6" />
                      <TreeNode title={'5.4.7.Model Selection for Time Series Forecasting'} key="5.4.7" />
                    </TreeNode>

                    <TreeNode title={'5.5.Model Export'} key="5.5" >
                      <TreeNode title={'5.5.1.PMML Model Export'} key="5.5.1" />
                      <></>
                    </TreeNode>
                  </TreeNode>

                  <TreeNode title={'6.Deployment Console'} key="6">
                    <TreeNode title={'6.1.Model Deployment'} key="6.1">
                      <TreeNode title={'6.1.1.Predict with Data Source'} key="6.1.1" />
                      <TreeNode title={'6.1.2.Predict with API'} key="6.1.2" />
                    </TreeNode>
                    <TreeNode title={'6.2.Monitor Deployed Models'} key="6.2">
                      <TreeNode title={'6.2.1.Operation Monitor'} key="6.2.1" />
                      <TreeNode title={'6.2.2.Performance Monitor'} key="6.2.2" />
                      <TreeNode title={'6.2.3.' + EN.PerformanceStatus} key="6.2.3" />
                    </TreeNode>
                  </TreeNode>

                  <TreeNode title={'7. Account Information'} key="7"/>

                  {local&&<TreeNode title={'8.Manual Modeling – JupyterLab'} key="8"/>}
                  <TreeNode title={'Appendix A: Data Quality Fixes'} key="a" >
                    <TreeNode title={'A.1. Fixing Outliers'} key="a.1" />
                    <TreeNode title={'A.2. Fixing Missing Values'} key="a.2" />
                    <TreeNode title={'A.3. Fixing Data Type Mismatches'} key="a.3" />
                  </TreeNode>
                  <TreeNode title={'Appendix B: Create a New Variable'} key="b">
                    <TreeNode title={'B.1. Basic Function'} key="b.1" />
                    <TreeNode title={'B.2. R2-Learn Custom Function'} key="b.2" />
                  </TreeNode>
                  {local&&<TreeNode title={'Appendix C: Jupyter Lab Third Party Package'} key="c"/>}
                </DirectoryTree>
              </TabPane>
              <TabPane tab={EN.TutorialVideo} key="2">
                {videoData.map((name,index) => {
                  return (
                    <div
                      onClick={changeVideo(index)}
                      key={index}
                      className={
                        videoKey !== +index
                          ? styles.tabLeft
                          : styles.tabLeftAction
                      }
                    >
                      {videoKey !== +index ? (
                        <img src={require('./resource/video.png')} alt="" />
                      ) : (
                          <PlayCircleTwoTone />
                        )}
                      <span>{name}</span>
                    </div>
                  );
                })}
              </TabPane>
            </Tabs>
          </div>
        </div>
        <div className={styles.content} ref={section}>
          {tabKey === 1 ? (
            Articles
          ) : <Video
                title={videoArticle.title}
            />}
        </div> */}
      </div>
    </section>
  );
}
export default observer(Support);
