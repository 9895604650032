import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import EN from '../../../constant/en';
import { MobXProviderContext, observer } from 'mobx-react';

const ChangePasswdModal: React.FC<{ email?: string, title?: string }> = ({ email, title }) => {
  const { userStore } = useContext(MobXProviderContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (userStore.changePasswdVisible) {
      form.resetFields();
    }
  }, [userStore.changePasswdVisible]);
  const handleSubmit = () => {
    form.validateFields().then(({ newPasswd, originPasswd }) => {
      Modal.confirm({
        title: EN.SubmitChangePasswd,
        content: EN.ChangePasswdConfirmMessage,
        okText: EN.ConfirmChange,
        onOk: () => {
          setLoading(true);
          userStore.changePassword(originPasswd, newPasswd, email).then(
            resp => {
              if (resp.data.status === 200) {
                message.success(EN.Passwordchangesucceeded, 2000);
                setTimeout(() => {
                  userStore.logout();
                }, 2000);
              } else {
                console.error(resp.data.error);
                message.error(`${EN.ModifyFailed} ${resp.data.message}`);
              }
              setLoading(false);
            },
            err => {
              console.error(err);
              message.error(EN.Passwordchangefailed);
              setLoading(false);
            },
          );
        },
      });
    });
  };
  return (
    <Modal
      title={title || EN.ChangePasswd}
      visible={userStore.changePasswdVisible}
      maskClosable={false}
      onCancel={() => userStore.changePasswdModalVisible(false)}
      okText={EN.confirm}
      cancelText={EN.Cancel}
      onOk={() => handleSubmit()}
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={EN.OriginPasswd}
          extra={EN.OriginPasswdTip}
          name="originPasswd"
          rules={[{ required: true }]}
        >
          <Input.Password disabled={loading}></Input.Password>
        </Form.Item>
        <Form.Item
          label={EN.EnterNewPassword}
          name="newPasswd"
          rules={[
            {
              required: true,
              pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/,
              message: EN.NewPasswordMessage,
            },
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder={EN.NewPasswordMessage}
            min={8}
            disabled={loading}
          ></Input.Password>
        </Form.Item>
        <Form.Item
          label={EN.ConfirmPassword}
          name="confirmPasswd"
          dependencies={['newPasswd']}
          rules={[
            {
              required: true,
              message: EN.ConfirmYour,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPasswd') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(EN.ConfirmPasswdNotMatch));
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder={EN.InputAgain}
            disabled={loading}
          ></Input.Password>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(ChangePasswdModal);
