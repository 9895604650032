import React  from 'react';
import styles from './styles.module.css';

export default function Modal(props){
    const { visible, width, content, title, onClose, closeByMask, showClose,mask=true } = props;
    const blockStyle = width ? { width: width } : {};
    return !!visible && <div className={styles.modal}>
      {mask&&<div className={styles.cover} onClick={closeByMask ? onClose : null}/>}
      <div className={styles.block} style={blockStyle}>
        <div className={styles.title}>
          <span id={styles.titleText}>{title}</span>
          {/*{showClose && <div className={styles.close} onClick={onClose}>*/}
          {/*  <span id={styles.titleText}>X</span>*/}
          {/*</div>}*/}
          {showClose && <i onClick={onClose}>
            ＋
          </i>}
        </div>
        <div className={styles.content}>{content}</div>
      </div>
    </div>
}
