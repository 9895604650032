import React, { ReactElement } from 'react';
import styles from './styles.module.css';
import { BoxPlots, FitBar, TSENOne, UnivariantPlots } from '../../../Charts';

interface Interface {
  readonly type:string
  readonly style?:any
  readonly data:any
  readonly message:{
    x:string
    y:string
  }
  readonly colType:string
  readonly renameVariable:any
}

export default function ScatterPlot(props:Interface):ReactElement{
    const { type, style, data, message, colType,renameVariable } = props;

    if(type === "MultiClassification"){
      return <FitBar
         message={data}
      />
    }
    if (type === 'Regression') {
      //散点图
      if (colType === 'Numerical') {
        return <div className={styles.plot} style={style}>
          <TSENOne
            x_name={message.x}
            y_name={message.y}
            data={data}
          />
        </div>
      }

      //箱线图
      return <div className={styles.plot} style={style}>
        <BoxPlots
          x_keys={data.x_keys}
          value={data.value}
        />
      </div>
    }
    return <div className={styles.plot} style={style}>
      <UnivariantPlots
        x_name={message.x}
        y_name={message.y}
        result={data}
        renameVariable={renameVariable}
      />
    </div>
}
