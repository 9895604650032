import React, { ReactElement, useContext, useState, useEffect, useMemo, useRef } from 'react';
import styles from './styles.module.css';
import { observer, MobXProviderContext } from 'mobx-react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { autorun } from 'mobx'
import StartTrain from './Start';
import { Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import EN from '../../constant/en';
import { ProjectSide } from 'components/Common';
import { ProjectStore } from 'stores/ProjectStore';
import TrainResult from './TrainResult';

export default observer(Modeling)

function Modeling():ReactElement{

  // @ts-ignore
  const {
    projectStore: {
        project,
        project:{
          lastSubStep,
          subStepActive,
          updateProject,
          clearInvalidModels,
          nextSubStep
        }
    },
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext);
  const history = useHistory();
  const sideRef:any = useRef()
  
	const [lock,upLock] = useState(false);

  useEffect(()=>{
    if (!project) return
    return autorun(() => {
      const { curStep, subStepActive, id } = project;
      if (curStep !== 3) return
      let url = ''
      if (subStepActive === 1) url = `/project/${id}/modeling/start`
      if (subStepActive >= 2) url = `/project/${id}/modeling/result`
      if (!url) return history.push("/")
      if (!history.location.pathname.startsWith(`/project/${id}/modeling`)) return
      if (history.location.pathname.includes(url)) return
      return history.replace(url)
    })
  },[])

  useEffect(()=>{
    sideRef?.current?.reset()
  },[sideRef?.current])
  
  // useEffect(()=>{
  //   if (!!project.invalidModels.length && !lock && !project.train2ing) {
  //     upLock(true);
  //     // project.invalidModels.forEach(model => {
  //     //   if (project.id !== model.projectId) return;
  //     //   notification.open({
  //     //     message: EN.ModelingError,
  //     //     description:
  //     //       `「${model.modelName}」${EN.ThresholdTooLowTips}`,
  //     //     icon: <WarningOutlined style={{ color: '#108ee9' }} />,
  //     //     duration: 0,
  //     //   });
  //     // })
  //     const filter_models = project.invalidModels.filter(m=>project.id === m.projectId)
  //     Modal.info({
  //       title: '以下模型未达到预期效果,将不在页面展示:',
  //       centered: true,
  //       width: '700px',
  //       content: (  <>
  //         <p style={{ maxHeight: '300px', overflowY:'auto' }}>
  //           { filter_models.map((m, index)=> {
  //             return <React.Fragment key={index}>{`${m.modelName} ${index === filter_models.length - 1 ? "" : "、"}`}<br></br></React.Fragment> 
  //           })}
  //         </p>
  //       </>),
  //       okCancel: false,
  //       mask: false,
  //       okText: EN.Yes,
  //       icon: <WarningOutlined style={{ color: '#108ee9' }}/>
  //     });
  //     clearInvalidModels(project.id);
  //     upLock(false);
  //   }
  // },[project.invalidModels])


  const resetSide = () => {
    if (sideRef?.current) sideRef?.current?.reset()
  }

  const enter = step => {
    if (step === subStepActive) return false;
    if (step > lastSubStep) return false;
    return updateProject(nextSubStep(step, 3))
  };

  return useMemo(()=>{
    return <div className={styles.modeling}>
      {project && <Switch>
        <Route exact path="/project/:id/modeling/start" component={StartTrain} />
        <Route exact path="/project/:id/modeling/result" component={() => {
          return <TrainResult
              resetSide={resetSide}
          />
        }} />
      </Switch>}
      {project && <ProjectSide
          enter={enter}
          project={project}
          keyword='modeling'
          ref={sideRef}
      />}
    </div>
  },[!project])
}
