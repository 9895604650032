import React from 'react';
import _ from 'lodash';
import { Image } from 'antd';
import styles from './ImageView.module.less'

interface IProps {
  image: string;
}

export const ImageView: React.FC<IProps> = ({ image }) => {
  return (
    <div className={styles.imageView}>
      <Image
        style={{ maxWidth: 550 }}
        src={image}
      />
    </div>
  );
};
