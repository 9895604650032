import { observer } from 'mobx-react';
import React, { ReactElement, useState } from 'react';
import ClusteringDetailCurves from '../../Modeling/Result/AdvancedView/ClusteringDetailCurves';
interface Interface {
  readonly model:any
  readonly project:any
}
const ClusteringModelRow = observer((props:Interface):ReactElement=>{
  const { model, project } = props;
  const [type, setType] = useState('parallel')
  return <ClusteringDetailCurves
    project={project}
    model={model}
    type={type}
    handleType={(key) => setType(key)}
    report={true}
  />
});

export default ClusteringModelRow
