import { observable, action, computed, when } from 'mobx';
import socketStore from 'stores/SocketStore';
import userStore from 'stores/UserStore';
import EN from '../../src/constant/en';
import {notification} from 'antd'

const sortStrategies = {
  createdDate: (a, b) =>
    a.createdDate === b.createdDate
      ? 0
      : a.createdDate > b.createdDate
      ? -1
      : 1,
  rcreatedDate: (a, b) =>
    a.createdDate === b.createdDate
      ? 0
      : a.createdDate < b.createdDate
      ? -1
      : 1,
  projectName: (a, b) => a.projectName.localeCompare(b.projectName),
  rprojectName: (a, b) => a.projectName.localeCompare(b.projectName) * -1,
  modelName: (a, b) => a.modelName.localeCompare(b.modelName),
  rmodelName: (a, b) => a.modelName.localeCompare(b.modelName) * -1
};

class IncrementStore {
  sortByOptions = {
    createdDate: EN.CreatedDate + '∧',
    rcreatedDate: EN.CreatedDate + '∨',
    projectName: EN.ProjectName + '∧',
    rprojectName: EN.ProjectName + '∨',
  };

  @observable id;
  @observable watchingList = false
  @observable increments = [];
  @observable incrementLists = [];//updateSetting use
  @observable incrementProjects = [];
  @observable currentId;
  @observable evalStatus = 0;
  @observable partialStatus = 0;
  @observable incrementResult = false;

  @observable updateFrequency = '';
  @observable updateDataSource = '';

  @observable fileData = [];

  @action changeFileData = (val) => {
    this.fileData = val
  };

  @action change = (name) => (val) => {
    this[name] = val;
  };

  @observable sortOptions = {
    keywords: '',
    sortBy: 'createdDate',
    perPage: 10,
    currentPage: 1
  };

  perPageOptions = {
    // 2: '2',
    5: '5',
    10: '10',
    20: '20'
  };

  @computed
  get totalCount() {
    return this.filter(this.sortOptions.keywords, this.incrementProjects).length;
  }


  constructor() {
    socketStore.ready().then((api: any) => {
      this.initWatch()
      api.addEventListener('online', this.initWatch)
      api.addEventListener('offline', this.onOffline)
    })
  }

  initWatch = () => {
    when(
      () => userStore.status === 'login',
      () => this.initProject('')
    );
  }

  initProject = (projectId) =>{
    socketStore.ready().then((api: any) => {
      this.evalStatus = 0;
      this.partialStatus = 0;
      // const callback = action((response: any) => {
      //   this.increments = response.list.map(({ model_name:modelName,...itm })=>{
      //     return {
      //       ...itm,
      //       modelName,
      //     }
      //   });
      // })
      // api.watchIncrements({
      //   projectId
      // }).then(callback);
      // api.addEventListener('watchIncrements', callback)
      ////////////////////////////////////////////////////
      const evalCallBack = action((response: any) => {
        const {status,trigger:{error='error'}} = response;
        if(status === -1&&!this.evalStatus){
          this.evalStatus = error;
        }
      })
      api.addEventListener('modelEval', evalCallBack)
      ////////////////////////////////////////////////////
      const partialCallBack = action((response: any) => {
        const {status,trigger:{error='error'}, message} = response;
        if(this.partialStatus||this.incrementResult){
          return
        }
        if(status === -1&&!this.evalStatus){
          this.partialStatus = error;
          notification['error']({
            message: EN.IncrementalTraining,
            description: `[${message.errorCode}]${message.msg}`,
            top:50,
            duration:5
          });
          setTimeout(()=>{
            this.partialStatus = 0;
          },5000);
        }else if(status === 201){
          this.incrementResult = true;
          notification['success']({
            message: EN.IncrementalTraining,
            description:'您当前模型增量训练已完成',
            top:50,
            duration:5
          });
          setTimeout(()=>{
            this.incrementResult = false
          },5000);
        }
      })
      api.addEventListener('partialTrain', partialCallBack)
    });
  }

  getIncrementsLists = (ids:any= this.increments.map(itm=>itm.id))=>{
    socketStore.ready().then((api: any) => {
      const callback = action((response: any) => {
        this.incrementLists = response.list.map(itm=>{
          itm.modelName = itm.increment.model_name;
          itm.cutoff = itm.increment.cutoff;
          Reflect.deleteProperty(itm,'increment')
          return itm;
        });
      })
      api.getIncrementsLists({
        ids,
      }).then(callback);
    });
  }

  // Evaluation = (data)=>{
  //   return socketStore.ready().then(api => api.modelEval({
  //     ...data,
  //     modelID:this.increments.map(itm=>itm.id)
  //   }).then(res => {
  //     this.getIncrementsLists();
  //   }));
  // }

  partialTrain = data=>{
    return socketStore.ready().then(api => {
      api.partialTrain(data)
    });
  }


  // @computed
  // get version_edition(){
  //   let version = ''
  //    this.incrementLists.map(itm=>{
  //     version = itm.modelName;
  //   });
  //   return version;
  // }

  @computed
  get sortedIncrementProjects() {
    const _increments = this.incrementProjects.slice();
    let result;

    // keywords
    result = this.filter(this.sortOptions.keywords, _increments)
        .sort(sortStrategies[this.sortOptions.sortBy]);

    // pagination
    const start =
      (this.sortOptions.currentPage - 1) *
      parseInt(this.sortOptions.perPage.toString(), 10);
    const end = start + parseInt(this.sortOptions.perPage.toString(), 10);
    result = result.slice(start, end);

    return result;
  }

  onOffline = action(() => {
    this.watchingList = false;
  })

  @action
  delete(preDelete,projectId) {
    // const list = data.map(itm=>{
    //   const {key:id,modelName,} = itm;
    //   return {
    //     id,
    //     modelName,
    //     projectId,
    //   }
    // })
    socketStore.ready().then((api: any) => api.removeIncrement({
      ...preDelete,
      projectId,
    }));
  }

  @action
  projectDelete(projectId,deploymentId) {
    socketStore.ready().then((api: any) => api.removeIncrementProjects({ projectId,deploymentId }));
  }

  @action
  changeSort = (key, value) => {
    this.sortOptions[key] = value;
  };

  filter(keywords, ments) {
    if (!keywords || keywords === '') return ments;
    const result: any[] = [];
    ments.map((_d, index) => {
      const _results = keywords
        .split(' ')
        .map(
          word =>
            (_d.projectName && _d.projectName.indexOf(word) >= 0) ||
            (_d.userId && _d.userId.indexOf(word) >= 0) ||
            (_d.modelName && _d.modelName.indexOf(word) >= 0)
        );
      if (_results.indexOf(true) !== -1) result.push(_d);
      return true;
    });
    return result;
  };


  @computed
  get sortedTrainings() {
    // const _trainings = this.increments.slice();
    // let result = this.filter(this.sortOptions.keywords, _trainings)
    //   .sort(sortStrategies[this.sortOptions.sortBy]);

    // pagination
    // const start =
    //   (this.sortOptions.currentPage - 1) *
    //   parseInt(this.sortOptions.perPage.toString(), 10);
    // const end = start + parseInt(this.sortOptions.perPage.toString(), 10);
    // return result.slice(start, end);
    return this.increments.slice();
  }

  async addIncrement(list) {
    const projectId = list[0][0];
    const data = list.map(itm=>{
      const [, projectName, modelName, modelType, mapHeader, bucket, bigData,score,scoreTypes,model_id,cutoff,supportIncrement,versions] = itm;
      return {
        incrementOptions: {},
        modelName,
        modelType,
        projectId,
        projectName,
        mapHeader,
        bucket,
        bigData,
        performanceOptions: {},
        score,
        scoreTypes,
        model_id,
        cutoff,
        supportIncrement,
        versions,
      }
    })
    const api: any = await socketStore.ready();
    // const checkResponse = await api.getProjectIncrement({ projectId })
    // if (checkResponse.incrementId) {
    //   api.updateIncrementModel({ incrementId: checkResponse.incrementId, modelName, modelType, bucket, mapHeader, bigData })
    //   return checkResponse.incrementId
    // }
    const response = await api.addIncrement({ data });
    if (response.status !== 200) {
      throw new Error(response.message);
    }
    return response.id;
  }


  async findByIds(ids) {

    socketStore.ready().then((api: any) => {
      api.findByIds({ ids }).then(res => {
        return res.response
      })
    })
  }

}

export default new IncrementStore();
export { IncrementStore };

