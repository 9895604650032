import React, { useContext, useEffect, useState, ReactElement } from 'react';
import styles from '../styles.module.css';
import { MobXProviderContext, observer } from 'mobx-react';
import EN from '../../../../../constant/en';
import ModelTable from './ModelTable';
import Project from 'stores/Project';
import Model from 'stores/Model';
import { ProgressStore } from 'stores/ProgressStore';
interface Interface {
  project: Project;
  models: Model[];
  sort: {
    key: string;
    value: number;
  };
  handleSort: (s: string) => void;
}

function RegressionView(props: Interface):ReactElement {
  // @ts-ignore
  const {
    progressStore: {
      progresses,
      getPrejectTrainProgresses
    },
  }:{
    progressStore:ProgressStore
  } = useContext(MobXProviderContext);
  
  const {
    project: {
      setSelectModel,
      upIsHoldout,
      train2Finished,
      trainModel,
      abortTrain,
      selectModel: current,
      isAbort,
      recommendModel,
      mapHeader,
      target,
      newVariable,
      stopIds,
      dataViews,
      bigData,
    },
    models,
    project,
    sort,
    handleSort,
  } = props;
  const onSelect = model => {
    setSelectModel(model.id);
    upIsHoldout(false);
  };

  useEffect(() => {
    getPrejectTrainProgresses(stopIds)
  }, []);

  const [allStopIds, setAllStopIds] = useState([]);

  useEffect(() => {
    let _stopIds = []
    stopIds.map(x => {
      if (progresses[x])
        _stopIds.push({key: x , value: progresses[x]})
      else _stopIds.push({key: x , value: {data: {requestId: x}}})
    });
    setAllStopIds(_stopIds);
  }, [stopIds, progresses]);
  
  if (!current) return null;
  const currentPerformance = current
    ? (current.chartData.roc_auc.macro > 0.8 && EN.GOOD) ||
      (current.chartData.roc_auc.macro > 0.6 && EN.OK) ||
      EN.NotSatisfied
    : '';
  const newMapHeader: any = {
    ...(Array.isArray(mapHeader) ? mapHeader.reduce((prev, v, k) => Object.assign(prev, { [k]: v }), {}) : mapHeader),
    ...newVariable.reduce((prev, v) => Object.assign(prev, { [v]: v }), {}),
  };

  return (
    <>
      <div className={styles.result}>
        <div className={styles.box}>
          <div className={styles.title}>
            <span>{EN.RecommendedAModel}</span>
          </div>
          <div className={styles.row}>
            <span>{EN.ModelingResult}: </span>
            <div className={styles.status}>
              &nbsp;&nbsp;{currentPerformance}
            </div>
          </div>
          <div className={styles.row}>
            <span>
              {EN.SelectedModel} :
              <a className={styles.nohover}>&nbsp;{current.modelName}</a>
            </span>
          </div>
          <div className={styles.row}>
            <span>
              {EN.Target} :
              <a className={styles.nohover}>&nbsp;{mapHeader[target]}</a>
            </span>
          </div>
          <div className={styles.row}>
            <span>
              {EN.MultiUnique} :
              <a className={styles.nohover}>
                &nbsp;{dataViews[target].uniqueValue}
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.line} />
      <ModelTable
        models={models}
        current={current}
        onSelect={onSelect}
        train2Finished={train2Finished}
        trainModel={trainModel}
        abortTrain={abortTrain}
        isAbort={isAbort}
        project={project}
        recommendId={recommendModel.id}
        sort={sort}
        handleSort={handleSort}
        mapHeader={newMapHeader}
        stopIds={stopIds}
        bigData={bigData}
        allStopIds={allStopIds}
      />
    </>
  );
}
export default observer(RegressionView);
