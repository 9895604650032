import React, { Component } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { CaretRightFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { autorun, observable } from 'mobx';
import { Table } from 'components/Common';
import EN from '../../../../constant/en';

import dataIcon from './data.svg';
import { formatNumber } from '../../../../util';
import Project from 'stores/Project';

interface PreviewProps {
  visiable: boolean,
  project: Project,
  hideTable: () => void,
  showTable: () => void
}

@observer
export default class Preview extends Component<PreviewProps> {
  @observable cleanData = []
  @observable newVariableData = []
  @observable loading = false

  constructor(props) {
    super(props)
    const { etlIndex, fetchData, readFile } = props.project;
    readFile(etlIndex).then(data => {
      this.cleanData = data
    })
    autorun(() => {
      if (!props.project.newVariablePath) return
      this.loading = true
      readFile(props.project.newVariablePath).then(data => {
        this.loading = false
        this.newVariableData = data
        // this.cleanData = data
      })
      // fetchData(props.project.newVariablePath).then(data => {
      //   this.loading = false
      //   this.newVariableData = data
      // })
    })
  }

  formatTable = () => {
    const { cleanData, newVariableData } = this
    const { visiable, project } = this.props
    const { colType, renameVariable, trainHeader, newVariable, newType, rawHeader, dataHeader, target, problemType } = project;
    if (!visiable) return []
    if (this.loading) return []
    if (!cleanData.length) return []
    if (!!newVariable.length && !newVariableData.length) return []
    const isUn = ['Clustering', 'Outlier'].includes(problemType)
    const variables = rawHeader.filter(_h => dataHeader.includes(_h) && _h !== target)
    if (!isUn && !!target) variables.unshift(target)
    const headerList = [...variables, ...newVariable].filter(h => !trainHeader.includes(h))
    // const showIndex = headerList.map(v => [...rawHeader, ...newVariable].indexOf(v))

    // const newMapHeader = {
    //   ...(Array.isArray(mapHeader) ? mapHeader.reduce((prev, v, k) => Object.assign(prev, { [k]: v }), {}) : mapHeader),
    //   // ...mapHeader.reduce((prev, v, k) => Object.assign(prev, { [k]: v }), {}),
    //   ...newVariable.reduce((prev, v) => Object.assign(prev, { [v]: v }), {})
    // }
    // const notShowIndex = rawHeader.filter(v => !headerList.includes(v))
    // const targetIndex = target ? headerList.indexOf(target) : -1
    const data = cleanData.map((row, index) => ({...row,...newVariableData[index]}));

    const types = { ...colType, ...newType }

    const realColumn = headerList.length
    /**
     * 根据showSelect, indexPosition变化
     * showSelect: true  显示勾选框
     * checkRow: 勾选框的行数
     * headerRow: 标题的行数
     * selectRow: 类型选择的行数
     * columnHeader: 表头的列数
     * rowHeader: 表头的行数
     */
    const indexArr = []
    const headerArr = []
    const selectArr = []
    for (let i = 0; i < realColumn; i++) {
      const header = headerList[i] || '';
      const headerText = header //newMapHeader[header]
      indexArr.push({
        content: <span>{i + 1}</span>,
        title: i + 1,
        cn: styles.cell
      })

      headerArr.push({
        content: <span>{headerText}</span>,
        title: headerText,
        cn: styles.titleCell
      })

      const colValue = types[header] === 'Numerical' ? 'Numerical' : 'Categorical'
      selectArr.push({
        content: <span>{colValue === 'Numerical' ? EN.Numerical : EN.Categorical}</span>,
        title: colValue === 'Numerical' ? EN.Numerical : EN.Categorical,
        cn: styles.cell
      })
    }

    const tableData = data.map(row => headerList.map(h => {
      // row[h]
      let v = row[h];
      if (h === target) v = renameVariable[v] || v;
      v = v === 'nan' ? '(other)' : v;
      return {
        content: <span>{formatNumber(v, 2)}</span>,
        title: formatNumber(v, 2),
        cn: styles.cell
      }
    }));

    return [indexArr, headerArr, selectArr, ...tableData]
  }

  render() {
    const { project, visiable, hideTable, showTable } = this.props
    const { dataHeader, target, trainHeader, newVariable, mapHeader, problemType } = project
    const isUn = ['Clustering', 'Outlier'].includes(problemType)
    const variables = [...dataHeader.filter(h => h !== target), ...newVariable]
    if (!isUn && target) variables.unshift(target)
    const header = variables.filter(v => !trainHeader.includes(v))
    const tableData = this.formatTable()
    return (
      <div className={classnames(styles.content, {
        [styles.active]: visiable
      })}>
        <div className={classnames(styles.icon,styles.desc)}
             data-shu = {`${visiable ? EN.CloseDataTable : EN.ViewDataTable}`}
             onClick={visiable ? hideTable : showTable}>
          {<Tooltip title={`${visiable ? EN.CloseDataTable : EN.ViewDataTable}`} mouseLeaveDelay={0}>
            <img src={dataIcon} alt={"view"} />
          </Tooltip>}
          {/* {!this.visiable && <span >View Data Table</span>} */}
        </div>
        <div className={styles.arrow}>{<CaretRightFilled style={{ transform: `rotate(${visiable ? 0 : 180}deg)` }} />}</div>
        <div className={styles.header}>
          {(!isUn && !!target) && <div className={styles.text}><span>{EN.TargetVariable}:</span><span className={styles.value} title={mapHeader[target]}>{mapHeader[target]}</span></div>}
          <div className={styles.text}><span>{EN.TotalVariables}:</span><span className={styles.value} title={header.length.toString()}>{header.length}</span></div>
        </div>
        <div className={styles.table}>
          <Table
            columnWidth={110}
            rowHeight={34}
            columnCount={header.length}
            rowCount={tableData.length}
            fixedColumnCount={0}
            fixedRowCount={3}
            style={{ border: "1px solid #ccc" }}
            data={tableData}
          />
        </div>
      </div>
    );
  }
}
