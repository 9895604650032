import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import styles from './Forecasting.module.css'
import Project from 'stores/Project';
import { DensityPlot, Lines } from '../../../Charts';

import { Checkbox, InputNumber, Button, Table, Popover, Spin } from 'antd';
import { toJS } from 'mobx';
import { formatNumber } from '../../../../util'
import { observer, useObserver, useLocalStore } from 'mobx-react';
import EN from '../../../../constant/en';
import _ from 'lodash';

interface Interface {
  project: Project,
  next: () => void
}

const Variable = (props: Interface): ReactElement => {
  const {
    project: {
      totalLines,
      forcastingSsp,
      colType,
      orderIndex: [oi],
      stationaryPlotData,
      dataViews,
      target,
      orderIndex,
      mapHeader,
      getDensityPlot,
      densityPlotData,
      adfData,
      readFile
    },
    next,
  } = props;
  let time = false;
  if (oi) {
    time = colType[oi] === 'Datetime';
  }

  const store = useLocalStore(() => ({
    preSelect: ['Original', 'Rolling mean', 'Rolling std', 'Weighted mean'],
    window: 10,
    updateField(data) {
      Object.assign(this, data);
    },
  }));

  useEffect(() => {
    !densityPlotData && getDensityPlot();
  }, []);

  const [lineData, upLineData] = useState(null);
  const [densityData, upDensityData] = useState(null);
  const [adf, upAdf] = useState([]);

  useEffect(() => {
    if (stationaryPlotData) {
      readFile(stationaryPlotData).then(lineData => {
        upLineData(lineData);
      })
    }
  }, [stationaryPlotData]);

  useEffect(() => {
    if (densityPlotData) {
      readFile(densityPlotData).then(densityData => {
        upDensityData(densityData);
      });
    }

    if (adfData) {
      readFile(adfData).then(({ confidence, ...adf }) => {
        const data = {...confidence,...adf};

        Object.keys(data).forEach(itm=>{
          data[itm] = data[itm].toFixed(3);
        });

        upAdf([{
          ...data,
          t: 'ADF',
          key: '1',
        }]);
      })
    }
  }, [densityPlotData, adfData]);

  const Line = useMemo(() => {
    if (lineData) {
      const [origin, rollingMean, rollingStd, weightedMean] = ['Original', 'Rolling mean', 'Rolling std', 'Weighted mean'].map(itm => store.preSelect.includes(itm));

      return <Lines
        width={1000}
        height={400}
        dataZoom
        selected={{
          [EN['Original']]:origin,
          [EN['Rolling mean']]:rollingMean,
          [EN['Rolling std']]:rollingStd,
          [EN['Weighted mean']]:weightedMean,
        }}
        x_names={{
          origin:EN['Original'],
          rollingMean:EN['Rolling mean'],
          rollingStd:EN['Rolling std'],
          weightedMean:EN['Weighted mean'],
        }}
        time={time}
        data={lineData} />
    }
    return <div style={{
      height: 400,
      width:1000,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }} >
      <Spin size="large" />
    </div>
  }, [lineData, store.preSelect]);
  const line_max = parseInt(String(totalLines * 0.3));

  useEffect(()=>{
    if(line_max<10){
      store.updateField({
        window : line_max
      })
    }
    !stationaryPlotData && forcastingSsp(toJS(store.preSelect), store.window);
  },[line_max]);

  // useEffect(() => {

  // }, []);

  const p_columns = [
    {
      title: EN.StatisticsTest,
      dataIndex: 't',
      key: 't',
    },
    {
      title: EN.StatisticsValue,
      dataIndex: 'adf',
      key: 'adf',
    },
    {
      title: EN.PValue,
      dataIndex: 'pValue',
      key: 'pValue',
    },
    {
      title: EN.Thresholds + '1%',
      key: '1%',
      dataIndex: '1%',
    },
    {
      title: EN.Thresholds + '5%',
      key: '5%',
      dataIndex: '5%',
    },
    {
      title: EN.Thresholds + '10%',
      key: '10%',
      dataIndex: '10%',
    },
  ];
  const l_columns = [
    {
      title: 'Column',
      dataIndex: 'Column',
      key: 'Column',
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      render: data => {
        const [text, show] = data.split(',');
        return <>
          {text}
          {show === 'true' && <Popover
            placement='topRight'
            getPopupContainer={el => el.parentElement}
            content={<DensityPlot data={densityData} />} trigger="click">
            <a>&nbsp;{EN.disp}</a>
          </Popover>}
        </>;
      }
    },
    {
      title: 'Min',
      dataIndex: 'min',
      key: 'Min',
    },
    {
      title: 'Q25',
      key: 'Q25',
      dataIndex: 'q25',
    },
    {
      title: 'Median',
      key: 'Median',
      dataIndex: 'median',
    },
    {
      title: 'Mean',
      key: 'Mean',
      dataIndex: 'mean',
    },
    {
      title: 'Q75',
      key: 'Q75',
      dataIndex: 'q75',
    },
    {
      title: 'Max',
      key: 'Max',
      dataIndex: 'max',
    },
    {
      title: 'Std',
      key: 'Std',
      dataIndex: 'std',
    },
  ];

  const Type = [colType[target], true];
  const Numerical = Type[0] === 'Numerical';

  let data: any = {
    key: -1,
    Column: mapHeader[target],
    Type,
  };

  if (Numerical) {
    data = {
      ...data,
      ...dataViews[target],
    }
  } else {
    data = {
      ...data,
      std: 'N/A',
      max: 'N/A',
      min: 'N/A',
      q75: 'N/A',
      mean: 'N/A',
      median: 'N/A',
      q25: 'N/A',
    }
  }

  const l_data: any = [data];

  orderIndex.map((itm, index) => {
    const Type = [colType[itm], false];
    const Numerical = Type[0] === 'Numerical';
    let data: any = {
      key: index,
      Column: mapHeader[itm],
      Type,
    };

    if (Numerical) {
      data = {
        ...data,
        ...dataViews[itm],
      }
    } else {
      data = {
        ...data,
        std: 'N/A',
        max: 'N/A',
        min: 'N/A',
        q75: 'N/A',
        mean: 'N/A',
        median: 'N/A',
        q25: 'N/A',
      }
    }

    l_data.push(data);
  });

  const ldata = l_data.map(r => {
    const rowData = {};
    Object.entries(r).forEach(([k, v]) => {
      rowData[k] = formatNumber((v || 'N/A').toString())
    });
    return rowData
  });

  function update(){
    forcastingSsp(toJS(store.preSelect), store.window);
    upLineData(null)
  }


  return useObserver(() => <div className={styles.content}>
    <section className={styles.vs}>
      <div style={{ whiteSpace: "nowrap", textAlign: 'left' }}>
        {EN.PCTPM}
        <section className={styles.check}>
          <Checkbox.Group
            value={store.preSelect}
            onChange={preSelect => store.updateField({ preSelect })}
          >
            {
              ['Original', 'Rolling mean', 'Rolling std', 'Weighted mean'].map(itm => <Checkbox key={itm} value={itm}>{EN[itm]}</Checkbox>)
            }
          </Checkbox.Group>
        </section>
        {EN.WindowSize}: <InputNumber
          min={2}
          max={line_max}
          value={Math.min(store.window, line_max)}
          onChange={window => store.updateField({ window })}
        />

        <div className={styles.inline}>
          <div className={styles.button} onClick={update}>
            <span>{EN.ShowStationaryPlot}</span>
          </div>
          &nbsp;
          <Button
            size='large'
            onClick={() => {
              store.updateField({
                preSelect: ['Original', 'Rolling mean', 'Rolling std', 'Weighted mean'],
                window: _.min([10,line_max])
              });
              update();
            }}>{EN.Reset}</Button>
        </div>
      </div>
      <div>
        {Line}
        <Table
          pagination={false}
          columns={p_columns}
          dataSource={adf} />
      </div>
    </section>

    <section className={styles.box}>
      <Table
        pagination={false}
        columns={l_columns}
        dataSource={ldata} />
    </section>

    <div className={styles.button}
      onClick={next}
      style={{ margin: "1rem 0" }}>
      <span>{EN.Continue}</span>
    </div>
  </div>);
};

export default observer(Variable)
