import React, { ReactElement } from 'react';
import ReactEcharts from 'echarts-for-react';
import styles from './charts.module.css';

interface Interface {
  width?: number;
  height?: number;
	used:number
	all:number|string
	title:string
	text?:string
}
export default function PIE_USER(props: Interface): ReactElement {
  let {
    width = 200,
    height = 200,
    used,
    all,
	  title,
	  text,
  } = props;
  const INF = isNaN(+all);
  const data = [];
  if(INF){
	  // text = '';
    all=100;
    used=0;
  }else{
    data.push({
      value: INF?0:Math.max(0,used),
      name: 'Used',
      itemStyle: {
        color: '#8f969e',
      },
    });
  }
  data.push({
    value: INF?1:Math.max(0,+all-used),
    name: 'Remaining',
    itemStyle: {
      color: '#1d2b3c',
    },
  });

  const option:any =  {
    tooltip: {
      trigger: 'item',
	    formatter: "{a} <br/>{b}: {c} ({d}%)"
    },
    series: [
      {
      	name:title,
        type: 'pie',
	      startAngle: -90 + used/+all*180,
	      silent:INF,
        data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  return <div className={styles.pie}>
	  <ReactEcharts
		  option={option}
		  style={{ height, width }}
		  notMerge={true}
		  lazyUpdate={true}
	  />
	  <dl>
		  <dt>{title}</dt>
		  <dd>{text||`${props.used}/${INF?'Unlimited':all}`}</dd>
	  </dl>
  </div>
}
