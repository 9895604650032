import React, { useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';

export default function Loading(){
  const chart = useRef(null);

  useEffect(()=>{
    const _chart = chart.current.getEchartsInstance();
    _chart.showLoading();
  },[]);

  const option:any =  {
    xAxis: {},
    yAxis: {},
  };
  return <ReactEcharts
    option={option}
    ref={chart}
    style={{height:'100%', width:'100%'}}
    notMerge={true}
    lazyUpdate={true}
    theme="customed"
  />
}
