import { MobXProviderContext, observer } from 'mobx-react';
import React, {
  HTMLAttributes,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import styles from './styles.module.css';
import EN from '../../constant/en';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import en_US from 'antd/lib/locale-provider/en_US';
// import { Select } from 'components/Common';
import {
  Input,
  Pagination,
  Select,
  ConfigProvider,
  Row,
  DatePicker,
  Button,
  Col,
  Space,
} from 'antd';
import { PlusOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { HttpRequestRes, ProblemType } from '@app/constant/types';
import { ProjectStore } from '@app/stores/ProjectStore';
import { useDebounceEffect, useRequest } from 'ahooks';
import { request } from '@app/utils';
import moment from 'moment';

const { Option } = Select;

interface Interface extends HTMLAttributes<HTMLDivElement> {
  addProject?: () => void
}

const Tools: React.FC<Interface> = ({ addProject }) => {
  // @ts-ignore
  const {
    projectStore: {
      projectListFilters,
      changeOption,
      resetProjectListFilters,
      queryProjectList,
    },
  }: {
    projectStore: ProjectStore;
  } = useContext(MobXProviderContext);
  const [keywords, setKeywords] = useState<string>(projectListFilters.keywords);
  const [filterDate, setFilterDate] = useState<null | [moment.Moment | null, moment.Moment | null]>(null);

  const handleSearch = () => {
    queryProjectList();
  };

  // 延迟更新filetes
  useDebounceEffect(
    () => {
      changeOption('keywords', keywords);
    },
    [keywords],
    {
      wait: 500,
    },
  );

  const tabsRequest = useRequest(
    () =>
      request
        .get<HttpRequestRes<string>>('/api-v2/tabInuseList')
        .then(res => res.data.data),
  );
  return (
    <>
      <Row style={{ marginBottom: '1rem', fontSize: '10px' }}>
        <Col span={3}>
          <Input.Search
            allowClear
            style={{ width: '95%' }}
            placeholder={EN.SearchProject}
            value={keywords}
            onChange={e => {
              setKeywords(e.target.value);
            }}
            onSearch={handleSearch}
          />
        </Col>
        <Col span={3}>
          <Select
            allowClear
            style={{ width: '95%' }}
            value={projectListFilters.from}
            placeholder={EN.ProjectSource}
            onChange={value => changeOption('from', value)}
          >
            <Option value="all">{EN.AllProject}</Option>
            <Option value="our">{EN.MyProject}</Option>
            <Option value="their">{EN.SharedProject}</Option>
          </Select>
        </Col>
        <Col span={3}>
          <Select
            allowClear
            value={projectListFilters.isSpark}
            placeholder={EN.TrainingMethod}
            style={{ width: '95%' }}
            onChange={value => changeOption('isSpark', value)}
          >
            <Option value="all">{EN.AllMethod}</Option>
            <Option value="spark">{EN.Distributed}</Option>
            <Option value="python">{EN.NonDistributed}</Option>
          </Select>
        </Col>
        <Col span={3}>
          <Select
            allowClear
            placeholder={EN.AlgorithmType}
            value={projectListFilters.problemType}
            style={{ width: '95%' }}
            onChange={value => changeOption('problemType', value)}
          >
            <Option value="all">{EN.AllAlgorithm}</Option>
            <Option value={ProblemType.Classification}>
              {EN.Classification}
            </Option>
            <Option value={ProblemType.MultiClassification}>
              {EN.MultiClassification}
            </Option>
            <Option value={ProblemType.Regression}>{EN.Regression}</Option>
            <Option value={ProblemType.Clustering}>{EN.Clustering}</Option>
            <Option value={ProblemType.Outlier}>{EN.Outlier}</Option>
            <Option value={ProblemType.Association}>{EN.Association}</Option>
            <Option value={ProblemType.Forecasting}>{EN.Forecasting}</Option>
          </Select>
        </Col>
        <Col span={4}>
          <Select
            allowClear
            placeholder={EN.ProjectTab}
            value={projectListFilters.tabName}
            style={{ width: '95%' }}
            onChange={value => changeOption('tabName', value)}
            options={tabsRequest.data?.map(n => ({ label: n, value: n }))}
          ></Select>
        </Col>
        <Col span={4}>
          <ConfigProvider locale={(window as any).r2_env.isEN ? en_US : zh_CN}>
            <DatePicker.RangePicker
              allowClear
              style={{ width: '95%' }}
              disabledDate={(current) => {
                if (filterDate && filterDate[0] && current) {
                  return current.diff(filterDate[0], 'months') >= 6 || current > moment();
                }
                return current > moment();
              }}
              onCalendarChange={(data) => {
                setFilterDate(data);
              }}
              onChange={value => changeOption('createDate', value)}
              value={projectListFilters.createDate}
            />
          </ConfigProvider>
        </Col>
        <Col span={4}>
          <Button icon={<RedoOutlined />} onClick={resetProjectListFilters}>
            {EN.Reset}
          </Button>
        </Col>
        {addProject && <Button
          icon={<PlusOutlined />}
          type="primary"
          style={{
            position: 'absolute',
            right: '0.8%',
          }}
          onClick={() => addProject()}
        >
          {EN.CreateNewProject}
        </Button>}
      </Row>
    </>
  );
};
export default observer(Tools);
