import React, { ReactElement, useContext, useEffect, useMemo, useRef } from 'react';
import styles from './styles.module.css';
import { observer, MobXProviderContext } from 'mobx-react';
import { ProjectSide } from 'components/Common';
import { Route, Switch, useHistory } from 'react-router-dom';
import DataConnect from './Connect';
import DataSchema from './Schema';
import DataQuality from './Quality';
import { ProjectStore } from 'stores/ProjectStore';

const Data = observer((): ReactElement => {
  const {
    projectStore: {
      project: {
        curStep,
        subStepActive,
        mainStep,
        lastSubStep,
        noCompute,
        nextSubStep,
        updateProject,
        problemType,
      },
      project,
    },
  } = useContext(MobXProviderContext);
  const history = useHistory();
  const sideRef = useRef(null);
  useEffect(() => {
    if (!project) return;
    if (curStep !== 2) return;
    let url = '';
    // if (project.bigData) {
    //   url = `/project/${project.id}/data/connect`;
    // } else {
      switch (subStepActive) {
        case 1:
          url = `/project/${project.id}/data/connect`;
          break;
        case 2:
          url = `/project/${project.id}/data/schema`;
          break;
        case 3:
          url = `/project/${project.id}/data/quality`;
          break;
        default:
      }
    // }

    if (!url) return history.push('/');
    if (!history.location.pathname.startsWith(`/project/${project.id}/data`))
      return;
    if (history.location.pathname.includes(url)) return;
    return history.replace(url);
  }, [subStepActive]);

  useEffect(() => {
    sideRef.current && sideRef.current.reset();
  }, [sideRef.current]);

  const enter = step => {
    if (step === subStepActive) return false;

    let maxStep = noCompute ? 2 : mainStep > 2 ? (problemType === 'Association' ? 2 : 3) : lastSubStep;

    if (step > maxStep) return false;

    return updateProject(nextSubStep(step, 2));
  };

  // const _Project = useMemo(() => {
  //   return <Switch>
  //     <Route
  //       exact
  //       path="/project/:id/data/connect"
  //       component={props => <DataConnect {...props} />}
  //     />
  //     {<Route
  //       exact
  //       path="/project/:id/data/schema"
  //       component={props => <DataSchema {...props} />}
  //     />}
  //     {<Route
  //       exact
  //       path="/project/:id/data/quality"
  //       component={props => <DataQuality {...props} />}
  //     />}
  //   </Switch>
  // }, []);

  if (!project) {
    console.log('project not ')
    return null;
  }
  return (
    <div className={styles.data}>
      <Switch>
        <Route
          exact
          path="/project/:id/data/connect"
          component={DataConnect}
        />
        <Route
          exact
          path="/project/:id/data/schema"
          component={DataSchema}
        />
        <Route
          exact
          path="/project/:id/data/quality"
          component={DataQuality}
        />
      </Switch>
      <ProjectSide
        enter={enter}
        project={project}
        keyword="data"
        ref={sideRef}
      />
    </div>
  );
});

export default Data;
