import { observer, useLocalStore } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';
import { Modal } from 'antd';
import EN from '../../../constant/en';
import styles from './Start.module.css';
import SimplifiedView from './Supervised/SimplifiedView';
import AdvancedView from './Supervised/AdvancedView';
import UnSimplifiedView from './UnSupervised/SimplifiedView';
import UnAdvancedView from './UnSupervised/AdvancedView';
import classnames from 'classnames'
import Preview from './Preview'
import { Show } from 'components/Common';
import Project from 'stores/Project';
import { ProjectRolesKey } from '@app/constant/types';

interface Interface {
  project: Project
  closeAdvanced: () => void
}

function AdvancedModel(props:Interface){
  const { project, closeAdvanced } = props;
  const store = useLocalStore(() => ({
    tab: 1,
    visiable: false,
    setting: {
      // name: `custom.${moment().format('MM.DD.YYYY_HH:mm:ss')}`,
      name: `custom_${moment().format('YYYYMMDD_HHmmss')}`,
      id: uuidV4(),
      setting: project.newSetting(),
      models: [],
    },
    updateField(data) {
      Object.assign(this, data);
    },
    selectedSetting: project.settingId ? project.settingId : 'default'
  }));
  const switchTab = num => {
    if (num !== 1 && num !== 2) return false;
    store.updateField({
      tab:num
    })
  };

  const [modal,setModal] = useState(false) as any

  const modeling = () => {
    const {
      advancedModeling,
      version,
      algorithms,
      dataHeader,
      newVariable,
      trainHeader,
      customHeader,
      informativesLabel,
      sparkAlgorithms,
      target,
      bigData
    } = project;
    const allVariables = [...dataHeader, ...newVariable];
    const checkedVariables = allVariables.filter(
      v => !trainHeader.includes(v) && v !== target,
    );
    const key = [allVariables, informativesLabel, ...customHeader]
      .map(v => v.sort().toString())
      .indexOf(checkedVariables.sort().toString());
    const hasNewOne = key === -1;
    if (!store.setting.name) {
      const config = {
        title: EN.DataException,
        content: EN.settingNameRequired,
        onOk:()=>setModal(false)
      }
      if(modal){
        modal.update(config);
      }else{
        const _modal = Modal.error(config)
        setModal(_modal)
      }
    }
    if (hasNewOne) project.customHeader.push(checkedVariables);
    const _version = new Set(version)
    const sortFn = (a, b) => a > b ? 1 : -1;
    if (!bigData) {
      if (!!algorithms.length) {
        _version.add('c')
        if (algorithms.includes('xgradient_boosting'))
          _version.add('x')
      } else {
        _version.delete('c')
      }
    } else {
      if (!!sparkAlgorithms.length) {
        _version.add('s')
      } else {
        _version.delete('s')
      }
    }
    project.version = [...new Set([..._version])].sort(sortFn)
    if (!project.version.length) {
      const config = {
        title: EN.DataException,
        content: EN.Youneedtoselectatleast,
        onOk:()=>setModal(false)
      }
      if(modal){
        return modal.update(config);
      }else{
        const _modal = Modal.error(config)
        return setModal(_modal)
      }
    }
    project.settings.push({ ...store.setting });
    project.settingId = store.setting.id;
    closeAdvanced();
    advancedModeling();
  };

  const showTable = () => {
    store.updateField({
      visiable:true
    })
  };

  const hideTable = () => {
    store.updateField({
      visiable:false
    })
  };

  const setSetting = setting => {
    Object.entries(setting).forEach(([key, value]) => {
      // this.setting.setting[key] = value;
      store.updateField({
        setting:{
          ...store.setting,
          setting:{
            ...store.setting.setting,
            [key]:value,
          }
        }
      })
    });
  };

  const setSettingName = name => {
    store.updateField({
      setting:{
        ...store.setting,
        name,
      }
    })
  };

  const setSelectedSetting = id => {
    store.updateField({
      selectedSetting: id
    })
  };

    const { dataHeader, newVariable, trainHeader, target, problemType } = project;
    const allVariables = [...dataHeader, ...newVariable];
    const checkedVariables = allVariables.filter(
      v => !trainHeader.includes(v) && v !== target,
    );
    const isUnsupervised = ['Clustering', 'Outlier'].includes(problemType);

    return (
      <div className={styles.advancedModel}>
        <div className={styles.advancedContent}>
          {!store.visiable ? <div className={styles.tabBox}>
            <div
              className={classnames(styles.tab, {
                [styles.active]: store.tab === 1,
              })}
              onClick={switchTab.bind(null, 1)}
            >
              <span>
                {EN.AdvancedVariable} {EN.Setting}
              </span>
            </div>
            <div
              className={classnames(styles.tab, {
                [styles.active]: store.tab === 2,
              })}
              onClick={switchTab.bind(null, 2)}
            >
              <span>
                {EN.AdvancellcedModeling} {EN.Setting}
              </span>
            </div>
          </div> : <div className={classnames(styles.tabBox, styles.nonTabBox)}>
          </div>}
          <div className={styles.viewBox}>
            <Preview
              project={project}
              visiable={store.visiable}
              showTable={showTable}
              hideTable={hideTable}
            />
            <div style={store.tab === 1 ? {} : { opacity: 0, position: 'absolute', top: -9999, left: -9999 }}>
              {
                isUnsupervised ? <UnSimplifiedView project={project} /> : <SimplifiedView project={project} />
              }
            </div>
            <div style={store.tab === 2 ? {} : { opacity: 0, position: 'absolute', top: -9999, left: -9999 }}>
              {
                isUnsupervised ? <UnAdvancedView
                  project={project}
                  hidden={store.visiable || store.tab === 1}
                  setting={store.setting}
                  selectedSetting={store.selectedSetting}
                  setSetting={setSetting}
                  setSettingName={setSettingName}
                  setSelectedSetting={setSelectedSetting}
                /> : <AdvancedView
                  project={project}
                  hidden={store.visiable || store.tab === 1}
                  setting={store.setting}
                  selectedSetting={store.selectedSetting}
                  setSetting={setSetting}
                  setSettingName={setSettingName}
                  setSelectedSetting={setSelectedSetting}
                />
              }
            </div>
           
          
            <div className={styles.bottom}>
              <Show
                name={ProjectRolesKey.StartAdvancedModeling}
              >
                <button
                  className={classnames(styles.save, {
                    [styles.disable]: !checkedVariables.length,
                  })}
                  onClick={!checkedVariables.length ? null : modeling}
                >
                  <span>{EN.Modeling}</span>
                </button>
              </Show>
              <button className={styles.cancel} onClick={closeAdvanced}>
                <span>{EN.Cancel}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}

export default observer(AdvancedModel)
