import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Hint, ProcessLoading } from 'components/Common';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Popover, Switch, Spin, Modal } from 'antd';
import histogramIcon from './histogramIcon.svg';
import { formatNumber } from '../../../../util';
import EN from '../../../../constant/en';
import CreateNewVariables from '../../../CreateNewVariable';
import SimplifiedViewPlot from './SimplifiedViewPlot';
import SimplifiedViewRow from './SimplifiedViewRow';
import CorrelationPlot from './CorrelationPlot';
import NewVariableView from '../../../CreateNewVariable/NewVariableView';
import store from '../../../CreateNewVariable/NewVariableStore';
import { Show } from '../../../Common';
import Project from 'stores/Project';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ProjectRolesKey } from '@app/constant/types';

interface Interface {
  project: Project;
}
function SimplifiedView(props: Interface): ReactElement {
  const {
    project: {
      preTrainImportance,
      colType,
      target,
      mapHeader,
      correlationMatrixData: correlationMatrixS3,
      renameVariable,
      preImportance,
      preImportanceLoading,
      addNewVariable2,
      newType,
      newVariableViews,
      id,
      expression,
      totalLines,
      importanceProgress,
      targetUnique,
      problemType,
      dataViews,
      dataHeader,
      trainHeader,
      newVariable,
      informativesLabel,
      customHeader,
      IV,
      histgramPlots,
      readFile,
      histgramPlot,
      univariatePlot,
      univariatePlots,
      correlationMatrix,
      bigData
    },
    project,
  } = props;
  const [sort, upSort] = useState(-1);
  const [showHistograms, upShowHistograms] = useState(false);
  const [showCorrelation, upShowCorrelation] = useState(false);
  const [visible, upVisible] = useState(false);
  const [chartData, upChartData] = useState({});
  const [result, upResult] = useState({});
  const [correlationMatrixData, upCorrelationMatrixData] = useState({} as any);
  const [IsIV, upIsIV] = useState(false);
  const [maxImportance, upMaxImportance] = useState(null);
  useEffect(() => {
    (async ()=>{project.init&& await reloadTable()})();
  }, [project.init]);

  useEffect(()=>{
    if(!dataViews){
      project.init = false
      project.initProject()
    }
  },[dataViews])

  useEffect(() => {
    if (!!correlationMatrixS3) {
      readFile(correlationMatrixS3).then((obj: any) => {
        const fields = Object.entries(colType)
          .filter(
            itm =>
              itm[1] !== 'Raw' &&
              itm[0] !== target &&
              !(itm[1] === 'Categorical' && dataViews[itm[0]].uniqueValue === 1),
          )
          .map(itm => itm[0])
          .filter(itm => !trainHeader.includes(itm) && dataHeader.includes(itm))
          .map(itm => mapHeader[itm]);
        const { header, data } = obj;
        upCorrelationMatrixData({
          data,
          header: header.map(itm => mapHeader[itm]),
          fields,
          target: mapHeader[target],
        })
      });
    } else {
      upCorrelationMatrixData({
        data: null,
        header: [],
        fields: [],
        target: ''
      })
    }
  }, [correlationMatrixS3])

  const show = async () => {
    // debugger;
    if (!chartData[target]) {
      const S3Data = await readFile(histgramPlots[target]);
      if (colType[target] === 'Numerical') {
        //histogram-numerical
        const { data, ...rest } = S3Data.data;
        showback(target, data, rest);
      } else {
        //histogram-categorical
        showback(target, S3Data.data)
      }
      return;
    }

    upShowHistograms(true);
  };

  const showback = (target, result, message?: any) => {
    upChartData({
      ...chartData,
      [target]: result,
    });
    upResult({
      ...result,
      [target]: message,
    });
    upShowHistograms(true);
  };

  const hide = e => {
    e && e.stopPropagation();
    upShowHistograms(false);
  };

  const sortImportance = () => {
    upSort(sort * -1);
  };

  const showCorrelationMatrix = () => {
    // const colType = toJS(project.colType);
    // const trainHeader = toJS(project.trainHeader);
    // const dataHeader = toJS(project.dataHeader);

    // const fields = Object.entries(colType)
    //   .filter(
    //     itm =>
    //       itm[1] !== 'Raw' &&
    //       itm[0] !== target &&
    //       !(itm[1] === 'Categorical' && dataViews[itm[0]].uniqueValue === 1),
    //   )
    //   .map(itm => itm[0])
    //   .filter(itm => !trainHeader.includes(itm) && dataHeader.includes(itm))
    //   .map(itm => mapHeader[itm]);

    upShowCorrelation(true);

    // if (!correlationMatrixData.data) {
    //   readFile(correlationMatrixS3).then((correlationMatrixData: any) => {
    //     const { header, data } = correlationMatrixData;
    //     upCorrelationMatrixData({
    //       data,
    //       header: header.map(itm => mapHeader[itm]),
    //       fields,
    //       target: mapHeader[target],
    //     });
    //     upShowCorrelation(true);
    //   });
    // } else {
    //   upCorrelationMatrixData({
    //     ...correlationMatrixData,
    //     fields,
    //   });
    //   upShowCorrelation(true);
    // }
  };

  const hideCorrelationMatrix = e => {
    e && e.stopPropagation();
    upShowCorrelation(false);
  };

  const handleCheck = (key, e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      project.trainHeader = trainHeader.filter(v => v !== key);
    } else {
      // 校验变量数量，不可为0
      const checkedVariables = [...dataHeader, ...newVariable].filter(
        v => ![...trainHeader, key].includes(v) && v !== target,
      );
      if (checkedVariables.length === 0) {
        // 直接返回提示
        return Modal.error({
          title: EN.AtleastOneVariableMsg,
        });
      }
      if (!trainHeader.includes(key)) {
        project.trainHeader = [...trainHeader, key];
      }
    }
  };

  const showNewVariable = async () => {
    upVisible(true);
  };

  const hideNewVariable = () => {
    upVisible(false);
    return reloadTable();
  };

  const reloadTable = async () => {
    console.log('reloadTable')
    await preTrainImportance();
    await histgramPlot();
    await univariatePlot();
    await correlationMatrix()
  };

  const handleChange = e => {
    const value = e.target.value;
    let filterList = [];
    if (!value) return;
    if (value === 'all') {
      filterList = [...dataHeader, ...newVariable];
    }
    if (value === 'informatives') {
      filterList = informativesLabel;
    }
    if (!isNaN(value) && value < customHeader.length) {
      filterList = customHeader[value];
    }
    project.trainHeader = [...dataHeader, ...newVariable].filter(
      v => !filterList.includes(v) && v !== target,
    );
  };

  const renderCell = (value, isNA) => {
    if (isNA) return 'N/A';
    if (isNaN(+value)) return value || 'N/A';
    return formatNumber(value, 2);
  };

  const targetUniques = targetUnique || NaN;
  const targetData:any = dataViews&&dataViews[target] || {};
  const allVariables = [
    ...dataHeader.filter(h => h !== target),
    ...newVariable,
  ];
  const variableType = { ...newType, ...colType };
  Reflect.deleteProperty(variableType, target);
  const newVariableType = dataHeader.filter(h => h !== target).reduce((prev, h) => ({ ...prev, [h]: colType[h] }), {})
  Reflect.deleteProperty(newVariableType, target);
  const checkedVariables = allVariables.filter(v => !trainHeader.includes(v));
  const key = [allVariables, [...informativesLabel], ...customHeader]
    .map(v => v.sort().toString())
    .indexOf(checkedVariables.sort().toString());
  const hasNewOne = key === -1;
  const selectValue = hasNewOne
    ? customHeader.length
    : key === 0
      ? 'all'
      : key === 1
        ? 'informatives'
        : key - 2;
  const newMapHeader: any = {
    ...(Array.isArray(mapHeader) ? mapHeader.reduce((prev, v, k) => Object.assign(prev, { [k]: v }), {}) : mapHeader),
    // ...mapHeader.reduce((prev, v, k) => Object.assign(prev, { [k]: v }), {}),
    ...newVariable.reduce((prev, v) => Object.assign(prev, { [v]: v }), {}),
  };
  const _import = IsIV ? IV : preImportance;
  const hLength = Object.keys(histgramPlots).length
  const hFinish = Object.values(histgramPlots).filter(v => !!v).length
  const uLength = Object.keys(univariatePlots).length
  const uFinish = Object.values(univariatePlots).filter(v => !!v).length
  const process = (preImportanceLoading ? importanceProgress / (bigData ? 1 : 2)
      : (bigData ? 100 : 50)) + (bigData ? 0 : (!uLength ? 25 : (uFinish / uLength * 25)) + (!hLength ? 25 : (hFinish / hLength * 25)))
  console.log('process',process)

  let content = EN.AdvancedModelingImportanceTip;

  switch (problemType){
    case 'Regression':
    case 'MultiClassification':
      content += bigData?'按信息增益由大到小降序排列。信息增益表示已知该预测变量的信息后，目标变量不确定性减少的程度。':'按互信息由大到小降序排列。互信息表示目标变量由于已知该预测变量的信息而减少的不确定性。'
      break;
    case 'Classification':
      if(bigData)
        content += '按信息增益由大到小降序排列。信息增益表示已知该预测变量的信息后，目标变量不确定性减少的程度。'
      break;
  }

  return (
    <div className={styles.simplified} style={{ zIndex: visible ? 3 : 1 }}>
      <div className={styles.targetTable}>
        <div className={styles.targetHead}>
          <div className={classnames(styles.targetCell, styles.targetName)}>
            <span>{EN.TargetVariable}</span>
          </div>
          {!bigData && <div className={styles.targetCell}>
            <span>{EN.Histogram}</span>
          </div>}
          <div className={styles.targetCell}>
            <span>{EN.DataType}</span>
          </div>
          <div className={styles.targetCell}>
            <span>{EN.Mean}</span>
          </div>
          <div className={styles.targetCell}>
            <span>{EN.UniqueValue}</span>
          </div>
          <div className={styles.targetCell}>
            <span>{EN.Min}</span>
          </div>
          <div className={styles.targetCell}>
            <span>{EN.Max}</span>
          </div>
        </div>
        <div className={styles.targetRow}>
          <div
            className={classnames(styles.targetCell, styles.targetName)}
            title={mapHeader[target]}
          >
            <span>{mapHeader[target]}</span>
          </div>
          {!bigData && <div className={styles.targetCell} onClick={show}>
            <img
              src={histogramIcon}
              className={styles.tableImage}
              alt="histogram"
            />
            <Popover
              placement="bottomLeft"
              visible={showHistograms}
              onVisibleChange={hide}
              trigger="click"
              title={
                <CloseOutlined
                  style={{
                    float: 'right',
                    height: 23,
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={hide} />
              }
              content={
                <SimplifiedViewPlot
                  type={colType[target]}
                  target={mapHeader[target]}
                  result={result[target]}
                  data={chartData[target]}
                  renameVariable={renameVariable}
                  across={problemType === 'MultiClassification'}
                />
              }
            />
          </div>}
          <div className={styles.targetCell}>
            <span>
              {colType[target] === 'Numerical' ? EN.Numerical : EN.Categorical}
            </span>
          </div>
          <div
            className={classnames(styles.targetCell, {
              [styles.none]: colType[target] === 'Categorical',
            })}
            title={renderCell(
              targetData.mean,
              colType[target] === 'Categorical',
            )}
          >
            <span>
              {renderCell(targetData.mean, colType[target] === 'Categorical')}
            </span>
          </div>
          <div
            className={classnames(styles.targetCell, {
              [styles.none]: colType[target] !== 'Categorical',
            })}
          >
            <span>{isNaN(targetUniques) ? 'N/A' : targetUniques}</span>
          </div>
          <div
            className={classnames(styles.targetCell, {
              [styles.none]: colType[target] === 'Categorical',
            })}
            title={renderCell(
              targetData.min,
              colType[target] === 'Categorical',
            )}
          >
            <span>
              {renderCell(targetData.min, colType[target] === 'Categorical')}
            </span>
          </div>
          <div
            className={classnames(styles.targetCell, {
              [styles.none]: colType[target] === 'Categorical',
            })}
            title={renderCell(
              targetData.max,
              colType[target] === 'Categorical',
            )}
          >
            <span>
              {renderCell(targetData.max, colType[target] === 'Categorical')}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.simplifiedText}>
        <span>{EN.CreateVariableListTip}</span>
      </div>
      <div className={styles.tool}>
        <div className={styles.toolSelect}>
          <div className={styles.toolLabel}>
            <span>{EN.CurrentVariableList}</span>
          </div>
          <select value={selectValue} onChange={handleChange}>
            <option value="all">
              {EN.AllVariables} ({allVariables.length})
            </option>
            {!bigData && <option value="informatives">
              {EN.Informatives} ({informativesLabel.length})
            </option>}
            {customHeader.map((v, k) => (
              <option key={k} value={k}>
                {EN.Custom}
                {k + 1} ({v.length})
              </option>
            ))}
            {hasNewOne && (
              <option value={customHeader.length}>
                custom_{customHeader.length + 1} ({checkedVariables.length})
              </option>
            )}
          </select>
        </div>
        <div className={styles.newVariable}>
          <Show name={ProjectRolesKey.AdvancedModelingConfig}>
            <div className={styles.toolButton} onClick={showNewVariable}>
              <span>{EN.CreateANewVariable}</span>
            </div>
          </Show>
          <CreateNewVariables
            open={visible}
            title={EN.CreateANewVariable}
            onClose={hideNewVariable}
            addNewVariable={addNewVariable2}
            variables={newVariableType}
            expression={expression}
            mapHeader={newMapHeader}
          />
          {/* { visible &&
            <Dialog
              style={{ zIndex: 900 }}
              aria-labelledby={'customized-dialog-title'}
              scroll={'body'}
              disableBackdropClick={true}
              open={true}
              fullWidth={true}
              maxWidth={'lg'}
              onClose={hideNewVariable}
            >
              <DialogTitle disableTypography className={styles.root}>
                <Typography variant="h6" className={styles.colorWhite}>
                  {EN.CreateANewVariable}
                </Typography>
                <IconButton
                  aria-label="Close"
                  className={styles.closeButton}
                  onClick={hideNewVariable}
                  href={''}
                >
                <CloseIcon fontSize="small" />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <NewVariableView
                  onClose={hideNewVariable}
                  variables ={newVariableType as any}
                  mapHeader={newMapHeader}
                  expression={expression}
                  addNewVariable={addNewVariable2}
                  functions= {store.functions}
                />
              </DialogContent>
              <DialogActions />
            </Dialog> 
          } */}
          {/* <Modal
            width={'80em'}
            title={EN.CreateANewVariable}
            visible={visible}
            footer={null}
            centered
            onCancel={hideNewVariable}
            >
              <NewVariableView
                  onClose={hideNewVariable}
                  variables ={newVariableType as any}
                  mapHeader={newMapHeader}
                  expression={expression}
                  addNewVariable={addNewVariable2}
                  functions= {store.functions}
                />
          </Modal> */}
        </div>
        {!bigData && <div
          className={classnames(styles.toolButton, styles.toolCheck)}
          onClick={showCorrelationMatrix}
        >
          {showCorrelation && (
            <Popover
              placement="left"
              visible={showCorrelation}
              onVisibleChange={hideCorrelationMatrix}
              trigger="click"
              content={
                !correlationMatrixData.data ? <Spin /> : <CorrelationPlot
                  onClose={hideCorrelationMatrix}
                  CorrelationMatrixData={correlationMatrixData}
                />
              }
            />
          )}
          <span>{EN.CheckCorrelationMatrix}</span>
        </div>}
      </div>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <div className={classnames(styles.tableTh, styles.tableCheck)} />
          <div className={styles.tableTh}>
            <span>{EN.Name}</span>
          </div>
          {!bigData && <div className={styles.tableTh}>
            <span>{EN.Histogram}</span>
          </div>}
          {!bigData && <div className={styles.tableTh}>
            <span>{EN.UnivariantPlots}</span>
          </div>}
          <div className={classnames(styles.tableTh, styles.tableImportance)}>
            <div className={styles.tableSort} onClick={sortImportance}>
              <span>
                <LegacyIcon
                  type={`arrow-${sort === 1 ? 'up' : 'down'}`}
                  theme="outlined"
                />
              </span>
            </div>
            <span>{EN.Importance}</span>
            <Hint
              themeStyle={{ fontSize: '1rem' }}
              content={content}
            />
            {
              !bigData&&problemType === 'Classification' && <>
                <span style={{ paddingLeft: 10 }}>MI</span>
                <Hint
                    themeStyle={{ fontSize: '1rem' }}
                    content={EN.SortinDescendingOrderBasedonDecreasingInformation}
                />
                <Switch
                  checked={IsIV}
                  onChange={() => upIsIV(!IsIV)}
                  size="small"
                  style={{
                    margin: '0 10px 0 10px',
                    backgroundColor: '#243448',
                  }}
                />
                <span>IV</span>
                <Hint
                    themeStyle={{ fontSize: '1rem' }}
                    content={EN.SortByDecreasingInformationValues}
                />
              </>
            }
          </div>
          <div className={styles.tableTh}>
            <span>{EN.DataType}</span>
          </div>
          <div className={styles.tableTh}>
            <span>{EN.UniqueValue}</span>
          </div>
          <div className={styles.tableTh}>
            <span>{EN.Mean}</span>
          </div>
          <div className={styles.tableTh}>
            <span>{EN.STD}</span>
          </div>
          <div className={styles.tableTh}>
            <span>{EN.Median}</span>
          </div>
          <div className={styles.tableTh}>
            <span>{EN.Min}</span>
          </div>
          <div className={styles.tableTh}>
            <span>{EN.Max}</span>
          </div>
        </div>
        {(bigData && process < 100) ? (
          <div className={styles.tableLoading}>
            <LoadingOutlined />
          </div>
        ) : (
            <div className={styles.tableBody}>
              {allVariables
                .sort((a, b) => {
                  return _import
                    ? sort * ((_import[a] || 0) - (_import[b] || 0))
                    : 0;
                }).map((h, i) => {
                  if (h === target) return null;
                  const data = { ...dataViews, ...newVariableViews }[h] || {};
                  const importance = _import ? _import[h] || 0 : 0.01;
                  if (!i && !maxImportance && importance > 1) {
                    upMaxImportance(importance);
                  }
                  const isNew = newVariable.includes(h);
                  return (
                    <SimplifiedViewRow
                      key={i}
                      value={h}
                      data={data}
                      importance={importance}
                      mapHeader={newMapHeader}
                      colType={variableType}
                      project={project}
                      isChecked={checkedVariables.includes(h)}
                      handleCheck={handleCheck.bind(null, h)}
                      lines={Math.min(Math.floor(totalLines * 0.95), 1000)}
                      id={id}
                      isNew={isNew}
                      isIV={IsIV}
                      maxImportance={maxImportance}
                    />
                  );
                })}
            </div>
          )}
      </div>
      {bigData&&process < 100 && (
        <ProcessLoading
          progress={process}
          style={{ bottom: '0.25em' }}
        />
      )}
    </div>
  );
}
export default observer(SimplifiedView);
