import React, { ReactElement } from 'react';

//修改表头
interface Interface {
  value: string;
}
export default function EditHeader(props: Interface):ReactElement {
  const { value } = props;
  return <span>{value}</span>;
}
