import React, { useState } from 'react';
import Lines from './Lines'
import { InputNumber, Spin } from 'antd';
import styles from '../Modeling/Start/TimeSeries/Forecasting.module.css';
import { QQPlot } from './index';

interface Interface {
	data:any
	time:boolean
	freq
	upFreq:(number)=>void
	qqData:any
	freq_max:number
}

export default function SeasonalDecompose(props:Interface){
	if(!props.data){
		return <Spin>
			<div style={{width:500,height:350}}/>
		</Spin>
	}
	const {data:{index,origin,seasonal,trend,residual},time=false,upFreq,qqData,freq_max} = props;



	const [freq,upF] = useState(props.freq);

	return <section>
		<div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'left'
		}}>
			Seasonal: &nbsp;&nbsp;<InputNumber
			min={2}
			max={freq_max}
			defaultValue={freq} onChange={upF}/>&nbsp;&nbsp;
			<div className={styles.button}
			     style={{lineHeight:0.8}}
			     onClick={upFreq.bind(this,freq)}>
				<span>Save</span>
			</div>
		</div>
		<div style={{
			display:'flex',
			flexWrap:"wrap",
      justifyContent: 'center'
    }}>
			<Lines
				data={{
					index,
					origin,
				}}
				time={time}
			/>
			<Lines
				data={{
					index,
					seasonal
				}}
				time={time}
			/>
		</div>

		<div style={{
			display:'flex',
			flexWrap:"wrap",
      justifyContent: 'center'
		}}>
			<Lines
				data={{
					index,
					trend
				}}
				time={time}
			/>

			<Lines
				data={{
					index,
					residual
				}}
				time={time}
			/>
			<QQPlot data={qqData} />
		</div>
	</section>
}
