import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Form, Input, Button, Checkbox, Slider, Select, FormProps
} from 'antd';

const { Option } = Select;

interface Props extends FormProps {
  predictOptions: Array<string>,
  target: string,
}


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 4,
    },
  },
};


export const DependentOptionForm: React.FunctionComponent<Props> = observer(({
  children,
  predictOptions,
  target,
  ...formProps
}) => {

  return (
    <Form
      {...formItemLayout}
      {...formProps}
    >
      <Form.Item
        label="预测变量"
        name="featureLabel"
      >
        <Select>
          {predictOptions.map(option => (
            <Option key={option} value={option}>{option}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="目标变量"
        name="target"
      >
        <Select disabled>
          <Option key={target} value={target}>{target}</Option>
        </Select>
      </Form.Item>

      <Form.Item  {...tailFormItemLayout} style={{ marginBottom: 0 }}>
        {children}
      </Form.Item>
    </Form>
  );
});

export default DependentOptionForm;
