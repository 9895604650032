import React, { useContext, useEffect, useState, ReactElement } from 'react';
import styles from '../styles.module.css';
import { MobXProviderContext, observer } from 'mobx-react';
import EN from '../../../../../constant/en';
import Performance from './Performance';
import ModelTable from './ModelTable';
import { PVA } from '../../../../Charts';
import Project from 'stores/Project';
import Model from 'stores/Model';
import { ProgressStore } from 'stores/ProgressStore';
interface Interface {
  project: Project;
  models: Model[];
  sort: {
    key: string;
    value: number;
  };
  handleSort: (s: string) => void;
}

function RegressionResult(props: Interface):ReactElement {
  const {
    project: {
      setSelectModel,
      upIsHoldout,
      train2Finished,
      trainModel,
      abortTrain,
      selectModel: current,
      isAbort,
      recommendModel,
      mapHeader,
      target,
      newVariable,
      stopIds,
      bigData,
    },
    models,
    project,
    sort,
    handleSort,
  } = props;

  // @ts-ignore
  const {
    progressStore: {
      progresses,
      getPrejectTrainProgresses
    },
  }:{
    progressStore:ProgressStore
  } = useContext(MobXProviderContext);

  const onSelect = model => {
    setSelectModel(model.id);
    upIsHoldout(false);
  };

  const [allStopIds, setAllStopIds] = useState([]);

  useEffect(() => {
    let _stopIds = []
    stopIds.map(x => {
      if (progresses[x])
        _stopIds.push({key: x , value: progresses[x]})
      else _stopIds.push({key: x , value: {data: {requestId: x}}})
    });
    setAllStopIds(_stopIds);
  }, [stopIds, progresses]);

  useEffect(() => {
    getPrejectTrainProgresses(stopIds)
  }, []);

  if (!current) return null;
  const currentPerformance = current
    ? (current.score.validateScore.r2 > 0.5 && EN.Acceptable) ||
      EN.NotAcceptable
    : '';
  const newMapHeader: any = {
    ...(Array.isArray(mapHeader) ? mapHeader.reduce((prev, v, k) => Object.assign(prev, { [k]: v }), {}) : mapHeader),
    // ...mapHeader.reduce((prev, v, k) => Object.assign(prev, { [k]: v }), {}),
    ...newVariable.reduce((prev, v) => Object.assign(prev, { [v]: v }), {}),
  };

  return (
    <>
      <div className={styles.result}>
        <div className={styles.box}>
          <div className={styles.title}>
            <span>{EN.RecommendedAModel}</span>
          </div>
          <div className={styles.row}>
            <span>{EN.ModelingResult}: </span>
            <div className={styles.status}>
              &nbsp;&nbsp;{currentPerformance}
            </div>
          </div>
          <div className={styles.row}>
            <span>
              {EN.SelectedModel} :
              <a className={styles.nohover}>&nbsp;{current.modelName}</a>
            </span>
          </div>
          <div className={styles.row}>
            <span>
              {EN.Target} :
              <a className={styles.nohover}>&nbsp;{mapHeader[target]}</a>
            </span>
          </div>
          <Performance current={current} />
        </div>
        <PVA
          key="pva"
          x_name={EN.PointNumber}
          y_name={
            (window as any).r2_env.isEN
              ? `${EN.Groupaverage} ${mapHeader[target]}`
              : `${mapHeader[target]} ${EN.Groupaverage}`
          }
          model={current}
          project={project}
        />
      </div>
      <div className={styles.line} />
      <ModelTable
        models={models}
        current={current}
        onSelect={onSelect}
        train2Finished={train2Finished}
        trainModel={trainModel}
        abortTrain={abortTrain}
        isAbort={isAbort}
        project={project}
        recommendId={recommendModel.id}
        sort={sort}
        handleSort={handleSort}
        mapHeader={newMapHeader}
        stopIds={stopIds}
        bigData={bigData}
        allStopIds={allStopIds}
      />
    </>
  );
}
export default observer(RegressionResult);
