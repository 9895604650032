import React, { ReactElement, useEffect, useState } from 'react';
import styles from './AdvancedView.module.css';

interface Interface {
  readonly curSelected: any;
  readonly value: any;
  readonly thumbnail: any;
  readonly onClick: any;
}

export default function Thumbnail(props: Interface):ReactElement {
  const {
    curSelected,
    value,
    thumbnail: { selectedIcon, normalIcon, text },
    onClick,
  } = props;
  const [clickActive, upClickActive] = useState(false);

  useEffect(() => {
    upClickActive(curSelected === value);
  }, [curSelected, value]);
  const handleClick = e => {
    e.stopPropagation();
    upClickActive(true);
    onClick(value);
  };
  const icon = clickActive ? selectedIcon : normalIcon;
  return (
    <div className={styles.thumbnail} onClick={handleClick}>
      <img src={icon} alt="icon" />
      <div>{text}</div>
    </div>
  );
}
