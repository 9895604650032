import socketStore from './SocketStore'
import userStore from './UserStore'
import projectStore from './ProjectStore'
import deploymentStore from './DeploymentStore'
import scheduleStore from './ScheduleStore'
import incrementStore from './IncrementStore'
import progressStore from './ProgressStore'

export default {
  socketStore,
  userStore,
  projectStore,
  deploymentStore,
  scheduleStore,
  incrementStore,
  progressStore
};

(window as any).stores = {
  socketStore,
  userStore,
  projectStore,
  deploymentStore,
  scheduleStore,
  incrementStore,
  progressStore
};
