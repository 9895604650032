import Supervised from './Supervised';
import UnSupervised from './UnSupervised';
import Association from './Association';
import TimeSeries from './TimeSeries';
import { Modal } from 'antd';
import styles from './styles.module.css';
import EN from '../../../constant/en';
import { WarningOutlined, CloseOutlined } from '@ant-design/icons';
import { MobXProviderContext, observer } from 'mobx-react';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { ProjectStore } from '../../../stores/ProjectStore';

interface Interface {
  resetSide: () => void;
}

let si;

const Result = (props: Interface): ReactElement => {
  // @ts-ignore
  const {
    projectStore: {
      project: { 
        problemType, 
        clearInvalidModels 
      },
      project,
    },
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext);
	const [lock,upLock] = useState(false);
  const { resetSide } = props;

  useEffect(()=>{
    if (!!project.invalidModels.length && !lock && !project.train2ing) {
      upLock(true);
      const filter_models = project.invalidModels.filter(m=>project.id === m.projectId)
      Modal.info({
        title: EN.ModelBelowExpectationsNotBeShow,
        centered: true,
        closable: true,
        className: styles['modal-info'],
        width: '700px',
        content: (  <>
          <p style={{ maxHeight: '300px', overflowY:'auto' }}>
            { filter_models.map((m, index)=> {
              return <React.Fragment key={index}>{`${m.modelName} ${index === filter_models.length - 1 ? "" : "、"}`}<br></br></React.Fragment> 
            })}
          </p>
        </>),
        okCancel: false,
        mask: false,
        okText: EN.ok,
        icon: <WarningOutlined style={{ color: '#108ee9' }}/>
      });
      clearInvalidModels(project.id);
      upLock(false);
    }
  },[project.train2ing])

  useEffect(() => {
    clearInterval(si)
    si = setInterval(()=> {
      if(project?.stopIds?.length){
        // project?.checkModels();
      }else{
        clearInterval(si)
      }
    },5000)
    return ()=>clearInterval(si)
  }, [project?.id,project?.stopIds?.length])

  return useMemo(()=>{
    const isAssociation = problemType === 'Association';
    const isTimeSeries = ['Forecasting', 'Survival', 'Prediction'].includes(problemType)
    const isUnsupervised = ['Clustering', 'Outlier'].includes(problemType);

    if (isTimeSeries) return <TimeSeries />;
    if (isAssociation) return <Association project={project} />;
    return isUnsupervised ? (
        <UnSupervised resetSide={resetSide} />
    ) : (
        <Supervised resetSide={resetSide} />
    );
  },[problemType])
};

export default observer(Result);
