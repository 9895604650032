import React, { ReactElement, useState } from 'react';
import { useIsMounted } from 'react-tidy';
import { observer } from 'mobx-react';
import styles from './styles.module.css';
import { Modal, Button, Row, Col } from 'antd';
import databaseIcon from './icon-database.svg';
import socketStore from 'stores/SocketStore';
import EN from '../../../constant/en';
import DBConfigForm from './DBConfigForm';
import { DBHistoryPopover } from './DBHistoryPopover';
import { ConnectData } from './types';

const storage = window.localStorage;

const defaultState: ConnectData = {
  sqlHostName: '',
  sqlPort: '',
  databaseType: 'mysql',
  sqlDatabase: '',
  sqlTable: '',
  sqlQueryStr: '',
  sqlEncoding: 'utf8',
  sqlUserName: '',
  sqlPassword: '',
  rememberMyPassword: false,
  rememberMyConnectionProfile: false,
  isCopy: true,
  auth_type: 'NONE',
};

const DatabaseConfigModal: React.FC<any> = ({ visible, ...props }) => {
  return (
    <Modal
      className={styles.modal}
      closable={false}
      visible={visible}
      footer={null}
      width={600}
      destroyOnClose={true}
    >
      <DatabaseConfig {...props} />
    </Modal>
  );
};

function DatabaseConfig(props): ReactElement {
  const [loading, setLoading] = useState(false);
  const [passTest, setPassTest] = useState(false);
  const isMounted = useIsMounted();
  const {
    onClose,
    mapHeader = {},
    onSubmit: submit,
    title,
    projectId,
    bigData,
    isOutput,
    skipTableCheck,
    options,
    sparkCheck,
    connectLocation, // 数据导入位置
  } = props;

  const getDefaultValues = () => {
    let storedProfile: any = storage.getItem('DatabaseConnectionProfile');
    const storedPassword = storage.getItem('DatabaseConnectionPassword');

    try {
      storedProfile = JSON.parse(storedProfile) || {};
    } catch (e) {
      storedProfile = {};
      storage.setItem('DatabaseConnectionProfile', '{}');
    }

    const filter = obj =>
      obj && typeof obj === 'object'
        ? Object.keys(obj).reduce((prev, curr) => {
            if (defaultState[curr] === undefined) return prev;
            if (obj[curr] === undefined) return prev;
            return { ...prev, [curr]: obj[curr] };
          }, {})
        : {};

    return {
      ...defaultState,
      databaseType: bigData ? 'gbase' : 'mysql',
      ...filter(storedProfile),
      sqlPassword: storedPassword || '',
      ...filter(options),
    };
  };

  const [formData, setFormData] = useState(getDefaultValues());

  const handleImportDatabase = (values: any) => {
    submit({ ...values, mapHeader });
  };
  const handleOnSelect = (data: ConnectData) => {
    console.log('#####data', data);
    setFormData({
      sqlHostName: data.sqlHostName,
      sqlPort: data.sqlPort,
      databaseType: data.databaseType,
      sqlDatabase: data.sqlDatabase,
      sqlTable: data.sqlTable,
      sqlQueryStr: data.sqlQueryStr,
      sqlEncoding: data.sqlEncoding,
      sqlUserName: data.sqlUserName,
      sqlPassword: data.sqlPassword,
      rememberMyPassword: data.rememberMyPassword,
      rememberMyConnectionProfile: data.rememberMyConnectionProfile,
      isCopy: data.isCopy,
      auth_type: data.auth_type,
    });
    if (passTest) return setPassTest(false);
  };
  const handleSqlTest = async (values: any) => {
    setLoading(true);
    const api = await socketStore.ready();
    const {
      status,
      message,
      errorCode,
      result,
      request,
    } = await api.databaseTest({
      sqlSource: values,
      projectId,
      skipTableCheck: !!skipTableCheck,
      bigData,
      action: connectLocation,
    });

    if (!isMounted()) {
      return;
    }

    setLoading(false);
    if (status !== 100) {
      Modal.error({
        centered: true,
        title: errorCode ? `[${errorCode}]` : '连接失败!',
        content:
          message.length > 200 ? message.substr(0, 200) + '...' : message,
        okText: EN.ok,
      });
      return;
    } else if (
      result?.rowsCount === 0 &&
      ['train', 'deploySource', 'performanceSource', 'increment'].includes(
        connectLocation,
      )
    ) {
      Modal.error({
        title: '连接失败!',
        content: `数据表'${request.sqlSource?.sqlTable}'为空.`,
      });
      return;
    }

    setPassTest(true);
  };

  return (
    <React.Fragment>
      <div className={styles.title}>
        <img
          className={styles.databaseIcon}
          alt="database"
          src={databaseIcon}
        />
        {title}
      </div>

      <DBConfigForm
        isOutput={isOutput}
        // sparkCheck={sparkCheck}
        initialValues={formData}
        disabled={loading || passTest}
        // showHbase={!bigData}
        onFinish={values => {
          if (!passTest) {
            handleSqlTest(values);
            return;
          }

          handleImportDatabase(values);
          return;
        }}
      >
        {/* { loading &&
        <Row style={{ paddingBottom: 12 }}>
            <Col sm={24} style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{  alignSelf: 'center' }}>{EN.EstimatedFiveMinutes}</span>
            </Col>
        </Row>
      } */}
        <Row style={{ paddingBottom: 48 }}>
          <Col sm={8} style={{ textAlign: 'right' }}>
            <Button
              style={{ marginRight: 24 }}
              type="primary"
              size={'large'}
              ghost
              onClick={onClose}
            >
              {EN.CANCEL}
            </Button>
          </Col>
          <Col sm={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              htmlType="submit"
              type="primary"
              size={'large'}
              loading={loading}
            >
              {passTest ? EN.ImportDatabase : EN.ConnectTest}
            </Button>

            <DBHistoryPopover
              trigger="click"
              onSelect={handleOnSelect.bind(this)}
            >
              <a style={{ marginLeft: 'auto' }}>{EN.ConnectHistory}</a>
            </DBHistoryPopover>
          </Col>
        </Row>
      </DBConfigForm>
    </React.Fragment>
  );
}
export default observer(DatabaseConfigModal);
