import React, { useContext, useEffect, useMemo } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { Confirm, ProcessLoading, ContinueButton } from 'components/Common';
import { MobXProviderContext, observer, useLocalStore } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { toJS } from 'mobx';
import EN from '../../constant/en';
import Bar from './Bar';
import Project from './Project';
import Tools from './Tools';
import { ProjectStore } from 'stores/ProjectStore';
import { UserStore } from 'stores/UserStore';
import { DeleteOutlined } from '@ant-design/icons';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import en_US from 'antd/lib/locale-provider/en_US';
import {
  Modal,
  Select,
  Popconfirm,
  Empty,
  List,
  message,
  ConfigProvider,
  Pagination,
} from 'antd';
import moment from 'moment';
import addProjectIcon from './combined-shape-copy.svg';
import btnSearch from './btn_search.svg';

const { Option } = Select;

function Home() {
  // @ts-ignore
  const {
    projectStore: {
      clean,
      deleteProjects,
      addProject,
      projectListFilters,
      total,
      changeOption,
      changePage,
      list,
      getUserList,
      userList = [],
      getRoleList,
      roleList,
      addUserToProject,
      removeUserFromProject,
      updateRoleAboutProject,
      queryProjectList,
    },
    userStore: { getAllUsers, getRoleList: getRoles, allUser, roleList: roles },
    userStore,
  }: {
    projectStore: ProjectStore;
    userStore: UserStore;
  } = useContext(MobXProviderContext);
  const history = useHistory();

  const owners = roles.find(item => item.name === 'Project Owners');

  const _allUser = useMemo(() => {
    const _userList = userList.map(itm => itm.email);
    return allUser.filter(itm => !_userList.includes(itm.email));
  }, [userList, allUser]);

  const _userList = useMemo(() => {
    return userList.filter(itm => itm.userId !== userStore.info.id && itm);
  }, [userList]);

  const store = useLocalStore(() => ({
    ids: [],
    isShow: false,
    selected: false,
    deleteNames: [],
    deleteIds: [],
    visible: false,
    loading: false,
    filter: '',
    share: {
      id: null,
    },
    add: {
      role: undefined,
      user: undefined,
    },
    updateField(data) {
      Object.assign(this, data);
    },
  }));

  let acts: {
    share: (ids) => void;
    duplicate: (ids) => void;
    delete: (ids) => void;
  };
  acts = {
    share: async ([id]) => {
      await getUserList(id);
      store.updateField({
        share: {
          id,
        },
      });
      return;
    },
    duplicate: () => {
      return;
    },
    delete: ids => {
      const willDeleteList = list.filter(prd => ids.includes(prd.id));
      const deployedProjectsIds = willDeleteList
        .filter(prd => prd.deployment)
        .map(p => p.id);
      if (deployedProjectsIds.length > 0) {
        return message.warning(
          `${EN.Project}ID${deployedProjectsIds.join(',')} ${EN.HasBeenDeployedAndCannotBeDeleted}`,
        );
      }
      store.updateField({
        deleteNames: willDeleteList.map(
          project => project.name || 'project#' + project.id,
        ),
        deleteIds: ids,
        visible: true,
      });
    },
  };

  useEffect(() => {
    clean();
    getAllUsers();
    getRoles();
    getRoleList();
    queryProjectList();
  }, []);

  const selectId = (isSelect, id) => {
    let ids;
    if (isSelect) {
      ids = store.ids.filter(v => v !== id);
    } else {
      ids = [...store.ids, id];
    }
    store.updateField({
      ids,
      isShow: !!ids.length,
    });
  };
  const removeSelected = () => {
    store.updateField({
      ids: [],
      isShow: false,
    });
  };

  const actions = (act, ids) => {
    ids = toJS(ids);
    if (!Array.isArray(ids)) ids = [ids];
    acts[act](ids);
  };

  const handleOk = () => {
    if (store.loading) return;
    store.updateField({
      loading: true,
      visible: false,
    });
    const { ids, deleteIds } = store;
    deleteProjects(deleteIds).then(() => {
      const newIds = ids.filter(id => !deleteIds.includes(id));
      store.updateField({
        deleteIds: [],
        ids: newIds,
        isShow: !!newIds.length,
        loading: false,
      });
    });
  };

  const handleCancel = () => {
    store.updateField({
      visible: false,
    });
  };
  const handleAdd = () => {
    if (store.loading) return;
    store.updateField({
      loading: true,
    });
    addProject()
      ?.then(data => {
        store.updateField({
          loading: false,
        });
        if (data.error) return alert(data.error);
        history.push(`/project/${data.id}`);
      })
      .catch(error => {
        console.log(error);
      });
  };

  function closeShare() {
    store.updateField({
      share: {},
      add: {
        role: undefined,
        user: undefined,
      },
    });
  }

  return (
    <div className={classnames(styles.home)} style={{ padding: '16px' }}>
      <Modal
        width={1380}
        footer={
          <div className={styles.share_footer}>
            <a onClick={closeShare}>{EN.Close}</a>
          </div>
        }
        title={
          <div className={styles.share_title}>
            {EN.Project} {store.share.id}
            <i onClick={closeShare}>＋</i>
          </div>
        }
        visible={!!store.share.id}
        closable={false}
      >
        <section className={styles.share}>
          <div className={styles.share_add}>
            <div className={styles.share_select_t}>
              <Select
                placeholder={EN.SearchMembers}
                showSearch
                className={styles.share_select}
                showArrow={false}
                onSearch={filter => store.updateField({ filter })}
                value={store.add.user}
                filterOption={(input, option) => {
                  return (
                    String(option.props.children)
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={user => {
                  store.updateField({
                    add: {
                      ...store.add,
                      user,
                    },
                  });
                }}
              >
                {_allUser.map(itm => (
                  <Option key={itm.id} value={itm.email}>
                    {itm.email}
                  </Option>
                ))}
              </Select>
              <img className={styles.share_select_img} src={btnSearch} alt="" />
            </div>

            <Select
              value={store.add.role}
              placeholder={EN.SelectRole}
              className={classnames(styles.share_select, styles.role_select)}
              onChange={role => {
                store.updateField({
                  add: {
                    ...store.add,
                    role,
                  },
                });
              }}
            >
              {roleList
                .filter(itm => itm.name !== 'Project Owners')
                .map(itm => {
                  return (
                    <Option key={itm.id} value={itm.id}>
                      {itm.name}
                    </Option>
                  );
                })}
            </Select>
            <ContinueButton
              disabled={!store.add.role || !store.add.user}
              text={EN.AddMembers}
              onClick={() => {
                addUserToProject(
                  store.share.id,
                  store.add.user,
                  store.add.role,
                );
                store.updateField({
                  add: {
                    user: undefined,
                    role: undefined,
                  },
                });
              }}
              height={40}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                position: 'relative',
                left: -1,
                top: 2,
              }}
            />
          </div>
          {/*<Table*/}
          {/*    scroll={{ y: 250 }}*/}
          {/*    columns={columns}*/}
          {/*    pagination={false}*/}
          {/*    dataSource={_userList} />*/}

          <dl className={styles.share_table}>
            <dt>
              <ul>
                <li>{EN.Members}</li>
                <li>{EN.Identity}</li>
                <li>{EN.JoinedTime}</li>
                <li />
              </ul>
            </dt>
            {_userList.map(itm => {
              return (
                <dd>
                  <ul>
                    <li>{itm.email}</li>
                    <li>
                      <Select
                        className={styles.role}
                        onChange={id => {
                          updateRoleAboutProject(itm.id, id);
                        }}
                        defaultValue={itm.role}
                      >
                        {roleList
                          .filter(itm => itm)
                          .map(itm => {
                            return (
                              <Option key={itm.id} value={itm.id}>
                                {itm.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </li>
                    <li>{moment(itm.created_at).format('YYYY.MM.DD HH:mm')}</li>
                    <li>
                      <Popconfirm
                        onConfirm={() => {
                          removeUserFromProject(store.share.id, itm.id);
                        }}
                        title={EN.ConfirmDeletion}
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </li>
                  </ul>
                </dd>
              );
            })}
          </dl>
          {_userList.length ? '' : <Empty />}
        </section>
      </Modal>
      {store.loading && (
        <ProcessLoading style={{ position: 'fixed' }} spinColor="#fff" />
      )}
      <Tools addProject={handleAdd} />
      <List<any>
        grid={{
          // @ts-ignore
          gutter: [16, 8],
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 5,
        }}
        dataSource={list}
        rowKey="id"
        renderItem={(project) => {
          return (
            <List.Item
              key={project.id + project.visiable}
              style={{ height: '100%' }}
            >
              <Project
                roles={owners}
                project={project}
                selectId={selectId}
                actions={actions}
                key={'project-' + project.id}
                selected={store.ids.includes(project.id)}
              />
            </List.Item>
          );
        }}
      />
      <ConfigProvider locale={(window as any).r2_env.isEN ? en_US : zh_CN}>
        <Pagination
          style={{ textAlign: 'right', marginTop: '5rem' }}
          current={projectListFilters.current}
          pageSize={projectListFilters.limit}
          total={total}
          showSizeChanger
          pageSizeOptions={['5', '10', '20']}
          onChange={(page, pageSize) => {
            changePage(page);
            changeOption('limit', pageSize);
          }}
        />
      </ConfigProvider>
      {store.isShow && (
        <Bar toggleSelect={removeSelected} ids={store.ids} actions={actions} />
      )}
      <Confirm
        width="7em"
        title={EN.DeleteDialogTitle}
        visible={store.visible && !!store.deleteIds.length}
        onClose={handleCancel}
        onConfirm={handleOk}
        confirmText={EN.Yes}
        closeText={EN.Nop}
        content={
          store.deleteNames.length > 1
            ? `${EN.ConfirmOfDeletManyProject} ${store.deleteNames.map(name => {
                return name.length > 40 ? name.slice(0, 40) + '...' : name;
              })}?`
            : `${EN.Areyousuretodeleteproject} ${store.deleteNames.toString() ||
                '...'}?`
        }
      />
    </div>
  );
}

export default observer(Home);
