import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { formatNumber } from '../../../../util';
import styles from './styles.module.css';
import { CloseOutlined } from '@ant-design/icons';
import { InputNumber, Popover, Spin } from 'antd';
import histogramIcon from './histogramIcon.svg';
import SimplifiedViewPlot from './SimplifiedViewPlot';
import EN from '../../../../constant/en';
import classnames from 'classnames'
import Project from 'stores/Project';

interface Interface {
  value: any
  project: Project
  isNew: boolean
  data: any
  colType: any
  weight: any
  isChecked: any
  handleCheck: any
  lines: any
  handleWeight: any
  mapHeader: any
  importance: any
}
@observer
export default class SimplifiedViewRow extends Component<Interface> {
  @observable histograms = false;
  @observable univariant = false;
  @observable chartData = {};
  @observable result = {};

  showHistograms = async () => {
    const { value, project } = this.props;
    const { histgramPlots,readFile } = project;
    if (!this.chartData[value]) {
      const s3Data = await readFile(histgramPlots[value]);
      if (project.colType[value] === 'Numerical') {
        const {data,...rest} = s3Data.data;
          this.showback(data, value, rest)
      } else {
          this.showback(s3Data.data, value);
      }
    } else {
      this.histograms = true;
    }
  };
  showback = (result, value, message?: any) => {
    this.chartData = {
      ...this.chartData,
      [value]: result,
    };
    this.result = {
      ...this.result,
      [value]: message,
    };
    this.histograms = true;
  };

  hideHistograms = e => {
    e && e.stopPropagation();
    this.histograms = false;
  };

  renderCell = (value, isNA) => {
    if (isNA) return 'N/A';
    if (isNaN(+value)) return value || 'N/A';
    return formatNumber(value, 2);
  };

  render() {
    const {
      data,
      colType,
      weight,
      value,
      project,
      isChecked,
      handleCheck,
      lines,
      handleWeight,
      mapHeader,
      importance,
    } = this.props;
    const { histgramPlots, problemType, bigData } = project;
    const valueType =
      colType[value] === 'Numerical' ? 'Numerical' : 'Categorical';
    const isRaw = colType[value] === 'Raw';
    const unique =
      (isRaw && `${lines}+`) ||
      (valueType === 'Numerical' && 'N/A') ||
      data.uniqueValue;

    return (
      <div className={styles.tableRow}>
        <div className={classnames(styles.tableTd, styles.tableCheck)}>
          <input type="checkbox" checked={isChecked} onChange={handleCheck} />
        </div>
        <div className={styles.tableTd} title={mapHeader[value]}>
          <span>{mapHeader[value]}</span>
        </div>
        <div className={styles.tableTd} style={{ borderColor: 'transparent' }}>
          <InputNumber
            value={weight || 1}
            max={99.9}
            min={0.1}
            step={0.1}
            precision={1}
            onChange={handleWeight}
          />
        </div>
        {!bigData && <div
          className={classnames(styles.tableTd, {
            [styles.notAllow]: isRaw,
          })}
          id={'Histograms' + value}
          onClick={this.showHistograms.bind(this, value)}
        >
          <Spin spinning = {!isRaw&&!histgramPlots[value]}>
            <img
              src={histogramIcon}
              className={styles.tableImage}
              alt="histogram"
            />
          </Spin>
          {!isRaw && this.histograms ? (
            <Popover
              placement="right"
              visible={!isRaw && this.histograms}
              overlayClassName="popovers"
              onVisibleChange={this.hideHistograms}
              trigger="click"
              title={
                <CloseOutlined
                  style={{
                    float: 'right',
                    height: 23,
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={this.hideHistograms} />
              }
              content={<SimplifiedViewPlot
                type={colType[value]}
                value={mapHeader[value]}
                result={this.result[value]}
                data={this.chartData[value]}
              />}
            />
          ) : null}
              </div> }
        {problemType === 'Clustering' && (
          <div className={classnames(styles.tableTd, styles.tableImportance)}>
            <div
              className={styles.preImpotance}
              title={formatNumber(importance, 3)}
            >
              <div
                className={styles.preImpotanceActive}
                style={{ width: importance * 100 + '%' }}
              />
            </div>
          </div>
        )}
        <div className={styles.tableTd} title={valueType}>
          <span>
            {valueType === 'Numerical' ? EN.Numerical : EN.Categorical}
          </span>
        </div>
        <div
          className={classnames(styles.tableTd, {
            [styles.none]: valueType !== 'Categorical',
          })}
          title={unique}
        >
          <span>{unique}</span>
        </div>
        <div
          className={classnames(styles.tableTd, {
            [styles.none]: valueType === 'Categorical',
          })}
          title={this.renderCell(data.mean, valueType === 'Categorical')}
        >
          <span>{this.renderCell(data.mean, valueType === 'Categorical')}</span>
        </div>
        <div
          className={classnames(styles.tableTd, {
            [styles.none]: valueType === 'Categorical',
          })}
          title={this.renderCell(data.std, valueType === 'Categorical')}
        >
          <span>{this.renderCell(data.std, valueType === 'Categorical')}</span>
        </div>
        <div
          className={classnames(styles.tableTd, {
            [styles.none]: valueType === 'Categorical',
          })}
          title={this.renderCell(data.median, valueType === 'Categorical')}
        >
          <span>
            {this.renderCell(data.median, valueType === 'Categorical')}
          </span>
        </div>
        <div
          className={classnames(styles.tableTd, {
            [styles.none]: valueType === 'Categorical',
          })}
          title={this.renderCell(data.min, valueType === 'Categorical')}
        >
          <span>{this.renderCell(data.min, valueType === 'Categorical')}</span>
        </div>
        <div
          className={classnames(styles.tableTd, {
            [styles.none]: valueType === 'Categorical',
          })}
          title={this.renderCell(data.max, valueType === 'Categorical')}
        >
          <span>{this.renderCell(data.max, valueType === 'Categorical')}</span>
        </div>
      </div>
    );
  }
}
