import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import EN from "../../constant/en";
import { Spin } from 'antd';
import _ from 'lodash';
import Project from 'stores/Project';
import { observer } from 'mobx-react';

interface Interface {
	width?:number
	height?:number
	project:Project
}


function SSPlot(props:Interface):ReactElement{
	const {width=600,height=400,project} = props;
	const {ssPlot} = project;
	let max = 1;

	useEffect(()=>{
		if(!ssPlot){
			project.getSsPlot()
		}
	},[]);

	const option:any = useMemo(()=>{
		if(!ssPlot){
			return {
				xAxis:{},
				yAxis:{},
			}
		}else{
			const {x,y} = ssPlot;
			const data = _.zip(x,y);
			max = _.max(y);
			return {
				xAxis: {
					name:EN.NumberofClusters,
				},
				yAxis: {
					name:EN.WithinGroupSsSquares,
					nameLocation:'center',
					nameGap:`${parseInt(String(max))}`.length * 10 - 4,
				},
				series:{
					type: 'line',
					symbolSize: 0,
					data,
					smooth: false,
				},
				grid:{
					x:`${parseInt(String(max))}`.length * 10 + 7,
					x2:150
				},
				tooltip: {
					trigger: 'axis',
					formatter: function (params) {
						if(params[0]&&params[0].value){
							const [x,y] = params[0].value;

							return `
							${EN.NumberofClusters}:${x}<br/>
							${EN.WithinGroupsSs}:${y.toFixed(3)}<br/>
						`
						}
					},
				},
			}
		}
	},[ssPlot]);

	return <Spin spinning={!ssPlot}>
		<ReactEcharts
			option={option}
			style={{height, width}}
			notMerge={true}
			lazyUpdate={true}
			theme='customed'
		/>
	</Spin>
}
export default observer(SSPlot);
