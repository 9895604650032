import React, { useContext, DragEvent, ReactElement, createRef } from 'react';
import styles from './styles.module.css';
import {
  observer,
  MobXProviderContext,
  useLocalStore,
  useObserver,
} from 'mobx-react';
import sampleIcon from './sample.svg';
import localFileIcon from './local-file.svg';
import sqlIcon from './sql.svg';
import { Confirm, Show, Uploads, DatabaseConfig } from 'components/Common';
import EN from '../../../constant/en';
import { Select, Modal } from 'antd';
import DataSample from './Sample';
import DbImportProgressModal from './modal/dbImportProgressModal';
import { NewUploadProps } from 'stores/Project';
import { ProjectStore } from 'stores/ProjectStore';
import { SocketStore } from 'stores/SocketStore';
import axios from 'axios'
import moment from 'moment';
import { ETL_STATUS } from '../../../constant/common';
import { ProjectRolesKey } from '@app/constant/types';

const { Option } = Select;

function DataConnect(): ReactElement {
  //@ts-ignore
  const {
    socketStore,
    projectStore: {
      project: {
        etling,
        charset,
        deleteIndex,
        updateProject,
        redoUploadData,
        updateProjectEtlStatus,
        // fastTrackInit,
        id,
        train2ing,
        models,
        dataInit,
        // dataInitBySql,
        bucket,
        etlIndex,
        bigData,
        problemType,
        roles,
        mainStep,
        lastSubStep,
        updateInitConnectData
      },
      project,
    },
  }: {
    projectStore: ProjectStore
    socketStore: SocketStore
  } = useContext(MobXProviderContext);
  const localStore = useLocalStore(() => ({
    process: 0,
    reImportConfirmVisible: false,
    isPause: false,
    uploading: false,
    file: null,
    key: '',
    pause: null,
    resume: null,
    abort: null,
    connectType: '',
    showPauseButton: false,
    updateField(data) {
      Object.assign(this, data);
    },
  }));

  const uploadRef: any = createRef();

  const onUpload = async ({
    pause,
    resume,
    abort,
  }: {
    pause: () => void;
    resume: () => void;
    abort: () => void;
  }) => {
    updateProjectEtlStatus({
      etlStatus: ETL_STATUS.UPLOADING,
      databaseConnectType: 'upload',
    });
    localStore.updateField({
      isPause: false,
      showPauseButton: true,
      uploading: true,
      file: null,
      pause,
      resume,
      abort,
    });
  };

  const beforeClose = (fileId: string) => deleteIndex(fileId)

  // const upload = (data: UploadProps) => {
  //   localStore.updateField({
  //     process: 50,
  //   });
  //   return fastTrackInit(data);
  // };

  const onError = (error: Error) => {
    localStore.updateField({
      uploading: false,
      file: null,
      process: 0,
      showPauseButton: false,
    });
    updateProject({ etlStatus: ETL_STATUS.UNUSE, etling: false, databaseConnectType: '' });
    // antdmessage.error(error.toString());
    Modal.error({
      title: EN.DataException,
      content: error.toString()
    });
  };

  // function onProgress1(process: string) {
  //   if (!localStore.uploading) return
  //   const [u, t] = process.split("/")
  //   localStore.updateField({
  //     uploading: true,
  //     process: (+u / +t) / 2 * 100,
  //   });
  // }

  function onProgress(process: number) {
    if (!localStore.uploading) return
    localStore.updateField({
      process: process / 2,
    });
  }

  function onComplete({
    fileId,
    fileName,
    uploadCost,
    fileSize,
    rawHeader
  }) {
    localStore.updateField({
      process: 50,
      showPauseButton: false,
    });
    // 更新InitConnectData
    updateInitConnectData({
      fileId,
      fileName,
      uploadCost,
      fileSize,
      rawHeader
    });

    dataInit({
      fileId,
      fileName,
      uploadCost,
      fileSize,
      rawHeader
    }).then(() => {
      localStore.updateField({
        process: 0,
        uploading: false
      });
    })
  }


  const hideSample = () => {
    localStore.updateField({
      connectType: '',
    });
  };

  const selectSample = (data: NewUploadProps) => {
    const _process = etling ? 50 : localStore.process;
    if (!!_process) return false;
    localStore.updateField({
      uploading: true,
      process: 50,
      showPauseButton: true,
    });
    updateProjectEtlStatus({
      etlStatus: ETL_STATUS.UPLOADED,
      databaseConnectType: 'sample',
    });
    onCheck({ size: data.fileSize } as File).then(result => {
      if (result.status === 200) {
        // 更新InitConnectData
        updateInitConnectData(data);
        dataInit(data, true).then(() => {
          localStore.updateField({
            uploading: false,
            process: 0,
            showPauseButton: false,
          });
        })
      } else {
        onError(new Error(result.message))
      }
    })
    hideSample();
    // fastTrackInit(data)
    //   .then(() => {
    //     localStore.updateField({
    //       uploading: false,
    //       process: 0,
    //     });
    //   })
    //   .catch(err => { });
  };

  const hideSql = () => {
    localStore.updateField({
      connectType: '',
    });
  };

  const saveConnectionProfile = (state) => {
    const storage = window.localStorage;
    if (state.rememberMyPassword) {
      storage.setItem('DatabaseConnectionPassword', state.sqlPassword);
    } else {
      storage.setItem('DatabaseConnectionPassword', '');
    }
    if (state.rememberMyConnectionProfile) {
      const profile = { ...state };
      delete profile.sqlPassword;
      storage.setItem('DatabaseConnectionProfile', JSON.stringify(state));
    } else {
      storage.setItem('DatabaseConnectionProfile', '');
      storage.setItem('DatabaseConnectionPassword', '');
    }
  };

  const onClick = (key: any) => {
    if (localStore.uploading || etling) return;
    localStore.updateField({
      key,
    });
    // if (train2ing || !!models.length || !!etlIndex) {
    if ((mainStep >= 2 && lastSubStep > 1) || mainStep >= 3) {
      return localStore.updateField({
        reImportConfirmVisible: true,
      });
    }
    onSelectConnectWay(key);
  };

  const onConfirm =  (k = localStore.key) => {
    updateProject({
      mainStep: 2,
      lastSubStep: 1,
      // rowsCount:0,
      // colsCount:0
    });
    onCloseRedoConfirm();
    onSelectConnectWay(k);
  };

  const onSelectConnectWay = (k = localStore.key) => {
    if (!k) return;
    switch (k) {
      case 'upload':
        //config.debug ?
        // uploadRef.current.show()
        uploadRef.current.click();
        break;
      case 'sample':
        localStore.updateField({
          connectType: 'sample',
        });
        break;
      case 'sql':
        localStore.updateField({
          connectType: 'sql',
        });
        break;
    }
  }
  const onCloseRedoConfirm = () => {
    localStore.updateField({
      reImportConfirmVisible: false,
    });
  };

  const block = (label: string, img: string, key: string) => {
    const btn = (
      <Show name={ProjectRolesKey.DataConnect}>
        <div className={styles.blockLabel}>
          <span>{label}</span>
        </div>
      </Show>
    );

    return (
      <div
        className={styles.uploadBlock}
        onClick={() => {
          if (roles[ProjectRolesKey.DataConnect] !== false) {
            onClick(key);
          }
        }}
      >
        <div className={styles.blockImg}>
          <img src={img} alt={label} />
        </div>
        {btn}
      </div>
    );
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const _process = etling ? 50 : localStore.process;
    if (_process) return false;
    localStore.updateField({
      file: e.dataTransfer.files[0],
    });
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleParse = () => {
    if (localStore.isPause) return;
    localStore.pause && localStore.pause();
    localStore.updateField({
      isPause: true,
    });
  };

  const handleResume = () => {
    if (!localStore.isPause) return;
    localStore.resume && localStore.resume();
    localStore.updateField({
      isPause: false,
    });
  };

  const onCheck = async (file: File) => {
    const { data } = await axios.post('/r2upload/check', {
      fileSize: file.size,
      type: 'modeling',
      projectId: id
    })
    return data
  }

  const closeUpload = () => {
    localStore.abort && localStore.abort();
    localStore.updateField({
      uploading: false,
      process: 0,
    });
    socketStore.stopCommands(project.id, 'database.db')
    project.abortDatabaseImport()
  };

  // const _process = (etling && !localStore.isSql) ? Math.max(50, localStore.process) : localStore.process;
  const charsetChange = charset => updateProject({ charset });
  const submitDatabase = async (state: any) => {
    socketStore.ready().then(api => {
      const startTime = moment().valueOf();

      hideSql(); // 关闭数据库配置框
      saveConnectionProfile(state); // 保存链接信息
      localStore.updateField({ // 打开上传进度条，初始值设为0
        uploading: true,
        process: 0,
      });
      api.databaseImportDb({
        ...state,
        projectId: id,
        bigData,
        problemType,
      }).then(resp => {
        if (resp.abort) return
        if (resp.status === 100) {
          // 2022-10-20TODO 
          // console.log('state :::', state);
          // console.log('resp :::', resp);
          
          onComplete({
            fileName: state.sqlTable,
            uploadCost: moment().valueOf() - startTime,
            fileId: resp.result?.csvLocation,
            fileSize: resp.result?.fileSize,
            rawHeader: resp.result?.columns || [],
          });
          localStore.updateField({
            uploading: true,
            process: 50,
          });
        }
        // } else {
        //   // let content = resp.message;
        //   // Modal.error({
        //   //   title: 'ERROR!',
        //   //   content: content.length>200?content.substr(0,200)+'...':content
        //   // });
        //   // localStore.updateField({
        //   //   uploading: false,
        //   //   process: 0,
        //   // });
      });
    });
  };

  return (
    <div
      className={styles.connect}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div className={styles.schemaInfo}>
        <div className={styles.schemaI}>
          <span>i</span>
        </div>
        <div className={styles.schemaText}>
          <span>{EN.Ifyourdatadoesnothaveaheader}</span>
        </div>
      </div>
      <div className={styles.title}>
        <span>{EN.Pleasechooseadata}</span>
        <label className={styles.chooseCharset}>{EN.choosecharset}</label>
        <Select
          style={{ width: '9rem' }}
          value={charset}
          onChange={charsetChange}
        >
          <Option value="auto">{EN.Auto}</Option>
          <Option value="utf-8"><div dangerouslySetInnerHTML={{ __html: EN.UTF_8 }} /></Option>
          <Option value="gbk">{EN.GBK}</Option>
          <Option value="big5">{EN.BIG5}</Option>
        </Select>
      </div>
      <div className={styles.uploadRow}>
        {!bigData && block(EN.FromR2L, sampleIcon, 'sample')}
        {!bigData && block(EN.FromComp, localFileIcon, 'upload')}
        {block(EN.FromSQL, sqlIcon, 'sql')}
        {!bigData && <Uploads
          onStart={onUpload}
          ref={uploadRef}
          onProgress={onProgress}
          onComplete={onComplete}
          style={{ display: "none" }}
          file={localStore.file}
          onError={onError}
          bucket={bucket}
          onCheck={onCheck}
          beforeClose={beforeClose}
        />}
        {/*<Uploader*/}
        {/*  onStart={onUpload}*/}
        {/*  onComplete={upload}*/}
        {/*  onError={onError}*/}
        {/*  params={{ userId: info.id, projectId: id }}*/}
        {/*  onProgress={onProgress1}*/}
        {/*  file={localStore.file}*/}
        {/*  charset={charset}*/}
        {/*  afterClose={afterClose}*/}
        {/*  ref={uploadRef}*/}
        {/*/>*/}
      </div>
      {!bigData && <DataSample
        project={project}
        onClose={hideSample}
        selectSample={selectSample}
        visible={localStore.connectType === 'sample'}
      />}
      <DbImportProgressModal
        visible={localStore.uploading || etling}
        onClose={closeUpload}
        uploading={localStore.uploading}
        handleParse={handleParse}
        handleResume={handleResume}
        showPauseButton={localStore.showPauseButton}
        process={localStore.process}
        isPause={localStore.isPause}
        bigData={bigData}
      />

      <DatabaseConfig
        options={{}}
        visible={localStore.connectType === 'sql'}
        onClose={hideSql}
        title={EN.DataSourceDatabase}
        projectId={id}
        onSubmit={submitDatabase}
        bigData={bigData}
        connectLocation='train'
      // copy={bigData}
      />
      <Confirm
        width={'6em'}
        visible={localStore.reImportConfirmVisible}
        title={EN.Warning}
        content={EN.Thisactionmaywipeoutallofyourprevious}
        onClose={onCloseRedoConfirm}
        onConfirm={() => onConfirm()}
        confirmText={EN.Continue}
        closeText={EN.CANCEL}
      />
    </div>
  );
}

export default observer(DataConnect);
