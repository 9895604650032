import React, { ReactElement, useEffect, useState, Fragment } from 'react';
import Project from 'stores/Project';

import { Button, Checkbox, Descriptions, InputNumber, Modal, Popover, Radio, Select } from 'antd';
import styles from './Forecasting.module.css'
import { ACF_PACF, SeasonalDecompose } from '../../../Charts';
import request from '../../../Request';
import { Range, Show } from "../../../Common";
import { observer, useObserver, useLocalStore } from 'mobx-react';
import EN from '../../../../constant/en';
import { toJS } from 'mobx';
import _ from 'lodash'
import { ProjectRolesKey } from '@app/constant/types';

const { Option } = Select;

interface Interface {
  project: Project
}

const Modeling = (props: Interface): ReactElement => {
  const {
    project,
    project: {
      getActPlot,
      acfPlotData,
      getSeasonalPlot,
      seasonalPlotData,
      qqPlotData,
      timeSeriesTrain,
      validationRate,
      FBProphet,
      ModelingSetting,
      measurement,
      colType,
      orderIndex: [oi = ''],
      arima,
      sarima,
      forecastSize,
      totalLines,
      time_default,
      forecastOption,
      forecastUnit,
      readFile
    },
  } = props;

  const [actData, upActData] = useState(null);
  const [seasonData, upSeasonData] = useState(null);
  const [qqData, upQqData] = useState(null);

  const time = colType[oi] === 'Datetime';

  const store = useLocalStore(() => ({
    show_season: false,
    updateField(data) {
      Object.assign(this, data);
    }
  }));

  useEffect(() => {
    !acfPlotData && getActPlot();
  }, []);

  useEffect(() => {
    if (qqPlotData) {
      readFile(qqPlotData).then((data) => {
        upQqData(data)
      })
    }
  }, [qqPlotData]);

  useEffect(() => {
    getSeasonalPlot(ModelingSetting.freq);
  }, [ModelingSetting.freq]);

  useEffect(() => {
    if (seasonalPlotData) {
      readFile(seasonalPlotData).then(seasonData => {
        upSeasonData(seasonData);
      })
    }
  }, [seasonalPlotData]);

  useEffect(() => {
    if (acfPlotData) {
      readFile(acfPlotData).then(actData => {
        upActData(actData);
      })
    }
  }, [acfPlotData]);
  function save() {
    timeSeriesTrain({
      measurement,
      FBProphet,
      validationRate,
      ModelingSetting,
      arima,
      sarima,
      forecastSize
    });
  }

  const handleSlider = (value: [number]) => {
    const [min] = value;
    project.validationRate = 100 - min;
  };

  const auto = ModelingSetting.trainType === 'auto';

  function reset(itm) {
    if (!auto) project[itm] = toJS(time_default[itm]);
    if (itm === 'FBProphet') project.validationRate = 20
  }

  const minIndex = [{ label: 'Year', value: 'Y' },
  { label: 'Quarter', value: 'Q' },
  { label: 'Month', value: 'M' },
  { label: 'Day', value: 'D' },
  { label: 'Hour', value: 'H' },
  { label: 'Minute', value: 'M' }].findIndex(v => v.value === forecastOption?.unit)

  return <section className={styles.content}>
    {useObserver(() => <>
      <div >
        {EN.SelectAlgorithm}:
        <br />
        <Radio.Group
          value={ModelingSetting.algorithm}
          style={{ lineHeight: 2 }}
          onChange={e => {
            project.ModelingSetting = {
              ...ModelingSetting,
              algorithm: e.target.value
            }
          }}>
          {
            [
              { label: 'ARIMA', value: 'arima' },
              { label: 'SARIMA', value: 'sarima' }
              // { label: 'FBProphet', value: 'prophet' }
              ].map(itm => <Fragment key={itm.value}>
                <br />
                <Radio value={itm.value}>{itm.label}</Radio>
              </Fragment>)
          }
        </Radio.Group>
      </div>
      <div>
        <br />
        {EN.SelectModelingMethod}:
        <br />
        <Radio.Group style={{ lineHeight: 2 }} value={ModelingSetting.trainType}
          onChange={e => {
            project.ModelingSetting = {
              ...ModelingSetting,
              trainType: e.target.value
            }
          }}>
          <Radio value='auto'>{EN.Automatic}</Radio>
          <br />
          <Radio value='custom'>{EN.Customize}</Radio>
        </Radio.Group>

        <Descriptions bordered style={{ margin: '0.5rem 1rem', display: (ModelingSetting.algorithm === 'arima' ? '' : 'none') }}>
          <Descriptions.Item label="P">
            <InputNumber
              min={0}
              max={14}
              disabled={auto}
              value={arima.p}
              onChange={(value) => {
                project.arima = {
                  ...arima,
                  p: value
                }
              }} /></Descriptions.Item>
          <Descriptions.Item label="P">
            <InputNumber
              disabled={true}
            /></Descriptions.Item>
          <Descriptions.Item label={EN.Seasonality}>
            <InputNumber disabled={true} />
          </Descriptions.Item>
          <Descriptions.Item label="D">
            <InputNumber
              value={arima.d}
              min={0} max={2} disabled={auto}
              onChange={(d) => {
                project.arima = {
                  ...arima,
                  d
                }
              }} /></Descriptions.Item>
          <Descriptions.Item label="D" span={2}>
            <InputNumber disabled={true} />
          </Descriptions.Item>
          <Descriptions.Item label="Q">
            <InputNumber
              min={0} max={14}
              disabled={auto}
              value={arima.q}
              onChange={(q) => {
                project.arima = {
                  ...arima,
                  q
                }
              }} /></Descriptions.Item>
          <Descriptions.Item label="Q" span={2}>
            <InputNumber
              disabled={true}
            /></Descriptions.Item>
          <Descriptions.Item label="">
            <Popover
              content={<ACF_PACF data={actData} />}
              placement='right'
              getPopupContainer={el => el.parentElement}
              trigger="click">
              <a>Show ACF/PACF</a>
            </Popover>
          </Descriptions.Item>
          <Descriptions.Item label="">
            <a style={{ color: (seasonData && qqData ? '' : '#ccc') }} onClick={() => seasonData && qqData && store.updateField({ show_season: true })}>Show Seasonal Decompose</a>
          </Descriptions.Item>
          <Descriptions.Item label="">
            <Button
              onClick={reset.bind(this, 'arima')}>{EN.Reset}</Button>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered style={{ margin: '0.5rem 1rem', display: (ModelingSetting.algorithm === 'sarima' ? '' : 'none') }}>
          <Descriptions.Item label="P">
            <InputNumber
              min={0}
              max={14}
              disabled={auto}
              value={sarima.ap}
              onChange={ap => {
                project.sarima = {
                  ...sarima,
                  ap
                }
              }} /></Descriptions.Item>
          <Descriptions.Item label="P">
            <InputNumber
              disabled={auto}
              min={0}
              max={3}
              value={sarima.sap}
              onChange={sap => {
                project.sarima = {
                  ...sarima,
                  sap
                }
              }} /></Descriptions.Item>
          <Descriptions.Item label={EN.Seasonality}>
            <InputNumber value={sarima.seasonality}
              min={2}
              max={_.floor(totalLines * 0.25)}
              disabled={auto}
              onChange={seasonality => {
                project.sarima = {
                  ...sarima,
                  seasonality
                }
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item label="D">
            <InputNumber
              value={sarima.ad}
              min={0} max={2} disabled={auto}
              onChange={ad => {
                project.sarima = {
                  ...sarima,
                  ad
                }
              }} /></Descriptions.Item>
          <Descriptions.Item label="D" span={2}>
            <InputNumber disabled={auto}
              min={1} max={2}
              value={sarima.sad}
              onChange={sad => {
                project.sarima = {
                  ...sarima,
                  sad
                }
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Q">
            <InputNumber
              min={0} max={14}
              disabled={auto}
              value={sarima.aq}
              onChange={aq => {
                project.sarima = {
                  ...sarima,
                  aq
                }
              }} /></Descriptions.Item>
          <Descriptions.Item label="Q" span={2}>
            <InputNumber
              disabled={auto}
              min={0} max={3}
              value={sarima.saq}
              onChange={saq => {
                project.sarima = {
                  ...sarima,
                  saq
                }
              }}
            /></Descriptions.Item>
          <Descriptions.Item label="">
            <Popover
              content={<ACF_PACF data={actData} />}
              trigger="click">
              <a>Show ACF/PACF</a>
            </Popover>
          </Descriptions.Item>
          <Descriptions.Item label="">
            <a style={{ color: (seasonData && qqData ? '' : '#ccc') }} onClick={() => seasonData && qqData && store.updateField({ show_season: true })}>Show Seasonal Decompose</a>
          </Descriptions.Item>
          <Descriptions.Item label="">
            <Button onClick={reset.bind(this, 'sarima')}>{EN.Reset}</Button>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          column={2}
          bordered style={{ margin: '0.5rem 1rem', display: (ModelingSetting.algorithm === 'prophet' ? '' : 'none') }}>
          <Descriptions.Item
            label={EN.Seasonality}
            span={2}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}>
              <Select
                value={FBProphet.seasonality}
                onChange={seasonality => {
                  project.FBProphet = {
                    ...FBProphet,
                    seasonality
                  }
                }}
                getPopupContainer={el => el.parentElement}
                style={{ width: 150 }}
                disabled={auto}>
                {['additive', 'multiplicative'].map(itm => <Option value={itm} key={itm}>{itm}</Option>)}
              </Select>
              {['yearly', 'weekly', 'daily'].map(itm => <Checkbox key={itm} disabled={auto} checked={FBProphet[itm]} onChange={e => {
                project.FBProphet = {
                  ...FBProphet,
                  [itm]: e.target.checked
                };
              }}><span style={{ textTransform: 'capitalize' }}>{itm}</span></Checkbox>)}
            </div>
          </Descriptions.Item>

          <Descriptions.Item label={EN.Step}>
            <InputNumber
              min={1}
              value={FBProphet.step}
              onChange={step => {
                project.FBProphet = {
                  ...FBProphet,
                  step
                };
              }}
              disabled={auto} />
          </Descriptions.Item>

          <Descriptions.Item label="Complexity">
            <Select
              value={FBProphet.complexity}
              onChange={complexity => {
                project.FBProphet = {
                  ...FBProphet,
                  complexity
                };
              }}
              getPopupContainer={el => el.parentElement}
              disabled={auto}>
              {['5', '4', '3', '2', '1'].map(itm => <Option value={itm} key={itm}>{itm}</Option>)}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item span={2} label={EN.Unit}>
            <Select style={{ width: 120 }}
              value={FBProphet.unit}
              onChange={unit => {
                project.FBProphet = {
                  ...FBProphet,
                  unit
                };
              }}
              getPopupContainer={el => el.parentElement}
              disabled={auto}>
              {[
                { label: 'Year', value: 'year' },
                { label: 'Quarter', value: 'quarter' },
                { label: 'Month', value: 'month' },
                { label: 'Day', value: 'day' },
                { label: 'Hour', value: 'hour' },
                { label: 'Minute', value: 'minute' }
              ].map(itm => <Option value={itm.value} key={itm.value}>{itm.label}</Option>)}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label="">
            <a style={{ color: (seasonData && qqData ? '' : '#ccc') }} onClick={() => seasonData && qqData && store.updateField({ show_season: true })}>Show Seasonal Decompose</a>
          </Descriptions.Item>
          <Descriptions.Item label="">
            <Button onClick={reset.bind(this, 'FBProphet')}>{EN.Reset}</Button>
          </Descriptions.Item>
        </Descriptions>
      </div>

      {ModelingSetting.algorithm === 'prophet' && <div className={styles.range} >
        {EN.TrainValidationSplit}:&nbsp;
        <div>
          <div className={styles.advancedPercent}>
            <div className={styles.advancedPercentTrain} style={{ width: (100 - validationRate) + '%' }} />
            <div className={styles.advancedPercentValidation} style={{ width: validationRate + '%' }} />
          </div>
          <Range
            range={true}
            step={1}
            min={1}
            max={99}
            onChange={handleSlider}
            value={[100 - parseInt((validationRate || '0').toString(), 10)]}
            tooltipVisible={true}
          />
        </div>
      </div>}

      <div className={styles.ws}>
        {EN.MeasurementMetric}:&nbsp;&nbsp;
        <Select
          style={{ width: 120 }}
          onChange={metrics => {
            project.measurement = metrics
          }}
          getPopupContainer={el => el.parentElement}
          value={measurement}>
          {[{ label: 'RMSE', value: 'rmse' },
          { label: 'MAE', value: 'mae' },
          { label: 'R-Square', value: 'r2' }].map(itm => <Option value={itm.value} key={itm.value}>{itm.label}</Option>)}
        </Select>
      </div>
      <div className={styles.ws}>
        {EN.ForecastingStep}:&nbsp;&nbsp;
        <InputNumber
          value={forecastSize}
          min={1} max={forecastOption?.max}
          onChange={forecastSize => {
            project.forecastSize = forecastSize
          }} />
      </div>
      {/* {!!oi && <div className={styles.ws}>
        {EN.ForecastingUnit}:&nbsp;&nbsp;
        <Select
          style={{ width: 120 }}
          onChange={forecastUnit => {
            project.forecastUnit = forecastUnit
          }}
          getPopupContainer={el => el.parentElement}
          value={forecastUnit}>
          {[{ label: 'Year', value: 'Y' },
          { label: 'Quarter', value: 'Q' },
          { label: 'Month', value: 'M' },
          { label: 'Day', value: 'D' },
          { label: 'Hour', value: 'H' },
          { label: 'Minute', value: 'M' }].map((itm, i) => <Option value={itm.value} key={itm.value} disabled={i <= minIndex}>{itm.label}</Option>)}
        </Select>
      </div>} */}

      <Show name={ProjectRolesKey.StartAdvancedModeling}>
        <div className={styles.button}
             onClick={save}
             style={{ margin: '30px 0' }}>
          <span>{EN.Modeling}</span>
        </div>
      </Show>

      <Modal
        title="Seasonal Decompose"
        visible={store.show_season}
        width={1000}
        footer={null}
        onCancel={() => store.updateField({ show_season: false })}
      >
        <SeasonalDecompose
          freq={ModelingSetting.freq}
          freq_max={parseInt(String(totalLines * 0.2))}
          upFreq={(freq) => {
            project.ModelingSetting = {
              ...ModelingSetting,
              freq
            };
          }}
          time={time}
          qqData={qqData}
          data={seasonData} />
      </Modal>
    </>)}
  </section>;
};

export default observer(Modeling)
