import * as React from 'react';

import { MuiCard } from './MuiModule';
import { Paper } from '@material-ui/core'
import Description from './FunctionDescription';

const useStyles = {
  detail: {
    height: 426,
    overflowY: 'auto',
    overflowX: 'hidden',
    textAlign: 'left',
    margin: '.75rem 0',
    padding: '0 1.5rem',
  },
};

export interface SummaryProps {
  detailKey: string;
}

function Summary(props: SummaryProps) {

  const { detailKey } = props;

  return (
    <MuiCard>
      <Paper style={useStyles.detail as any} elevation={0} square={true}>
        <Description funcName={detailKey}/>
      </Paper>
    </MuiCard>
  );
}

export default Summary;
