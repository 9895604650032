import React, { useContext, ReactElement, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { MobXProviderContext, observer } from 'mobx-react';
import EN from '../../../../constant/en';
import ModelTable from './ModelTable';
import { Lines } from '../../../Charts';
import Project from 'stores/Project';
import Model from 'stores/Model';
import failIcon from './fail.png';
import { ProgressStore } from 'stores/ProgressStore';
interface Interface {
  project: Project;
  models: Model[];
  sort: {
    key: string;
    value: number;
  };
  handleSort: (s: string) => void;
}

function TimeSeriesResult(props: Interface): ReactElement {
  const {
    project: {
      setSelectModel,
      train2Finished,
      trainModel,
      abortTrain,
      selectModel: current,
      isAbort,
      recommendModel,
      mapHeader,
      target,
      newVariable,
      stopIds,
      colType,
      bigData,
      readFile
    },
    models,
    project,
    sort,
    handleSort,
  } = props;

  // @ts-ignore
  const {
    progressStore: {
      progresses,
      getPrejectTrainProgresses
    },
  }:{
    progressStore:ProgressStore
  } = useContext(MobXProviderContext);
  
  const [allStopIds, setAllStopIds] = useState([]);

  useEffect(() => {
    let _stopIds = []
    stopIds.map(x => {
      if (progresses[x])
        _stopIds.push({key: x , value: progresses[x]})
      else _stopIds.push({key: x , value: {data: {requestId: x}}})
    });
    setAllStopIds(_stopIds);
  }, [stopIds, progresses]);

  useEffect(() => {
    getPrejectTrainProgresses(stopIds)
  }, []);

  const onSelect = model => {
    setSelectModel(model.id);
  };

  if (!current) return null;
  const currentPerformance = current
    ? (current.score.r2 > 0.7 && EN.GOOD) || (current.score.r2 > 0.3 && EN.Acceptable) ||
    EN.NotAcceptable
    : '';
  const newMapHeader: any = {
    ...(Array.isArray(mapHeader) ? mapHeader.reduce((prev, v, k) => Object.assign(prev, { [k]: v }), {}) : mapHeader),
    ...newVariable.reduce((prev, v) => Object.assign(prev, { [v]: v }), {}),
  };

  const { resultPlotData, orderIndex: [ind = '__no'], target: original } = current;

  const [Line, upLine] = useState(null);

  useEffect(() => {
    readFile(resultPlotData).then(data => {
      const index = data[ind];
      const Original = data[original].filter(itm => itm !== 'nan');
      const Predict = data[`${original}_predict`].slice(0, Original.length);
      const Forecast = data[`${original}_predict`].slice(Original.length - 1);
      Forecast.unshift(...new Array(Original.length - 1));

      upLine(<Lines
        time={colType[ind] === 'Datetime'}
        width='100%'
        dataZoom
        data={{
          index,
          Original,
          Predict,
          Forecast,
        }} />)
    })
  }, [resultPlotData]);

  return (
    <>
      <div className={styles.result}>
        <div className={styles.box}>
          <div className={styles.title}>
            <span>{EN.RecommendedAModel}</span>
          </div>
          <div className={styles.row}>
            <span>{EN.ModelingResult}: </span>
            <div className={styles.status}>
              &nbsp;&nbsp;{currentPerformance}
            </div>
          </div>
          <div className={styles.row}>
            <span>
              {EN.SelectedModel} :
              <a className={styles.nohover}>&nbsp;{current.modelName}</a>
            </span>
          </div>
          <div className={styles.row}>
            <span>
              {EN.Target} :
              <a className={styles.nohover}>&nbsp;{mapHeader[target]}</a>
            </span>
          </div>
          {!!project.faultReason && <div className={styles.row}>
            <div className={styles.error}>
              <span style={{ color: '#ff3151' }}><img src={failIcon} alt={'fail'} />&nbsp;&nbsp;Alert</span>
              <span>{project.faultReason}</span></div>
          </div>}
        </div>
        <div className={styles.box}>
          {Line}
        </div>
      </div>
      <div className={styles.line} />
      <ModelTable
        models={models}
        current={current}
        onSelect={onSelect}
        train2Finished={train2Finished}
        trainModel={trainModel}
        abortTrain={abortTrain}
        isAbort={isAbort}
        project={project}
        recommendId={recommendModel.id}
        sort={sort}
        handleSort={handleSort}
        mapHeader={newMapHeader}
        stopIds={stopIds}
        bigData={bigData}
        allStopIds={allStopIds}
      />
    </>
  );
}
export default observer(TimeSeriesResult);
