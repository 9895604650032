import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MobXProviderContext, observer } from 'mobx-react';
import moment from 'moment';
import { Confirm,Show} from 'components/Common';
import { DeleteOutlined } from '@ant-design/icons';
import { Switch, Input, Pagination, Select, ConfigProvider } from 'antd';
import EN from '../../constant/en';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import en_US from 'antd/lib/locale-provider/en_US';
import { DeploymentStore } from 'stores/DeploymentStore';
import { IncrementStore } from 'stores/IncrementStore';
import { ScheduleStore } from 'stores/ScheduleStore';
import { UserStore } from 'stores/UserStore';
import styles from './styles.module.css';

const { Option } = Select;

function Deploy(){
  // @ts-ignore
  const {
    incrementStore:{
      projectDelete,
    },
    deploymentStore:{
      initWatch,
      changeSort,
      sortOptions,
      sortByOptions,
      totalCount,
      sortedDeployments,
      enableDeployment,
    },
    scheduleStore:{
      schedules,
      // initWatch,
    },
    userStore:{
      info:{
        email
      }
    }
  }:{
    deploymentStore:DeploymentStore
    incrementStore:IncrementStore
    scheduleStore:ScheduleStore
    userStore:UserStore
  } = useContext(MobXProviderContext);
  const history = useHistory();

  useEffect(()=>{
    initWatch()
  },[])

  const sortSchedule = useMemo(()=>{
    return schedules.sort((a:any,b:any)=>-a.id + b.id)
  },[schedules])

  const list = useMemo(()=>{
    return sortedDeployments.map(itm=>{
      const {
        id:deploymentId,
        user_id:{email},
        created_at,
        project,
        enable,
        model_name:modelName,
        lastRunTime,
        nextRunTime,
        supportIncrement,
      } = itm;
      const result = sortSchedule.find((itm:any)=>itm.projectId?.id === project.id && ['deployment', 'performance'].includes(itm.type))
      let status;
      switch (result?.status){
        case 'finished':
          status = EN.DeploymentCompleted;
          break;
        case 'issue':
          status = EN.Issue;
          break;
        case undefined:
          status = EN.WaitingDeployment
          break;
        default:
          status = EN.DeploymentProgress
      }

      return {
        deploymentId,
        created_at,
        email,
        projectName:project.name,
        id:project.id,
        enable,
        modelName,
        lastRunTime,
        nextRunTime,
        supportIncrement,
        status,
      }
    })
  },[sortedDeployments,sortSchedule])

  const [visible,upVisible] = useState(false)

  const deleteConfirm = (projectId,deploymentId) =>{
    projectDelete(projectId,deploymentId);
    upVisible(false)
  }
  const toggle = (currentType, targetType) => () => {
    if (currentType === targetType) {
      changeSort(
        'sortBy',
        targetType.startsWith('r')
          ? targetType.slice(1, targetType.length)
          : 'r' + targetType
      );
    } else {
      changeSort('sortBy', targetType);
    }
  };

    return (
      <div className={styles.home}>
        <div className={styles.filter} style={{ marginBottom: 24 }}>
          <Input.Search
            placeholder={EN.SearchProject}
            value={sortOptions.keywords}
            onChange={value => changeSort('keywords', value.target.value)}
            style={{ flex: 1, maxWidth: 320, marginRight: 16 }}
            enterButton
          />
          <span style={{ marginLeft: 'auto' }}>
            <span>{EN.SortBy}: </span>
            <Select
              className={styles.selector}
              value={sortOptions.sortBy}
              onChange={(value) => changeSort('sortBy', value)}
            >
              {Object.keys(sortByOptions).map(key => (
                <Option value={key} key={key}>
                  {sortByOptions[key]}
                </Option>
              ))}
            </Select>
          </span>
          <ConfigProvider locale={(window as any).r2_env.isEN ? en_US : zh_CN}>
            <Pagination
              style={{ marginLeft: 16 }}
              current={sortOptions.currentPage}
              pageSize={parseInt(String(sortOptions.perPage), 10)}
              total={totalCount}
              showSizeChanger
              pageSizeOptions={['5', '10', '20']}
              onChange={(page, pageSize) => {
                changeSort('currentPage', page)
                changeSort('perPage', pageSize)
              }}
            />
          </ConfigProvider>
        </div>
        <div className={styles.listWrapper}>
          <div className={styles.head}>
            <span
                className={styles.projectName}
                // onClick={toggle(
                //     sortOptions.sortBy,
                //     'id'
                // )}
            >
              ID
            </span>
            <span
              className={styles.projectName}
              onClick={toggle(
                sortOptions.sortBy,
                'projectName'
              )}
            >
              {EN.ProjectName}
            </span>
            <span
                className={styles.modelName}
                onClick={toggle(
                    sortOptions.sortBy,
                    'modelName'
                )}
            >
              {EN.ModelName}
            </span>
            {/* <span
                className={styles.projectName}
            >
              {EN.Enable}
            </span> */}
            <span
                className={styles.projectName}
            >
              {EN.DeploymentStatus}
            </span>
            <span
                className={styles.createdDate}
                // onClick={toggle(
                //     sortOptions.sortBy,
                //     'lastRunTime'
                // )}
            >
              {EN.LastRunTime}
            </span>
            <span
                className={styles.createdDate}
                // onClick={toggle(
                //     sortOptions.sortBy,
                //     'lastRunTime'
                // )}
            >
              {EN.NextRunTime}
            </span>
            <span
              className={styles.createdDate}
              onClick={toggle(
                sortOptions.sortBy,
                'createdDate'
              )}
            >
              {EN.CreatedDate}
            </span>
            <span className={styles.owner}>{EN.Owner}</span>
            <span className={styles.delete}>
              <ul className={styles.demo}>
                <li><i/>{EN.IncrementalModel}</li>
                <li><i/>{EN.NonIncrementalModel}</li>
              </ul>
            </span>
          </div>
          <div className={styles.list}>
            {list.map((ment) => (
              <div
                key={ment.id}
                data-id={ment.id}
                className={styles.project}
              >
                <span
                    className={styles.projectName}
                    title={ment.id}
                    onClick={() =>
                      history.push(`/deploy/project/${ment.deploymentId}/deployment`)
                    }
                >
                  {ment.id}
                </span>
                <span
                  className={styles.projectName}
                  title={ment.projectName}
                  onClick={() =>
                    history.push(`/deploy/project/${ment.deploymentId}/deployment`)
                  }
                >
                  {ment.projectName}
                </span>
                <span
                    className={styles.modelName}
                    title={ment.modelName}
                    onClick={() =>
                      history.push(`/deploy/project/${ment.deploymentId}/deployment`)
                    }
                >
                  <span style={{
                    color:ment.supportIncrement ? '#8242DD':"#D0A409"
                  }}>{ment.modelName}</span>
                </span>
                {/* <span
                    className={styles.projectName}
                    title={ment.projectName}
                >
                  <Switch
                      checked={ment.enable}
                      disabled={!ment.deploymentId}
                      onChange={enable=>{
                        enableDeployment(enable,ment.deploymentId)
                      }}
                      style={{ backgroundColor: (ment.enable?'#1D2B3C':'')}}
                  />
                </span> */}
                <span
                    className={styles.projectName}
                    title={ment.modelName}
                    onClick={() =>
                      history.push(`/deploy/project/${ment.deploymentId}/deployment`)
                    }
                >
                  {ment.status}
                </span>
                <span
                    className={styles.createdDate}
                    title={moment(+ment.lastRunTime).format('YYYY.MM.DD HH:mm')}
                    onClick={() =>
                      history.push(`/deploy/project/${ment.id}`) }
                >
                  {ment.lastRunTime&&moment(+ment.lastRunTime).format('YYYY.MM.DD HH:mm')}
                </span>
                <span
                    className={styles.createdDate}
                    title={moment(ment.nextRunTime).format('YYYY.MM.DD HH:mm')}
                    onClick={() =>
                      history.push(`/deploy/project/${ment.id}`) }
                >
                  {ment.nextRunTime>0&&moment(+ment.nextRunTime).format('YYYY.MM.DD HH:mm')}
                </span>
                <span
                  className={styles.createdDate}
                  title={moment(ment.created_at).format('YYYY.MM.DD HH:mm')}
                  onClick={() =>
                    history.push(`/deploy/project/${ment.id}`) }
                >
                  {moment(ment.created_at).format('YYYY.MM.DD HH:mm')}
                </span>
                <span className={styles.owner} title={ment.email}>
                  {ment.email}
                </span>
                <span className={styles.delete}>
                    {/*<Show*/}
                    {/*    name = 'deploy_delete'*/}
                    {/*    none = {<span className={styles.delete} />}>*/}
                  {email === ment.email&& <span
                      onClick={() => upVisible(ment.id)}
                  >
                      <DeleteOutlined />
                    </span>}
                    {/*</Show>*/}
                </span>

                <Confirm width={'6em'} visible={visible === ment.id} title={EN.Warning}
                  content={EN.Areyousuretodeletethismodel}
                  onClose={() => upVisible(false)}
                  onConfirm={deleteConfirm.bind(this,ment.id,ment.deploymentId)}
                  confirmText={EN.Continue} closeText={EN.CANCEL} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
}

export default observer(Deploy)
