import React from 'react';
import styles from '../styles.module.css';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { PredictTable } from '../Score';
import { formatNumber } from '../../../util';
import EN from '../../../constant/en';
import { D3D2, ISO } from '../../Charts';

interface Interface {
  project: any,
  checkBox
}

const Metrics = (props: Interface) => {
  const { project, checkBox } = props

  const { selectModel: model } = project;
  const {
    score: { validateScore: vs, holdoutScore: hs },
    score,
    fitIndex,
    chartData,
    holdoutChartData,
    modelName,
    target,
    graphicList
  } = model;
  const { roc } = chartData || {} as any;
  const { roc: roch } = holdoutChartData || {} as any;
  const { targetArray, targetColMap, renameVariable } = project;
  const [v0, v1] = !targetArray.length
    ? Object.keys(targetColMap)
    : targetArray;
  const [no, yes] = [renameVariable[v0] || v0, renameVariable[v1] || v1];

  return <div className={classnames(styles.block, styles.VariableImpact)}>
    {checkBox('metrics')}
    {project.problemType === 'Regression' && (
      <div className={styles.metrics}>
        <div
          className={classnames(
            styles.metricsRow,
            styles.metricsHeader,
          )}
        >
          <span className={styles.metricsCell} />
          <span className={styles.metricsCell}>R²</span>
          <span className={styles.metricsCell}>Adjusted R²</span>
          <span className={styles.metricsCell}>MSE</span>
          <span className={styles.metricsCell}>RMSE</span>
          <span className={styles.metricsCell}>NRMSE</span>
          <span className={styles.metricsCell}>MAE</span>
        </div>
        <div className={styles.metricsRow}>
          <span className={styles.metricsCell}>{EN.Validation}</span>
          <span className={styles.metricsCell} title={vs.r2}>
            {formatNumber(vs.r2)}
          </span>
          <span className={styles.metricsCell} title={vs.adjustR2}>
            {formatNumber(vs.adjustR2)}
          </span>
          <span className={styles.metricsCell} title={vs.mse}>
            {formatNumber(vs.mse)}
          </span>
          <span className={styles.metricsCell} title={vs.rmse}>
            {formatNumber(vs.rmse)}
          </span>
          <span className={styles.metricsCell} title={vs.nrmse}>
            {formatNumber(vs.nrmse)}
          </span>
          <span className={styles.metricsCell} title={vs.mae}>
            {formatNumber(vs.mae)}
          </span>
        </div>
        <div className={styles.metricsRow}>
          <span className={styles.metricsCell}>{EN.Holdout}</span>
          <span className={styles.metricsCell} title={hs.r2}>
            {formatNumber(hs.r2)}
          </span>
          <span className={styles.metricsCell} title={hs.adjustR2}>
            {formatNumber(hs.adjustR2)}
          </span>
          <span className={styles.metricsCell} title={hs.mse}>
            {formatNumber(hs.mse)}
          </span>
          <span className={styles.metricsCell} title={hs.rmse}>
            {formatNumber(hs.rmse)}
          </span>
          <span className={styles.metricsCell} title={hs.nrmse}>
            {formatNumber(hs.nrmse)}
          </span>
          <span className={styles.metricsCell} title={hs.mae}>
            {formatNumber(hs.mae)}
          </span>
        </div>
      </div>
    )}
    {project.problemType === 'Classification' && (
      <div className={styles.metrics}>
        <div
          className={classnames(
            styles.metricsRow,
            styles.metricsHeader,
          )}
        >
          <span className={styles.metricsCell} />
          <span className={styles.metricsCell}>AUC</span>
          <span className={styles.metricsCell}>Cutoff</span>
          <span className={styles.metricsCell}>{EN.Accuracy}</span>
          <span className={styles.metricsCell}>{EN.Precision}</span>
          <span className={styles.metricsCell}>{EN.Recall}</span>
          <span className={styles.metricsCell}>Fbeta</span>
          <span className={styles.metricsCell}>KS</span>
          <span className={styles.metricsCell}>{EN.LogLoss}</span>
        </div>
        <div className={styles.metricsRow}>
          <span className={styles.metricsCell}>{EN.Validation}</span>
          <span className={styles.metricsCell} title={vs.auc}>
            {formatNumber(vs.auc)}
          </span>
          <span
            className={styles.metricsCell}
            title={roc.Threshold[fitIndex]}
          >
            {formatNumber(roc.Threshold[fitIndex])}
          </span>
          <span
            className={styles.metricsCell}
            title={model.accValidation}
          >
            {formatNumber(model.accValidation)}
          </span>
          <span
            className={styles.metricsCell}
            title={roc.Precision[fitIndex]}
          >
            {formatNumber(roc.Precision[fitIndex])}
          </span>
          <span
            className={styles.metricsCell}
            title={roc.Recall[fitIndex]}
          >
            {formatNumber(roc.Recall[fitIndex])}
          </span>
          <span
            className={styles.metricsCell}
            title={roc.F1[fitIndex]}
          >
            {formatNumber(roc.F1[fitIndex])}
          </span>
          <span
            className={styles.metricsCell}
            title={roc.KS[fitIndex]}
          >
            {formatNumber(roc.KS[fitIndex])}
          </span>
          <span
            className={styles.metricsCell}
            title={roc.LOGLOSS[fitIndex]}
          >
            {formatNumber(roc.LOGLOSS[fitIndex])}
          </span>
        </div>
        <div className={styles.metricsRow}>
          <span className={styles.metricsCell}>{EN.Holdout}</span>
          <span className={styles.metricsCell} title={hs.auc}>
            {formatNumber(hs.auc)}
          </span>
          <span
            className={styles.metricsCell}
            title={roch.Threshold[fitIndex]}
          >
            {formatNumber(roch.Threshold[fitIndex])}
          </span>
          <span
            className={styles.metricsCell}
            title={model.accHoldout}
          >
            {formatNumber(model.accHoldout)}
          </span>
          <span
            className={styles.metricsCell}
            title={roch.Precision[fitIndex]}
          >
            {formatNumber(roch.Precision[fitIndex])}
          </span>
          <span
            className={styles.metricsCell}
            title={roch.Recall[fitIndex]}
          >
            {formatNumber(roch.Recall[fitIndex])}
          </span>
          <span
            className={styles.metricsCell}
            title={roch.F1[fitIndex]}
          >
            {formatNumber(roch.F1[fitIndex])}
          </span>
          <span
            className={styles.metricsCell}
            title={roch.KS[fitIndex]}
          >
            {formatNumber(roch.KS[fitIndex])}
          </span>
          <span
            className={styles.metricsCell}
            title={roch.LOGLOSS[fitIndex]}
          >
            {formatNumber(roch.LOGLOSS[fitIndex])}
          </span>
        </div>
        <div className={styles.titles}>
          <div className={styles.metricsTitle}>
            {EN.ConfusionMatrix}
          </div>
          {project.criteria === 'cost' ? (
            <div className={styles.metricsTitle}>{EN.CostBased}</div>
          ) : null}
        </div>
        <PredictTable
          models={model}
          model={model}
          yes={yes}
          no={no}
          project={project}
        />
      </div>
    )}
    {project.problemType === 'Clustering' && (
      <div className={styles.metrics}>
        <div
          className={classnames(
            styles.metricsRow,
            styles.metricsHeader,
          )}
        >
          <span className={styles.metricsCell} />
          {!project.bigData && <span className={styles.metricsCell}>CVNN</span>}
          {!project.bigData && <span className={styles.metricsCell}>R square</span>}
          {!project.bigData && <span className={styles.metricsCell}>RMSSTD</span>}
          {!project.bigData && <span className={styles.metricsCell}>CH Index</span>}
          {!project.bigData && <span className={styles.metricsCell}>Silhouette Cosine</span>}
          <span className={styles.metricsCell}>Silhouette {!project.bigData ? 'Euclidean' : 'Score'}</span>
          {(!project.bigData && !!target.length) && <span className={styles.metricsCell}>{EN.adjustMutualInfo}</span>}
          {(!project.bigData && !!target.length) && <span className={styles.metricsCell}>{EN.adjustRandScore}</span>}
        </div>
        <div className={styles.metricsRow}>
          <span className={styles.metricsCell} title={modelName}>{modelName}</span>
          {!project.bigData && <span className={styles.metricsCell} title={score.CVNN}>
            {formatNumber(score.CVNN)}
          </span>}
          {!project.bigData && <span className={styles.metricsCell} title={score.RSquared}>
            {formatNumber(score.RSquared)}
          </span>}
          {!project.bigData && <span className={styles.metricsCell} title={score.RMSSTD}>
            {formatNumber(score.RMSSTD)}
            </span>}
          {!project.bigData && <span className={styles.metricsCell} title={score.CH}>
            {formatNumber(score.CH)}
          </span>}
          {!project.bigData && <span className={styles.metricsCell} title={score.silhouette_cosine}>
            {formatNumber(score.silhouette_cosine)}
          </span>}
          <span className={styles.metricsCell} title={score.silhouette_euclidean}>
            {formatNumber(score.silhouette_euclidean)}
          </span>
          {(!project.bigData &&!!target.length) && <span className={styles.metricsCell} title={(model.realLabelScore || {}).adjust_mutual_info}>
            {formatNumber((model.realLabelScore || {}).adjust_mutual_info)}
          </span>}
          {(!project.bigData &&!!target.length) && <span className={styles.metricsCell} title={(model.realLabelScore || {}).adjust_rand_score}>
            {formatNumber((model.realLabelScore || {}).adjust_rand_score)}
          </span>}
        </div>
        <D3D2 project={project} url={model.multiVarPlotData} chartData={graphicList?.multiVar.plot.data}/>
      </div>
    )}
    {project.problemType === 'Outlier' && (
      <div className={styles.metrics}>
        <div
          className={classnames(
            styles.metricsRow,
            styles.metricsHeader,
          )}
        >
          <span className={styles.metricsCell} />
          {!project.bigData && <span className={styles.metricsCell}>{EN.Score}</span>}
          <span className={styles.metricsCell}>{EN.ContaminationRate}</span>
          {(!project.bigData && !!target.length) && <span className={styles.metricsCell}>{EN.PerformanceAUC}</span>}
          {(!project.bigData && !!target.length) && <span className={styles.metricsCell}>{EN.Accuracys}</span>}
        </div>
        <div className={styles.metricsRow}>
          <span className={styles.metricsCell} title={modelName}>{modelName}</span>
          {!project.bigData && <span className={styles.metricsCell} title={score.score}>
            {formatNumber(score.score)}
          </span>}
          <span className={styles.metricsCell} title={model.rate || 0}>
            {formatNumber(model.rate || 0)}
          </span>
          {(!project.bigData && !!target.length) && <span className={styles.metricsCell} title={score.auc || 0}>
            {formatNumber(score.auc || 0)}
          </span>}
          {(!project.bigData && !!target.length) && <span className={styles.metricsCell} title={model.accuracyData[formatNumber(model.rate)] || 0}>
            {formatNumber(model.accuracyData[formatNumber(model.rate)] || 0)}
          </span>}
        </div>
        <ISO project={project} chartData={graphicList.iso.plot.data}/>
      </div>
    )}
  </div>
}

export default observer(Metrics)
