import React, { ReactElement } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Detail from 'components/Detail';
import Deploy from 'components/Deploy';
import Main from 'components/Main/index';
import Home from 'components/Home';
import Test from 'components/Test';
import Support from 'components/Support';
import Account from 'components/Account';

export default function Router():ReactElement{
  return  <Switch>
    <Route exact path="/" component={(props) => <Home {...props} />} />
    <Route exact path="/deploy" component={(props) => <Deploy {...props} />} />
    <Route exact path="/test" component={(props) => <Test {...props} />} />
    <Route path="/project/:pid" component={(props) => <Main {...props} />} />
    <Route path="/deploy/project/:id" component={(props) => <Detail {...props} />} />
    <Route exact path="/support" component={(props) => <Support {...props} />} />
    <Route exact path='/account' component={(props) => <Account {...props}/>} />
    <Redirect to="/" />
  </Switch>
}
