import React from 'react';
import moment from 'moment';
import styles from './styles.module.css';
import { Button } from 'antd';

interface Interface {
  restriction: {
    user: {
      startTime;
      endTime;
      portalUrl: string
      renew?: boolean,
      type?: number
    };
    restriction: any;
    used: any;
  };
}

const Charts = (props: Interface) => {
  const {
    restriction: {
      user: {
        startTime,
        endTime,
        portalUrl,
        renew,
        type
      },
      restriction: {
        NoOfModeling,
        NoOfPredictions,
        Projects,
        ConcurrentProjects,
        ModelingDataSize,
        NoOfUsers,
        StorageSpace,
        DataFormat,
        APIAccess,
        ResourcePriority,
        TechnicalSupport,
        amount,
        TYPE,
        TIME,
        UL,
        SA,
      },
      used: { projectCount: _projectCount, modelCount: _modelCount, storageCount: _storageCount, deployCount: _deployCount },
    },
  } = props;

  const Text = num => {
    if (isNaN(num)) {
      return num;
    }
    let unit = "bytes";
    if (num > 1024 * 1024 * 1024) {
      unit = " Gb";
      return (num / (1024 * 1024 * 1024)).toFixed(2) + unit;
    }
    if (num > 1024 * 1024) {
      unit = " Mb";
      return (num / (1024 * 1024)).toFixed(2) + unit;
    }
    if (num > 1024) {
      unit = " kb";
      return (num / 1024).toFixed(2) + unit;
    }

    return num.toFixed(2) + unit;
  };

  const InfToUnl = num => num == Infinity ? 'Unlimited' : num;
  const { isEN } = (window as any).r2_env;
  const billType = isEN ? '(USD)' : '(RMB)';
  const subs = TIME === 'MONTH' ? 'Monthly ' : 'Annual ';
  const price = (isEN ? `$` : '¥') + amount / 100;

  const data = [
    {
      name: `${amount ? subs : ''}Subscription${billType}`,
      value: amount ? price : '-',
    },
    {
      name: 'Modeling Data Size',
      value: ModelingDataSize + (ModelingDataSize === 'UNLIMITED' ? '' : 'MB'),
    }, {
      name: 'No. of Users',
      value: NoOfUsers,
    }, {
      name: 'No. of Modeling',
      value: `${_modelCount}/${InfToUnl(NoOfModeling)}`,
    }, {
      name: 'No. of Predictions',
      value: `${_deployCount}/${InfToUnl(NoOfPredictions)}`,
    }, {
      name: 'Storage Space',
      value: `${Text(_storageCount)}/${StorageSpace}${(StorageSpace === 'UNLIMITED' ? '' : 'GB')}`,
    }, {
      name: 'Concurrent Projects',
      value: ConcurrentProjects,
    }, {
      name: 'Projects',
      value: `${_projectCount}/${InfToUnl(Projects)}`,
    }, {
      name: 'Data Format',
      value: DataFormat,
    }, {
      name: 'API Access',
      value: APIAccess,
    }, {
      name: 'Resource Priority',
      value: ResourcePriority,
    }, {
      name: 'Technical Support',
      value: TechnicalSupport,
    },
  ];

  return (
    <section className={styles.table}>
      <div className={styles.title}>
        {TYPE}
      </div>

      <div className={styles.time}>
        <div style={{ display: (startTime ? '' : 'none') }}>
          Start Date {moment(startTime).format('YYYY-MM-DD hh:mm')} - Expiry
            Date {moment(endTime).format('YYYY-MM-DD hh:mm')}
        </div>
        <dl className={styles.add}>
          <dt>Included Algorithms Packs:</dt>
          <dd>
            <ol>
              <li>Supervised learning (Classification, Regression)</li>
              <li style={{ display: UL ? '' : 'none' }}>Unsupervised learning (Clustering, Anomaly Detection, Association)</li>
              <li style={{ display: SA ? '' : 'none' }}>Time Series Analysis</li>
            </ol>
          </dd>
        </dl>
        <dl className={styles.tb}>
          {
            data.map(itm => <dd key={itm.name}>
              <span>{itm.name}</span>
              <span>{itm.value}</span>
            </dd>)
          }
        </dl>
      </div>
      <div className={styles.cancel} style={{ display: portalUrl ? '' : 'none' }}>
        {/* {type !== 1 && <Button onClick={()=>window.open(portalUrl+'/purchase')} >
          Purchase
        </Button>} */}
        {renew && <Button onClick={() => window.open(portalUrl + '/cancelRenewal')} style={{ marginLeft: 20 }}>
          Cancel Subscription
        </Button>}
      </div>
    </section>
  );
};

export default Charts;
