import React, { ReactElement, useState } from 'react';
import styles from './styles.module.css';
import { observer } from 'mobx-react';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ProgressBar, Show } from '..';
import EN from '../../../constant/en';
import { ProjectRolesKey } from '@app/constant/types';

interface Interface {
  readonly trainingModel:any
  readonly isAbort:boolean
  readonly abortTrain:(id)=>void
  readonly showAbortButton:boolean
}

const TrainModel = (props: Interface):ReactElement => {
  const { trainingModel, isAbort, abortTrain, showAbortButton } = props;
  const [abort, setAbort] = useState(false);

  const handleTrain = () => {
    setAbort(true);
    abortTrain(trainingModel.requestId);
  };
  
  return (
    <div className={styles.rowData}>
      <div className={styles.trainingModel}>
        <Tooltip title={trainingModel.actionKey || EN.TrainingNewModel}>
          <span id={styles.trainingModelSpan}>
            {trainingModel.actionKey || EN.TrainingNewModel}
          </span>
        </Tooltip>
      </div>
      <ProgressBar waiting={trainingModel?.waiting}  progress={trainingModel.value || 0} />

      {showAbortButton && <Show name={ProjectRolesKey.ModelingAbort}>
        <div
          className={styles.abortButton}
          onClick={!isAbort && !abort ? handleTrain : null}
        >
          {isAbort || abort ? (
            <LoadingOutlined />
          ) : (
            <span id={styles.abortButtonSpan}>{EN.AbortTraining}</span>
          )}
        </div>
      </Show>}
    </div>
  );
};

export default observer(TrainModel);
