import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import ClassificationResult from './Classification/ClassificationResult';
import RegressionResult from './Regression/RegressionResult';
import MultiClassification from './MultiClassification';
import Model from 'stores/Model';
import Project from 'stores/Project';
const Classification = 'Classification';

interface Interface {
  models: Model[];
  project: Project;
  sort: {
    key: string;
    value: number;
  };
  handleSort: (k: string) => void;
}

function SimpleView(props: Interface): ReactElement {
  const { models, project, sort, handleSort } = props;
  const { problemType } = project;

  switch (problemType) {
    case Classification:
      return <ClassificationResult
          models={models}
          project={project}
          sort={sort}
          handleSort={handleSort}
      />
    case 'MultiClassification':
      return <MultiClassification
          models={models}
          project={project}
          sort={sort}
          handleSort={handleSort}
      />
    default:
      return <RegressionResult
          models={models}
          project={project}
          sort={sort}
          handleSort={handleSort}
      />
  }
}

export default observer(SimpleView);
