import React, { Component } from 'react';
import styles from './styles.module.css';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import VariableList from './VariableList';
import { PredictTable } from './Score';
import { observable, action } from 'mobx';
import { Checkbox } from 'antd';
import { formatNumber } from '../../util';
import EN from '../../constant/en';
import MPF from '../Modeling/Result/MPF';
import AdvancedView from 'components/Modeling/Result/AdvancedView'
import AdvancedModelTable from './Score/AdvancedModelTable';
import Summary from '../Data/Quality/Summary';
import Metrics from './Model/Metrics';
import VariableImpact from 'components/Modeling/Result/VariableImpact';

const listdisplayProp: any = {};
function displayProp(obj: any) {
  let Categorical = 0;
  let Numerical = 0;
  // for (let name in obj) {
  //   if (obj[name] === 'Categorical' || obj[name] === 'Raw') {
  //     Categorical += 1;
  //   } else if (obj[name] === 'Numerical') {
  //     Numerical += 1;
  //   }
  // }
  Object.entries(obj).forEach(itm => {
    const [, type] = itm;
    if (type === 'Categorical' || type === 'Raw') {
      Categorical += 1;
    } else if (type === 'Numerical') {
      Numerical += 1;
    }
  });

  listdisplayProp.Categorical = Categorical;
  listdisplayProp.Numerical = Numerical;
  return listdisplayProp;
}

interface Interface {
  projectStore?: any
  project?: any
}

@inject('projectStore')
@observer
class Report extends Component<Interface> {
  @observable isEdit = false;

  @observable config = {
    profile: true,
    dataSchema: true,
    dataQuality: true,
    dataAnalysis: true,
    correlationMatrix: true,
    modelResult: true,
    modelName: true,
    metrics: true,
    variableImpact: true,
    score: true,
    processFlow: true,
  };

  constructor(props) {
    super(props);
    props.projectStore.currentId = props.projectStore.list.id;
    (window as any).rr = this;
    console.log(this, 'this');
  }

  @observable show = false;
  @observable sort = {
    simple: {
      key: 'name',
      value: 1,
    },
    advanced: {
      key: 'Model Name',
      value: 1,
    },
  };
  @observable metric = this.props.projectStore.list[0].measurement;

  handleSort = (view, key) => {
    const sort = this.sort[view];
    if (!sort) return;
    if (sort.key === key) sort.value = -sort.value;
    else {
      sort.key = key;
      sort.value = 1;
    }
    this.sort = { ...this.sort, [view]: sort };
  };

  handleChange = action(value => {
    this.metric = value;
  });

  reset = () => {
    const project = this.props.projectStore.list;
    project.selectModel.resetFitIndex();
    project.costOption.FN = 0;
    project.costOption.FP = 0;
    project.costOption.TN = 0;
    project.costOption.TP = 0;
  };

  isShow = name => {
    return this.isEdit || this.config[name];
  };

  checkChange = name =>
    action((e: any) => {
      if (name === 'modelResult') {
        if (this.config.modelResult) {
          this.config.modelResult = false;
          this.config.modelName = false;
          this.config.metrics = false;
          this.config.variableImpact = false;
          this.config.score = false;
          this.config.processFlow = false;
        } else {
          this.config.modelResult = true;
          this.config.modelName = true;
          this.config.metrics = true;
          this.config.variableImpact = true;
          this.config.score = true;
          this.config.processFlow = true;
        }
      }
      this.config[name] = e.target.checked;
    });

  checkBox = name =>
    this.isEdit && (
      <Checkbox onChange={this.checkChange(name)} checked={this.config[name]} />
    );

  render() {
    const {
      projectStore: {
        list,
      },
    } = this.props;
    const { problemType, selectModel: model } = list[0];

    const arr = ['Regression', 'MultiClassification', 'Classification']

    const isUn = !arr.includes(problemType)

    displayProp(list[0].colType);
    return (
      <div className={styles.report}>
        <h1 className={styles.totalTitle}>
          {EN.ProjectReport}: {list[0].name}
          {/*<small onClick={this.toggleEdit}>{this.isEdit ? EN.Save : EN.Edit}</small>*/}
        </h1>
        {this.isShow('profile') && (
          <div className={classnames(styles.block, styles.profile)}>
            {this.checkBox('profile')}
            <h3 className={styles.blockTitle}>{EN.Profile}</h3>
            <div className={styles.blockRow}>
              {EN.ProjectStatement}: {list[0].statement || '-'}
            </div>
            <div className={styles.blockRow}>
              {EN.BusinessValue}: {list[0].business || '-'}
            </div>
            <div className={styles.blockRow}>
              {EN.ProblemType}: {list[0].problemType}
            </div>
            <div className={styles.blockRow}>
              {EN.Dataset}: {list[0].fileName || '-'}
            </div>
          </div>
        )}
        {this.isShow('dataSchema') && (
          <div className={styles.block}>
            {this.checkBox('dataSchema')}
            <h3 className={styles.blockTitle}>{EN.DataSchemas}</h3>
            <div className={styles.schema}>
              <div
                className={classnames(styles.schemaRow, styles.schemaHeader)}
              >
                <span className={styles.schemaCell}>Num of Rows</span>
                <span className={styles.schemaCell}>Num of Columns</span>
                <span className={styles.schemaCell}>Num of Categorical Variables</span>
                <span className={styles.schemaCell}>Num of Numerical Variables</span>
              </div>
              <div className={styles.schemaRow}>
                <span className={styles.schemaCell}>
                  {formatNumber(list[0].totalRawLines)}
                </span>
                <span className={styles.schemaCell}>
                  {formatNumber(list[0].rawHeader.length.toString())}
                </span>
                <span className={styles.schemaCell}>
                  {listdisplayProp.Categorical}
                </span>
                <span className={styles.schemaCell}>
                  {listdisplayProp.Numerical}
                </span>
              </div>
            </div>
          </div>
        )}
        {!list[0].noCompute && this.isShow('dataQuality') && (
          <div className={styles.block}>
            {this.checkBox('dataQuality')}
            <h3 className={styles.blockTitle}>{EN.DataQuality}</h3>
            <Summary
              project={list[0]}
              report
            />
          </div>
        )}
        {this.isShow('dataAnalysis') && (
          <div className={styles.block}>
            {this.checkBox('dataAnalysis')}
            {list[0].correlationMatrixData ? (
              <div>
                <h3 className={styles.blockTitle}>
                  {EN.ExploratoryDataAnalysis}
                </h3>
                <div className={styles.blockRow}>
                  <VariableList project={list[0]} />
                </div>
              </div>
            ) : null}
          </div>
        )}

        <div className={styles.modelResult}>
          {this.isShow('modelResult') && (
            <h1 className={styles.title}>{EN.ModelResult}</h1>
          )}
          {this.checkBox('modelResult')}
          {this.isShow('metrics') && (
            <Metrics project={list[0]} checkBox={this.checkBox} />
          )}


          {(list[0].problemType !== 'Outlier' && this.isShow('score')) && (
            <div className={classnames(styles.block, styles.score)}>
              {this.checkBox('score')}
              <div className={styles.blockRow}>
                <AdvancedView models={list[0].models}
                  project={list[0]}
                  sort={{
                    key: 'name',
                    value: 1,
                  }}
                  handleSort={() => { }}
                  metric={this.metric}
                  handleChange={this.handleChange}
                  currentSettingId='all'
                  changeSetting={() => { }}
                  report={true}
                />
              </div>
            </div>
          )}

          {list[0].problemType !== 'Outlier' ? <AdvancedModelTable
            {...this.props}
            models={list[0].models}
            project={list[0]}
            sort={{
              key: 'name',
              value: 1,
            }}
            handleSort={() => { }}
          /> : !list[0].bigData ? <div className={classnames(styles.block, styles.VariableImpact)}>
              {this.checkBox('variableImpact')}
              <h3 className={styles.blockTitle}>{EN.VariableImpact}</h3>
              <div className={styles.blockRow}><VariableImpact model={list[0].selectModel} mapHeader={list[0].mapHeader} /></div>
            </div>: null}

          {!list[0].bigData && <div className={classnames(styles.block, styles.processFlow)}>
            {this.checkBox('processFlow')}
            <h3 className={styles.blockTitle}>{EN.ModelProcessFlow}</h3>
            <div className={styles.blockRow}>
              <MPF
                modelId={isUn ? '' : model.id}
                project={list[0]}
                model={list[0].selectModel}
              />
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

export default Report;
