import React, { useContext, useEffect, useRef, useState } from 'react';
import katex from 'katex'
import 'katex/dist/katex.css'
import styles from './styles.module.css'
import EN from '../../../../../constant/en';
import { MobXProviderContext } from 'mobx-react';
// import Project from 'stores/Project';
import _ from 'lodash';


export default function ModelInterpretation(props){
  const {linearData,treeData,modelName} = props;
  const dom = useRef(null);
  const [url,setUrl] = useState('');
  const {
    projectStore:{
      project:{
        readFile
      }
    },
  } = useContext(MobXProviderContext);

  useEffect( ()=>{
    readFile(linearData||treeData).then((result:any)=>{
      if(linearData && dom?.current){
        let {
          coef=[],
          feature=[],
          intercept//:[intercept],
        } = result;
        if(intercept>0){
          intercept = '+' + intercept;
        }
        let list = '';
        _.zip(coef,feature).forEach((itm:any,ind)=>{
          const dt = itm[1].replace(/_/g,'\\_');
          if(ind>1000)return;
          list+=`${itm[0]}*${dt}\\\\`
        });
        katex.render(`Z=\\Sigma \\allowbreak\t
      \\begin{Bmatrix} 
         ${list}
      \\end{Bmatrix}
     \\allowbreak\t ${intercept}`,
          dom.current, {
            throwOnError: false,
            maxExpand:Infinity,
          });
      }else{
        setUrl(result)
      }
    })
  },[]);

  const [downloadUrl,upDownloadUrl] = useState('');

  useEffect(()=>{
    if(url){
      const arr = url.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      upDownloadUrl(URL.createObjectURL(new Blob([u8arr], { type: mime })))
    }
  },[url]);

  if(linearData){    
    return <div ref={dom} className={styles.ModelInterpretation}/>
  }

  return <section className={styles.treeData}>
    {downloadUrl&&<a href={downloadUrl} download={modelName+'.png'}><i/>{EN.PictureDownload}</a>}
    <img src={url} alt=""/>
  </section>
}
