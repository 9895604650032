import React, { useContext, useState } from 'react';
import styles from './Start.module.css';
import { observer, MobXProviderContext } from 'mobx-react';
import { Modal, Show } from 'components/Common';
import { CloudDownloadOutlined, StarFilled } from '@ant-design/icons';
import autoIcon from './icon_automatic_modeling.svg';
import advancedIcon from './icon_advanced_modeling.svg';
import EN from '../../../constant/en';
import { v4 as uuidV4 } from 'uuid';
import moment from 'moment';
import AdvancedModel from './AdvancedModel'
import Association from './Association'
import TimeSeries from './TimeSeries'
import { ProjectStore } from 'stores/ProjectStore';
import { ProjectRolesKey } from '@app/constant/types';

function StartTrain(){
  // @ts-ignore
  const {
    projectStore:{
      project:{
        newSetting,
        settings,
        problemType,
        id,
        fileName,
        bigData,
      },
      project,
    },
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext)
   const [visible,upVisible] = useState(false)

    function fastTrain() {
      if (!project.roles[ProjectRolesKey.StartAutomaticModeling]) return;
      const setting = newSetting();
      const name = `auto_${moment().format('YYYYMMDD_HHmmss')}`;
      const id = uuidV4();
      settings.push({
        setting,
        name,
        id,
        models: [],
      });
      project.settingId = id;
      project.fastTrain();
    }

    const advanced = () => {
      if (!project.roles[ProjectRolesKey.AdvancedModelingConfig]) return;
      upVisible(true)
    };

    const closeAdvanced = () => {
      upVisible(false)
    };

    const realName = fileName?.endsWith('.csv') ? fileName.slice(0, -4) : fileName;
    const filename = `${realName}-clean.csv`
    const cleanUrl = `/r2upload/download/clean?projectId=${id}&filename=${filename}`
    if (problemType === 'Association') return <Association project={project} />;
    if (problemType === 'Forecasting') return <TimeSeries project={project} />;
    return (
      <div className={styles.modelStart}>
        <div className={styles.startTitle}>
          <span>{EN.PrepareToModel}
            &nbsp;&nbsp;
          {!bigData && <a href={cleanUrl} target="_blank" className={styles.downloadEtlData}>
              <CloudDownloadOutlined />
              {EN.DownloadEtlData}
            </a>}
          </span>
        </div>
        <div className={styles.trainWarp}>
          <div className={styles.trainBox}>
            <div className={styles.trainBlock} onClick={fastTrain.bind(this)}>
              <div className={styles.trainRecommend}>
                <span>
                  <StarFilled style={{ color: '#50647a' }} />
                  {EN.Recommended}
                </span>
              </div>
              <div className={styles.trainImg}>
                <img src={autoIcon} alt="auto" />
              </div>
              <div className={styles.trainName}>
                <span>{EN.EasyAndSimple}</span>
              </div>
            </div>
            <Show
              name={ProjectRolesKey.StartAutomaticModeling}
            >
              <button
                className={styles.train} onClick={fastTrain.bind(this)}>
                <span>{EN.AutomaticModeling}</span>
              </button>
            </Show>

          </div>
          <div className={styles.trainSep} />
          <div className={styles.trainBox}>
            <div className={styles.trainBlock} onClick={advanced}>
              <div className={styles.trainImg}>
                <img src={advancedIcon} alt="advanced" />
              </div>
              <div className={styles.trainName}>
                <span>{EN.DetailedAndAdvanced}</span>
              </div>
            </div>
            <Show
              name={ProjectRolesKey.AdvancedModelingConfig}
            >
            <button className={styles.train} onClick={advanced}>
              <span>{EN.AdvancedModeling}</span>
            </button>
            </Show>
          </div>
        </div>
        <Modal
          width="13em"
          content={
            <AdvancedModel
              project={project}
              closeAdvanced={closeAdvanced}
            />
          }
          title={EN.AdvancedModeling}
          onClose={closeAdvanced}
          visible={visible}
          closeByMask={true}
          showClose={true}
        />
      </div>
    );
}

export default observer(StartTrain)
