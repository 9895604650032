import { observable, action, computed, when } from 'mobx';
import userStore from 'stores/UserStore';
import Deployment from './Deployment';
import socketStore from 'stores/SocketStore';
import EN from '../../src/constant/en'
import Project from './Project'
import { message } from 'antd';

const sortStrategies = {
  createdDate: (a, b) =>
    a.created_at === b.created_at
      ? 0
      : a.created_at > b.created_at
        ? -1
        : 1,
  rcreatedDate: (a, b) =>
    a.created_at === b.created_at
      ? 0
      : a.created_at < b.created_at
        ? -1
        : 1,
  projectName: (a, b) => a.project?.name.localeCompare(b.project?.name),
  rprojectName: (a, b) => a.project?.name.localeCompare(b.project?.name) * -1,
  modelName: (a, b) => a.model_name.localeCompare(b.model_name),
  rmodelName: (a, b) => a.model_name.localeCompare(b.model_name) * -1
};

const filter = (keywords, deployments) => {
  if (!keywords || keywords === '') return deployments;
  const result: any[] = [];
  deployments.map((_d, index) => {
    const _results = keywords
      .split(' ')
      .map(
        word =>
            _d.project?.name?.includes(word) ||
            _d.userId?.includes(word) ||
            _d.model_name?.toLowerCase()?.includes(word.toLowerCase())||
            String(_d.project?.id)?.includes(word)
      );
    if (_results.indexOf(false) === -1) result.push(_d);
    return true;
  });
  return result;
};

class DeploymentStore {
  @observable sortByOptions = {
    createdDate: EN.CreatedDate + '∧',
    rcreatedDate: EN.CreatedDate + '∨',
    projectName: EN.ProjectName + '∧',
    rprojectName: EN.ProjectName + '∨',
    modelName: EN.ModelName + ' ∧',
    rmodelName: EN.ModelName + ' ∨'
  };

  @observable deployments = [];
  @observable watchingList = false
  @observable currentId;
  @observable
  sortOptions = {
    keywords: '',
    sortBy: 'createdDate',
    perPage: 10,
    currentPage: 1
  };
  @observable currentModel;
  @observable init;
  @observable updatedBySelf = 0;

  @computed
  get totalCount() {
    return filter(this.sortOptions.keywords, this.deployments).length;
  }

  @computed
  get sortedDeployments() {
    const _deployments = this.deployments.slice();
    let result;

    // keywords
    result = filter(this.sortOptions.keywords, _deployments);

    // order
    result = result.sort(sortStrategies[this.sortOptions.sortBy]);

    // pagination
    const start =
      (this.sortOptions.currentPage - 1) *
      parseInt(this.sortOptions.perPage.toString(), 10);
    const end = start + parseInt(this.sortOptions.perPage.toString(), 10);
    result = result.slice(start, end);

    return result;
  }

  @computed
  get currentDeployment() {
    const _deployment = this.deployments.find(({ id }) => id === parseInt(this.currentId, 10));
    return new Deployment(_deployment || {});
  }

  constructor() {
    socketStore.ready().then((api: any) => {
      this.initWatch()
      api.addEventListener('online', this.initWatch)
      api.addEventListener('offline', this.onOffline)
    })
  }

  initWatch = () => {
    let si;
    when(
      () => userStore.status === 'login',
      () =>
        socketStore.ready().then((api: any) => {
          const callback = action((response: any) => {
            this.init = true;
            if(this.updatedBySelf <= 0){
              this.updatedBySelf = 0;
              this.deployments = response.list.filter(itm=>itm);
            } else 
              this.updatedBySelf --;
            this.watchingList = true;
            clearInterval(si)
          })
          if(!this.init){
            si = setInterval(()=>{
              api.watchDeployments().then(callback);
            },10000)
          }
          api.watchDeployments().then(callback);
          api.addEventListener('watchDeployments', callback)
        })
    );
  }

  onOffline = action(() => {
    this.watchingList = false;
  })

  async setWarning(id,warning,warningEmails){
    const api: any = await socketStore.ready();
    return api.warningSet({
      id,
      warning,
      warningEmails
    })
  }

  dataChange = action((id, data) => {
    this.deployments.forEach(deployment=> {
      if (deployment.id === id) {
        Object.keys(data).map(x=>{
          deployment[x] = data[x];
        })
        return;
      }
    });
  })

  setUpdatedBySelf = action(() => {
    this.updatedBySelf++;
  })

  @action
  setCurrentDeployment(currentId){
    this.currentId = currentId;
  }
  async addDeployment(projectId, projectName, modelName, modelType, mapHeader, bucket, bigData,supportIncrement,version='') {
    const data = {
      deploymentOptions: {},
      incrementOptions:{},
      modelName,
      modelType,
      projectId,
      projectName,
      mapHeader,
      bucket,
      bigData,
      performanceOptions: {},
      supportIncrement,
      version,
    };
    const api: any = await socketStore.ready();
    const checkResponse = await api.getProjectDeployment({ projectId })
    if (checkResponse.deploymentId) {
      api.updateDeploymentModel({
        deploymentId: checkResponse.deploymentId,
        modelName,
        modelType,
        bucket,
        mapHeader,
        bigData,
        version,
        supportIncrement
      })
      return checkResponse.deploymentId
    }
    const response = await api.addDeployment({ data });
    if (response.status !== 200) {
      throw new Error(response.message);
    }
    return response.id;
  }

  @action
  create(project) {
    return new Promise((resolve, reject) => {
      socketStore.ready().then((api: any) => api.addDeployment({ project }));
    });
  }

  @action
  change(id, key, value) {
    const _d = new Deployment(this.deployments.find((_d: any) => _d.id === id));
    _d[key] = value;
    return _d.save();
  }

  @action
  toggleEnable = () => {
    this.currentDeployment.enable = true;
    return this.currentDeployment.save();
    // const _d = new Deployment(this.deployments.find((_d: any) => _d.id === id));
    // _d.enable = value
    // return _d.save();
    // 当前toggleEnable调用value全部为true，且suspendDeployment函数无任何可用逻辑，故暂时删除
    // if (value) {
    //   ;
    // } else {
    //   _d.enable = !_d.enable;
    //   socketStore.ready().then((api: any) => {
    //     if (_d.enable === false) {
    //       // api.suspendDeployment({ id });
    //     } else if (_d.enable === true) {
    //       return api.deploySchedule({
    //         deploymentId: id,
    //         threshold: {
    //           type: _d.performanceOptions.measurementMetric,
    //           value: _d.performanceOptions.metricThreshold
    //         }
    //       });
    //     }
    //   });
    // }
  }

  @action
  delete(id) {
    socketStore.ready().then((api: any) => api.removeDeployment({ id }));
  }

  @action
  changeSort = (key, value) => {
    this.sortOptions[key] = value;
  };

  enableDeployment = (enable,id)=>{
    socketStore.ready().then((api: any) => {
      return api.updateDeployment({
        data:{
          enable,
          id
        }
      })
    });
  }

  deploySchedule = (deploymentId, threshold = null,version=null) => socketStore.ready().then((api: any) => {
    return api.deploySchedule({
      deploymentId,
      threshold,
      version,
    })
  });
}

export default new DeploymentStore();

export { DeploymentStore }
