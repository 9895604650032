import React, { ReactElement, useEffect, useRef } from 'react';
import styles from './styles.module.css';
import { observer, useLocalStore } from 'mobx-react';
import { ProcessLoading } from 'components/Common';
import EN from '../../../constant/en';
import Project from 'stores/Project';

interface Interface {
  readonly project: Project;
  readonly onClose: () => void;
  readonly visible: boolean;
  readonly selectSample: (obj: unknown) => void;
}

function DataSample(props: Interface): ReactElement {
  const {
    project: { getSample },
    selectSample,
    onClose,
    visible,
  } = props;

  const domRef = useRef(null);

  const store = useLocalStore(() => ({
    files: [],
    loading: true,
    select: -1,
    updateField(data) {
      Object.assign(this, data);
    },
  }));

  useEffect(() => {
    getSample().then(list => {
      store.updateField({
        files: list,
        loading: false,
      });
    });
  }, []);

  const onSelect = (index: number) => {
    store.updateField({
      select: index,
    });
  };

  const submit = () => {
    const file = (store.files || [])[store.select];
    // const file = (store.files ??= [])[store.select];
    if (!file) return;
    store.updateField({
      loading: true,
    });
    // Axios.post('/r2upload/sample', { filename: file.name }).then(
    // (result: any) => {
    // if (result.status !== 200) return message.error('select sample error');
    // const data = result.data.data;
    // selectSample({
    //   // rawHeader: data.header,
    //   // totalRawLines: data.lines,
    //   originalIndex: file.name,
    //   fileName: file.name,
    //   uploadCost: 0,
    // });
    selectSample({
      fileId: file.file,
      fileName: file.name,
      fileSize: file.size,
      uploadCost: 0
    })
    store.updateField({
      loading: false,
    });
    // },
    // );
  };

  const formatSize = (size: number) => {
    let { size: s, n } = getSize(size);
    if (n < 0) n = 1;
    const unit =
      (n === 1 && 'b') ||
      (n === 2 && 'Kb') ||
      (n === 3 && 'Mb') ||
      (n === 4 && 'Gb') ||
      'Tb';
    return parseInt((s * 100).toString(), 10) / 100 + ' ' + unit;
  };

  const getSize = (
    size: number,
    n: number = 1,
  ): { size: number; n: number } => {
    if (n >= 5) return { size, n };
    const s = size / 1024;
    if (s > 1) return getSize(s, ++n);
    return { size, n };
  };

  if (!visible) return null;

  return store.loading ? <ProcessLoading style={{ position: 'fixed' }} /> : <div className={styles.sample} ref={domRef}>
    <div className={styles.cover} onClick={onClose} />
    <div className={styles.sampleBlock}>
      <div className={styles.sampleTitle}>
        <span>{EN.ChooseSampleD}</span>
        <div className={styles.close} onClick={onClose}>
          <span>X</span>
        </div>
      </div>
      <div className={styles.sampleTop}>
        <span>{EN.SelectSampleData}</span>
      </div>
      <div className={styles.sampleTable}>
        <div className={styles.sampleHeader}>
          <div className={styles.sampleCell}>
            <span>{EN.Name}</span>
          </div>
          <div className={styles.sampleCell}>
            <span>{EN.FileName}</span>
          </div>
          <div className={styles.sampleCell}>
            <span>{EN.TargetVariable}</span>
          </div>
          {/* <div className={styles.sampleCell}>
            <span>{EN.DataLines}</span>
          </div> */}
          <div className={styles.sampleCell}>
            <span>{EN.DataSize}</span>
          </div>
        </div>
        {(store.files || []).map((row, index) => {
          return (
            <div
              className={styles.sampleRow}
              key={index}
              onClick={onSelect.bind(null, index)}
            >
              <div className={styles.sampleRadio}>
                <input
                  type="radio"
                  name="sampleSelect"
                  checked={store.select === index}
                  onChange={onSelect.bind(null, index)}
                />
              </div>
              <div className={styles.sampleCell} title={row.usecase}>
                <span>{row.name}</span>
              </div>
              <div className={styles.sampleCell} title={row.filename}>
                <span>{row.name}</span>
              </div>
              <div className={styles.sampleCell} title={row.target}>
                <span>{row.target}</span>
              </div>
              {/* <div className={styles.sampleCell}>
                <span>{formatNumber(row.lines)}</span>
              </div> */}
              <div className={styles.sampleCell} title={row.size}>
                <span>{formatSize(row.size)}</span>
              </div>
            </div>
          );
        })}
        <div className={styles.sampleButton}>
          <button onClick={submit} className={styles.submit}>
            <span>{EN.LoadSampleData}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

}

export default observer(DataSample);
