import React, { ReactElement } from 'react';
import ReactEcharts from 'echarts-for-react';
import Project from 'stores/Project';
import _ from 'lodash';
import { formatNumber } from '../../util';

interface Interface {
  message: {
    avg: {
      data:any
      name:{
        x:string
        y:string
      }
    };
    freq: {
      data:any
      name:{
        x:string
        y:string
      }
    };
  };
  project: Project;
}

export default function MIBox3D(props: Interface): ReactElement {
  const {
    message: {
      avg:{
        name: { x, y },
        data:avg_data,
      },
      freq:{
        data:freq_data
      },
    },
    project: { mapHeader, colType, colMap,newType,newVariableViews },
  } = props;

  const x_name = mapHeader[x] || x;
  const y_name = mapHeader[y] || y;

  const avg_freqs: number[] = avg_data.map(itm => itm.freq);
  const freq_freqs: number[] = freq_data.map(itm => itm.freq);

  const avg_max = _.max(avg_freqs)*1.01;
  const avg_min = _.min(avg_freqs)-0.01;

  const freq_max = _.max(freq_freqs)*1.01;
  const freq_min = _.min(freq_freqs)-0.01;

  const avg_series = [[], [], [], [], [], []];
  const freq_series = [[], [], [], [], [], []];

  const CType = Object.assign({},colType,newType);

  const xc = CType[x] === 'Categorical'||!colType[x];
  const yc = CType[y] === 'Categorical'||!colType[y];
  const CData = Object.assign({},colMap,newVariableViews);
  const x_ent = Object.entries(CData[x]);
  const y_ent = Object.entries(CData[y]);

  function ns(n){
    return typeof n === 'number'?n.toFixed(6):n
  }
  avg_data.forEach(itm => {
    const { x, y, freq, data } = itm;
    let _x = x;
    let _y = y;
    if(xc){
      const type = x_ent.filter(it=>it[1] === x);
      if(type[0]&&type[0][0]){
        _x = type[0][0];
      }
    }
    if(yc){
      const type = y_ent.filter(it=>it[1] === y);
      if(type[0]&&type[0][0]){
        _y = type[0][0];
      }
    }
    data.forEach((it, index) => {
      avg_series[index].push([ns(_x), ns(_y), it, freq.toFixed(6), ...data.map(itm=>itm.toFixed(6))]);
    });
  });

  freq_data.forEach(itm => {
    const { x, y, freq, data } = itm;
    let _x = x;
    let _y = y;
    if(xc){
      const type = x_ent.filter(it=>it[1] === x);
      if(type[0]&&type[0][0]){
        _x = type[0][0];
      }
    }
    if(yc){
      const type = y_ent.filter(it=>it[1] === y);
      if(type[0]&&type[0][0]){
        _y = type[0][0];
      }
    }
    data.forEach((it, index) => {
      freq_series[index].push([ns(_x), ns(_y), it, freq.toFixed(6), ...data.map(itm=>itm.toFixed(6))]);
    });
  });

  const name = ['min', 'q25', 'median', 'q75', 'max', 'mean'];

  const selected: any = {};

  name.forEach(itm => (selected[itm] = false));
  selected.mean = true;

  const axis3D = {
    type:'category',
    axisLabel:{
      formatter:value=>{
        if(value.__proto__.toFixed){
          return value.toFixed(6)
        }
        return value;
      }
    },
    nameTextStyle:{
      color:'blue',
      fontSize:12,
    }
  };

  const avg_xAxis3D:any = {
    name:x_name,
    ...axis3D,
  };

  const freq_xAxis3D:any = {
    name:x_name,
    ...axis3D,
  };

  const avg_yAxis3D:any = {
    name:y_name,
    ...axis3D,
  };

  const freq_yAxis3D:any = {
    name:y_name,
    ...axis3D,
  };

  if(xc){
    const ent = Object.entries(CData[x]);
    let _data = [];
    avg_data.forEach(itm=>{
      const type = ent.filter(it=>it[1] === itm.x);
      if(type[0]){
        if(!_data.includes(type[0][0])){
          _data.push(type[0][0])
        }
      }
    });
    _data.length&&(avg_xAxis3D.data = _data);

    _data = [];
    freq_data.forEach(itm=>{
      const type = ent.filter(it=>it[1] === itm.x);
      if(type[0]){
        if(!_data.includes(type[0][0])){
          _data.push(type[0][0])
        }
      }
    });
    _data.length&&(freq_xAxis3D.data = _data);
  }

  if(yc){
    const ent = Object.entries(CData[y]);
    let _data = [];
    avg_data.forEach(itm=>{
      const type = ent.filter(it=>it[1] === itm.y);
      if(type[0]){
        if(!_data.includes(type[0][0])){
          _data.push(type[0][0])
        }
      }
    });
    _data.length&&(avg_yAxis3D.data = _data);

    _data = [];
    freq_data.forEach(itm=>{
      const type = ent.filter(it=>it[1] === itm.y);
      if(type[0]){
        if(!_data.includes(type[0][0])){
          _data.push(type[0][0])
        }
      }
    });
    _data.length&&(freq_yAxis3D.data = _data);
  }

  const avgSeries = avg_series.map((itm, index) => {
    return {
      name: name[index],
      type: 'bar3D',
      stack: 'stack',
      shading: 'lambert',
      data: itm,
      itemStyle:{
        color:'green'
      },
      emphasis: {
        label: {
          show: false,
        },
      },
    };
  });

  const freqSeries = freq_series.map((itm, index) => {
    return {
      name: name[index],
      type: 'bar3D',
      stack: 'stack',
      shading: 'lambert',
      data: itm,
      itemStyle:{
        color:'blue'
      },
      emphasis: {
        label: {
          show: false,
        },
      },
    };
  });

  const tooltip = {
    hideDelay:2000,
    confine:true,
    formatter:tip=>{
      const {marker,seriesName,data} = tip;
      if(!marker){
        return;
      }
      const [x,y,,freq,...n_name] = data;

      let result:string = `
            ${marker}${seriesName}<br/>
            ${x_name}:${x}<br/>
            ${y_name}:${y}<br/>
            freq:${freq}<br/>
        `;

      name.forEach((itm,index)=>{
        result += `${[itm]}:${n_name[index]}<br/>`
      });
      return result
    }
  };

  const visualMap = {
    inRange: {
      color: ['#80bdfd', '#b0e39b'],
    },
    formatter: value => {
      return value.toFixed(6);
    },
    dimension: 3,
  };

  const option:any =  {
    baseOption:{
      tooltip,
      legend: {
        orient: 'vertical',
        // left: 'left',
        selectedMode: 'single',
        selected,
        left:50,
        bottom:100,
      },
      zAxis3D: {
        type: 'value',
        name: '',
      },
      grid3D: {
        light: {
          main: {
            quality: 'ultra',
            intensity: 1.5,
          },
        },
      },
    },
    timeline:{
      axisType:'category',
      loop:false,
      data:['Avg','Freq'],
      controlStyle:{
        show:false
      },
      right:'85%',
      left:'5%',
    },
    options:[{
      series:avgSeries,
      xAxis3D:avg_xAxis3D,
      yAxis3D:avg_yAxis3D,
      visualMap:{
        ...visualMap,
        min:avg_min,
        max:avg_max,
      }
    },{
      series:freqSeries,
      xAxis3D:freq_xAxis3D,
      yAxis3D:freq_yAxis3D,
      visualMap:{
        ...visualMap,
        min:freq_min,
        max:freq_max,
      }
    }]
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: '100%', width: '80%' }}
      notMerge={true}
      lazyUpdate={true}
      theme="customed"
    />
  );
}
