import React, { forwardRef, ReactElement, useEffect, useRef } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { observer, useLocalStore, useObserver } from 'mobx-react';
import dataConnectActive from './data_prograss_a.svg';
import dataSchemaActive from './data_schema_a.svg';
import dataSchema from './data_schema.svg';
import dataQualityActive from './data_quality_a.svg';
import dataQuality from './data_quality_d.svg';

import modelSelectionIcon from './model_selection_d.svg';
import startModelingActiveIcon from './start_modeling_a.svg';
import modelSelectionActiveIcon from './model_selection_a.svg';

import EN from '../../../constant/en';
import Project from 'stores/Project';

interface Interface {
  project: Project;
  enter: any;
  keyword: any;
  ref?;
}

const ProjectSide = forwardRef(
  (props: Interface, ref): ReactElement => {
    const STEP = {
      data: [
        { label: EN.DataConnects, value: 'dataConnect' },
        { label: EN.DataSchemas, value: 'dataSchema' },
        { label: EN.DataQualitys, value: 'dataQuality' },
      ],
      modeling: [
        { label: EN.StartModeling, value: 'startModeling' },
        { label: EN.ModelSelection, value: 'modelSelection' },
      ],
    };

    const IMAGES = {
      data: {
        dataSchema: <img src={dataSchema} alt="schema" />,
        dataQuality: <img src={dataQuality} alt="quality" />,
        dataConnectActive: <img src={dataConnectActive} alt="connect" />,
        dataSchemaActive: <img src={dataSchemaActive} alt="schema" />,
        dataQualityActive: <img src={dataQualityActive} alt="quality" />,
      },
      modeling: {
        modelSelection: <img src={modelSelectionIcon} alt="selection" />,
        startModelingActive: <img src={startModelingActiveIcon} alt="start" />,
        modelSelectionActive: (
          <img src={modelSelectionActiveIcon} alt="selection" />
        ),
      },
    };

    const {
      project: { mainStep, lastSubStep, noCompute, subStepActive, problemType },
      enter,
      keyword,
    } = props;
    const domRef = useRef(null);

    const store = useLocalStore(() => ({
      loading: false,
      // right: 0,
      min: 0,
      max: 0,
      updateField(data) {
        Object.assign(this, data);
      },
    }));

    useEffect(() => {
      reset();
      window.addEventListener('resize', reset);
    }, []);

    useEffect(() => {
      if (!domRef.current) {
        window.removeEventListener('resize', reset);
      }
    }, [domRef.current]);

    // const check = () => {
    //   if (!domRef.current) return;
    //   const parent = domRef.current.parentElement;
    //   const prev = domRef.current.previousSibling;
    //   if (!parent || !prev) return;
    //   const max = prev.clientWidth;
    //   const min = parent.clientWidth;
    //   if (store.min === min && store.max === max) return false;
    //   parent.scrollLeft = 0;
    //   store.updateField({
    //     max,
    //     min,
    //     // right:0,
    //   });
    //   return true;
    // };

    const reset = () => {
      if (store.loading) return;
      if (!domRef.current) return;
      store.updateField({
        loading: true,
      });
      const parent = domRef.current.parentElement;
      const prev = domRef.current.previousSibling;
      if (!parent || !prev) {
        store.updateField({
          loading: false,
        });
        return setTimeout(reset, 100);
      }
      // const checked = check();
      // if (checked) {
      // if (store.max > store.min) {
      // const parent = domRef.current.parentElement;
      // parent.onscroll = e => {
      // const checkedByscroll = check();
      // if (checkedByscroll) return;
      // store.updateField({
      //   right: Math.min(store.max - store.min, e.target.scrollLeft) * -1,
      // });
      // };
      // }
      // }
      store.updateField({
        loading: false,
      });
    };

    const step =
      keyword !== 'data' ?
        lastSubStep :
        (noCompute || problemType === 'Association') ?
          2 :
          mainStep > 2 ?
            3 :
            lastSubStep

    const list = STEP[keyword];

    const imgs = IMAGES[keyword];
    return useObserver(() => (
      <div
        className={styles.projectSide}
        ref={domRef}
        style={{
          // right: store.right
        }}
      >
        <div className={styles.sideLines}/>
        <div className={styles.sideBox}>
          {list.map((v, k) => {
            return (
              <div
                className={classnames(styles.sideBlock, {
                  [styles.sideBlockLarge]: list.length < 3,
                  [styles.active]: step > k,
                  [styles.current]: subStepActive === k + 1,
                })}
                key={k}
                onClick={enter.bind(null, k + 1)}
              >
                {/*{k !== 0 && (*/}
                {/*  <div*/}
                {/*    className={classnames(styles.sideLine, {*/}
                {/*      [styles.sideLineLarge]: list.length < 3,*/}
                {/*    })}*/}
                {/*  >*/}
                {/*    <span>*/}
                {/*      -------------------------------------------------------------*/}
                {/*    </span>*/}
                {/*  </div>*/}
                {/*)}*/}
                <div className={styles.sideIcon}>
                  {imgs[v.value + (step > k ? 'Active' : '')]}
                </div>
                <span>{v.label}</span>
              </div>
            );
          })}
        </div>
      </div>
    ));
  },
);
export default observer(ProjectSide);
