import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { observer } from 'mobx-react';
import { formatNumber } from '../../../util';
interface Interface {
  progress: any
  style?: any,
  allowRollBack?: boolean
  waiting?:boolean
}
function ProgressBar(props:Interface){
  const { style,allowRollBack,progress,waiting=false } = props;
  const [value,upValue] = useState(progress)

  useEffect(()=>{
    setProgress(progress, allowRollBack);
  },[progress,allowRollBack])

  const setProgress = (progress, allowRollBack = false) => {
    if (typeof progress === 'number') {
      upValue(allowRollBack
          ? progress
          : Math.max(progress, value || 0))
    } else {
      upValue(0)
    }
  };

  const text = waiting?'排队中': `${formatNumber(value, 2)}%`

    return (
      <div className={styles.processBg} style={style}>
        <div className={styles.processBlock}>
          <div className={styles.process} style={{ width: `${value}%` }} />
        </div>
        <div className={styles.text}>{text}</div>
      </div>
    );
}

export default observer(ProgressBar)
