import { observer } from 'mobx-react';
import React, { ReactElement, useState } from 'react';
import { formatNumber } from '../../../../util';
import styles from './styles.module.css';
import histogramIcon from './histogramIcon.svg';
import { CloseOutlined } from '@ant-design/icons';
import { Popover, Spin } from 'antd';
import SimplifiedViewPlot from './SimplifiedViewPlot';
import univariantIcon from './univariantIcon.svg';
import ScatterPlot from './ScatterPlot';
import EN from '../../../../constant/en';
import classnames from 'classnames';
import SimplePlot from './SimplePlot';
import Project from 'stores/Project';

interface Interface {
  readonly data: any;
  readonly colType: any;
  readonly importance: any;
  readonly value: any;
  readonly project: Project;
  readonly isChecked: boolean;
  readonly handleCheck: any;
  readonly id: any;
  readonly lines: any;
  readonly isNew: any;
  readonly mapHeader: ReadonlyArray<string>
  readonly isIV: boolean
  readonly maxImportance: number
}

// interface InterfaceData {
//   readonly field: string
//   readonly id: number | string
//   interval?: number
//   size?: number
// }

const SimplifiedViewRow = observer((props: Interface): ReactElement => {
  const {
    value,
    colType,
    project: {
      dataViews,
      mapHeader,
      target,
      problemType,
      histgramPlots,
      univariatePlots,
      renameVariable,
      readFile,
      newType,
      bigData
    },
    isNew,
    data: _data,
    lines,
    isChecked,
    handleCheck,
    importance,
    isIV,
    maxImportance,
  } = props;

  const [histograms, upHistograms] = useState(false);
  const [univariant, upUnivariant] = useState(false);
  const [chartData, upChartData] = useState({});
  const [result, upResult] = useState({});
  const [scatterData, upScatterData] = useState({});

  function showback(result, value: any, message?: any) {
    upChartData({
      ...chartData,
      [value]: result,
    });
    upResult({
      ...result,
      [value]: message,
    });
    upHistograms(true);
  }

  function showbackUnivariant(result, x, y, type) {
    upScatterData({
      ...scatterData,
      [value]: {
        ...result,
      },
      [`${value}-msg`]: {
        x,
        y,
        type,
      },
    });
    upUnivariant(true);
  }

  async function showHistograms() {
    if (!chartData[value]) {
      const s3Data = await readFile(histgramPlots[value]);
      // if (isNew) {
      //   const {data,...rest} = s3Data.data.data?s3Data.data:s3Data;
      //   return showback(data, value,rest)
      // }
      if (colType[value] === 'Numerical') {
        const {data,...rest} = s3Data.data;
        // const {
        //   max,
        //   min,
        // } = dataViews[value];
        //histogram-numerical 二分类 连续变量 直方图
        showback(s3Data.data.data, value, rest)
      } else {
        //histogram-categorical 二分类 离散变量 直方图
        showback(s3Data.data, value)
      }
    } else {
      upHistograms(true);
    }
  }

  async function showUnivariant() {
    if (!scatterData[value]) {
      const s3Data = await readFile(univariatePlots[value]);
      if (isNew) {
        return showbackUnivariant(s3Data.data, mapHeader[value],mapHeader[target],newType[value])
      }
      const type = colType[value];
      if (problemType === 'Regression') {//回归
        if (type === 'Numerical') {
          //散点图
          //regression-numerical
          showbackUnivariant(
            s3Data.data,
            mapHeader[value],
            mapHeader[target],
            'Numerical',
          );
        } else {
          //回归-分类 箱线图
          //regression-categorical
          // s3Data
          showbackUnivariant(
            s3Data.data,
            mapHeader[value],
            mapHeader[target],
            'Categorical',
          );
        }
        // _result
      } else {
        //Univariant
        if (type === 'Numerical') {
          if (problemType === 'MultiClassification') {
                upScatterData({
                  ...scatterData,
                  [value]: {
                    ...s3Data.data,
                  },
                  [`${value}-msg`]: {
                    type,
                    x: mapHeader[value],
                  },
                });
                upUnivariant(true);
          } else {
            //classification-numerical
              upScatterData({
                ...scatterData,
                [value]: {
                  ...s3Data.data,
                },
                [`${value}-msg`]: {
                  type,
                  x: mapHeader[value],
                },
              });
              upUnivariant(true);
          }
        } else {
          //classification-categorical
            upScatterData({
              ...scatterData,
              [value]: {
                ...s3Data.data,
              },
              [`${value}-msg`]: {
                type,
                x: mapHeader[value],
              },
            });
            upUnivariant(true);
        }
      }
      return;
    }
    upUnivariant(true);
  }

  function renderCell(value, isNA) {
    if (isNA) return 'N/A';
    if (isNaN(+value)) return value || 'N/A';
    return formatNumber(value, 2);
  }

  const valueType =
    colType[value] === 'Numerical' ? 'Numerical' : 'Categorical';
  const isRaw = colType[value] === 'Raw';
  const unique =
    (isRaw && `${lines}+`) ||
    (valueType === 'Numerical' && 'N/A') ||
    _data.uniqueValue;

  return (
    <div className={styles.tableRow}>
      <div className={classnames(styles.tableTd, styles.tableCheck)}>
        <input type="checkbox" checked={isChecked} onChange={handleCheck} />
      </div>
      <div className={styles.tableTd} title={mapHeader[value] || value}>
        <span>{mapHeader[value] || value}</span>
      </div>
      {!bigData && <div
        className={classnames(styles.tableTd, {
          [styles.notAllow]: isRaw,
        })}
        id={'Histograms' + value}
      >
        <Spin
          spinning = {!isRaw&&!histgramPlots[value]}
        >
          <img
            src={histogramIcon}
            className={styles.tableImage}
            alt="histogram"
            onClick={showHistograms}
          />
        </Spin>
        {!isRaw && histograms ? (
          <Popover
            placement="right"
            visible={!isRaw && histograms}
            onVisibleChange={() => upHistograms(false)}
            overlayClassName="popovers"
            trigger="click"
            title={
              <CloseOutlined
                style={{
                  float: 'right',
                  height: 23,
                  alignItems: 'center',
                  display: 'flex',
                }}
                onClick={() => upHistograms(false)} />
            }
            content={
              <SimplePlot isNew={isNew} path={histgramPlots[value]}>
                <SimplifiedViewPlot
                  type={colType[value]}
                  value={mapHeader[value] || value}
                  result={result[value]}
                  data={chartData[value]}
                />
              </SimplePlot>
            }
          />
        ) : null}
      </div>}

        {!bigData && <div
          className={classnames(styles.tableTd, {
            [styles.notAllow]: isRaw,
          })}
          id={'Univariant' + value}
        >
          <Spin
            spinning = {!isRaw&&!univariatePlots[value]}
          >
            <img
              src={univariantIcon}
              className={styles.tableImage}
              alt="univariant"
              onClick={showUnivariant}
            />
          </Spin>
          {!isRaw && univariant ? (
            <Popover
              placement="right"
              overlayClassName="popovers"
              visible={!isRaw && univariant}
              onVisibleChange={() => upUnivariant(false)}
              trigger="click"
              title={
                <CloseOutlined
                  style={{
                    float: 'right',
                    height: 23,
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={() => upUnivariant(false)} />
              }
              content={
                <SimplePlot
                  isNew={isNew}
                  path={univariatePlots[value]}>
                  <ScatterPlot
                    type={problemType}
                    data={scatterData[value]}
                    message={scatterData[`${value}-msg`]}
                    colType={colType[value]}
                    renameVariable={renameVariable}
                  />
                </SimplePlot>
              }
            />
          ) : null}
        </div>}
      <div className={classnames(styles.tableTd, styles.tableImportance)}>
        <div
          className={styles.preImpotance}
          title={formatNumber(importance, 3)}
        >
          <div
            className={styles.preImpotanceActive}
            style={{ width: (isIV ? importance / maxImportance : importance) * 100 + '%' }}
          />
        </div>
      </div>
      <div
        className={styles.tableTd}
        title={valueType === 'Numerical' ? EN.Numerical : EN.Categorical}
      >
        <span>{valueType === 'Numerical' ? EN.Numerical : EN.Categorical}</span>
      </div>
      <div
        className={classnames(styles.tableTd, {
          [styles.none]: valueType !== 'Categorical',
        })}
        title={unique}
      >
        <span>{unique}</span>
      </div>
      <div
        className={classnames(styles.tableTd, {
          [styles.none]: valueType === 'Categorical',
        })}
        title={renderCell(_data.mean, valueType === 'Categorical')}
      >
        <span>{renderCell(_data.mean, valueType === 'Categorical')}</span>
      </div>
      <div
        className={classnames(styles.tableTd, {
          [styles.none]: valueType === 'Categorical',
        })}
        title={renderCell(_data.std, valueType === 'Categorical')}
      >
        <span>{renderCell(_data.std, valueType === 'Categorical')}</span>
      </div>
      <div
        className={classnames(styles.tableTd, {
          [styles.none]: valueType === 'Categorical',
        })}
        title={renderCell(_data.median, valueType === 'Categorical')}
      >
        <span>{renderCell(_data.median, valueType === 'Categorical')}</span>
      </div>
      <div
        className={classnames(styles.tableTd, {
          [styles.none]: valueType === 'Categorical',
        })}
        title={renderCell(_data.min, valueType === 'Categorical')}
      >
        <span>{renderCell(_data.min, valueType === 'Categorical')}</span>
      </div>
      <div
        className={classnames(styles.tableTd, {
          [styles.none]: valueType === 'Categorical',
        })}
        title={renderCell(_data.max, valueType === 'Categorical')}
      >
        <span>{renderCell(_data.max, valueType === 'Categorical')}</span>
      </div>
    </div>
  );
});
export default SimplifiedViewRow;
