import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';

interface Interface {
  readonly path:string
  readonly isNew:boolean
  readonly children?: any
}

const SimplePlot = observer((props:Interface):ReactElement|any=>{
  const {isNew,children} = props;

  if (!isNew) {
    return children;
  }
  const cloneEl = el => React.cloneElement(el);
  return Array.isArray(children) ? children.map(cloneEl) : cloneEl(children);
});

export default SimplePlot;
