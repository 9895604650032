import React from 'react'
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash'

interface Interface {
	data:{
		normed:number[]
		bins:number[]
		x:number[]
		y:number[]
	},
	width?
	height?
}
export default function DensityPlot(props:Interface){

	const {data,height=400,width=1000} = props;
	if(!data)return null;

	const {x,y,normed,bins} = data;

	const bar_xs = [];
	bins.reduce((a,b)=>{
		bar_xs.push((a+b)/2);
		return b
	});

	const series = [{
		data:_.zip(x,y),
		type:'line',
		symbolSize:0,
	},{
		type:'bar',
		data:_.zip(bar_xs,normed),
		barWidth:width/bar_xs.length*0.75,
		// barCategoryGap:0,
	}];


	const option:any = {
		title: {
			text: 'Density Plot',
			subtext: ''
		},
		xAxis: {
			min:x[0],
			max:x[x.length-1]
			// name:x_name,
			// type: 'category',
			// data: xDate,
		},
		yAxis: {
			// name:y_name,
			type: 'value',
		},
		series,
	};

	return <ReactEcharts
		option={option}
		style={{height, width}}
		notMerge={true}
		lazyUpdate={true}
		theme="customed"
	/>
}
