import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Form, Input, Button, Checkbox, Slider, Select, FormProps
} from 'antd';

const { Option } = Select;

interface Props extends FormProps {
}



const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 4,
    },
  },
};
const defaultFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 16,
      offset: 0,
    },
    sm: {
      span: 18,
      offset: 0,
    },
  },
};

export const ShapSingleOptionForm: React.FunctionComponent<Props> = observer(({
  children,
  ...formProps
}) => {

  return (
    <Form
      {...formItemLayout}
      {...formProps}
    >
      <Form.Item
        label="数据选择"
        name="data_index"
      >
        <Input placeholder={'请输入指定数据的index'} type='number' />
      </Form.Item>

      <Form.Item
        label="样本选择"
        name="random_sample"
      >
        <Select>
          <Option key={1} value={1}>随机好样本</Option>
          <Option key={0} value={0}>随机坏样本</Option>
          <Option key={2} value={2} style={{ color: "#979797" }} disabled={true}>指定样本群体[cohort analysis]</Option>
        </Select>
      </Form.Item>

      <Form.Item  {...tailFormItemLayout} style={{ marginBottom: 0 }}>
        {children}
      </Form.Item>
    </Form>
  );
});

export default ShapSingleOptionForm;
