import React, { ReactElement, useState, useMemo } from 'react';
import styles from './api.module.css';
import { observer } from 'mobx-react';
import EN from '../../../constant/en';
import papaparse from 'papaparse';
function csv2array(csv) {
  const result = papaparse.parse(csv, { header: true })

  return result.data
}

interface Interface {
  deployment: any,
  type: string
}

function ApiInstruction(props: Interface): ReactElement {
  const { deployment, type } = props;

  const [token, upToken] = useState(false);
  const [csvData, upCsvData] = useState('');

  const jsonData = useMemo(() => {
    let result;
    try {
      result = JSON.stringify(csv2array(csvData));
    } catch (e) { }
    return result;
  }, [csvData])

  const showToken = async () => (upToken(await deployment.getDeploymentToken(type)));
  const onDataChange = (e: any) => (upCsvData(e.target.value));
  return (
    <div className={styles.row}>
      <div className={styles.apiInstruction}>
        <div className={styles.mdStyle}>
          <h1>{EN.Introduction}</h1>
          <p>{EN.WhatDoesOurAPIDo}</p>
          <p>{EN.WhatDoesOurAPIDoAnswer}</p>
          <h1>{EN.Request}</h1>
          <ul>
            <li>URL: /api/{type}</li>
            <li>HTTP Method: POST (x-www-form-urlencoded)</li>
            <li>
              Data parameters:
              <ol>
                <li>deploymentId</li>
                <li>token</li>
                <li>data(JSON string)</li>
              </ol>
            </li>
          </ul>
          <p>{EN.DeploymentpagewillprovidedeploymentIdandtokenparameter}</p>
          <h1>{EN.Response}</h1>
          <h2>{EN.Parameters}:</h2>
          <ol>
            <li>{EN.predictresult}</li>
            <li>{EN.errorcodeifsuccess}</li>
            <li>{EN.humenreadableinformation}</li>
            <li>{EN.originalerrorinformation}</li>
          </ol>
          <h2>{EN.ResponseSample}:</h2>
          <code>
            {'{'}
            &quot; result&quot;:[
            {'{'}
            &quot; age&quot;:&quot;300.0&quot;,
            &quot;job&quot;:&quot;unemployed&quot;,
            &quot;marital&quot;:&quot;married&quot;,
            &quot;education&quot;:&quot;primary&quot;,
            &quot;default&quot;:&quot;no&quot;,
            &quot;balance&quot;:&quot;1787&quot;,
            &quot;housing&quot;:&quot;no&quot;, &quot;loan&quot;:&quot;no&quot;,
            &quot;contact&quot;:&quot;cellular&quot;,
            &quot;day&quot;:&quot;19&quot;, &quot;month&quot;:&quot;oct&quot;,
            &quot;duration&quot;:&quot;79&quot;,
            &quot;campaign&quot;:&quot;1&quot;,
            &quot;pdays&quot;:&quot;100&quot;,
            &quot;previous&quot;:&quot;3&quot;,
            &quot;poutcome&quot;:&quot;unknown&quot;,
            &quot;y&quot;:&quot;no&quot;, &quot;ypredict&quot;:&quot;0&quot;,
            &quot;yprobability_1&quot;:&quot;0.013476189619787587&quot; {'}'} ],
            &quot;code&quot;:10000, &quot;message&quot;:&quot;ok&quot; {'}'}
          </code>
          <br />
          <br />
          <br />
          <h1>{EN.Authentication}</h1>
          <p>{EN.DeploymentAuthentication}</p>
          <h1>{EN.ErrorCodes}</h1>
          <ul>
            <li>10001: &#39;{EN.DeploymentNotFound}&#39;,</li>
            <li>10002: &#39;{EN.DataNotFound}&#39;,</li>
            <li>10003: &#39;{EN.TokenNotFound}&#39;,</li>
            <li>10004: &#39;{EN.dataisnotavalidJSONstring}&#39;,</li>
            <li>10005: &#39;{EN.dataisemptyor}&#39;,</li>
            <li>10006: &#39;{EN.fileuploaderror}&#39;,</li>
            <li>10007: &#39;{EN.fileuploaFrror}&#39;,</li>
            <li>10008: &#39;{EN.predicterror}&#39;,</li>
            <li>10009: &#39;{EN.predictf}&#39;,</li>
            <li>10010: &#39;{EN.invalidtoken}&#39;,</li>
            <li>10011: &#39;{EN.exceedpredictionusageimit}&#39;,</li>
            <li>10012: &#39;{EN.exceedpredictionapiimit}&#39;,</li>
            <li>10013: &#39;{EN.downloadpredictesultfailed}&#39;,</li>
            <li>10014: &#39;{EN.predictresultisempty}&#39;</li>
          </ul>
          <h1>{EN.RateLimit}</h1>
          <p>{EN.DeploymentRateLimit}</p>
        </div>
        <div className={styles.block}>
          <span className={styles.title}>{EN.Parameters}:</span>
          <div className={styles.parameter}>
            <span className={styles.label}>{EN.DeploymentId}:</span>
            <span className={styles.value}>{deployment.id}</span>
          </div>
          <div className={styles.parameter}>
            <span className={styles.label}>{EN.Token}:</span>
            {token ? (
              <span className={styles.value}>{token}</span>
            ) : (
                <a className={styles.showToken} onClick={showToken}>
                  {EN.Showtoken}
                </a>
              )}
          </div>
        </div>
        <div className={styles.block}>
          <span className={styles.title}>{EN.CURLSample}:</span>
          <textarea
            className={styles.dataText}
            cols={60}
            rows={10}
            placeholder={EN.Yourcsvdata}
            value={csvData}
            onChange={onDataChange}
          />
          <p className={styles.sampleContent}>
            curl -X POST --data 'token={token || '*YOUR DEPLOYMENT TOKEN*'}
            &deploymentId={deployment.id}&data=
            {jsonData
              ? jsonData
              : '*YOUR JSON FORMAT DATA*'}
            ' {window.location.origin}/api/{type}
          </p>
        </div>
      </div>
    </div>
  );
}

export default observer(ApiInstruction);
