import React, { ReactElement, useMemo } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { CloseOutlined } from '@ant-design/icons';
import { Hint, Select, Show, NumberInput } from 'components/Common';
import EN from '../../../constant/en';
import { ProjectRolesKey } from '@app/constant/types';

interface DataSchemaToolsProps {
  problemType: string,
  targetOption: StringObject,
  maxCounts: number,
  target: string,
  item: string,
  // isMissed: boolean,
  // isDuplicated: boolean,
  uniques: number,
  bigData: boolean,
  targetSelect: (value: string) => void,
  variableSelect: (value: string) => void,
  handleUnique: (value: number) => void,
  toggleSelect: () => void,
  autoFix: () => void,
  toggleOrderIndex: () => void
}

function SchemaTools(props: DataSchemaToolsProps):ReactElement {
  const { problemType, targetOption, maxCounts, target, item, uniques, targetSelect, variableSelect, handleUnique, toggleSelect, autoFix, toggleOrderIndex, bigData } = props

  const isUnsupervised = ['Clustering', 'Outlier'].includes(problemType);
  const isAssociation = problemType === 'Association'
  const isMulti = problemType === 'MultiClassification'
  const isTimeSeries = ['Forecasting', 'Survival', 'Prediction'].includes(problemType)

  const targetHint = isAssociation ? EN.AssociationTarget : EN.TargetVariable

  const variableOption = { ...targetOption }
  if (target) delete variableOption[target]
  if (item) delete targetOption[item]

  const variableDom = useMemo(() => {
    if (isTimeSeries) return <Show
      name={ProjectRolesKey.SchemaContinue}
    >
      <>
        <div
          className={styles.schemaSelect}
          onClick={toggleOrderIndex}
        >
          <span>{EN.SelectOrderIndex}</span>
        </div>
        <Hint
          themeStyle={{
            fontSize: '1.5rem',
            lineHeight: '2rem',
            display: 'flex',
            alignItems: 'center',
          }}
          content={EN.TimeSeriesOrder}
        />
      </>
    </Show>
    if (isAssociation) return (
      <Select
        title={EN.AssociationVariable}
        dropdownClassName={'targetSelect'}
        width={'190px'}
        options={variableOption}
        onChange={variableSelect}
        value={item}
        // disabled={isMissed || isDuplicated}
        selectOption={{
          showSearch: true,
          allowClear: !!item,
          clearIcon: <CloseOutlined />,
          style: { marginRight: '30px' }
        }}
        getPopupContainer={() =>
          document.getElementById('schemaContent')
        }
      />
    );
    return <Show
      name={ProjectRolesKey.SchemaContinue}
    >
      <div
        className={styles.schemaSelect}
        onClick={toggleSelect}
      >
        <span>{EN.UnselectUndesirableVariables}</span>
      </div>
      <Hint
        themeStyle={{
          fontSize: '1.5rem',
          lineHeight: '2rem',
          display: 'flex',
          alignItems: 'center',
        }}
        content={
          <div>
            {EN.Unselectpredictorsthatleadtolesswantedmodeling} <br />
            {EN.VariableIDs} <br />
            {EN.Variablesthatarederivedfromthetarget} <br />
            {EN.Anyothervariablesyou}
          </div>
        }
      />
    </Show>
  }, [target, item])

  return (
    <div className={styles.schemaTools}>
      {(bigData && isUnsupervised)||problemType === "Outlier" ? null : <Select
        title={targetHint}
        dropdownClassName={'targetSelect'}
        width={'190px'} 
        options={targetOption}
        onChange={targetSelect}
        value={target}
        // disabled={isMissed || isDuplicated}
        selectOption={{
          showSearch: true,
          allowClear: !!target,
          clearIcon: <CloseOutlined />,
          style: { marginRight: '30px' }
        }}
        getPopupContainer={() =>
          document.getElementById('schemaContent')
        }
      />}
      {isMulti && <div className={styles.multiInput}>
        <span>{EN.MultiUnique}</span>
        {!!target && <Hint
          themeStyle={{
            fontSize: '1.5rem',
            lineHeight: '2rem',
            display: 'flex',
            alignItems: 'center',
          }}
          content={
            <div>
              {EN.MultiUniqueHint1}{maxCounts}
              {EN.MultiUniqueHint2}{maxCounts}
              {EN.MultiUniqueHint3}
            </div>
          }
        />}
        <NumberInput min={3} max={maxCounts} disabled={!target} value={uniques || ''} isInt={true} onBlur={handleUnique} />
      </div>}
      {(!bigData && isUnsupervised&&problemType !== "Outlier") && <Hint
        themeStyle={{
          fontSize: '1.5rem',
          lineHeight: '2rem',
          display: 'flex',
          alignItems: 'center',
        }}
        content={EN[`${problemType}Target`]}
      />}
      {variableDom}
    </div>
  );
}

export default observer(SchemaTools)
