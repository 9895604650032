import EventEmitter from 'events';
import { v4 as uuidV4 } from 'uuid';

const debug = false;

interface SocketInterface {
  reconnect: () => void,
  send: (message: StringObject) => void
}

class Socket extends EventEmitter implements SocketInterface {
  private url = `${window.location.protocol.startsWith('https') ? 'wss' : 'ws'}://${window.location.hostname}:${window.location.port}/ws`;
  private reconnectLock = false;
  private connectionError = false;
  private errorTimes = 0;
  private ws: null | WebSocket = null;
  private timeout = 30 * 1000;
  private heartbeatTimeout: null | NodeJS.Timeout = null;
  private serverTimeout: null | NodeJS.Timeout = null;
  private messageList: StringObject[] = [];

  constructor() {
    super();
    this.createWebSocket();
  }

  private createWebSocket() {
    try {
      if ('WebSocket' in window) {
        this.ws = new (window as any).WebSocket(this.url);
      } else if ('MozWebSocket' in window) {
        this.ws = new (window as any).MozWebSocket(this.url);
      } else {
        alert('Your browser does not support websocket.');
      }
      this.initEventHandle();
    } catch (e) {
      console.log('createWebSocket-error', e);
      this.reconnect();
    }
  }

  public reconnect() {
    if (this.reconnectLock) return;
    this.reconnectLock = true;
    this.onClose("offline reconnect")
    console.log("offline")
    this.emit('offline');
    setTimeout(() => {
      //没连接上会一直重连，设置延迟避免请求过多
      this.createWebSocket();
      this.reconnectLock = false;
    }, 2000);
  }

  private start() {
    if (this.heartbeatTimeout !== null) clearTimeout(this.heartbeatTimeout);
    if (this.serverTimeout !== null) clearTimeout(this.serverTimeout);

    this.heartbeatTimeout = setTimeout(() => {
      // send a  ping, any message from server will reset this timeout.
      this.ws && this.ws.readyState === 1 && this.ws.send('ping');
      this.serverTimeout = setTimeout(
        () => this.ws && this.ws.readyState === 1 && this.terminate(),
        this.timeout,
      );
    }, this.timeout);
  }

  private initEventHandle() {
    if (!this.ws) return;
    this.ws.addEventListener('message', this.onMessage);
    this.ws.addEventListener('error', this.onError);
    this.ws.addEventListener('close', this.onClose);
    this.ws.addEventListener('open', this.onOpen);
  }

  private onMessage = (event: unknown) => {
    this.start(); //any message represent current connection working.
    this.emit('message', event);
  };

  private onClose = (event: unknown) => {
    this.errorTimes++;
    // if (this.errorTimes >= 10) {
    //   this.connectionError = event
    //   console.error('error retry > 10 times')
    // }
    console.warn('websocket-listen-closed!', event);
    if (!this.connectionError) this.reconnect();
  };

  private onError = (event: unknown) => {
    console.error('websocket-listen-error!', event);
    if (!this.connectionError) this.reconnect();
  };

  private onOpen = () => {
    this.errorTimes = 0;
    this.start(); //reset client heartbeat check
    this.emit('online');
    console.info('websocket connect successful. ' + new Date().toUTCString());
    window.addEventListener("beforeunload", () => {
      this.connectionError = true
      this.emit("close")
    })
    while (this.messageList.length) {
      const message = this.messageList.shift();
      this.send(message);
    }
    // for (let i = 0; i < this.messageList.length; i++) {
    //   const message = this.messageList.shift()
    //   this.send(message)
    // }
  };

  public send(message: StringObject) {
    if (!this.ws) return
    if (this.ws.readyState === 1) {
      this.ws.send(JSON.stringify(message));
    } else {
      this.messageList.push(message);
    }
  }

  private terminate() {
    // this.ws && this.ws.terminate()
    if (this.heartbeatTimeout) clearTimeout(this.heartbeatTimeout);
    if (this.serverTimeout) clearTimeout(this.serverTimeout);
  }
}

interface SocketStoreInterface {
  status: string,
  api: { addEventListener: (str: string, callback: () => void) => void, removeEventListener: (str: string, callback: () => void) => void }
  socket?: Socket
}

class SocketStore extends EventEmitter implements SocketStoreInterface {
  status = 'init';
  api = { addEventListener: this.addListener.bind(this), removeEventListener: this.removeListener.bind(this) };
  private sendMessage: StringObject[] = []
  socket: Socket

  connect = () => {
    this.socket = new Socket();
    this.socket.on('message', (message: { data: string }) => {
      let data = message.data;
      if (data === 'casLogout') {
        return location.href = '/cas/login';
      }
      if (data === 'pong') return;
      if (debug) console.log('receive message:', data);
      let _data: {
        type: string
        request: {
          _id: string,
          progress: boolean
        }
        api?: string[]
      }
      try {
        _data = JSON.parse(data);
      } catch (e) {
        console.warn('unknown message:', message.data);
      }
      if (_data.type === 'api') {
        return this.initApi(_data.api);
      }
      if (_data.request && _data.request._id && _data.request.progress !== true) {
        this.sendMessage = this.sendMessage.filter(m => m.id !== _data.request._id)
        return this.emit(_data.request._id, _data);
      }
      if (_data.request && _data.request._id && _data.request.progress === true)
        return this.emit('progress' + _data.request._id, _data);
      if (_data.type) return this.emit(_data.type, _data);
    });
    this.socket.on('offline', () => {
      this.emit('offline');
    });
    this.socket.on('online', () => {
      this.emit('online');
    });
    // if (debug) {
    //   window.ss = this;
    //   window.ws = this.ws;
    //   window.api = this.api;
    //   window.axios = axios;
    // }
  };

  // reconnect() {
  //   this.socket.terminate()
  //   this.connect()
  // }

  ready(): Promise<any> {
    if (this.status === 'ready') return Promise.resolve(this.api);
    return new Promise((resolve) => {
      this.once('apiReady', resolve.bind(null, this.api));
    });
  }

  initApi(api: string[]) {
    api.map(eventName => {
      return Reflect.set(this.api, eventName, (data: any = {}, callback?: () => void) => {
        if (typeof data === 'function') {
          callback = data;
          data = {};
        }
        const _id = uuidV4();
        data._id = _id;
        data.type = eventName;
        if (callback) this.on('progress' + _id, callback);
        return new Promise((resolve) => {
          this.once(_id, (...args) => {
            if (callback) this.removeListener('progress' + _id, callback);
            // @ts-ignore
            resolve(...args);
          });
          let promise = Promise.resolve()
          // if (!!data.command) promise = this.stopCommands(data.projectId, data.command)
          //先移除之前的命令
          promise.then(() => {
            const _message = {
              id: _id,
              command: data.command,
              projectId: data.projectId
            }
            this.sendMessage.push(_message)
            this.socket.send(data);
          })
        });
      })
    });
    this.emit('apiReady');
    this.status = 'ready';
  }

  //停止其他命令
  stopCommands = (projectId: string, command: string = ''): Promise<void> => {
    return new Promise(resolve => {
      const messages = this.sendMessage.filter(m => (m.projectId === projectId) && (!command || m.command === command))
      if (!messages.length) return resolve()
      this.sendMessage = this.sendMessage.filter(m => !messages.includes(m))

      const promiseArr = messages.map(message => {
        //移除监听
        this.removeAllListeners(message.id)
        this.removeAllListeners('progress' + message.id)

        const _id = uuidV4();
        //发送命令
        const data = {
          stopId: message.id,
          projectId: message.projectId,
          command: "top.stop",
          _id,
          type: 'r2Abort'
        }
        const promise = new Promise(_resolve => {
          this.once(_id, _resolve);
        })

        this.socket.send(data);

        return promise
      })

      Promise.all(promiseArr).then(() => resolve())
    })
  }
}

export { SocketStore }

export default new SocketStore();
