export enum Domain {
  CMB = 'CMB', // 招商银行
  NUOQI = 'NUOQI', // 诺棋
  R2 = 'R2',
  SCTAX = 'SCTAX', // 川税
  GZCB = 'GZCB', // 广州银行
}

export enum ProblemType {
  Classification = 'Classification',
  MultiClassification = 'MultiClassification',
  Regression = 'Regression',
  Clustering = 'Clustering',
  Outlier = 'Outlier',
  Association = 'Association',
  Forecasting = 'Forecasting',
}

export interface HttpRequestRes<T> {
  data: Array<T>;
  status: number;
}
export enum ProjectRolesKey {
  ProjectContinue = 'project_continue', // 项目继续按钮
  ProblemContinue = 'problem_continue', // 项目问题继续按钮
  DataConnect = 'data_connect', // 项目数据连接
  SchemaContinue = 'schema_continue', // 项目质量
  QualityContinue = 'quality_continue', // 数据质量-目标变量
  QualityPredictContinue = 'quality_predict_continue', // 数据质量-预测变量
  StartAutomaticModeling = 'start_automatic_modeling', // 开始建模-自动建模
  AdvancedModelingConfig = 'advanced_modeling_config', // 开始建模-高级建模-配置
  StartAdvancedModeling = 'start_advanced_modeling', // 开始建模-高级建模-运行
  ModelingAbort = 'modeling_abort', // 停止训练
  ModelEdit = 'model_edit', // 模型选择
  ModelDeploy = 'model_deploy', // 模型选择-部署模型
  ModelExport = 'model_export', // 模型选择-模型导出
  DeploymentOperation = 'deployment_operation', // 模型部署-部署模型
  DeploymentIncrementalModel = 'deployment_incremental', // 模型部署-模型增量
  DeploymentPerformance = 'deployment_performance', // 模型部署-性能监测
  // 用户权限 TODO
  Dashboard = 'dashboard',
  Project = 'project',
  JupyterLab = 'jupyterlab',
  Support = 'support',
  Deployment = 'deployment',
}
export enum DatabaseConnectType {
  Sample = 'sample',
  Sql = 'sql',
  Upload = 'upload',
}
