import React, { ReactElement, useMemo } from 'react';
import { observer } from 'mobx-react';
import Model from 'stores/Model';
import { Impact } from '../../Charts';

interface Interface {
  readonly model: Model
  readonly mapHeader: any
}
const VariableImpact = observer((props: Interface): ReactElement => {
  const { model, mapHeader } = props;
  const { featureImportance, importanceCoefficient } = model as any;

  const arr = Object.entries(featureImportance || {}).sort(
    (a: any, b: any) => b[1] - a[1]
  );

  const ic = importanceCoefficient && Object.entries(importanceCoefficient);

  let y_names = [], imports = [], list = [];
  arr.reverse().forEach((itm) => {
    const [k, v] = itm;
    y_names.push(mapHeader[k] || k);
    imports.push(v);
    if (ic) {
      const l_v: any = (ic.find(it => it[0] === k) || [null, null])[1];
      if (l_v !== null) {
        list.push(l_v.toFixed(3))
      } else {
        list.push('')
      }
    }
  });

  return useMemo(() => {
    return <Impact
      y_names={y_names}
      imports={imports}
      list={list}
    />
  }, [])
});
export default VariableImpact;
