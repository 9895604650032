import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import EN from '../../constant/en';
import styles from './charts.module.css';
import { Select } from 'antd';
import { MobXProviderContext, observer } from 'mobx-react';
import Project from 'stores/Project';
const { Option } = Select;
import MIBar from './MIBar';
import MI3D from './MI3D';
import MIBoxPlots from './MIBoxPlots';
import MIBox3D from './MIBox3D';
import Model from 'stores/Model';
import _ from 'lodash';
import Loading from './Loading';
import { ProjectStore } from '../../stores/ProjectStore';

interface Interface {
  model: Model;
}

function ModelInsight(props: Interface): ReactElement {
  const {
    model: {
      featureImportance,
      saveModelInsight,
      cutoff,
      dependenceData,
      modelInsightList,
    },
  } = props;
  // @ts-ignore
  const {
    projectStore: {
      project: {
        mapHeader,
        problemType,
        readFile,
      },
      project,
    },
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext);
  const [vars, upVars] = useState(modelInsightList);
  const [var1,var2] = [...vars,undefined];
  const [show_name, up_show_name] = useState({ var1,var2});
  const [message, upMessage] = useState(undefined as any);
  const [show, upShow] = useState(false);
  
  const list = Object.entries(featureImportance)
    .sort((a: any, b: any) => b[1] - a[1])
    .splice(0, 10)
    .map(itm => itm[0]);

  useEffect(() => {
    if(!show_name.var1 && !show_name.var2) return
    if (dependenceData) {
      readFile(dependenceData).then(async result=>{
        if(!result.avg){
          return save()
        }
        await upShow(false);
        upMessage(result);
        // upShow(true);
      });
    }
  }, [dependenceData]);

  function selection(order) {
    const disable = Object.values(show_name).filter(
      itm => itm !== show_name[order],
    );

    const options = list.map(itm => (
      <Option
        key={itm}
        title={mapHeader[itm] || itm}
        disabled={disable.includes(itm)}
        value={itm}
      >
        {mapHeader[itm] || itm}
      </Option>
    ));

    return (
      <Select
        allowClear
        placeholder={EN.Pleaseselect}
        value={show_name[order] || undefined}
        style={{ width: 120 }}
        getPopupContainer={el => el.parentElement}
        onChange={name => {
          up_show_name({
            ...show_name,
            [order]: name,
          });
        }}
      >
        {options}
      </Select>
    );
  }

  async function save() {
    if(show){
      return
    }
    const { var1 = '', var2 = '' } = show_name;
    const list = [var1, var2];
    const _list = list.filter(itm => itm);
    upVars(_list);
    if (!_list.length) {
      upShow(false);
    } else {
      upShow(true)
    }
    await saveModelInsight(_list, cutoff);
    upShow(false)
  }

  const Chart = useMemo(() => {
    
    if(show){
      return <Loading/>
    }
    
    if(!message||!message.avg){
      return;
    }
    const names = Object.values(message.avg.name);
    const wrong = _.difference(vars,names).length || _.difference(names,vars).length;
    
  
    if (problemType === 'Regression') {
      if (vars.length === 1) {
        return <MIBoxPlots message={message} project={project} />;
      } else if (vars.length === 2) {
        return <MIBox3D message={message} project={project} />;
      }
    }
    if (vars.length === 1) {
      return <MIBar message={message} project={project} />;
    } else if (vars.length === 2) {
      return <MI3D message={message} project={project} />;
    }
  }, [message, show]);

  return (
    <section className={styles.ModelInsight}>
      <dl>
        <dt>{EN.Choose2Variables}</dt>
        <dd>Var1:{selection('var1')}</dd>
        <dd>Var2:{selection('var2')}</dd>
        <dd>
          <button style={show ? {background: '#ddd'} : undefined} className={styles.button} onClick={save}>
            <span>PDP</span>
          </button>
        </dd>
      </dl>
      {Chart}
    </section>
  );
}
export default observer(ModelInsight);
