import ReactEcharts from "echarts-for-react";
import React, { ReactElement } from 'react';
import echarts from 'echarts'
import _ from 'lodash'
import EN from "../../constant/en";
import { toJS } from 'mobx';

interface Interface {
	readonly message:any
}

export default function FitBar(props:Interface):ReactElement{
	const {message:{
		data:DATA,
		item,
		featureWidth:widths=item.map(itm=>itm.count),
		featureUnique:x_names= item.map(itm=>itm.key),
		targetUnique:y_names=DATA.map(itm=>itm.name),
	}} = props;
	const max_h = 10;

	const y_width = Math.min(max_h+1,y_names.length);
	const x_width = Math.min(10,x_names.length);

	let heights;

	if(DATA[0]&&DATA[0].value){
		heights = _.zip(...(DATA).map(itm=>{
			if(itm.value.length){
				return itm.value
			}
			return new Array(y_names.length);
		}));
	}else{
		heights = DATA;//新建变量
	}

	const x_sum = _.sum(widths);

	const x = widths.map(itm=>itm/x_sum);

	const ys = heights.map((itm)=>{
		const sum = _.sum(itm.map(it=>+it))||1;
		return itm.map((it=0)=>+it/sum)
	});

	const yNameL:number = _.max(y_names.map(itm=>itm.length));

	let data = [];
	let sum = 0;

	function make_data(y,itm,x_name){
		let y_name = _.cloneDeep(toJS(y_names));
		const _data = [];

		const _y =[...y].sort((a,b)=>b-a);
		let y_sum = 0;

		_y.forEach((it,ind)=>{
			const _y_name = y_name[y.indexOf(it)];
			let m_y,m_y_name;
			y = y.filter(i=>{
				if(i===it){
					if(m_y){
						return true
					}
					m_y = true;
					return false;
				}
				return true
			});
			y_name = y_name.filter(i=>{
				if(i===_y_name){
					if(m_y_name){
						return true
					}
					m_y_name = true;
					return false;
				}
				return true
			});

			let __x_name='',__y_name='';

			if(!ind){
				__x_name = x_name;
				__y_name = _y_name;
			}

			_data.push([sum,sum+itm,it,y_sum,__x_name,__y_name,x_name,_y_name]);
			y_sum+=it;
		});

		_data.sort((a,b)=>-a[2]+b[2]);
		const others = _data.splice(max_h);

		data.push(..._data);

		if(others.length){
			const [,,end,start] = _.last(_data);
			const last_sum = end+start;
			if(1-last_sum){
				data.push([sum,sum+itm,1-last_sum,last_sum,'','',x_name,EN.Others]);
			}
		}
	}

	x.filter((i,index)=>index<x_width).forEach((itm,index)=>{
		let y = ys[index];
		const x_name = x_names[index];
		make_data(y,itm,x_name);
		sum += itm;
	});

	const others = x.filter((i,index)=>index>=x_width);

	const o_length = others.length;
	if(o_length){
		const last = _.zip(...ys.splice(-o_length)).map(_.sum);
		const _sum = _.sum(last);
		const _last = last.map(itm=>itm/_sum);

		make_data(_last,1-sum,EN.Others);
		// data.push([sum,1,1,0,EN.Others,'',EN.Others,'1']);
	}

	data = (echarts as any).util.map(data, function (item, index) {
		const x_ind = index/y_width;
		// const y_ind = index%y_width;
		// const color = colors[y_ind];
		return {
			value: item,
			name:item[7],
			itemStyle: {
				normal: {
					// color,
					opacity:1 - x_ind/(x_width+1),
				}
			}
		};
	});

	let _data = data.filter(itm=>!itm.value[0]);
	let __data = _.cloneDeep(_data);
	__data = __data.map(itm=>{
		const {value} = itm;
		value[1] = 0;
		if(!value[2]){
				value[7] = '';
		}

		return {
			...itm,
			value,
		}
	});
	function renderItem(params, api) {
		const yValue = api.value(2);
		const start = api.coord([api.value(0), yValue + api.value(3)]);
		const size = api.size([api.value(1) - api.value(0), yValue]);
		const style = api.style();

		return {
			type: 'rect',
			shape: {
				x: start[0],
				y: start[1],
				width: size[0],
				height: size[1]
			},
			style,
		};
	}

	const custom = {
		type: 'custom',
		renderItem,
		dimensions: ['from', 'to', 'profit'],
	};

	const series:any = data.map(itm=>({
		...custom,
		name:itm.name,
		label: {
			normal: {
				show: true,
				position: 'bottom',
				rotate:'30',
				color:'#000',
				distance:20,
			}
		},
		data:[itm],
		encode: {
			x: [0, 1],
			y: 4,
			itemName: 3
		},
	}));

	series.push({
		...custom,
		label: {
			normal: {
				show: true,
				position: 'left',
				color:'#000',
			}
		},
		data:__data,
		encode: {
			x: [0, 1],
			y: 7,
			itemName: 3
		},
	});


	const option:any = {
		tooltip: {
			formatter:params=>{
				const {marker,value} = params;
				const [x_start,x_end,yp,,,,x_name,y_name] = value;
				return`
					${marker}${x_name}:${((x_end-x_start)*100).toFixed(3)}%<br/>
					${y_name}:${(yp*100).toFixed(3)}%
				`
			}
		},
		xAxis: {
			min:0,
			max:1,
			show:false,
		},
		yAxis: [{
		  min:0,
			max:1,
      axisLine:{
			  show:false
      },
      axisLabel:{
        show:false
      },
			splitLine:{
		  	show:false
			}
		},{
		  min:0,
      max:1,
      axisLine:{
        show:false
      },
      name:EN.Scale,
			splitLine:{
		  	show:false
			}
    }],
		series,
		legend:{
			orient: 'vertical',
			left: 'right',
			type:"scroll"
		},
		// dataZoom:{
		// 	type: 'slider',
    //   filterMode:"none",
    //   rangeMode:['percent','percent']
		// },
    grid:{
      top:50,
      bottom:100,
	    right:Math.max(7*yNameL,50)+50,
	    left:Math.max(7*yNameL,50),
    }
	};


	return <ReactEcharts
		option={option}
		style={{height: 500, width: 650+10*yNameL}}
		notMerge={true}
		lazyUpdate={true}
		theme="customed"
	/>
}
