import React, { useRef, useEffect, ReactElement, useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import InputNum from 'rc-input-number';
import { default as _, debounce } from 'lodash';
import { Button, Spin } from 'antd';
import './echarts.config'
import EN from '../../constant/en';
import 'rc-input-number/assets/index.css'
import styles from './styles.module.css';
import {toJS} from "mobx";
import Project from 'stores/Project';
import { observer, useLocalStore } from 'mobx-react';
import { useDebounce } from 'react-debounce-hook'
interface Interface {
	closeEdit:any
	saveEdit:any
	field:any
	id:string
	project:Project
	title?:string,
}
function OutlierRange(props:Interface):ReactElement{
	const {
	    field,
	    project,
	    project: { readFile, outlierDictTemp, rawDataView = {}, histogramData },
	    saveEdit,
	    closeEdit,
	    title,
	  } = props;
	let {low,high} = rawDataView[field];
	const interval = (high-low)/ 10;
	const _startValue =  +low - 2 * +interval;
	const _endValue =  +high + 2 *+interval;
	if(toJS(outlierDictTemp)[field]){
		const data = outlierDictTemp[field];
		low = +(+data[0]).toFixed(2);
		high = +(+data[1]).toFixed(2);
	}
	const chart:any = useRef();
	const localStore = useLocalStore(() => ({
		max:null,
		min:0,
		ready:false,
		selectArea:[low,high],//选中的区域
		low:0,
		high:0,
		bin:Math.min(rawDataView[field].doubleUniqueValue||14, 14),
		loading:true,
		chart:null,
		data:null,
        sliderMin:+(_startValue.toFixed(3)),
        sliderMax:+(_endValue.toFixed(3)),
		version:1,
		updateField(data) {
			Object.assign(this, data);
		},
	}));

	useEffect(()=>{
		const _chart = chart.current.getEchartsInstance();
		localStore.updateField({
			chart:_chart,
			loading:true,
			version:localStore.version +1
		})
		// getData()
	},[]);

	useEffect(()=>{
		if(localStore.loading){
			localStore.chart.showLoading();
		}else{
			localStore.chart.hideLoading()
		}
	},[localStore.loading])

	const slider = ()=>{
		console.log('in slider')
		if(localStore.chart){
			localStore.updateField({
				loading:true,
				version:localStore.version +1
			})
			// getData()
		}
	};


	  useEffect(()=>{
		  slider()
		  // localStore.updateField({
			//   version:localStore.version +1
		  // })
	  },[localStore.sliderMin,localStore.sliderMax])

	const getData = ()=> {
		console.log('getData')
		let {bin,sliderMin,sliderMax,loading} = localStore;
		const {outlierHistogram} = project;
		if(sliderMin === sliderMax){
            sliderMax += 0.0001;
		}
		outlierHistogram([field],[[sliderMin,sliderMax]],bin);
		setBrush(loading);
		brush();
	}

	useDebounce(localStore.version, getData);


	const refreshData = ()=>{
		let {bin,sliderMin,sliderMax} = localStore;
		const _chart = localStore.chart || chart.current.getEchartsInstance();
		let {min,max,low,high} = rawDataView[field];

		low = +low.toFixed(6);
		high = +high.toFixed(6);

		if(toJS(outlierDictTemp)[field]){
			const data = outlierDictTemp[field];
			low = +(+data[0]).toFixed(6);
			high = +(+data[1]).toFixed(6);
		}

		const interval = (sliderMax-sliderMin) / bin;
		if(sliderMin === sliderMax){
            sliderMax += 0.0001;
		}

		readFile(histogramData).then((data:any) => {
			localStore.updateField({
				title,
				min:(Math.max(+min,+low)).toFixed(3),
				max:(Math.min(+max,+high)).toFixed(3),
				data,
				chart:_chart,
				ready:true,
				low,
				high,
				interval,
                sliderMax,
			})
			setBrush()
			brush()
		});
	}

	const brush = debounce(()=>{
		console.log('brush')
		const {chart,selectArea} = localStore;
		chart.on('brushselected',  (params)=> {
			const {areas=[]} = params.batch[0];
			if(!areas[0]){
				return
			}
			const area =  areas[0];
			const [start,end] = toJS(selectArea);
			const [_start,_end] = area.coordRange;
			if(start!==_start||end!==_end){
				console.log('use setSelectArea')
				setSelectArea(area.coordRange)
			}
		});
	},1000)

	const setSelectArea = debounce((selectArea)=>{
		console.log('setSelectArea')
		localStore.updateField({
			selectArea
		})
		// setBrush()
	},1000)

	const setBrush = (loading=false)=>{
		console.log('setBrush')
		const {chart,selectArea} = localStore;

		localStore.updateField({
			loading
		})
		chart.dispatchAction({
			type: 'brush',
			areas: [
				{
					brushType: 'lineX',
					coordRange: selectArea,
					xAxisIndex: 0,
				},
			],
		});
	}

	const getOption = useMemo(()=> {
		const {ready,data,selectArea,bin,sliderMin,sliderMax} = localStore;
		const {min,max,low,high} = project.rawDataView[field];
		if(!ready){
			return {
				xAxis:{},
				yAxis:{},
			}
		}

		const _endValue = sliderMax + (sliderMax - sliderMin)/1000;

		const [height,range] = data;

		const _data = height.map((itm,index)=>{
			return [range[index],range[index+1],itm];
		});

		function renderItem(params, api) {
			const yValue = api.value(2);
			const start = api.coord([api.value(0), yValue]);
			const size = api.size([api.value(1) - api.value(0), yValue]);
			const style = api.style();

			return {
				type: 'rect',
				shape: {
					x: start[0],
					y: start[1],
					width: size[0],
					height: size[1],
				},
				style,
			};
		}
		const nameTextStyle = {
			color:'#000',
		};
		let mins=true;
		return {
			xAxis: {
				min:_.min([sliderMin,low,min,min - 0.5*(max-min)/2,selectArea[0]]),
				max:_.max([sliderMax,high,max,max + 0.5*(max-min)/2,selectArea[1]]),
				scale: true,
				nameTextStyle,
				axisLabel:{
					interval:0,
					rotate:10,
					formatter:num=>{
						if(+num>1000000){
							const p = Math.floor(Math.log(+num) / Math.LN10).toFixed(3);
							const n = (+num * Math.pow(10, -p)).toFixed(3);
							return +n + 'e+' + +p;
						}else if(+num<-1000000){
							num = -num;
							const p = Math.floor(Math.log(+num) / Math.LN10).toFixed(3);
							const n = (+num * Math.pow(10, -p)).toFixed(3);
							return '-'+n + 'e+' + +p;
						}
						return num.toFixed(3);
					}
				},
			},
			yAxis: {
				nameTextStyle,
			},
			toolbox:{
				show:false,
			},
			tooltip : {},
			series: {
				type: 'custom',
				renderItem,
				label: {
					normal: {
						show: bin<=20,
						position: 'top',
					},
				},
				encode: {
					x: [0, 1],
					y: 2,
					// tooltip: [0, 1, 2],
					itemName: 3
				},
				data:_data,
		        tooltip:{
		          trigger: 'item',
		          formatter:params=>{
		            const {marker,value} = params;
		            if(!value)return;
		            const [x,y,z] = value;
		            return `
								${marker}[${x.toFixed(3)},${y.toFixed(3)}):${z}
							`;
		          }
		        },
			},
			dataZoom: [{
				type: 'inside',
				rangeMode:['value','value'],
				startValue:sliderMin,
				endValue:_endValue,
			},{
				type: 'slider',
				rangeMode:['value','value'],
				startValue:sliderMin,
				endValue:_endValue,
				labelPrecision:2,
				backgroundColor:"rgba(204,204,204,0.2)",
				labelFormatter: (value)=> {
					if(value === _endValue){
						value = sliderMax;
					}
					const key = mins?'sliderMin':'sliderMax'
					localStore.updateField({
						[key]:value
					})
					mins = !mins;
					return value.toFixed(3);
				},
			}],
			brush: {
				xAxisIndex: 'all',
				brushLink: 'all',
				removeOnClick:false,
				outOfBrush: {
					opacity:0.5,
					colorAlpha: 0.5,
				},
				brushStyle:{
					borderWidth: 1,
					color: 'rgba(176, 227, 155, 0.3)',
					borderColor: 'rgba(254, 197, 113, 0.8)',
				},
			},
		};
	},[localStore.ready,localStore.sliderMin,localStore.sliderMax,localStore.data]);

	const reset = (force=false)=>{
		console.log('reset',force)
		let {low,high} = rawDataView[field];

		localStore.updateField({
			selectArea:[low,high],
		})
		// setBrush()
		if(force){
			const bin = Math.min(rawDataView[field].doubleUniqueValue||Infinity, 14);
			const interval = (high-low)/ 10;
			const startValue =  +low - 2 * +interval;
			const endValue =  +high + 2 *+interval;
			localStore.updateField({
                sliderMin:+(startValue.toFixed(3)),
				sliderMax:+(endValue.toFixed(3)),
				bin,
				loading:true,
				version:localStore.version +1
			})
			// getData(true);
		}
	}

	 const {selectArea} = localStore;
     const {min,max} = project.rawDataView[field];
	 const [start,end] = selectArea;

    useEffect(()=>{
    	if(chart.current&&project.histogramData){
    		console.log('pres refreshData')
		    refreshData()
	    }
    },[project.histogramData])

	const __max = max;
    const __min = min;

    return <>
				<div key="div" className={styles.outlierTop}>
				<div>{EN.Minimum}:<InputNum
					max={+end}
					step={0.01}
					precision={2}
					value={+start}
					style={{ minWidth: 100 }}
					disabled={localStore.loading}
					onChange={(start)=>{
						if(start === '-'){
							start = '-0'
						}
						if(+start>+end){
							start = ((end as any).toFixed(2)*100 - 1)/100;
						}
						localStore.updateField({
							selectArea:[+start,+end],
						})
						setBrush()
					}}
				/></div>
				<div>{EN.Maximum}:<InputNum
					min={+start}
					step={0.01}
					precision={2}
					value={+end}
					style={{ minWidth: 100 }}
					disabled={localStore.loading}
					onChange={(end)=>{
						if(end === '-'){
							end = '-0'
						}
						if(+start>+end){
							end = start;
						}
						localStore.updateField({
							selectArea:[+start,+end],
						})
						setBrush()
					}}
				/></div>
			<Button
				disabled={localStore.loading}
				onClick={reset.bind(this,true)}>{EN.Reset}</Button>
			</div>
			<ReactEcharts
				ref = {chart}
				option={getOption as any}
				style={{height: 400, width: 1000}}
				notMerge={true}
				lazyUpdate={true}
				theme='customed'
				key="'chart"
			/>
	    <div className={styles.outlierRanged} key='pva' id='pva'>
		    <Spin spinning={localStore.loading}>
			    <div className={styles.minmax}>
	                {EN.ChartDisplayRange}:
		            <section>
			            <InputNum
			              max={+localStore.sliderMax}
			              precision={3}
			              value={localStore.sliderMin}
			              style={{ minWidth: 100,marginLeft:20 }}
			              onChange={min=>{
			                if(min>=+localStore.sliderMax){
			                  min = localStore.sliderMax - 0.0001
			                }
			                  localStore.updateField({
			                    sliderMin:min
			                  })
			              }}
			            />
			            <a className = {localStore.loading||+localStore.sliderMin===__min?styles.disable:''}
		                 onClick={()=>{
		                   if(!localStore.loading&&+localStore.sliderMin!==__min){
		                     localStore.updateField({
		                       sliderMin:__min
		                     })
		                   }
		                 }}>{EN.SetToMinimum}</a>
		            </section>
				    <span>~</span>
		            <section>
		                <InputNum
		                    min={+localStore.sliderMin}
		                    precision={3}
			                value={localStore.sliderMax}
			                style={{ minWidth: 100 }}
			                onChange={max=>{
				                if(max<=+localStore.sliderMin){
				                  max = +localStore.sliderMin+ 0.0001
				                }
			                    localStore.updateField({
				                    sliderMax:max
			                    })
			                }}
		                />
	                    <a
		                    className = {localStore.loading||+localStore.sliderMax===__max?styles.disable:''}
		                    onClick={()=>{
			                  if(!localStore.loading&&+localStore.sliderMax!==__max){
			                    localStore.updateField({
			                      sliderMax:__max
			                    })
			                  }
	                        }}>{EN.SetToMaximum}</a>
		                 </section>
	                 </div>
		            <div>
		                {EN.NumberOfCartons}:
					        <InputNum
					          min={1}
					          max={10000}
					          precision={0}
					          value={+localStore.bin}
					          style={{ width: 100 }}
					          onChange={bin=>{
					            if(bin){
						            localStore.updateField({
							            bin,
							            version:localStore.version +1,
							            loading:true,
						            })
						            // getData(true)
					            }
					          }}
					        />
					        (&lt;=10,000)
			            {/*<Button onClick={getData.bind(this,true)}>{EN.Upgrade}</Button>*/}
		            </div>
		        </Spin>
	        </div>
			<div key='bottom' className={styles.fixesBottom}>
				<button className={styles.save} onClick={()=>saveEdit(selectArea)}><span style={{color:'#fff'}}>{EN.Apply}</span></button>
				<button className={styles.cancel} onClick={closeEdit}><span>{EN.Cancel}</span></button>
			</div>
		</>
}

export default observer(OutlierRange);
