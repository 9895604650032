import tus from 'tus-js-client'
import React, { forwardRef, ReactElement, useEffect, useState } from 'react';
import { useLocalStore, useObserver } from 'mobx-react';
import styles from './styles.module.css';
import moment from 'moment'
import EN from '../../../constant/en'

const AllowExt = ["csv", "CSV"]

interface Interface {
  onProgress: (number) => void
  onComplete: ({ fileId, fileName, uploadCost, fileSize }: { fileId: string, fileName: string, uploadCost: number, fileSize: number }) => void
  onStart: any
  className?: string
  children?: ReactElement
  style?,
  file?: File
  onError: (err: Error) => void,
  bucket: string,
  onCheck: (file: File) => Promise<any>
  beforeClose?: (id: string) => void
}

const Uploads = forwardRef((props: Interface, ref: any): ReactElement => {
  const { onProgress, onComplete, onStart, className, children, style = {}, file, onError, bucket, onCheck, beforeClose } = props;
  let costTemp = 0
  let startTime = 0

  useEffect(() => {
    if (!file) return
    const ext = file.name.split('.').pop()
    if (!ext || !AllowExt.includes(ext)) return onError(new Error(EN.PleaseUploaafileinoneofthefollowingformats))
    onStart({})
    let promise = Promise.resolve({ status: 200, message: 'ok' })
    if (onCheck && typeof onCheck === 'function') {
      promise = onCheck(file)
    }
    promise.then(data => {
      if (data.status === 200) {
        start(file)
        onStart({
          pause: abort,
          resume: () => start(file),
          abort,
        })
      } else {
        onError(new Error(data.message))
      }
    })

  }, [file])

  const store = useLocalStore(() => ({
    upload: null,
    updateField(data) {
      Object.assign(this, data);
    },
  }));

  async function up(e) {
    const file = e.target.files[0];
    e.target.value = null
    if (!file) return
    const ext = file.name.split('.').pop()
    if (!ext || !AllowExt.includes(ext)) return onError(new Error(EN.PleaseUploaafileinoneofthefollowingformats))
    onStart({})
    let promise = Promise.resolve({ status: 200, message: 'ok' })
    if (onCheck && typeof onCheck === 'function') {
      promise = onCheck(file)
    }
    promise.then(data => {
      if (data.status === 200) {
        start(file);
        onStart({
          pause: () => abort(false),
          resume: () => start(file),
          abort,
        })
      } else {
        onError(new Error(data.message))
      }
    })
  }
  function start(file: File) {
    startTime = moment().valueOf();
    const upload = new tus.Upload(file, {
      resume: true,
      removeFingerprintOnSuccess: true,
      endpoint: location.origin + '/tusd/files/',
      retryDelays: [0, 3000, 5000, 10000, 20000],
      withCredentials: true,
      chunkSize: 5 * 1024 * 1024,
      headers: {
        r2bucket: bucket,
      },
      metadata: {
        filename: file.name,
        filetype: file.type || 'csv',
      },
      onError: function (error) {
        console.log('Failed because: ' + error);
        errorCallBack(error)
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
        // console.log(bytesUploaded, bytesTotal, percentage + '%');
        onProgress(+percentage);
      },
      onSuccess: function () {
        const realId = getFileId()
        if (!realId) return errorCallBack(new Error("upload Failed"))
        const cost = moment().valueOf() - startTime + costTemp
        costTemp = 0
        onComplete && onComplete({
          fileId: realId,
          fileName: (upload.file as File).name,
          fileSize: (upload.file as File).size,
          uploadCost: cost
        });
      },
    });
    function errorCallBack(error: Error) {
      const realId = getFileId()
      if (realId) {
        if (beforeClose && typeof beforeClose === 'function') {
          beforeClose(realId)
        }
      }
      onError(error)
    }
    function getFileId() {
      if(!upload.url) return ''
      const id = upload.url.split("/").pop()
      if (!id) return ""
      const realId = id.split("+").shift()
      if (!realId) return ""
      return realId
    }

    upload.start();
    store.updateField({
      upload
    });
  }

  function abort(isAbort = true) {
    costTemp += moment().valueOf() - startTime
    if (isAbort) costTemp = 0
    store.upload && store.upload.abort();
  }

  return useObserver(() => <>
    <label className={className}>{children}</label>
    <input
      ref={ref}
      style={style}
      className={styles.input}
      accept={AllowExt.map(n => "." + n).join(",")}
      onChange={up} type='file' />
  </>)
});

export default Uploads;
