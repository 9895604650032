import React, { ReactElement } from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';

interface Interface {
  readonly y_names:string[]
  readonly imports:number[]
  readonly list?:string[]
}

export default function Impact(props:Interface): ReactElement {
  const {y_names,imports,list} = props;
  let sizeValue:number = 80;
  const grid = [];
  const xAxis:any = [{
    show:false,
  }];

  const yAxis:any =  [
    {
      type: 'category',
      data : y_names,
      gridIndex: 0,
      splitLine:{
        show:false
      },
      axisLine:{
        show:false
      },
      axisLabel: {
        "show": false
      }
    }
  ];

  const _series = {
    type: 'bar',
    xAxisIndex: 0,
    yAxisIndex: 0,
    data:imports,
  };

  const series:any = [{
    ..._series,
    label: {
      normal: {
        show: true,
        position: "insideLeft",
        formatter:'{b}:{c}',
        color:'black'
      }
    },
  }];

  if(list.length){
    const _list = list.map(itm=>+itm);
    const full:number = _.max(_list)-_.min(_list);
    sizeValue = 100/(full+1);
    grid.push({
      left: sizeValue+'%',
      right:0,
    });
    xAxis.push({
      ...xAxis[0],
      gridIndex: 1,
    });
    yAxis.push({
      ...yAxis[0],
      gridIndex: 1,
      axisLabel:{
        show:false
      }
    });
    const list_series = {
      ..._series,
      xAxisIndex: 1,
      yAxisIndex: 1,
      barGap:"-100%",
      type: 'bar',
    };
    series.push({//<0
      ...list_series,
      data:list.map((itm:string)=>{
        if(+itm>0||itm==='0.000'){
          return itm;
        }
      }),
      label: {
        normal: {
          show: true,
          "position": "insideLeft",
          color:'black'
        }
      }
    });

    series.push({//>0
      ...list_series,
      data:list.map((itm:string)=>{
        if(+itm<0||itm==='-0.000'){
          return itm;
        }
      }),
      label: {
        normal: {
          show: true,
          "position": "insideRight",
          color:'black'
        }
      }
    })
  }
  grid.unshift({
    right: (100-sizeValue)+'%',
    left:35,
  });
  const option:any = {
    legend: {},
    tooltip: {},
    grid,
    xAxis,
    yAxis,
    dataZoom:{
      type: 'slider',
      rangeMode:['percent','percent'],
      filterMode:"filter",
      orient: 'vertical',
      yAxisIndex:xAxis.map((it,ind)=>ind),
      maxValueSpan:15,
      start:100,
      end: 100 - 100 / y_names.length * 15,
      show:y_names.length>15,
      left:0,
      showDetail:false,
    },
    visualMap: {
      show:false,
      min: -1,
      max: 1,
      dimension: 0,
      inRange: {
        color: ['#b0e39b', '#7fbdfe']
      },
      type:"piecewise",
      pieces:[
        {max:-1e-10,min:-1},
        {min:0,max:1}
      ],
    },
    series,
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: 400, width: '60%' }}
      notMerge={true}
      lazyUpdate={true}
      theme="customed"
    />
  );
}
