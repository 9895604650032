import React from 'react'
import CF from './CF'
import { Spin } from 'antd';

interface Interface {
	data:any
	style?:object
}
export default function ACF_PACF(props:Interface){
	if(!props.data){
		return <Spin>
			<div style={{width:400,height:500}}/>
		</Spin>
	}
	const {
    data: { x, acf, acfLower, acfUpper, pacf, pacfLower, pacfUpper },
    style,
  } = props;

	return <section style={style}>
		<CF
			title='Autocorrelation'
			x={x}
			cf={acf}
			upper={acfUpper}
			lower={acfLower}
		/>
		<CF
			title='Partial Autocorrelation'
			x={x}
			cf={pacf}
			upper={pacfUpper}
			lower={pacfLower}
		/>
	</section>
}
