import React, {
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styles from './styles.module.css';
import {
  Select,
  Checkbox,
  InputNumber,
  Form,
  Divider,
  Input,
  FormInstance,
  FormProps,
} from 'antd';
import EN from '../../../constant/en';
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 8,
    },
  },
};

interface DBConfigFormProps extends FormProps {
  isOutput: boolean;
  // sparkCheck: boolean;
  disabled?: boolean;
  // showHbase?: boolean;
}

const DBConfigForm = React.forwardRef<FormInstance, DBConfigFormProps>(({
  isOutput,
  // sparkCheck,
  // showHbase = true,
  children,
  disabled = false,
  initialValues,
  ...formProps
}) => {
  const [form] = Form.useForm();
  const [rememberMyConnectionProfile, setRememberMyConnectionProfile] = useState<boolean>(initialValues.rememberMyConnectionProfile);
  const [databaseType, setDatabaseType] = useState<string>(
    initialValues.databaseType || 'mysql',
  );
  const [authType, setAuthType] = useState<string>(
    initialValues.auth_type || 'NONE',
  );
  useEffect(() => {
    form.setFieldsValue(initialValues);
    setDatabaseType(initialValues.databaseType);
    setAuthType(initialValues.auth_type);
  }, [initialValues]);
  const getValueFromEvent = (e)=>{
      return e.target.value.replace(/\s+/g,"");
  }
  const changeDatabaseType = (type: string) => {
    setDatabaseType(type);
    if (type === "impala" && authType === 'NONE') {
      setAuthType("NOSASL");
      initialValues.auth_type = "NOSASL";
      initialValues.databaseType = type;
      form.setFieldsValue(initialValues);
    }
    if (type === "hive" && authType === 'NOSASL') {
      setAuthType("NONE");
      initialValues.auth_type = "NONE";
      initialValues.databaseType = type;
      form.setFieldsValue(initialValues);
    }
  }
  // console.log(form.getFieldValue('databaseType'))
  // console.log(form.getFieldValue('auth_type'))
  // const hbase = databaseType === 'hbase';
  return (
    <Form {...formItemLayout} form={form} {...formProps}>
      <Form.Item
        label={EN.Hostname}
        name="sqlHostName"
        required
        rules={[
          {
            required: true,
            message: EN.pleaseenteryourhostname,
          },
        ]}
      >
        <Input placeholder={EN.Eg + 'db.abc.com'} disabled={disabled} />
      </Form.Item>
      <Form.Item
        label={EN.Port}
        name="sqlPort"
        required
        rules={[
          {
            required: true,
            message: EN.invalidhostport,
          },
        ]}
      >
        <InputNumber style={{ width: '100%' }} placeholder={EN.Eg + '12345'} disabled={disabled} />
      </Form.Item>
      <Divider dashed />

      <Form.Item
        label={EN.DatabaseType}
        name="databaseType"
        required
        rules={[
          {
            required: true,
            message: EN.invaliddatabasetype,
          },
        ]}
      >
        <Select onChange={value => changeDatabaseType(value.toString())} disabled={disabled}>
          <Option value={'mysql'}>mysql</Option>
          <Option value={'oracle'}>oracle</Option>
          <Option value={'hive'}>hive</Option>
          <Option value={'gbase'}>gbase</Option>
          {/* {!sparkCheck && showHbase && <Option value={'hbase'}>hbase</Option>} */}
          <Option value={'impala'}>impala</Option>
          <Option value={'vertica'}>vertica</Option>
          <Option value={'clickhouse'}>clickhouse</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={EN.DatabaseName}
        name="sqlDatabase"
        required
        rules={[
          {
            required: true,
            message: EN.pleaseenteryourdatabasename,
          },
        ]}
        getValueFromEvent={ getValueFromEvent } 
      >
        <Input placeholder={EN.pleaseenteryourdatabasename} disabled={disabled} />
      </Form.Item>
      <Form.Item
        label={EN.TableName}
        name="sqlTable"
        required
        rules={[
          {
            required: true,
            message: EN.pleaseenteryourtablename,
          }
        ]}
        getValueFromEvent={ getValueFromEvent } 
      >
        <Input placeholder={EN.Yourtablename} disabled={disabled}/>
      </Form.Item>

      {!isOutput && (
        <Form.Item label={EN.SQLoptional} name="sqlQueryStr" >
          <Input placeholder={EN.SQLforquery} disabled={disabled} />
        </Form.Item>
      )}
      <Form.Item
        label={`${EN.DatabaseEncoding}(${EN.optional})`}
        name="sqlEncoding"
      >
        <Select disabled={disabled}>
          <Option value="utf8">utf8</Option>
          <Option value="gb2312">gb2312</Option>
        </Select>
      </Form.Item>
      <Divider dashed />
      { databaseType === 'hive' &&
        <Form.Item label={`${EN.AuthenticationMethod}`} name="auth_type">
          <Select onChange={value => setAuthType(value.toString())} disabled={disabled}>
            <Option value={"NONE"}>NONE</Option>
            <Option value={"CUSTOM"}>CUSTOM</Option>
            <Option value={"LDAP"}>LDAP</Option>
            <Option value={"KERBEROS"}>KERBEROS</Option>
          </Select>
        </Form.Item>}
      { databaseType === 'impala' &&
        <Form.Item label={`${EN.AuthenticationMethod}`} name="auth_type">
          <Select onChange={value => setAuthType(value.toString())} disabled={disabled}>
            <Option value={"NOSASL"}>NOSASL</Option>
            <Option value={"PLAIN"}>PLAIN</Option>
            <Option value={"LDAP"}>LDAP</Option>
            <Option value={"KERBEROS"}>KERBEROS</Option>
          </Select>
        </Form.Item>}
      { authType !== "KERBEROS" && <>
        <Form.Item label={`${EN.Username}`} name="sqlUserName" required>
          <Input placeholder={EN.Yourdatabaseusername} disabled={disabled} />
        </Form.Item>
        <Form.Item label={`${EN.Password}`} name="sqlPassword" required>
          <Input.Password placeholder={EN.Yourdatabasepassword} disabled={disabled} />
        </Form.Item>
        <Form.Item
          {...tailFormItemLayout}
          name="rememberMyPassword"
          valuePropName="checked"
          required
        >
          <Checkbox disabled={disabled || !rememberMyConnectionProfile}>
            <span className={styles.checkboxText}>{EN.RememberMyPassword}</span>
          </Checkbox>
        </Form.Item>
      </>}
{/* 
      {hbase && (
        <>
          <Form.Item label={`${EN.rowStart}(${EN.optional})`} name="rowStart">
            <Input disabled={disabled} />
          </Form.Item>
          <Form.Item label={`${EN.rowEnd}(${EN.optional})`} name="rowEnd">
            <Input disabled={disabled} />
          </Form.Item>
        </>
      )} */}

      <Divider dashed />

      <Form.Item
        {...tailFormItemLayout}
        name="rememberMyConnectionProfile"
        required
        valuePropName="checked"
      >
        <Checkbox onChange={e => setRememberMyConnectionProfile(e.target.checked)} disabled={disabled}>
          <span className={styles.checkboxText}>
            {EN.RememberMyConnectionProfile}
          </span>
        </Checkbox>
      </Form.Item>
      {children}
    </Form>
  );
});
export default DBConfigForm;
