import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import WelcomeHeader from './WelcomeHeader'
import NormalHeader from './NormalHeader'
import ProjectHeader from './ProjectHeader'
import LoginHeader from './LoginHeader'
import { UserStore } from 'stores/UserStore';
import { History } from 'history';

interface Interface {
  history: History,
}

function Header(props:Interface){
  const {userStore} = useContext(MobXProviderContext);

  const isHome = props.history.location.pathname === '/';
  const isDeploy = props.history.location.pathname.startsWith('/deploy');
  const isLogin = userStore.status === 'login';
  const isProject = props.history.location.pathname.startsWith('/project');
  if (!isLogin)
    return (
      <LoginHeader />
    );
  if (isHome) return <WelcomeHeader />;
  if (isDeploy) return <NormalHeader />;
  if (isProject) return <ProjectHeader />;
  return <WelcomeHeader />
}

export default withRouter(observer(Header));
