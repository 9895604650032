import React, { ReactElement, useEffect, useState } from 'react';
import Project from 'stores/Project';
// import styles from './Forecasting.module.css'
// import { Tabs } from 'antd';
// const { TabPane } = Tabs;
import Variable from './Variable'
import Modeling from './Modeling'
import classes from '../../Result/UnSupervised/styles.module.css';
import EN from '../../../../constant/en';
import classNames from 'classnames';

interface Interface {
  project: Project
}

const Forecasting = (props: Interface): ReactElement => {
  const {
    project,
    project: {
      updateProject,
      measurement,
      FBProphet,
      validationRate,
      ModelingSetting,
      arima,
      sarima,
      forecastSize
    }
  } = props;

  const [view, upView] = useState('Variable Setting');

  useEffect(() => {
    if (view === 'Variable Setting') {
      updateProject({
        measurement,
        FBProphet,
        validationRate,
        ModelingSetting,
        arima,
        sarima,
        forecastSize
      })
    }
  }, [view]);

  return <section className={classes.root} style={{ zIndex: 0 }}>
    <div className={classes.tabs}>
      <span
        className={classNames(classes.tab, (view === 'Variable Setting' ? classes.active : ''))}
        onClick={() => upView('Variable Setting')}
      >
        {EN.VariableSetting}
      </span>
      <span
        className={classNames(classes.tab, (view === 'Modeling Setting' ? classes.active : ''))}
        onClick={() => upView('Modeling Setting')}
      >
        {EN.ModelingSetting}
      </span>
    </div>
    {
      view === 'Variable Setting' ? <Variable next={() => upView('Modeling Setting')} project={project} /> : <Modeling project={project} />
    }
  </section>;
};

export default Forecasting
