import { action, computed, observable, toJS, when } from 'mobx';
import socketStore from './SocketStore';
import Model from './Model';
import { Modal, message as _message, message, notification } from 'antd';
import axios from 'axios';
import { formatNumber } from '../util';
import request from 'components/Request';
import EN from '../../src/constant/en';
import { Coordinate } from 'components/CreateNewVariable/types/Coordinate';
import moment from 'moment';
import ProjectStore from './ProjectStore';
import { ETL_STATUS } from '../constant/common';
import { Domain } from '@app/constant/types';

export interface Stats {
  min?: number;
  max?: number;
  std?: number;
  median?: number;
  uniqueValue: number;
  mode: string;
  mean?: number | string;
  skewness?: number;
  high?: number;
  low?: number;
  doubleUniqueValue?: number;
  dateCount?: number;
  doubleCount?: number;
  q25?: number;
  q75?: number;
}

export interface Categorical {
  missingValue?: number;
}

export interface Numerical {
  mismatch?: number;
  missingValue?: number;
  outlierCount?: number;
}

export interface BaseResponse {
  errorCode?: number;
  message: string;
  status: number;
  result?: any;
  id?: string;
  model?: Model;
  command?: string;
}

export interface CostOption {
  TP: number;
  FP: number;
  FN: number;
  TN: number;
}

export interface Settings {
  id: string;
  name: string;
  setting: unknown;
  models?;
  selected?: string;
}

export interface SsPlot {
  x: number[];
  y: number[];
}

export interface TrainCommand {
  kType?: string;
  kValue?: number;
  algorithms?: string[];
  sparkAlgorithms?: string[];
  standardType?: string;
  // searchTime?: number,
  metricsMethod?: string;
  featureLabel?: string[];
  randomSeed?: number;
  projectId?: string;
  command?: string;
  settingName?: string;
  applyWeights?: NumberObject;
  problemType?: string;
  targetLabel?: string[];
  version?: string;
  sampling?: string;
  speedVSaccuracy?: number;
  ensembleSize?: number;
  randSeed?: number;
  measurement?: string;
  holdoutRate?: number;
  featuresPreprocessor?: string[];
  validationRate?: number;
  nfold?: number;
  showSsPlot?: boolean;
  esIndex?: string;
  settingId?: string;
  mapHeader?: StringObject;
  classificationType?: number;
  classNames?: string[];
  numIter?: number;
  surrogate?: string;
  modelingWay?: string; // 建模方式，自动建模/高级建模
}

export interface DataView {
  [key: string]: Stats;
}

export interface UploadProps {
  totalRawLines: number;
  fileName: string;
  originalIndex: string;
  rawHeader: string[];
  uploadCost: number;
}

export interface NewUploadProps {
  fileName: string;
  fileId: string;
  uploadCost: number;
  fileSize: number;
  rawHeader: string[];
}

export interface NewVariable {
  name: string;
  nameArray: string[];
  exps: Coordinate[];
}

export interface AssociationOption {
  type: 'apriori' | 'fptree';
  fptree: {
    support: number;
    confidence: number;
    lift: number;
    length: number;
  };
  apriori: {
    support: number;
    confidence: number;
    lift: number;
    length: number;
  };
}

export interface AssociationView {
  view: {
    average: number;
    max: number;
    min: number;
    total: number;
    users: number;
  };
  plot: string;
}

export interface AssociationTrainCommand {
  command: string;
  csvLocation: string[];
  algo: 'apriori' | 'fptree';
  minSupport: number;
  minConfidence: number;
  minLift: number;
  maxLength: number;
  featureLabel: string[];
  targetLabel: string[];
  projectId: string;
  problemType: string;
  mapHeader: StringObject;
}

export interface TimeSeriesTrainCommand {
  command: string;
  featureLabel: string[];
  targetLabel: string[];
  orderIndex: string[];
  projectId: string;
  problemType: string;
  algorithm: string;
  trainType: string;
  mapHeader: StringObject;
  modelOption: {
    forecastSize: number;
    splitRate: number;
    metrics: string;
    seasonality?: string;
    yearly?: boolean;
    weekly?: boolean;
    daily?: boolean;
    complexity?: number;
    step?: number;
    unit?: string;
  };
}

export interface OutlierDict {
  [k: string]: [number, number];
}

interface IDectedField {
  colMap: {
    [key: string]: number;
  };
  colType: 'Categorical' | 'Numerical' | 'Raw';
  dataView: {
    [key: string]: Stats;
  };
  mismatchCount: number;
  nullCount: number;
  outlierCount: number;
  uniqueValue: number;
  numericalCount: number;
  comment: string;
  valueCount: {
    [key: string]: number;
  };
}

interface InvalidModel {
  projectId: string;
  modelId: string;
  modelName?: string;
}

export interface Progress {
  requestId: string;
  value: number;
  state: ProgressState;
  type?: ProgressType;
  data?: object;
}

export enum ProgressState {
  COMPLETED = 'completed',
  PROCESSING = 'processing',
  FAILED = 'failed',
}

export enum ProgressType {
  TRAIN = 'train',
}

class Project {
  visiable: boolean;
  @observable init: boolean = false;
  univariatePlotsBase64: StringObject;
  histgramPlotsBase64: StringObject;
  rawHistgramPlotsBase64: StringObject;

  @observable colMap: StringObject = {};
  // @observable stats: Stats;
  @observable userId: string = '';
  @observable models: Model[] = []; //训练完成的模型列表
  @observable trainModel: unknown = {};
  // @observable autorun: IReactionDisposer[] = [];

  @observable id: string = ''; //projectId
  @observable exist: boolean = true;
  @observable loading: boolean = false; //updateProject后为true,不知作用
  @observable host: string = '';
  @observable loadModel: boolean = false;
  @observable email: string = ''; //owner email

  //step
  @observable mainStep: number = 0; //项目完成菜单 0 1 2 3
  @observable curStep: number = 0; //浏览器浏览当前主菜单 0 1 2 3
  @observable lastSubStep: number = 1;
  @observable subStepActive: number = 1; // 当前浏览子菜单

  //project
  @observable name: string = ''; //project name
  @observable tabName: string = ''; // 标签名字
  @observable createTime: number = 0; //create time
  @observable updateTime: number = 0; //update time
  @observable charset: string = 'auto'; //传数据页面设置的编码格式
  @observable bucket: string = ''; //`user-projectId`
  // description;

  //problem
  @observable problemType: string = ''; //问题类型
  @observable statement: string = ''; //问题描述
  @observable business: string = ''; //商业价值
  @observable changeProjectType: string = ''; //和 problemType 啥关系?
  @observable targetUnique: number = 2;
  @observable bigData: boolean = false; //spark

  //etl
  @observable etlId: string = '';
  @observable etling: boolean = false; //上传文件,50%后
  @observable etlProgress: number = 0; //etl进度
  @observable statsTime: number = 0; //etl时间
  @observable databaseConnectType: string = ''; // 数据连接类型 // sample 案例数据 local 本地上传 sql数据库导入
  @observable etlStatus: ETL_STATUS;
  @observable importDbMsg: string = '';
  @observable sqlSource: {
    sqlHostName: string;
    sqlPort: number | null;
    databaseType: string;
    sqlDatabase: string;
    sqlTable: string;
    sqlQueryStr: string;
    sqlEncoding: string;
    sqlUserName: string;
    sqlPassword: string;
    auth_type: string;
    rememberMyPassword: Boolean;
    rememberMyConnectionProfile: Boolean;
  } = {
    sqlHostName: '',
    sqlPort: null,
    databaseType: '',
    sqlDatabase: '',
    sqlTable: '',
    sqlQueryStr: '',
    sqlEncoding: '',
    sqlUserName: '',
    sqlPassword: '',
    auth_type: '',
    rememberMyPassword: true,
    rememberMyConnectionProfile: true,
  };

  @observable initConnectData: NewUploadProps = {
    fileName: '',
    fileId: '',
    uploadCost: 0,
    fileSize: 0,
    rawHeader: [],
  };
  etlCost: number = 0; //用时 开始时间(`project:${projectId}:etlTime`) - now
  statsCost: number = 0; //用时 开始时间(`project:${projectId}:statsTime`) - now

  // upload data
  @observable detectedFields: Record<string, IDectedField> = {};
  @observable dataHeader: string[] = []; //所有的 header
  @observable rawHeader: string[] = []; //所有的 header ??
  // add header map
  @observable mapHeader: StringObject = {}; //{headers}
  @observable colType: StringObject = {}; //"Categorical" "Numerical" "Raw"
  // totalLines: unknown =  0;
  @observable totalRawLines: number = 0; //代码总行数
  // @observable firstEtl: boolean = true;//?
  @observable target: string = ''; //选中的header
  @observable noCompute: boolean = false;
  @observable validationRate: number = 20;
  @observable holdoutRate: number = 20;
  @observable fileName: string = ''; //文件名
  @observable fileId: string = ''; //文件ID
  @observable fileSize: number = 0; //文件大小
  @observable rowsCount: number = 0; //行数
  @observable colsCount: number = 0; //列数
  uploadCost: number = 0;
  @observable orderIndex: string[] = [];
  @observable dataSort: string = 'asc';
  @observable dataSortTemp: string = 'asc';
  @observable cleanData: string = '';
  // cleanData: unknown =  []
  // @observable originPath: string = '';

  @observable noComputeTemp: boolean = false;
  @observable originalIndex: string = '';
  @observable etlIndex: string = '';
  @observable orderNum: NumberObject = {};

  //data quality
  @observable mismatchFillMethod: StringObject = {};
  @observable nullFillMethod: StringObject = {};
  @observable outlierFillMethod: StringObject = {};
  @observable outlierDict: OutlierDict = {};
  @observable targetMap: NumberObject = {};
  @observable targetArray: string[] = [];
  @observable rawDataView: DataView | null = null;
  @observable preImportance: NumberObject | null = null;
  @observable IV: NumberObject | null = null;
  @observable preImportanceLoading: boolean = false;
  @observable importanceProgress: number = 0;
  @observable histgramPlots: StringObject = {};
  @observable univariatePlots: StringObject = {};
  @observable newVariable: string[] = [];
  @observable expression: { [key: string]: NewVariable } = {};
  @observable newType: StringObject = {};
  @observable informativesLabel: string[] = [];
  @observable colValueCounts: { [key: string]: NumberObject } = {};
  @observable totalFixedLines: number = 0;
  @observable nullLineCounts: NumberObject = {};
  @observable mismatchLineCounts: NumberObject = {};
  @observable outlierLineCounts: NumberObject = {};
  @observable renameVariable: StringObject = {};
  @observable missingReason: StringObject = {};
  @observable newVariablePath: string = '';
  @observable newVariableViews: DataView = {};
  @observable otherMap: StringObject = {};
  // totalFixedCount: unknown =  0
  @observable deletedCount: number = 0;
  @observable targetMapTemp: NumberObject = {};
  @observable targetArrayTemp: string[] = [];
  @observable missingReasonTemp: StringObject = {};
  @observable mismatchFillMethodTemp: StringObject = {};
  @observable nullFillMethodTemp: StringObject = {};
  @observable outlierFillMethodTemp: StringObject = {};
  @observable outlierDictTemp: OutlierDict = {};
  @observable deleteColumns: string[] = [];
  @observable m_cro: string = 'macro';
  @observable outlierLoading: boolean = false;
  // @observable hasSendEtl: boolean = false;
  @observable histogramData: string = '';
  @observable deployment;

  // train
  // 训练状态
  @observable train2Finished: boolean = false; //训练结束
  @observable train2ing: boolean = false;
  @observable train2Error: boolean = false;
  // 不需要参加训练的label
  @observable trainHeader: string[] = [];
  @observable customHeader: string[][] = [];
  @observable criteria: string = 'defualt';
  @observable costOption: CostOption = {
    TP: 0,
    FP: 0,
    FN: 0,
    TN: 0,
  };
  @observable mappingKey: string = '';
  @observable distribution: number = 0;
  @observable ssPlot: SsPlot | null = null;

  // Advanced Modeling Setting
  @observable settingId: string = '';
  @observable settings: Settings[] = [];

  // 训练速度和过拟合
  @observable speedVSaccuracy: number = 5;

  @observable ensembleSize: number = 20;
  @observable randSeed: number = 0;
  @observable measurement: string = 'CVNN';
  @observable resampling: string = 'no';
  @observable runWith: string = 'holdout'; // totalLines < 10000 ? 'cross' : 'holdout'
  @observable crossCount: number = 5;
  @observable dataRange: string = 'all';
  @observable customField: string = '';
  @observable customRange: [] | [number, number] = [];
  @observable algorithms: string[] = [];
  @observable sparkAlgorithms: string[] = [];
  @observable incrementalAlgorithms: string[] = [];
  @observable selectId: string = '';
  @observable checkItems: string[] = [];
  @observable incrementMode: boolean = false;
  @observable version: string[] = ['a', 'b', 'd'];
  @observable features: string[] = [
    'Extra Trees',
    'Random Trees',
    'Fast ICA',
    'Kernel PCA',
    'PCA',
    'Polynomial',
    'Feature Agglomeration',
    'Kitchen Sinks',
    'Linear SVM',
    'Nystroem Sampler',
    'Select Percentile',
    'Select Rates',
  ];
  @observable dataViews: DataView | null = null;
  @observable dataViewsLoading: boolean = false;
  @observable dataViewProgress: number = 0;
  @observable algorithmRadio: 'all' | 'none' | 'default' = 'default';

  //un
  @observable weights: NumberObject = {};
  @observable standardType: string = 'standard';
  // @observable searchTime: number = 5;
  @observable kValue: number = 5;
  @observable kType: string = 'auto';

  //un temp
  @observable weightsTemp: NumberObject = {};
  @observable standardTypeTemp: string = 'standard';

  @observable stopModel: boolean = false;
  @observable stopEtl: boolean = false;
  @observable isAbort: boolean = false;
  @observable stopIds: string[] = [];

  @observable reportProgress: number = 0;
  @observable reportProgressText: string = 'init';
  @observable reportCancel: boolean = false;
  @observable isHoldout: boolean = false;
  @observable role: String;
  @observable roles: any = {};
  @observable showSsPlot: boolean = false;
  @observable metricCorrection: {
    metric: string;
    type: string;
    value: number;
  } = { metric: 'default', type: '', value: 0 };
  @observable fbeta: number = 1;
  @observable associationOption: AssociationOption = {
    type: 'fptree',
    fptree: {
      support: 10,
      confidence: 0.5,
      lift: 3,
      length: 3,
    },
    apriori: {
      support: 0.02,
      confidence: 0.2,
      lift: 3,
      length: 3,
    },
  };

  @observable associationView: AssociationView = {
    view: {
      average: 0,
      max: 0,
      min: 0,
      total: 0,
      users: 0,
    },
    plot: '',
  };

  @observable correlationMatrixLoading: boolean = false;
  @observable correlationMatrixData: string = '';

  @observable stationaryPlotData: string = '';
  @observable adfData: string = '';
  @observable densityPlotData: string = '';
  @observable acfPlotData: string = '';
  @observable seasonalPlotData: string = '';
  @observable qqPlotData: string = '';
  @observable forecastSize: number = 5;
  @observable forecastOption: { unit: string; max: number } = {
    unit: '',
    max: 0,
  };
  @observable forecastUnit: string = '';
  @observable FBProphet: {
    yearly: boolean;
    weekly: boolean;
    daily: boolean;
    complexity: number;
    unit: string;
    step: number;
    seasonality: string;
  } = {
    yearly: true,
    weekly: true,
    daily: true,
    complexity: 3,
    unit: 'day',
    step: 1,
    seasonality: 'additive',
  };
  @observable arima: {
    p: number;
    d: number;
    q: number;
  } = {
    p: 1,
    d: 1,
    q: 1,
  };

  @observable sarima: {
    ap: number;
    ad: number;
    aq: number;
    sap: number;
    sad: number;
    saq: number;
    seasonality: number;
  } = {
    ap: 1,
    ad: 1,
    aq: 1,
    sap: 2,
    sad: 2,
    saq: 1,
    seasonality: 7,
  };

  @observable ModelingSetting: {
    algorithm: string;
    forcastingStep: number;
    trainType: string;
    freq: null | number;
  } = {
    algorithm: 'arima',
    forcastingStep: 5,
    trainType: 'auto',
    freq: null,
  };

  @observable faultReason: string = '';
  @observable modelCount: number = 0;

  //sparkdeploy_increment_list_id
  @observable ks: number[] = [3, 5];
  @observable Contamination: number = 5;
  @observable deploy_increment_list_id; //deploy中的项目id
  @observable numIter: number = 50; //参数调优搜索次数
  @observable surrogate: string = 'GaussianProcess'; //超参数调优

  //explain
  @observable modelExplaining: boolean = false;
  @observable modelExplainError: string;

  @observable invalidModels: InvalidModel[] = [];

  @observable creatingContainerState: string = '';

  @observable creatingContainerId: number = 0;

  // progress
  @observable progresses: Object = {};

  constructor(id: string, args: Object) {
    this.id = id;
    this.visiable = true;
    this.setProperty(args);
    // this.getPlots = debounce(this.getPlots,1000)
  }

  readIndex = async (index: string) => {
    const maxCell = 400000;
    const max = Math.min(
      parseInt((maxCell / (this.rawHeader.length || 1)).toString(), 10),
      500,
    );
    const url = `/etls/${index}/preview?end=${max - 1}`;
    const { data } = await axios.get(url);
    const result = data.result
      ? data.result.map((row: StringObject) => this.rawHeader.map(h => row[h]))
      : [];
    return result;
  };

  readFile = async id => {
    const url = `/tusd/files?id=${encodeURIComponent(id)}&r2bucket=${
      this.bucket
    }`;
    let { data } = await axios.get(url);
    if (id.endsWith('.json')) {
      try {
        data = JSON.parse(data);
      } catch (e) {}
    }
    return data;
  };

  reloadData = async (
    start: number,
    end: number,
    missing: string[],
    mismatch: string[],
    outlier: string[],
  ) => {
    const complement = key =>
      key +
      '.' +
      (this.colType[key] === 'Datetime'
        ? 'date'
        : this.colType[key] === 'Numerical'
        ? 'double'
        : 'keyword');
    let query = [`start=${start}`, `end=${end}`];
    if (missing.length > 0)
      query.push('missing=' + missing.map(complement).join(','));
    if (mismatch.length > 0)
      query.push('mismatch=' + mismatch.map(complement).join(','));
    if (outlier.length > 0) {
      query.push('outlier=' + outlier.map(complement).join(','));
      query.push(
        'outlierValue=' +
          outlier
            .map(key => {
              const outlier =
                this.outlierDictTemp[key] &&
                this.outlierDictTemp[key].length === 2
                  ? this.outlierDictTemp[key]
                  : [this.rawDataView[key].low, this.rawDataView[key].high];
              const [low, high] = outlier;
              return low + '|' + high;
            })
            .join(','),
      );
    }
    // this.uploadData = []
    const url = `/etls/${this.originalIndex}/advancedPreview?${query.join(
      '&',
    )}`;
    const { data } = await axios.get(url);
    // this.uploadData = data.result ? data.result.map((row: StringObject) => this.rawHeader.map(h => row[h])) : []
    return data.result
      ? data.result.map((row: StringObject) => this.rawHeader.map(h => row[h]))
      : [];
  };

  reloadTimeData = async (start: number, end: number, index: string[]) => {
    const complement = key =>
      key +
      '.' +
      (this.colType[key] === 'Datetime'
        ? 'date'
        : this.colType[key] === 'Numerical'
        ? 'double'
        : 'keyword');
    let query = [`start=${start}`, `end=${end}`];
    if (index.length > 0)
      query.push('mismatch=' + index.map(complement).join(','));
    // this.uploadData = []
    const url = `/etls/${this.originalIndex}/timePreview?${query.join('&')}`;
    const { data } = await axios.get(url);
    // this.uploadData = data.result ? data.result.map((row: StringObject) => this.rawHeader.map(h => row[h])) : []
    return data.result
      ? data.result.map((row: StringObject) => this.rawHeader.map(h => row[h]))
      : [];
  };

  @computed
  get time_default() {
    const forecastSize = !this.forecastOption?.unit
      ? Math.min(Math.floor(this.totalLines / 2), 5)
      : 1;

    return {
      FBProphet: {
        yearly: true,
        weekly: true,
        daily: true,
        complexity: 3,
        unit: 'day',
        step: 1,
        seasonality: 'additive',
      },
      arima: {
        p: 1,
        d: 1,
        q: 1,
      },
      sarima: {
        ap: 1,
        ad: 1,
        aq: 1,
        sap: 2,
        sad: 2,
        saq: 1,
        seasonality: 7,
      },
      validationRate: 80,
      measurement: 'rmse',
      forecastSize,
      forecastUnit: this.forecastOption?.unit,
    };
  }

  @computed
  get totalLines() {
    return this.totalRawLines - this.deletedCount;
  }

  fetchData = async (path: string) => {
    const api = await socketStore.ready();
    const result = await api.fetchData({ path });
    return result.data;
  };

  @computed
  get _models() {
    return this.models.filter(itm => !itm.errorMsg);
  }

  @computed
  get defaultUploadFile() {
    // this.noComputeTemp = false

    return {
      dataHeader: [],
      rawHeader: [],
      colType: {},
      // totalLines: 0,
      totalRawLines: 0,
      // firstEtl: true,
      target: '',
      noCompute: false,
      rawDataView: null,
      originalIndex: '',
      uploadCost: 0,
      fileSize: 0,
      fileName: '',
      fileId: '',
      etlId: '',
      orderIndex: [],
      dataSort: 'asc',
      dataSortTemp: 'asc',
    } as {
      dataHeader: string[];
      rawHeader: string[];
      colType: StringObject;
      // totalLines: number,
      totalRawLines: number;
      // firstEtl: boolean,
      target: string;
      noCompute: boolean;
      rawDataView: null;
      originalIndex: string;
      uploadCost: number;
      fileSize: number;
      fileName: string;
      fileId: string;
      etlId: string;
      orderIndex: string[];
      dataSort: string;
      dataSortTemp: string;
    };
  }

  @computed
  get defaultDataQuality() {
    const forecastOption = {
      max: Math.floor(this.totalLines / 2),
      unit: '',
    };

    return {
      targetMap: {},
      targetArray: [],
      totalFixedLines: 0,
      renameVariable: {},
      missingReason: {},
      targetMapTemp: {},
      targetArrayTemp: [],
      outlierDictTemp: {},
      outlierDict: {},
      otherMap: {},
      dataViews: null,
      dataViewsLoading: false,
      deletedCount: 0,
      etlIndex: '',
      deleteColumns: [],
      outlierLoading: false,
      mismatchFillMethod: {},
      mismatchFillMethodTemp: {},
      nullFillMethod: {},
      nullFillMethodTemp: {},
      outlierFillMethod: {},
      outlierFillMethodTemp: {},
      orderNum: {},
      forecastOption,
      cleanData: '',
    } as {
      targetMap: NumberObject;
      targetArray: string[];
      totalFixedLines: number;
      renameVariable: StringObject;
      missingReason: StringObject;
      targetMapTemp: NumberObject;
      targetArrayTemp: string[];
      outlierDictTemp: OutlierDict;
      outlierDict: OutlierDict;
      otherMap: StringObject;
      dataViews: null;
      dataViewsLoading: boolean;
      deletedCount: number;
      etlIndex: string;
      deleteColumns: string[];
      outlierLoading: boolean;
      mismatchFillMethod: StringObject;
      mismatchFillMethodTemp: StringObject;
      nullFillMethod: StringObject;
      nullFillMethodTemp: StringObject;
      outlierFillMethod: StringObject;
      outlierFillMethodTemp: StringObject;
      orderNum: NumberObject;
      forecastOption: { unit: string; max: number };
      cleanData: string;
    };
  }

  @computed
  get defaultAlgorithms() {
    let algorithms: string[] = [];
    let disableList: string[] = [];
    if (this.bigData) return [];
    if (this.problemType === 'Clustering') {
      algorithms = [
        'KMeans',
        'GMM',
        'Birch',
        'Agg',
        'SpectralClustering',
        'DBSCAN',
        'MeanShift',
      ];
      disableList =
        (this.totalLines > 100000 && ['Birch', 'MeanShift']) ||
        (this.totalLines > 50000 && ['MeanShift']) ||
        (this.totalLines > 20000 && ['DBSCAN', 'MeanShift']) ||
        [];
    } else if (this.problemType === 'Outlier') {
      algorithms = [
        'HBOS',
        'PCA',
        'IsolationForest',
        'MCD',
        'EllipticEnvelope',
        'OneClassSVM',
        'LocalOutlierFactor',
        'ABOD',
        'FB',
      ];
      disableList =
        (this.totalLines > 300000 && [
          'OneClassSVM',
          'LocalOutlierFactor',
          'ABOD',
          'FB',
          'IsolationForest',
          'MCD',
          'EllipticEnvelope',
        ]) ||
        (this.totalLines > 30000 && [
          'OneClassSVM',
          'LocalOutlierFactor',
          'ABOD',
          'FB',
        ]) ||
        [];
    } else if (this.problemType === 'Classification') {
      algorithms = [
        'adaboost',
        'bernoulli_nb',
        'decision_tree',
        'extra_trees',
        'gaussian_nb',
        'gradient_boosting',
        'k_nearest_neighbors',
        'lda',
        'liblinear_svc',
        'libsvm_svc',
        'multinomial_nb',
        'passive_aggressive',
        'qda',
        'random_forest',
        'sgd',
        // 'xgradient_boosting'
      ];
      disableList =
        (this.totalLines > 1000000 && [
          'adaboost',
          'bernoulli_nb',
          'decision_tree',
          'extra_trees',
          'gaussian_nb',
          'gradient_boosting',
          'k_nearest_neighbors',
          'lda',
          'liblinear_svc',
          'libsvm_svc',
          'multinomial_nb',
          'passive_aggressive',
          'qda',
          'random_forest',
          'sgd',
          // 'xgradient_boosting'
        ]) ||
        [];
    } else if (this.problemType === 'Regression') {
      algorithms = [
        'adaboost',
        'ard_regression',
        'decision_tree',
        'extra_trees',
        'gaussian_process',
        'gradient_boosting',
        'k_nearest_neighbors',
        'liblinear_svr',
        'libsvm_svr',
        'random_forest',
        'ridge_regression',
        'sgd',
        // 'xgradient_boosting',
      ];
      disableList =
        (this.totalLines > 1000000 && [
          'adaboost',
          'ard_regression',
          'decision_tree',
          'extra_trees',
          'gaussian_process',
          'gradient_boosting',
          'k_nearest_neighbors',
          'liblinear_svr',
          'libsvm_svr',
          'random_forest',
          'ridge_regression',
          'sgd',
          // 'xgradient_boosting',
        ]) ||
        [];
    } else if (this.problemType === 'MultiClassification') {
      algorithms = [
        'adaboost',
        'bernoulli_nb',
        'decision_tree',
        'extra_trees',
        'gaussian_nb',
        'gradient_boosting',
        'k_nearest_neighbors',
        'lda',
        'liblinear_svc',
        'libsvm_svc',
        'multinomial_nb',
        'passive_aggressive',
        'qda',
        'random_forest',
        'sgd',
        // 'xgradient_boosting',
      ];
      disableList =
        (this.totalLines > 1000000 && [
          'adaboost',
          'bernoulli_nb',
          'decision_tree',
          'extra_trees',
          'gaussian_nb',
          'gradient_boosting',
          'k_nearest_neighbors',
          'lda',
          'liblinear_svc',
          'libsvm_svc',
          'multinomial_nb',
          'passive_aggressive',
          'qda',
          'random_forest',
          'sgd',
          // 'xgradient_boosting',
        ]) ||
        [];
    }
    return algorithms.filter(a => !disableList.includes(a));
  }

  @computed
  get defaultIncrementalAlgorithms() {
    let algorithms: string[] = [];
    switch (this.problemType) {
      case 'Classification':
        algorithms = ['snm', 'q', 'r', 't'];
        break;
      case 'Regression':
        algorithms = ['q', 'r'];
        break;
    }

    return algorithms;
  }

  @computed
  get defaultSparkAlgorithms() {
    if (!this.bigData) return [];
    let algorithms: string[] = [];
    let disableList: string[] = [];

    switch (this.problemType) {
      case 'Classification':
        algorithms = [
          'spark_logistic_regression',
          'spark_gradient_boosting',
          'spark_random_forest',
          'spark_decision_tree',
          'spark_linear_svc',
          'spark_xgboost',
          // 'spark_naive_bayes'
        ];
        break;
      case 'Regression':
        algorithms = [
          'spark_linear_regression',
          'spark_decision_tree',
          'spark_random_forest',
          'spark_gradient_boosting',
          'spark_generalized_linear_regression',
          'spark_xgboost',
        ];
        break;
      case 'Clustering':
        algorithms = ['KMeans', 'GMM', 'BisectingKMeans'];
        break;
      case 'Outlier':
        algorithms = ['IsolationForest', 'KMeans'];
    }
    // if (this.problemType === "Classification") {
    //   // algorithms = []
    //   algorithms = [
    //     "spark_logistic_regression",
    //     "spark_gradient_boosting",
    //     "spark_random_forest",
    //     "spark_decision_tree",
    //     "spark_naive_bayes",
    //     'spark_xgboost'
    //   ]
    // } else if (this.problemType === "Regression") {
    //   // algorithms = []
    //   algorithms = [
    //     "spark_linear_regression",
    //     "spark_decision_tree",
    //     "spark_random_forest",
    //     "spark_gradient_boosting",
    //     "spark_generalized_linear_regression",
    //     'spark_xgboost'
    //   ]
    // } else if (this.problemType === "Clustering") {
    //   algorithms = [
    //     "KMeans",
    //     "GMM",
    //     "BisectingKMeans",
    //   ]
    // } else if (this.problemType === "Outlier") {
    //   algorithms = [
    //     "IsolationForest",
    //     "KMeans",
    //   ]
    // }
    return algorithms.filter(a => !disableList.includes(a));
  }

  @computed
  get defaultVersion() {
    if (this.bigData) return ['s'];
    const version = ['a', 'b'];
    if (this.problemType === 'MultiClassification') {
      version.push('c');
      if (this.totalLines > 5000000) version.length = 0;
    } else if (
      this.problemType === 'Classification' ||
      this.problemType === 'Regression'
    ) {
      version.push('q', 'r');
      if (this.totalLines <= 1000000) version.push('c');
      if ((window as any).r2_env.supportDnn) version.push('d');
      if (this.problemType === 'Classification' && this.totalLines <= 1000000)
        version.push('e', 'snm', 't');
      if (this.totalLines > 5000000) version.length = 0;
      // if ((window as any).r2_env.useSpark) version.push('s')
    }
    return version;
  }

  @computed
  get defaultTrain() {
    // 初始化训练数据
    const measurement =
      (this.problemType === 'Classification' && 'auc') ||
      (this.problemType === 'Regression' && 'r2') ||
      (this.problemType === 'Clustering' &&
        (this.bigData ? 'silhouette_euclidean' : 'CVNN')) ||
      (this.problemType === 'Outlier' && 'score') ||
      (this.problemType === 'MultiClassification' && 'macro_auc') ||
      (this.problemType === 'Forecasting' && 'rmse') ||
      '';
    // const version = [1, 2]

    // if (this.problemType === 'Classification' || this.problemType === 'Regression') {
    //   version.push(3)
    //   version.push(4)
    //   if (!config.debug) version.push(6)
    // }
    const min =
      this.problemType === 'Classification' ||
      this.problemType === 'MultiClassification'
        ? Math.min(
            ...Object.values(this.targetCounts)
              .sort((a, b) => b - a)
              .slice(0, this.targetUnique),
          )
        : Infinity;
    const forecastSize = !this.forecastOption?.unit
      ? Math.min(this.forecastOption?.max, 5)
      : 1;
    const forecastUnit = this.forecastOption?.unit;

    return {
      train2Finished: false,
      train2ing: false,
      train2Error: false,
      criteria: 'default',
      costOption: { TP: 0, FP: 0, FN: 0, TN: 0 },
      speedVSaccuracy: 5,
      ensembleSize: 20,
      // maxTime: 10,
      randSeed: 0,
      resampling: 'no',
      runWith: this.totalLines < 10000 ? 'cross' : 'holdout',
      crossCount: Math.min(min - 1, 5),
      dataRange: 'all',
      customField: '',
      customRange: [],
      algorithms: this.defaultAlgorithms,
      sparkAlgorithms: this.defaultSparkAlgorithms,
      measurement,
      selectId: '',
      version: this.defaultVersion,
      trainHeader: [],
      customHeader: [],
      newVariable: [],
      newType: {},
      expression: {},
      validationRate: 20,
      holdoutRate: 20,
      preImportanceLoading: false,
      preImportance: {},
      informativesLabel: [],
      mappingKey: '',
      newVariablePath: '',
      newVariableViews: {},
      distribution: 0,
      weights: {},
      standardType: 'standard',
      // searchTime: 5,
      kValue: 5,
      kType: 'auto',
      trainModel: {},
      stopIds: [],
      features: [
        'Extra Trees',
        'Random Trees',
        'Fast ICA',
        'Kernel PCA',
        'PCA',
        'Polynomial',
        'Feature Agglomeration',
        'Kitchen Sinks',
        'Linear SVM',
        'Nystroem Sampler',
        'Select Percentile',
        'Select Rates',
      ],
      ssPlot: null,
      algorithmRadio: 'default',
      settingId: '',
      settings: [],
      metricCorrection: { metric: 'default', type: '', value: 0 },
      fbeta: 1,
      correlationMatrixLoading: false,
      correlationMatrixData: '',
      stationaryPlotData: '',
      adfData: '',
      densityPlotData: '',
      acfPlotData: '',
      seasonalPlotData: '',
      qqPlotData: '',
      FBProphet: {
        yearly: true,
        weekly: true,
        daily: true,
        complexity: 3,
        unit: 'day',
        step: 1,
        seasonality: 'additive',
      },
      arima: {
        p: 1,
        d: 1,
        q: 1,
      },
      sarima: {
        ap: 1,
        ad: 1,
        aq: 1,
        sap: 2,
        sad: 2,
        saq: 1,
        seasonality: 7,
      },
      ModelingSetting: {
        algorithm: 'arima', // 算法
        forcastingStep: 5, // 预测步长
        trainType: 'auto', // 建模方式
        freq: null,
      },
      // hasSendEtl: false,
      forecastSize,
      forecastUnit,
      histgramPlots: {},
      univariatePlots: {},
      ks: this.problemType === 'Clustering' ? [3, 5] : [2],
      Contamination: 5,
      numIter: 50,
      surrogate: 'GaussianProcess',
    } as {
      train2Finished: boolean;
      train2ing: boolean;
      train2Error: boolean;
      criteria: string;
      costOption: { TP: 0; FP: 0; FN: 0; TN: 0 };
      speedVSaccuracy: number;
      ensembleSize: number;
      // maxTime: 10,
      randSeed: number;
      resampling: string;
      runWith: string;
      crossCount: number;
      dataRange: string;
      customField: string;
      customRange: string[];
      algorithms: string[];
      sparkAlgorithms: string[];
      measurement: string;
      selectId: string;
      version: string[];
      trainHeader: string[];
      customHeader: string[];
      newVariable: string[];
      newType: unknown;
      expression: unknown;
      validationRate: number;
      holdoutRate: number;
      preImportanceLoading: boolean;
      preImportance: NumberObject;
      informativesLabel: string[];
      mappingKey: string;
      newVariablePath: string;
      newVariableViews: unknown;
      distribution: number;
      weights: NumberObject;
      standardType: string;
      // searchTime: number,
      kValue: number;
      kType: string;
      trainModel: unknown;
      stopIds: string[];
      features: string[];
      ssPlot: null;
      algorithmRadio: 'all' | 'none' | 'default';
      settingId: string;
      settings: Settings[];
      metricCorrection: { metric: string; type: string; value: number };
      fbeta: number;
      correlationMatrixLoading: boolean;
      correlationMatrixData: string;
      stationaryPlotData: string;
      adfData: string;
      densityPlotData: string;
      acfPlotData: string;
      seasonalPlotData: string;
      qqPlotData: string;
      FBProphet: any;
      arima: any;
      ModelingSetting: any;
      // hasSendEtl: boolean,
      forecastSize: number;
      forecastUnit: string;
      histgramPlots: StringObject;
      univariatePlots: StringObject;
      ks: number[];
      Contamination: number;
      numIter: number;
      surrogate: string;
    };
  }

  @computed
  get settingName(): string {
    if (this.currentSetting) return this.currentSetting.name;
    return '';
  }

  @computed
  get currentSetting(): Settings | undefined {
    return this.settings.find((s: any) => s.id === this.settingId);
  }

  @action
  goback = () => {
    const { mainStep, lastSubStep } = this;
    let backStep = mainStep;
    let backSubStep = lastSubStep;
    if (lastSubStep === 1) {
      backStep--;
      backSubStep = backStep === 2 ? 3 : 1;
    } else {
      backSubStep--;
    }
    this.updateProject({
      curStep: backStep,
      mainStep: backStep,
      lastSubStep: backSubStep,
      subStepActive: backSubStep,
    });
  };

  @action
  jump = (routeIndex: number, subStepActive: number) => ({
    subStepActive,
    curStep: routeIndex,
  });

  @action
  nextMainStep = (routeIndex: number) => {
    let obj;
    if (routeIndex <= this.mainStep) {
      obj = {
        curStep: routeIndex,
        subStepActive: 1,
      };
    } else
      obj = {
        curStep: routeIndex,
        mainStep: routeIndex,
        subStepActive: 1,
        lastSubStep: 1,
      };
    return obj;
  };

  @action
  nextSubStep = (subStepIndex: number, routeIndex: number) => {
    let obj;
    if (routeIndex === this.mainStep) {
      if (subStepIndex > this.lastSubStep) {
        obj = {
          subStepActive: subStepIndex,
          lastSubStep: subStepIndex,
        };
      } else {
        obj = {
          subStepActive: subStepIndex,
        };
      }
    } else {
      obj = {
        subStepActive: subStepIndex,
      };
    }
    return obj;
  };

  @action
  upM_cro(mc) {
    this.m_cro = mc;
  }

  @action
  upIsHoldout(isHoldout: boolean) {
    // if(this.problemType === "Classification"){
    //   const {chartData,holdoutChartData,fitIndex} = this.selectModel;
    //   const {roc:{Threshold:data}} = !isHoldout?holdoutChartData:chartData;
    //   const {roc:{Threshold:nextData}} = isHoldout?holdoutChartData:chartData;
    //
    //   const od = data[fitIndex];
    //
    //   const nextValue = Object.values(nextData).sort((a,b)=>Math.abs(a-od)-Math.abs(b-od))[0];
    //
    //   const newFitIndex = Object.entries(nextData).filter(itm=>itm[1] === nextValue)[0][0];
    //   this.selectModel.fitIndex = +newFitIndex;
    // }
    if (this) {
      this.isHoldout = isHoldout;
    }
  }

  @action
  checkPName = name => {
    return socketStore.ready().then(api => {
      return api
        .checkPName({
          name,
          projectId: this.id,
        })
        .then(({ used }) => {
          return used;
        });
    });
  };

  @action
  updateProject = (data: Object) => {
    this.loading = true;

    return socketStore.ready().then(api => {
      return api.updateProject({ ...data, id: this.id }).then(() => {
        this.setProperty(data);
      });
    });
  };
  @action
  updateProjectEtlStatus = (data: {
    etlStatus: ETL_STATUS;
    databaseConnectType?: string;
  }) => {
    this.loading = true;
    this.etlStatus = data.etlStatus;
    this.databaseConnectType = data.databaseConnectType;
    return socketStore.ready().then(api => {
      return api.updateProject({ ...data, id: this.id });
    });
  };

  @action
  updateInitConnectData = (data: NewUploadProps) => {
    this.initConnectData = { ...data };
  };

  @action
  queryCreatingContainer = () => {
    this.loading = true;
    return socketStore.ready().then(api => {
      return api.queryCreatingContainer();
    });
  };

  // checkModels = ()=>{
  //   socketStore.ready().then(api => {
  //     api.checkModels({
  //       projectId:this.id
  //     })
  //   })
  // }

  @action
  fetchProject = async () => {
    const api = await socketStore.ready();
    return api.initProject({ id: this.id });
  };

  @action
  refreshProject = () => {
    return this.fetchProject().then(result => {
      const { status, message, project } = result;
      if (status !== 200) {
        Modal.error({
          title: result.errorCode ? `[${result.errorCode}]` : EN.DataException,
          content: message,
        });
      }
      // return antdMessage.error(message)
      this.setProperty({ ...project, init: true });
      this.initModels();
    });
  };

  @action
  checkCreatingContainerState = () => {
    return socketStore.ready().then(api => {
      return api.checkCreatingContainerState().then(res => {
        return {
          creatingContainerState: res.data.value,
          creatingContainerId: res.data.id,
        };
      });
    });
  };

  @action
  editCreatingContainerState = (paramId, paramValue) => {
    return socketStore.ready().then(api => {
      return api
        .editCreatingContainerState({ paramId, paramValue })
        .then(res => {
          return {
            creatingContainerState: res.data.value,
            creatingContainerId: res.data.id,
          };
        });
    });
  };

  @action
  initProject = () => {
    if (this.init) return;
    this.fetchProject().then(result => {
      const { status, message, project } = result;
      if (status !== 200) {
        Modal.error({
          title: result.errorCode ? `[${result.errorCode}]` : EN.DataException,
          content: message,
        });
      }
      // return antdMessage.error(message)
      this.setProperty({ ...project, init: true });
      this.initModels();
    });
  };

  @action
  clean = () => {
    this.init = false;
    this.loadModel = false;
    // this.autorun.forEach(f => f())

    // //重新初始化
    // const initProject = new Project(this.id, {
    //   id: this.id,
    //   fileName: this.fileName,
    //   createTime: this.createTime,
    //   updateTime: this.updateTime,
    //   name: this.name,
    //   problemType: this.problemType,
    //   train2ing: this.train2ing,
    //   modelCount: this.modelCount,
    //   visiable: this.visiable
    // })

    // Object.assign(this, initProject)
    // this.uploadData = []
    // this.cleanData = []
  };

  setDefaultProperty = (data: Partial<Project>) => {
    // console.log('setDefaultProperty')
    const keys = [
      'fileName',
      'name',
      'problemType',
      'train2ing',
      'createTime',
      'modelCount',
    ];
    const _data = {};
    keys.forEach(k => {
      if (data[k] !== undefined) _data[k] = data[k];
    });
    Object.assign(this, { ..._data, updateTime: moment().unix() });
  };

  @action
  setProperty = (data: Partial<Project>) => {
    Reflect.deleteProperty(data, 'totalLines');
    Reflect.deleteProperty(data, 'userId');
    Reflect.deleteProperty(data, 'id');
    for (let key in data) {
      const value = Reflect.get(data, key);
      if (typeof value === 'function') {
        Reflect.deleteProperty(data, key);
        continue;
      }
      if (key === 'problemType') {
        Reflect.set(data, 'changeProjectType', value);
      }
      if (key === 'noCompute') {
        Reflect.set(data, 'noComputeTemp', value);
      }
      if (key === 'weights') {
        Reflect.set(data, 'weightsTemp', value);
      }
      if (key === 'standardType') {
        Reflect.set(data, 'standardTypeTemp', value);
      }
    }
    // data.updateTime = +new Date()
    Object.assign(this, data);
  };

  /**---------------------------------------------problem-------------------------------------------------*/
  @action
  saveProblem = async force => {
    const updObj: {
      statement: string;
      business: string;
      problemType: string;
      measurement?: string;
      targetUnique: number;
      bigData: boolean;
    } = {
      statement: this.statement,
      business: this.business,
      problemType: this.changeProjectType,
      targetUnique:
        ((this.changeProjectType === 'Classification' ||
          this.changeProjectType === 'Outlier') &&
          2) ||
        0,
      bigData: this.bigData,
    };
    updObj.measurement =
      (this.changeProjectType === 'Classification' && 'auc') ||
      (this.changeProjectType === 'Regression' && 'r2') ||
      (this.changeProjectType === 'Clustering' &&
        (this.bigData ? 'silhouette_euclidean' : 'CVNN')) ||
      (this.changeProjectType === 'Outlier' && 'score') ||
      (this.problemType === 'MultiClassification' && 'macro_auc') ||
      '';
    if (
      (this.problemType && this.changeProjectType !== this.problemType) ||
      force
    ) {
      await this.abortTrainByEtl(false);
      await socketStore.stopCommands(this.id);
      if (this.fileId) await this.deleteIndex(this.fileId);
      this.models = [];
      //全部恢复到problem步骤
      const backData = Object.assign(
        {},
        this.defaultUploadFile,
        this.defaultDataQuality,
        this.defaultTrain,
        updObj,
        {
          mainStep: 2,
          curStep: 2,
          lastSubStep: 1,
          subStepActive: 1,
        },
      );

      await this.updateProject(backData);
    } else {
      await this.updateProject(Object.assign({}, updObj, this.nextMainStep(2)));
    }
  };

  /**---------------------------------------------progress-------------------------------------------------*/
  @action
  getPrejectTrainProgresses = async () => {
    console.log('getPrejectTrainProgresses init');
    if (!this.stopIds) return;
    const api = await socketStore.ready();
    this.progresses = {};
    const result = await api.getProgressByRequestIds(this.stopIds);
    if (result.status == 200 && result.list) this.progresses = result.list;
    console.log(this.progresses);
  };

  /**---------------------------------------------data-------------------------------------------------*/
  // 修改上传文件
  // @action
  // fastTrackInit = async (data: UploadProps) => {
  //   return
  //   await this.abortTrainByEtl(false)
  //   this.models = []
  //   const api = await socketStore.ready()
  //   const { header } = await api.getHeader({ index: data.originalIndex })

  //   const totalRawLines = data.totalRawLines
  //   const fileName = data.fileName
  //   const originalIndex = data.originalIndex
  //   const mapHeader = data.rawHeader.map(_h => _h.trim())
  //   const uploadCost = data.uploadCost
  //   const rawHeader = header
  //   const dataHeader = header
  //   const backData = Object.assign({}, this.defaultUploadFile, this.defaultDataQuality, this.defaultTrain, {
  //     rawHeader,
  //     dataHeader,
  //     mapHeader,
  //     uploadCost,
  //     totalRawLines,
  //     originalIndex,
  //     fileName,
  //     mainStep: 2,
  //     curStep: 2,
  //     lastSubStep: 1,
  //     subStepActive: 1
  //   })
  //   console.info(`upload cost: ${uploadCost}ms`)
  //   this.etling = true
  //   await this.updateProject(backData)
  //   // const pass = await this.etl()
  //   const result = await this.originalStats()
  //   if (result.status !== 200) {
  //     Modal.error({
  //       title: 'ERROR!',
  //       content: result.message
  //     });
  //     // antdMessage.error(result.message)
  //   }
  // }

  @action
  originalStats = async () => {
    const api = await socketStore.ready();
    return await api.originalStats({
      index: this.originalIndex,
      projectId: this.id,
      headers: toJS(this.rawHeader),
    });
  };
  @action
  abortDatabaseImport = async () => {
    // if (!this.etlId) return Promise.resolve()
    return await socketStore.ready().then(api =>
      api.abortDatabaseImport({
        command: 'top.stop',
        projectId: this.id,
        etlId: this.etlId,
        fileId: this.fileId,
      }),
    );
  };

  @action
  abortEtlData = async () => {
    // if (!this.etlId) return Promise.resolve()
    return await socketStore.ready().then(api =>
      api.abortEtlData({
        command: 'top.stop',
        projectId: this.id,
        etlId: this.etlId,
        fileId: this.fileId,
      }),
    );
  };

  @action
  dataInit = async (data: NewUploadProps, isSample: boolean = false) => {
    // 案例数据/手动上传完成，数据数据初始化
    !this.bigData && (await this.abortTrainByEtl(false));
    !this.bigData && (await socketStore.stopCommands(this.id));
    this.models = [];
    // const api = await socketStore.ready()
    if (!data.fileId) return;
    // 2022-10-20TODO
    // console.log('dataInit', data);

    const columns = data.rawHeader;

    const fileId = data.fileId;
    const fileSize = data.fileSize;
    const fileName = data.fileName;
    const uploadCost = data.uploadCost;
    let backData = Object.assign(
      {},
      this.defaultUploadFile,
      this.defaultDataQuality,
      this.defaultTrain,
      {
        fileName,
        fileId,
        uploadCost,
        fileSize,
        mainStep: 2,
        curStep: 2,
        lastSubStep: 1,
        subStepActive: 1,
        etling: true,
        etlStatus: ETL_STATUS.DETECTPENDING,
      },
    );
    this.etlStatus = ETL_STATUS.DETECTPENDING;
    this.etling = true;
    if (this.bigData) {
      this.etling = false;
      backData = {
        fileName,
        fileId,
        uploadCost,
        fileSize,
      };
    }
    await this.updateProject(backData);
    console.info(`upload cost: ${uploadCost}ms`);

    if (this.bigData) return;

    const result = await this.newOriginalStats(isSample, columns);
    if (result.status !== 100) {
      let errorMsg = result.message;
      if (result.status === -2) {
        errorMsg = EN.EmptyData;
        window.alert(errorMsg);
      }
      // 超过使用限制
      else if (result.status === -3) {
        errorMsg = EN.CommandLimitExceed;
        window.alert(errorMsg);
      } else {
        // antdMessage.error(errorMsg)
        Modal.error({
          title: result.errorCode ? `[${result.errorCode}]` : EN.DataException,
          content: errorMsg,
          okText: EN.ok,
        });
      }
      // this.updateProject({ fileName: '', fileId: '', etling: false })
    }
  };

  /**
   * @desc 传完文件执行
   * @param isSample
   * @param columns
   */
  @action
  newOriginalStats = async (isSample: boolean, columns: string[]) => {
    const api = await socketStore.ready();
    const result = await api.newOriginalStats({
      projectId: this.id,
      charset: this.charset,
      isSample,
    });
    console.info(`stats cost: ${this.statsCost}ms`);
    return result;
  };

  @action
  autoFixHeader = () => {
    /**
     * 自动修改header
     */
    const temp: NumberObject = {};
    const header = Object.keys(this.mapHeader).map((h, i) => {
      h = h.trim();
      let _h = h;
      if (/^$/.test(h)) {
        _h = `Unnamed: ${i}`;
      }
      if (!temp[h]) {
        temp[h] = 1;
      } else {
        _h = h + '.' + temp[h];
        temp[h]++;
      }
      return _h;
    });

    // 上传文件，target为空
    return this.updateProject({
      mapHeader: header,
    });
  };

  @computed
  get headerTemp() {
    //查看是否存在相同名称的header
    let temp: { [key: string]: number[] } = {};
    let isMissed = false;
    let isDuplicated = false;
    this.rawHeader.forEach((h, i) => {
      h = h.trim();
      if (!h) {
        isMissed = true;
        return;
      }
      if (!temp[h]) {
        temp[h] = [i];
      } else {
        isDuplicated = true;
        temp[h].push(i);
      }
    });
    return {
      temp,
      isMissed,
      isDuplicated,
    };
  }

  getAssociationData = () => {
    return socketStore.ready().then(async api => {
      if (!this.target) throw new Error('error target');
      if (this.dataHeader.length !== 1) throw new Error('error feature');
      const feature = this.dataHeader[0];
      const featureLabel = [this.target, feature];
      const index = this.originalIndex;
      const {
        status,
        message,
        errorCode,
      }: BaseResponse = await api.getAssociationData({
        command: 'correlation.dataView',
        featureLabel,
        index,
        id: this.id,
      });
      if (status < 0) {
        return {
          message,
          errorCode,
        };
      }
      return;
    });
  };

  @action
  endSchema = async () => {
    // 先重置进度
    this.etlProgress = 0;
    await this.updateProject({
      etlProgress: 0,
    });
    this.etling = true;
    await this.abortTrainByEtl(false);
    await socketStore.stopCommands(this.id);
    this.models = [];
    this.mismatchFillMethod;
    const data: {
      target: string;
      colType: StringObject;
      dataHeader: string[];
      noCompute: boolean;
      nullFillMethod: StringObject;
      nullFillMethodTemp: StringObject;
      outlierFillMethod: StringObject;
      outlierFillMethodTemp: StringObject;
      mismatchFillMethod: StringObject;
      mismatchFillMethodTemp: StringObject;
      crossCount?: number;
      targetUnique?: number;
      orderIndex: string[];
      dataSort: string;
      dataSortTemp: string;
    } = {
      target: this.target,
      colType: { ...this.colType },
      dataHeader: [...this.dataHeader],
      noCompute: this.noComputeTemp,
      nullFillMethod: this.nullFillMethod,
      nullFillMethodTemp: this.nullFillMethodTemp,
      outlierFillMethod: this.outlierFillMethod,
      outlierFillMethodTemp: this.outlierFillMethodTemp,
      mismatchFillMethod: this.mismatchFillMethod,
      mismatchFillMethodTemp: this.mismatchFillMethodTemp,
      orderIndex: [...this.orderIndex],
      dataSort: this.dataSort,
      dataSortTemp: this.dataSort,
    };
    const isAssociation = this.problemType === 'Association';
    const isMulti = this.problemType === 'MultiClassification';
    if (isMulti) data.targetUnique = this.targetUnique;
    if (this.noComputeTemp || isAssociation) {
      if (
        this.problemType === 'Classification' ||
        this.problemType === 'MultiClassification'
      ) {
        const defaultCount =
          this.problemType === 'MultiClassification'
            ? Math.min(Math.max(Math.ceil(this.totalLines / 100), 3), 10)
            : 3;
        const kvs = Object.entries(this.targetCounts)
          .sort((a, b) => b[1] - a[1])
          .slice(0, this.targetUnique);
        const smallKeys = kvs
          .filter(tc => tc[1] < defaultCount)
          .map(([key]) => key || 'NULL');
        if (!this.bigData && !!smallKeys.length) {
          this.etling = false;
          this.etlProgress = 0;
          return Promise.reject(
            `${EN.rowslessthantrainbefore}${
              smallKeys.length === 1
                ? smallKeys[0]
                : `${smallKeys.slice(0, smallKeys.length - 1).join(' ,')}${
                    EN.and
                  }${smallKeys.slice(smallKeys.length - 1)}`
            }${EN.rowslessthantrainafter}`,
          );
        }
        const min = Math.min(...kvs.map(kv => kv[1]));
        if (min < 5) data.crossCount = min - 1;
      }

      if (isAssociation) {
        try {
          this.etlProgress = 50;
          const errorInfo = await this.getAssociationData();
          if (errorInfo) {
            Modal.error({
              title: errorInfo.errorCode ? `[${errorInfo.errorCode}]` : EN.DataException,
              content: errorInfo.message,
              okText: EN.ok,
            });
            return;
          }
        } catch (e) {
          this.etling = false;
          this.etlProgress = 0;
          // return antdMessage.error(e.message)
          return Modal.error({
            title: EN.DataException,
            content: e.message,
          });
        }
        await this.updateProject(
          Object.assign({}, this.defaultDataQuality, this.defaultTrain, data, {
            curStep: 3,
            mainStep: 3,
            subStepActive: 1,
            lastSubStep: 1,
          }),
        );
        this.etling = false;
        return;
      }
      await this.updateProject(
        Object.assign({}, this.defaultDataQuality, data),
      );
      const pass = await this.newEtl();
      if (!pass) {
        this.etlProgress = 0;
        this.etling = false;
        return;
      }
      await this.updateProject(Object.assign({}, this.defaultTrain));
      this.etlProgress = 0;
      this.etling = false;
      // this.etling = false
    } else {
      const step = {
        curStep: 2,
        mainStep: 2,
        subStepActive: 3,
        lastSubStep: 3,
      };
      await this.updateProject(
        Object.assign(
          {},
          this.defaultDataQuality,
          this.defaultTrain,
          data,
          step,
        ),
      );
      this.etlProgress = 0;
      this.etling = false;
    }
  };

  @computed
  get qualityHasChanged() {
    if (!this.etlIndex) return true;
    let hasChange = false;
    const list = [
      'targetMap',
      'outlierDict',
      'nullFillMethod',
      'mismatchFillMethod',
      'outlierFillMethod',
    ];
    for (const item of list) {
      const before = this[item];
      const after = this[item + 'Temp'];
      hasChange = this.hasChanged(before, after);
      if (hasChange) break;
    }
    return hasChange || this.dataSort !== this.dataSortTemp;
  }

  @action
  endQuality = async () => {
    if (!this.qualityHasChanged) return;
    // 先重置进度
    this.etlProgress = 0;
    await this.updateProject({
      etlProgress: 0,
    });
    this.etling = true;
    await this.abortTrainByEtl(false);
    await socketStore.stopCommands(this.id);
    if (this.etlIndex) await this.deleteIndex(this.etlIndex);
    this.models = [];
    const data: {
      targetMap: NumberObject;
      targetArray: string[];
      outlierDict: OutlierDict;
      nullFillMethod: StringObject;
      mismatchFillMethod: StringObject;
      outlierFillMethod: StringObject;
      missingReason: StringObject;
      crossCount?: number;
      dataHeader: string[];
      dataSort: string;
      dataSortTemp: string;
    } = {
      targetMap: toJS(this.targetMapTemp),
      targetArray: toJS(this.targetArrayTemp),
      outlierDict: toJS(this.outlierDictTemp),
      nullFillMethod: toJS(this.nullFillMethodTemp),
      mismatchFillMethod: toJS(this.mismatchFillMethodTemp),
      outlierFillMethod: toJS(this.outlierFillMethodTemp),
      missingReason: toJS(this.missingReasonTemp),
      dataHeader: this.dataHeader.filter(h => !this.deleteColumns.includes(h)),
      dataSort: this.dataSortTemp,
      dataSortTemp: this.dataSortTemp,
    };

    if (
      this.problemType === 'Classification' ||
      this.problemType === 'MultiClassification'
    ) {
      const defaultCount =
        this.problemType === 'MultiClassification'
          ? Math.min(Math.max(Math.ceil(this.totalLines / 100), 3), 10)
          : 3;
      const kvs = Object.entries(this.targetCounts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, this.targetUnique);
      const smallKeys = kvs
        .filter(tc => tc[1] < defaultCount)
        .map(([key]) => key || 'NULL');
      if (!!smallKeys.length) {
        this.etling = false;
        this.etlProgress = 0;
        return Promise.reject(
          `${EN.rowslessthantrainbefore}${
            smallKeys.length === 1
              ? smallKeys[0]
              : `${smallKeys.slice(0, smallKeys.length - 1).join(' ,')}${
                  EN.and
                }${smallKeys.slice(smallKeys.length - 1)}`
          }${EN.rowslessthantrainafter}`,
        );
      }
      const min = Math.min(...kvs.map(kv => kv[1]));
      if (min < 5) data.crossCount = min - 1;
    }
    await this.updateProject(data);
    // await this.etl()
    const pass = await this.newEtl();
    if (!pass) {
      this.etlProgress = 0;
      this.etling = false;
      return Promise.reject();
    }
    const step = {
      curStep: 2,
      mainStep: 2,
      subStepActive: 3,
      lastSubStep: 3,
    };
    await this.updateProject(Object.assign({}, this.defaultTrain, step));
    this.etlProgress = 0;
    this.etling = false;
    return true;
  };

  deleteIndex = async (index: string) => {
    // return Promise.resolve()
    if (!index) return;
    const api = await socketStore.ready();
    return api.deleteIndex({ index, projectId: this.id });
  };

  newEtl = async () => {
    const api = await socketStore.ready();
    const isTimeSeries = ['Forecasting', 'Survival', 'Prediction'].includes(
      this.problemType,
    );
    const fields = [
      ...new Set([
        this.target,
        ...(isTimeSeries ? this.orderIndex : this.dataHeader),
      ]),
    ];
    const { status, message, errorCode } = await api.newEtl(
      {
        projectId: this.id,
        dataHeader: fields,
        command: 'etl.transform',
      },
      ({ progress }: { progress: number }) => {
        this.etlProgress = progress;
      },
    );
    // 同步
    // this.setProperty(result)
    if (status < 0) {
      Modal.error({
        title: errorCode ? `[${errorCode}]` : EN.DataException,
        content: message,
        okText: EN.ok,
        centered: true,
      });
    }
    console.info(`etl cost: ${this.etlCost}ms`);
    return status === 100;
  };

  redoUploadData = () => {
    socketStore.ready().then(api => {
      const startTime = moment().valueOf();
      api
        .databaseImportDb({
          ...this.sqlSource,
          projectId: this.id,
          bigData: this.bigData,
          problemType: this.problemType,
        })
        .then(resp => {
          if (resp.abort) return;
          if (resp.status === 100) {
            this.updateInitConnectData({
              fileId: resp.result?.csvLocation,
              fileName: this.sqlSource.sqlTable,
              uploadCost: moment().valueOf() - startTime,
              fileSize: resp.result?.fileSize,
              rawHeader: resp.result?.columns,
            });
            this.dataInit({
              fileId: resp.result?.csvLocation,
              fileName: this.sqlSource.sqlTable,
              uploadCost: moment().valueOf() - startTime,
              fileSize: resp.result?.fileSize,
              rawHeader: resp.result?.columns,
            });
          }
        });
    });
  };
  redoDetectDataView = () => {
    console.log('redoDetectDataView', this.initConnectData);

    if (!this.bigData) {
      this.dataInit(this.initConnectData);
      return;
    }
    // const data = {
    //   fileName: this.fileName,
    //   fileId: this.fileId,
    //   uploadCost: this.uploadCost,
    //   fileSize: this.fileSize,
    //   rawHeader: this.rawHeader
    // }
    // this.dataInit(data)
    socketStore.ready().then(api => {
      api.databaseDetectedDateView({
        ...this.sqlSource,
        projectId: this.id,
        bigData: this.bigData,
        problemType: this.problemType,
      });
    });
  };

  continueTask = async () => {
    const backData = {
      mainStep: 2,
      curStep: 2,
      lastSubStep: 2,
      subStepActive: 2,
      etling: false,
      importDbMsg: '',
    };
    await this.updateProject(backData);
  };

  hasChanged = (before: Object, after: Object): boolean => {
    if (Object.keys(before).length === Object.keys(after).length) {
      for (const key in before) {
        if (before.hasOwnProperty(key)) {
          const bvalue = Reflect.get(before, key);
          const avalue = Reflect.get(after, key);
          if (typeof bvalue === 'object') {
            const changed = this.hasChanged(bvalue as Object, avalue as Object);
            if (changed) return changed;
          } else {
            if (bvalue !== avalue) return true;
          }
        }
      }
      return false;
    }
    return true;
  };

  @computed
  get targetCounts() {
    const {
      colValueCounts,
      target,
      nullLineCounts,
      targetArrayTemp,
      targetMapTemp,
    } = this;
    if (!target) return {};
    const countData = { ...colValueCounts[target] };
    if (nullLineCounts[target]) countData[''] = nullLineCounts[target];
    return (
      (!targetArrayTemp?.length
        ? countData
        : targetArrayTemp
            ?.map((v, k) => {
              let n = 0;
              Object.entries(targetMapTemp).forEach(([key, value]) => {
                if (value === k) n += countData ? countData[key] || 0 : 0;
              });
              return { [v]: n };
            })
            .reduce((start, item) => {
              return Object.assign(start, item);
            }, {})) || {}
    );
  }

  @computed
  get formatStatsTime() {
    return moment(Number(this.statsTime)).format('YYYY-MM-DD HH:mm:ss');
  }

  @computed
  get issues() {
    const data = {
      rowIssue: false,
      dataIssue: false,
      targetIssue: false,
      targetRowIssue: false,
    };
    const {
      colType,
      totalRawLines,
      targetCounts,
      rawDataView,
      rawHeader,
      targetArrayTemp,
      target,
      variableIssueCount,
      targetIssuesCountsOrigin,
      targetUnique,
    } = this;
    const targetUniques = targetUnique || NaN;
    if (colType[target] === 'Categorical') {
      const isLess =
        Object.keys(targetCounts).filter(_k => _k !== '').length === 1;
      const isMore = Object.keys(targetCounts).length > targetUniques;
      const isGood =
        !!targetArrayTemp.length ||
        (isNaN(targetUniques)
          ? !Object.keys(targetCounts).includes('')
          : !isLess && !isMore);
      data.targetIssue = !isGood;
    } else if (colType[target] === 'Numerical') {
      const dataview = Reflect.get(rawDataView, target);
      const unique = dataview.uniqueValue || 1000;
      data.targetIssue = unique < Math.min((rawHeader.length - 1) * 6, 1000);
    }

    if (totalRawLines < 1000) {
      data.rowIssue = true;
    }

    if (
      target &&
      colType[target] === 'Numerical' &&
      targetIssuesCountsOrigin.mismatchRow +
        targetIssuesCountsOrigin.nullRow +
        targetIssuesCountsOrigin.outlierRow >
        0
    ) {
      data.targetRowIssue = true;
    }

    if (
      +variableIssueCount.nullCount +
        +variableIssueCount.mismatchCount +
        +variableIssueCount.outlierCount >
      0
    ) {
      data.dataIssue = true;
    }

    return data;
  }

  @computed
  get variableIssues() {
    const {
      dataHeader,
      nullLineCounts,
      mismatchLineCounts,
      outlierLineCounts,
      colType,
      totalRawLines,
      problemType,
      target,
      deleteColumns,
    } = this;
    const obj = {
      mismatchRow: {},
      nullRow: {},
      outlierRow: {},
    };
    const variables = [
      ...dataHeader.filter(h => h !== target),
      ...deleteColumns,
    ];
    variables.forEach(h => {
      let mismatchRow = 0;
      let nullRow = 0;
      let outlierRow = 0;
      if (
        (colType[h] === 'Numerical' || colType[h] === 'Datetime') &&
        mismatchLineCounts[h]
      ) {
        mismatchRow =
          ((mismatchLineCounts[h] || 0) / (totalRawLines || 1)) * 100;
      }
      if (nullLineCounts[h]) {
        nullRow = ((nullLineCounts[h] || 0) / (totalRawLines || 1)) * 100;
      }
      if (
        problemType === 'Clustering' &&
        colType[h] === 'Numerical' &&
        outlierLineCounts[h]
      ) {
        outlierRow = ((outlierLineCounts[h] || 0) / (totalRawLines || 1)) * 100;
      }
      obj.mismatchRow = Object.assign(obj.mismatchRow, { [h]: mismatchRow });
      obj.nullRow = Object.assign(obj.nullRow, { [h]: nullRow });
      obj.outlierRow = Object.assign(obj.outlierRow, { [h]: outlierRow });
    });
    return obj;
  }

  @computed
  get variableIssueCount() {
    const {
      nullLineCounts,
      mismatchLineCounts,
      outlierLineCounts,
      target,
      colType,
      problemType,
      dataHeader,
      deleteColumns,
    } = this;
    const variables = [...dataHeader, ...deleteColumns];
    const nullCount = Object.entries(
      Object.assign({}, nullLineCounts, { [target]: 0 }) || {},
    )
      .filter(([_k]) => variables.includes(_k))
      .reduce((sum, [k, v]) => sum + (Number.isInteger(v) ? v : 0), 0);
    const mismatchCount = Object.entries(
      Object.assign({}, mismatchLineCounts, { [target]: 0 }) || {},
    )
      .filter(([_k]) => variables.includes(_k))
      .reduce(
        (sum, [k, v]) =>
          sum + (colType[k] === 'Numerical' && Number.isInteger(v) ? v : 0),
        0,
      );
    const outlierCount =
      problemType === 'Clustering'
        ? Object.entries(
            Object.assign({}, outlierLineCounts, { [target]: 0 }) || {},
          )
            .filter(([_k]) => variables.includes(_k))
            .reduce(
              (sum, [k, v]) =>
                sum +
                (colType[k] === 'Numerical' && Number.isInteger(v) ? v : 0),
              0,
            )
        : 0;

    return { nullCount, mismatchCount, outlierCount };
  }

  @computed
  get targetColMap() {
    const { colValueCounts, target, nullLineCounts } = this;
    const countData = { ...colValueCounts[target] };
    if (nullLineCounts[target]) countData[''] = nullLineCounts[target];
    let n = 0;
    const array = Object.entries(countData).sort((a, b) => b[1] - a[1]);
    const map = array.reduce((start, [k]) => {
      return Object.assign(start, { [k]: n++ });
    }, {});
    return map;
  }

  @computed
  get targetIssuesCountsOrigin() {
    const {
      target,
      outlierLineCounts,
      mismatchLineCounts,
      nullLineCounts,
      colType,
    } = this;
    const arr = {
      mismatchRow:
        colType[target] === 'Datetime' || colType[target] === 'Numerical'
          ? mismatchLineCounts[target] || 0
          : 0,
      nullRow: nullLineCounts[target],
      outlierRow:
        colType[target] === 'Numerical' ? outlierLineCounts[target] || 0 : 0,
    };
    return arr;
  }

  @action
  dataView = () => {
    // const key = isClean ? 'dataViews' : 'rawDataView'
    // const featureLabel = [...this.dataHeader, ...this.newVariable].filter(v => !Object.keys(this[key]).includes(v))
    // if(!featureLabel.length) return Promise.resolve()
    return socketStore.ready().then(api => {
      // const command = {
      //   projectId: this.id,
      //   command: 'dataView',
      //   actionType: isClean ? 'clean' : 'raw',
      //   feature_label
      // };
      const readyLabels = Object.keys(this.newVariableViews);
      // const data_label = this.dataHeader.filter(v => !readyLabels.includes(v))
      const feature_label = this.newVariable.filter(
        v => !readyLabels.includes(v),
      );
      // const feature_label = [...data_label, ...new_label]
      if (!feature_label.length || feature_label.length === 0)
        return Promise.resolve();

      const command = {
        projectId: this.id,
        command: 'top.dataView',
        actionType: 'clean',
        feature_label,
      };
      // if (new_label.length) {
      //   const variables = [...new Set(new_label.map(label => label.split("_")[1]))]
      //   command.csvScript = variables.map(v => this.expression[v]).filter(n => !!n).join(";").replace(/\|/g, ",")
      // }
      this.dataViewsLoading = true;
      return api.dataView(command).then((returnValue: BaseResponse) => {
        const { status, message, errorCode } = returnValue;
        if (status < 0) {
          return Modal.error({
            title: errorCode ? `[${errorCode}]` : EN.DataException,
            content: message,
            okText: EN.ok,
            centered: true,
          });
        }
        // this.setProperty({ newVariableViews: result.data, dataViewsLoading: false })
      });
    });
  };

  @action
  fixTarget = () => {
    this.updateProject({
      targetMapTemp: this.targetMapTemp,
      targetArrayTemp: this.targetArrayTemp,
      otherMap: this.otherMap,
    });
  };

  @action
  fixFillMethod = () => {
    this.updateProject({
      outlierDictTemp: toJS(this.outlierDictTemp),
      nullFillMethodTemp: toJS(this.nullFillMethodTemp),
      mismatchFillMethodTemp: toJS(this.mismatchFillMethodTemp),
      outlierFillMethodTemp: toJS(this.outlierFillMethodTemp),
      missingReasonTemp: toJS(this.missingReasonTemp),
      deleteColumns: [...this.deleteColumns],
      orderNum: { ...this.orderNum },
    });
    this.outlierCount();
  };

  // @action
  // addNewVariable = (variableName, variables, exp, type) => {
  //   const fullExp = `${ variables.map(v => "@" + v).join(",") } = ${ exp }`
  //   const oldExp = Object.values(this.expression).join(";")
  //   const allExp = `${ oldExp }; ${ fullExp } `

  //   return socketStore.ready().then(api => {
  //     const command = {
  //       projectId: this.id,
  //       command: 'top.createNewVariable',
  //       csvScript: allExp.replace(/\|/g, ",")
  //     };
  //     return api.createNewVariable(command, progressResult => {
  //     }).then(returnValue => {
  //       const { status, result } = returnValue
  //       if (status < 0) {
  //         antdMessage.error(result.msg)
  //         return false
  //       }
  //       const newVariable = [...this.newVariable, ...variables]
  //       const trainHeader = [...this.trainHeader, ...variables]
  //       const newType = Object.assign({}, this.newType, variables.reduce((start, v) => {
  //         start[v] = type
  //         return start
  //       }, {}))
  //       const expression = Object.assign({}, this.expression, { [variableName]: fullExp })
  //       this.updateProject({
  //         newVariable,
  //         trainHeader,
  //         expression,
  //         newType,
  //       })
  //       return true
  //     })
  //   })
  // }

  outlierCount = () => {
    return Promise.resolve();
    // if (this.outlierLoading) return Promise.resolve()
    // const { originalIndex, outlierDictTemp, id, outlierDict, rawDataView } = this

    // const changedRange = Object.entries(outlierDictTemp).filter(([key, range]) => {
    //   const [low, high] = range
    //   if (outlierDict.hasOwnProperty(key)) {
    //     const [_low, _high] = outlierDict[key]
    //     if (low === _low && high === _high) return false
    //     return true
    //   } else {
    //     if (low === rawDataView[key].low && high === rawDataView[key].high) return false
    //     return true
    //   }
    // })

    // if (!changedRange.length) return Promise.resolve()

    // this.outlierLoading = true
    // const changedDict = changedRange.reduce((prev, [key, value]) => {
    //   prev[key] = value
    //   return prev
    // }, {} as OutlierDict)
    // return socketStore.ready().then(api => api.outlierCount({
    //   index: originalIndex,
    //   stats: changedDict,
    //   projectId: id
    // }))
  };

  @action
  addNewVariable2 = (variables: NewVariable[], type: StringObject) => {
    const scripts = [...Object.values(this.expression), ...variables].map(
      v => ({
        name: v.nameArray.map(n => ({
          value: n,
          type: 'ID',
          name: n,
        })),
        script: v.exps,
      }),
    );

    return socketStore.ready().then(api => {
      const command = {
        projectId: this.id,
        command: `${this.bigData ? 'spark.' : ''}etl.createNewVariable`,
        csvScript: scripts,
      };
      return api
        .createNewVariable(command)
        .then((returnValue: BaseResponse) => {
          const { status, result, message, errorCode } = returnValue;
          console.log(result, 'createNewVariable');
          if (status < 0) {
            return Modal.error({
              title: errorCode ? `[${errorCode}]` : EN.DataException,
              content: message,
              okText: EN.ok,
              centered: true,
            });
          }
          const variablenames = variables.reduce(
            (prev, _v) => [...prev, ..._v.nameArray],
            [],
          );
          const newVariable = [...this.newVariable, ...variablenames];
          const trainHeader = [...this.trainHeader, ...variablenames];
          const newType = Object.assign({}, this.newType, type);
          const variableExp = variables.reduce((prev, _v) => {
            prev[_v.name] = _v;
            return prev;
          }, {} as { [key: string]: NewVariable });
          const expression = Object.assign({}, this.expression, variableExp);

          const uptData: {
            newVariable;
            trainHeader;
            expression;
            newType;
            newVariableViews?;
            preImportance?;
            informativesLabel?;
          } = {
            newVariable,
            trainHeader,
            expression,
            newType,
          };
          if (result.dataView) uptData.newVariableViews = result.dataView;
          if (result.importanceData)
            uptData.preImportance = result.importanceData;
          if (result.informativesLabel)
            uptData.informativesLabel = result.informativesLabel;
          return this.updateProject({
            newVariable,
            trainHeader,
            expression,
            newType,
            newVariableViews: result.dataView,
          }).then(() => true);
        });
    });
  };

  /**---------------------------------------------train------------------------------------------------*/
  @computed
  get selectModel() {
    if (this.selectId)
      return (
        this.models.find(m => m.id === this.selectId) || this.recommendModel
      );
    return this.recommendModel;
  }

  @computed
  get selectModels() {
    return this.checkItems.map(itm => {
      return this.models.find(m => m.modelName === itm) || this.recommendModel;
    });
    // if (this.selectId) return this.models.find(m => m.id === this.selectId) || this.recommendModel
    // return this.recommendModel
  }

  @computed
  get recommendModel() {
    const {
      costOption,
      criteria,
      models,
      problemType,
      defaultRecommendModel,
      targetCounts,
      distribution,
    } = this;
    const [v0, v1] = Object.values(targetCounts);
    const percent0 = parseFloat(formatNumber((v1 / (v0 + v1)).toString(), 4));
    const percentNew = distribution ? distribution / 100 : percent0;
    let model;
    if (problemType === 'Classification') {
      const { TP, FN, FP, TN } =
        criteria === 'cost' ? costOption : { TP: 0, FN: 0, FP: 0, TN: 0 };
      if (TP || FN || FP || TN) {
        if (!!defaultRecommendModel.length) {
          for (
            let i = 0;
            i < Math.ceil(defaultRecommendModel.length * 0.3);
            i++
          ) {
            if (!model) {
              model = defaultRecommendModel[i];
              continue;
            }
            if (
              model.getBenefit(TP, FN, FP, TN, percentNew, percent0).benefit <
              defaultRecommendModel[i].getBenefit(
                TP,
                FN,
                FP,
                TN,
                percentNew,
                percent0,
              ).benefit
            ) {
              model = defaultRecommendModel[i];
            }
          }
        }
      }
    }
    if (model) return model;
    if (!!defaultRecommendModel.length) return defaultRecommendModel[0];
    return models[0];
  }

  // @computed
  // get defaultRecommendModel() {
  //   const { models, measurement, problemType } = this
  //   const currentMeasurement = measurement || (problemType === 'Classification' ? 'auc' : 'r2')
  //   const endWithSe = currentMeasurement.endsWith("se")
  //   const filterModels = models.filter(_m => {
  //     const { score } = _m
  //     const { validateScore, holdoutScore } = score || {}
  //     if (!validateScore || !holdoutScore) return false
  //     const [validate, holdout] = [parseFloat(validateScore[currentMeasurement]), parseFloat(holdoutScore[currentMeasurement])]
  //     if (isNaN(validate) || isNaN(holdout)) return false
  //     // if (validate < 0 || holdout < 0) return false
  //     return true
  //   }).map(m => {
  //     const { score, id } = m
  //     const { validateScore, holdoutScore } = score || {}
  //     const validate = formatNumber(validateScore[currentMeasurement], 6)
  //     const holdout = formatNumber(holdoutScore[currentMeasurement], 6)
  //     const diff = formatNumber(Math.abs(validate - holdout), 6)
  //     const base = endWithSe ? formatNumber(validate / holdout, 6) : formatNumber(holdout / validate, 6)
  //     return { validate, holdout, diff, id, base }
  //   })
  //   // 没有有效值的model就推荐第一个
  //   if (!filterModels.length) return models[0]
  //   const data = filterModels.filter(m => {
  //     const { validate, holdout } = m
  //     if (validate < 0 || holdout < 0) return false
  //     return true
  //   })
  //   // 有效值都小于0 推荐holdout最大的
  //   let recommend
  //   if (!data.length) {
  //     recommend = [...filterModels].sort((a, b) => b.holdout - a.holdout)[0]
  //   } else {
  //     const holdoutArr = endWithSe ? [...data].sort((a, b) => b.holdout - a.holdout) : [...data].sort((a, b) => a.holdout - b.holdout)
  //     const diffArr = [...data].sort((a, b) => b.diff - a.diff)
  //     data.forEach(d => {
  //       const { id, base } = d
  //       const diffIndex = diffArr.indexOf(d) + 1
  //       const holdoutIndex = holdoutArr.indexOf(d) + 1
  //       const score = formatNumber(base * diffIndex * holdoutIndex, 6)
  //       if (!recommend) return recommend = { id, score }
  //       if (recommend.score < score) return recommend = { id, score }
  //     })
  //   }
  //   // recommend
  //   return models.find(m => m.id === recommend.id)
  // }

  @computed
  get defaultRecommendModel() {
    let { models, measurement, problemType } = this;
    if (problemType === 'Association') return this.models;
    const currentMeasurement =
      measurement ||
      (problemType === 'Classification' && 'auc') ||
      (problemType === 'Regression' && 'r2') ||
      (problemType === 'Clustering' &&
        (this.bigData ? 'silhouette_euclidean' : 'CVNN')) ||
      (problemType === 'Outlier' && 'score') ||
      (problemType === 'MultiClassification' && 'macro_auc') ||
      (problemType === 'Forecasting' && 'rmse');
    const sort =
      currentMeasurement === 'CVNN' || currentMeasurement.endsWith('se')
        ? -1
        : 1;
    const currentModels = models.filter(_m => !_m.id.includes('DBSCAN'));

    return (!currentModels.length ? models : currentModels)
      .map(m => {
        const { score, chartData, holdoutChartData } = m;
        const { validateScore, holdoutScore } = score;
        let validate, holdout;
        if (problemType === 'Classification') {
          validate = Reflect.get(
            m,
            (currentMeasurement === 'log_loss'
              ? 'logloss'
              : currentMeasurement) + 'Validation',
          ); //m[currentMeasurement + 'Validation']
          holdout = Reflect.get(
            m,
            (currentMeasurement === 'log_loss'
              ? 'logloss'
              : currentMeasurement) + 'Holdout',
          ); //m[currentMeasurement + 'Holdout']
        } else if (problemType === 'Regression') {
          validate = Reflect.get(validateScore, currentMeasurement); //validateScore[currentMeasurement]
          holdout = Reflect.get(holdoutScore, currentMeasurement); //holdoutScore[currentMeasurement]
        } else if (problemType === 'Clustering' || problemType === 'Outlier') {
          validate = Reflect.get(score, currentMeasurement) || Infinity; //score[currentMeasurement]
          holdout = Reflect.get(score, currentMeasurement) || Infinity; //score[currentMeasurement]
        } else if (problemType === 'MultiClassification') {
          const [t, p] = currentMeasurement.split('_');
          validate =
            currentMeasurement === 'macro_auc'
              ? chartData.roc_auc.macro
              : p === 'f1'
              ? validateScore[`${t} _F1`]
              : validateScore[`${t} _${p.slice(0, 1).toUpperCase()} `];
          holdout =
            currentMeasurement === 'macro_auc'
              ? holdoutChartData.roc_auc.macro
              : p === 'f1'
              ? holdoutScore[`${t} _F1`]
              : holdoutScore[`${t} _${p.slice(0, 1).toUpperCase()} `];
        } else if (problemType === 'Forecasting') {
          validate = Reflect.get(score, currentMeasurement); //validateScore[currentMeasurement]
          holdout = Reflect.get(score, currentMeasurement); //holdoutScore[currentMeasurement]
        }
        if (isNaN(+validate) || isNaN(+holdout)) return null;
        return { id: m.id, value: validate + holdout };
      })
      .filter(_m => {
        return !!_m;
      })
      .sort((a, b) => (b.value - a.value) * sort)
      .map(_m => models.find(m => m.id === _m.id));
  }

  @action
  fastTrain = () => {
    if (this.train2ing) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: EN.YourProjectIsCurrentlyInTraining,
      });
    }
    // return antdMessage.error("Your project is already training, please stop it first.")
    const {
      id,
      problemType,
      target,
      dataHeader,
      colType,
      mapHeader,
      bigData,
    } = this;

    let command = '';
    let trainData: TrainCommand = {};

    const featureLabel = dataHeader
      .filter(d => d !== target)
      .filter(h => colType[h] !== 'Raw');
    const realLabel = featureLabel.reduce((prev, k) => {
      prev[k] = mapHeader[k];
      return prev;
    }, {});
    if (!bigData && !featureLabel.length) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: '缺少特征标签!',
      });
    }
    // return antdMessage.error("no feature label");
    const setting = this.settings.find(s => s.id === this.settingId);
    if (!setting || !setting.name) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: EN.ModelingSettingsException,
      });
    }
    // return antdMessage.error("setting error")

    switch (problemType) {
      case 'Clustering':
        command = 'clustering.train';
        trainData = {
          kType: 'auto',
          kValue: undefined,
          algorithms: this.defaultAlgorithms,
          sparkAlgorithms: this.defaultSparkAlgorithms,
          standardType: 'standard',
          speedVSaccuracy: 5,
          // searchTime: 5,
          metricsMethod: this.bigData ? 'silhouette_euclidean' : 'CVNN',
          featureLabel,
          randomSeed: 0,
          projectId: id,
          command,
          settingName: setting.name,
          settingId: setting.id,
          applyWeights: {},
          problemType,
          targetLabel: target ? [target] : [],
          esIndex: this.etlIndex,
          mapHeader: realLabel,
          numIter: 50,
          surrogate: 'GaussianProcess',
          modelingWay: 'auto',
        };
        break;
      case 'Outlier':
        command = 'outlier.train';
        trainData = {
          algorithms: this.defaultAlgorithms,
          sparkAlgorithms: this.defaultSparkAlgorithms,
          standardType: 'standard',
          speedVSaccuracy: 5,
          // searchTime: 5,
          featureLabel,
          randomSeed: 0,
          projectId: id,
          command,
          settingName: setting.name,
          settingId: setting.id,
          applyWeights: {},
          problemType,
          targetLabel: target ? [target] : [],
          esIndex: this.etlIndex,
          mapHeader: realLabel,
          numIter: 50,
          surrogate: 'GaussianProcess',
          modelingWay: 'auto',
        };
        break;
      case 'Classification':
        const min = Math.min(...Object.values(this.targetCounts));
        command = 'clfreg.train';
        trainData = {
          problemType,
          featureLabel,
          targetLabel: [target],
          projectId: id,
          version: this.defaultVersion.join(','),
          command,
          sampling: 'no',
          speedVSaccuracy: 5,
          ensembleSize: 20,
          randSeed: 0,
          measurement: 'auc',
          settingName: setting.name,
          settingId: setting.id,
          holdoutRate: 0.2,
          algorithms: this.defaultAlgorithms,
          sparkAlgorithms: this.defaultSparkAlgorithms,
          featuresPreprocessor: [
            'Extra Trees',
            'Random Trees',
            'Fast ICA',
            'Kernel PCA',
            'PCA',
            'Polynomial',
            'Feature Agglomeration',
            'Kitchen Sinks',
            'Linear SVM',
            'Nystroem Sampler',
            'Select Percentile',
            'Select Rates',
          ].map(fe => Reflect.get(formatFeature('Classification'), fe)),
          esIndex: this.etlIndex,
          mapHeader: realLabel,
          numIter: 50,
          surrogate: 'GaussianProcess',
          modelingWay: 'auto',
        };
        if (this.totalLines > 10000) {
          trainData.validationRate = 0.2;
        } else {
          trainData.nfold = Math.min(min, 5);
        }
        break;
      case 'Regression':
        command = 'clfreg.train';
        trainData = {
          problemType,
          featureLabel,
          targetLabel: [target],
          projectId: id,
          version: this.defaultVersion.join(','),
          command,
          sampling: 'no',
          speedVSaccuracy: 5,
          ensembleSize: 20,
          randSeed: 0,
          measurement: 'r2',
          settingName: setting.name,
          settingId: setting.id,
          holdoutRate: 0.2,
          algorithms: this.defaultAlgorithms,
          sparkAlgorithms: this.defaultSparkAlgorithms,
          featuresPreprocessor: [
            'Extra Trees',
            'Random Trees',
            'Fast ICA',
            'Kernel PCA',
            'PCA',
            'Polynomial',
            'Feature Agglomeration',
            'Kitchen Sinks',
            'Linear SVM',
            'Nystroem Sampler',
            'Select Percentile',
            'Select Rates',
          ].map(fe => Reflect.get(formatFeature('Regression'), fe)),
          esIndex: this.etlIndex,
          mapHeader: realLabel,
          numIter: 50,
          surrogate: 'GaussianProcess',
          modelingWay: 'auto',
        };
        if (this.totalLines > 10000) {
          trainData.validationRate = 0.2;
        } else {
          trainData.nfold = 5;
        }
        break;
      case 'MultiClassification':
        const Multimin = Math.min(...Object.values(this.targetCounts));
        const classes =
          this.targetArray.length > 1
            ? this.targetArray
            : Object.entries(this.colValueCounts[this.target])
                .sort((a, b) => b[1] - a[1])
                .map(v => v[0])
                .slice(0, this.targetUnique);
        command = 'multi.train';
        trainData = {
          problemType,
          featureLabel,
          targetLabel: [target],
          projectId: id,
          version: this.defaultVersion.join(','),
          command,
          sampling: 'no',
          speedVSaccuracy: 5,
          ensembleSize: 20,
          randSeed: 0,
          measurement: 'macro_auc',
          settingName: setting.name,
          settingId: setting.id,
          holdoutRate: 0.2,
          algorithms: this.defaultAlgorithms,
          sparkAlgorithms: this.defaultSparkAlgorithms,
          featuresPreprocessor: [
            'Extra Trees',
            'Random Trees',
            'Fast ICA',
            'Kernel PCA',
            'PCA',
            'Polynomial',
            'Feature Agglomeration',
            'Kitchen Sinks',
            'Linear SVM',
            'Nystroem Sampler',
            'Select Percentile',
            'Select Rates',
          ].map(fe => Reflect.get(formatFeature('Classification'), fe)),
          esIndex: this.etlIndex,
          mapHeader: realLabel,
          classificationType: this.targetUnique,
          classNames: classes,
          numIter: 50,
          surrogate: 'GaussianProcess',
          modelingWay: 'auto',
        };
        if (this.totalLines > 10000) {
          trainData.validationRate = 0.2;
        } else {
          trainData.nfold = Math.min(Multimin, 5);
        }
        break;
      default:
        return;
    }

    // id: request ID
    // projectId: project ID
    // csv_location: csv 文件相对路径
    // problem_type: 预测类型 Classification , Regression
    // feature_label: 特征列名
    // target_label:  目标列
    // fill_method:  无效值
    // model_option: model的额外参数，不同model参数不同
    // kwargs:
    // const trainData = {
    //   problemType,
    //   featureLabel,
    //   targetLabel: target,
    //   projectId: id,
    //   version: '1,2',
    //   command,
    //   sampling: 'no',
    //   speedVSaccuracy: 5,
    //   ensembleSize: 20,
    //   randSeed: 0,
    //   measurement: problemType === "Classification" ? "auc" : "r2",
    //   settingName: setting.name,
    //   holdoutRate: 0.2
    // };
    if (this.bigData) {
      if (!trainData.sparkAlgorithms.length) {
        return Modal.error({
          title: EN.ModelingFailed,
          content: EN.Youneedtoselectatleast,
        });
      }
      // return antdMessage.error(EN.Youneedtoselectatleast);
    } else {
      if (
        problemType === 'Classification' ||
        problemType === 'Regression' ||
        problemType === 'MultiClassification'
      ) {
        if (!trainData.version) {
          return Modal.error({
            title: EN.ModelingFailed,
            content: EN.Youneedtoselectatleast,
          });
        }
        // return antdMessage.error(EN.Youneedtoselectatleast);
      } else {
        if (!trainData.algorithms.length) {
          return Modal.error({
            title: EN.ModelingFailed,
            content: EN.Youneedtoselectatleast,
          });
        }
        // return antdMessage.error(EN.Youneedtoselectatleast);
      }
    }

    this.modeling(
      trainData,
      Object.assign(
        {
          train2Finished: false,
          train2ing: true,
          train2Error: false,
          selectId: '',
          settings: this.settings,
          settingId: this.settingId,
          trainHasError: false,
          faultReason: '',
          numIter: 50,
          surrogate: 'GaussianProcess',
        },
        this.nextSubStep(2, 3),
      ),
    );
  };

  advancedModeling = () => {
    if (this.train2ing) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: EN.YourProjectIsCurrentlyInTraining,
      });
    }
    const {
      id,
      problemType,
      target,
      dataHeader,
      weightsTemp,
      newVariable,
      trainHeader,
      colType,
      features,
      standardTypeTemp,
      mapHeader,
      bigData,
    } = this;
    let command = '';
    let trainData: TrainCommand = {};

    const featureLabel = [...dataHeader, ...newVariable]
      .filter(d => d !== target && !trainHeader.includes(d))
      .filter(h => colType[h] !== 'Raw');
    const realLabel = featureLabel.reduce((prev, k) => {
      prev[k] = newVariable.includes(k) ? k : mapHeader[k];
      return prev;
    }, {});
    if (!bigData && !featureLabel.length) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: '缺少特征变量.',
      });
    }
    // return antdMessage.error("no feature label")
    const setting = this.settings.find(s => s.id === this.settingId);
    if (!setting || !setting.name) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: EN.ModelingSettingsException,
      });
    }
    // return antdMessage.error("setting error")

    const sparkAlgorithms = !(window as any).r2_env.useSpark
      ? []
      : this.sparkAlgorithms;

    switch (problemType) {
      case 'Clustering':
        command = 'clustering.train';
        const disableItems = [
          ...(this.totalLines > 20000 ? ['SpectralClustering'] : []),
          ...(this.totalLines > 50000 ? ['Agg', 'DBSCAN'] : []),
          ...(this.kType === 'no_more_than' ? ['DBSCAN', 'MeanShift'] : []),
        ];

        trainData = {
          kType: this.kType,
          kValue: this.kValue,
          algorithms: this.algorithms.filter(al => !disableItems.includes(al)),
          sparkAlgorithms,
          standardType: standardTypeTemp,
          speedVSaccuracy: this.speedVSaccuracy,
          // searchTime: this.searchTime,
          metricsMethod: this.measurement,
          featureLabel: featureLabel,
          randomSeed: this.randSeed,
          projectId: id,
          command,
          settingName: setting.name,
          settingId: setting.id,
          applyWeights: weightsTemp,
          problemType,
          targetLabel: target ? [target] : [],
          esIndex: this.etlIndex,
          mapHeader: realLabel,
          numIter: this.numIter,
          surrogate: this.surrogate,
          modelingWay: 'advanced',
        };
        break;
      case 'Outlier':
        command = 'outlier.train';
        trainData = {
          algorithms: this.algorithms,
          sparkAlgorithms,
          standardType: standardTypeTemp,
          speedVSaccuracy: this.speedVSaccuracy,
          // searchTime: this.searchTime,
          featureLabel: featureLabel,
          randomSeed: this.randSeed,
          projectId: id,
          command,
          settingName: setting.name,
          settingId: setting.id,
          applyWeights: weightsTemp,
          problemType,
          targetLabel: target ? [target] : [],
          esIndex: this.etlIndex,
          mapHeader: realLabel,
          numIter: this.numIter,
          surrogate: this.surrogate,
          modelingWay: 'advanced',
        };
        break;
      default:
        command =
          problemType === 'MultiClassification'
            ? 'multi.train'
            : 'clfreg.train';
        let featureList: string[] = [];
        if (
          problemType === 'Classification' ||
          problemType === 'MultiClassification'
        ) {
          if (
            this.algorithms.some(al =>
              [
                'adaboost',
                'decision_tree',
                'extra_trees',
                'gradient_boosting',
                'k_nearest_neighbors',
                'liblinear_svc',
                'random_forest',
                'gaussian_nb',
                'xgradient_boosting', // xgradient_boosting test
              ].includes(al),
            )
          )
            featureList = featureList.concat([
              'Extra Trees',
              'Random Trees',
              'fast ICA',
              'PCA',
              'Polynomial',
              'feature agglomeration',
              'linear SVM',
              'Select Percentile',
              'Select Rates',
            ]);
          if (this.algorithms.includes('multinomial_nb'))
            featureList = featureList.concat([
              'Extra Trees',
              'Random Trees',
              'Polynomial',
              'Feature Agglomeration',
              'linear SVM',
              'Nystroem Sampler',
              'Select Percentile',
              'Select Rates',
            ]);
          if (
            this.algorithms.some(al =>
              [
                'bernoulli_nb',
                'lda',
                'libsvm_svc',
                'passive_aggressive',
                'qda',
                'sgd',
              ].includes(al),
            )
          )
            featureList = featureList.concat([
              'Fast ICA',
              'Kernel PCA',
              'Kitchen Sinks',
              'Linear SVM',
            ]);
        } else {
          if (
            this.algorithms.some(al =>
              [
                'adaboost',
                'decision_tree',
                'extra_trees',
                'gradient_boosting',
                'k_nearest_neighbors',
                'random_forest',
                'gaussian_process',
                'xgradient_boosting', // xgradient_boosting test
              ].includes(al),
            )
          )
            featureList = featureList.concat([
              'Extra Trees',
              'Random Trees',
              'fast ICA',
              'PCA',
              'Polynomial',
              'Feature Agglomeration',
              'linear SVM',
              'Nystroem Sampler',
              'Select Percentile',
              'Select Rates',
            ]);
          if (
            this.algorithms.some(al =>
              [
                'ard_regression',
                'liblinear_svr',
                'libsvm_svr',
                'ridge_regression',
                'sgd',
              ].includes(al),
            )
          )
            featureList = featureList.concat([
              'Fast ICA',
              'Kernel PCA',
              'Kitchen Sinks',
              'Linear SVM',
            ]);
        }
        const featuresPreprocessor = features
          .filter(fe => featureList.includes(fe))
          .map(fe => {
            const curFeature = formatFeature(
              problemType === 'Classification'
                ? 'Classification'
                : 'Regression',
            );
            return Reflect.get(curFeature, fe);
          });
        const classes =
          this.targetArray.length > 1
            ? this.targetArray
            : Object.entries(this.colValueCounts[this.target])
                .sort((a, b) => b[1] - a[1])
                .map(v => v[0])
                .slice(0, this.targetUnique);
        trainData = {
          problemType,
          featureLabel,
          targetLabel: [target],
          projectId: id,
          version: this.version.join(','),
          command,
          sampling: this.resampling,
          speedVSaccuracy: this.speedVSaccuracy,
          ensembleSize: this.ensembleSize,
          randSeed: this.randSeed,
          measurement: this.measurement,
          settingName: setting.name,
          settingId: setting.id,
          holdoutRate: this.holdoutRate / 100,
          algorithms: this.algorithms,
          sparkAlgorithms,
          featuresPreprocessor,
          esIndex: this.etlIndex,
          mapHeader: realLabel,
          classificationType: this.targetUnique,
          classNames: classes,
          numIter: this.numIter,
          surrogate: this.surrogate,
          modelingWay: 'advanced',
        };
        if (this.runWith === 'holdout') {
          trainData.validationRate = this.validationRate / 100;
        } else {
          trainData.nfold = this.crossCount;
        }
    }

    // id: request ID
    // projectId: project ID
    // csv_location: csv 文件相对路径
    // problem_type: 预测类型 Classification , Regression
    // feature_label: 特征列名
    // target_label:  目标列
    // fill_method:  无效值
    // model_option: model的额外参数，不同model参数不同
    // kwargs:
    // const trainData = {
    //   problemType,
    //   featureLabel,
    //   targetLabel: target,
    //   projectId: id,
    //   version: '1,2',
    //   command,
    //   sampling: 'no',
    //   speedVSaccuracy: 5,
    //   ensembleSize: 20,
    //   randSeed: 0,
    //   measurement: problemType === "Classification" ? "auc" : "r2",
    //   settingName: setting.name,
    //   holdoutRate: 0.2
    // };
    this.modeling(
      trainData,
      Object.assign(
        {
          train2Finished: false,
          train2ing: true,
          train2Error: false,
          selectId: '',
          settings: this.settings,
          settingId: this.settingId,
          trainHasError: false,
          kType: this.kType,
          kValue: this.kValue,
          algorithms: this.algorithms,
          standardType: standardTypeTemp,
          trainHeader: [...this.trainHeader],
          customHeader: [...this.customHeader],
          // searchTime: this.searchTime,
          measurement: this.measurement,
          randSeed: this.randSeed,
          weights: weightsTemp,
          features,
          version: this.version,
          resampling: this.resampling,
          speedVSaccuracy: this.speedVSaccuracy,
          ensembleSize: this.ensembleSize,
          holdoutRate: this.holdoutRate,
          validationRate: this.validationRate,
          crossCount: this.crossCount,
          sparkAlgorithms,
          faultReason: '',
          numIter: this.numIter,
          surrogate: this.surrogate,
        },
        this.nextSubStep(2, 3),
      ),
    );
  };

  forcastingSsp = (preSelect, windowSize) => {
    const data = {
      command: 'time.stationaryPlot',
      projectId: this.id,
      windowSize,
      featureLabel: [],
      targetLabel: [this.target],
    };

    socketStore.ready().then(api => api.esayplot(data));
  };

  outlierHistogram = (feature_label, outlier_range, bins) => {
    const data = {
      command: `${this.bigData ? 'spark.' : ''}etl.outlierHistogram`,
      projectId: this.id,
      feature_label,
      outlier_range,
      bins,
    };

    socketStore.ready().then(api => api.esayplot(data));
  };

  getDensityPlot = () => {
    const data = {
      command: 'time.densityPlot',
      projectId: this.id,
      featureLabel: [],
      targetLabel: [this.target],
    };

    socketStore.ready().then(api => api.getDensityPlot(data));
  };

  getActPlot = (nLags = 20) => {
    const data = {
      command: 'time.acfPlot',
      projectId: this.id,
      nLags,
      featureLabel: [],
      targetLabel: [this.target],
    };
    socketStore.ready().then(api => api.getActPlot(data));
  };

  getSeasonalPlot = (freq = null) => {
    const data = {
      command: 'time.seasonalPlot',
      projectId: this.id,
      freq,
      featureLabel: [],
      targetLabel: [this.target],
    };
    socketStore.ready().then(api => api.esayplot(data));
  };

  associationModeling = () => {
    if (this.train2ing) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: EN.YourProjectIsCurrentlyInTraining,
      });
    }
    // return antdMessage.error("Your project is already training, please stop it first.")
    if (this.etling) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: EN.ModelingSettingsException,
      });
    }
    // return antdMessage.error('modeling error')
    this.train2ing = true;
    this.isAbort = false;
    const { type } = this.associationOption;
    const option = this.associationOption[type];
    const realLabel = this.rawHeader.reduce((prev, k) => {
      prev[k] = this.mapHeader[k];
      return prev;
    }, {});
    const trainData: AssociationTrainCommand = {
      algo: type,
      minSupport: option.support,
      minConfidence: option.confidence,
      minLift: option.lift,
      maxLength: option.length,
      command: 'correlation.train',
      csvLocation: [this.originalIndex],
      featureLabel: [this.target, ...this.dataHeader],
      targetLabel: [this.target],
      projectId: this.id,
      problemType: this.problemType,
      mapHeader: realLabel,
    };

    const updateData = Object.assign(
      {
        train2Finished: false,
        train2ing: true,
        train2Error: false,
        selectId: '',
        settings: this.settings,
        settingId: this.settingId,
        associationOption: this.associationOption,
        trainHasError: false,
        faultReason: '',
      },
      this.nextSubStep(2, 3),
    );
    this.models = [];
    // socketStore.ready().then(api => api.train({...trainData, data: updateData,command: "clfreg.train"}, progressResult => {
    socketStore
      .ready()
      .then(api => api.train({ ...trainData, data: updateData }))
      .then(returnValue => {
        const { status, actionKey, error, errorCode } = returnValue;
        if (status !== 100) {
          let message = returnValue.message;
          if (status === -3) {
            message = EN.CommandLimitExceed;
            window.alert(message);
          } else if (status === -4) {
            message = EN.TrainingLimitExceed;
            window.alert(message);
          } else {
            Modal.error({
              title: errorCode ? `[${errorCode}]` : actionKey || EN.ModelingFailed,
              content: error || message,
              okText: EN.ok,
              centered: true,
            });
            // antdMessage.error(message)
          }
        }
      });
  };

  // timeSeriesEtl = () => {
  //   return socketStore.ready().then(api => api.timeSeriesEtl({ projectId: this.id }))
  // };

  timeSeriesTrain = data => {
    const {
      measurement,
      forecastSize,
      FBProphet,
      arima,
      sarima,
      validationRate,
      ModelingSetting: { trainType, algorithm },
      ModelingSetting,
    } = data;
    const auto = trainType === 'auto';
    const useArima = algorithm !== 'prophet';
    const useSarima = algorithm === 'sarima';
    if (this.train2ing) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: EN.YourProjectIsCurrentlyInTraining,
      });
    }
    // return antdMessage.error("Your project is already training, please stop it first.");
    if (this.etling) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: EN.ModelingSettingsException,
      });
    }
    // return antdMessage.error('modeling error')

    this.train2ing = true;
    this.isAbort = false;

    const splitRate = (100 - validationRate) / 100;

    const updateData = Object.assign(
      {
        train2Finished: false,
        train2ing: true,
        train2Error: false,
        selectId: '',
        trainHasError: false,
        FBProphet,
        ModelingSetting,
        measurement,
        arima,
        sarima,
        forecastSize,
        validationRate,
        faultReason: '',
        //添加option
      },
      this.nextSubStep(2, 3),
    );

    const modelOption = Object.assign(
      {
        forecastSize: +forecastSize,
        splitRate,
        metrics: measurement,
      },
      auto ? {} : useArima ? (useSarima ? sarima : arima) : FBProphet,
    );

    const realLabel = this.rawHeader.reduce((prev, k) => {
      prev[k] = this.mapHeader[k];
      return prev;
    }, {});

    const trainData: TimeSeriesTrainCommand = {
      trainType,
      algorithm,
      command: 'time.train',
      featureLabel: [],
      targetLabel: [this.target],
      projectId: this.id,
      problemType: this.problemType,
      orderIndex: this.orderIndex,
      mapHeader: realLabel,
      modelOption,
    };

    return socketStore
      .ready()
      .then(api => api.timeSeriesTrain({ ...trainData, updateData }))
      .then(returnValue => {
        const { status, actionKey, error, errorCode } = returnValue;
        if (status !== 100) {
          this.train2ing = false;
          let message = returnValue.message;
          if (status === -3) {
            message = EN.CommandLimitExceed;
            window.alert(message);
          } else if (status === -4) {
            message = EN.TrainingLimitExceed;
            window.alert(message);
          } else {
            // antdMessage.error(message)
            Modal.error({
              title: errorCode ? `[${errorCode}]` : actionKey || EN.ModelingFailed,
              content: error || message,
            });
          }
        }
      });
  };

  newSetting = () => {
    const {
      problemType,
      dataHeader,
      newVariable,
      targetCounts,
      trainHeader,
      defaultAlgorithms,
      informativesLabel,
    } = this;
    if (this.bigData) return { sparkAlgorithms: this.defaultSparkAlgorithms };
    const featureLabel = [...dataHeader, ...newVariable].filter(
      h => !trainHeader.includes(h),
    );
    const min =
      problemType === 'Classification' || problemType === 'MultiClassification'
        ? Math.min(...Object.values(targetCounts))
        : Infinity;

    switch (problemType) {
      case 'Clustering':
        return {
          kType: 'auto',
          kValue: 5,
          measurement: this.bigData ? 'silhouette_euclidean' : 'CVNN',
          algorithms: defaultAlgorithms,
          standardType: 'standard',
          speedVSaccuracy: 5,
          // searchTime: 5,
          featureLabel,
          informativesLabel,
          randSeed: 0,
          weights: {},
        };
      case 'Outlier':
        return {
          algorithms: defaultAlgorithms,
          standardType: 'standard',
          speedVSaccuracy: 5,
          // searchTime: 5,
          featureLabel,
          informativesLabel,
          randSeed: 0,
          weights: {},
        };
      default:
        return {
          version: this.defaultVersion,
          validationRate: 20,
          holdoutRate: 20,
          randSeed: 0,
          measurement:
            problemType === 'Classification'
              ? 'auc'
              : problemType === 'MultiClassification'
              ? 'macro_auc'
              : 'r2',
          runWith: this.totalLines < 10000 ? 'cross' : 'holdout',
          resampling: 'no',
          crossCount: Math.min(min - 1, 5),
          dataRange: 'all',
          customField: '',
          customRange: [],
          features: [
            'Extra Trees',
            'Random Trees',
            'Fast ICA',
            'Kernel PCA',
            'PCA',
            'Polynomial',
            'Feature Agglomeration',
            'Kitchen Sinks',
            'Linear SVM',
            'Nystroem Sampler',
            'Select Percentile',
            'Select Rates',
          ],
          algorithms: defaultAlgorithms,
          speedVSaccuracy: 5,
          ensembleSize: 20,
          featureLabel,
          informativesLabel,
        };
    }

    // const name = `${ type }.${ moment().format('MM.DD.YYYY_HH:mm:ss') } `
    // const id = uuidV4()
    // this.settingId = id
    // this.settings.push({
    //   id,
    //   name: name,
    //   setting,
    //   models: []
    // })
  };

  newSingleSetting = () => {
    const {
      problemType,
      dataHeader,
      newVariable,
      targetCounts,
      trainHeader,
      defaultAlgorithms,
      informativesLabel,
    } = this;
    if (this.bigData) return { sparkAlgorithms: this.defaultSparkAlgorithms };
    const featureLabel = [...dataHeader, ...newVariable].filter(
      h => !trainHeader.includes(h),
    );
    const min =
      problemType === 'Classification' || problemType === 'MultiClassification'
        ? Math.min(...Object.values(targetCounts))
        : Infinity;
    switch (problemType) {
      case 'Clustering':
        return {
          kType: 'auto',
          kValue: 5,
          measurement: this.bigData ? 'silhouette_euclidean' : 'CVNN',
          algorithms: [],
          standardType: 'standard',
          speedVSaccuracy: 5,
          // searchTime: 5,
          featureLabel,
          informativesLabel,
          randSeed: 0,
          weights: {},
        };
      case 'Outlier':
        return {
          algorithms: [],
          standardType: 'standard',
          speedVSaccuracy: 5,
          // searchTime: 5,
          featureLabel,
          informativesLabel,
          randSeed: 0,
          weights: {},
        };
      default:
        return {
          version: ['b'],
          validationRate: 20,
          holdoutRate: 20,
          randSeed: 0,
          measurement:
            problemType === 'Classification'
              ? 'auc'
              : problemType === 'MultiClassification'
              ? 'macro_auc'
              : 'r2',
          runWith: this.totalLines < 10000 ? 'cross' : 'holdout',
          resampling: 'no',
          crossCount: Math.min(min - 1, 5),
          dataRange: 'all',
          customField: '',
          customRange: [],
          features: [
            'Extra Trees',
            'Random Trees',
            'Fast ICA',
            'Kernel PCA',
            'PCA',
            'Polynomial',
            'Feature Agglomeration',
            'Kitchen Sinks',
            'Linear SVM',
            'Nystroem Sampler',
            'Select Percentile',
            'Select Rates',
          ],
          algorithms: [],
          speedVSaccuracy: 5,
          ensembleSize: 20,
          featureLabel,
          informativesLabel,
        };
    }
  };
  removeCurSetting = () => {
    this.settings = this.settings.filter(st => st.id !== this.settingId);
    const curSetting = this.settings[this.settings.length - 1];
    this.settingId = curSetting ? curSetting.id : '';
  };
  trainErrorCallBack = result => {
    const { actionKey, error, errorCode } = result?.message || {};
    let message = error;
    console.log('trainError', result);
    // clfreg.d.train 算法后端修改状态为fail
    if (error == 'command clfreg.d.train not define in system.') return;
    if (+errorCode === 22001) {
      message = EN.TrainError22001;
    }
    // const modal = Modal.info(null);
    // const config = {
    //   centered: true,
    //   title: errorCode
    //     ? `[${errorCode}]`
    //     : actionKey
    //     ? actionKey + `${EN.TrainFail}! ${EN.ProjectId}:${result?.project}`
    //     : `${EN.TrainFail}! ${EN.ProjectId}:${result?.project}`,
    //   content: error,
    //   okText: EN.ok,
    // };
    // if (modal) {
    //   modal.update(config);
    // } else {
    //   Modal.error(config);
    // }
    notification.warn({
      message: errorCode ? `[${errorCode}]${EN.ProjectId}: ${result?.project}` : `${EN.ProjectId}: ${result?.project}`,
      description: message,
      duration: null,
    });
  };
  modeling = (trainData: TrainCommand, updateData: Object) => {
    if (this.etling) {
      return Modal.error({
        title: EN.ModelingFailed,
        content: EN.ModelingSettingsException,
      });
    }
    if ((window as any).r2_env.domain === Domain.CMB) {
      // TODO 规则权限整理
      Reflect.deleteProperty(trainData, 'numIter');
      Reflect.deleteProperty(trainData, 'surrogate');
      Reflect.deleteProperty(updateData, 'numIter');
      Reflect.deleteProperty(updateData, 'surrogate');
    }
    // return antdMessage.error('modeling error')
    this.train2ing = true;
    this.isAbort = false;

    socketStore.ready().then(api => {
      api.removeEventListener('trainError', this.trainErrorCallBack); // 清除缓存监听
      api.addEventListener(`trainError`, this.trainErrorCallBack); // 重制当前监听
    });

    socketStore
      .ready()
      .then(api => api.train({ ...trainData, data: updateData }))
      .then(returnValue => {
        const { status, errorCode } = returnValue;
        if (status !== 100) {
          this.train2ing = false;
          let message = returnValue.message;
          if (status === -3) {
            message = EN.CommandLimitExceed;
            notification.open({
              message: `${EN.TrainFail}! ${EN.ProjectId}:${trainData?.projectId}`,
              description: message,
              duration: null,
            });
            // window.alert(message);
          } else if (status === -4) {
            message = EN.TrainingLimitExceed;
            // window.alert(message);
            notification.open({
              message: `${EN.TrainFail}! ${EN.ProjectId}:${trainData?.projectId}`,
              description: message,
              duration: null,
            });
          } else {
            // 与异常监听重复
            // Modal.error({
            //   title: errorCode ? `[${errorCode}]` : EN.ModelingFailed,
            //   content: message,
            //   okText: EN.ok,
            //   centered: true,
            // });
          }
        }
      });
  };

  abortTrain = (stopId: string, isModeling: boolean = true) => {
    if (!stopId) return Promise.resolve();
    // if (this.isAbort) return Promise.resolve()
    const command = {
      command: 'top.stop',
      action: 'train',
      projectId: this.id,
      stopId,
      isModeling,
    };
    return socketStore.ready().then(api =>
      api.abortTrain(command).then((returnValue: BaseResponse) => {
        const { status, message, result, id } = returnValue;
        if (id !== this.id) return;
        if (status !== 200) {
          return Modal.error({
            title: EN.DataException,
            content: message,
          });
        }
        // return antdMessage.error(message)
        this.setProperty(result);
      }),
    );
  };

  abortTrainByEtl = async (isModeling: boolean = true) => {
    // this.models = []
    this.isAbort = true;
    // const arr = []
    // if (this.train2ing && !!this.stopIds.length) {
    for (let si of this.stopIds || []) {
      await this.abortTrain(si, isModeling);
    }
    // const arr = this.stopIds.map(si => this.abortTrain(si))
    // return Promise.all(arr)
    // }
    // this.train2ing = false;
    // this.train2Finished = true;
    // this.isAbort = false
    return this.updateProject({
      train2ing: false,
      train2Finished: true,
      isAbort: false,
    });
  };

  clearInvalidModels = (projectId: string) => {
    if (!!this.invalidModels.length)
      this.invalidModels = this.invalidModels.filter(
        model => model.projectId !== projectId,
      );
  };

  setModel = (data: Model, force = false, isCreate = false) => {
    const model = new Model(this.id, {
      ...data,
      measurement: this.measurement,
    });
    const { problemType } = model;
    let key;
    switch (problemType) {
      case 'Classification':
        key = 'auc';
        break;
      case 'Clustering':
        key = this.bigData ? 'silhouette_euclidean' : 'CVNN';
        break;
      default:
        key = 'r2';
        break;
    }
    const min = problemType === 'Classification' ? 0.5 : 0;
    const { validateScore } =
      problemType === 'Clustering'
        ? { validateScore: model.score || {} }
        : model.score || {}; // 聚类直接返回score
    if (
      (problemType === 'Classification' ||
        problemType === 'Regression' ||
        problemType === 'Clustering') &&
      validateScore
    ) {
      // 添加非空校验
      const isInvalidModel =
        validateScore[key] == null || validateScore[key] < min;
      if (isInvalidModel) {
        const invalidmodel: InvalidModel = {
          projectId: model.projectId,
          modelId: model.id,
          modelName: !!model.modelName ? model.modelName : '',
        };
        this.invalidModels = this.invalidModels.filter(
          x => x.projectId !== model.projectId && x.modelId !== model.id,
        );
        this.invalidModels.push(invalidmodel);
        socketStore.ready().then(api => {
          if (this.deployment && this.deployment.model_name === model.modelName)
            return;
          api.deleteInvalidModel({
            projectId: model.projectId,
            modelName: model.modelName,
          });
        });
        return;
      }
    }
    this.models = [...this.models.filter(m => data.id !== m.id), model];
    if (!force && data.chartData && this.criteria === 'cost') {
      const { TP, FP, FN, TN } = this.costOption;
      const [v0, v1] = Object.values(this.targetCounts);
      const percent0 = parseFloat(formatNumber((v1 / (v0 + v1)).toString(), 4));
      const percentNew = this.distribution ? this.distribution / 100 : percent0;
      const { index } = model.getBenefit(TP, FP, FN, TN, percentNew, percent0);
      if (index === model.fitIndex) return;
      return model.updateModel({ fitIndex: index });
    }
  };

  setModelField = (data: Partial<Model>, times: number = 0) => {
    const model = this.models.find(m => data.id === m.id);
    if (!model) {
      if (times > 10) return;
      setTimeout(() => this.setModelField(data, times + 1), 100);
      return;
    }
    model.setProperty(data);
    if (data.chartData && this.criteria === 'cost') {
      const { TP, FP, FN, TN } = this.costOption;
      const [v0, v1] = Object.values(this.targetCounts);
      const percent0 = parseFloat(formatNumber((v1 / (v0 + v1)).toString(), 4));
      const percentNew = this.distribution ? this.distribution / 100 : percent0;
      const { index } = model.getBenefit(TP, FP, FN, TN, percentNew, percent0);
      if (index === model.fitIndex) return;
      return model.updateModel({ fitIndex: index });
    }
  };

  setSelectModel = (id: string) => {
    return this.updateProject({ selectId: id });
  };

  setCheckedModel = (ids, checked) => {
    const checkItems = new Set(this.checkItems);
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    ids.forEach(id => {
      if (checked) {
        checkItems.add(id);
      } else {
        checkItems.delete(id);
      }
    });
    return this.updateProject({
      checkItems: [...checkItems],
    });
  };

  initModels = (force = false) => {
    if (this.loadModel) return;
    if (!force && !!this.models.length) return;
    this.loadModel = true;
    let show = true;
    let count = 0;
    const so = setTimeout(() => {
      if (!count) {
        show = false;
        // Modal.error({
        //  title: 'ERROR!',
        //  content: EN.timeoutRetry
        // });
        message.error(EN.timeoutRetry, 3);
        this.initModels();
      }
    }, 60000);
    this.models = [];
    const readyModels = [];
    when(
      () => this.init,
      () =>
        readyModels.forEach(m => {
          this.setModel(m, true);
        }),
    );
    socketStore
      .ready()
      .then(api =>
        api.queryModelList({ id: this.id }, (progressResult: BaseResponse) => {
          const { status, message, model } = progressResult;
          if (status !== 200) {
            return Modal.error({
              title: EN.DataException,
              content: message,
            });
          }
          // return antdMessage.error(message)
          if (!model && this.modelCount) {
            this.loadModel = false;
            return this.initModels(true);
          }
          count++;
          if (this.init) {
            return this.setModel(model, true);
          }
          readyModels.push(model);
        }),
      )
      .then(result => {
        if (!show) return;
        clearTimeout(so);
        const { status, message } = result;
        if (status !== 200) return alert(message);
        this.loadModel = false;
        if (this.train2ing) {
          setTimeout(() => {
            this.initModels(true);
          }, 5 * 60 * 1000);
        }
      });
  };

  correlationMatrix = () => {
    // if (!this.dataViews) this.goback();
    if (this.bigData) return Promise.resolve();
    if (this.problemType === 'Association') return Promise.resolve();
    if (this.correlationMatrixData) return Promise.resolve();
    // if (this.correlationMatrixLoading) return Promise.resolve();
    // if(this.dataViews) this.goback();
    return socketStore.ready().then(api => {
      const featureLabel = this.dataHeader.filter(
        h =>
          this.colType[h] !== 'Raw' &&
          h !== this.target &&
          !(
            this.colType[h] === 'Categorical' &&
            this.dataViews[h].uniqueValue === 1
          ),
      );
      if (this.target) featureLabel.push(this.target);
      const command = {
        // command: 'top.correlationMatrix',
        command: this._problemType('correlationMatrix'),
        // command: `${ this.problemType === 'MultiClassification' ? 'multi' : 'clfreg' }.correlationMatrix`,
        featureLabel,
        projectId: this.id,
        target_label: this.target ? [this.target] : [],
      };
      this.correlationMatrixLoading = true;
      return api
        .correlationMatrix(command)
        .then((returnValue: BaseResponse) => {
          const { status, message, errorCode } = returnValue;
          if (status < 0) {
            return Modal.error({
              title: errorCode ? `[${errorCode}]` : EN.DataException,
              content: message,
              okText: EN.ok,
              centered: true,
            });
          }
          // this.setProperty({
          //   preImportance: result.preImportance,
          //   informativesLabel: result.informativesLabel,
          //   preImportanceLoading: false
          // })
        });
    });
  };

  preTrainImportance = (hard = false) => {
    // if (this.bigData) return Promise.resolve()
    if (this.preImportanceLoading) return Promise.resolve();
    return socketStore.ready().then(api => {
      const readyLabels = this.preImportance
        ? Object.keys(this.preImportance)
        : [];
      const all_label = [...this.dataHeader, ...this.newVariable];

      const unPreImportanceFeatureLabel = all_label.filter(
        v =>
          this.colType[v] !== 'Raw' &&
          !readyLabels.includes(v) &&
          v !== this.target,
      );
      if (!hard && !unPreImportanceFeatureLabel.length)
        return Promise.resolve();

      let cmd = `${this.bigData ? 'spark.' : ''}${
        this.problemType === 'MultiClassification' ? 'multi' : 'clfreg'
      }.preTrainImportance`;

      const command = {
        projectId: this.id,
        command: cmd,
        feature_label: all_label
          .filter(d => d !== this.target)
          .filter(h => this.colType[h] !== 'Raw'),
        target_label: this.target ? [this.target] : [],
      };
      this.preImportanceLoading = true;
      return api
        .preTrainImportance(command)
        .then((returnValue: BaseResponse) => {
          const { status, message, errorCode } = returnValue;
          if (status < 0) {
            return Modal.error({
              title: errorCode ? `[${errorCode}]` : EN.DataException,
              content: message,
              okText: EN.ok,
              centered: true,
            });
          }
        });
    });
  };

  clusterPreTrainImportance = (force: boolean) => {
    if (this.preImportanceLoading) return Promise.resolve();
    if (this.problemType !== 'Clustering') return Promise.resolve();
    const allLabel = [...this.dataHeader, ...this.newVariable].filter(
      v => v !== this.target,
    );

    // 不是点击刷新
    if (!force) {
      const before = allLabel.reduce((prev, la) => {
        prev[la] = this.weights[la] || 1;
        return prev;
      }, {} as NumberObject);
      const after = allLabel.reduce((prev, la) => {
        prev[la] = this.weightsTemp[la] || 1;
        return prev;
      }, {} as NumberObject);

      const isChange =
        !Object.keys(this.preImportance).length ||
        this.hasChanged(before, after) ||
        this.standardTypeTemp !== this.standardType;
      if (!isChange) return Promise.resolve();
    }

    return socketStore.ready().then(api => {
      let cmd = `${this.bigData ? 'spark.' : ''}clustering.preTrainImportance`;
      const feature_label = allLabel
        .filter(d => d !== this.target)
        .filter(h => this.colType[h] !== 'Raw');
      const command = {
        projectId: this.id,
        command: cmd,
        feature_label: feature_label,
        standardType: this.standardTypeTemp,
        target_label: this.target ? [this.target] : [],
        weights: feature_label.reduce((prev, la) => {
          prev.push(this.weightsTemp[la] || 1);
          // prev[la] =
          return prev;
        }, [] as number[]),
      };
      // if (new_label.length) {
      //   const variables = [...new Set(new_label.map(label => label.split("_")[1]))]
      //   command.csvScript = variables.map(v => this.expression[v]).filter(n => !!n).join(";").replace(/\|/g, ",")
      // }
      this.preImportanceLoading = true;
      return api
        .preTrainImportance(command)
        .then((returnValue: BaseResponse) => {
          const { status, message, errorCode } = returnValue;
          if (status < 0) {
            return Modal.error({
              title: errorCode ? `[${errorCode}]` : EN.DataException,
              content: message,
              okText: EN.ok,
              centered: true,
            });
          }
        });
    });
  };

  univariatePlot = () => {
    if (this.bigData) return Promise.resolve();
    if (['Clustering', 'Outlier'].includes(this.problemType)) {
      return Promise.resolve();
    }

    // const readyLabels = Object.keys(this.univariatePlots)
    // const readyLabels = Object.entries(this.univariatePlots).filter(itm => itm[1]).map(itm => itm[0]);
    const readyLabels = Object.keys(this.univariatePlots);
    const data_label = this.dataHeader.filter(
      v => !readyLabels.includes(v) && v !== this.target,
    );
    // const data_label = this.dataHeader.filter(v => !readyLabels.includes(v));
    const new_label = this.newVariable.filter(v => !readyLabels.includes(v));
    const feature_label = [...data_label, ...new_label];
    if (!feature_label.length || feature_label.length === 0)
      return Promise.resolve();

    // Reflect.deleteProperty(this.univariatePlots, field)
    // this.univariatePlots[field] = ''
    return socketStore.ready().then(api => {
      const command = {
        projectId: this.id,
        command: this._problemType('univariatePlot'),
        feature_label,
        target_label: this.target ? [this.target] : [],
      };
      // if (field) {
      //   if (this.newVariable.includes(field)) {
      //     command.feature_label = [...this.newVariable]
      //   } else {
      //     command.feature_label = [field]
      //   }
      // }
      return api
        .univariatePlot(command, (progressResult: BaseResponse) => {
          const { result, status, errorCode } = progressResult;
          if (status < 0 || status === 100) {
            if (status < 0) {
              return Modal.error({
                title: errorCode ? `[${errorCode}]` : EN.DataException,
                content: message,
                okText: EN.ok,
                centered: true,
              });
            }
            return;
          }
          const { field: plotKey, Data, progress } = result;
          if (progress && progress === 'start') return;
          const univariatePlots = Object.assign({}, this.univariatePlots);
          univariatePlots[plotKey] = Data;
          this.setProperty({ univariatePlots });
        })
        .then(this.handleError);
    });
  };

  _problemType = last => {
    let cmd: string;
    switch (this.problemType) {
      case 'Clustering':
        cmd = `${this.bigData ? 'spark.' : ''}clustering.${last}`;
        break;
      case 'Outlier':
        cmd = `outlier.${last}`;
        break;
      case 'MultiClassification':
        cmd = `multi.${last}`;
        break;
      case 'Forecasting':
      case 'Survival':
      case 'Prediction':
        cmd = `time.${last}`;
        break;
      default:
        cmd = `clfreg.${last}`;
    }
    return cmd;
  };

  histgramPlot = () => {
    if (this.bigData) return Promise.resolve();
    // const readyLabels = Object.entries(this.histgramPlots).filter(itm => itm[1]).map(itm => itm[0]);
    const readyLabels = Object.keys(this.histgramPlots);
    const data_label = this.dataHeader.filter(v => !readyLabels.includes(v));
    const new_label = this.newVariable.filter(v => !readyLabels.includes(v));
    const feature_label = [...data_label, ...new_label];
    if (!feature_label.length || feature_label.length === 0)
      return Promise.resolve();
    return socketStore.ready().then(api => {
      const command: {
        projectId: string;
        command: string;
        feature_label: string[];
        newFeatureLabel?: NumberObject;
        target_label: string[];
      } = {
        projectId: this.id,
        command: this._problemType('histgramPlot'),
        feature_label,
        target_label: this.target ? [this.target] : [],
      };
      if (feature_label.includes(this.target)) {
        const newFeatureLabel = {};
        Object.keys(this.targetColMap)
          .slice(0, 2)
          .forEach((k, index) => {
            const rename = this.renameVariable[k];
            if (!!rename) Reflect.set(newFeatureLabel, rename, index);
          });
        if (!!Object.keys(newFeatureLabel).length)
          command.newFeatureLabel = newFeatureLabel;
      }
      return api
        .histgramPlot(command, (progressResult: BaseResponse) => {
          const { result, status, errorCode } = progressResult;
          if (status < 0 || status === 100 || !result) {
            if (status < 0) {
              return Modal.error({
                title: errorCode ? `[${errorCode}]` : EN.DataException,
                content: message,
                okText: EN.ok,
                centered: true,
              });
            }
            return;
          }
          const { field: plotKey, Data, progress } = result;
          if (progress && progress === 'start') return;
          const histgramPlots = Object.assign({}, this.histgramPlots);
          histgramPlots[plotKey] = Data;
          this.setProperty({ histgramPlots });
        })
        .then(this.handleError);
    });
  };

  getSsPlot = () => {
    return socketStore.ready().then(api => {
      return api
        .ssPlot(
          {
            command: this._problemType('ssPlot'),
            // command: 'clustering.ssPlot',
            projectId: this.id,
          },
          (prosss: BaseResponse) => {},
        )
        .then((returnValue: BaseResponse) => {
          const { status, message, errorCode } = returnValue;
          if (status < 0) {
            Modal.error({
              title: errorCode ? `[${errorCode}]` : EN.DataException,
              content: message,
              okText: EN.ok,
              centered: true,
            });
          }
        });
    });
  };

  handleError = (returnValue: BaseResponse) => {
    const { message, status, command } = returnValue;
    if (status < 0) {
      Modal.error({
        title: EN.DataException,
        content: `命令: ${command}, 异常: ${message} `,
      });
    }
  };

  getSample = () => {
    return socketStore
      .ready()
      .then(api => api.getSample({ problemType: this.problemType }))
      .then(res => {
        return res.list || [];
      })
      .catch(e => {
        Modal.error({
          title: EN.SystemDataAnomaly,
          content: EN.FailedToRetrieveCaseData,
        });
        // console.error(e.message, "sample error")
        // antdMessage.error("fetch sample error")
        return [];
      });
  };

  getBase64Image = (img: HTMLImageElement) => {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var dataURL = canvas.toDataURL('image/png');
    return dataURL; // return dataURL.replace("data:image/png;base64,", "");
  };

  generateReportHtml = async (jsonData: string) => {
    const script = 'script';
    const body = 'body';
    // const link = 'link'
    const style = 'style';
    let cssHtml = '';
    let scriptHtml = '';

    const htmlResp = await axios.get('/index.html');
    let html = htmlResp.data;

    const links: HTMLCollectionOf<HTMLLinkElement> = document.getElementsByTagName(
      'link',
    );
    for (let i = 0; i < links.length; i++) {
      const href = links[i].getAttribute('href');
      // let cssHtml = ''
      if (href.endsWith('.css')) {
        const cssRes = await axios.get(href);
        cssHtml += `<${style}>${cssRes.data}</${style}>`;
      }
      html = html.replace(links[i].outerHTML, '');
    }

    const scripts: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName(
      'script',
    );

    for (let i = 0; i < scripts.length; i++) {
      const src = scripts[i].getAttribute('src');
      if (!src) continue;
      const scriptsRes = await axios.get(src);
      scriptHtml += `<${script}>` + scriptsRes.data + `</${script}>`;
      html = html.replace(scripts[i].outerHTML, '');
    }

    html = html.replace(`</${body}>`, '');
    html =
      html +
      `${cssHtml}
    <${style}>body{overflow:auto}</${style}>
    <${script}>if(!Array.prototype.values) Array.prototype.values = Array.prototype[Symbol.iterator];</${script}>
    <${script}>window.r2Report=${jsonData};window.r2_env=${JSON.stringify(
        (window as any).r2_env,
      )};</${script}>
    ${scriptHtml}
    </${body}>`;
    return html;
    // html = html.replace(`< ${link} rel = "manifest" href = "/manifest.json" > `, '')
    // html = html.replace(`< ${link} rel = "shortcut icon" href = "/favicon.ico" > `, '')
    // const cssVersionStartStr = `< ${link} href = "/static/css/main.`
    // const cssVersionStart = html.indexOf(cssVersionStartStr) + cssVersionStartStr.length
    // const cssVersionEnd = html.indexOf('.', cssVersionStart)
    // const cssVersion = html.slice(cssVersionStart, cssVersionEnd)

    // const cssUrl = `/static/css/main.${cssVersion}.chunk.css`
    // const cssLink = `<${link} href="/static/css/main.${cssVersion}.chunk.css" rel="stylesheet">`
    // const cssResp = await axios.get(cssUrl)
    // const cssData = cssResp.data
    // const cssTag = `<${style}>${cssData}</${style}>`
    // html = html.replace(cssLink, '')

    // const cssChunkVersionStartStr = `<${link} href="/static/css/2.`
    // const cssChunkVersionStart = html.indexOf(cssChunkVersionStartStr) + cssChunkVersionStartStr.length
    // const cssChunkVersionEnd = html.indexOf('.', cssChunkVersionStart)
    // const cssChunkVersion = html.slice(cssChunkVersionStart, cssChunkVersionEnd)

    // const cssChunkUrl = `/static/css/2.${cssChunkVersion}.chunk.css`
    // const cssChunkLink = `<${link} href="/static/css/2.${cssChunkVersion}.chunk.css" rel="stylesheet">`
    // const cssChunkResp = await axios.get(cssChunkUrl)
    // const cssChunkData = cssChunkResp.data
    // const cssChunkTag = `<${style}>${cssChunkData}</${style}>`
    // html = html.replace(cssChunkLink, '')

    // const jsVersionStartStr = `<${script} src="/static/js/main.`
    // const jsVersionStart = html.indexOf(jsVersionStartStr) + jsVersionStartStr.length
    // const jsVersionEnd = html.indexOf('.', jsVersionStart)
    // const jsVersion = html.slice(jsVersionStart, jsVersionEnd)

    // const jsUrl = `/static/js/main.${jsVersion}.chunk.js`
    // const jsLink = `<${script} src="/static/js/main.${jsVersion}.chunk.js"></${script}>`
    // const jsResp = await axios.get(jsUrl)
    // const jsData = jsResp.data
    // const jsTag = `<${script}>` + jsData + `</${script}>`
    // html = html.replace(jsLink, '')

    // const jsChunkVersionStartStr = `<${script} src="/static/js/2.`
    // const jsChunkVersionStart = html.indexOf(jsChunkVersionStartStr) + jsChunkVersionStartStr.length
    // const jsChunkVersionEnd = html.indexOf('.', jsChunkVersionStart)
    // const jsChunkVersion = html.slice(jsChunkVersionStart, jsChunkVersionEnd)

    // const jsChunkUrl = `/static/js/2.${jsChunkVersion}.chunk.js`
    // const jsChunkLink = `<${script} src="/static/js/2.${jsChunkVersion}.chunk.js"></${script}>`
    // const jsChunkResp = await axios.get(jsChunkUrl)
    // const jsChunkData = jsChunkResp.data
    // const jsChunkTag = `<${script}>` + jsChunkData + `</${script}>`
    // html = html.replace(jsChunkLink, '')

    // html = html.replace(`</${body}>`, '')
    // cannot use replace with js code ($$typeof wrong)
    // html = html + `${cssChunkTag}${cssTag}<${script}>window.r2Report=${jsonData};window.r2_env=${JSON.stringify((window as any).r2_env)};</${script}>${jsChunkTag}${jsTag}</${body}>`
    // return html
  };

  async histogram(field: string) {
    const {
      colType,
      dataViews,
      etlIndex,
      histgramPlot,
      newType,
      histgramPlots,
    } = this;
    // const value: Stats = Reflect.get(dataViews, field);
    let url = histgramPlots[field];
    const type = colType[field] || newType[field];

    // if (!url) {
    //   await histgramPlot();
    //   url = this.histgramPlots[field];
    // }
    if (type === 'Numerical') {
      return {
        name: 'histogram-numerical',
        url,
      };
    } else {
      return {
        name: 'histogram-categorical',
        url,
      };
    }
  }

  async univariant(value: string) {
    const {
      problemType,
      colType,
      univariatePlot,
      univariatePlots,
      newType,
    } = this;

    const arr = ['Regression', 'MultiClassification', 'Classification'];

    if (!arr.includes(problemType))
      return {
        name: '',
        url: '',
      };

    const type = colType[value] || newType[value];

    let url = univariatePlots[value];

    // if (!url) {
    //   await univariatePlot();
    //   url = this.univariatePlots[value];
    // }

    if (problemType === 'Regression') {
      if (type === 'Numerical') {
        //散点图
        return {
          name: 'regression-numerical',
          url,
        };
      } else {
        //回归-分类 箱线图
        return {
          name: 'regression-categorical',
          url,
        };
      }
    } else {
      //Univariant

      if (type === 'Numerical') {
        if (problemType === 'MultiClassification') {
          //多分类
          return {
            name: 'multiClassification-numerical',
            url,
          };
        }
        return {
          name: 'classification-numerical',
          url,
        };
      } else {
        return {
          name: 'classification-categorical',
          url,
        };
      }
    }
  }

  //在这里获取所以直方图折线图数据
  allVariableList = async (model: Model) => {
    const {
      target,
      correlationMatrixData,
      dataHeader,
      newVariable,
      preImportance,
      trainHeader,
    } = this;

    const allVariables = [
      ...dataHeader.filter(h => h !== target),
      ...newVariable,
    ];
    const checkedVariables = allVariables.filter(v => !trainHeader.includes(v));
    [allVariables]
      .map(v => v.sort().toString())
      .indexOf(checkedVariables.sort().toString());
    allVariables.sort((a, b) => {
      return preImportance
        ? -1 * ((preImportance[a] || 0) - (preImportance[b] || 0))
        : 0;
    });

    const histogramPromiseList = (target
      ? [target, ...allVariables]
      : allVariables
    ).map(async itm => {
      const { name, url } = await this.histogram(itm);
      const result = url ? await this.readFile(url) : {};

      return {
        [itm]: {
          data: result.data,
          name,
        },
      };
    });

    const histogramPromise = Promise.all(histogramPromiseList).then(list => {
      return list.reduce((prev, item) => Object.assign(prev, item), {});
    });

    const univariantPromiseList = allVariables.map(async itm => {
      const { name, url } = await this.univariant(itm);
      const result = url ? await this.readFile(url) : {};

      return {
        [itm]: {
          data: result.data,
          name,
        },
      };
    });

    const univariantPromise = Promise.all(univariantPromiseList).then(list => {
      return list.reduce((prev, item) => Object.assign(prev, item), {});
    });

    const correlationPromise =
      correlationMatrixData &&
      new Promise(async resolve => {
        const result = await this.readFile(correlationMatrixData);
        resolve({
          plot: {
            data: result,
            name: 'correlation-matrix',
          },
        });
      });

    const {
      validatePlotData,
      holdoutPlotData,
      parallelPlotData,
      pcaPlotData,
      multiVarPlotData,
      outlierPlotData,
    } = model;

    const validatePlotDataPromise = validatePlotData
      ? new Promise(async resolve => {
          const result = await this.readFile(validatePlotData);
          resolve(result);
        })
      : Promise.resolve(null);

    const holdoutPlotDataPromise = holdoutPlotData
      ? new Promise(async resolve => {
          const result = await this.readFile(holdoutPlotData);
          resolve(result);
        })
      : Promise.resolve(null);

    const pvaPromise = Promise.all([
      validatePlotDataPromise,
      holdoutPlotDataPromise,
    ]).then(([validateResult, holdoutResult]) => {
      return {
        validate: {
          data: validateResult,
          name: 'predicted-vs-actual-plot',
        },
        holdout: {
          data: holdoutResult,
          name: 'predicted-vs-actual-plot',
        },
      };
    });

    const fitPromise = Promise.all([
      validatePlotDataPromise,
      holdoutPlotDataPromise,
    ]).then(([validateResult, holdoutResult]) => {
      return {
        validate: {
          data: validateResult,
          name: 'fit-plot',
        },
        holdout: {
          data: holdoutResult,
          name: 'fit-plot',
        },
      };
    });

    const parallelPromise = parallelPlotData
      ? new Promise(async resolve => {
          const result = await this.readFile(parallelPlotData);
          resolve({
            plot: {
              data: result,
              name: 'parallel-plot',
            },
          });
        })
      : Promise.resolve({
          plot: {
            data: null,
            name: 'parallel-plot',
          },
        });

    const pcaPromise = pcaPlotData
      ? new Promise(async resolve => {
          const result = await this.readFile(pcaPlotData);
          resolve({
            plot: {
              data: result,
              name: 'pca-plot',
            },
          });
        })
      : Promise.resolve({
          plot: {
            data: null,
            name: 'pca-plot',
          },
        });

    const multiVarPromise = multiVarPlotData
      ? new Promise(async resolve => {
          const result = await this.readFile(multiVarPlotData);
          resolve({
            plot: {
              data: result,
              name: 'multiVar-plot',
            },
          });
        })
      : Promise.resolve({
          plot: {
            data: null,
            name: 'multiVar-plot',
          },
        });

    const isoPromise = outlierPlotData
      ? new Promise(async resolve => {
          const result = await this.readFile(outlierPlotData);
          resolve({
            plot: {
              data: result,
              name: 'iso-plot',
            },
          });
        })
      : Promise.resolve({
          plot: {
            data: null,
            name: 'iso-plot',
          },
        });

    return Promise.all([
      histogramPromise,
      univariantPromise,
      correlationPromise,
      pvaPromise,
      fitPromise,
      parallelPromise,
      pcaPromise,
      multiVarPromise,
      isoPromise,
    ]).then(
      ([
        histogram,
        univariant,
        correlation,
        pva,
        fit,
        parallel,
        pca,
        multiVar,
        iso,
      ]) => {
        return {
          histogram,
          univariant,
          correlation,
          pva,
          fit,
          parallel,
          pca,
          multiVar,
          iso,
        };
      },
    );

    // const list = [];
    // list.push(await this.histogram(target));

    // list.push({
    //   name: 'correlation-matrix',
    //   url: correlationMatrixData
    // });

    // const allVariables = [...dataHeader.filter(h => h !== target), ...newVariable];
    // const checkedVariables = allVariables.filter(v => !trainHeader.includes(v));
    // [allVariables].map(v => v.sort().toString()).indexOf(checkedVariables.sort().toString());
    // allVariables.sort((a, b) => {
    //   return preImportance ? -1 * ((preImportance[a] || 0) - (preImportance[b] || 0)) : 0
    // });

    // for (let itm of allVariables) {
    //   list.push(await this.histogram(itm));
    //   list.push(await this.univariant(itm));
    // }

    // const { validatePlotData, holdoutPlotData } = model;
    // if (validatePlotData) {
    //   list.push({
    //     name: 'predicted-vs-actual-plot',
    //     url: validatePlotData,
    //   });
    //   list.push({
    //     name: 'predicted-vs-actual-plot',
    //     url: holdoutPlotData,
    //   });
    //   list.push({
    //     name: 'fit-plot',
    //     url: validatePlotData,
    //   });
    //   list.push({
    //     name: 'fit-plot',
    //     url: holdoutPlotData,
    //   });
    // }

    // const promises = list.map(async ({ name, url }) => {
    //   const result: any = await this.readFile(url);
    //   let data = {};
    //   if (
    //     [
    //       'correlation-matrix',
    //       'predicted-vs-actual-plot',
    //     ].includes(name)
    //   ) {
    //     data = {
    //       data: result
    //     };
    //   } else if ([
    //     'fit-plot',
    //   ].includes(name)) {
    //     data = {
    //       ...result
    //     }
    //   } else {
    //     data = {
    //       data: result.data
    //     };
    //   }

    //   return {
    //     ...data,
    //     name,
    //   }
    // });

    // return Promise.all(promises);
  };

  abortAll = () => {
    const arr = [
      this.abortDatabaseImport(),
      this.abortEtlData(),
      this.abortTrainByEtl(false),
      socketStore.stopCommands(this.id),
    ];
    return Promise.all(arr);
  };
  cancelReport = () => {
    setTimeout(
      action(() => {
        this.reportProgressText = 'init';
      }),
      10,
    );
    this.reportProgress = 0;
    this.reportCancel = true;
  };
  exportModelReport = async (modelId: string): Promise<void> => {
    try {
      this.reportCancel = false;
      //在这里获取所以直方图折线图数据
      this.reportProgressText = 'preparing histgram plot.';
      await this.histgramPlot();
      if (this.reportCancel) return;
      this.reportProgress = 20;
      this.reportProgressText = 'preparing univariate plot.';
      await this.univariatePlot();
      if (this.reportCancel) return;
      this.reportProgress = 40;
      this.reportProgressText = 'preparing correlation matrix.';
      await this.correlationMatrix();
      if (this.reportCancel) return;
      this.reportProgress = 60;
      this.reportProgressText = 'preparing preTrainImportance.';
      const arr = ['Regression', 'MultiClassification', 'Classification'];
      if (arr.includes(this.problemType)) {
        await this.preTrainImportance();
      } else {
        await this.clusterPreTrainImportance(false);
      }
      if (this.reportCancel) return;
      this.reportProgress = 90;
      this.reportProgressText = 'preparing model plot.';
      const model: any = this.models.find(m => m.id === modelId);
      // if(!this.bigData){
      model.graphicList = await this.allVariableList(model);
      // }
      if (this.reportCancel) return;
      this.reportProgress = 100;
      this.reportProgressText = 'generate html.';
      const json = JSON.stringify([{ ...this, ...{ models: [model] } }]);
      const html = await this.generateReportHtml(json);
      if (this.reportCancel) return;
      loadFile(`Report_${this.selectId}.html`, html);
      this.cancelReport();
    } catch (e) {
      Modal.error({
        title: '导出失败!',
        content: `导出报告错误。${e.message}`,
      });
      this.cancelReport();
    }
  };

  deleteModelByModelId = (modelId: string): void => {
    socketStore.ready().then(api => {
      if (this.deployment && this.deployment.model_name === modelId) {
        _message.warning('该模型已部署，请更换部署模型或删除部署后重试');
        return;
      }
      return api.deleteModel({ projectId: this.id, modelId }).then(res => {
        if (res.status == 200) {
          _message.success('删除成功');
          this.models = this.models.filter(m => modelId !== m.id);
        } else {
          _message.error(`删除失败!${res.message}`);
        }
      });
    });
  };
}

function loadFile(fileName: string, content: string) {
  const aLink = document.createElement('a');
  const blob = new Blob([content], {
    type: 'text/plain',
  });
  aLink.download = fileName;
  aLink.href = URL.createObjectURL(blob);

  //firefox
  aLink.style.display = 'none';
  document.body.appendChild(aLink);

  aLink.click();

  URL.revokeObjectURL(blob.toString());
}

function formatFeature(pt: 'Classification' | 'Regression') {
  const obj = {
    Classification: {
      'Extra Trees': 'extra_trees_preproc_for_classification',
      'Random Trees': 'random_trees_embedding',
      'Fast ICA': 'fast_ica',
      'Kernel PCA': 'kernel_pca',
      PCA: 'pca',
      Polynomial: 'polynomial',
      'Feature Agglomeration': 'feature_agglomeration',
      'Kitchen Sinks': 'kitchen_sinks',
      'Linear SVM': 'liblinear_svc_preprocessor',
      'Nystroem Sampler': 'nystroem_sampler',
      'Select Percentile': 'select_percentile_classification',
      'Select Rates': 'select_rates',
    },
    Regression: {
      'Extra Trees': 'extra_trees_preproc_for_regression',
      'Random Trees': 'random_trees_embedding',
      'Fast ICA': 'fast_ica',
      'Kernel PCA': 'kernel_pca',
      PCA: 'pca',
      Polynomial: 'polynomial',
      'Feature Agglomeration': 'feature_agglomeration',
      'Kitchen Sinks': 'kitchen_sinks',
      'Linear SVM': 'liblinear_svc_preprocessor',
      'Nystroem Sampler': 'nystroem_sampler',
      'Select Percentile': 'select_percentile_regression',
      'Select Rates': 'select_rates',
    },
  };
  return obj[pt];
}

export default Project;
