import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRequest } from 'ahooks';
import { LimeExplainOption, LimeExplain, ExplainViewLabel, updatePlotData } from './type';
import r2LoadGif from '@app/assets/R2Loading.gif'
import { Button, Result, Col, Row, Image } from 'antd';
import { LimeOptionForm } from './LimeOptionForm';
import html2canvas from "html2canvas";

interface LimePlotsProps {
  explain: LimeExplain,
  onExplain: (option: LimeExplainOption) => Promise<LimeExplain>,
  refreshExplain: (id: number) => Promise<LimeExplain>,
  onUpdateExplain?: (exp: LimeExplain) => any,
  updatePlotData?: (id: number, param: updatePlotData) => any,
  readHdfsFile?: (url: string) => any
}

export const LimePlots: React.FC<LimePlotsProps> = ({
  explain,
  onExplain,
  refreshExplain,
  onUpdateExplain = () => {},
  updatePlotData,
  readHdfsFile
}) => {

  const renderImage = () => {
    if (!!explain.plotData.htmlLocation && !explain.plotData.image && explain.plotData.htmlLocation.startsWith("hdfs://")) {
      readHdfsFile(explain.plotData.htmlLocation).then(res => {
        const iframe = document.createElement("iframe");
        iframe.setAttribute("width", "100%");
        iframe.setAttribute("frameborder", '0');
        document.body.appendChild(iframe);
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(res);
        iframe.contentWindow.document.close();
        setTimeout(function(){
            html2canvas(iframe.contentWindow.document.body).then(function(canvas) {
                explain.plotData.image = canvas.toDataURL('image/jpeg');
                // console.log(explain.plotData.image);
                updatePlotData(explain.id, { plotData: explain.plotData});
                onUpdateExplain(explain);
            });
        }, 1000);
      });
      
    }
  }

  renderImage();

  const { run: sync, cancel: cancelSync } = useRequest((id: number) => refreshExplain(id).then((res) => {
    onUpdateExplain(res);
  }), {
    pollingInterval: 5000,
    manual: true,
  });

  useEffect(() => {
    const func = (explain.errorMsg || !explain.explaining) ? cancelSync : () => sync(explain.id);
    func();

    return cancelSync;
  }, [explain.id, explain.errorMsg, explain.explaining])

  useEffect(() => {
    renderImage();
  }, [explain.plotData])

  const newExplain = (_option: LimeExplainOption) => {
    cancelSync();
    _option.data_index =Number(_option.data_index);
    onExplain(_option).then((res) => {
      onUpdateExplain(res)
    });
  }


  const renderOption = (text: string) => {
    return (
        <LimeOptionForm
          onFinish={values => newExplain(values)}
          initialValues={explain.plotData.option}
        >
          <Button type='primary' htmlType='submit'>{text}</Button>
        </LimeOptionForm>
    )
  }

  if (explain.explaining) {
    return (
      <Result
        icon={(<img src={r2LoadGif} alt="loading" />)}
        title={`正在解释${ExplainViewLabel[explain.viewType]}`}
        // subTitle={project.modelExplainError}
        // extra={renderOption('等待时间过长？重新解释')}
      />
    )
  }

  if (explain.errorMsg || !explain.plotData.htmlLocation) {
    return (
      <Result
        status="error"
        title={`解释${ExplainViewLabel[explain.viewType]}失败`}
        subTitle={explain.errorMsg}
        extra={renderOption('重新解释')}
      />
    )
  }

  return (
    <Row>
      <Col span={12}>
        {renderOption('确定')}
      </Col>
      <Col span={24}>
        <div style={{ marginTop:40 }}></div>
        {!!explain.plotData.image && 
        <Image
          style={{ maxWidth: 800, width: '100%' }}
          src={explain.plotData.image}
        />
        }
      </Col>
    </Row>
  );
}
