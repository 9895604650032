import React, { Component } from 'react';
import styles from './styles.module.css';
import { Slider } from 'antd'

interface Interface {
  range?,
  min?,
  max?,
  onChange?,
  value?,
  step?,
  tooltipVisible?,
  marks?
  disabled?:boolean
}

export default function Range(props:Interface){
  const { range, min, max, onChange, value, step, tooltipVisible, marks,disabled=false} = props;

  const stop = e => {
    e.stopPropagation()
  };
  return <div className={styles.range}
              onMouseOver={stop}>
    <Slider
      style={{
        cursor:(disabled?'not-allowed':'')
      }}
      range={range}
      min={min}
      max={max}
      onChange={disabled?()=>{}:onChange}
      value={value}
      step={step}
      tooltipVisible={tooltipVisible}
      getTooltipPopupContainer={el => el.parentElement}
      marks={marks}
    />
  </div>
}
