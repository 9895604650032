import React, { ReactElement } from 'react';
import ModelProcessFlow from './ModelProcessFlow'
import ModelProcessFlow_Logistic from './ModelProcessFlow_Logistic'
import MPF_TIME from './MPF_TIME'
import MPF_UL from './MPF_UL'
import EN from '../../../constant/en';
import Project from 'stores/Project';
import Model from 'stores/Model';

interface Interface {
  modelId?:string
  readonly project: Project;
  readonly model: Model;
}

export default function MPF(props:Interface):ReactElement {
  const { modelId = '',model:{problemType},project:{bigData}} = props;
  const mismatchArray =  [{
    value: 'mode',
    label: EN.Replacewithmostfrequentvalue
  }, {
    value: 'drop',
    label: EN.Deletetherows
    // label: 'Replace with most frequent value'
  }, {
    value: 'ignore',//Categorical
    label: EN.Replacewithauniquevalue
  }, {
    value: 'ignore',
    label: EN.DoNothing
  },{
    value: 'mean',
    label: EN.Replacewithmeanvalue
  },{
    value: 'min',
    label: EN.Replacewithminvalue
  }, {
    value: 'max',
    label: EN.Replacewithmaxvalue
  }, {
    value: 'median',
    label: EN.Replacewithmedianvalue
  }, {
    value: 'zero',
    label: EN.ReplaceWith0
  }, {
    value: 'others',
    label: EN.Replacewithothers
  },{
    value: 'respective',
    label: EN.ReplaceRespective
  },{
    value: 'column',
    label: EN.Deletethecolumn
  }];

  if(problemType === 'Forecasting'){
    const timeArray = [{
      value: 'last',
      label: EN.Replacewithlast
    }, {
      value: 'drop',
      label: EN.Deletetherows
    }, {
      value: 'mean',
      label: EN.Replacewithmeanvalue
    }, {
      value: 'median',
      label: EN.Replacewithmedianvalue
    }, {
      value: 'linear',
      label: EN.Replacewithlinear
    }, {
      value: 'polynomial',
      label: EN.Replacewithpolynomial
    }, {
      value: 'spline',
      label: EN.Replacewithspline
    }];
    return <MPF_TIME {...props} mismatchArray={timeArray} />
  }

  if(bigData){
    return <MPF_UL {...props} mismatchArray={mismatchArray} />
  }

  if (modelId.includes('Logistic')) {
    return <ModelProcessFlow_Logistic {...props} mismatchArray={mismatchArray} />
  } else if (modelId) {
    return <ModelProcessFlow {...props} mismatchArray={mismatchArray} />
  }
  //非监督
  return <MPF_UL {...props} mismatchArray={mismatchArray} />
}
