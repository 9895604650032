// import '@babel/polyfill'
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'components/App';
import 'antd/dist/antd.less';

ReactDOM.render(<App />, document.getElementById('root'));
