import React, {
  ReactElement,
  useContext,
  useState,
} from 'react';
import styles from './styles.module.css';
import { observer, MobXProviderContext } from 'mobx-react';
import { ProcessLoading } from 'components/Common';
import EN from '../../../../constant/en';
import { ProjectStore } from 'stores/ProjectStore';
import TimeSeriesResult from './TimeSeriesResult'

function TimeSeries(): ReactElement {
  // @ts-ignore
  const {
    projectStore: {
      project: {
        selectModel,
        id,
        fileName,
        models,
        loadModel,
      },
      project,
    },
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext);

  const [sort, upSort] = useState({
    key: 'time',
    value: 1,
  });

  const handleSort = (key) => {
    const _sort = { ...sort };
    if (!_sort) return;
    if (_sort.key === key) {
      _sort.value = -_sort.value;
    } else {
      _sort.key = key;
      _sort.value = 1;
    }

    upSort(_sort);
  };

  if (!models.length) return null;
  if (loadModel) return <ProcessLoading style={{ position: 'fixed' }} />;

  const realName = fileName.endsWith('.csv') ? fileName.slice(0, -4) : fileName;

  return (
    <div className={styles.modelResult}>
      <TimeSeriesResult
        models={models}
        project={project}
        sort={sort}
        handleSort={handleSort}
      />
      <div className={styles.buttonBlock}>
        <a style={{ display: 'flex' }} target='_blank' href={`/r2upload/download/timeSeries?projectId=${id}&modelId=${selectModel.id}&filename=${encodeURIComponent(`${realName}-${selectModel.id}.csv`)}`}>
          <button className={styles.button}>
            <span>{EN.Download}</span>
          </button>
        </a>
      </div>
    </div>
  );
}
export default observer(TimeSeries);
