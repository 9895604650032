import { observable, computed } from 'mobx';
import moment from 'moment';
import { action } from 'mobx';
import socketStore from 'stores/SocketStore';
import _ from 'lodash';

const defaultDeploymentOptions = {
  option: null,
  source: null,
  sourceOptions: {
    hostname:'',
    sqlTable:'',
  },
  location: null,
  locationOptions: {},
  file: '',
  frequency: null,
  frequencyOptions: {},
  dataReport: null,
  autoDisable: null,
  mapHeader: {},
  charset: 'auto',
  fileId:''
};

const defaultPerformanceOptions = {
  option: null,
  source: null,
  sourceOptions: {},
  location: null,
  locationOptions: {},
  file: '',
  measurementMetric: 'AUC',
  metricThreshold: 0.7,
  frequency: null,
  frequencyOptions: {},
  dataReport: null,
  autoDisable: null,
  mapHeader: {},
  charset: 'auto'
};

export default class Deploy {
  @observable id;
  @observable warningEmails;
  @observable warning;

  @observable userId;
  @observable projectId;
  @observable projectName;
  @observable modelId;
  @observable modelName;
  @observable incrementName;
  @observable deploymentName;
  @observable name;
  @observable name_increment;
  @observable modelType;
  @observable createdDate;
  @observable enable;
  @observable email;
  @observable modelList;
  @observable lineCount;
  @observable mapHeader;
  @observable bucket;
  @observable bigData;
  @observable supportIncrement;
  @observable deployment_increments;
  @observable increment_name;
  @observable origin_model_name;
  @observable loading = false;

  @observable deploymentOptions = { ...defaultDeploymentOptions };
  @observable incrementOptions = {...defaultDeploymentOptions};
  @observable performanceOptions = { ...defaultPerformanceOptions };

  constructor(deploy) {
    this.warning = deploy.warning;
    this.warningEmails = deploy.warningEmails;
    this.id = deploy.id;
    this.userId = deploy.userId;
    this.projectId = deploy.project?.id;
    this.deployment_increments = deploy.deployment_increments;
    this.projectName = deploy.projectName;
    this.origin_model_name = deploy.origin_model_name;
    this.deploymentName = deploy.deploymentName;
    this.modelId = deploy.modelId;
    this.modelName = deploy.model_name;
    this.incrementName = deploy.incrementName;
    this.name = deploy.name;
    this.increment_name = deploy.increment_name;
    this.name_increment = deploy.name_increment;
    this.modelType = deploy.modelType;
    this.enable = deploy.enable;
    this.createdDate = deploy.createdDate;
    this.email = deploy.email;
    this.lineCount = deploy.lineCount;
    this.mapHeader = deploy.mapHeader;
    this.bucket = deploy.bucket;
    this.bigData = deploy.bigData;
    this.supportIncrement = deploy.supportIncrement;
    this.incrementOptions = deploy.incrementOptions || {};
    this.deploymentOptions = {
      ...defaultDeploymentOptions,
      ...deploy.deploymentOptions
    };
    let default_measurementMetric;
    switch (deploy.modelType) {
      case 'Classification':
        default_measurementMetric = 'AUC';
        break;
      case 'Regression':
        default_measurementMetric = 'R2';
        break;
      case 'Outlier':
        default_measurementMetric = 'Accuracy';
        break;
      case 'Clustering':
        default_measurementMetric = this.bigData ? 'silhouette_euclidean' : 'CVNN';
        break;
      case 'MultiClassification':
        default_measurementMetric = 'macro_auc';
        break;
    }

    this.performanceOptions = {
      ...defaultPerformanceOptions,
      metricThreshold: deploy.modelType === 'Classification' ? 0.7 : 0.5,
      measurementMetric: default_measurementMetric,
      ...deploy.performanceOptions
    };
    this.getModelInfo()
  }

  save = () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      socketStore.ready().then((api: any) => {
        api.updateDeployment({
          data: {
            id: this.id,
            projectId: this.projectId,
            projectName: this.projectName,
            model_name: this.modelName,
            modelType: this.modelType,
            createdDate: this.createdDate,
            email: this.email,
            enable: this.enable,
            deploymentOptions: this.deploymentOptions,
            performanceOptions: this.performanceOptions,
            incrementOptions:this.incrementOptions,
            lineCount: this.lineCount,
            mapHeader: this.mapHeader,
            incrementName:this.incrementName,
            deploymentName:this.deploymentName,
            name:this.name,
            name_increment:this.name_increment,
            supportIncrement:this.supportIncrement,
            increment_name:this.increment_name,
            origin_model_name:this.origin_model_name
          }
        }).then(()=>{
          this.loading = false;
          resolve('finish');
        })
      })
    });
  };
  singleSave = (single_data) => {
    return new Promise((resolve, reject) => {
      socketStore.ready().then((api: any) => {
        api.updateDeployment({
          data: {
            id: this.id,
            ...single_data
          }
        }).then(()=>{
          resolve({});
        })
      })
    });
  };
  log = () =>
    console.log({
      id: this.id,
      userId: this.userId,
      projectId: this.projectId,
      projectName: this.projectName,
      modelName: this.modelName,
      modelType: this.modelType,
      createdDate: this.createdDate,
      email: this.email,
      enable: this.enable,
      deploymentOptions: this.deploymentOptions,
      performanceOptions: this.performanceOptions,
      updatedDate: moment().unix()
    });

  getModelInfo = action(async () => {
    const api: any = await socketStore.ready()
    if(this.projectId) {
      const { modelList } = await api.getAllModels({ projectId: this.projectId, modelType: this.modelType })
      this.modelList = modelList
    }
  })

  findModel = (modelName) => {
    let result;
    this.modelList && Object.entries(this.modelList).forEach(([settingName, models]: [any, any]) => {
      if (result) return
      models.forEach(model => {
        if (result || !model) return
        if (model.name === modelName) result = model
      })
    })
    if(!result){
      _.flattenDeep(this.deployment_increments.map(itm=>itm.increments.map(it=>{
        return [{
          [it.new]:it.newScore,
          [it.old]:it.oldScore
        }]
      }))).forEach(itm=>{
        if(Object.keys(itm).includes(modelName)){
          let performance ='';
          itm[modelName]&&Object.entries(itm[modelName])
              .forEach(itm=>performance +=`${itm[0]}=${itm[1]}
              `)
          result = {
            performance,
          }
        }
      })

      // this.deployment_increments
      // const incre = this.deployment_increments.find(itm=>itm.model_name === modelName)
      // console.log(213,result,incre)
      // const it = incre.increments.find(itm=>itm.new === modelName || itm.old === modelName)
      // if(it.new === modelName){
      //   result = {
      //     performance:it.newScore.toString()
      //   }
      // }else{
      //   result = {
      //     performance:it.oldScore.toString()
      //   }
      // }
    }
    return result;
  }

  @computed
  get currentModel() {
    if (!this.modelList) return
    return this.findModel(this.modelName)
    // return this.findModel(this.origin_model_name)
  }

  get version(){
    if (!this.modelList) return
    console.log('origin_model_name & modelName: '+this.origin_model_name, this.modelName);
    const currentModel = this.findModel(this.origin_model_name||this.modelName)
    return currentModel?.modelId?.version
  }

  getDeploymentToken = async (type: string) => {
    const api: any = await socketStore.ready()
    const result = await api.getDeploymentToken({ deploymentId: this.id, projectId: this.projectId, deployType: type })
    return result.token
  }
}
