import React, { ReactElement } from 'react';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import Layout from 'components/App/Layout';
import Report from 'components/Report'
import ErrorBoundary from 'components/Common/ErrorBoundary';
import Stores from 'stores/index';
import { ConfigProvider, message } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import enUS from 'antd/es/locale/en_US';

const r2Report = (window as any).r2Report;
const stores = {
  ...Stores,
};

message.config({
  duration: 4,
  maxCount: 1,
});

export default function App(): ReactElement {
  return (
    <ErrorBoundary locale={enUS}>
      <ConfigProvider locale={(window as any).r2_env.isEN ? enUS : zh_CN}>
        <Provider {...stores}>
          {r2Report 
            ? <Report /> 
            : (
              <BrowserRouter>
                <ErrorBoundary>
                  <Layout />
                </ErrorBoundary>
              </BrowserRouter>
            )
          }
        </Provider>
      </ConfigProvider>
    </ErrorBoundary>
  )
}
