import React, { ReactElement, useState, useMemo, useEffect } from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import {
  ContinueButton,
  Modal,
  ProcessLoading,
  Table,
  Confirm,
  Show,
} from 'components/Common';
import TimeSeriesFixIssue from './Issues/TimeSeriesFixIssue';
import TimeSeriesSummary from './TimeSeriesSummary';
import { Select } from 'antd';
import { formatNumber } from '../../../util';
import EN from '../../../constant/en';
import Project from 'stores/Project';
import moment from 'moment';
import { ProjectRolesKey } from '@app/constant/types';

interface TimeSeriesIssueProps {
  project: Project;
}

const { Option } = Select;

function TimeSeriesIssue(props: TimeSeriesIssueProps): ReactElement {
  const {
    project: {
      updateProject,
      nextSubStep,
      qualityHasChanged,
      train2ing,
      models,
      fixFillMethod,
      endQuality,
      colType,
      etling,
      mapHeader,
      target,
      readFile,
      originalIndex,
      etlProgress,
      outlierDictTemp,
      orderIndex,
      totalRawLines,
      dataSortTemp,
      variableIssueCount,
      variableIssues
    },
    project,
  } = props;

  const { nullCount, mismatchCount, outlierCount } = variableIssueCount

  const [visible, upVisible] = useState(false);
  const [summary, upSummary] = useState(false);
  const [warning, upWarning] = useState(false);
  const [index, upIndex] = useState([]);
  const [multiMode, upMultiMode] = useState(false);
  const [loadData, upLoadData] = useState(true)
  const [uploadData, upUploadData] = useState([])

  useEffect(() => {
    getData()
  }, [])

  const backToConnect = () => {
    return updateProject(nextSubStep(1, 2));
  };

  const editFixes = () => {
    upVisible(true);
    closeSummary();
  };

  const closeFixes = () => {
    upVisible(false);
  };

  const showSummary = () => {
    if (!qualityHasChanged) return upSummary(true);
    if (train2ing || !!models.length) return upWarning(true);
    onConfirm();
  };

  const closeSummary = () => {
    upSummary(false);
  };

  const saveDataFixes = () => {
    fixFillMethod();
    // message.info(EN.Thechangeswillbeappliedintrainingsection, 5);
    closeFixes();
  };

  const onClose = () => {
    upWarning(false);
  };

  const onConfirm = () => {
    endQuality()
      .then(() => upSummary(true))
      .catch(() => { });
    onClose();
  };

  const updateCondition = (column) => () => {
    const that = index.slice()
    const _index = that.indexOf(column);
    if (!multiMode) {
      that.length = 0
    } else {
      if (_index !== -1) that.splice(_index, 1);
    }
    if (_index === -1) that.push(column)
    upIndex(that)
    // getData()
    // return reloadTimeData(0, 500, that);
  };

  const getData = () => {
    upLoadData(true)
    readFile(originalIndex).then(data => {
      upLoadData(false)
      upUploadData(data)
    })
  }

  const changeSelect = (dataSortTemp) => {
    // const value = e.target.value
    project.setProperty({ dataSortTemp })
  }

  const tableData = useMemo(() => {
    if (loadData) return []
    if (etling) return [];
    const headerList = [target, ...orderIndex]
    const data = uploadData.map(row =>
      headerList.map(i => row[i])
    );
    // const showIndex = headerList
    //   .map(v => rawHeader.indexOf(v));
    // const data = uploadData.map(row =>
    //   showIndex.map(i => row[i])
    // );
    /**
     * 根据showSelect, indexPosition变化
     * showSelect: true  显示勾选框
     * checkRow: 勾选框的行数
     * headerRow: 标题的行数
     * selectRow: 类型选择的行数
     * columnHeader: 表头的列数
     * rowHeader: 表头的行数
     */
    const cannotReverse = !orderIndex.some(h => colType[h] === "Datetime")
    const realColumn = headerList.length + 1;
    const SortArr = [{
      content: <span>{EN.SortBy}</span>,
      title: EN.SortBy,
      cn: styles.cell,
    }, {
      content: <Select
          showArrow={false}
          value={dataSortTemp} style={{
            width:120,
          }} onChange={changeSelect}>
        <Option value="asc">{EN.OrderSort}</Option>
        {cannotReverse && <Option value='desc'>{EN.ReverseSort}</Option>}
      </Select>,
      title: 's',
      cn: styles.cell,
    }, ...Array(realColumn - 2).fill({
      content: <span/>,
      title: '',
      cn: styles.cell,
    })];

    const indexArr = [{
      content: <span/>,
      title: '',
      cn: styles.cell,
    }];

    const headerArr = [{
      content: <span>{EN.Columns}</span>,
      title: EN.Columns,
      cn: styles.titleCell,
    }];
    const selectArr = [{
      content: <span>{EN.DataType}</span>,
      title: EN.DataType,
      cn: styles.cell,
    }];
    const issueArr = [{
      content: <span/>,
      title: '',
      cn: styles.cell,
    }];
    for (let i = 1; i < realColumn; i++) {
      const header = headerList[i - 1] || '';
      const headerText = mapHeader[header];

      indexArr.push({
        content: <span>{header === target ? EN.TargetVariable : EN.OrderIndex}</span>,
        title: header === target ? EN.TargetVariable : EN.OrderIndex,
        cn: styles.cell,
      });

      headerArr.push({
        content: <span>{headerText}</span>,
        title: headerText,
        cn: styles.titleCell,
      });

      const colValue =
        colType[header] === 'Raw' ? 'Categorical' : colType[header];
      selectArr.push({
        content: (
          <span>
            {EN[colValue]}
            {/* {colValue === 'Numerical' ? EN.Numerical : EN.Categorical} */}
          </span>
        ),
        title: EN[colValue],//colValue === 'Numerical' ? EN.Numerical : EN.Categorical,
        cn: styles.cell,
      });
      const issues = [];

      const percent = (variableIssues.mismatchRow[header] + variableIssues.nullRow[header])

      if (!!percent) {
        issues.push(
          <div
            className={styles.errorBlock}
            key={'mismatch' + header}
            onClick={updateCondition(header)}
          >
            <div className={styles.issueBackground}>
              <div className={styles.mismatch} />
              <div
                className={classnames({
                  [styles.issueActive]: index.indexOf(header) !== -1,
                })}
              />
            </div>
            <span>
              {percent < 0.01
                ? '<0.01'
                : formatNumber(percent, 2)}
              %
            </span>
          </div>,
        );
      }
      const issueData = {
        content: <div className={styles.errorBox}>{issues}</div>,
        title: '',
        cn: styles.cell,
      };
      issueArr.push(issueData);
    }
    const tableData = data.length
      ? data.map((row, rowIndex) => {
        let showRow = true
        const dataRow = row.map((v, k) => {
          v = (v === null || v === 'nan') ? undefined : v
          const header = headerList[k]
          const itemData = {
            content: <span>{(v === undefined ? "" : `${v}`)}</span>,
            title: (v === undefined ? "" : `${v}`),
            cn: styles.cell,
          };

          const isNum = colType[header] === 'Numerical';
          const isDate = colType[header] === 'Datetime';
          const isMissing = isNaN(+v) ? !v : false;
          const isMismatch = isDate ? !moment(v).isValid() : isNum
            ? isNaN(+v) || isNaN(parseFloat(v.toString()))
            : false;
          if (isMissing || isMismatch) {
            itemData.cn = classnames(itemData.cn, styles.mismatch);
          }
          //
          showRow = index.includes(header) ? (isMissing || isMismatch) : true
          return itemData;
        })
        return showRow ? [{
          content: <span>{rowIndex}</span>,
          title: rowIndex,
          cn: styles.cell,
        }, ...dataRow] : null
      }).filter(r => !!r)
      : [[]];

    return [indexArr, SortArr, headerArr, selectArr, issueArr, ...tableData];
  }, [uploadData, outlierDictTemp, variableIssues, etling, dataSortTemp]);

  // const toggleMultiMode = () => {
  //   upMultiMode(!multiMode);
  //   upIndex([]);
  // };

  const header = [target, ...orderIndex]

  const dataIssue = !!(nullCount + mismatchCount + outlierCount)
  const rowIssue = totalRawLines < 20

  return (
    <div className={styles.quality}>
      <div className={styles.issue}>
        {rowIssue || dataIssue ? (
          <div className={styles.issueTitle}>
            <span>
              {EN.IssueS}
              {+rowIssue + +dataIssue > 1 && EN.SS} {EN.Found}!
            </span>
          </div>
        ) : (
            <div className={styles.cleanTitle}>
              <span>{EN.VariableQualitylooksgood}</span>
            </div>
          )}
        <div className={styles.issueBox}>
          {rowIssue && (
            <div className={styles.issueText}>
              <div className={styles.point} />
              <span className={styles.limitText}>{EN.Foryourwholedataset20}</span>
              <Show name={ProjectRolesKey.QualityPredictContinue}>
                <div className={styles.button} onClick={backToConnect}>
                  <button>
                    <span>{EN.LoadaNewDataset}</span>
                  </button>
                </div>
              </Show>
            </div>
          )}
          {dataIssue && (
            <div className={styles.issueText}>
              <div className={styles.point} />
              <span className={styles.limitText}>
                {EN.SomeissuesarefoundR2learnhasgenerated}
              </span>
              <Show name={ProjectRolesKey.QualityPredictContinue}>
                <div className={styles.button} onClick={editFixes}>
                  <button>
                    <span>{EN.EditTheFixes}</span>
                  </button>
                </div>
              </Show>
            </div>
          )}
        </div>
      </div>
      <div className={styles.typeBox}>
        {!!(mismatchCount + nullCount) && (
          <div className={styles.type}>
            <div className={classnames(styles.typeBlock, styles.mismatch)} />
            <span>{EN.DataTypeMismatch}</span>
          </div>
        )}
        {!!outlierCount && (
          <div className={styles.type}>
            <div className={classnames(styles.typeBlock, styles.outlier)} />
            <span>{EN.OutlierDetection}</span>
          </div>
        )}
      </div>
      <div className={styles.variableIssue}>
        <div className={styles.contentBox}>
          <Table
            columnWidth={160}
            rowHeight={34}
            columnCount={header.length + 1}
            rowCount={tableData.length}
            fixedColumnCount={1}
            fixedRowCount={5}
            style={{ border: '1px solid #ccc' }}
            data={tableData}
          />
        </div>
        <Show name={ProjectRolesKey.QualityPredictContinue}>
          <div className={styles.variableBottom}>
            <ContinueButton
              onClick={showSummary}
              text={EN.Continue}
              width="15%"
              disabled={!!rowIssue}
            />
          </div>
        </Show>
      </div>
      {etling && (
        <ProcessLoading progress={etlProgress} style={{ position: 'fixed' }} />
      )}
      <Modal
        content={
          <TimeSeriesFixIssue
            project={project}
            nullCount={nullCount}
            mismatchCount={mismatchCount}
            outlierCount={outlierCount}
            closeFixes={closeFixes}
            saveDataFixes={saveDataFixes}
            isTarget={true}
            issueRow={variableIssues}
          />
        }
        visible={visible}
        width="12em"
        title={EN.HowR2LearnWillFixTheIssues}
        onClose={closeFixes}
        closeByMask={true}
        showClose={true}
      />
      <Modal
        content={<TimeSeriesSummary project={project} editFixes={editFixes} />}
        visible={summary}
        width="12em"
        title={EN.HowR2LearnWillFixTheIssues}
        onClose={closeSummary}
        closeByMask={true}
        showClose={true}
      />
      {
        <Confirm
          width={'6em'}
          visible={warning}
          title={EN.Warning}
          content={EN.Thisactionmaywipeoutallofyourprevious}
          onClose={onClose}
          onConfirm={onConfirm}
          confirmText={EN.Continue}
          closeText={EN.CANCEL}
        />
      }
    </div>
  );
}

export default observer(TimeSeriesIssue)
