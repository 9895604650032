import React, { ReactElement, useContext } from 'react';
import styles from './styles.module.css';
import { MobXProviderContext } from 'mobx-react';
import { observer } from 'mobx-react';
import emptyIcon from './icon-no-report.svg';
import EN from '../../../constant/en';
import ScheduleOperation from './ScheduleOperation';
import { ScheduleStore } from 'stores/ScheduleStore';
import { DeploymentStore } from 'stores/DeploymentStore';

function Operation(): ReactElement {
  // @ts-ignore
    const {
        deploymentStore:{
          currentDeployment:{
              deploymentOptions,
              bigData,
              modelName,
          },
        },
        scheduleStore:{
          sortedDeploymentSchedules
        },
      }:{
          deploymentStore:DeploymentStore
          scheduleStore:ScheduleStore
      } = useContext(MobXProviderContext);
  return (
    <div className={styles.operation}>
      <div className={styles.info}>
        <span className={styles.model}>
          {EN.Model}: {modelName}
        </span>
      </div>
      {!sortedDeploymentSchedules?.length && <Empty />}
      {sortedDeploymentSchedules?.length ? (
        <div className={styles.table}>
          <div className={styles.head}>
            <span className={styles.modelName}>{EN.ModelName}</span>
            <span className={styles.deploymentTime}>{EN.DeploymentTime}</span>
            <span className={styles.deploymentStyle}>{EN.DeploymentStyle}</span>
            <span className={styles.executionSpeed}>
              {EN.ExecutionSpeed} <small>{EN.Rowss}</small>
            </span>
            <span className={styles.dataSize}>{EN.TotalLines}</span>
            <span className={styles.status}>{EN.Status}</span>
            <span className={styles.results}>{EN.Results}</span>
            <span className={styles.results}>{EN.DeploySummary}</span>
          </div>
          <div className={styles.list}>
            {sortedDeploymentSchedules?.map(s => (
              <ScheduleOperation cddo={deploymentOptions} schedule={s} key={s.schedule.id} bigData={bigData} />
            ))}
          </div>
        </div>
      ):''}
    </div>
  );
}

const Empty = () => (
  <div className={styles.emptyTable}>
    <img src={emptyIcon} className={styles.emptyIcon} alt={EN.Empty} />
    <span className={styles.emptyText}>{EN.NoDeploymentReportYet}</span>
  </div>
);

export default observer(Operation);
