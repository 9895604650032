import React from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';

export default function ContinueButton(props){
    const {disabled, onClick, text, width,height,style={}} = props;
    return <div className={styles.button}
                style={{
                    width: width || "1.1em",
                    height,
                }}>
        <button disabled={disabled} onClick={onClick}
                style={style}
                className={classnames(styles.continue,{
            [styles.disabled]: disabled
        })}>{text}</button>
    </div>
}
