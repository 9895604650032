import React  from 'react';
import styles from "./styles.module.css";
import { List, Button, Card } from 'antd';
interface Interface {
    files: any;
}
import EN from '../../constant/en';
export default function PdfView(props: Interface) {
    const fileList = props.files;
    return (
        <Card title={EN.UserOperationManual} bordered={false} headStyle={{fontSize: '20px'}} >
            <List<any>
                header={
                    <div className={styles.headerlists}>
                        <div className={styles.left}>
                            <span>{EN.FileName}</span>
                        </div>
                        <div className={styles.size}>{EN.FileSize}</div>
                        <div className={styles.time} >{EN.ReleaseDate}</div>
                        <div className={styles.right}>
                            {EN.Operation}
                        </div>
                    </div>
                }
                dataSource={fileList}
                rowKey="id"
                size='large'
                split={false}
                renderItem={(file, index) => {
                    return (
                        <List.Item key={file.id} style={{fontSize: '17px', height: '100%' }}>
                            <div className={styles.lists}>
                                <div className={styles.left}>
                                    <span>{file.name + '.pdf'}</span>
                                </div>
                                <div className={styles.size}>{file.size}</div>
                                <div className={styles.time} >{file.createdAt}</div>
                                <div className={styles.right}>
                                    <Button type="primary" onClick={()=>window.open((file.url), '_blank')}>{EN.Preview}</Button>
                                    <a style={{
                                        margin: 0
                                    }}href={file.url} download={file.name+'.pdf'}>{EN.Download}</a>
                                </div>
                            </div>
                        </List.Item>
                    )
                }}
            />
        </Card>
    );
}