import React, { ReactElement, useContext, useEffect } from 'react';
import { MobXProviderContext, observer, useLocalStore } from 'mobx-react';
import {
  CaretRightOutlined,
  PauseOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Modal, Progress, Select } from 'antd';
import { action } from 'mobx';
import databaseIcon from '../Deployment/icon-database.svg';
import Uploader from '../Uploader';
import fileIcon from '../Deployment/icon-file-local.svg';
import { formatNumber } from '../../../util';
import { DeploymentStore } from 'stores/DeploymentStore';
import { UserStore } from 'stores/UserStore';
import { IncrementStore } from 'stores/IncrementStore';
import { SocketStore } from 'stores/SocketStore';
import { ProjectStore } from 'stores/ProjectStore';
import { BlackButton, DatabaseConfig, Hint, Show } from '../../Common';
import EN from '../../../constant/en';
import styles from '../Deployment/styles.module.css';
import { ProjectRolesKey } from '@app/constant/types';

const { Option, OptGroup } = Select;

let si;
function Increment(): ReactElement {
  // @ts-ignore
  const {
    deploymentStore: {
      currentDeployment: {
        id,
        save,
        modelList,
        modelName,
        currentModel,
        version,
        projectId,
        supportIncrement,
        mapHeader,
        incrementOptions,
        increment_name,
        incrementName = increment_name,
        name = modelName,
        // name_increment = name,
        deployment_increments = [],
        origin_model_name,
      },
      currentDeployment,
    },
    userStore,
    incrementStore: {
      partialTrain,
      partialStatus,
      incrementResult,
      initProject: incrementInit,
    },
    incrementStore,
    socketStore,
    projectStore: { initProject, project, projectInit },
  }: {
    deploymentStore: DeploymentStore;
    userStore: UserStore;
    incrementStore: IncrementStore;
    socketStore: SocketStore;
    projectStore: ProjectStore;
  } = useContext(MobXProviderContext);

  const store = useLocalStore(() => ({
    modelEditing: false,
    tempModelName: false,
    dialog: null,
    errorTimes: 0,
    uploadStatus: false as boolean | string,
    uploadError: null as any,
    uploadPercentage: 0 as any,
    uploadOperator: null as any,
    progress: 0,
    incrementName: false,
    name: false,
    supportIncrement: false,
    increment_name: '',
    origin_model_name: '',
    // result:'您当前模型增量训练已完成',
    // showSuccess:false,
    updateField(data) {
      Object.assign(this, data);
    },
  }));

  useEffect(() => {
    projectId &&
      !projectInit &&
      initProject(projectId) &&
      incrementInit(projectId);
  }, [projectId]);

  useEffect(() => {
    if (partialStatus || incrementResult) {
      clearInterval(si);
      store.updateField({
        progress: 0,
      });
      incrementStore.partialStatus = 0;
      incrementStore.incrementResult = false;
    }
    return () => clearInterval(si);
  }, [partialStatus, incrementResult]);

  const modelChange = data => {
    const [
      tempModelName,
      increment_name,
      origin_model_name,
      supportIncrement,
    ] = JSON.parse(data);
    store.updateField({
      tempModelName,
      incrementName: tempModelName,
      name,
      supportIncrement,
      increment_name,
      origin_model_name,
    });
  };

  const onSaveModel = action(async () => {
    let upStore: any = {
      modelEditing: !store.modelEditing,
    };
    if (store.modelEditing && store.tempModelName) {
      const {
        tempModelName,
        incrementName,
        name,
        supportIncrement,
        increment_name,
        origin_model_name,
      } = store;
      currentDeployment.modelName = increment_name;
      currentDeployment.incrementName = incrementName;
      currentDeployment.name = name;
      currentDeployment.supportIncrement = supportIncrement;
      currentDeployment.increment_name = increment_name;
      currentDeployment.deploymentName = tempModelName; //DeploymentName
      currentDeployment.origin_model_name = origin_model_name;
      await save();
      upStore.tempModelName = false;
    }
    store.updateField(upStore);
  });

  const show = dialog =>
    store.updateField({
      dialog,
    });

  const pause = () => {
    if (!store.uploadOperator) return;
    if (store.uploadStatus === 'uploading') {
      socketStore.ready().then(api => {
        api.removeEventListener('offline', pause);
      });
      store.uploadOperator.pause();
      store.updateField({
        uploadStatus: 'paused',
      });
      return;
    }
    store.uploadOperator.resume();
    store.updateField({
      uploadStatus: 'uploading',
    });
  };

  const uploader = {
    onError: action((error, times = 1) => {
      console.error(error);
      socketStore.ready().then(api => {
        api.removeEventListener('offline', pause);
      });
      store.updateField({
        errorTimes: times,
        uploadStatus: 'error',
        uploadError: error,
      });
    }),
    onFinished: action((response, file) => {
      socketStore.ready().then(api => {
        api.removeEventListener('offline', pause);
      });
      incrementOptions.file = file.name;
      incrementOptions.fileId = response.originalIndex;
      incrementOptions.source = 'file';
      store.updateField({
        uploadPercentage: 100,
        uploadStatus: false,
      });
      return save();
    }),
    onProgress: action(progress => {
      store.updateField({
        uploadStatus: 'uploading',
        uploadPercentage: formatNumber(String(progress), 2),
      });
    }),
    onStart: action(opt => {
      socketStore.ready().then(api => {
        api.addEventListener('offline', pause);
      });
      store.updateField({
        uploadStatus: 'uploading',
        uploadPercentage: 0,
        uploadOperator: opt,
      });
    }),
    operator: uploadOperator => {
      store.updateField({
        uploadOperator,
      });
    },
    params: {
      projectId,
      userId: userStore.info.id,
      type: 'deploy',
    },
    mapHeader,
  };

  const DataSource = observer(() => {
    return (
      <div className={styles.dataSource}>
        <span className={styles.label}>
          <span className={styles.text}>{EN.DataSourceText}:</span>
        </span>
        <div className={styles.selections} style={{ alignItems: 'center' }}>
          {incrementOptions.source === 'database' && (
            <div
              className={styles.selected}
              onClick={show.bind(this, 'databasesource')}
            >
              <span className={styles.result}>
                <img
                  alt="database"
                  src={databaseIcon}
                  className={styles.selectionIcon}
                />
                <span className={styles.resultText}>
                  {EN.Database}
                  <span className={styles.path}>
                    {incrementOptions.sourceOptions?.sqlTable}
                  </span>
                </span>
              </span>
              <span className={styles.or}>
                <span className={styles.orText}>{EN.Or}</span>
              </span>
            </div>
          )}
          {incrementOptions.source === 'file' && (
            <div className={styles.selected} id="file">
              <Uploader className={styles.resultText} {...uploader}>
                <img
                  alt="file"
                  src={fileIcon}
                  className={styles.selectionIcon}
                />
                {EN.LocalFile}
                <span className={styles.path} title={incrementOptions.file}>
                  {incrementOptions.file}
                </span>
              </Uploader>
              <span className={styles.or}>
                <span className={styles.orText}>{EN.Or}</span>
              </span>
            </div>
          )}

          {incrementOptions.source !== 'database' && (
            <div
              className={styles.selection}
              onClick={show.bind(null, 'databasesource')}
            >
              <span className={styles.text}>
                <img
                  alt="database"
                  src={databaseIcon}
                  className={styles.selectionIcon}
                />
                {EN.Database}
              </span>
            </div>
          )}
          {incrementOptions.source !== 'file' && (
            <div className={styles.selectionWithoutHover} id="file">
              <Uploader className={styles.text} {...uploader}>
                <img
                  alt="file"
                  src={fileIcon}
                  className={styles.selectionIcon}
                />
                {EN.LocalFile}
              </Uploader>
            </div>
          )}
        </div>
      </div>
    );
  });

  const done = async () => {
    clearInterval(si);
    store.updateField({
      progress: 0.1,
    });
    incrementStore.incrementResult = false;
    let spin = 0.1;
    si = setInterval(() => {
      spin = spin + ((100 - spin) / 100) * Math.floor(Math.random() * 5);
      store.updateField({
        progress: +spin.toFixed(2),
      });
    }, 1000);

    return partialTrain({
      data_sample_method: [
        {
          csvLocation: incrementOptions.fileId,
          filename: incrementOptions.file,
          sampleMethod: 'order',
          orderStart: 0,
          orderEnd: 1,
        },
      ],
      id: projectId,
      version: increment_name || modelName,
      edition: {
        deploymentId: id,
        origin_model_name: origin_model_name || modelName,
      },
      v: version,
    });
  };

  const abort = () => {
    project.abortTrain('increment');
    clearInterval(si);
    store.updateField({
      progress: 0,
    });
  };

  const closeDialog = () =>
    store.updateField({
      dialog: null,
    });

  return (
    <div className={styles.deployment}>
      <div className={styles.info}>
        <span className={styles.model}>
          {EN.Model}:{' '}
          {store.modelEditing ? (
            <Select
              value={store.tempModelName || incrementName || modelName}
              onChange={modelChange}
              style={{ width: 400 }}
            >
              {modelList &&
                Object.entries(modelList).flatMap(([settingName, models]) => {
                  const filteredModels = (models as any[]).filter(model =>(!!model.supportIncrement));
                  if (filteredModels.length === 0) {
                    return null;
                  }
                  return (
                    <OptGroup key={settingName} label={settingName}>
                      {filteredModels.flatMap((model) => {
                        const incre = deployment_increments.find(
                          itm => itm.model_name === model.name,
                        );
                        if (incre) {
                          return incre.increments.map((itm, index) => {
                            let { version } = itm;
                            return (
                              <Option
                                key={itm.new}
                                value={JSON.stringify([
                                  `${model.name}[${index + 1}.${version}]`, //IncrementName,DeploymentName
                                  itm.new, //Increment_name,Model_name
                                  model.name,
                                  model.supportIncrement,
                                ])}
                              >
                                {model.name}[{index + 1}.{version}]
                              </Option>
                            );
                          });
                        }
                        const value = JSON.stringify([model.name, model.name, model.name, model.supportIncrement]);
                        return (
                          <Option key={model.modelId?.model_name} value={value}>
                            {model.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })
              }
              {!modelList &&
                <OptGroup key={null} label={'Loading...'}>
                </OptGroup>
              }
            </Select>
          ) : (
            incrementName || modelName
          )}
        </span>
        <Hint
          themeStyle={{ fontSize: '1rem' }}
          content={currentModel?.performance}
        />
        <a className={styles.change} onClick={onSaveModel}>
          <Show name={ProjectRolesKey.DeploymentOperation}>
            {store.modelEditing ? EN.Save : EN.Change}
          </Show>
        </a>
        <span className={styles.data}>{EN.DeploymentDataDefinition}</span>
        <Hint
          themeStyle={{ fontSize: '1rem' }}
          content={EN.ValidationDataDefinitionTip}
        />
        <a
          className={styles.download}
          target="_blank"
          href={`/r2upload/dataDefinition?projectId=${projectId}`}
        >
          {EN.Download}
        </a>
      </div>
      {!supportIncrement && (
        <div className={styles.warning}>
          <WarningOutlined />
          {EN.CanNotIncrement}
        </div>
      )}
      {supportIncrement && (
        <>
          <DataSource />
        </>
      )}
      <Show name={ProjectRolesKey.DeploymentOperation}>
        {supportIncrement && incrementOptions.source && (
          <div className={styles.done}>
            <div className={styles.selections}>
              <span className={styles.label}>
                <span className={styles.text} />
              </span>
              <div className={styles.save} onClick={done}>
                <BlackButton className={styles.saveText}>{EN.DONE}</BlackButton>
              </div>
            </div>
          </div>
        )}
      </Show>

      <Modal
        visible={!!store.uploadStatus}
        width={700}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          store.updateField({
            uploadStatus: false,
          });
          store.uploadOperator.pause();
        }}
      >
        <div className={styles.uploading}>
          <Progress
            percent={
              isNaN(store.uploadPercentage)
                ? 0
                : parseFloat(store.uploadPercentage)
            }
          />
          <span className={styles.speed} />
          <span className={styles.pause} onClick={pause}>
            {store.uploadStatus === 'uploading' ? (
              <span>
                <PauseOutlined />
                {EN.Paused}
              </span>
            ) : (
              <span>
                <CaretRightOutlined />
                {EN.Resume}
              </span>
            )}
          </span>
        </div>
        {store.uploadStatus === 'error' && (
          <div className={styles.uploadError}>
            {store.uploadError.toString()}
          </div>
        )}
      </Modal>

      <Modal
        title={EN.IncrementalTraining}
        visible={+store.progress > 0}
        onOk={null}
        centered={true}
        closable={true}
        footer={null}
        maskClosable={incrementResult}
        onCancel={() => {
          if (incrementResult) {
            incrementStore.incrementResult = false;
            store.updateField({
              progress: 0,
            });
          } else {
            abort();
          }
        }}
      >
        {/*{*/}
        {/*	incrementResult||!+store.progress?<div>*/}
        {/*		您当前模型增量训练已完成*/}
        {/*	</div>:*/}
        <Progress
          strokeLinecap="square"
          strokeColor={{
            '0%': '#1e2c3d',
            '100%': '#1e2c3d',
          }}
          percent={store.progress}
        />
        {/*}*/}
      </Modal>

      {/*<Modal*/}
      {/*	title={EN.Error}*/}
      {/*	visible={!!partialStatus}*/}
      {/*	onOk={null}*/}
      {/*	centered={true}*/}
      {/*	closable={true}*/}
      {/*	footer={null}*/}
      {/*	onCancel={()=>{*/}
      {/*		// store.updateField({*/}
      {/*		// 	progress:0*/}
      {/*		// })*/}
      {/*		incrementStore.partialStatus = 0;*/}
      {/*	}}*/}
      {/*>*/}
      {/*	<div>*/}
      {/*		{partialStatus}*/}
      {/*	</div>*/}
      {/*</Modal>*/}

      <DatabaseConfig
        options={incrementOptions.sourceOptions}
        visible={store.dialog === 'databasesource'}
        onClose={closeDialog}
        title={EN.DataSourceDatabase}
        mapHeader={mapHeader}
        projectId={projectId}
        testApi={true}
        onSubmit={action(options => {
          // cddo['file'] = options.sqlTable;
          incrementOptions['source'] = 'database';
          incrementOptions['sourceOptions'] = options;
          // selectionOption('dataBase',options)();
          closeDialog();
          return save();
        })}
        bigData={false}
        connectLocation="increment"
      />
    </div>
  );
}

export default observer(Increment);
