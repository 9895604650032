import React, { ReactElement } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import EN from '../../constant/en';
import { Row, Col } from 'antd';
import styles from './styles.module.css';
import { Background } from 'components/Common';
import SignIn from 'components/User/SignIn';
import classNames from 'classnames';

export default function LoginRouter():ReactElement{
  return (
    <div className={styles.warp}>
      <Background />
      <div className={classNames(styles.loginWrap, 'container')}>
        <Row gutter={16}>
          <h1 className={styles.loginTitle}>
            {EN.AIAIMachineLearningPlatform}
          </h1>
        </Row>
        <Row gutter={16} style={{ flex: 1 }}>
          <Col xs={0} sm={0} md={12} xl={16} xxl={16} />
          <Col xs={24} sm={24} md={12} xl={8} xxl={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Switch>
              <Route path="/" component={(props) => <SignIn {...props} />} />
              <Redirect to="/" />
            </Switch>
          </Col>
        </Row>
      </div>
    </div>
  );
}
