import React, { ReactElement } from 'react';
import ReactEcharts from 'echarts-for-react';
import Project from 'stores/Project';
import _ from 'lodash';

interface Interface {
  message: {
    avg: {
      data: any;
      name: {
        x: string;
        y:string
      };
    };
    freq: {
      data: any;
      name: {
        x: string;
      };
    };
  };
  project: Project;
}

export default function MI3D(props:Interface): ReactElement {
  const {
    message: {
      avg:{
        name: { x, y },
        data:avg_data,
      },
      freq:{
        data:freq_data
      },
    },
    project: { mapHeader, colType, colMap,newVariableViews },
  } = props;

  const avg_freqs:number[] = avg_data.map(itm=>itm.freq);
  const freq_freqs:number[] = freq_data.map(itm=>itm.freq);

  const avg_max = _.max(avg_freqs)*1.01;
  const freq_max = _.max(freq_freqs)*1.01;
  const avg_min = _.min(avg_freqs)-0.01;
  const freq_min = _.min(freq_freqs)-0.01;

  const x_name = mapHeader[x]||x;
  const y_name = mapHeader[y]||y;

  const axis3D = {
    type:'category',
    axisLabel:{
      formatter:value=>{
        if(value.__proto__.toFixed){
          return value.toFixed(6)
        }
        return value;
      }
    },
    nameTextStyle:{
      color:'blue',
      fontSize:12,
    }
  };

  const avg_xAxis3D:any = {
    name:x_name,
    ...axis3D,
  };
  const freq_xAxis3D:any = {
    name:x_name,
    ...axis3D,
  };

  const avg_yAxis3D:any = {
    name:y_name,
    ...axis3D,
  };
  const freq_yAxis3D:any = {
    name:y_name,
    ...axis3D,
  };

  const xc = colType[x] === 'Categorical';
  const yc = colType[y] === 'Categorical';
  const CData = Object.assign({},colMap,newVariableViews);

  if(xc){
    const ent = Object.entries(CData[x]);
    let _data = [];
    avg_data.forEach(itm=>{
      const type = ent.filter(it=>it[1] === itm.x);
      if(type[0]&&!_data.includes(type[0][0])){
        _data.push(type[0][0])
      }
    });
    _data.length&&(avg_xAxis3D.data = _data);

    _data = [];
    freq_data.forEach(itm=>{
      const type = ent.filter(it=>it[1] === itm.x);
      if(type[0]&&!_data.includes(type[0][0])){
        _data.push(type[0][0])
      }
    });
    _data.length&&(freq_xAxis3D.data = _data);
  }

  if(yc){
    const ent = Object.entries(CData[y]);
    let _data = [];
    avg_data.forEach(itm=>{
      const type = ent.filter(it=>it[1] === itm.y);
      if(type[0]&&!_data.includes(type[0][0])){
        _data.push(type[0][0])
      }
    });
    _data.length&&(avg_yAxis3D.data = _data);

    _data = [];
    freq_data.forEach(itm=>{
      const type = ent.filter(it=>it[1] === itm.y);
      if(type[0]&&!_data.includes(type[0][0])){
        _data.push(type[0][0])
      }
    });
    _data.length&&(freq_yAxis3D.data = _data);
  }

  const x_ent = Object.entries(CData[x]);
  const y_ent = Object.entries(CData[y]);

  function ns(n){
    return typeof n === 'number'?n.toFixed(6):n
  }

  function series_data(data){
    return data.map(function (item) {
      let {x,y,data,freq} = item;
      if(xc){
        const type = x_ent.filter(it=>it[1] === x);
        if(type[0]&&type[0][0]){
          x = type[0][0];
        }
      }
      if(yc){
        const type = y_ent.filter(it=>it[1] === y);
        if(type[0]&&type[0][0]){
          y = type[0][0];
        }
      }
      return {
        value: [x,y,data,freq]
      }
    })
  }

  const series = {
    name:'',
    type: 'bar3D',
    shading: 'lambert',
    label: {
      show: false,
      textStyle: {
        fontSize: 16,
        borderWidth: 1
      }
    },
    emphasis: {
      label: {
        show:false,
      },
      itemStyle: {
        color: '#900'
      }
    }
  };
  const visualMap = {
    inRange: {
      color: ['#80bdfd', '#b0e39b']
    },
    formatter:value=>{
      return value.toFixed(3)
    }
  };

  const option:any =  {
    baseOption:{
      tooltip: {
        hideDelay:2000,
        confine:true,
        formatter:tip=>{
          const {marker,value} = tip;
          if(!marker)return;
          const [x,y,val,freq] = value;

          return `
            ${marker}<br/>
            ${x_name}:${ns(x)}<br/>
            ${y_name}:${ns(y)}<br/>
            value:${val.toFixed(6)}<br/>
            freq:${freq.toFixed(6)}<br/>
        `
        }
      },
      zAxis3D: {
        type: 'value',
        name:'',
      },
      grid3D: {
        light: {
          main: {
            quality: 'ultra',
            intensity: 1.5,
          },
        },
      },
    },
    timeline:{
      axisType:'category',
      loop:false,
      data:['Avg','Freq'],
      controlStyle:{
        show:false
      },
      right:'85%',
      left:'5%',
    },
    options:[{
      series:{
        ...series,
        data:series_data(avg_data)
      },
      xAxis3D:avg_xAxis3D,
      yAxis3D:avg_yAxis3D,
      visualMap:{
        ...visualMap,
        min:avg_min,
        max:avg_max,
      }
    },{
      series:{
        ...series,
        data:series_data(freq_data)
      },
      xAxis3D:freq_xAxis3D,
      yAxis3D:freq_yAxis3D,
      visualMap:{
        ...visualMap,
        min:freq_min,
        max:freq_max,
      }
    }]
  };

  return <ReactEcharts
    option={option}
    style={{ height: '100%', width: '100%' }}
    notMerge={true}
    lazyUpdate={true}
    theme="customed"
  />
}
