import React,{ ReactElement } from 'react';
import { observer } from 'mobx-react';

// modules
import { MuiDialog } from './modules/MuiModule';
import NewVariableView from './NewVariableView';

import store from './NewVariableStore';

// types
import { DialogProps } from '@material-ui/core/Dialog';

interface Interface extends DialogProps {
  title?: string;
  variables?;
  mapHeader?;
  expression;
  addNewVariable;
}

function CreateNewVariable(props:Interface):ReactElement{
    const {
      open,
      title,
      onClose,
      variables,
      mapHeader,
      expression,
      addNewVariable,
    } = props;

    return (
      <MuiDialog
        {...{
          open,
          title,
          onClose,
          maxWidth: 'lg',
          fullWidth: true,
        }}
        disableBackdropClick
      >
        <NewVariableView
            onClose={onClose}
            variables={variables}
            mapHeader={mapHeader}
            expression={expression}
            addNewVariable={addNewVariable}
            functions= {store.functions}
        />
      </MuiDialog>
    );
}
export default observer(CreateNewVariable)
