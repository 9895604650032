import {observer, useLocalStore, MobXProviderContext } from 'mobx-react';
import React, { ReactElement, useContext } from 'react';
import styles from '../styles.module.css';
import { Radio, Tooltip, Popconfirm, Button } from "antd";
import EN from '../../../../../constant/en';
import { formatNumber } from '../../../../../util';
import moment from 'moment';
import Variable from '../../Variable.svg';
import Process from '../../Process.svg';
import VariableImpact from '../../VariableImpact';
import classnames from 'classnames'
import Model from 'stores/Model';
import Project from 'stores/Project';
import { ProjectStore } from 'stores/ProjectStore';
import MPF from '../../MPF';
import ModelInterpretation from '../ModelInterpretation'
import { ProjectRolesKey } from '@app/constant/types';
import { Show } from '@app/components/Common';

interface Interface {
  model: Model
  onSelect: (s: string) => void
  isRecommend: boolean
  isSelect: boolean
  mapHeader: any
  project: Project
}

export default observer(ModelDetail)

function ModelDetail(props:Interface):ReactElement{
  // @ts-ignore
  const {
    projectStore: { project: { deleteModelByModelId, exportModelReport } },
  }:{
    projectStore:ProjectStore
  } = useContext(MobXProviderContext);
  const {
    model,
    onSelect,
    isRecommend,
    isSelect,
    mapHeader,
    project,
  } = props;
  const { linearData = false, treeData = false, modelName } = model;
  const _check = linearData || treeData;

  const store = useLocalStore(() => ({
    type: '',
    visible: false,
    updateField(data) {
      Object.assign(this, data);
    },
  }));

  function toggleImpact(type) {
    if (!store.visible) {
      //本来是关着的
      store.updateField({
        type,
        visible:true
      })
      return;
    }
    if (store.type === type) {
      store.updateField({
        visible:false
      })
    } else {
      store.updateField({
        type,
      })
    }
  }

    return (
      <div className={styles.rowBox}>
        <Tooltip
          placement="left"
          title={isRecommend ? EN.Recommended : EN.Selected}
          visible={isSelect || isRecommend}
          overlayClassName={styles.recommendLabel}
          autoAdjustOverflow={false}
          arrowPointAtCenter={true}
          getPopupContainer={el => el.parentElement}
        >
          <div className={styles.rowData}>
            <div id="radio" className={styles.a_radio}>
              <Radio checked={isSelect} onChange={onSelect.bind(this,model)}/>
            </div>
            <div className={classnames(styles.cell, styles.name)}>
              <Tooltip title={model.modelName}>{model.modelName}</Tooltip>
            </div>
            <div className={styles.cell}>
              <span>{formatNumber((model.score.validateScore.Kappa || "null").toString())}</span>
            </div>
            <div className={styles.cell}>
              <span>{formatNumber((model.chartData.roc_auc.macro || "null").toString())}</span>
            </div>
            <div className={styles.cell}>
              <span>{formatNumber((model.executeSpeed || "").toString()) + EN.Rowss}</span>
            </div>
            <div className={styles.cell}>
              <span>
                {model.created_at
                  ? moment(String(model.created_at)).format('YYYY/MM/DD HH:mm')
                  : ''}
              </span>
            </div>
            <div className={classnames(styles.cell, styles.compute)}>
              <img src={Variable} alt="" />
              <span onClick={toggleImpact.bind(this, 'impact')}>
                {EN.Compute}
              </span>
            </div>
            <div className={classnames(styles.cell, styles.compute, _check ? '' : styles.disable)}>
              <i className={classnames(styles.check, _check ? '' : styles.uncheck)} />
              <span onClick={() => _check && toggleImpact('check')}>
                {EN.check}
              </span>
            </div>
            <div className={classnames(styles.cell, styles.compute)}>
              <img src={Process} alt="" />
              <span onClick={toggleImpact.bind(this, 'process')}>
                {EN.Compute}
              </span>
            </div>
            <div className={classnames(styles.cell, styles.compute)}>
              <span onClick={exportModelReport.bind(this, model.id)}>{EN.Export}</span>
            </div>
            <div className={classnames(styles.cell, styles.compute)}>
              <Show name={ProjectRolesKey.ModelEdit}>
                <Popconfirm
                  title={`${EN.ConfirmDeletion}该模型`}
                  onConfirm={deleteModelByModelId.bind(this, model.id)}
                  okText={EN.confirm}
                  cancelText={EN.Cancel}
                >
                  <span >{EN.Delete}</span>
                </Popconfirm>
              </Show>
            </div>
          </div>
        </Tooltip>
        {store.visible && store.type === 'impact' && (
          <VariableImpact model={model} mapHeader={mapHeader} />
        )}
        {store.visible && store.type === 'process' && (
          <MPF project={project} model={model} modelId={model.id} />
        )}
        {store.visible && store.type === 'check' && <ModelInterpretation treeData={treeData} linearData={linearData} modelName={modelName} />}
      </div>
    );
}
