import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';
import styles from './list.module.css';
import emptyIcon from './icon-no-report.svg';
import EN from '../../../constant/en';
import SchedulePerformance from './SchedulePerformance';

function Status(){
  const {
    deploymentStore,
    scheduleStore,
  } = useContext(MobXProviderContext)

  // const selectionOption = (key, value) => () => {
  //   currentDeployment.performanceOptions[
  //     key
  //   ] = value;
  //   return currentDeployment.save();
  // };
    const cd = deploymentStore.currentDeployment;
    const cdpo = cd.performanceOptions;
    return (
      <div className={styles.status}>
        <div className={styles.info} style={{
          fontSize:'1rem',
          padding: '1.2em',
          borderBottom: '1px dashed #d7d7d7',
        }}>
            <span>
            {EN.CurrentModel}：{cd.modelName}
            </span>
        </div>

        <div className={styles.list}>
          {scheduleStore.sortedPerformanceSchedules.length === 0 && <Empty />}
          {scheduleStore.sortedPerformanceSchedules.length > 0 && (
            <div className={styles.table}>
              <div className={styles.head}>
                <span className={styles.modelName}>{EN.ModelName}</span>
                <span className={styles.modelInvokeTime}>
                  {EN.ModelInvokeTime}
                </span>
                <span className={styles.deploymentStyle}>
                  {EN.DeploymentStyle}
                </span>
                {/* {!cd.bigData&&<span className={styles.executionSpeed}>
                  {EN.ExecutionSpeed} <small>{EN.Rowss}</small>
                </span>} */}
                <span className={styles.executionSpeed}>
                  {EN.ExecutionSpeed} <small>{EN.Rowss}</small>
                </span>
                {/*{(cd.modelType === 'Clustering'||!cd.bigData) && <span className={styles.performance}>{EN.Performance}</span>}*/}
                
                {/* TODO python performance 是否展示,如何展示 */}
                <span className={styles.performance}>{EN.Performance}</span>
                  {/* {!cd.bigData && <span className={styles.threshold}>{EN.Threshold}</span>} */}
                <span className={styles.threshold}>{EN.Threshold}</span>
                <span className={styles.status_}>{EN.Status}</span>
                <span className={styles.results}>{EN.Results}</span>
                <span className={styles.results}>{EN.DeploySummary}</span>
              </div>
              <div className={styles.list}>
                {scheduleStore.sortedPerformanceSchedules.map(s => <SchedulePerformance schedule={s.schedule} deployment={cd} cdpo={cdpo} key={s.schedule.id} />)}
              </div>
            </div>
          )}
        </div>
      </div>
    );

}

const Empty = () => (
  <div className={styles.emptyTable}>
    <img src={emptyIcon} className={styles.emptyIcon} alt={EN.Empty} />
    <span className={styles.emptyText}>{EN.NoDeploymentReportYet}</span>
  </div>
);


export default observer(Status)


