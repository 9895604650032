import React, { ReactElement, useEffect, useContext, useState } from 'react';
import styles from './styles.module.css';
import { MobXProviderContext, observer } from 'mobx-react';
import { LoadingOutlined } from '@ant-design/icons';
import { ProgressBar } from 'components/Common';
import EN from '../../../constant/en';
import { ProjectStore } from 'stores/ProjectStore';
import { ProgressStore } from 'stores/ProgressStore';

const Loading = observer(
  (): ReactElement => {
    // @ts-ignore
   const {
      projectStore: {
        project: { 
          trainModel, 
          isAbort, 
          abortTrainByEtl, 
          train2Finished,
          stopIds
        },
      },
      progressStore: {
        progresses,
        getPrejectTrainProgresses
      },
    } : {
        projectStore:ProjectStore,
        progressStore:ProgressStore
    } = useContext(MobXProviderContext);

    useEffect(() => {
      getPrejectTrainProgresses(stopIds)
    }, []);

    const [allStopIds, setAllStopIds] = useState({});

    useEffect(() => {
      let _stopIds = {};
      stopIds.map(x => {
        if (progresses[x])
          Object.assign(_stopIds,{[x]: progresses[x]})
      });
      setAllStopIds(_stopIds);
    }, [stopIds, progresses]);


    const curModel: any = Object.values(allStopIds).sort(
      (a: any, b: any) => (b.value || 0) - (a.value || 0),
    )[0];

    const progress = train2Finished ? 100 : (curModel || {}).value || 0

    return (
      <div className={styles.loading}>
        <div className={styles.training}>
          <ProgressBar progress={progress} />
        </div>
        <div className={styles.trainingText}>
          <span>{EN.TrainingS}</span>
        </div>
        {
          <div className={styles.trainingAbort}>
            <div
              className={styles.abortButton}
              onClick={() => abortTrainByEtl()}
            >
              {isAbort ? (
                <span>
                  <LoadingOutlined />
                </span>
              ) : (
                <span>{EN.AbortTraining}</span>
              )}
            </div>
          </div>
        }
      </div>
    );
  },
);
export default Loading;
