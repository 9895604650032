import React, { useState, useContext, ReactElement } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import VariableIssue from './VariableIssue';
import TargetIssue from './TargetIssue';
import TimeSeriesIssue from './TimeSeriesIssue';

function DataQuality(): ReactElement {
  const { projectStore: { project } } = useContext(MobXProviderContext);

  const [step, upStep] = useState(project.etling ? 2 : 1);
  const changeTab = value => {
    upStep(value);
  };
  const isTimeSeries = ['Forecasting', 'Survival', 'Prediction'].includes(project.problemType)
  if (isTimeSeries)
    return <TimeSeriesIssue project={project} />

  if (!project.target)
    return (
      <VariableIssue project={project} changeTab={changeTab.bind(null, 1)} />
    );
  return step !== 1 ? (
    <VariableIssue project={project} changeTab={changeTab.bind(null, 1)} />
  ) : (
      <TargetIssue project={project} changeTab={changeTab.bind(null, 2)} />
    );
}

export default observer(DataQuality);
