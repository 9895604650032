import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { observer, inject, MobXProviderContext } from 'mobx-react';
import { Radio, Spin } from 'antd';
import { action } from 'mobx';
import { RadioChangeEvent } from 'antd/lib/radio';
import EN from '../../constant/en';
import { ProjectStore } from 'stores/ProjectStore';
import {
  Show,
  Hint,
  ContinueButton,
  Confirm,
  ProcessLoading,
} from 'components/Common';
import { UserStore } from 'stores/UserStore';
import { ProjectRolesKey } from '@app/constant/types';

const RadioGroup = Radio.Group;

// interface ProblemProps {
//   projectStore: ProjectStore;
//   userStore: UserStore
// }

interface ProblemState {
  visiable: boolean;
  loading: boolean;
}

const initState: ProblemState = {
  visiable: false,
  loading: false,
};

function Problem() {
  // @ts-ignore
  const {
    projectStore:{
      project
    },
    userStore
  }:{
    projectStore:ProjectStore
    userStore:UserStore
  } = useContext(MobXProviderContext)

  const [loading, setLoading] = useState(true);
  const [isBigData, setIsBigData] = useState(project.bigData);
  const [plans, setPlans] = useState({
    SL: true,
    UL: false,
    SA: false,
  });

  useEffect(() => {
    userStore.getPlans().then(plan => {
      setPlans && setPlans(plan);
      setLoading(false);
    });
  }, []);

  const { changeProjectType, fileName, problemType, saveProblem } = project;

  const Unsupervised = [
    { value: 'Clustering', type: EN.Clustering, detail: EN.ClusteringHint },
    {
      value: 'Outlier',
      type: EN.Outlier,
      detail: EN.OutlierDetectionHint,
    },
    {
      value: 'Association',
      type: EN.Association,
      detail: EN.AssociationHint,
    },
  ];
  const selectable = [
    {
      value: 'Classification',
      type: EN.TrueorFalseBinaryClassification,
      detail: <p>{EN.Topredictifaneventislikely}</p>,
    },
    {
      value: 'Regression',
      type: EN.ContinuousValuesRegression,
      detail: <p>{EN.Topredictacontinuous}</p>,
    },
    {
      value: 'MultiClassification',
      type: EN.MultiClassification,
      detail: <p>{EN.MultiClassificationHint}</p>,
    },
  ];

  const TimeSeries = [
    { value: 'Forecasting', type: EN.Forecasting, detail: EN.ForecastingHint },
    // {
    //   value: 'Survival',
    //   type: EN.Survival,
    //   detail: EN.SurvivalHint,
    // },
    // {
    //   value: 'Prediction',
    //   type: EN.Prediction,
    //   detail: EN.PredictionHint,
    // },
  ];

  const [state, setState] = React.useState(initState);

  const onChange = action((type: string) => (e: RadioChangeEvent) => {
    project.setProperty({
      [type]: e.target.value,
    });
  });

  const nextStep = () => {
    if (!!fileName && ((problemType && problemType !== changeProjectType) || project.bigData !== isBigData))
      return setState({
        ...state,
        visiable: true,
      });
    onConfirm();
  };

  const onClose = () => {
    setState({
      ...state,
      visiable: false,
    });
    setLoading(false);
  };

  const onConfirm = async () => {
    setState({
      ...state,
      visiable: false,
    });
    setLoading(true);

    let force = false;
    if (!!fileName && ((problemType && problemType !== changeProjectType) || project.bigData !== isBigData)) force = true;
    project.bigData = isBigData;
    
    await saveProblem(force);
    setLoading(false);
  };

  const handleBigData = (e) => {
    if (!isBigData && (changeProjectType === 'Association' || changeProjectType === 'Forecasting' || changeProjectType === 'MultiClassification'
      || changeProjectType === 'Classification' || changeProjectType === 'Regression')) project.changeProjectType = '';
    setIsBigData(e.target.value);
  };

  // if (loading) return <ProcessLoading style={{ position: 'fixed' }}/>;
  return (
    <Spin tip={EN.Loading} size="large" spinning={loading}>
      <div className={styles.problem}>
        <div className={styles.title}>
          <span>{EN.ChooseProblemType}</span>
        </div>

        {(window as any).r2_env.useSpark &&
        <div className={styles.checkBox}>
          {/*<Checkbox id="isBigData" checked={isBigData} onChange={handleBigData}>{EN.UseBigData}</Checkbox>*/}


          <Radio.Group onChange={handleBigData} value={isBigData}>
            <Radio value={false}>{'Python'}</Radio>
            <Radio value={true}>{EN.UseBigData}</Radio>
          </Radio.Group>


          {/*<input*/}
          {/*  type="checkbox"*/}
          {/*  id="isBigData"*/}
          {/*  onChange={handleBigData}*/}
          {/*  checked={isBigData}*/}
          {/*/>*/}
          {/*<label htmlFor="isBigData">{EN.UseBigData}</label>*/}
        </div>
        }

        {plans.SL && <div className={styles.radioBox}>
          <div className={styles.text}>
            <span>{EN.Predictions}</span>
          </div>
          <RadioGroup
            className={styles.radio}
            value={changeProjectType}
            onChange={onChange('changeProjectType')}
          >
            {selectable.map((content, index) => {
              if (isBigData && content.value === 'MultiClassification') {
                return '';
              }
              return (
                <Radio key={index} value={content.value} >
                  {content.type}
                  <Hint content={content.detail} placement="right" />
                </Radio>
              )})}
          </RadioGroup>
        </div>}
        {plans.UL && <div className={styles.radioBox}>
          <div className={styles.text}>
            <span>{EN.UnsupervisedLearning}</span>
          </div>
          <RadioGroup
            className={styles.radio}
            value={changeProjectType}
            onChange={onChange('changeProjectType')}
          >
            {Unsupervised.map((content, index) => (
              isBigData && content.value === 'Association' ?
                null : <Radio key={index} value={content.value}>
                  {content.type}
                  {!!content.detail && (
                    <Hint content={content.detail} placement="right"/>
                  )}
                </Radio>
            ))}
          </RadioGroup>
        </div>}
        {plans.SA && <div className={styles.radioBox}>
          {
            isBigData ? null : <div className={styles.text}>
              <span>{EN.TimeSeries}</span>
            </div>
          }
          <RadioGroup
            className={styles.radio}
            value={changeProjectType}
            onChange={onChange('changeProjectType')}
          >
            {TimeSeries.map((content, index) => (
              isBigData ? null :
                <Radio key={index} value={content.value} disabled={isBigData}>
                  {content.type}
                  {!!content.detail && (
                    <Hint content={content.detail} placement="right"/>
                  )}
                </Radio>
            ))}
          </RadioGroup>
        </div>}
        <Show name={ProjectRolesKey.ProblemContinue}>
          <div className={styles.radio}>
            <ContinueButton
              onClick={nextStep}
              disabled={!changeProjectType}
              text={EN.Continue}
              width={null}
            />

          </div>
        </Show>
          <Confirm
            width={'6em'}
            visible={state.visiable}
            title={EN.Warning}
            content={EN.Thisactionmaywipeoutallofyourprevious}
            onClose={onClose}
            onConfirm={onConfirm}
            confirmText={EN.Continue}
            closeText={EN.CANCEL}
            loading={state.loading}
          />
      </div>
    </Spin>
  );
}

export default  inject('projectStore', 'userStore')(observer(Problem));
