import React, { Component } from 'react';
import styles from '../styles.module.css';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { NumberInput } from 'components/Common';
import { observable } from 'mobx'
import { Select } from 'antd'
import { formatNumber } from '../../../../util'
import EN from '../../../../constant/en';
import Project from 'stores/Project';
const { Option } = Select;

interface TimeSeriesFixIssueProps {
  saveDataFixes: () => void,
  closeFixes: () => void,
  project: Project,
  isTarget: boolean,
  nullCount: number,
  mismatchCount: number,
  outlierCount: number,
  issueRow: { mismatchRow: NumberObject, nullRow: NumberObject, outlierRow: NumberObject }
}

class TimeSeriesFixIssue extends Component<TimeSeriesFixIssueProps> {
  @observable fillMethod = { ...this.props.project.nullFillMethodTemp };
  @observable checked = []
  @observable orderNum = { ...this.props.project.orderNum }

  fixSelect = (key) => (e) => {
    let value = e.target.value
    value = isNaN(+(value)) ? value : parseFloat(value)
    Object.assign(this.fillMethod, {
      [key]: value === 'others' ? '' : value
    })
  }

  save = () => {
    const { project } = this.props
    const realFillMethod: { [key: string]: string } = {}
    Object.keys(this.fillMethod).forEach(field => {
      const value = this.fillMethod[field]
      if (!!value) realFillMethod[field] = value
    })
    project.nullFillMethodTemp = { ...realFillMethod }
    project.mismatchFillMethodTemp = { ...realFillMethod }
    project.orderNum = { ...this.orderNum }
    this.props.saveDataFixes()
  }

  formatCell = num => {
    if (typeof num === "number") return formatNumber(num.toString(), 2, true)
    if (typeof num === "string") return num
    return "N/A"
  }

  handleInput = (field, value) => {
    this.orderNum[field] = value
  }

  handleCheckAll = (e) => {
    const checked = e.target.checked
    if (!checked) return this.checked = []
    const { issueRow: { mismatchRow, nullRow } } = this.props
    this.checked = [...new Set([...Object.keys(mismatchRow).filter((k) => !!mismatchRow[k]), ...Object.keys(nullRow).filter((k) => !!nullRow[k])])]
  }

  handleCheck = (field) => (e) => {
    const checked = e.target.checked
    let arr = this.checked
    if (!checked && arr.includes(field)) arr = arr.filter(h => h !== field)
    if (checked && !arr.includes(field)) arr.push(field)
    this.checked = arr
  }

  handleReset = () => {
    const { issueRow: { mismatchRow, nullRow } } = this.props

    const keys = [...new Set([...Object.keys(mismatchRow).filter((k) => !!mismatchRow[k]), ...Object.keys(nullRow).filter((k) => !!nullRow[k])])]

    keys.forEach(k => {
      this.fillMethod[k] = 'delete'
    })
  }

  handleSelect = (value) => {
    const fillmethod = this.checked.reduce((prev, k) => {
      prev[k] = value
      return prev
    }, {})
    this.fillMethod = fillmethod
  }

  render() {
    const { closeFixes, project, nullCount, mismatchCount, issueRow: { mismatchRow, nullRow } } = this.props;
    const { mapHeader, colType, rawDataView, nullLineCounts, mismatchLineCounts } = project

    const keys = [...new Set([...Object.keys(mismatchRow).filter((k) => !!mismatchRow[k]), ...Object.keys(nullRow).filter((k) => !!nullRow[k])])]
    const keysHasDate = keys.some(k => colType[k] === 'Datetime')

    const timeArray = [{
      value: 'last',
      label: EN.Replacewithlast
    }, {
      value: 'drop',
      label: EN.Deletetherows
    }, {
      value: 'mean',
      label: EN.Replacewithmeanvalue
    }, {
      value: 'median',
      label: EN.Replacewithmedianvalue
    }, {
      value: 'linear',
      label: EN.Replacewithlinear
    }, {
      value: 'polynomial',
      label: EN.Replacewithpolynomial
    }, {
      value: 'spline',
      label: EN.Replacewithspline
    }]

    return <div className={styles.fixesContent}>
      <div className={styles.fixesBlock}>
        {(!!mismatchCount || !!nullCount) && <div className={styles.fixesArea}>
          <div className={styles.typeBox}>
            <div className={styles.type}>
              <div className={classnames(styles.typeBlock, styles.mismatch)} />
              <span>{EN.DataTypeMismatch}</span>
            </div>
            {keys.length > 1 && <div className={styles.batch}>
              <Select placeholder={EN.BatchFix} value={undefined} onSelect={this.handleSelect} className={styles.batchSelect} >
                {timeArray.map(item => {
                  if (keysHasDate && item.value === 'mean') return null
                  if (keysHasDate && item.value === 'median') return null
                  return <Option value={item.value} key={item.value}>{item.label}</Option>
                })}
              </Select>
            </div>}
          </div>
          <div className={styles.fixesTable}>
            <div className={classnames(styles.fixesRow, styles.fixesHeader)}>
              {keys.length > 1 && <div className={styles.fixedCheck}><input type="checkbox" checked={this.checked.length === keys.length} onChange={this.handleCheckAll} /></div>}
              <div className={classnames(styles.fixesTd, styles.fixesLarge)}><span>{EN.VariableName}</span></div>
              <div className={styles.fixesTd}><span>{EN.DataType}</span></div>
              <div className={styles.fixesTd}><span>{EN.QuantityofMismatch}</span></div>
              <div className={styles.fixesTd}><span>{EN.Mean}</span></div>
              <div className={styles.fixesTd}><span>{EN.Median}</span></div>
              <div className={styles.fixesTd}><span>{EN.MostFrequentValue}</span></div>
              <div className={classnames(styles.fixesTd, styles.fixesLarge)}>
                <span>{EN.Fix}</span>
                <span className={styles.reset} onClick={this.handleReset}>{EN.Reset}</span>
              </div>
            </div>
            <div className={styles.fixesBody}>
              {keys.map((k, i) => {
                const percnet = mismatchRow[k] + nullRow[k]
                if (!percnet) return null
                const showType = colType[k] === 'Raw' ? 'Categorical' : colType[k]
                if (showType === 'Categorical') return null
                const isDate = showType === 'Datetime'
                const num = mismatchLineCounts[k] + nullLineCounts[k]
                const rowText = num + ' (' + (percnet === 0 ? 0 : percnet < 0.01 ? '<0.01' : formatNumber(percnet.toString(), 2)) + '%)'
                const mode = !rawDataView ? 'N/A' : (showType === 'Numerical' ? 'N/A' : rawDataView[k].mode)
                const mean = !rawDataView ? 'N/A' : (showType === 'Numerical' ? rawDataView[k].mean : 'N/A')
                const median = !rawDataView ? 'N/A' : (showType === 'Numerical' ? rawDataView[k].median : 'N/A')
                const method = this.fillMethod.hasOwnProperty(k) ?
                  this.fillMethod[k] : 'mean'
                const isOthers = ['polynomial', 'spline'].includes(method)
                return <div className={styles.fixesRow} key={i}>
                  {Object.keys(mismatchRow).length > 1 && <div className={styles.fixedCheck}><input type="checkbox" checked={this.checked.includes(k)} onChange={this.handleCheck(k)} /></div>}
                  <div className={classnames(styles.fixesCell, styles.fixesLarge)}><span title={mapHeader[k]}>{mapHeader[k]}</span></div>
                  <div className={styles.fixesCell}><span>{EN[showType]}</span></div>
                  <div className={styles.fixesCell}><span title={rowText}>{rowText}</span></div>
                  <div className={styles.fixesCell}><span title={this.formatCell(mean)}>{this.formatCell(mean)}</span></div>
                  <div className={styles.fixesCell}><span title={this.formatCell(median)}>{this.formatCell(median)}</span></div>
                  <div className={styles.fixesCell}><span title={this.formatCell(mode)}>{this.formatCell(mode)}</span></div>
                  <div className={classnames(styles.fixesCell, styles.fixesLarge)}>
                    <select value={method} onChange={this.fixSelect(k)}>
                      {timeArray.map(item => {
                        if (isDate && item.value === 'mean') return null
                        if (isDate && item.value === 'median') return null
                        return <option value={item.value} key={item.value}>{item.label}</option>
                      })}
                    </select>
                    {isOthers && <NumberInput min={1} max={5} isInt={true} value={this.orderNum[k] || (method === 'polynomial' ? 2 : 1)} onBlur={this.handleInput.bind(null, k)} />}
                  </div>
                </div>
              })}
            </div>
          </div>
        </div>}
      </div>
      <div className={styles.fixesBottom}>
        <button className={styles.save} onClick={this.save} ><span>{EN.Save}</span></button>
        <button className={styles.cancel} onClick={closeFixes}><span>{EN.CANCEL}</span></button>
      </div>
    </div>
  }
}

export default observer(TimeSeriesFixIssue)
