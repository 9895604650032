import React from 'react'
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash'

export default function CF(props){
	const {
		title,
		x,
		cf,
		upper,
		lower,
	} = props;

	const option:any = {
		title: {
			text: title,
			subtext: ''
		},
		boundaryGap: ['20%', '20%'],
		xAxis: {},
		yAxis: {
			type: 'value'
		},
		series: [{
			data: _.zip(x,upper).filter(itm=>itm[1]),
			type: 'line',
			areaStyle: {
				color:'#b4e7cc'
			},
			symbolSize:0,
		},{
			data: _.zip(x,lower).filter(itm=>itm[1]),
			type: 'line',
			areaStyle: {
				color:'#84e7cc'
			},
			symbolSize:0,
		},{
			symbolSize: 10,
			data: _.zip(x,cf),
			type: 'scatter',
			itemStyle:{
				color:'#1f77b4'
			}
		},{
			type:'bar',
			data: _.zip(x,cf),
			barWidth:3,
			itemStyle:{
				color:'#aaa'
			}
		}],
	};

	return <ReactEcharts
		option={option}
		style={{height: 350, width: 500}}
		notMerge={true}
		lazyUpdate={true}
		theme="customed"
	/>
}
