import React, { useContext } from 'react';
import { inject, observer, MobXProviderContext } from 'mobx-react';
import {
  LogoutOutlined,
  SettingOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import styles from './styles.module.css';
import mockAvatar from 'components/Layout/Sider/mr-one-copy.svg';
import notificationIcon from './notification.svg';
import down from './combined-shape-copy.svg';
import EN from '../../../constant/en';
import ChangePasswdModal from './ChangePasswdModal';

const WelcomeHeader: React.FC = observer(() => {
  const { userStore } = useContext(MobXProviderContext);

  const menu = (
    <Menu className={styles.logout}>
      {/* <Menu.Item key="0">
          <a onClick={this.changepassword}><Icon type='unlock' />{EN.ChangePassword}</a>
        </Menu.Item> */}
      <Menu.Item key="1">
        <a onClick={() => userStore.logout()}>
          <LogoutOutlined />
          {EN.LogOut}
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a onClick={() => userStore.goManagement()}>
          <SettingOutlined />
          {EN.Management}
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a
          onClick={() => {
            userStore.changePasswdModalVisible(true);
          }}
        >
          <KeyOutlined />
          {EN.ChangePasswd}
        </a>
      </Menu.Item>
    </Menu>
  );
  const { from } = userStore.info;

  return (
    <div className={styles.header}>
      <ChangePasswdModal />
      <div className={styles.wheader}>
        <img src={mockAvatar} alt="avatar" className={styles.wavatar} />
        <span className={styles.welcome}>
          {EN.Welcome}, {userStore.info.email.split('@')[0]}
        </span>
        {!from && (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
            <div className={styles.down}>
              <img src={down} alt="down" />
            </div>
          </Dropdown>
        )}
      </div>
      <div className={styles.notification}>
        <img src={notificationIcon} alt="notification" />
        <span>
          <span className={styles.num}>1</span> {EN.Notification}
        </span>
        <div className={styles.pot} />
      </div>
    </div>
  );
});

export default WelcomeHeader;
