import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip'

interface HintProps extends Omit<TooltipPropsWithTitle, 'title'> {
  content: React.ReactNode | (() => React.ReactNode),
  themeStyle?: React.CSSProperties,
}

export const Hint: React.FC<HintProps> = ({ content, themeStyle, ...props }) => {
  return (
    <Tooltip title={content} {...props}>
      <QuestionCircleOutlined style={{ cursor: 'pointer', marginLeft: 8, ...themeStyle }} />
    </Tooltip>
  )
}

export default Hint;
