import React, { useContext } from 'react';
import { MobXProviderContext, observer, useLocalStore } from 'mobx-react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import EN from '../../../constant/en';
import {
  Checkbox,
  Card,
  Button,
  Input,
  Form,
  Tag,
} from 'antd';
import ChangePasswdModal from '../../Layout/Header/ChangePasswdModal';
import { LoginErrorCode } from '@app/constant/common';

// function showConfirm(props, email, password) {
//   const { userStore } = useContext(MobXProviderContext);

//   confirm({
//     width: 400,
//     icon: null,
//     content: header(props),
//     okText: EN.YES,
//     cancelText: EN.NO,
//     onOk() {
//       userStore.isCheck ? localStorage.setItem('checked', String(true)) : null;
//       userStore.change('isWatchVideo')(true);

//       userStore.status = 'login';
//       userStore.getStatus();

//       userStore.login({ email, password });
//       props &&
//         props.history.push({
//           pathname: '/support',
//           state: {
//             key: 'loginTo',
//           },
//         });
//       userStore.change('tabKey')('2');
//     },
//     onCancel() {
//       userStore.isCheck ? localStorage.setItem('checked', String(true)) : null;
//       userStore.change('isWatchVideo')(false);

//       userStore.status = 'login';
//       userStore.getStatus();

//       userStore.login({ email, password });
//     },
//   });
// }

const getErrorMsg = (code: LoginErrorCode): string | undefined => {
  switch (code) {
    case LoginErrorCode.Disabled:
      return EN.UserDisabled;
    case LoginErrorCode.InvalidLoginDate:
      return EN.InvalidLoginDate;
    case LoginErrorCode.Invalid:
      return EN.UserInvalid;
    case LoginErrorCode.PasswordError:
      return EN.PasswordError;
    case LoginErrorCode.UserNotFound:
      return EN.UserNotFound;
    default:
      return undefined;
  }
}
const SignIn: React.FC = observer(props => {
  const { userStore } = useContext(MobXProviderContext);

  const { _remember = false, _email = '', _password = '' } = JSON.parse(
    localStorage.password || '{}',
  );

  if ((window as any)?.r2_env.cas) {
    // location.href = '/cas/login';
    return null;
  }
  const [form] = Form.useForm();
  const email = form.getFieldValue('email');
  const store = useLocalStore(() => ({
    email: _email,
    password: _password,
    warning: {
      email: '',
      password: '',
    },
    loading: false,
    loginHintMsg: '',
    updateField(data) {
      Object.assign(this, data);
    },
  }));
  const login = (values: any, first = true) => {
    const { email, password, remember } = values;
    store.updateField({
      loading: true,
    });
    userStore.login({ email, password }).then((res: any) => {
      const { status, errorCode, message, info } = res.data;
      store.updateField({
        loading: false,
      });
      if (status === 200) {
        userStore.status = 'login';
        userStore.getStatus();
        userStore.info = info;
        if (remember) {
          localStorage.password = JSON.stringify({
            _remember: true,
            _email: email,
            _password: password,
          });
        } else {
          localStorage.password = '{}';
        }
      } else if (errorCode === LoginErrorCode.PasswordExpired) {
        store.updateField({
          loginHintMsg: '密码已过期，请修改密码',
        });
        userStore.changePasswdModalVisible(true);
      } else if (getErrorMsg(errorCode)) {
        store.updateField({
          loginHintMsg: getErrorMsg(errorCode),
        });
      } else if (first) {
        store.updateField({
          loading: true,
        });
        setTimeout(() => {
          login(values, false);
        }, 2000);
      } else {
        store.updateField({
          loginHintMsg: EN[message] || message || 'Login failure',
        });
        alert(EN[message] || message || 'Login failure');
      }
    });
  };
  const handleOnFormChange = () => {
    store.updateField({
      loginHintMsg: '',
    });
  }
  return (
    <Card
      className={styles.signin}
      bordered={false}
      title={EN.SignLogIn}
      style={{ width: '100%' }}
    >
      <Form
        initialValues={{ remember: true }}
        onFinish={login}
        className={styles.signinForm}
        form={form}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: EN.Enteryouremail },
            { type: 'email', message: EN.Enteravaildemial },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            onChange={handleOnFormChange}
            placeholder={EN.EmailAddress}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: EN.EnterNewPassword }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            onChange={handleOnFormChange}
            placeholder={EN.Enteryourpassword}
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox className={styles.remember}>
              {EN.RememberMyPassword}
            </Checkbox>
          </Form.Item>
          {store.loginHintMsg && (
            <Tag
              color="red"
              style={{ float: 'right' }}
              onClick={() => userStore.changePasswdModalVisible(true)}
            >
              {store.loginHintMsg}
            </Tag>
          )}
          {/* <Link
          to='/forgetpassword'
          className="login-form-forgot"
          style={{ float: 'right' }}
        >
          {EN.ForgetPassword}?
        </Link> */}
        </Form.Item>
        <Form.Item noStyle>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block
            shape="round"
            loading={store.loading}
            size="large"
          >
            {EN.SignIn}
          </Button>
        </Form.Item>
        <ChangePasswdModal email={email} title={store.loginHintMsg}/>
      </Form>
    </Card>
  );
});

export default SignIn;
