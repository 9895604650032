import React, { ReactElement } from 'react';
import styles from './styles.module.css';
import Next from './Next.svg';
import { DownOutlined } from '@ant-design/icons';
import { Popover, Button } from 'antd';
import { formatNumber } from '../../../util';
import EN from '../../../constant/en';
import Project from 'stores/Project';
import Model from 'stores/Model';

interface Interface {
  readonly project: Project;
  readonly model: Model;
  readonly mismatchArray: ReadonlyArray<Object>;
}

export default function MPF_TIME(props: Interface): ReactElement {
  const {
    model: { dataFlow },
    project: {
      mismatchFillMethod,
      mismatchLineCounts,
      nullLineCounts,
      target,
      mapHeader,
      orderIndex
    },
    mismatchArray,
  } = props;

  function list(data) {
    const _data = Object.entries(data).filter(
      itm => itm[0].toString().toUpperCase() !== 'MODEL_NAME',
    );
    if (_data.length) {
      return (
        <>
          {_data.map((itm, index) => {
            const key = itm[0];
            let value = itm[1];
            if (typeof value === 'number') {
              value = formatNumber(String(value));
            }
            return (
              <dd key={index}>
                {'' + key}:{'' + value}
              </dd>
            );
          })}
        </>
      );
    }
  }

  function Third(data) {
    return <dl>{list(data)}</dl>;
  }

  function popOver(content, text) {
    return (
      <Popover
        overlayClassName={styles.popover}
        arrowPointAtCenter={true}
        autoAdjustOverflow={false}
        getPopupContainer={el => el.parentElement}
        placement="bottom"
        content={content}
        trigger="click"
      >
        <Button>
          {text}
          <DownOutlined />
        </Button>
      </Popover>
    );
  }

  function DQF() {
    const fields = [target, ...orderIndex]
    const mfm = fields.filter(f => !!(mismatchLineCounts[f] + nullLineCounts[f])).reduce((prev, k) => Object.assign(prev, { [k]: mismatchFillMethod[k] }), {})

    const mi = DQFData(mfm, EN.mismatch);

    if (!mi) {
      return (
        <dl>
          <dd>{EN.none}</dd>
        </dl>
      );
    }

    return (
      <dl className={styles.over}>
        {mi}
      </dl>
    );
  }

  function DQFData(data, title) {
    const values = Object.entries(data);

    const result: any = mismatchArray.map((itm: any) => ({
      type: itm.value,
      key: itm.label,
      data: [],
    }));
    values.forEach(itm => {
      result.find(it => it.type === itm[1]).data.push(itm[0])
    });

    const res = result.filter(itm => itm.data && itm.data.length);

    if (!res.length) {
      return null;
    }
    return (
      <>
        <dt>{title}</dt>
        {res.map((itm, ind) => {
          return (
            <dd key={ind}>
              <label>{itm.key}:</label>
              <ul>
                {itm.data.map((it, ind) => (
                  <li key={ind} title={mapHeader[it]}>
                    {mapHeader[it]}
                  </li>
                ))}
              </ul>
            </dd>
          );
        })}
      </>
    );
  }

  return (
    <section className={styles.process}>
      <label>{EN.RawData}</label>
      <img src={Next} alt="" />
      {popOver(DQF(), EN.DataQualityFixing)}
      <img src={Next} alt="" />
      {popOver(Third(dataFlow[0]), dataFlow[0].model_name)}
      <img src={Next} alt="" />
      <label>{EN.Prediction}</label>
    </section>
  );
}
