import React, { ReactElement } from 'react';
import classnames from 'classnames';
import { Modal, ModalProps, Col, Row, Card, Popover, Table } from 'antd';
import _ from 'lodash';
import { ExplainViewType, DescribeMap } from './type';
import styles from './FunctionDescribeModel.module.less';

const { Column } = Table;

interface FunctionDescribeProps extends ModalProps {
  type: ExplainViewType;
}

const exampleData = [
  { key: 'email_id', label: '邮件发送的ID，数据中的唯一键' },
  { key: 'email_text', label: '邮件的文本形式；其中包括长文本和短文本' },
  { key: 'email_version', label: '邮件的版本格式，其中包括个性化邮件和通用邮件' },
  { key: 'hour', label: '邮件发送客户的时间' },
  { key: 'weekday', label: '邮件发送客户的周工作日' },
  { key: 'user_country', label: '客户接收邮件时所在国家' },
  { key: 'user_past_purchase', label: '接收邮件的客户群体过去历史购买产品次数' },
  { key: 'clicked', label: '用户是否点击了邮件的内部链接，目标变量' },
]

export const FunctionDescribeModel: React.FC<FunctionDescribeProps> = ({
  type,
  ...props
}) => {

  const describe = DescribeMap[type];
  const exampleBackground = (
    <div>
      <div style={{ marginBottom: 8, fontWeight: 'bolder' }}>假设公司现在通过发送邮件的方式触达客户，邮件内部有公司最新推广的内容链接；业务方希望通过调整当前策略来提升客户收到邮件后的内部链接点击率</div>
      <div style={{ marginBottom: 8 }}>现阶段数据案例中提供以下数据作为使用参考：</div>
      <Table
        dataSource={exampleData}
        pagination={false}
        size='small'
        showHeader={false}
        bordered={false}
        className={styles.table}
        rowClassName={(_, index) =>  classnames(styles.tableRow, { [styles.highlight]: index % 2 === 0 })}
      >
        <Column title={null} dataIndex="key" key="key" />
        <Column title={null} dataIndex="label" key="label" />
      </Table>
    </div>
  )

  return (
    <Modal
      title='方法解释'
      footer={null}
      width={'80vw'}
      {...props}
    >
      <Popover overlayStyle={{ maxWidth: '60vw' }} placement="rightTop" content={exampleBackground} trigger='click'>
        <div className={styles.exampleBackground}>
          案例背景
        </div>
      </Popover>

      {describe &&
        <div className={classnames(styles.describeView, describe.type === 'horizontal' ? styles.horizontal : styles.vertical)}>
          <Card title='可视化效果图' bodyStyle={{ padding: 0, overflow: 'hidden', flex: 1 }} className={styles.describeCard} style={{ [describe.type === 'horizontal' ? 'marginRight' : 'marginBottom']: 16 }}>
            <div className={styles.visionWrapper}>
              <img className={styles.vision} src={describe.image} />
            </div>
          </Card>
          <Card title='方法解读' bodyStyle={{ padding: 0, overflowY: 'hidden', flex: 1 }} className={styles.describeCard}>
            <div className={styles.describe}>
              {describe.describe}
            </div>
          </Card>
        </div>
      }
    </Modal>
  )
};
