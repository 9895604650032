import { observer } from 'mobx-react';
import React, { useState, ReactElement } from 'react';
import { formatNumber } from '../../../util';
import styles from './list.module.css';
import moment from 'moment';
import EN from '../../../constant/en';
import classnames from 'classnames';
import { Popover } from 'antd';
import { Modal } from '../../Common';

import Summary from '../Summary';
import alertIcon from './fail.svg';

interface Interface {
  schedule: any;
  deployment: any;
  cdpo: any;
}

const isExcessThreshold = schedule => {
  if (!schedule.result || !schedule.result.score) return false;
  if (
    !schedule.threshold ||
    !schedule.threshold.type ||
    !schedule.threshold.value
  )
    return false;
  const nameMap = {
    R2: 'r2',
    RMSE: 'rmse',
    MSE: 'mse',
    AUC: 'auc',
    Accuracy: 'acc',
    F1: 'f1',
    Precision: 'precision',
    Recall: 'recall',
    CVNN: 'CVNN',
    CH: 'CH',
    Silhouette_Score: 'silhouette_euclidean',
    macro_auc: 'macro_auc',
    macro_f1: 'macro_F1',
    micro_f1: 'micro_F1',
    macro_recall: 'macro_R',
    micro_recall: 'micro_R',
    macro_precision: 'macro_P',
    micro_precision: 'micro_P',
  };
  const value = {
    R2: (threshold, real) => threshold > real,
    RMSE: (threshold, real) => threshold < real,
    MSE: (threshold, real) => threshold < real,
    Accuracy: (threshold, real) => threshold > real,
    AUC: (threshold, real) => threshold > real,
    F1: (threshold, real) => threshold > real,
    Precision: (threshold, real) => threshold > real,
    Recall: (threshold, real) => threshold > real,
    CVNN: (threshold, real) => threshold > real,
    CH: (threshold, real) => threshold > real,
    silhouette_euclidean: (threshold, real) => threshold > real,
    macro_auc: (threshold, real) => threshold > real,
    micro_auc: (threshold, real) => threshold > real,
    macro_f1: (threshold, real) => threshold > real,
    micro_f1: (threshold, real) => threshold > real,
    macro_recall: (threshold, real) => threshold > real,
    micro_recall: (threshold, real) => threshold > real,
    macro_precision: (threshold, real) => threshold > real,
    micro_precision: (threshold, real) => threshold > real,
  }[schedule.threshold.type](
    schedule.threshold.value,
    schedule.result.score[nameMap[schedule.threshold.type]],
  );
  return value;
};

const transferR2 = str => (str === 'R2' ? 'R²' : str);

const Alert = ({ text }) => (
  <div className={styles.alert}>
    <div className={styles.alertHead}>
      <img className={styles.alertIcon} src={alertIcon} alt="alert" />
    </div>
    <div className={styles.alertContent}>{text}</div>
  </div>
);

function SchedulePerformance(props: Interface): ReactElement {
  const { cdpo, deployment, schedule } = props;

  const [visible, setVisible] = useState(false);

  const showSummary = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showScore = (score, type) => {
    let s;
    if (score && score[type]) s = formatNumber(score[type], 2);
    return s || 0;
  };

  const { bigData, modelType } = deployment;
  return (
    <div className={styles.project}>
      <span className={styles.modelName} title={schedule.name}>
        {schedule.name}
      </span>
      <span className={styles.modelInvokeTime}>
        {isNaN(schedule.actualTime || schedule.estimatedTime)
          ? schedule.actualTime || schedule.estimatedTime
          : moment
              .unix(schedule.actualTime || schedule.estimatedTime)
              .format('MM/DD/YYYY-hh:mma')}
      </span>

      <span className={styles.deploymentStyle}>
        {EN.Predictwith} {EN.DataSource}
        <br />
        <span title={schedule.file}>{schedule.file}</span>
        {/* {s.deployment.performanceOptions.source === 'file' || s.deployment.performanceOptions.source === 'database'
            ? EN.DataSource
            : EN.APISource} */}
      </span>
      <span className={styles.executionSpeed}>
        {/* {(!bigData && schedule.status === 'finished')
          ? schedule.result && schedule.result.executeSpeed
          : ' - '} */}
        { schedule.status === 'finished'
        ? schedule.result && schedule.result.executeSpeed
        : ' - '}
      </span>
      {modelType === 'Classification' && (
        <span
          className={classnames(styles.performance, {
            [styles.issue]: isExcessThreshold(schedule),
          })}
        >
          {schedule.result && schedule.status === 'finished'
            ? `Accuracy:${showScore(
                schedule.result.score,
                'acc',
              )} AUC:${showScore(
                schedule.result.score,
                'auc',
              )} F1:${showScore(
                schedule.result.score,
                'f1',
              )} Precision:${showScore(
                schedule.result.score,
                'precision',
              )} Recall:${showScore(schedule.result.score, 'recall')}`
            : ' - '}
        </span>
      )}

      {modelType === 'Regression' && (
        <span
          className={classnames(styles.performance, {
            [styles.issue]: isExcessThreshold(schedule),
          })}
        >
          {schedule.result && schedule.status === 'finished'
            ? `MSE:${showScore(
                schedule.result.score,
                'mse',
              )} RMSE:${showScore(
                schedule.result.score,
                'rmse',
              )} R²:${showScore(schedule.result.score, 'r2')}`
            : ' - '}
        </span>
      )}
      {/* TODO python performance 是否展示,如何展示 */}
      {/* {bigData && modelType === 'Outlier' && ( */}
      {bigData && modelType === 'Outlier' && (
        <span
          className={classnames(styles.performance, {
            [styles.issue]: isExcessThreshold(schedule),
          })}
        >
          {schedule.result && schedule.status === 'finished'
            ? `Accuracy:${showScore(schedule.result.score, 'score')}`
            : ' - '}
        </span>
      )}

      {modelType === 'Clustering' && (
        <span
          className={classnames(styles.performance, {
            [styles.issue]: isExcessThreshold(schedule),
          })}
        >
          {schedule.result && schedule.status === 'finished'
            ? `${
                !deployment.bigData
                  ? `CVNN:${showScore(schedule.result.score, 'CVNN')}`
                  : ''
              } ${
                !deployment.bigData
                  ? `CH:${showScore(schedule.result.score, 'CH')}`
                  : ''
              } Silhouette Score:${showScore(
                schedule.result.score,
                'silhouette_euclidean',
              )}`
            : ' - '}
        </span>
      )}

      {modelType === 'MultiClassification' && (
        <span
          className={classnames(styles.performance, {
            [styles.issue]: isExcessThreshold(schedule),
          })}
        >
          {schedule.result && schedule.status === 'finished'
            ? `Macro AUC:${showScore(
                schedule.result.score,
                'macro_auc',
              )} Micro AUC:${showScore(
                schedule.result.score,
                'micro_auc',
              )} Macro F1:${showScore(
                schedule.result.score,
                'macro_F1',
              )} Micro F1:${showScore(
                schedule.result.score,
                'micro_F1',
              )} Macro Recall:${showScore(
                schedule.result.score,
                'macro_R',
              )} Micro Recall:${showScore(
                schedule.result.score,
                'micro_R',
              )} Macro Precision:${showScore(
                schedule.result.score,
                'macro_P',
              )} Micro Precision:${showScore(
                schedule.result.score,
                'micro_P',
              )}`
            : ' - '}
        </span>
      )}

      {(!['MultiClassification', 'Clustering', 'Outlier', 'Regression', 'Classification'].includes(modelType) || (!bigData && modelType === 'Outlier') )&& (
        <span  className={classnames(styles.performance)} >
          - 
        </span>
      )}

      {/*<span*/}
      {/*  className={classnames(styles.performance, {*/}
      {/*    [styles.issue]: isExcessThreshold(schedule)*/}
      {/*  })}*/}
      {/*>*/}
      {/*  {*/}
      {/*    schedule.result &&*/}
      {/*    schedule.status === 'finished' &&*/}
      {/*    (schedule.result.problemType === 'Classification'*/}
      {/*      ? `Accuracy:${showScore(schedule.result.score, 'acc')} AUC:${showScore(schedule.result.score, 'auc')} F1:${showScore(schedule.result.score, 'f1')} Precision:${showScore(schedule.result.score, 'precision')} Recall:${showScore(schedule.result.score, 'recall')}`*/}
      {/*      : `MSE:${showScore(schedule.result.score, 'mse')} RMSE:${showScore(schedule.result.score, 'rmse')} R²:${showScore(schedule.result.score, 'r2')}`)*/}
      {/*  }*/}
      {/*  {*/}
      {/*    schedule.result &&*/}
      {/*    schedule.status === 'finished' &&*/}
      {/*    (schedule.result.problemType === 'Outlier'*/}
      {/*      ? `Accuracy:${showScore(schedule.result.score, 'acc')}`*/}
      {/*      : `CVNN:${showScore(schedule.result.score, 'cvnn')} CH:${showScore(schedule.result.score, 'ch')} Silhouette Score:${showScore(schedule.result.score, 'silhouette_score')}`)*/}
      {/*  }*/}
      {/*</span>*/}
      
      {/* spark是否展示阈值 */}
      {/* {!bigData && (
        <span className={styles.threshold}>
          {schedule.threshold && `${transferR2(schedule.threshold.type)}:`}
          {schedule.threshold && `${schedule.threshold.value}`}
        </span>
      )} */}
      {schedule.threshold && (
        <span className={styles.threshold}>
          {schedule.threshold && `${transferR2(schedule.threshold.type)}:`}
          {schedule.threshold && `${schedule.threshold.value}`}
        </span>
      )}
      {!schedule.threshold && (
        <span className={styles.threshold}>
          -
        </span>
      )}
      {schedule.status !== 'issue' && (
        <span className={styles.status_}>
          {schedule.status[0].toUpperCase() +
            schedule.status.substr(1, schedule.status.lenght)}
        </span>
      )}

      {schedule.status === 'issue' && (
        <Popover
          placement="left"
          overlayClassName={styles.popover}
          content={<Alert text={schedule.result['processError']} />}
        >
          <span className={classnames(styles.status_, styles.issue)}>
            {EN.Issue}
          </span>
        </Popover>
      )}
      {schedule.status === 'finished' &&
      schedule.location !== 'database' &&
      schedule.result ? (
        <a
          className={styles.results}
          target="_blank"
          href={`/r2upload/download/${schedule.id}?filename=${
            typeof cdpo.file === 'string'
              ? cdpo.file
              : cdpo.sourceOptions.databaseType
          }-${moment
            .unix(schedule.actualTime || schedule.estimatedTime)
            .format('MM-DD-YYYY_hh-mm')}-predict.csv`}
        >
          {EN.Download}
        </a>
      ) : (
        <span className={styles.emptyResults}> - </span>
      )}
      {schedule.result?.dataReport?.dataView &&
      schedule.status === 'finished' ? (
        <a onClick={showSummary} className={styles.results}>
          <span className={styles.status_}>{EN.DeploySummaryData}</span>
        </a>
      ) : (
        <span className={styles.emptyResults}> - </span>
      )}
      {schedule.status === 'finished' && (
        <Modal
          // content={<Summary summary={summary} onClose={onClose} loading={loading} />}
          content={
            <Summary
              summary={{
                ...schedule.result.dataReport,
                mapHeader: schedule.mapHeader || deployment.mapHeader,
                problemType: modelType,
              }}
              onClose={onClose}
            />
          }
          visible={visible}
          width="12em"
          title={EN.DeploySummary}
          onClose={onClose}
          closeByMask={true}
          showClose={true}
        />
      )}
    </div>
  );
}
export default observer(SchedulePerformance);
