import { observable, action, when } from 'mobx';
import axios from 'axios';
import socketStore from './SocketStore';
import { changeEN } from '../constant/en';
import EN from '../constant/en';
// import testData from './classification.json'
// import testData from './regression.json'
// import testData from './test.json'
import { Modal, notification } from 'antd';
import { Domain } from '@app/constant/types';

const getParam = (paramstr: string): {code?: string, state?: string, token?:string} => {
  const param = {};
  if (paramstr) {
    const paramStrList = paramstr.replace('?', '').split('&');
    paramStrList.forEach((str) => {
      const [key, value] = str.split('=');
      param[key] = value;
    });
  }
  console.log('####param', param);
  return param;
}
interface exceptionPromptMsg {
  // status: number,
  // id: string,
  msg: string,
  projectId: number,
  // userId: number,
  command: string,
}

class UserStore {

  @observable status = 'init'; // unlogin login
  @observable info = {
    id: '',
    email: '',
    role: {} as any,
    level: 0,
    from: ''
  };
  @observable tabKey = '1';
  @observable videoKey = '1';
  @observable isWatchVideo = true;
  @observable isCheck = false;
  @observable planList = [];
  @observable labUrl = '';
  @observable dashUrl = '';
  @observable account = false;
  @observable allUser = [];
  @observable roleList = [];
  @observable changePasswdVisible: boolean = false;

  @action change = (name) => (val) => {
    this[name] = val;
  };
  @action exceptionListener = () => {
    socketStore.ready().then(api => {
      api.addEventListener("exceptionPrompt", (data: exceptionPromptMsg) => {
        const { msg, projectId, command } = data;
        notification.warning({
        message: `${EN.Project}ID：${projectId}`,
        description: EN.InsufficientResourcesMessage,
        duration: 4,
        top: 110,
        style: { borderRadius: '20px' },
        placement: 'topRight',
      });
      });
    });
  };

  constructor() {
    // window.r2Report = testData

    if ((window as any).r2Report) {
      this.status = 'unlogin'
      return
    }
    this.getEnv().then(() => {
      if ((window as any).r2_env.domain === Domain.GZCB && location.pathname === '/auth') {
        this.singleLogin();
      } else if ((window as any).r2_env.domain === Domain.NUOQI && location.pathname === '/auth') {
        this.singleLoginWithToken();
      } else {
        this.getStatus();
      }
    })

    when(() => this.status === 'login', socketStore.connect.bind(socketStore))
  }

  getAllUsers = () => {
    socketStore.ready().then( api => {
      // if(!this.allUser.length){
        api.allUserList().then(result=>{
          this.allUser = result.list;
        })

      // }
      // return this.allUser;
    })
  }

  getEnv() {
    return axios.get(`/env`).then(action((res: any) => {
      if (res.data.status === 200)
        (window as any).r2_env = res.data.env;
        Object.freeze((window as any).r2_env)
        changeEN()
    }));
  }
  
  getRoleList = () =>{
    socketStore.ready().then(api => {
      api.getAllRoleList().then(result=>{
        this.roleList = result.list
      })
    })
  }

  async singleLogin() {
    const { code, state } = getParam(location.search);
    await axios.post(`/gzcb/login`, { code, state }).then(action((res: any) => {
      const { status, msg, url } = res.data;
      if (status === 200) { // 登陆成功
        return this.getStatus();
      }
      this.status = 'unlogin'
      if (status === 301) { // 重定向至认证系统
        return location.href = url;
      }
      return Modal.error({
        title: '登陆失败',
        content: msg,
      });
    }));
  }

  // 单一登陆（诺棋专用）
  async singleLoginWithToken() {
    const { token } = getParam(location.search);
    await axios.post(`/nuoqi/login`, { token }).then(action((res: any) => {
      const { status, msg, url } = res.data;
      if (status === 200) { // 登陆成功
        return this.getStatus();
      }
      this.status = 'unlogin'
      if (status === 301) { // 重定向至认证系统
        return location.href = url;
      }
      return Modal.error({
        title: '登陆失败',
        content: msg,
      });
    }));
  }

  getStatus() {
    axios.get(`/user/status?v=${Date.now()}`).then(action((res: any) => {
      const { data } = res;
      if (data.status === 200) {
        const info = data.info
        if (info && info.role) {
          this.info = info
          // console.log('block',data.info.block);
          // if (info.role.project === false && location.pathname === '/') {
          //   location.href = '/deploy'
          // }
        }
        this.status = 'login'
      } else {
        if((window as any).r2_env.cas){
          if(data?.casLogin){
            return Modal.error({
              title: '登录失败!',
              content: data.message||"抱歉,暂无使用权限,请联系管理员!",
              onOk:async ()=>{
                await this.logout()
                // location.replace('/cas/logout')
                // this.status = 'unlogin'
              }
            });
          }else{
            return location.href = '/cas/login'
          }
        }
        if (data.portal) location.href = data.portal
        this.status = 'unlogin'
      }
    })).catch(() => {
      this.status = 'unlogin'
    });

    axios.get('/layout').then(({ data }) => {
      this.dashUrl = data.dashUrl
      this.labUrl = data.labUrl
    }).catch(() => {
      this.dashUrl = '';
      this.labUrl = ''
    })
  }

  getAccountInfo() {
    return axios.get('/user/accountinfo').then(({ data }) => {
      return data
    }).catch(() => {
      console.log(`get account info error`)
      return {}
    })

    // return axios.get('/user/accountinfo').then(({ data }) => {
    //   return data
    // }).catch(() => {
    //   console.log(`get account info error`)
    //   return {}
    // })
  }

  getPlans = async () => {
    try {
      const { data } = await axios.get('/user/getPlans')
      return data.plan
    } catch (e) {
      return {}
    }

  }

  login(params) {
    return axios.post(`/user/login`, params)
  }

  register(params) {
    axios.post(`/user/register`, params).then(action((res: any) => {
      if (res.data.status === 200) {
        this.info = res.data.info;
        this.status = 'login';
        this.getStatus();
      } else {
        alert(res.data.message)
      }
    }))
  }

  logout() {
    if((window as any).r2_env.cas){
      return location.replace('/cas/logout')
    }
    axios.delete(`/user/logout`).then(action(async(res: any) => {
      if (res.data.status === 200) {
        if (res.data.portalUrl) {
          await axios.delete('/user/clean');
          return location.href = res.data.portalUrl;
        }
        this.status = 'unlogin';
        this.labUrl = '';
        this.dashUrl = '';
        if(res.data.cas){
          return location.href = '/cas/login'
        }
        // 回到主域名
        return location.href = location.origin;
        // window.location.reload()
      } else {
        alert(res.data.message)
      }
    }))
  }

  goManagement() {
    window.open((window as any).r2_env.managementUrl || '/admin');
  }

  resetPassword(code, password) {
    return axios.put(`/user/resetpassword`, { code, password })
  }

  forgetPassword(email) {
    return axios.post(`/user/forgetpassword`, { email })
  }

  changePassword(originPasswd: string, newPasswd: string, email?: string) {
    return axios.post(`/user/changepassword`, { originPasswd, newPasswd, email })
  }

  changePasswdModalVisible = (isopen: boolean) => {
    this.changePasswdVisible = isopen;
  };
}

export { UserStore }

export default new UserStore()
