import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRequest } from 'ahooks';
import { TreeExplainOption, TreeExplain, ExplainViewLabel } from './type';
import r2LoadGif from '@app/assets/R2Loading.gif'
import { Button, Result, Col, Row, Image } from 'antd';
import { TreeOptionForm } from './TreeOptionForm';


interface TreePlotsProps {
  explain: TreeExplain,
  onExplain: (option: TreeExplainOption) => Promise<TreeExplain>,
  refreshExplain: (id: number) => Promise<TreeExplain>,
  onUpdateExplain?: (exp: TreeExplain) => any,
}

export const TreePlots: React.FC<TreePlotsProps> = ({
  explain,
  onExplain,
  refreshExplain,
  onUpdateExplain = () => {},
}) => {
  const { run: sync, cancel: cancelSync } = useRequest((id: number) => refreshExplain(id).then((res) => {
    onUpdateExplain(res);
  }), {
    pollingInterval: 5000,
    manual: true,
  });

  useEffect(() => {
    const func = (explain.errorMsg || !explain.explaining) ? cancelSync : () => sync(explain.id);
    func();

    return cancelSync;
  }, [explain.id, explain.errorMsg, explain.explaining])

  const newExplain = (_option: TreeExplainOption) => {
    cancelSync();
    onExplain(_option).then((res) => {
      onUpdateExplain(res)
    });
  }


  const renderOption = (text: string) => {
    return (
      // <div>
        <TreeOptionForm
          onFinish={values => newExplain(values)}
          initialValues={explain.plotData.option}
        >
          <Button type='primary' htmlType='submit'>{text}</Button>
        </TreeOptionForm>
    )
  }

  if (explain.explaining) {
    return (
      <Result
        icon={(<img src={r2LoadGif} alt="loading" />)}
        title={`正在解释${ExplainViewLabel[explain.viewType]}`}
        // subTitle={project.modelExplainError}
        extra={renderOption('等待时间过长？重新解释')}
      />
    )
  }

  if (explain.errorMsg || !explain.plotData.image) {
    return (
      <Result
        status="error"
        title={`解释${ExplainViewLabel[explain.viewType]}失败`}
        subTitle={explain.errorMsg}
        extra={renderOption('重新解释')}
      />
    )
  }

  return (
    <Row>
      <Col span={12}>
        {renderOption('确定')}
      </Col>
      <Col span={12}>
        <Image
          style={{ maxWidth: 550, width: '100%' }}
          src={explain.plotData.image}
        />
      </Col>
    </Row>
  );
}
